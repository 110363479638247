import React from 'react'
import { Row } from 'react-table'
import { useTranslation } from 'react-i18next'

import { ABTestRowData } from './ABTestRowData'
import { ButtonType, ButtonTheme } from 'components/Button'
import { Edit } from 'icons/Edit'
import { LinkButton } from 'components/LinkButton'
import { routesBuilder } from 'utilities/routesBuilder'

interface Props {
  row: Row<ABTestRowData>
}

export const ActionsCell: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation('inventory')

  return (
    <div className='cell--actions' onClick={(e) => e.stopPropagation()}>
      {!row.original.active && (
        <LinkButton
          to={routesBuilder.quickwrap.abTesting.edit(row.original.id)}
          type={ButtonType.SingleAction}
          theme={ButtonTheme.Blue}
          className='cell--actions__button cell--actions__button--icon'
          tooltipContent={t('common:buttons.edit')}
        >
          <Edit />
        </LinkButton>
      )}
    </div>
  )
}
