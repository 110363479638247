import { useQuery } from '@apollo/client'

import { Role } from 'webapp/constants/Role'

import UsersList from 'gql/queries/users/UsersList.gql'

export const useQueryUsersByRole = (roles: Role | Role[], skip?: boolean) => {
  const { data } = useQuery<{ users: Connection<User> }>(UsersList, {
    fetchPolicy: 'no-cache',
    skip,
    variables: {
      filter: [
        {
          by: 'role',
          operator: 'is',
          values: [roles].flat()
        }
      ]
    }
  })

  return data?.users.nodes
}
