import $ from 'jquery'

const onInputFocus = (target: JQuery) => {
  if (target.val() !== '') {
    target.closest('.styled-input').addClass('not-empty')
  } else {
    target.closest('.styled-input').removeClass('not-empty')
  }
}

const onInputChangeHandler = () => {
  const input = $('.styled-input input')

  input.change(function () {
    onInputFocus($(this))
  })

  input.keyup(function () {
    onInputFocus($(this))
  })
}

const onInputLoadHandler = () => {
  $('.styled-input input').each(function () {
    onInputFocus($(this))
  })
}

const onFocusHandler = () => {
  $('.styled-input input').focus(function () {
    if ($(this).closest('.field_with_errors')) {
      $(this).closest('.field_with_errors').addClass('focused')
    }
  })

  $('.styled-input input').focusout(function () {
    $(this).closest('.focused').removeClass('focused')
  })
}

onInputLoadHandler()
onInputChangeHandler()
onFocusHandler()
