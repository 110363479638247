import React from 'react'
import {
  BidderInventoryTabElements,
  BidderInventoryTabs
} from 'components/quickwrap/Setup/BidderInventory/bidderInventoryTabs'
import { Box } from 'components/Box'
import { DomainComparisonContainer } from 'webapp/containers/quickwrap/Setup/DomainComparisonContainer'
import { InventoryConnectionContainer } from 'containers/quickwrap/Setup/InventoryConnectionContainer'
import { TabNavigation, useCurrentTabNavigation } from 'components/Box/TabNavigation'

export const BidderInventory = () => {
  const tabParamName = 'tab'
  const defaultTab = BidderInventoryTabs.inventoryConnection
  const currentTab = useCurrentTabNavigation(tabParamName) || defaultTab

  return (
    <Box className='bidder-inventory'>
      <TabNavigation tabs={BidderInventoryTabElements} searchParam={tabParamName} defaultTab={defaultTab} />
      <InventoryConnectionContainer isVisible={currentTab === BidderInventoryTabs.inventoryConnection} />
      <DomainComparisonContainer isVisible={currentTab === BidderInventoryTabs.domainComparison} />
    </Box>
  )
}
