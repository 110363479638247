import React, { useMemo } from 'react'

import { Collapse, Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Select, StatusForm } from 'components/Form'
import { CommonInputs } from 'components/shared/CustomCode/CommonInputs'
import { Product } from 'constants/Product'
import { FormikCheckboxContainer, FormikInputContainer } from 'containers/FormikContainers'
import { useCurrentProduct } from 'utilities/products/current'
import { CustomCodeFactory } from './formSchema'
import { CustomCodeFormProps } from './types'

export const CustomCodeForm = ({
  customCodeTemplates,
  errors,
  existingCustomCode,
  isSubmitting,
  onlySuperusersCanEdit,
  onSubmit,
  onTemplateChange
}: CustomCodeFormProps): JSX.Element => {
  const { t } = useTranslation('setup')
  const product: Product = useCurrentProduct()

  const options = useMemo(
    () =>
      customCodeTemplates.map((code, i) => ({
        label: code.name,
        value: i,
        codeBefore: code.codeBefore,
        codeAfter: code.codeAfter
      })),
    [customCodeTemplates]
  )

  return (
    <div className={'custom-code-form'}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Select
            disabled={onlySuperusersCanEdit}
            id='custom-code-template'
            label={t('customCodes.form.template.label')}
            labelStyle='bold'
            name='template'
            onChangeHandler={onTemplateChange}
            options={options}
            placeholder={t('customCodes.form.template.placeholder')}
            tooltipContent={t('customCodes.form.template.tooltip')}
          />
        </Grid>
      </Grid>

      <Formik enableReinitialize initialValues={CustomCodeFactory(existingCustomCode)} onSubmit={onSubmit}>
        {({ dirty, values }) => (
          <StatusForm name={`${product}-custom-code`} dirty={dirty}>
            <Grid container>
              <CommonInputs errors={errors} disabled={onlySuperusersCanEdit} />
            </Grid>

            <Collapse in={values.saveAsTemplate}>
              <Grid item xs={12} md={8}>
                <Field
                  component={FormikInputContainer}
                  errors={errors.errorsFor('name')}
                  id='custom-code-name'
                  label={t('customCodes.form.name.label')}
                  labelStyle='bold'
                  name='name'
                  type='text'
                />
              </Grid>
            </Collapse>

            <div className='custom-code-form__submit-section'>
              <Field
                component={FormikCheckboxContainer}
                disabled={onlySuperusersCanEdit}
                id='custom-code-save-as-template'
                label={t('customCodes.form.saveAsTemplate')}
                name='saveAsTemplate'
              />

              <Button
                buttonType='submit'
                disabled={isSubmitting || !dirty}
                theme={ButtonTheme.Orange}
                type={ButtonType.Primary}
              >
                {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
              </Button>
            </div>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
