import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ToggleSwitch } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error as QqlError } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { DomainAutoSendRequestProps, UpdateAutoSendRequestData, UpdateAutoSendRequestVars } from './types'

import { UPDATE_DOMAIN_AUTO_SEND_REQUEST } from 'gql/mutations/domains/UpdateDomainAutoSendRequest'

import './style.scss'

export const DomainAutoSendRequestToggle = ({ domainId, initialStatus }: DomainAutoSendRequestProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [status, setStatus] = useState<boolean>(initialStatus)

  const [updateAutoSendRequest] = useMutation<UpdateAutoSendRequestData, { input: UpdateAutoSendRequestVars }>(
    UPDATE_DOMAIN_AUTO_SEND_REQUEST,
    {
      onCompleted: ({ updateDomain: { domain, errors } }) => {
        if (errors.length > 0) {
          createNotifier(errors.map((e: QqlError) => e.message).join(', '), NotificationType.ERROR)
        } else if (domain) {
          const { name, autoSendRequestEnabled } = domain

          createNotifier(t('domains.autoSendRequestToggle.update.success', { name }), NotificationType.SUCCESS)
          setStatus(autoSendRequestEnabled)
        }
      }
    }
  )

  const handleChange = () =>
    void updateAutoSendRequest(
      nestGqlInput({
        domainId,
        ['autoSendRequestEnabled']: !initialStatus
      })
    )

  const TOGGLE_KEY = `domain-${domainId}-auto-send-request-toggle`

  return (
    <div className='DomainAutoSendRequestToggle' onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        checked={status}
        id={TOGGLE_KEY}
        key={TOGGLE_KEY}
        name={TOGGLE_KEY}
        onChange={() => handleChange()}
      />
    </div>
  )
}
