export const bidderSelectFilterOption = (candidate: { [key: string]: any }, input: string): boolean => {
  if (input) {
    const props = candidate.label && candidate.label.props
    const query = input.toLowerCase()
    return (
      props &&
      (props.fileName.includes(query) ||
        props.placeholder.toLowerCase().includes(query) ||
        props.bidderCode?.toLowerCase().includes(query))
    )
  }
  return true
}
