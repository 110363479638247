import React from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { GamConnectButton } from 'components/oss/Gam/GamConnectButton'
import { GamConnectorContainer } from 'containers/oss/Workspaces/EditWorkspaceContainer/GamConnectorContainer'
import { oauthGamCallbackUrl } from 'webapp/constants/oauthCallbackUrl'

export const WorkspaceGamConnector: React.FC = () => {
  const history = useHistory<History>()

  const isUserAuthorised = Boolean(queryString.parse(history.location.search)?.refreshToken)

  return (
    <div className='WorkspaceGamConnector-box box__body'>
      {isUserAuthorised ? (
        <GamConnectorContainer />
      ) : (
        <GamConnectButton oauthCallbackUrl={oauthGamCallbackUrl} translateKey={'connector.button.connect'} />
      )}
    </div>
  )
}
