import React from 'react'

import { StickyCell } from 'components/quickwrap/Setup/DomainComparisonTable/StickyCell'

interface Props {
  startingOffset?: number
  version: DomainVersion
}

export const DomainTestCell: React.FC<Props> = ({ startingOffset, version }) => {
  return (
    <StickyCell className='domain-comparison-table__test' startingOffset={startingOffset} stickToLeft tag='td'>
      {version.activeAbTest && <span>{version.activeAbTest?.name}</span>}
    </StickyCell>
  )
}
