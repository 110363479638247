import { isEqual } from 'lodash'
import { AdvancedFilterRow } from './types'

export const removeAdvancedFilterRow = ({
  advancedFilters,
  filterPath,
  onSetFiltersDebounced
}: AdvancedFilterRow): void => {
  const filterToRemoveIndex = advancedFilters.findIndex((f: AdvancedFilter) => f.by === filterPath)

  if (filterToRemoveIndex > -1) {
    const updated: AdvancedFilter[] = [...advancedFilters]
    updated.splice(filterToRemoveIndex, 1)

    onSetFiltersDebounced(updated)
  }
}

export const updateAdvancedFilterRow = (
  { advancedFilters, filterPath, onSetFiltersDebounced }: AdvancedFilterRow,
  nameFilterValues: string[],
  updatedLinkedFilter: AdvancedFilter
): void => {
  const filterIndex: number = advancedFilters.findIndex((f: AdvancedFilter) => f.by === filterPath)

  if (filterIndex > -1) {
    const updated: AdvancedFilter[] = [...advancedFilters]

    if (!isEqual(updated[filterIndex].values, nameFilterValues)) {
      updated[filterIndex] = updatedLinkedFilter

      onSetFiltersDebounced(updated)
    }
  } else {
    onSetFiltersDebounced([...advancedFilters, updatedLinkedFilter])
  }
}
