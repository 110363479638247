import React from 'react'

import { CellProps, Column } from 'react-table'

import { DateCell } from 'components/Table'
import { OssAdUnitRowData } from 'components/oss/Inventory/AdUnits/Table/OssAdUnitRowData'
import OssAdUnitProductToggle from 'components/shared/Inventory/AdUnits/OssAdUnitProductToggle'
import { ActionsCell } from 'components/shared/Inventory/AdUnits/Table/ActionsCell'
import { Product } from 'webapp/constants/Product'
import i18n from 'webapp/i18n'

export const columnsConfig = (): Column<OssAdUnitRowData>[] => [
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.domain'),
    accessor: 'domain'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.adUnitName'),
    accessor: 'adUnitName'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.elementId'),
    accessor: 'elementId'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.adUnitPath'),
    accessor: 'adUnitPath'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.ossPagesCount'),
    accessor: 'ossPagesCount'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.quickWrap'),
    accessor: 'qwEnabled',
    Cell: (rowData) => QuickWrapEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.priceGenius'),
    accessor: 'pgEnabled',
    Cell: (rowData) => PriceGeniusEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.fillRateBooster'),
    accessor: 'frbEnabled',
    Cell: (rowData) => FillRateBoosterEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.revive'),
    accessor: 'reviveEnabled',
    Cell: (rowData) => ReviveEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    Header: () => i18n.t('table.header.actions'),
    id: 'actions',
    Cell: ({ row }: CellProps<OssAdUnitRowData>) => <ActionsCell row={row} product={Product.OSS} />,
    disableSortBy: true
  }
]

const QuickWrapEnabledCell = ({
  row: {
    original: { id, qwEnabled }
  }
}: CellProps<OssAdUnitRowData>): JSX.Element => (
  <OssAdUnitProductToggle ossAdUnitId={id} initialStatus={qwEnabled} product={Product.Quickwrap} />
)

const PriceGeniusEnabledCell = ({
  row: {
    original: { id, pgEnabled }
  }
}: CellProps<OssAdUnitRowData>): JSX.Element => (
  <OssAdUnitProductToggle ossAdUnitId={id} initialStatus={pgEnabled} product={Product.PriceGenius} />
)

const FillRateBoosterEnabledCell = ({
  row: {
    original: { id, frbEnabled }
  }
}: CellProps<OssAdUnitRowData>): JSX.Element => (
  <OssAdUnitProductToggle ossAdUnitId={id} initialStatus={frbEnabled} product={Product.FillRateBooster} />
)

const ReviveEnabledCell = ({
  row: {
    original: { id, reviveEnabled }
  }
}: CellProps<OssAdUnitRowData>): JSX.Element => (
  <OssAdUnitProductToggle ossAdUnitId={id} initialStatus={reviveEnabled} product={Product.Revive} />
)
