import { RoleType } from 'webapp/constants/Role'

export const InviteUserFactory = (roleType?: RoleType): InviteUserSchema => {
  return {
    roleType: { value: roleType ?? RoleType.Publisher },
    email: '',
    role: null,
    workspaceId: null
  }
}
