import { BidderPriorityTypes } from 'webapp/constants/BidderPriorityTypes'
import { BidderConsentModeTypes } from 'webapp/constants/BidderConsentModeTypes'
import { SchainNetworkConnectionTypes } from 'webapp/constants/SchainNetworkConnectionTypes'

export const OwnBidderFactory = (bidderSettings?: BidderSetting): OwnBidderFormData => ({
  id: bidderSettings?.id,
  active: bidderSettings?.active ?? false,
  bidderId: { value: bidderSettings?.bidder.id ?? '' },
  bidderCode: bidderSettings?.bidderCode ?? '',
  bidderSid: bidderSettings?.bidderSid ?? '',
  bidderSettingNetworks:
    (bidderSettings?.bidderSettingNetworks ?? [])
      .sort((a: BidderSettingNetwork, b: BidderSettingNetwork) => a.position - b.position)
      .map((bidderSettingNetwork: BidderSettingNetwork) => ({
        value: bidderSettingNetwork.network.id ?? '',
        label: bidderSettingNetwork.network.name
      })) ?? [],
  bidCpmAdjustment: bidderSettings?.bidCpmAdjustment ?? 1,
  consentMode: { value: bidderSettings?.consentMode ?? BidderConsentModeTypes.consent },
  priority: { value: bidderSettings?.priority ?? BidderPriorityTypes.primary },
  s2sAvailable: bidderSettings?.bidder.s2sAvailable ?? false,
  schainNetworkConnection: bidderSettings?.bidderSettingNetworks?.length
    ? SchainNetworkConnectionTypes.Indirect
    : SchainNetworkConnectionTypes.Direct
})
