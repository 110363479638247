import React, { useEffect, useState } from 'react'

import Creatable from 'react-select/creatable'

import { isEqual } from 'lodash'
import { valueToSelectOptions } from './service'
import { TagsInputProps } from './types'

import './style.scss'

const CLASS_NAME = 'TagsInput'

export const TagsInput = ({ disabled, value: initValue, onChange, placeholder, id }: TagsInputProps): JSX.Element => {
  const initValueSelectOptions: SelectOption<string>[] = valueToSelectOptions(initValue)

  const [value, setValue] = useState<SelectOption<string>[]>(initValueSelectOptions)

  useEffect(() => {
    if (!isEqual(value, initValueSelectOptions)) {
      setValue(initValueSelectOptions)
    }
  }, [initValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChange(value.map((o: SelectOption<string>): string => o.value))
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (newValue: readonly SelectOption<string>[]): void => {
    const trimmedValues: string[] = newValue.map((o: SelectOption<string>) => o.value.trim())

    const newSelectOptions: SelectOption<string>[] = trimmedValues.map((v: string) => ({
      label: v,
      value: v
    }))

    setValue(newSelectOptions)
  }

  return (
    <Creatable
      className={CLASS_NAME}
      classNamePrefix={CLASS_NAME}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Menu: () => null,
        MenuList: () => null
      }}
      id={id}
      isClearable={false}
      isDisabled={disabled}
      isMulti
      name={id}
      onChange={handleChange}
      options={[]}
      placeholder={placeholder ?? ''}
      value={value}
    />
  )
}
