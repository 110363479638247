import { RoleType } from 'webapp/constants/Role'

export class InviteUserSerializer {
  constructor(private data: InviteUserSchema, private isSuperuser: boolean) {}

  getParams() {
    return this.isSuperuser ? this.superuserParams() : this.publisherParams()
  }

  private publisherParams() {
    return {
      email: this.data.email,
      role: this.data.role?.value ?? ''
    }
  }

  private superuserParams() {
    return {
      ...this.publisherParams(),
      workspaceId: this.data.roleType?.value === RoleType.Publisher ? this.data.workspaceId?.value : undefined
    }
  }
}
