import { emptySelectOption } from 'webapp/constants/emptySelectOption'
import { separatorNumberFormat } from 'utilities/formatNumbers'

const currencyList = [
  { value: 'DKK', label: 'DKK' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'MYR', label: 'MYR' },
  { value: 'PLN', label: 'PLN' },
  { value: 'USD', label: 'USD' },
  { value: 'TRY', label: 'TRY' },
  { value: 'SEK', label: 'SEK' },
  { value: 'CZK', label: 'CZK' },
  { value: 'NOK', label: 'NOK' },
  { value: 'INR', label: 'INR' }
]

const currencyMap: { [key: string]: string } = {
  DKK: 'da-DK',
  EUR: 'en-EN',
  GBP: 'en-GB',
  MYR: 'en-MY',
  PLN: 'pl-PL',
  USD: 'en-US',
  TRY: 'tr-TR',
  SEK: 'se-SE',
  CZK: 'cz-CZ',
  NOK: 'no-NO',
  INR: 'en-IN'
}

export const currencyUtils = Object.freeze({
  getList: () => currencyList,

  get: (value?: string): SelectOption =>
    currencyList.find((el) => el.value === value?.toUpperCase()) ?? emptySelectOption,

  currencyFormat: (value: number, currencyCode?: string) => {
    if (!currencyCode) {
      return separatorNumberFormat(value)
    }
    const locales = currencyMap[currencyCode]
    const formatter = new Intl.NumberFormat(locales, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 6
    })
    return formatter.format(value)
  }
})
