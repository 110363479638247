import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { Product } from 'constants/Product'
import ConfigurationContainer from 'pages/price-genius/Configuration/ConfigurationContainer'
import { DataStudioPage } from 'pages/shared/Performance/DataStudio'
import { routesBuilder } from 'utilities/routesBuilder'

export const PriceGeniusRoutes = [
  <LayoutRoute
    component={ConfigurationContainer}
    key='price-genius-configuration'
    path={routesBuilder.price_genius.configuration.root}
    product={Product.PriceGenius}
  />,

  <LayoutRoute
    component={DataStudioPage}
    key='price-genius-data-studio'
    path={routesBuilder.price_genius.performance.dataStudio}
    product={Product.PriceGenius}
    userPermissionRequired
  />
]
