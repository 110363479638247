import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { LoadingContainer } from 'components/LoadingContainer'
import { QueryTableProps } from './types'
import { Table } from 'components/Table'
import { useQueryTableParams } from './useQueryTableParams'

export function QueryTable<T>({
  className,
  collectionName,
  columnsConfig,
  columnTooltips,
  dataQuery,
  getRowProps,
  globalFilter,
  hiddenColumns,
  hiddenColumnsHandler,
  mapper,
  onRowClick,
  pageSize = 10,
  pageIndex,
  paginateExpandedRows,
  searchableFields,
  setPageIndex,
  shouldRefetchData,
  subCollectionName,
  variables
}: QueryTableProps<T>): JSX.Element {
  const history = useHistory()
  const keyedCollection = collectionName + (subCollectionName ?? '')

  const queryTableParams = useQueryTableParams(keyedCollection, searchableFields, pageSize, globalFilter, pageIndex)

  const { loading, data, refetch } = useQuery(dataQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { ...variables, ...queryTableParams }
  })

  const isFirstRun = useRef(true)
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    refetch()
  }, [queryTableParams, history.location]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refetch()
  }, [shouldRefetchData, refetch])

  const getData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (dataObject: any, key: string) => {
      if (!dataObject || !dataObject[key]) return []

      return mapper ? dataObject[key].nodes.map(mapper) : dataObject[key].nodes
    },
    [mapper]
  )

  const getTableSize = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (dataObject: any, key: string): number => {
      if (!dataObject || !dataObject[key]) return 0

      return Math.ceil(dataObject[key].totalCount / pageSize)
    },
    [pageSize]
  )

  const determineHiddenColumns = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (dataObject: any): string[] => {
      return (hiddenColumns || []).concat(dataObject && hiddenColumnsHandler ? hiddenColumnsHandler(dataObject) : [])
    },
    [hiddenColumns, hiddenColumnsHandler]
  )

  useEffect(() => {
    shouldRefetchData && refetch()
  }, [shouldRefetchData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoadingContainer loading={loading}>
      <Table<T>
        className={className}
        collectionName={keyedCollection}
        columns={columnsConfig}
        columnTooltips={columnTooltips}
        data={data && getData(data, collectionName)}
        getRowProps={getRowProps}
        hiddenColumns={determineHiddenColumns(data)}
        onRowClick={onRowClick}
        pageIndex={pageIndex}
        paginateExpandedRows={paginateExpandedRows}
        queryPageSize={data && getTableSize(data, collectionName)}
        queryPagination={true}
        setPageIndex={setPageIndex}
      />
    </LoadingContainer>
  )
}
