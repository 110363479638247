import React from 'react'

import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { DateCell } from 'components/Table'
import DomainProductToggle from 'components/shared/Inventory/Domains/DomainProductToggle'
import DomainWrapperVersionToggle from 'components/shared/Inventory/Domains/DomainWrapperVersionToggle'
import DomainAutoSendRequestToggle from 'components/shared/Inventory/Domains/DomainAutoSendRequestToggle'
import { ActionsCell } from 'components/shared/Inventory/Domains/DomainsTable/ActionsCell'
import { DomainRowData } from 'components/shared/Inventory/Domains/DomainsTable/DomainRowData'
import { LiveCell } from 'components/shared/Inventory/Domains/DomainsTable/LiveCell'
import { Product } from 'webapp/constants/Product'

export const columnsConfig: Column<DomainRowData>[] = [
  {
    Header: () => i18n.t('inventory:domains.tableHeader.name'),
    accessor: 'name'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.workspaceName'),
    accessor: 'workspace',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.pages'),
    accessor: 'pagesCount'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.adUnits'),
    accessor: 'adUnitsCount'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.devices'),
    accessor: 'prebidDeviceCount'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.quickWrap'),
    accessor: 'qwEnabled',
    Cell: (rowData) => QuickWrapEnabledCell(rowData),
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.priceGenius'),
    accessor: 'pgEnabled',
    Cell: (rowData) => PriceGeniusEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.fillRateBooster'),
    accessor: 'frbEnabled',
    Cell: (rowData) => FillRateBoosterEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.viewabilityBooster'),
    accessor: 'vbEnabled',
    Cell: (rowData) => ViewabilityBoosterEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.revive'),
    accessor: 'reviveEnabled',
    Cell: (rowData) => ReviveEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.wrapperVersion'),
    accessor: 'wrapperVersion',
    Cell: (rowData) => WrapperVersionCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.autoSendRequestEnabled'),
    accessor: 'autoSendRequestEnabled',
    Cell: (rowData) => autoSendRequestEnabledCell(rowData)
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.live'),
    accessor: 'live',
    disableSortBy: true,
    Cell: ({ value }) => <LiveCell live={value} />
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    id: 'actions',
    Header: () => i18n.t('Actions'),
    Cell: ({ row }: CellProps<DomainRowData>) => <ActionsCell row={row} product={Product.OSS} />,
    disableSortBy: true
  }
]

const QuickWrapEnabledCell = ({
  row: {
    original: { id, qwEnabled }
  }
}: CellProps<DomainRowData>): JSX.Element => (
  <DomainProductToggle domainId={id} initialStatus={qwEnabled} product={Product.Quickwrap} />
)

const PriceGeniusEnabledCell = ({
  row: {
    original: { id, pgEnabled }
  }
}: CellProps<DomainRowData>): JSX.Element => (
  <DomainProductToggle domainId={id} initialStatus={pgEnabled} product={Product.PriceGenius} />
)

const FillRateBoosterEnabledCell = ({
  row: {
    original: { id, frbEnabled }
  }
}: CellProps<DomainRowData>): JSX.Element => (
  <DomainProductToggle domainId={id} initialStatus={frbEnabled} product={Product.FillRateBooster} />
)

const ViewabilityBoosterEnabledCell = ({
  row: {
    original: { id, vbEnabled }
  }
}: CellProps<DomainRowData>): JSX.Element => (
  <DomainProductToggle domainId={id} initialStatus={vbEnabled} product={Product.ViewabilityBooster} />
)

const ReviveEnabledCell = ({
  row: {
    original: { id, reviveEnabled }
  }
}: CellProps<DomainRowData>): JSX.Element => (
  <DomainProductToggle domainId={id} initialStatus={reviveEnabled} product={Product.Revive} />
)

const WrapperVersionCell = ({
  row: {
    original: { id, wrapperVersion }
  }
}: CellProps<DomainRowData>): JSX.Element => <DomainWrapperVersionToggle domainId={id} initialStatus={wrapperVersion} />

const autoSendRequestEnabledCell = ({
  row: {
    original: { id, autoSendRequestEnabled }
  }
}: CellProps<DomainRowData>): JSX.Element => (
  <DomainAutoSendRequestToggle domainId={id} initialStatus={autoSendRequestEnabled} />
)
