export class WorkspaceSettingsSerializer {
  settings: { [key: string]: any }
  workspaceId: string

  constructor(settings: WorkspaceSettings, workspaceId: string) {
    this.settings = settings
    this.workspaceId = workspaceId
  }

  updateParams() {
    return {
      workspaceId: this.workspaceId,
      ...this._prepareBody()
    }
  }

  private _prepareBody() {
    const body: Partial<WorkspaceSettings> = {}

    this._rawParams().forEach((paramName) => (body[paramName] = this.settings[paramName]))

    return body
  }

  private _rawParams(): Array<keyof WorkspaceSettings> {
    return ['ybApsPubId']
  }
}
