import React from 'react'

import { InviteUserPage, UsersListPage } from 'pages/oss/Users'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { Product } from 'webapp/constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'

export const UserRoutes = [
  <LayoutRoute
    key='invite-user'
    product={Product.OSS}
    path={routesBuilder.oss.users.invite}
    exact
    component={InviteUserPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='list-users'
    product={Product.OSS}
    path={routesBuilder.oss.users.root}
    exact
    component={UsersListPage}
  />
]
