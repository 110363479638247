import React from 'react'
import Slider from 'react-slick'
import { useFormikContext } from 'formik'

import avatars from 'utilities/profile/avatars'
import { Image } from 'components/Image'

export const AvatarSlider: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<User>()

  const onSwipeHandler = (index: number) => {
    setFieldValue('avatar', index + 1)
  }

  return (
    <div className='avatar-slider'>
      <Slider dots={true} initialSlide={values.avatar - 1} afterChange={onSwipeHandler}>
        {avatars.map((avatar, i) => (
          <div key={i} className={`avatar-${i + 1}`}>
            <Image src={avatar} alt={`Avatar ${i + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  )
}
