import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'

import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'

import GetReport from 'webapp/gql/queries/reports/GetReport.gql'
import GenerateAdManagerReport from 'webapp/gql/mutations/reports/GenerateAdManagerReport.gql'
import { ReportResultsDataSerializer } from 'serializers/ReportResultsDataSerializer'
import { routesBuilder } from 'utilities/routesBuilder'

type RefreshTokenType = string | string[] | undefined

export const useGetAdManagerReport = (refreshToken: RefreshTokenType, currencyCode: string) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEditReport, setIsEditReport] = useState(false)
  const [reportFormData, setReportFormData] = useState<ReportFormType | undefined>()
  const history = useHistory()

  const { createNotifier } = useContext(NotificationContext) as Notification
  const reportDataSerializer = new ReportResultsDataSerializer()

  const [getReport, { stopPolling }] = useLazyQuery(GetReport, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ adManagerReport }) => getReportSuccessHandler(adManagerReport),
    onError: (error) => createNotifier(error.message, NotificationType.ERROR, 10)
  })

  const getReportSuccessHandler = (adManagerReport: AdManagerReport) => {
    const { status, data, failureReason } = adManagerReport

    switch (status) {
      case 'success':
        stopPolling && stopPolling()
        history.push(routesBuilder.quickwrap.performance.reportingResults, prepareReportData(data))
        setIsSubmitting(false)
        setIsEditReport(false)
        break
      case 'failure':
        stopPolling && stopPolling()
        setIsSubmitting(false)
        setIsEditReport(false)
        createNotifier(failureReason, NotificationType.ERROR, 10)
        break
    }
  }

  const prepareReportData = (data: Array<ReportData>) => {
    reportDataSerializer.setData(data)

    return {
      reportData: reportDataSerializer.results,
      reportFormData: Object.assign({}, reportFormData, { timeGenerated: new Date(), refreshToken }),
      currencyCode
    }
  }

  const [generateAdManagerReport] = useMutation(GenerateAdManagerReport, {
    onCompleted: ({ generateAdManagerReport }) => {
      const adManagerReportId = generateAdManagerReport.adManagerReport.id
      getReport({ variables: { adManagerReportId } })
    }
  })

  return {
    isSubmitting,
    setIsSubmitting,
    setReportFormData,
    isEditReport,
    setIsEditReport,
    generateAdManagerReport
  }
}
