import React from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
}

export const InfoBar: React.FC<Props> = ({ className, children }) => {
  const containerClass = classNames('info-bar', className)
  return <div className={containerClass}>{children}</div>
}
