import { ApolloClient, from, InMemoryCache, QueryOptions } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'

import { apiHeaders } from 'utilities/apiHeaders'
import jwtToken from 'utilities/jwtToken'

import { customHistory as history } from 'utilities/history'

const NO_CACHE: Pick<QueryOptions, 'fetchPolicy'> = {
  fetchPolicy: 'no-cache'
}

const NETWORK_ONLY_CACHE: Pick<QueryOptions, 'fetchPolicy'> = {
  fetchPolicy: 'network-only'
}

const authLink = setContext((_, { headers }) => {
  const token = jwtToken.token

  return {
    headers: {
      ...headers,
      ...apiHeaders,
      authorization: token.length > 0 ? `Bearer ${token}` : ''
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, extensions }) => {
      switch (extensions?.code) {
        case 'NOT FOUND':
          history.replace('/not_found')
          break
        case 'FORBIDDEN':
          history.replace('/unauthorized')
          break
        case 'UNAUTHORIZED':
          break
        default:
          console.log(`[GraphQL error]: Message: ${message}, Location: ${extensions?.code}`)
      }
    })
  if (networkError) {
    history.push('/error')
  }
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, createUploadLink()])
})

export { apolloClient, NETWORK_ONLY_CACHE, NO_CACHE }
