import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid } from '@material-ui/core'
import { PropsValue } from 'react-select'
import { useField, FieldConfig } from 'formik'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { bidderSelectFilterOption } from 'utilities/bidderSelectFilterOption'
import { Errors, ErrorUtils } from 'utilities/errorUtils'
import { ParamForm, ParamFormSection } from 'components/Form/ParamFormSection'
import { ParamLogo } from 'components/Form/ParamFormSection/ParamLogo'
import { PrebidModuleKind } from 'webapp/constants/PrebidModuleKind'
import { RequestPrebidModulesContainer } from './RequestPrebidModulesContainer'

import ValidatePrebidModuleParams from 'gql/mutations/prebidModules/ValidatePrebidModuleParams.gql'

interface Props {
  errorType: string
  errors: Errors
  field: FieldConfig
  formId: string
  handleIsDirty: (dirty: boolean) => void
  hideRequestModules?: boolean
  isDemand: boolean
  isDirty: boolean
  moduleKind: PrebidModuleKind
  prebidModules: Array<PrebidModule>
  setErrors: (errors: ErrorUtils) => void
  translations: string
}

export const FormikPrebidModulesContainer: React.FC<Props> = ({
  errorType,
  errors,
  formId,
  handleIsDirty,
  hideRequestModules,
  isDemand,
  isDirty,
  moduleKind,
  prebidModules,
  setErrors,
  translations,
  ...other
}) => {
  const { t } = useTranslation('setup')
  const saveErrorMessage = t(`${translations}.form.saveError`)
  const [field, , helpers] = useField(other.field.name)
  const [newPrebidModule, setNewPrebidModule] = useState<SelectOption | null>(null)

  const availablePrebidModules = useMemo(() => {
    const selectedPrebidModules = (field.value || [])
      .filter((module: Params) => !module._destroy)
      .map((module: Params) => module.moduleId)

    return prebidModules.filter((module: PrebidModule) => !selectedPrebidModules.includes(module.id))
  }, [prebidModules, field])

  useEffect(() => {
    setNewPrebidModule(null)
  }, [availablePrebidModules.length])

  const updateModuleParams = useCallback(
    (newModuleParams: PrebidModuleParams) => {
      helpers.setValue(
        field.value.map((moduleParam: PrebidModuleParams) =>
          moduleParam.id === newModuleParams.moduleId ? newModuleParams : moduleParam
        )
      )
    },
    [helpers, field]
  )

  const addModuleParam = useCallback(
    (newModuleParams: PrebidModuleParams) => {
      handleIsDirty(true)
      helpers.setValue((field.value || []).concat([newModuleParams]))
    },
    [helpers, field, handleIsDirty]
  )

  const newParams = (params: Array<string>) =>
    params.reduce(
      (acc, next) => ({
        ...acc,
        [next]: ''
      }),
      {}
    )

  const onPrebidModuleParamsHandler = (event: React.MouseEvent) => {
    event.preventDefault()

    if (newPrebidModule?.value) {
      const module = availablePrebidModules.find((module: PrebidModule) => module.id === newPrebidModule.value)
      const hasParams = isDemand || module?.paramsNames.length === 0
      const moduleParam = field.value?.find((moduleParam: PrebidModuleParams) => moduleParam.moduleId === module?.id)
      if (moduleParam) {
        delete moduleParam._destroy
        moduleParam.params = {}
        moduleParam.saved = hasParams
        handleIsDirty(true)
        updateModuleParams(moduleParam)
      } else {
        if (module) {
          const params = { ...newParams(module.paramsNames) }
          addModuleParam({ moduleId: module.id, name: module.name, params, saved: hasParams })
        }
      }
      setNewPrebidModule(null)
    }
  }

  const changeNewPrebidModule = (item: PropsValue<SelectOption>) => {
    setNewPrebidModule(item as SelectOption)
  }

  const getItem = (module: PrebidModuleParams) => {
    const mod = prebidModules.find((mod) => mod.id === module.moduleId) || ({} as PrebidModule)
    return {
      ...mod,
      _id: mod.id,
      validator: mod.adapter
    }
  }

  const onSaveHandler = (index: number) => {
    errors.removeError([errorType, String(index)], saveErrorMessage)
    setErrors(new ErrorUtils(errors.raw))
  }

  const onChangeHandler = (saved: boolean, index: number, moduleId?: string, values?: Params) => {
    const moduleParam = field.value.find((param: PrebidModuleParams) => param.moduleId === moduleId)
    if (moduleParam) {
      if (values) moduleParam.params = values
      moduleParam.saved = saved
      handleIsDirty(true)
      updateModuleParams(moduleParam)
    }

    saved && onSaveHandler(index)
  }

  const onDeleteHandler = (index: number) => {
    errors.deleteAt(index, errorType)
    setErrors(new ErrorUtils(errors.raw))
  }

  const deletePrebidModuleParams = (newModuleParam: PrebidModuleParams) => {
    helpers.setValue(field.value.filter((module: PrebidModuleParams) => module.moduleId !== newModuleParam.moduleId))
  }

  const onDeleteModuleParamsHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    moduleId: string,
    index: number
  ) => {
    event.preventDefault()

    const moduleParam = field.value.find((param: PrebidModuleParams) => param.moduleId === moduleId)
    if (moduleParam) {
      moduleParam.saved = true
      handleIsDirty(true)
      moduleParam._destroy = moduleParam.id
      moduleParam.id ? updateModuleParams(moduleParam) : deletePrebidModuleParams(moduleParam)
    }

    onDeleteHandler(index)
  }

  const [validatePrebidModuleParams] = useMutation(ValidatePrebidModuleParams)
  const onValidateParams = (id: string, params: Params) =>
    validatePrebidModuleParams({ variables: { input: { prebidModuleId: id, params } } })

  const renderParamForm = () =>
    (field.value || [])
      .filter((module: PrebidModuleParams) => !module._destroy)
      .map((module: PrebidModuleParams, index: number) => (
        <ParamForm
          key={module.moduleId}
          dirty={isDirty}
          item={getItem(module)}
          isDemand={isDemand}
          onChangeHandler={(saved, moduleId, values) => onChangeHandler(saved, index, moduleId, values)}
          onDeleteHandler={(event) => onDeleteModuleParamsHandler(event, module.moduleId as string, index)}
          params={module.params && Object.keys(module.params).length > 0 ? module.params : undefined}
          formErrors={errors.errorsFor([errorType, String(index)])}
          validateParams={onValidateParams}
          translation='prebidModules'
          isSaved={Boolean(module.saved)}
        />
      ))

  const availableOptions = availablePrebidModules.map((module: PrebidModule) => ({
    label: (
      <ParamLogo
        fileName={module.adapter}
        placeholder={module.name}
        className='bidders-select-logo prebid-module-select-logo'
      />
    ),
    value: module.id
  }))

  const disableSaveButton = () => false

  const renderRequestContainer = hideRequestModules
    ? undefined
    : () => <RequestPrebidModulesContainer kind={moduleKind} />

  return (
    <Grid item xs={12}>
      {prebidModules && (
        <Grid item lg={10} md={12} xs={12}>
          <ParamFormSection
            availableOptions={availableOptions}
            disabledSaveButton={disableSaveButton()}
            filterOption={bidderSelectFilterOption}
            formId={formId}
            onAddHandler={onPrebidModuleParamsHandler}
            onChangeHandler={changeNewPrebidModule}
            renderParamForm={renderParamForm}
            renderRequestContainer={renderRequestContainer}
            selectedItem={newPrebidModule}
            translationRoot='setup'
            translations={`${translations}.form.addPrebidModule`}
          />
        </Grid>
      )}
    </Grid>
  )
}
