import React from 'react'
import { Column, Row } from 'react-table'
import { useHistory } from 'react-router-dom'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { columnsConfig } from './columnsConfig'
import { DataStudioLinkRowData } from './DataStudioLinkRowData'
import { QueryTable } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'

import DataStudioLinks from 'gql/queries/productConnections/DataStudioLinks.gql'

const GLOBAL_FILTER = [{ by: 'data_studio_url', operator: 'is_not_null', values: [] }]

export const DataStudioLinksTable: React.FC = () => {
  const history = useHistory()

  const mapper = (node: ProductConnection) => new DataStudioLinkRowData(node)
  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    const productConnection = row.original.productConnection as unknown as ProductConnection
    history.push(routesBuilder.oss.performance.dataStudioLinks.edit(productConnection.id))
  }

  return (
    <QueryTable<DataStudioLinkRowData>
      className='data-studio-links-table'
      collectionName={CollectionNames.ProductConnections}
      columnsConfig={columnsConfig as Column[]}
      dataQuery={DataStudioLinks}
      globalFilter={GLOBAL_FILTER}
      mapper={mapper}
      onRowClick={openEditingPage}
    />
  )
}
