import i18n from 'webapp/i18n'

export class DomainRowData {
  public hasActiveAbTest: boolean

  constructor(public domain: VersionedDomain) {
    this.hasActiveAbTest = domain.abTests.some((test) => test.active)
  }

  get id(): string {
    return this.domain.id
  }

  get name(): string {
    return this.domain.name
  }

  get activeVersion(): string {
    return this.hasActiveAbTest ? i18n.t('inventory:domains.tableBody.abTestRunning') : this.domain.activeVersion.name
  }

  get live(): boolean {
    return this.domain.live
  }

  get workspace(): string {
    return this.domain.workspace.name
  }

  get prebidDeviceCount(): number | boolean {
    return !this.hasActiveAbTest && this.domain.prebidDeviceCount
  }

  get pagesCount(): number {
    return this.domain.activeVersion.pagesCount
  }

  get adUnitsCount(): number {
    return this.domain.activeVersion.adUnitsCount
  }

  get updatedAt(): Date {
    return this.domain.updatedAt
  }

  get qwEnabled(): boolean {
    return this.domain.qwEnabled
  }

  get pgEnabled(): boolean {
    return this.domain.pgEnabled
  }

  get reviveEnabled(): boolean {
    return this.domain.reviveEnabled
  }

  get vbEnabled(): boolean {
    return this.domain.vbEnabled
  }

  get frbEnabled(): boolean {
    return this.domain.frbEnabled
  }

  get wrapperVersion(): string {
    return this.domain.wrapperVersion
  }

  get autoSendRequestEnabled(): boolean {
    return this.domain.autoSendRequestEnabled
  }
}
