import React, { useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { LoadingContainer } from 'components/LoadingContainer'
import { YbDemandTable } from 'components/Bidders/YbDemandTable'

import DemandStatusQuery from 'gql/queries/yieldbird_demand/DemandStatus.gql'

interface Props {
  demandBidderSettings: BidderSetting[]
}

export const YieldbirdDemandStatusContainer: React.FC<Props> = ({ demandBidderSettings }) => {
  const { t } = useTranslation('setup')
  const { data, loading, refetch } = useQuery(DemandStatusQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
    } else {
      refetch()
    }
  }, [demandBidderSettings, refetch])

  return (
    <>
      {demandBidderSettings.length > 0 && (
        <div className='yb-demand-status-container'>
          <div className='yb-demand-bidders__subheading yb-demand-bidders__subheading--with-margin'>
            {t('yieldbirdDemand.status.title')}
          </div>
          <LoadingContainer loading={loading}>
            <YbDemandTable data={data && data.demandStatus} demandBidderSettings={demandBidderSettings} />
          </LoadingContainer>
        </div>
      )}
    </>
  )
}
