import React from 'react'

import { getProductName } from 'utilities/products/utils'
import { getProductSettings } from 'utilities/products/settings'

type LogoProps = {
  className?: string
  product: ProductConnection['product']
}

export const Logo: React.FC<LogoProps> = ({ className, product }) => (
  <img src={getProductSettings(product).logoIcon} className={className} alt={getProductName(product)} />
)
