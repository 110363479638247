import React, { useContext, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Formik, Field, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { PasswordFormFactory } from './formSchema'
import { StatusForm } from 'components/Form'

import ChangePasswordMutation from 'gql/mutations/profile/ChangePassword.gql'

export const PasswordForm: React.FC = () => {
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [data, setData] = useState(PasswordFormFactory())

  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('profile')

  const [ChangePassword, { loading: isSubmitting }] = useMutation(ChangePasswordMutation, {
    onCompleted: ({ changePassword: { profile, errors } }) => {
      if (profile) {
        setData(PasswordFormFactory())
        createNotifier(t('passwordForm.successMessage'), NotificationType.SUCCESS)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmitHandler = (values: ChangePasswordData, actions: FormikHelpers<ChangePasswordData>) => {
    ChangePassword({ variables: { input: values } }).then(({ data }) => {
      // clear password inputs after successful update
      data?.changePassword?.profile && actions.resetForm()
    })
  }

  return (
    <div className='password-form'>
      <Formik initialValues={data} onSubmit={onSubmitHandler} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='oss-password' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={6}>
                <Field
                  id='current-password'
                  name='currentPassword'
                  type='password'
                  label={t('passwordForm.currentPassword.label')}
                  component={FormikInput}
                  errors={errors.errorsFor('currentPassword')}
                />
                <Field
                  id='password'
                  name='password'
                  type='password'
                  label={t('passwordForm.password.label')}
                  component={FormikInput}
                  errors={errors.errorsFor('password')}
                />
                <Field
                  id='password-confirmation'
                  name='passwordConfirmation'
                  type='password'
                  label={t('passwordForm.passwordConfirmation.label')}
                  component={FormikInput}
                  errors={errors.errorsFor('passwordConfirmation')}
                />
              </Grid>
            </Grid>

            <Button
              disabled={isSubmitting || !dirty}
              type={ButtonType.Primary}
              theme={ButtonTheme.Blue}
              buttonType='submit'
            >
              {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
