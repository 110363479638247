import React from 'react'
import { Field } from 'formik'

import { FormikCheckboxContainer } from 'containers/FormikContainers'

interface Props {
  id: string
  disabled?: boolean
}

export const CheckBoxCell: React.FC<Props> = ({ id, disabled }) => (
  <Field id={id} name={id} disabled={disabled} component={FormikCheckboxContainer} />
)
