import React, { ReactElement } from 'react'
import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { ButtonType } from 'components/Button'
import { DateCell } from 'components/Table'
import { Edit } from 'icons/Edit'
import { GamConnectButton } from 'components/oss/Gam/GamConnectButton'
import { RetryCell } from './RetryCell'
import { oauthGamCallbackUrl } from 'webapp/constants/oauthCallbackUrl'
import { StatusCell } from './StatusCell'

const isAddNativeDisabled = (network: ConnectedNetwork) => {
  const isNative = Boolean(localStorage.getItem('native'))
  const editedNetwork = JSON.parse(localStorage.getItem('network') ?? 'null')
  const selectedNetwork = editedNetwork?.id === network.id

  return isNative && selectedNetwork
}

export const columnsConfig: Column<ConnectedNetwork>[] = [
  {
    Header: (): ReactElement => i18n.t('workspaces:connectedNetworks.table.networkCode'),
    accessor: 'networkCode',
    disableSortBy: true
  },
  {
    Header: (): ReactElement => i18n.t('workspaces:connectedNetworks.table.dateOfConnection'),
    accessor: 'createdAt',
    disableSortBy: true,
    Cell: ({ value }): ReactElement => <DateCell value={value} />
  },
  {
    Header: (): ReactElement => i18n.t('workspaces:connectedNetworks.table.currency'),
    accessor: 'currency',
    disableSortBy: true
  },
  {
    Header: (): ReactElement => i18n.t('workspaces:connectedNetworks.table.bannerStatus'),
    accessor: 'bannerStatus',
    disableSortBy: true,
    Cell: ({ row }: CellProps<ConnectedNetwork>): ReactElement => <StatusCell row={row} type={'bannerStatus'} />
  },
  {
    Header: (): ReactElement => i18n.t('workspaces:connectedNetworks.table.nativeStatus'),
    accessor: 'nativeStatus',
    disableSortBy: true,
    Cell: ({ row }: CellProps<ConnectedNetwork>): ReactElement =>
      row.original.nativeStatus === 'passive' ? (
        <GamConnectButton
          connectedNetwork={row.original}
          disabled={isAddNativeDisabled(row.original)}
          native={true}
          oauthCallbackUrl={oauthGamCallbackUrl}
          translateKey={'connector.button.add'}
          type={ButtonType.Secondary}
        />
      ) : (
        <StatusCell row={row} type={'nativeStatus'} />
      )
  },
  {
    id: 'actions',
    disableSortBy: true,
    Cell: ({ row }: CellProps<ConnectedNetwork>) => (
      <div className='d-flex'>
        <RetryCell row={row} />

        <GamConnectButton
          className='cell--actions__button cell--actions__button--icon'
          connectedNetwork={row.original}
          oauthCallbackUrl={oauthGamCallbackUrl}
          translateKey={'connector.button.add'}
        >
          <Edit />
        </GamConnectButton>
      </div>
    )
  }
]
