import React from 'react'

export const Edit: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16.54' height='16.541' viewBox='0 0 16.54 16.541'>
      <g id='edit' transform='translate(0.2 0.341)'>
        <path
          id='Path_9216'
          data-name='Path 9216'
          className='cls-1'
          d='M14.62,47.493a.394.394,0,0,0-.394.393v3.488a1.182,1.182,0,0,1-1.183,1.179H1.972A1.182,1.182,0,0,1,.789,51.375V41.136a1.182,1.182,0,0,1,1.183-1.179h3.5a.393.393,0,1,0,0-.786h-3.5A1.971,1.971,0,0,0,0,41.136V51.375a1.971,1.971,0,0,0,1.972,1.964H13.042a1.971,1.971,0,0,0,1.972-1.964V47.886A.394.394,0,0,0,14.62,47.493Zm0,0'
          transform='translate(0 -37.339)'
        />
        <path
          id='Path_9217'
          data-name='Path 9217'
          className='cls-1'
          d='M120.833.882a2.12,2.12,0,0,0-3,0l-8.4,8.4a.471.471,0,0,0-.121.207l-1.1,3.989a.471.471,0,0,0,.58.58l3.989-1.105a.471.471,0,0,0,.207-.121l8.4-8.4a2.122,2.122,0,0,0,0-3ZM110.459,9.59l6.877-6.877,2.218,2.218-6.877,6.877Zm-.443.889,1.772,1.772-2.451.679Zm10.7-6.714-.5.5L118,2.047l.5-.5a1.177,1.177,0,0,1,1.665,0l.553.553A1.179,1.179,0,0,1,120.72,3.766Zm0,0'
          transform='translate(-106.006 -0.261)'
        />
      </g>
    </svg>
  )
}
