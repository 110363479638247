import { gql } from '@apollo/client'
import { DOMAIN_FRAGMENT } from 'gql/fragments/domains/DomainFragment'

export const DOMAIN = gql`
  ${DOMAIN_FRAGMENT}

  query Domain($domainId: ID!) {
    domain(domainId: $domainId) {
      ...DomainFragment
    }
  }
`
