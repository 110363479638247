import React from 'react'
import { Column, Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { ButtonTheme, ButtonType } from 'components/Button'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { LinkButton } from 'components/LinkButton'
import { QueryTable } from 'containers/QueryTable'
import { columnsConfig } from 'components/oss/Finance/DataStudioLinks/Table/columnsConfig'
import { routesBuilder } from 'utilities/routesBuilder'

import WorkspaceListQuery from 'gql/queries/workspaces/WorkspaceList.gql'

export const ListPage: React.FC = () => {
  const { t } = useTranslation('dataStudio')

  const history = useHistory()
  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    history.push(routesBuilder.oss.finance.dataStudioLinks.edit(row.original.id as string))
  }

  return (
    <Box className='data-studio-links' list={true}>
      <BoxHeader
        title={t('links.list.title')}
        containerClassName='box-header__container'
        rowClassName='header__row--width-100'
      >
        <LinkButton
          type={ButtonType.Primary}
          theme={ButtonTheme.Blue}
          to={routesBuilder.oss.finance.dataStudioLinks.new}
        >
          {t('common:buttons.addNew')}
        </LinkButton>
      </BoxHeader>

      <QueryTable<Workspace>
        className='data-studio-links-table'
        collectionName={CollectionNames.Workspaces}
        columnsConfig={columnsConfig as Column[]}
        dataQuery={WorkspaceListQuery}
        globalFilter={[{ by: 'data_studio_url', values: [], operator: 'is_not_null' }]}
        onRowClick={openEditingPage}
      />
    </Box>
  )
}
