import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TableState, UseTableState } from './types'

export const useTableState = (): UseTableState => {
  const location = useLocation<TableState>()
  const initialPageIndex = location?.state?.pageIndex >= 0 ? location.state.pageIndex : 0
  const [pageIndex, setPageIndex] = useState<number>(initialPageIndex)

  return {
    pageIndex,
    setPageIndex
  }
}
