import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from 'react-tooltip-lite'

import { Image } from 'components/Image'
import { RadioButton } from 'components/Form'
import { SortColumn, SortConfig, SortDirection } from './columnsConfig'
import { useOnClickOutside } from 'utilities/useOnClickOutside'

import SortHorizontal from 'images/sort_horizontal.svg'

type BiddersOnDomainCellType = {
  sortConfig: SortConfig
  setSortConfig: (sortConfig: SortConfig) => void
}

export const BiddersOnDomainCell = ({ sortConfig, setSortConfig }: BiddersOnDomainCellType): JSX.Element => {
  const OPTIONS = {
    nameAsc: { column: SortColumn.NAME, direction: SortDirection.ASC },
    nameDesc: { column: SortColumn.NAME, direction: SortDirection.DESC },
    pluggedAsc: { column: SortColumn.AD_UNITS_PLUGGED, direction: SortDirection.ASC },
    pluggedDesc: { column: SortColumn.AD_UNITS_PLUGGED, direction: SortDirection.DESC }
  }

  const filterRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('setup')
  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(filterRef, () => setIsOpen(false))

  const onChange = (val: string) => {
    setSortConfig(OPTIONS[val as keyof typeof OPTIONS])
  }

  return (
    <div>
      {t('bidders.inventoryConnection.dataTable.biddersOnDomain')}
      <Tooltip
        className='BiddersOnDomainCell__tooltip'
        tipContentClassName='BiddersOnDomainCell'
        direction='right'
        arrow={false}
        isOpen={isOpen}
        zIndex={1301}
        content={
          <div ref={filterRef}>
            {Object.keys(OPTIONS).map((option) => (
              <RadioButton
                key={option}
                name='inventorySort'
                id={`inventory-sort-${option}`}
                checked={
                  sortConfig.column === OPTIONS[option as keyof typeof OPTIONS].column &&
                  sortConfig.direction === OPTIONS[option as keyof typeof OPTIONS].direction
                }
                label={t(`bidders.inventoryConnection.dataTable.columnSort.${option}`)}
                value={option}
                onChange={onChange}
              />
            ))}
          </div>
        }
      >
        <span className='BiddersOnDomainCell__icon' onClick={() => setIsOpen(true)}>
          <Image src={SortHorizontal} alt='sorting icon' className='qw-table__icon--sort' />
        </span>
      </Tooltip>
    </div>
  )
}
