export class WorkspaceSerializer {
  constructor(private workspace: WorkspaceFormData, private isSuperuser: boolean) {}

  createParams() {
    return {
      crmId: this.workspace.crmId,
      csManagerId: this.workspace.csManagerId?.value ?? null,
      name: this.workspace.name,
      pgGlobalTargetingEnabled: this.workspace.pgGlobalTargetingEnabled
    }
  }

  updateParams() {
    return this.isSuperuser ? this.superuserUpdateParams() : this.publisherUpdateParams()
  }

  private publisherUpdateParams = () => ({
    amazonAsi: this.workspace.amazonAsi,
    name: this.workspace.name
  })

  private superuserUpdateParams() {
    return {
      ...this.publisherUpdateParams(),
      crmId: this.workspace.crmId,
      csManagerId: this.workspace.csManagerId?.value ?? null,
      pgGlobalTargetingEnabled: this.workspace.pgGlobalTargetingEnabled,
      workspaceId: this.workspace.id
    }
  }
}
