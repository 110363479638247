import React, { MouseEvent, useCallback, useMemo, useState } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

import { CroppedModal } from './CroppedModal'
import { ExpandIcon } from 'icons/ExpandIcon'

interface Props {
  stopPropagation?: boolean
  textElements: Array<string> | string
}

interface EllipsisState {
  clamped: boolean
}

const EMPTY_VALUE = '-'

export const CroppedCell: React.FC<Props> = ({ stopPropagation = true, textElements }) => {
  const [isClamped, setIsClamped] = useState(true)
  const [isVisible, setIsVisible] = useState(false)

  const [text, basedOn] = useMemo(() => {
    if (textElements.length === 0) {
      return [EMPTY_VALUE, 'words']
    }
    if (textElements instanceof Array) {
      return [textElements.join(', '), 'words']
    }
    return [textElements, 'letters']
  }, [textElements])

  const onReflowHandler = (state: EllipsisState) => {
    setIsClamped(state.clamped)
  }

  const onClick = useCallback(
    (event: MouseEvent) => {
      if (stopPropagation) {
        event.stopPropagation()
      }
    },
    [stopPropagation]
  )

  return (
    <div className='cell--ellipsis' onClick={onClick}>
      <LinesEllipsis text={text} maxLine='2' ellipsis='...' basedOn={basedOn} onReflow={onReflowHandler} />

      {isClamped && <ExpandIcon onClickHandler={() => setIsVisible(true)} alt='expand' classNames='expand-icon' />}

      <CroppedModal content={text} isVisible={isVisible} setIsVisible={setIsVisible} />
    </div>
  )
}
