import React, { ChangeEvent, useMemo } from 'react'
import classNames from 'classnames'

import { BetaChip } from 'components/Chips/BetaChip'
import { Label, Tooltip, ErrorMessages } from 'components/Form'
import { ToggleState } from './enums'

export enum TooltipTogglePlacement {
  Inside = 'inside',
  Outside = 'outside'
}

interface Props {
  id: string
  name: string
  checked: boolean
  disabled?: boolean
  disabledSeamlessly?: boolean
  className?: string
  errors?: Array<string>
  isBeta?: boolean
  label?: string
  labelClassName?: string
  labelFalse?: string
  labelStyle?: string
  labelTrue?: string
  tooltipContent?: string
  tooltipTogglePlacement?: TooltipTogglePlacement
  warningContent?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const ToggleSwitch: React.FC<Props> = ({
  name,
  disabled,
  disabledSeamlessly,
  id,
  checked,
  className,
  errors,
  isBeta,
  label,
  labelClassName,
  labelFalse,
  labelStyle,
  labelTrue,
  tooltipContent,
  tooltipTogglePlacement = TooltipTogglePlacement.Outside,
  warningContent,
  onChange
}) => {
  const classString = useMemo(() => {
    return classNames('qw-toggle-switch', className, {
      'qw-toggle-switch--checked': checked,
      'qw-toggle-switch--disabled': disabled
    })
  }, [checked, disabled, className])

  const labelClass = useMemo(
    () => classNames('qw-toggle-switch__label', labelClassName, labelStyle && `qw-toggle-switch__label--${labelStyle}`),
    [labelStyle]
  )

  const tooltip = (tooltipContent || warningContent) && (
    <div className='qw-toggle-switch__tooltips'>
      {tooltipContent && <Tooltip title={tooltipContent} />}
      {warningContent && <Tooltip title={warningContent} warning={true} />}
    </div>
  )

  return (
    <div className='qw-toggle-switch-container'>
      <div className={classString}>
        <input
          type='checkbox'
          name={name}
          id={id}
          className='qw-toggle-switch__input'
          disabled={disabled || disabledSeamlessly}
          checked={checked}
          onChange={onChange}
        />
        <Label className={labelClass} htmlFor={id}>
          {label && <span className='qw-toggle-switch__label-text'>{label}</span>}
          {tooltipTogglePlacement === TooltipTogglePlacement.Inside && tooltip}
          {isBeta && <BetaChip />}
          <div className='qw-toggle-switch__checkmark'>
            <span
              className='qw-toggle-switch__inner'
              data-yes={labelTrue ?? ToggleState.ON}
              data-no={labelFalse ?? ToggleState.OFF}
            />
            <span className='qw-toggle-switch__switch' />
          </div>
        </Label>
        {tooltipTogglePlacement === TooltipTogglePlacement.Outside && tooltip}
      </div>
      <ErrorMessages errors={errors} />
    </div>
  )
}
