import { useEffect, useState } from 'react'

import { filterStore } from 'utilities/filters/filterStore'
import { processQueryParams, QueryParams } from './queryTableParams'

export function useQueryTableParams(
  collectionName: string,
  searchableFields: string[] | undefined,
  pageSize: number,
  globalFilter?: AdvancedFilter[],
  pageIndex?: number
): QueryParams {
  const getCurrentParams = () => processQueryParams(collectionName, searchableFields, pageSize, globalFilter, pageIndex)
  const [params, setParams] = useState(getCurrentParams())

  useEffect(() => {
    const callback = () => setParams(getCurrentParams())
    filterStore.subscribe(collectionName, callback)

    return () => {
      filterStore.unsubscribe(callback)
      filterStore.resetFilter(collectionName)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setParams(getCurrentParams())
  }, [searchableFields]) // eslint-disable-line react-hooks/exhaustive-deps

  return params
}
