import React from 'react'

import { Trial } from 'components/Layout/ProductStatusBar/Trial'
import { UnlimitedPlanInProgress } from 'components/Layout/ProductStatusBar/UnlimitedPlanInProgress'
import { useCurrentProductConnection } from 'utilities/products/current'
import { ProductStatus } from 'webapp/constants/ProductStatus'

export const ProductStatusBar: React.FC = () => {
  const currentProductConnection = useCurrentProductConnection()

  switch (currentProductConnection?.status) {
    case ProductStatus.UnlimitedPlanInProgress:
      return <UnlimitedPlanInProgress />
    case ProductStatus.Trial:
      return <Trial productConnection={currentProductConnection} />
    default:
      return null
  }
}
