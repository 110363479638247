import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'webapp/gql/fragments/common'

const PAYLOAD_TYPE = 'UpdatePagePayload'

export const UPDATE_PAGE = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation UpdatePage($input: UpdatePageInput!) {
    updatePage(input: $input) {
      page {
        id
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
