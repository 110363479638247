import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Modal } from 'components/Modals'

type InfoBarConfirmModalType = {
  confirmModalVisible: boolean
  confirmWithAllDomainsHandler: () => void
  disabled: boolean
  domains: Domain[]
  setConfirmModalVisible: (value: boolean) => void
  showApplicableDomainsModal: () => void
}

export const InfoBarConfirmModal = ({
  confirmModalVisible,
  disabled,
  domains,
  confirmWithAllDomainsHandler,
  setConfirmModalVisible,
  showApplicableDomainsModal
}: InfoBarConfirmModalType): JSX.Element => {
  const { t } = useTranslation('performance')

  const cancelHandler = () => {
    setConfirmModalVisible(false)
  }

  const showApplicableDomainsModalHandler = () => {
    setConfirmModalVisible(false)
    showApplicableDomainsModal()
  }

  return (
    <Modal
      visible={confirmModalVisible}
      setVisible={setConfirmModalVisible}
      title={t('prebidAnalytics.infoBar.confirmationModal.title')}
    >
      <>
        <p>{t('prebidAnalytics.infoBar.confirmationModal.content')}</p>
        <div>
          <Button
            className='confirmation__modal__button'
            disabled={disabled}
            onClickHandler={cancelHandler}
            theme={ButtonTheme.BlueReverse}
            type={ButtonType.Primary}
          >
            {t('prebidAnalytics.infoBar.confirmationModal.cancel')}
          </Button>
          <Button
            className='confirmation__modal__button'
            disabled={disabled}
            onClickHandler={confirmWithAllDomainsHandler}
            theme={ButtonTheme.Blue}
            type={ButtonType.Primary}
          >
            {t('prebidAnalytics.infoBar.confirmationModal.allDomains')}
          </Button>
          {domains.length > 0 && (
            <Button
              className='confirmation__modal__button'
              disabled={disabled}
              onClickHandler={showApplicableDomainsModalHandler}
              theme={ButtonTheme.White}
              type={ButtonType.Primary}
            >
              {t('prebidAnalytics.infoBar.confirmationModal.specifiedDomains')}
            </Button>
          )}
        </div>
      </>
    </Modal>
  )
}
