import React, { useCallback, useMemo, useState } from 'react'
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import { Select } from 'components/Form'

interface Props {
  buttonContent: string
  errors: Errors
  generalSizeType: boolean
  label: string
  name: string
  onRemoveHandler?: (index: number) => void
  options: Array<SelectOption>
  placeholder: string
  schemaBuilder?: () => { [key: string]: any }
  tooltipContent: string
}

export const CollectionSelect: React.FC<Props> = ({
  buttonContent,
  children,
  label,
  name,
  onRemoveHandler,
  options,
  placeholder,
  schemaBuilder,
  tooltipContent
}) => {
  const { values } = useFormikContext<{ [key: string]: any }>()
  const [keyCounter, setKeyCounter] = useState(1)

  const [newItem, setNewItem] = useState<SelectOption | null>(null)
  const onChangeHandler = useCallback(
    (item) => {
      setNewItem(item)
    },
    [setNewItem]
  )
  const disabled = (): boolean => newItem === null

  const availableOptions = useMemo(() => {
    const selected = values[name]
      .filter((item: { [key: string]: any }) => !item._destroy)
      .map((item: { [key: string]: any }) => item.value)

    return options.filter((option: SelectOption) => !selected.includes(option.value))
  }, [options, values])

  const onAddHandler = useCallback(
    (arrayHelpers: ArrayHelpers) => {
      if (newItem?.value) {
        arrayHelpers.push(Object.assign({}, newItem, schemaBuilder?.()))
        setNewItem(null)
      }
    },
    [newItem, schemaBuilder, setNewItem]
  )

  const onDeleteHandler = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index)
    setKeyCounter((keyCounter) => keyCounter + 1)
    onRemoveHandler && onRemoveHandler(index)
  }

  return (
    <div className='collection-select'>
      <FieldArray
        name={name}
        render={(arrayHelpers: ArrayHelpers) => (
          <>
            {values[name].map((element: any, index: number) => (
              <div key={keyCounter * (index + 1)}>
                {React.cloneElement(children as React.ReactElement<any>, {
                  index: index,
                  onDeleteHandler: () => onDeleteHandler(arrayHelpers, index)
                })}
              </div>
            ))}

            <div className='collection-select__add'>
              <Select
                name={name}
                className='collection-select__select'
                label={label}
                onChangeHandler={onChangeHandler}
                options={availableOptions}
                placeholder={placeholder}
                selectedItem={newItem}
                tooltipContent={tooltipContent}
              />
              <Button
                buttonType='button'
                className='ml-20'
                disabled={disabled()}
                onClickHandler={() => onAddHandler(arrayHelpers)}
                theme={ButtonTheme.Blue}
                type={ButtonType.Primary}
              >
                {buttonContent}
              </Button>
            </div>
          </>
        )}
      />
    </div>
  )
}
