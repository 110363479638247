import React, { useState, useContext } from 'react'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AbilityAction } from 'utilities/security'
import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { Bin } from 'icons/Bin'
import { ConfirmationModal } from 'components/Modals'
import { Can } from 'webapp/context/AbilityContext'
import { NotificationType } from 'components/ToastNotifier'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'

import DeleteUser from 'gql/mutations/users/DeleteUser.gql'

interface Props {
  row: Row<User>
}

export const ActionsCell: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation('users')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

  const deleteHandler = () => setVisibleDeleteModal(true)
  const user = row.original
  const userName = user.fullName || user.email

  const [destroyUser] = useMutation(DeleteUser, {
    onCompleted: ({ destroyUser: { user, error } }) => {
      if (user) {
        createNotifier(t('modal.delete.notification.success', { user: userName }), NotificationType.SUCCESS)
        history.push(history.location)
      } else if (error) {
        createNotifier(t('modal.delete.notification.error'), NotificationType.ERROR)
      }
      setVisibleDeleteModal(false)
    }
  })

  const confirmHandler = () => destroyUser({ variables: { input: { userId: row.original.id } } })

  return (
    <div className='cell--actions cell--user-actions'>
      <Can I={AbilityAction.DELETE} this={user}>
        <Button
          type={ButtonType.SingleAction}
          theme={ButtonTheme.Blue}
          className='cell--actions__button cell--actions__button--icon'
          onClickHandler={deleteHandler}
          title={t('common:buttons.delete')}
        >
          <Bin />
        </Button>

        <ConfirmationModal
          visible={visibleDeleteModal}
          setVisible={setVisibleDeleteModal}
          title={t('modal.delete.confirm.title')}
          content={t('modal.delete.confirm.content', { user: userName })}
          confirmButtonText={t('common:buttons.delete')}
          cancelButtonText={t('common:buttons.cancel')}
          onConfirmHandler={confirmHandler}
        />
      </Can>
    </div>
  )
}
