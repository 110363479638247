import React from 'react'
import { Grid } from '@material-ui/core'
import { Formik, Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { StatusForm } from 'components/Form'

interface Props {
  settings: WorkspaceSettings
  errors: Errors
  isSubmitting: boolean
  onSubmit: (settings: WorkspaceSettings) => void
}

export const WorkspaceSettingsForm: React.FC<Props> = ({ settings, errors, isSubmitting, onSubmit }) => {
  const { t } = useTranslation('')

  return (
    <div className='workspace-form qw-form'>
      <Formik initialValues={settings} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='oss-workspace-settings' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={3} xs={6}>
                <Field
                  id='general-settings-yb-aps-pub-id'
                  name='ybApsPubId'
                  type='text'
                  label={t('workspaces:workspaceSettings.label')}
                  tooltipContent={t('workspaces:workspaceSettings.tooltip')}
                  component={FormikInput}
                  errors={errors.errorsFor('ybApsPubId')}
                  labelStyle='bold'
                />
              </Grid>
            </Grid>

            <Button
              buttonType='submit'
              className='qw-submit'
              disabled={isSubmitting || !dirty}
              theme={ButtonTheme.Blue}
              type={ButtonType.Primary}
            >
              {isSubmitting ? t('buttons.saving') : t('buttons.save')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
