import i18n from 'webapp/i18n'
import { routesBuilder } from 'utilities/routesBuilder'

export const BidderInventoryTabs = {
  inventoryConnection: 'inventory-connection',
  domainComparison: 'domain-comparison'
}

export const BidderInventoryTabElements = [
  {
    href: routesBuilder.quickwrap.setup.bidders(BidderInventoryTabs.inventoryConnection),
    title: i18n.t('setup:bidders.inventoryConnection.tab.inventoryConnection')
  },
  {
    href: routesBuilder.quickwrap.setup.bidders(BidderInventoryTabs.domainComparison),
    title: i18n.t('setup:bidders.inventoryConnection.tab.domainComparison')
  }
]
