import React from 'react'
import classNames from 'classnames'

interface BidderConnectionCellType {
  active: boolean
  connection: DomainVersionBidderConnection['connectionType']
  index: number
}

export const BidderConnectionCell = ({ active, connection, index }: BidderConnectionCellType): JSX.Element => {
  const shortConnectionName = getShortenConnectionName(connection)

  const tdClassName = classNames('domain-comparison-table__bidder', {
    'domain-comparison-table__bidder--first': index === 0
  })

  let connectionClassName = `domain-comparison-table__bidder-connection domain-comparison-table__bidder-connection--${
    shortConnectionName?.toLowerCase() || 'none'
  }`

  if (shortConnectionName) {
    connectionClassName += ` domain-comparison-table__bidder-connection--${active ? 'active' : 'inactive'}`
  }

  return (
    <td className={tdClassName}>
      <span className={connectionClassName}>{shortConnectionName}</span>
    </td>
  )
}

const getShortenConnectionName = (connection: DomainVersionBidderConnection['connectionType']) => {
  switch (connection) {
    case 'client':
      return 'C2S'
    case 'server':
      return 'S2S'
    case 'client_server':
      return 'Hyb'
    default:
      return null
  }
}
