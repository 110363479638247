import React, { useState, useContext } from 'react'
import { FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { BiddersRecommendationForm } from 'components/sharedInterfaces/BiddersRecommendationForm'
import { BiddersRecommendationSerializer } from 'serializers/BiddersRecommendationSerializer'
import { Box, BoxHeader, BoxBody } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { RecommendBiddersForm } from 'components/Bidders/RecommendBiddersForm'

import BiddersRecommendation from 'gql/mutations/bidders/BiddersRecommendation.gql'

export const RecommendBiddersContainer: React.FC = () => {
  const [counter, setCounter] = useState(0)
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const [biddersRecommendation, { loading: loading }] = useMutation(BiddersRecommendation, {
    onCompleted: ({ biddersRecommendation: { errors } }) => {
      if (errors.length === 0) {
        createNotifier(t('recommendBidders.form.successMessage'), NotificationType.SUCCESS)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmitHandler = (values: BiddersRecommendationForm, actions: FormikHelpers<BiddersRecommendationForm>) => {
    const serializer = new BiddersRecommendationSerializer(values)
    biddersRecommendation({ variables: { input: serializer.params() } }).then(({ data }) => {
      // clear inputs after successful submit
      if (data.biddersRecommendation.errors.length === 0) {
        actions.resetForm()
        setCounter(() => counter + 1)
      }
    })
  }

  return (
    <>
      <Box className='recommend-bidders-container' isExpandable={true} defaultExpand={false}>
        <BoxHeader title={t('recommendBidders.form.title')} subtitle={t('recommendBidders.form.subtitle')} />
        <BoxBody>
          <RecommendBiddersForm
            onSubmitHandler={onSubmitHandler}
            errors={errors}
            isSubmitting={loading}
            counter={counter}
          />
        </BoxBody>
      </Box>
    </>
  )
}
