import React from 'react'

import { InventoryConnectionTableContainer } from 'containers/quickwrap/Setup/InventoryConnectionContainer/InventoryConnectionTableContainer'

interface Props {
  isVisible?: boolean
}

export const InventoryConnectionContainer: React.FC<Props> = ({ isVisible = true }) => {
  return (
    <div hidden={!isVisible}>
      <InventoryConnectionTableContainer />
    </div>
  )
}
