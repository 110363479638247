import { gql } from '@apollo/client'
import { OSS_CONSENT_MANAGEMENT_FRAGMENT } from 'gql/fragments/ossConsentManagement/OssConsentManagementFragment'

export const UPDATE_DOMAIN_FRAGMENT = gql`
  ${OSS_CONSENT_MANAGEMENT_FRAGMENT}

  fragment UpdateDomainFragment on Domain {
    amazonSid
    currency
    customCodeEnabled
    id
    name
    ossConsentManagement {
      ...OssConsentManagementFragment
    }
    ossConsentManagementEnabled
  }
`
