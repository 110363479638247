import React from 'react'

import { FilterType } from 'webapp/constants/FilterType'
import { AdvancedFilterSelectValue } from './AdvancedFilterSelectValue'
import { AdvancedFilterTagsInputValue } from './AdvancedFilterTagsInputValue'

interface Props {
  cache: Record<string, unknown>
  filter: AdvancedFilter
  filterConfig?: AdvancedFilterConfiguration
  setCache: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  updateFilter: (id: string, newFilter: Partial<AdvancedFilter>) => void
}

export const AdvancedFilterValue: React.FC<Props> = ({ cache, filter, filterConfig, setCache, updateFilter }) => {
  if (filterConfig?.type === FilterType.Select) {
    return (
      <AdvancedFilterSelectValue
        cache={cache}
        filter={filter}
        filterConfig={filterConfig}
        setCache={setCache}
        updateFilter={updateFilter}
      />
    )
  } else {
    return <AdvancedFilterTagsInputValue filter={filter} filterConfig={filterConfig} updateFilter={updateFilter} />
  }
}
