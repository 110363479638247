import i18n from 'webapp/i18n'
import { BidderConsentModeTypes } from './BidderConsentModeTypes'

export const BidderConsentModeOptions: SelectOption[] = [
  {
    value: BidderConsentModeTypes.consent,
    label: i18n.t('setup:bidders.form.consentMode.values.consent')
  },
  {
    value: BidderConsentModeTypes.nonconsent,
    label: i18n.t('setup:bidders.form.consentMode.values.nonconsent')
  },
  {
    value: BidderConsentModeTypes.all,
    label: i18n.t('setup:bidders.form.consentMode.values.all')
  }
]
