import React from 'react'

import { useTranslation } from 'react-i18next'

import { BoxHeader } from 'components/Box'
import { ProductStatus } from 'constants/ProductStatus'
import { Product } from 'webapp/constants/Product'
import { Item } from './Item/Item'

import { ProductListProps } from './types'

const AVAILABLE_PRODUCTS = [
  Product.FillRateBooster,
  Product.PriceGenius,
  Product.Quickwrap,
  Product.Revive,
  Product.ViewabilityBooster
]

const compareStatuses = (pc: ProductConnection, onlyActive: boolean | undefined) => {
  const status: ProductStatus = pc.status
  const INACTIVE = ProductStatus.Inactive

  return onlyActive ? status !== INACTIVE : status === INACTIVE
}

export const ProductList: React.FC<ProductListProps> = ({ productConnections, onlyActive }) => {
  const { t } = useTranslation('products')

  const productsConnections: ProductConnection[] = productConnections
    .filter((pc: ProductConnection) => AVAILABLE_PRODUCTS.some((p: Product) => p === pc.product))
    .filter((pc: ProductConnection) => compareStatuses(pc, onlyActive))

  return productsConnections.length > 0 ? (
    <>
      <BoxHeader title={onlyActive ? t('list.activeProducts') : t('list.recommendedProducts')} />

      <div className='ProductList'>
        {productsConnections.map((pc: ProductConnection) => (
          <Item productConnection={pc} key={pc.id} />
        ))}
      </div>
    </>
  ) : null
}
