import React from 'react'

import { useTranslation } from 'react-i18next'

import { TagsInput } from 'components/Form/TagsInput'
import { useHandleFilterBothWays } from './useHandleFilterBothWays'
import { useSubscribeFilters } from './useSubscribeFilters'

import { NameFilterProps } from './types'

import magnifierImg from 'images/magnifying-glass.svg'

import './style.scss'

export const NameFilter = ({ collection, operator, path, placeholder }: NameFilterProps): JSX.Element => {
  const { t } = useTranslation()
  const { filtersChanged } = useSubscribeFilters(collection)
  const { value, setValue } = useHandleFilterBothWays({
    collection,
    filtersChanged,
    operator,
    path
  })

  return (
    <div className='NameFilter'>
      <TagsInput
        id={path}
        placeholder={placeholder || t('nameFilter.searchByName')}
        value={value}
        onChange={setValue}
      />

      <img className='NameFilter__icon' src={magnifierImg} />
    </div>
  )
}
