import { CheckboxStates } from './CheckboxStates'

class TreeObject {
  public checked: boolean
  public key: string
  public label: string
  public value?: string
  private children: TreeObject[]
  private parent: TreeObject | null

  constructor(structureItem: CheckboxTreeListDataItem, parent?: TreeObject) {
    this.label = structureItem.label
    this.key = structureItem.key
    this.value = structureItem.value
    this.checked = false
    this.children = []
    this.parent = parent || null
  }

  public addChild(child: TreeObject) {
    this.children.push(child)
  }

  public check(filter: string, value?: boolean): void {
    if (!this.value || this.containsFilter(filter)) {
      this.checked = typeof value !== 'undefined' ? (value as boolean) : !this.checked

      this.children.forEach((child) => child.check(filter, this.checked))
    }
  }

  public checkboxState() {
    if (!this.children.length) {
      return this.checked ? CheckboxStates.CheckedState : CheckboxStates.UncheckedState
    } else {
      if (this.children.every((child) => child.checkboxState() === CheckboxStates.CheckedState)) {
        return CheckboxStates.CheckedState
      } else if (
        this.children.some((child) =>
          [CheckboxStates.CheckedState, CheckboxStates.MiddleState].includes(child.checkboxState())
        )
      ) {
        return CheckboxStates.MiddleState
      } else {
        return CheckboxStates.UncheckedState
      }
    }
  }

  public containsFilter(filter: string): boolean {
    return !this.label.includes(filter) ? this.children.some((child) => child.containsFilter(filter)) : true
  }

  public checkedChildrenNumber(): number {
    return this.children.filter((child) => child.checked).length
  }
}

export class TreeHelper {
  private treeObjects: Record<string, TreeObject>

  constructor(dataStructure: CheckboxTreeListDataItem[], values: string[]) {
    this.treeObjects = {}

    dataStructure.forEach((structureItem) => this.parse(structureItem))
    this.initialize(values)
  }

  public findByKey(key: string): TreeObject {
    return this.treeObjects[key]
  }

  public initialize(values: string[]): void {
    Object.values(this.treeObjects)
      .filter((treeObject) => treeObject.value && values.includes(treeObject.value))
      .forEach((treeObject) => (treeObject.checked = true))
  }

  public values(): string[] {
    return Object.values(this.treeObjects)
      .filter((treeObject: TreeObject) => treeObject.value && treeObject.checked)
      .map((treeObject: TreeObject) => treeObject.value) as string[]
  }

  private parse(dataStructure: CheckboxTreeListDataItem, parent?: TreeObject) {
    const treeObject = new TreeObject(dataStructure, parent)

    if (parent) {
      parent.addChild(treeObject)
    }

    if (dataStructure.children) {
      dataStructure.children.forEach((child) => this.parse(child, treeObject))
    }

    this.treeObjects[treeObject.key] = treeObject
  }
}
