import React from 'react'
import { useFormikContext } from 'formik'

import { Checkbox, Label } from 'components/Form'
import { CheckboxItem } from 'components/Form/CheckboxGroup/CheckboxItem'

type Props = {
  errors?: string[]
  items: SelectOption<string>[]
  label?: string
  name: string
  onChange?: () => void
  selectAllLabel?: string
  tooltipContent?: string
}

export const CheckboxGroup: React.FC<Props> = ({
  errors,
  items,
  label,
  name,
  onChange,
  selectAllLabel,
  tooltipContent
}) => {
  const { values, setFieldValue } = useFormikContext<Record<typeof name, (string | number)[]>>()
  const fieldValues = values[name]

  const onSelectAllChange = (checked: boolean) => {
    setFieldValue(name, checked ? items.map((item) => item.value) : [])
  }

  return (
    <div className='checkbox-group'>
      {label && (
        <Label className='input__label input__label--bold' tooltipContent={tooltipContent} htmlFor={name}>
          {label}
        </Label>
      )}
      <div className='qw-label__container'>
        {selectAllLabel && (
          <Checkbox
            id='checkbox-group__all'
            name='checkbox-group__all'
            checked={fieldValues.length === items.length}
            onChange={(e) => onSelectAllChange(e.target.checked)}
            label={selectAllLabel}
          />
        )}
      </div>
      <div className='checkbox-group__container' role='group' aria-labelledby='checkbox-group'>
        {items.map((item) => (
          <CheckboxItem
            key={item.value as string}
            name={name}
            option={item}
            isSelected={fieldValues?.includes(item.value as string) ?? false}
            onChange={onChange}
          />
        ))}
      </div>
      <div className='error__messages'>{errors}</div>
    </div>
  )
}
