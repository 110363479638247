/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import React from 'react'
import { Column } from 'react-table'

import i18n from 'webapp/i18n'
import { NameCell } from './NameCell'
import { TotalValueCell } from './TotalValueCell'
import { TotalValueHeader } from './TotalValueHeader'
import { FooterCell } from './FooterCell'

const REVENUE_DIVIDER = 1000000

export const columnsConfig = (currency: string): Array<Column<ReportData>> => {
  return [
    {
      Header: () => i18n.t('reports:resultPage.inventory.table.adUnitName'),
      accessor: 'name',
      Cell: ({ value, row }) => <NameCell value={value} row={row} />,
      disableSortBy: true,
      Footer: () => <FooterCell label='total' />
    },
    {
      Header: () => <TotalValueHeader cellName='totalImpressions' />,
      accessor: 'total_impressions',
      Cell: ({ value, row }) => <TotalValueCell value={value} isExpanded={row.isExpanded} />,
      disableSortBy: true,
      Footer: ({ page }: any) => <FooterCell page={page} type='total_impressions' className='cell--total-value' />
    },
    {
      Header: () => <TotalValueHeader cellName='totalRevenue' />,
      accessor: 'total_revenue',
      Cell: ({ value, row }) => (
        <TotalValueCell value={value} currency={currency} isExpanded={row.isExpanded} divider={REVENUE_DIVIDER} />
      ),
      disableSortBy: true,
      Footer: ({ page }: any) => (
        <FooterCell
          page={page}
          currency={currency}
          type='total_revenue'
          divider={REVENUE_DIVIDER}
          className='cell--total-value'
        />
      )
    }
  ]
}
