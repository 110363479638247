import React from 'react'
import { Column } from 'react-table'

import { Table } from 'components/Table'
import { columnsConfig } from './columnsConfig'

interface Props {
  collectionName: string
  currencyCode: string
  reportData: Array<ReportData>
  showSummaryRow: boolean
}

export const ReportResultTable: React.FC<Props> = ({ collectionName, currencyCode, reportData, showSummaryRow }) => {
  return (
    <Table<ReportData>
      collectionName={collectionName}
      className='report-results-table'
      columns={columnsConfig(currencyCode) as Column[]}
      data={reportData}
      hasSummaryRow={showSummaryRow}
    />
  )
}
