import React from 'react'
import { Field } from 'formik'
import { FormikSelectContainer } from 'containers/FormikContainers'

import { FormSchema } from './formSchema'

interface Props {
  id: string
  connection: SelectOption
  disabled: boolean
}

export const ConnectionHeader: React.FC<Props> = ({ id, connection, disabled }) => {
  return (
    <Field
      className='connection'
      component={FormikSelectContainer}
      disabled={disabled}
      name={id}
      options={FormSchema.modes}
      selectedItem={connection}
    />
  )
}
