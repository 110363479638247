import React from 'react'

import { ErrorsContainer } from 'containers/Errors'
import ErrorImg from 'images/not_found.png'
import { useGoogleAnalytics } from 'utilities/reactGa'

export const NotFoundPage: React.FC = () => {
  useGoogleAnalytics(location.pathname)

  return <ErrorsContainer className='error-page__not-found' image={ErrorImg} translationPlaceholder='notFound' />
}
