import React from 'react'
import moment from 'moment'

import { Avatar } from 'components/Avatar'

interface Props {
  comment: Comment
}

export const CommentItem: React.FC<Props> = ({ comment }) => {
  const user = comment.user
  return (
    <div className='comment-item'>
      {user && (
        <div className='comment-item__header'>
          <Avatar user={user as User} containerClassName='comment-item__avatar' />
          <div className='comment-item__name'>{user.fullName}</div>
          <div className='comment-item__date'>{moment(comment.createdAt).format('DD.MM.YY')}</div>
        </div>
      )}
      <div className='comment-item__content'>{comment.content}</div>
    </div>
  )
}
