import React from 'react'
import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { ToggleCell } from './ToggleCell'

export const columnsConfig = (
  toggleDomainsHandler: (id: string, prebidAnalyticsEnabled: boolean) => void
): Column<PrebidAnalyticsDomain>[] => {
  return [
    {
      Header: () => i18n.t('performance:prebidAnalytics.infoBar.applicableDomainsModal.table.headers.domain'),
      accessor: 'name',
      disableSortBy: true
    },
    {
      Header: () => i18n.t('performance:prebidAnalytics.infoBar.applicableDomainsModal.table.headers.prebidAnalytics'),
      accessor: 'prebidManagerEnabled',
      disableSortBy: true,
      Cell: (props: CellProps<PrebidAnalyticsDomain>): JSX.Element | null => (
        <ToggleCell domain={props.row.original} toggleDomainsHandler={toggleDomainsHandler} />
      )
    }
  ]
}
