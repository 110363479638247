import { WorkspacePackageEnum } from 'utilities/workspacePackageUtils'

interface FormValues {
  [key: string]: boolean
}

const reducePackages = (result: FormValues, element: WorkspacePackage) => ({
  ...result,
  [element.name]: element.active
})

export const WorkspacePackagesFactory = (packages: Array<WorkspacePackage> = []): WorkspacePackagesForm => {
  const packagesValues = packages.reduce(reducePackages, {})

  return {
    prebidAnalyticsEnabled: packagesValues[WorkspacePackageEnum.PREBID_MANAGER] ?? false,
    s2sEnabled: packagesValues[WorkspacePackageEnum.S2S] ?? false
  }
}
