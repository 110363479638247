import React, { useContext } from 'react'
import { Formik, Field } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { BidderPriorityField } from 'components/FormikComponents/BidderPriorityField'
import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import {
  FormikInputContainer as FormikInput,
  FormikSortableSelectContainer as FormikSortableSelect,
  FormikToggleContainer as FormikToggle,
  FormikRadioContainer as FormikRadio
} from 'containers/FormikContainers'
import { Label, StatusForm } from 'components/Form'
import { LoadingButton } from 'components/Button/LoadingButton'
import { OwnBidderConsentModeField } from './OwnBidderConsentModeField'
import { OwnBidderSelect } from './OwnBidderSelect'
import { RequestNewBidderContainer } from 'containers/quickwrap/Setup/RequestNewBidderContainer'
import { SchainNetworkConnectionTypes } from 'webapp/constants/SchainNetworkConnectionTypes'
import { UserContext } from 'webapp/context/UserContext'

type OwnBidderFormType = {
  bidderSetting: OwnBidderFormData
  bidders: Bidder[]
  bidderSettings: BidderSetting[]
  closeModal: () => void
  onSubmitHandler: (form: OwnBidderFormData) => void
  errors: Errors
  isSubmitting?: boolean
  networks: Network[]
}

export const OwnBidderForm = ({
  bidderSetting,
  bidders,
  bidderSettings,
  closeModal,
  onSubmitHandler,
  errors,
  isSubmitting,
  networks
}: OwnBidderFormType): JSX.Element => {
  const { t } = useTranslation('setup')
  const { user } = useContext(UserContext)

  return (
    <div className='own-bidder-form'>
      <Formik initialValues={bidderSetting} onSubmit={onSubmitHandler} enableReinitialize>
        {({ dirty, values }) => (
          <StatusForm name='ownBidder' dirty={dirty}>
            <div className='own-bidder-form__row'>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <OwnBidderSelect bidders={bidders} bidderSettings={bidderSettings} errors={errors} />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    id='own-bidder-code'
                    name='bidderCode'
                    type='text'
                    placeholder={t('bidders.form.bidderCode.placeholder')}
                    label={t('bidders.form.bidderCode.label')}
                    tooltipContent={t('bidders.form.bidderCode.tooltip')}
                    component={FormikInput}
                    errors={errors.errorsFor('bidderCode')}
                  />
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={2} className='own-bidder-form__row'>
              <Grid item xs={3}>
                <Field
                  id='own-bid-cpm-adjustment'
                  name='bidCpmAdjustment'
                  type='number'
                  step={0.01}
                  placeholder={t('bidders.form.bidCpmAdjustment.placeholder')}
                  label={t('bidders.form.bidCpmAdjustment.label')}
                  tooltipContent={t('bidders.form.bidCpmAdjustment.tooltip')}
                  component={FormikInput}
                  errors={errors.errorsFor('bidCpmAdjustment')}
                />
              </Grid>

              <Grid item xs={6} className='own-bidder-form__bidder-sid-column'>
                <Field
                  id='own-bidder-sid'
                  name='bidderSid'
                  type='text'
                  placeholder={t('bidders.form.bidderSid.placeholder')}
                  label={t('bidders.form.bidderSid.label')}
                  tooltipContent={t('bidders.form.bidderSid.tooltip')}
                  component={FormikInput}
                  errors={errors.errorsFor('bidderSid')}
                />
              </Grid>
            </Grid>
            {user?.workspace?.adNetworkEnabled && (
              <Grid container spacing={2} className='own-bidder-form__row'>
                <Grid item xs={12}>
                  <Label className='input__label input__label--bold' htmlFor={'schainNetworkConnection'}>
                    {t('bidders.form.schainNetworkConnection.label')}
                  </Label>
                  <Grid container>
                    <Field
                      className='mr-3'
                      name='schainNetworkConnection'
                      id='direct-schain-network-connection'
                      label={t('bidders.form.direct.label')}
                      value={SchainNetworkConnectionTypes.Direct}
                      component={FormikRadio}
                    />
                    <Field
                      className='ml-3'
                      name='schainNetworkConnection'
                      id='indirect-schain-network-connection'
                      label={t('bidders.form.indirect.label')}
                      value={SchainNetworkConnectionTypes.Indirect}
                      component={FormikRadio}
                    />
                  </Grid>
                </Grid>
                {values.schainNetworkConnection === SchainNetworkConnectionTypes.Indirect && (
                  <Grid item xs={12}>
                    <Field
                      id='bidderSettingNetworks'
                      isMulti={true}
                      name='bidderSettingNetworks'
                      label={t('bidders.form.bidderSettingNetworks.label')}
                      tooltipContent={t('bidders.form.bidderSettingNetworks.tooltip')}
                      component={FormikSortableSelect}
                      errors={errors.errorsFor('bidderSettingNetworks')}
                      options={networks.map((network) => ({ label: network.name, value: network.id }))}
                      placeholder={t('bidders.form.bidderSettingNetworks.placeholder')}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container spacing={2} className='own-bidder-form__row'>
              <Grid item xs={4}>
                <BidderPriorityField errors={errors} isFirstRow />
              </Grid>
              <Grid item xs={4}>
                <OwnBidderConsentModeField errors={errors} />
              </Grid>
              <Grid item xs={4}>
                <Field
                  id='own-bidder-active'
                  name='active'
                  label={t('bidders.form.active.label')}
                  tooltipContent={t('bidders.form.active.tooltip')}
                  component={FormikToggle}
                  errors={errors.errorsFor('active')}
                />
              </Grid>
            </Grid>

            <RequestNewBidderContainer demand={false} />

            <Button
              buttonType='button'
              className='own-bidder-form__button'
              onClickHandler={closeModal}
              theme={ButtonTheme.BlueReverse}
              type={ButtonType.Primary}
            >
              {t('common:buttons.cancel')}
            </Button>

            <LoadingButton
              type={ButtonType.Primary}
              className='own-bidder-form__button'
              theme={ButtonTheme.Blue}
              buttonType='submit'
              disabled={!dirty}
              loading={isSubmitting}
            >
              {t('common:buttons.save')}
            </LoadingButton>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
