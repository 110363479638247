import React from 'react'

import { useHistory } from 'react-router-dom'
import { Column } from 'react-table'

import { QueryTable, QueryTableProps } from 'containers/QueryTable'
import { ReviveDomainConfiguration } from 'types/ReviveDomainConfiguration'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { ReviveDomainConfigurationRow } from './ReviveDomainConfigurationRow'
import { useColumnsConfig } from './useColumnsConfig'

import { REVIVE_DOMAIN_CONFIGURATIONS } from 'gql/queries/revive/reviveDomainConfigurations'

export const ConfigurationsTable = (): JSX.Element => {
  const history = useHistory()

  const redirectToEdit: QueryTableProps<ReviveDomainConfigurationRow>['onRowClick'] = (row) => {
    const { id: reviveDomainConfigurationId } = row.original.reviveDomainConfiguration as ReviveDomainConfiguration

    history.push(routesBuilder.revive.configuration.edit(reviveDomainConfigurationId))
  }

  return (
    <QueryTable<ReviveDomainConfigurationRow>
      className='revive-domain-configurations-table'
      collectionName={CollectionNames.ReviveDomainConfigurations}
      columnsConfig={useColumnsConfig() as Column[]}
      dataQuery={REVIVE_DOMAIN_CONFIGURATIONS}
      mapper={(node: ReviveDomainConfiguration): ReviveDomainConfigurationRow => new ReviveDomainConfigurationRow(node)}
      onRowClick={redirectToEdit}
    />
  )
}
