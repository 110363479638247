import React from 'react'

import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { ActionsCell } from 'components/shared/Inventory/Domains/DomainsTable/ActionsCell'
import { DomainRowData } from 'components/shared/Inventory/Domains/DomainsTable/DomainRowData'
import { LiveCell } from 'components/shared/Inventory/Domains/DomainsTable/LiveCell'
import { DateCell, DateCellFormat } from 'components/Table'
import { Product } from 'webapp/constants/Product'

export const columnsConfig: Column<DomainRowData>[] = [
  {
    Header: () => i18n.t('inventory:domains.tableHeader.domainName'),
    accessor: 'name'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.activeVersionName'),
    accessor: 'activeVersion',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.live'),
    accessor: 'live',
    disableSortBy: true,
    Cell: ({ value }) => <LiveCell live={value} />
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.workspaceName'),
    accessor: 'workspace',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.pages'),
    accessor: 'pagesCount'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.adUnits'),
    accessor: 'adUnitsCount'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.devices'),
    accessor: 'prebidDeviceCount'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value, row }) => !row.original.hasActiveAbTest && <DateCell value={value} format={DateCellFormat.DOTS} />
  },
  {
    Header: () => i18n.t('table.header.actions'),
    Cell: ({ row }: CellProps<DomainRowData>) => <ActionsCell row={row} product={Product.Quickwrap} />,
    id: 'actions',
    disableSortBy: true
  }
]
