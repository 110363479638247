import React, { ReactNode } from 'react'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Modal } from 'components/Modals'
import { useTranslation } from 'react-i18next'

interface Props {
  bodyClassName?: string
  cancelButtonText?: string
  cancelButtonTheme?: ButtonTheme
  children?: ReactNode
  closeButton?: boolean
  confirmButtonText?: string
  confirmButtonTheme?: ButtonTheme
  content?: string
  header?: ReactNode
  isSubmitting?: boolean
  onCancelHandler?: () => void
  onConfirmHandler?: () => void
  setVisible: (visible: boolean) => void
  title?: string
  visible: boolean
}

export const ConfirmationModal: React.FC<Props> = ({
  bodyClassName,
  cancelButtonText,
  cancelButtonTheme,
  children,
  closeButton,
  confirmButtonText,
  confirmButtonTheme,
  content,
  header,
  isSubmitting,
  onCancelHandler,
  onConfirmHandler,
  setVisible,
  title,
  visible
}) => {
  const { t } = useTranslation('products')

  const handleCancel = () => {
    onCancelHandler && onCancelHandler()
    setVisible(false)
  }

  const handleConfirm = () => {
    if (onConfirmHandler) onConfirmHandler()
  }

  return (
    <Modal
      backdrop={true}
      bodyClass={bodyClassName}
      closeButton={closeButton}
      onClose={onCancelHandler}
      setVisible={setVisible}
      shadow={true}
      title={title}
      visible={visible}
    >
      <div className='confirmation__modal-container'>
        {header}

        {content && <p className='confirmation__modal-content'>{content}</p>}

        {children}

        <footer>
          {(onCancelHandler || cancelButtonText) && (
            <Button
              className='confirmation__modal__button'
              onClickHandler={handleCancel}
              theme={cancelButtonTheme ?? ButtonTheme.BlueReverse}
              type={ButtonType.Primary}
            >
              {cancelButtonText ?? t('common:buttons.cancel')}
            </Button>
          )}

          {onConfirmHandler && (
            <Button
              className='confirmation__modal__button'
              disabled={isSubmitting}
              onClickHandler={handleConfirm}
              theme={confirmButtonTheme ?? ButtonTheme.Blue}
              type={ButtonType.Primary}
            >
              {confirmButtonText ?? t('common:buttons.confirm')}
            </Button>
          )}
        </footer>
      </div>
    </Modal>
  )
}
