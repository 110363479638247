import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { PagesContainer } from 'containers/oss/Inventory/Pages/PagesContainer'
import { PageAdd, PageEdit } from 'pages/oss/Inventory/Pages/PageFormContainer'
import { routesBuilder } from 'utilities/routesBuilder'
import { Product } from 'webapp/constants/Product'

export const PageRoutes = [
  <LayoutRoute
    key='pages-list'
    product={Product.OSS}
    exact
    path={routesBuilder.oss.inventory.pages.root}
    component={PagesContainer}
  />,
  <LayoutRoute
    key='pages-add'
    product={Product.OSS}
    path={routesBuilder.oss.inventory.pages.add}
    component={PageAdd}
  />,
  <LayoutRoute
    key='pages-edit'
    product={Product.OSS}
    path={routesBuilder.oss.inventory.pages.edit()}
    component={PageEdit}
  />
]
