import React, { useContext, useState } from 'react'
import { FormikHelpers } from 'formik'
import { Grid } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { CommentList } from 'components/Comments/CommentList'
import { CommentForm } from 'components/Comments/CommentForm'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { Product } from 'webapp/constants/Product'

import CommentsQuery from 'gql/queries/domains/DomainCommentsSelect.gql'
import CreateComment from 'gql/mutations/comments/CreateComment.gql'

interface Props {
  domainId: string
  product: Product
}

export const DomainCommentContainer: React.FC<Props> = ({ domainId, product }) => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const commentable = { domainId }

  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [isExpanded, setIsExpanded] = useState(false)

  const { refetch, data } = useQuery(CommentsQuery, {
    fetchPolicy: 'network-only',
    variables: { domainId }
  })

  const comments = data?.comments?.nodes

  const [createComment, { loading }] = useMutation(CreateComment)

  const onSubmit = async (values: CommentFormData, { resetForm }: FormikHelpers<CommentFormData>) => {
    try {
      const options = { variables: { input: { ...commentable, content: values.content } } }
      const { data } = await createComment(options)
      const errors = data.createComment.errors
      if (!errors.length) {
        createNotifier(t('domains.edit.comments.new.successMessage'), NotificationType.SUCCESS)
        refetch()
        resetForm()
      }
      setErrors(new ErrorUtils(errors))
    } catch {
      createNotifier(t('common:errors.common'), NotificationType.ERROR)
    }
  }

  return (
    <>
      <Box className='domain-comments' isExpandable={true} defaultExpand={false} onToggle={setIsExpanded}>
        <BoxHeader title={t('domains.edit.comments.title')} />
        <div className='box__body'>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <CommentList comments={comments} showCount={isExpanded ? undefined : 1} />
            </Grid>
            {isExpanded && (
              <Grid item xs={12} lg={6}>
                <CommentForm
                  commentable={commentable}
                  onSubmit={onSubmit}
                  errors={errors}
                  isSubmitting={loading}
                  product={product}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </Box>
    </>
  )
}
