import React from 'react'

import { CellProps, Column } from 'react-table'

import { DateCell } from 'components/Table'
import i18n from 'webapp/i18n'
import { OssAdUnitRowData } from './OssAdUnitRowData'
import ReviveToggleCell from './ReviveToggleCell'

export const columnsConfig = (): Column<OssAdUnitRowData>[] => [
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.adUnitName'),
    accessor: 'adUnitName'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.adUnitPath'),
    accessor: 'adUnitPath'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    Header: () => i18n.t('revive:configuration.columns.revive'),
    accessor: 'reviveEnabled',
    Cell: ({ row }: CellProps<OssAdUnitRowData>) => <ReviveToggleCell row={row} />
  }
]
