import React from 'react'

import ExpandIconSrc from 'images/icon-expand.svg'

interface Props {
  classNames?: string
  alt: string
  onClickHandler?: (e: React.MouseEvent<Element, MouseEvent>) => void
}

const ExpandIcon: React.FC<Props> = ({ classNames, alt, onClickHandler }) => {
  return <img className={`${classNames}`} src={ExpandIconSrc} alt={alt} onClick={onClickHandler} />
}

export { ExpandIcon }
