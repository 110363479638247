import React from 'react'
import { Collapse, Grid } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer, FormikToggleContainer as FormikToggle } from 'containers/FormikContainers'
import { PrebidDevicesFormikValues } from 'containers/shared/Inventory/Domains/DevicesContainer/PrebidDevicesFormBuilder'

interface Props {
  index: number
  errors: Errors
}

export const PrebidDeviceRow: React.FC<Props> = ({ index, errors }) => {
  const { t } = useTranslation('inventory')
  const { values } = useFormikContext<PrebidDevicesFormikValues>()
  const fieldName = `devices.${index}`

  return (
    <Grid container spacing={10}>
      <Grid item lg={4} xs={6}>
        <Field
          id={`prebid_device-timeout-enabled-${index}`}
          name={`${fieldName}.timeoutEnabled`}
          label={t('domains.edit.prebidDevices.timeoutEnabled')}
          tooltipContent={t('setup:generalSettings.form.fields.timeout.tooltip')}
          component={FormikToggle}
          labelStyle='bold'
        />
        <Collapse in={values.devices[index].timeoutEnabled}>
          <Field
            id={`prebid_device-timeout-${index}`}
            name={`${fieldName}.timeout`}
            label={t('domains.edit.prebidDevices.timeout')}
            type='number'
            component={FormikInputContainer}
            labelStyle='bold'
            errors={errors.errorsFor([String(index), 'timeout'])}
          />
        </Collapse>
      </Grid>
    </Grid>
  )
}
