import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { InactiveItem } from 'components/Header/ProductMenu/InactiveItem'
import { SecondaryProducts } from 'webapp/constants/SecondaryProducts'
import { UserContext } from 'webapp/context/UserContext'
import { isProductConnectionActive } from 'utilities/products/activation'

export const InactiveProducts: React.FC = () => {
  const { t } = useTranslation('products')

  const { indexedProductConnections } = useContext(UserContext)

  const inactiveProducts = useMemo(
    () => SecondaryProducts.filter((product) => !isProductConnectionActive(indexedProductConnections?.[product])),
    [indexedProductConnections]
  )

  if (!inactiveProducts.length || !indexedProductConnections) return null

  return (
    <div className='product-menu__section product-menu__section--inactive'>
      <div className='product-menu__heading'>{t('menu.inactiveProducts')}</div>

      {inactiveProducts.map((product) => (
        <InactiveItem key={product} productConnection={indexedProductConnections[product]} />
      ))}
    </div>
  )
}
