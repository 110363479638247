import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'gql/fragments/common'

const PAYLOAD_TYPE = 'UpdateDomainPayload'

export const UPDATE_DOMAIN_WRAPPER_VERSION = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}

  mutation UpdateDomain($input: UpdateDomainInput!) {
    updateDomain(input: $input) {
      domain {
        id
        name
        wrapperVersion
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
