import React from 'react'
import { useField, FieldConfig } from 'formik'
import { PropsValue } from 'react-select'

import { Select } from 'components/Form'

interface Props {
  id: string
  className?: string
  containerClassName?: string
  options: Array<SelectOption>
  defaultValue?: SelectOption
  selectedItem?: SelectOption | []
  placeholder: string
  isSearchable?: boolean
  isClearable?: boolean
  closeMenuOnSelect?: boolean
  errors?: Array<string>
  disabled?: boolean
  label?: string
  labelStyle?: string
  tooltipContent?: string
  isMulti?: boolean
  onChangeHandler?: (value: PropsValue<SelectOption>) => void
  filterOption?: (candidate: SelectOption, input: string) => boolean
  field: FieldConfig
}

export const FormikSelectContainer: React.FC<Props> = ({
  id,
  className,
  containerClassName,
  options,
  defaultValue,
  placeholder,
  isSearchable,
  isClearable,
  closeMenuOnSelect,
  errors,
  disabled,
  label,
  labelStyle,
  tooltipContent,
  onChangeHandler,
  selectedItem,
  filterOption,
  isMulti,
  ...other
}) => {
  const [field, , helpers] = useField(other.field.name)
  const onChange = (value: PropsValue<SelectOption>) => {
    const result = value || []

    helpers.setValue(result)
    onChangeHandler && onChangeHandler(result)
  }

  const filterSelectedValues = (): SelectOption | SelectOption[] | undefined => {
    if (isMulti) {
      return options.filter((el: SelectOption) =>
        field.value.some((selected: SelectOption) => selected.value === el.value)
      )
    }
    return options.find((elem: SelectOption): boolean => elem.value === field.value?.value)
  }

  return (
    <>
      <Select
        id={id}
        name={field.name}
        className={className}
        containerClassName={containerClassName}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        closeMenuOnSelect={closeMenuOnSelect}
        disabled={disabled}
        errors={errors}
        label={label}
        labelStyle={labelStyle}
        tooltipContent={tooltipContent}
        selectedItem={selectedItem || filterSelectedValues()}
        onChangeHandler={onChange}
        filterOption={filterOption}
        isMulti={isMulti}
      />
    </>
  )
}
