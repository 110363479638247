import React, { useEffect, useState } from 'react'

interface BoxProviderProps {
  isExpandable?: boolean
  defaultExpand?: boolean
  onToggle?: (isOpen: boolean) => void
}

interface BoxContextProps extends BoxProviderProps {
  isExpanded: boolean
  toggleExpand: () => void
}

const defaults = {
  isExpandable: false,
  defaultExpand: true,
  isExpanded: true,
  toggleExpand: () => {}
}

export const BoxContext = React.createContext<BoxContextProps>(defaults)

export const BoxProvider: React.FC<BoxProviderProps> = ({
  isExpandable = false,
  defaultExpand = true,
  children,
  onToggle
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpand)

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev)
    onToggle?.(!isExpanded)
  }

  useEffect(() => {
    setIsExpanded(defaultExpand)
  }, [defaultExpand])

  return (
    <BoxContext.Provider value={{ isExpandable, defaultExpand, isExpanded, toggleExpand }}>
      {children}
    </BoxContext.Provider>
  )
}
