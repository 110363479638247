import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Modals'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ConnectedNetworksTableContext } from 'components/oss/Workspaces/ConnectedNetworksTable'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
  onConfirmHandler: () => void
  onCancelHandler?: () => void
}

export const GamAutomationModal: React.FC<Props> = ({ visible, setVisible, onConfirmHandler, onCancelHandler }) => {
  const { t } = useTranslation('workspaces')
  const { isRunInProgress } = useContext(ConnectedNetworksTableContext)

  const cancelHandler = useCallback(() => {
    onCancelHandler && onCancelHandler()
    setVisible(false)
  }, [onCancelHandler, setVisible])

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      shadow={true}
      backdrop={true}
      title={t('gamConnector.networkForm.modal.title')}
    >
      <div className='WorkspaceGamConnector__modal-content'>
        <span className='confirmation__modal-content'>{t('gamConnector.networkForm.modal.content1')}</span>
        <br />

        <span className='confirmation__modal-content'>{t('gamConnector.networkForm.modal.content2')}</span>
        <a href={t('gamConnector.networkForm.modal.link1.href')} target='_blank' rel='noreferrer'>
          {t('gamConnector.networkForm.modal.link1.label')}
        </a>
        <br />
        <br />

        <span className='confirmation__modal-content'>{t('gamConnector.networkForm.modal.content3')}</span>
        <span className='confirmation__modal-content confirmation__modal-content_bold'>
          {t('gamConnector.networkForm.modal.content4')}
        </span>
        <br />

        <span className='confirmation__modal-content'>{t('gamConnector.networkForm.modal.content5')}</span>
        <a href={t('gamConnector.networkForm.modal.link2.href')} target='_blank' rel='noreferrer'>
          {t('gamConnector.networkForm.modal.link2.label')}
        </a>

        <footer className='WorkspaceGamConnector__modal-footer'>
          <Button
            className='confirmation__modal__button'
            onClickHandler={cancelHandler}
            theme={ButtonTheme.BlueReverse}
            type={ButtonType.Primary}
          >
            {t('common:buttons.cancel')}
          </Button>

          <Button
            className='confirmation__modal__button'
            disabled={isRunInProgress}
            onClickHandler={onConfirmHandler}
            theme={ButtonTheme.Blue}
            type={ButtonType.Primary}
          >
            {t('common:buttons.accept')}
          </Button>
        </footer>
      </div>
    </Modal>
  )
}
