import { useMemo } from 'react'

import { RouteProductPrefix } from 'webapp/constants/RouteProductPrefix'
import { Product } from 'webapp/constants/Product'
import i18n from 'webapp/i18n'

export type IndexedProductConnections = { [product in string & Omit<Product, Product.OSS>]: ProductConnection }

const removeProductPrefixRegexString = Object.values(RouteProductPrefix)
  .map((prefix) => prefix.replace(/_/g, '-'))
  .join('|')

const removeProductPrefixRegex = new RegExp(`^/(${removeProductPrefixRegexString})`)

export const removeProductPrefix = (path: string) => {
  return path.replace(removeProductPrefixRegex, '')
}

export const getProductName = (product: Product) => {
  return i18n.t(`products:product.${product}`)
}

export const useIndexedProductConnections = (user: User | null) => {
  return useMemo(() => {
    return user?.workspace?.productConnections?.reduce((acc, productConnection) => {
      acc[productConnection.product] = productConnection
      return acc
    }, {} as IndexedProductConnections)
  }, [user?.workspace?.productConnections])
}
