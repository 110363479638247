import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { AdUnitsContainer } from 'containers/oss/Inventory/AdUnits/AdUnitsContainer'
import { AdUnitAdd, AdUnitEdit } from 'pages/oss/Inventory/AdUnits/AdUnitFormContainer'
import { routesBuilder } from 'utilities/routesBuilder'
import { Product } from 'webapp/constants/Product'

export const AdUnitRoutes = [
  <LayoutRoute
    key='ad-units-list'
    product={Product.OSS}
    exact
    path={routesBuilder.oss.inventory.adUnit.root}
    component={AdUnitsContainer}
  />,
  <LayoutRoute
    key='ad-units-add'
    product={Product.OSS}
    path={routesBuilder.oss.inventory.adUnit.add()}
    component={AdUnitAdd}
  />,
  <LayoutRoute
    key='ad-units-edit'
    product={Product.OSS}
    path={routesBuilder.oss.inventory.adUnit.edit()}
    component={AdUnitEdit}
  />
]
