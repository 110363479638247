import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import { Column, Row } from 'react-table'

import { PageRowData } from 'components/shared/Inventory/Pages/PagesTable/PageRowData'
import { QueryTable } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { useTableState } from 'webapp/hooks/useTableState'
import { columnsConfig } from './columnsConfig'

import PAGES_QUERY from 'gql/queries/pages/Pages.gql'

export const PagesTable: React.FC = () => {
  const mapper = useCallback((node: PageRow) => new PageRowData(node), [])

  const history = useHistory()
  const { pageIndex, setPageIndex } = useTableState()

  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    history.push(routesBuilder.quickwrap.inventory.pages.edit((row.original as unknown as PageRowData).id), {
      pageIndex
    })
  }

  return (
    <QueryTable<PageRowData>
      collectionName={CollectionNames.Pages}
      dataQuery={PAGES_QUERY}
      columnsConfig={columnsConfig as Column[]}
      mapper={mapper}
      onRowClick={openEditingPage}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
    />
  )
}
