import { domainVersionName } from 'utilities/domainVersionName'
import { SizeTypeEnum } from 'utilities/sizeTypeUtils'

export class AdUnitRowData {
  adUnit: AdUnit

  constructor(adUnit: AdUnit) {
    this.adUnit = adUnit
  }

  get id(): string {
    return this.adUnit.id as string
  }

  get pagesCount(): number {
    return this.adUnit.ossAdUnit.ossPagesCount
  }

  get biddersCount(): number {
    return this.adUnit.biddersCount as number
  }

  get deviceCount(): number {
    return this.adUnit.sizeType === SizeTypeEnum.GENERAL
      ? 0
      : this.adUnit.mediaTypes?.filter((mediaType) => Boolean(mediaType.workspaceDevice)).length || 0
  }

  get domainId(): string {
    return this.adUnit.domain?.id as string
  }

  get domainVersionId(): string {
    return this.adUnit?.domainVersion?.id as string
  }

  get domainName(): string {
    return this.adUnit.domain?.name || ''
  }

  get domainVersionName(): string {
    return domainVersionName(this.adUnit.domainVersion?.counter, this.adUnit.domainVersion?.name)
  }

  // react-table requires data returned by an accessor to be primitive and sortable
  // that's why it returns joined string instead of an array
  get types(): string[] {
    return this.adUnit.media as string[]
  }

  get updatedAt(): Date {
    return this.adUnit.updatedAt as Date
  }

  get ossAdUnit(): OssAdUnit {
    return this.adUnit.ossAdUnit
  }

  get ownBidders(): string[] {
    return this.adUnit.ownBidders || []
  }

  get ybBidders(): string[] {
    return this.adUnit.ybBidders || []
  }

  get workspaceName(): string {
    return this.adUnit.domain?.workspace?.name || ''
  }
}
