import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Button, ButtonType } from 'components/Button'
import { Can } from 'webapp/context/AbilityContext'
import { getProductName } from 'utilities/products/utils'
import { getProductSettings } from 'utilities/products/settings'
import { ProductActivationModal } from 'components/oss/Products/ProductActivationModal'
import { ProductPlan } from 'webapp/constants/ProductPlan'

interface Props {
  productConnection: ProductConnection
}

export const InactiveItem: React.FC<Props> = ({ productConnection }) => {
  const { t } = useTranslation('products')

  const settings = getProductSettings(productConnection.product)
  const name = getProductName(productConnection.product)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const onClickHandler = () => {
    setIsModalVisible(true)
  }

  return (
    <div className='product-menu__item product-menu__item--inactive'>
      <div className='product-menu__logo'>
        <img src={settings.logoIcon} alt={name} />
      </div>

      <div className='product-menu__item-right'>
        <div>{name}</div>

        <Can I={AbilityAction.EDIT} a={AbilitySubject.PRODUCT}>
          <Button theme={settings.buttonTheme} type={ButtonType.Primary} onClickHandler={onClickHandler}>
            {t('buttons.try')}
          </Button>

          <ProductActivationModal
            plan={ProductPlan.Trial}
            productConnection={productConnection}
            setVisible={setIsModalVisible}
            visible={isModalVisible}
          />
        </Can>
      </div>
    </div>
  )
}
