import React, { ReactElement } from 'react'

import { DomainVersionSelectRow } from 'components/shared/Inventory/Domains/DomainVersionSelect/DomainVersionSelectRow'

export type DomainVersionSelectOption = {
  active: boolean
  label: ReactElement<typeof DomainVersionSelectRow>
  name: string
  value: string
}

const compareDomainVersionsByActiveAndName = (a: DomainVersionSelectOption, b: DomainVersionSelectOption) =>
  a.active === b.active ? (a.name > b.name ? 1 : -1) : a.active ? -1 : 1

const domainVersionToSelectOption = (version: DomainVersion, testedDomainVersionIds: string[]) => ({
  value: version.id,
  name: version.name,
  label: <DomainVersionSelectRow key={version.id} {...version} testedDomainVersionIds={testedDomainVersionIds} />,
  active: version.active
})

export const DomainVersionOptionsFactory = (
  versions: DomainVersion[],
  testedDomainVersionIds: string[]
): DomainVersionSelectOption[] => {
  return versions
    .map((version) => domainVersionToSelectOption(version, testedDomainVersionIds))
    .sort(compareDomainVersionsByActiveAndName)
}
