import { useTranslation } from 'react-i18next'

export type AdvancedFilterOperators = Array<keyof ReturnType<typeof useOperators>>

export function useOperators() {
  const { t } = useTranslation('common')

  return {
    is: t('advancedFilter.operators.is'),
    is_not: t('advancedFilter.operators.isNot'),
    bigger_than: t('advancedFilter.operators.isBiggerThan'),
    smaller_than: t('advancedFilter.operators.isSmallerThan'),
    before_date: t('advancedFilter.operators.isBefore'),
    after_date: t('advancedFilter.operators.isAfter'),
    contains: t('advancedFilter.operators.contains'),
    not_contains: t('advancedFilter.operators.containsNot')
  }
}
