import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ButtonType, ButtonTheme } from 'components/Button'
import { LinkButton } from 'components/LinkButton'
import { NetworksTable } from 'components/quickwrap/Setup/Networks/NetworksTable'
import { routesBuilder } from 'utilities/routesBuilder'

export const NetworksContainer = (): JSX.Element => {
  const { t } = useTranslation('setup')

  return (
    <Box className='networks' isExpandable={true}>
      <BoxHeader
        title={t('networks.list.title')}
        subtitle={t('networks.list.subtitle')}
        containerClassName={'box-header__container--space-between'}
        rowClassName={'header__row--width-100'}
      >
        <div>
          <LinkButton
            className='ml-4'
            type={ButtonType.Primary}
            theme={ButtonTheme.Orange}
            to={routesBuilder.quickwrap.setup.networks.new}
          >
            {t('networks.list.addNew')}
          </LinkButton>
        </div>
      </BoxHeader>
      <BoxBody>
        <NetworksTable />
      </BoxBody>
    </Box>
  )
}
