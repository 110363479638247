import { camelize } from './camelize'

export interface Error {
  path: string[]
  message: string
}

export interface Errors {
  raw: Error[]
  errorsFor(field: string | string[]): string[]
  nestedErrorsFor(field: string | string[]): string[]
  setError(field: string | string[], message: string): void
  removeError(field: string | string[], message: string): void
  deleteAt(index: number, field?: string | string[]): void
}

export class ErrorUtils implements Errors {
  public errors: Error[]

  constructor(errors: Error[]) {
    this.errors = errors
  }

  get raw(): Error[] {
    return this.errors
  }

  errorsFor(field: string | string[]): string[] {
    const path = Array.isArray(field) ? ['attributes', ...field] : ['attributes', field]

    const error = this.errors.find((err) => this.compareArrays(err.path, path))
    return error?.message?.split('; ') || []
  }

  nestedErrorsFor(field: string | string[]): string[] {
    const path = Array.isArray(field) ? ['attributes', ...field] : ['attributes', field]

    const errors = this.errors.filter(
      (err: Error) => err.path.length != path.length && this.compareArrays(err.path.slice(0, path.length), path)
    )
    return errors
      .map((err: Error) => err.message.split('; ').map((msg: string) => [err.path[err.path.length - 1], msg].join(' ')))
      .reduce((acc, val) => acc.concat(val), [])
  }

  setError(field: string | string[], message: string): void {
    const path = Array.isArray(field) ? ['attributes', ...field] : ['attributes', field]
    const index = this.errors.findIndex((err) => this.compareArrays(err.path, path))

    if (index < 0) {
      this.errors.push({ message: message, path: path })
    } else {
      this.errors[index].message = this.errors[index].message + `; ${message}`
    }
  }

  removeError(field: string | string[], message: string): void {
    const path = Array.isArray(field) ? ['attributes', ...field] : ['attributes', field]
    const index = this.errors.findIndex((err) => this.compareArrays(err.path, path))

    if (index > -1) {
      this.errors[index].message = this.errors[index].message
        .split('; ')
        .filter((msg) => !msg.match(message))
        .join('; ')
      this.errors = this.errors.filter((err) => err.message.length > 0)
    }
  }

  deleteAt(index: number, field?: string | string[]): void {
    let path = ['attributes']
    if (field) {
      path = path.concat(Array.isArray(field) ? field : [field])
    }
    path.push(String(index))

    const errorIndex = path.length - 1

    this.errors = this.errors.filter((err) => !path.every((el, ix) => el === err.path[ix]))
    this.errors = this.errors.map((err) => {
      if (path.slice(0, path.length - 1).every((el, ix) => el === err.path[ix])) {
        const currentPath = Number(err.path[errorIndex])
        if (currentPath >= index) {
          err.path[errorIndex] = String(currentPath - 1)
        }
      }
      return err
    })
  }

  private compareArrays(arr1: string[], arr2: string[]): boolean {
    return arr1.length === arr2.length && arr1.every((el, ix) => el === camelize(arr2[ix]))
  }
}
