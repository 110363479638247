import React, { useState, useContext, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { WorkspaceDeviceForm } from 'components/quickwrap/Setup/GeneralSettings'
import { WorkspaceDeviceSerializer } from 'serializers/WorkspaceDeviceSerializer'

import UpdateWorkspaceDevices from 'gql/mutations/workspaceDevices/UpdateWorkspaceDevices.gql'
import WorkspaceDevicesQuery from 'gql/queries/workspaceDevices/WorkspaceDevices.gql'

type WorkspaceDeviceContainerProps = {
  expandable?: boolean
}

export const WorkspaceDeviceContainer = ({ expandable = false }: WorkspaceDeviceContainerProps): JSX.Element => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [isSubmitting] = useState(false)
  const [workspaceDevices, setWorkspaceDevices] = useState<Array<WorkspaceDevice>>([])

  const workspaceDeviceSerializer = useMemo(() => new WorkspaceDeviceSerializer(workspaceDevices), [workspaceDevices])

  useQuery(WorkspaceDevicesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ workspaceDevices: { nodes } }) => {
      setWorkspaceDevices(nodes)
    }
  })

  const [updateWorkspaceDevices] = useMutation(UpdateWorkspaceDevices, {
    onCompleted: ({ updateWorkspaceDevices: { workspaceDevices, errors } }) => {
      if (workspaceDevices) {
        setWorkspaceDevices(workspaceDevices)
        createNotifier(t('sizeMapping.successMessage'), NotificationType.SUCCESS)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmitHandler = useCallback(
    (sizeMappings: WorkspaceDeviceFormData) => {
      updateWorkspaceDevices({
        variables: { input: { attributes: workspaceDeviceSerializer.updateParams(sizeMappings.sizeMapping) } }
      })
    },
    [updateWorkspaceDevices, workspaceDeviceSerializer]
  )

  const onRemoveHandler = (field: number) => {
    errors.deleteAt(field)
    setErrors(errors)
  }

  return (
    <Box isExpandable={expandable}>
      <BoxHeader title={t('sizeMapping.title')} subtitle={t('sizeMapping.subtitle')} />

      <BoxBody>
        <WorkspaceDeviceForm
          errors={errors}
          isSubmitting={isSubmitting}
          onSubmitHandler={onSubmitHandler}
          onRemoveHandler={onRemoveHandler}
          workspaceDevices={workspaceDevices}
        />
      </BoxBody>
    </Box>
  )
}
