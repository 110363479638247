import React from 'react'

import { Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { CommonInputs } from 'components/shared/CustomCode/CommonInputs'
import { Product } from 'constants/Product'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { CustomCodeSerializer } from 'serializers/CustomCodeSerializer'
import { Errors } from 'utilities/errorUtils'
import { useCurrentProduct } from 'utilities/products/current'

interface Props {
  existingCustomCode?: CustomCode
  onSubmit: (form: CustomCodeBasicSubmitForm) => void
  isSubmitting: boolean
  errors: Errors
}

export const CustomCodeTemplateForm: React.FC<Props> = ({ existingCustomCode, onSubmit, errors, isSubmitting }) => {
  const { t } = useTranslation('setup')
  const product: Product = useCurrentProduct()

  return (
    <div className='custom-code-form'>
      <Formik initialValues={existingCustomCode || CustomCodeSerializer.emptyParams()} onSubmit={onSubmit}>
        {({ dirty }) => (
          <StatusForm name={`${product}-custom-code-template`} dirty={dirty}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Field
                  id='custom-code-name'
                  name='name'
                  type='text'
                  label={t('customCodes.form.name.label')}
                  component={FormikInput}
                  errors={errors.errorsFor('name')}
                  labelStyle='bold'
                />
              </Grid>
              <CommonInputs errors={errors} />
            </Grid>

            <div className='custom-code-form__submit-section'>
              <Button
                disabled={isSubmitting || !dirty}
                type={ButtonType.Primary}
                theme={ButtonTheme.Orange}
                buttonType='submit'
              >
                {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
              </Button>
            </div>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
