import React, { useState } from 'react'

import { DynamicListInputFilter } from './DynamicListInputFilter'
import { PreviewListElement } from './PreviewListElement'

type DynamicListInputPreviewType = {
  clearHandler: (items: string[]) => void
  items: string[]
  placeholder: string
}

export const DynamicListInputPreview = ({
  clearHandler,
  items,
  placeholder
}: DynamicListInputPreviewType): JSX.Element => {
  const [filter, setFilter] = useState('')

  const clearFilterHandler = () => {
    clearHandler(items)
  }

  const filterHandler = (filter: string) => setFilter(filter)

  return (
    <div className='DynamicListInput__section'>
      <DynamicListInputFilter
        clearHandler={clearFilterHandler}
        filterHandler={filterHandler}
        placeholder={placeholder}
      />
      <div className='DynamicListInput__preview'>
        {items
          .filter((item) => !filter || item.includes(filter))
          .map((item) => (
            <PreviewListElement key={item} item={item} clearFilterHandler={clearHandler} />
          ))}
      </div>
    </div>
  )
}
