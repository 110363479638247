import { TFunction } from 'i18next'
import { CmAttributes } from 'webapp/pages/oss/Inventory/Domains/NewPage'
import { Errors } from 'webapp/utils/errorUtils'

export type TCFSectionProps = {
  errors: Errors
  placement: TCFSectionPlacement
  values: OSSConsentManagementFormData | CmAttributes
}

export enum TCFSectionPlacement {
  ADD_NEW_DOMAIN,
  EDIT_DOMAIN
}

export type FieldProps = {
  accessor: string
  errors?: Errors
  t: TFunction
}
