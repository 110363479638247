class FormStatus {
  private _statuses: { [key: string]: boolean }

  constructor() {
    this._statuses = {}
  }

  getStatus(form: string) {
    return this._statuses[form]
  }

  setStatus(form: string, dirty: boolean) {
    this._statuses[form] = dirty
  }

  reset(form: string) {
    delete this._statuses[form]
  }

  clear() {
    Object.keys(this._statuses).forEach((key) => {
      delete this._statuses[key]
    })
  }

  get isAnyDirty(): boolean {
    return !!Object.keys(this._statuses).find((form) => this._statuses[form])
  }
}

export const formStatus = Object.freeze(new FormStatus())
