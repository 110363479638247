import React, { useCallback, useContext } from 'react'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ToggleSwitch } from 'components/Form'
import { UserContext } from 'webapp/context/UserContext'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import UpdateWorkspace from 'gql/mutations/workspaces/UpdateWorkspace.gql'

interface Props {
  row: Row<Workspace>
}

export const AdNetworkCell: React.FC<Props> = ({ row }) => {
  const isAdNetworkEnabled = Boolean(row.original.adNetworkEnabled)

  const { t } = useTranslation('workspaces')
  const history = useHistory<History>()

  const { reloadUser } = useContext(UserContext)
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [updateWorkspace] = useMutation(UpdateWorkspace, {
    onCompleted: ({ updateWorkspace: { workspace } }) => {
      if (workspace) {
        reloadUser()
        createNotifier(t('list.adNetwork.successMessage'), NotificationType.SUCCESS)
        history.push(history.location)
      }
    }
  })

  const onChangeHandler = useCallback(() => {
    updateWorkspace(nestGqlInput({ workspaceId: row.original.id, adNetworkEnabled: !isAdNetworkEnabled }))
  }, [row.original.id, isAdNetworkEnabled, updateWorkspace])

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        key={'adnetwork'}
        id={`adnetwork-${row.id}`}
        name={`adnetwork-${row.id}`}
        checked={isAdNetworkEnabled}
        onChange={onChangeHandler}
        className='adnetwork__switch'
        labelClassName={`adnetwork-${row.id}`}
      />
    </div>
  )
}
