import { useTranslation } from 'react-i18next'

import { FilterType } from 'webapp/constants/FilterType'

export const useFilterConfig = (): AdvancedFilterConfiguration[] => {
  const { t } = useTranslation('inventory')

  return [
    {
      label: t('filters.label.adUnitPath'),
      name: 'oss_ad_unit.ad_unit_path',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.adUnitPath'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.adUnitDivId'),
      name: 'oss_ad_unit.element_id',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.adUnitDivId'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.pagePath'),
      name: 'oss_ad_unit.oss_pages.path',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.pagePath'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.versionName'),
      name: 'domain_version.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.versionName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.domainName'),
      name: 'domain_version.domain.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.domainName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.bidder'),
      name: 'bidder_settings.bidder_code',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.bidder'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.deviceName'),
      name: 'media_types.workspace_device.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.deviceName'),
      type: FilterType.Input
    }
  ]
}
