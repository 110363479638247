import React from 'react'

import { BidderFormContainer } from 'components/BidderForm/BidderFormContainer'
import { Section, StatusForm } from 'components/Form'
import { ParamFormLogo } from 'components/Form/ParamFormSection/ParamFormLogo'

type WorkspacePrebidModuleType = {
  onCheckboxChangeHandler: () => void
  value: boolean
  workspacePrebidModule: PrebidModule
}

export const WorkspacePrebidModule = ({
  onCheckboxChangeHandler,
  value,
  workspacePrebidModule
}: WorkspacePrebidModuleType): JSX.Element => {
  return (
    <>
      <StatusForm
        name={`workspacePrebidModule-${workspacePrebidModule.id}`}
        dirty={false}
        Container={BidderFormContainer}
      >
        <Section className='param-form param-form--no-params'>
          <input type='checkbox' className='param-form__connected' checked={value} onChange={onCheckboxChangeHandler} />

          <ParamFormLogo item={workspacePrebidModule} isSaved={true} />
        </Section>
      </StatusForm>
    </>
  )
}
