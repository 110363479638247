import React from 'react'
import classNames from 'classnames'
import { Row } from 'react-table'
import { useTranslation } from 'react-i18next'

import { BidderDemandStatus, DomainDemandStatus, DemandStatusRow } from 'components/sharedInterfaces/DemandStatus'

interface Props {
  bidder: string
  row: Row<DemandStatusRow>
}

const StatusCell: React.FC<Props> = ({ bidder, row }) => {
  const { t } = useTranslation('setup')
  const demandStatus = row.original.demandStatus.find(
    (status: DomainDemandStatus): boolean => status.bidder.name === bidder
  )

  if (!demandStatus) {
    return <div>-</div>
  }

  const completed = demandStatus.statuses.find(
    (status: BidderDemandStatus): boolean => status.name === 'completed'
  ).adUnitsCount
  const total = demandStatus.statuses
    .map((status: BidderDemandStatus): number => status.adUnitsCount)
    .reduce((acc: number, curr: number): number => acc + curr)
  const status = total > 0 && completed > 0 ? 'completed' : 'requested'

  return (
    <>
      <div className={classNames('status-cell', `status-cell--${status}`)}>
        {t(`yieldbirdDemand.status.${status}`)}
        {status === 'completed' && (
          <span className='status-cell__details'>
            ({completed}/{total})
          </span>
        )}
      </div>
    </>
  )
}

export default StatusCell
