import React from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import NameFilter from 'components/NameFilter'
import { CollectionNames } from 'constants/CollectionNames'
import { Product } from 'constants/Product'
import { Can } from 'context/AbilityContext'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import AdUnitsTable from '../AdUnitsTable'
import { AdUnitsTableContainerProps } from './types'
import { useFilterConfig } from './useFilterConfig'

const COLLECTION = CollectionNames.OssAdUnits

export const AdUnitsTableContainer = ({ domainId }: AdUnitsTableContainerProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const history = useHistory()
  const filterConfig = useFilterConfig()

  const redirectToAdd = (): void =>
    history.push(routesBuilder.oss.inventory.adUnit.add(domainId), { fromProduct: Product.Revive })

  return (
    <Box list>
      <BoxHeader title={t('adUnits.list.title')} rowClassName='header__row--width-100'>
        <Can I={AbilityAction.EDIT} a={AbilitySubject.ADUNIT}>
          <Button onClickHandler={redirectToAdd} theme={ButtonTheme.Red} type={ButtonType.Primary}>
            {t('common:buttons.add')}
          </Button>
        </Can>

        <div className='box-header__filters'>
          <AdvancedFilter
            alignPopup='right'
            collectionName={COLLECTION}
            config={filterConfig}
            theme={ButtonTheme.Red}
          />

          <NameFilter
            collection={COLLECTION}
            operator={'contains'}
            path={'ad_unit_path'}
            placeholder={t('adUnits.list.search.byAdUnitPath')}
          />
        </div>
      </BoxHeader>

      <AdUnitsTable domainId={domainId} />
    </Box>
  )
}
