import { Product } from 'webapp/constants/Product'
import { RouteProductPrefix } from 'webapp/constants/RouteProductPrefix'

export const viewabilityBoosterRoutes = {
  viewability_booster: {
    performance: {
      dataStudio: `/${RouteProductPrefix[Product.ViewabilityBooster]}/performance/data-studio`,
      root: `/${RouteProductPrefix[Product.ViewabilityBooster]}/performance`
    }
  }
}
