/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class ABTestSerializer {
  constructor(public formData: ABTestFormData, public existingRecord?: ABTest) {}

  createParams() {
    return this.prepareBody()
  }

  updateParams() {
    return {
      abTestId: this.formData.id,
      ...this.prepareBody()
    }
  }

  private prepareBody() {
    return {
      domainId: this.formData.domainId.value || '',
      name: this.formData.name,
      abTestVersionsAttributes: this.getAbTestVersionsAttributes()
    }
  }

  private getAbTestVersionsAttributes() {
    const versionsToSave = this.formData.abTestVersionsAttributes.map((abTestVersionsAttribute) => ({
      id: abTestVersionsAttribute.id || undefined,
      domainVersionId: abTestVersionsAttribute.domainVersionId?.value || '',
      share: Number(abTestVersionsAttribute.share)
    }))

    let versionsToDelete: { id: string; _destroy: boolean }[] = []
    if (this.existingRecord) {
      versionsToDelete = this.existingRecord.abTestVersions
        .filter((version) => !versionsToSave.find((versionToSave) => version.id === versionToSave.id))
        .map((version) => ({
          id: version.id,
          _destroy: true
        }))
    }

    return [...versionsToSave, ...versionsToDelete]
  }

  static startTestParams = (abTest: ABTest) => {
    return {
      abTestId: abTest.id,
      active: true
    }
  }

  static endTestParams = (formData: ABEndTestFormData) => {
    return {
      abTestId: formData.id,
      active: false,
      activateDomainVersionId: formData.activateDomainVersionId?.value
    }
  }
}
