import React, { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { LoadingContainer } from 'components/LoadingContainer'
import differenceBy from 'lodash/differenceBy'
import { NO_CACHE } from 'utilities/apolloClient'
import { Container } from './components'
import { convertOssAdUnitsToCS, convertOssPagesToCS } from './service'
import * as T from './types'

import { ACTIVE_OSS_PAGES_FOR_AD_UNIT_CONNECTING_TABLE } from 'gql/queries/adUnits/activeOssPagesForAdUnitConnectingTable'
import { OSS_AD_UNITS_FOR_PAGE_CONNECTING_TABLE } from 'gql/queries/adUnits/ossAdUnitsForPageConnectingTable'
import { ACTIVE_OSS_AD_UNITS_FOR_PAGE_CONNECTING_TABLE } from 'gql/queries/pages/activeOssAdUnitsForPageConnectingTable'
import { OSS_PAGES_FOR_AD_UNIT_CONNECTING_TABLE } from 'gql/queries/pages/ossPagesForAdUnitConnectingTable'

import './PagesAdUnitsConnectingTable.scss'

const PagesAdUnitsConnectingTable = (props: T.PagesAdUnitsConnectingTableProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const [ossAdUnits, setOssAdUnits] = useState<T.CommonSlice[]>()
  const [activeOssAdUnits, setActiveOssAdUnits] = useState<T.CommonSlice[]>()
  const [ossPages, setOssPages] = useState<T.CommonSlice[]>()
  const [activeOssPages, setActiveOssPages] = useState<T.CommonSlice[]>()
  const [filter, setFilter] = useState<string>('')
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false)
  const [isDataReady, setIsDataReady] = useState<boolean>(false)

  const { domainId, onChange, onInitActives, tableFor } = props

  const setByName: T.SetByNameProps = {
    ossAdUnits: setOssAdUnits,
    activeOssAdUnits: setActiveOssAdUnits,
    ossPages: setOssPages,
    activeOssPages: setActiveOssPages
  }

  useEffect(() => {
    if (tableFor === 'OSS_PAGE') {
      fetchOssAdUnits({ variables: { domainId } })

      const { ossPageId } = props
      if (ossPageId) {
        fetchActiveOssAdUnits({ variables: { ossPageId } })
      } else {
        setActiveOssAdUnits([])
      }
    } else if (tableFor === 'OSS_AD_UNIT') {
      fetchOssPages({ variables: { domainId } })

      const { ossAdUnitId } = props
      if (ossAdUnitId) {
        fetchActiveOssPages({ variables: { ossAdUnitId } })
      } else {
        setActiveOssPages([])
      }
    }
  }, [domainId, tableFor]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDataFetched && ((ossAdUnits && activeOssAdUnits) || (ossPages && activeOssPages))) {
      setIsDataFetched(true)
    }
  }, [isDataFetched, ossAdUnits, activeOssAdUnits, ossPages, activeOssPages]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Exclude actives from all
    if (isDataFetched && !isDataReady) {
      if (tableFor === 'OSS_PAGE' && ossAdUnits && activeOssAdUnits) {
        setOssAdUnits(differenceBy(ossAdUnits, activeOssAdUnits, 'id'))
      } else if (tableFor === 'OSS_AD_UNIT' && ossPages && activeOssPages) {
        setOssPages(differenceBy(ossPages, activeOssPages, 'id'))
      }
      setIsDataReady(true)
    }
  }, [isDataFetched, isDataReady, ossAdUnits, activeOssAdUnits, ossPages, activeOssPages]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDataFetched && isDataReady) {
      if (tableFor === 'OSS_AD_UNIT' && activeOssPages) {
        onChange(activeOssPages.map((ops: T.CommonSlice) => ops.id))
      } else if (tableFor === 'OSS_PAGE' && activeOssAdUnits) {
        onChange(activeOssAdUnits.map((oaus: T.CommonSlice) => oaus.id))
      }
    }
  }, [isDataFetched, isDataReady, ossAdUnits, activeOssAdUnits, ossPages, activeOssPages]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDataReady) {
      if (tableFor === 'OSS_AD_UNIT' && activeOssPages) {
        onInitActives(activeOssPages.map((ops: T.CommonSlice) => ops.id))
      } else if (tableFor === 'OSS_PAGE' && activeOssAdUnits) {
        onInitActives(activeOssAdUnits.map((oaus: T.CommonSlice) => oaus.id))
      }
    }
  }, [isDataReady]) // eslint-disable-line react-hooks/exhaustive-deps

  const [fetchOssAdUnits] = useLazyQuery<T.OssAdUnitsData, T.OssPagesOrAdUnitsVars>(
    OSS_AD_UNITS_FOR_PAGE_CONNECTING_TABLE,
    {
      ...NO_CACHE,
      onCompleted: (data: T.OssAdUnitsData): void => setOssAdUnits(convertOssAdUnitsToCS(data.ossAdUnits.nodes))
    }
  )

  const [fetchActiveOssAdUnits] = useLazyQuery<T.ActiveOssAdUnitsData, T.ActiveOssAdUnitsVars>(
    ACTIVE_OSS_AD_UNITS_FOR_PAGE_CONNECTING_TABLE,
    {
      ...NO_CACHE,
      onCompleted: (data: T.ActiveOssAdUnitsData): void =>
        setActiveOssAdUnits(convertOssAdUnitsToCS(data.ossPage.ossAdUnits.nodes))
    }
  )

  const [fetchOssPages] = useLazyQuery<T.OssPagesData, T.OssPagesOrAdUnitsVars>(
    OSS_PAGES_FOR_AD_UNIT_CONNECTING_TABLE,
    {
      ...NO_CACHE,
      onCompleted: (data: T.OssPagesData): void => setOssPages(convertOssPagesToCS(data.ossPages.nodes))
    }
  )

  const [fetchActiveOssPages] = useLazyQuery<T.ActiveOssPagesData, T.ActiveOssPagesVars>(
    ACTIVE_OSS_PAGES_FOR_AD_UNIT_CONNECTING_TABLE,
    {
      ...NO_CACHE,
      onCompleted: (data: T.ActiveOssPagesData): void =>
        setActiveOssPages(convertOssPagesToCS(data.ossAdUnit.ossPages.nodes))
    }
  )

  const Loading = (): JSX.Element => <LoadingContainer loading />

  if (tableFor) {
    let data: T.DataSlicesByTableFor

    if (ossAdUnits && activeOssAdUnits) {
      data = { tableFor: 'OSS_PAGE', ossAdUnits, activeOssAdUnits }
    } else if (ossPages && activeOssPages) {
      data = { tableFor: 'OSS_AD_UNIT', ossPages, activeOssPages }
    } else return <Loading />

    return Container({ data, setByName, filterState: { filter, setFilter }, t })
  } else return <Loading />
}

export default PagesAdUnitsConnectingTable
