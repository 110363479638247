import React from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import NameFilter from 'components/NameFilter'
import { CollectionNames } from 'constants/CollectionNames'
import { Product } from 'constants/Product'
import { Can } from 'context/AbilityContext'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { ConfigurationsTable } from '../ConfigurationsTable/ConfigurationsTable'
import { useFilterConfig } from './useFilterConfig'

const COLLECTION = CollectionNames.PriceGeniusDomainConfigurations

export const ConfigurationContainer = (): JSX.Element => {
  const { t } = useTranslation('priceGenius')
  const history = useHistory()
  const filterConfig = useFilterConfig()

  const redirectToOssAddDomain = (): void =>
    history.push(routesBuilder.oss.inventory.domains.new, { fromProduct: Product.PriceGenius })

  return (
    <Box list>
      <BoxHeader title={t('configuration.header.title')} rowClassName='header__row--width-100'>
        <Can I={AbilityAction.ADD} a={AbilitySubject.DOMAIN}>
          <Button onClickHandler={redirectToOssAddDomain} theme={ButtonTheme.BlueDark} type={ButtonType.Primary}>
            {t('configuration.header.addDomain')}
          </Button>
        </Can>

        <div className='box-header__filters'>
          <AdvancedFilter
            alignPopup='right'
            collectionName={COLLECTION}
            config={filterConfig}
            theme={ButtonTheme.BlueDark}
          />

          <NameFilter
            collection={COLLECTION}
            operator={'contains'}
            path={'domain.name'}
            placeholder={t('configuration.header.searchByDomain')}
          />
        </div>
      </BoxHeader>

      <ConfigurationsTable />
    </Box>
  )
}
