import { gql } from '@apollo/client'

import { TABLE_INPUTS_N_RESOLVERS } from 'gql/fragments/common'
import { PRICE_GENIUS_DOMAIN_CONFIGURATION_FRAGMENT } from 'gql/fragments/priceGenius/PriceGeniusDomainConfigurationFragment'

export const PRICE_GENIUS_DOMAIN_CONFIGURATIONS = gql`
  ${PRICE_GENIUS_DOMAIN_CONFIGURATION_FRAGMENT}

  query pgDomainConfigurations(${TABLE_INPUTS_N_RESOLVERS[0]}) {
    pgDomainConfigurations(${TABLE_INPUTS_N_RESOLVERS[1]}) {
      totalCount
      nodes {
        ...PriceGeniusDomainConfigurationFragment
      }
    }
  }
`
