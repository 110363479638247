import React, { ChangeEvent } from 'react'
import clsx from 'clsx'
import { Field, FieldProps } from 'formik'

import { ButtonType, ButtonTheme } from 'components/Button'

type Props = {
  option: SelectOption
  isSelected: boolean
  name: string
  onChange?: () => void
}

const labelClassNames = `button button--${ButtonType.Secondary} checkbox-group__checkbox-item`

export const CheckboxItem: React.FC<Props> = ({ option, isSelected, name, onChange }) => {
  const itemClassNames = clsx(
    labelClassNames,
    isSelected ? `button--${ButtonTheme.Blue}` : `button--${ButtonTheme.BlueReverse}`
  )

  return (
    <label key={option.value} className={itemClassNames}>
      <Field type='checkbox' name={name} className='checkbox-group__checkbox' value={option.value}>
        {({ field }: FieldProps) => {
          const inputAttributes = {
            ...field,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              field.onChange(e)
              onChange?.()
            }
          }

          return (
            <div>
              <input type='checkbox' className='checkbox-group__checkbox' {...inputAttributes} />
            </div>
          )
        }}
      </Field>
      <span className='button__content'>{option.label}</span>
    </label>
  )
}
