import React, { useContext, useEffect, useState } from 'react'

import { Box } from 'components/Box'
import ProductConfirmationModal from 'components/oss/Products/ProductConfirmationModal'
import { ProductList } from 'components/oss/Products/ProductList'
import { UserContext } from 'webapp/context/UserContext'
import usePrevious from 'webapp/hooks/usePrevious'

import './style.scss'

const findChangedProductConnection = (
  userProductConnections: ProductConnection[],
  previousUserProductConnections: ProductConnection[]
): ProductConnection | null =>
  userProductConnections.find((upc: ProductConnection) =>
    previousUserProductConnections.find((pupc: ProductConnection) => pupc.id === upc.id && pupc.status !== upc.status)
  ) ?? null

export const ProductsContainer: React.FC = () => {
  const { user } = useContext(UserContext)

  const [userProductConnections, setUserProductConnections] = useState<ProductConnection[] | null>(null)
  const previousUserProductConnections = usePrevious<ProductConnection[] | null>(userProductConnections)
  const [changedProductConnection, setChangedProductConnection] = useState<ProductConnection | null>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

  useEffect(() => {
    setUserProductConnections(user?.workspace?.productConnections ?? null)
  }, [user])

  useEffect(() => {
    if (userProductConnections && previousUserProductConnections) {
      setChangedProductConnection(findChangedProductConnection(userProductConnections, previousUserProductConnections))
    }
  }, [userProductConnections]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (changedProductConnection) {
      setShowConfirmationModal(true)
    }
  }, [changedProductConnection])

  if (!userProductConnections) {
    return null
  }

  return (
    <Box className='ProductsContainer'>
      {changedProductConnection && (
        <ProductConfirmationModal
          productConnection={changedProductConnection}
          setVisible={setShowConfirmationModal}
          visible={showConfirmationModal}
        />
      )}

      <ProductList onlyActive productConnections={userProductConnections} />

      <ProductList productConnections={userProductConnections} />
    </Box>
  )
}
