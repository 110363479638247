import React from 'react'
import pluralize from 'pluralize'
import { Grid } from '@material-ui/core'
import { Options } from 'react-select'

interface Props {
  name: string
  values: Options<{ label: string; value: string }>
}

export const ValueContainerContent: React.FC<Props> = ({ name, values }) => {
  const mainLabel = values[0]?.label
  const groupLabelString = values.length > 2 ? pluralize.plural(name) : pluralize.singular(name)
  const groupLabel = values.length > 1 ? `+ ${values.length - 1} ${groupLabelString}` : undefined

  return (
    <Grid container justifyContent='space-between'>
      <Grid item>{mainLabel}</Grid>
      <Grid className='ValueContainerContent__secondaryLabel' item>
        {groupLabel}
      </Grid>
    </Grid>
  )
}
