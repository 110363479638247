import React from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { CommentFormFactory } from './formSchema'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { Product } from 'webapp/constants/Product'

interface Props {
  commentable: Commentable
  isSubmitting: boolean
  onSubmit: (values: CommentFormData, formikBag: FormikHelpers<CommentFormData>) => Promise<void>
  errors: ErrorUtils
  product: Product
}

export const CommentForm: React.FC<Props> = ({ commentable, isSubmitting, onSubmit, errors, product }) => {
  const { t } = useTranslation('inventory')

  return (
    <Formik initialValues={CommentFormFactory(commentable)} onSubmit={onSubmit}>
      {(values) => (
        <Form className='comment-form'>
          <Field
            tooltipContent={t('domains.edit.comments.form.placeholder')}
            className={'comment-form__input'}
            component={FormikInput}
            errors={errors.errorsFor('content')}
            label={t('domains.edit.comments.form.label')}
            name='content'
            type='textarea'
            placeholder={t('domains.edit.comments.form.placeholder')}
          />
          <div className='comment-form__button-wrap'>
            <Button
              buttonType='submit'
              disabled={isSubmitting || !values.values.content.trim()}
              type={ButtonType.Primary}
              theme={product === Product.Quickwrap ? ButtonTheme.Orange : ButtonTheme.Blue}
            >
              {isSubmitting ? t('domains.edit.comments.form.submitting') : t('domains.edit.comments.form.submit')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
