import { useEffect, useState } from 'react'

import { useCurrentProduct } from 'utilities/products/current'

/**
 * This masks barely noticeable product name flickering during product switching
 */
export const useDelayedCurrentProduct = () => {
  const currentProduct = useCurrentProduct()
  const [delayedCurrentProduct, setDelayedCurrentProduct] = useState(useCurrentProduct())

  useEffect(() => {
    setTimeout(() => {
      setDelayedCurrentProduct(currentProduct)
    }, 100)
  }, [currentProduct])

  return delayedCurrentProduct
}
