import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldConfig, useField } from 'formik'
import { Grid } from '@material-ui/core'

import { WorkspacePrebidModule } from './WorkspacePrebidModule'
import { Errors, ErrorUtils } from 'utilities/errorUtils'

type FormikDomainVersionPrebidModulesContainerType = {
  errors: Errors
  field: FieldConfig
  setErrors: (errors: ErrorUtils) => void
  setManualDirty: (value: boolean) => void
  workspacePrebidModules: WorkspacePrebidModule[]
}

const sortWorkspacePrebidModules = (a: WorkspacePrebidModule, b: WorkspacePrebidModule) =>
  a.prebidModule.name < b.prebidModule.name ? -1 : 1

export const FormikDomainVersionPrebidModulesContainer = ({
  errors,
  setErrors,
  setManualDirty,
  workspacePrebidModules,
  ...other
}: FormikDomainVersionPrebidModulesContainerType): JSX.Element => {
  const [field, , helpers] = useField(other.field.name)
  const { t } = useTranslation('inventory')

  const groupWorkspacePrebidModules = (workspacePrebidModules: WorkspacePrebidModule[]) => {
    const groupedWorkspacePrebidModules: { [key: string]: WorkspacePrebidModule[] } = {}

    workspacePrebidModules.forEach((workspacePrebidModule: WorkspacePrebidModule) => {
      groupedWorkspacePrebidModules[workspacePrebidModule.prebidModule.kind] ||= []
      groupedWorkspacePrebidModules[workspacePrebidModule.prebidModule.kind].push(workspacePrebidModule)
    })

    return groupedWorkspacePrebidModules
  }

  const onWorkspacePrebidModuleSelectionChange = (workspacePrebidModule: WorkspacePrebidModule) => {
    if (field.value.includes(workspacePrebidModule.id)) {
      helpers.setValue(field.value.filter((id: string) => id !== workspacePrebidModule.id))
    } else {
      helpers.setValue(field.value.concat(workspacePrebidModule.id))
    }
  }

  const isChecked = (workspacePrebidModule: WorkspacePrebidModule) => field.value.includes(workspacePrebidModule.id)

  return (
    <Grid item xs={12}>
      {workspacePrebidModules && (
        <Grid item lg={10} md={12} xs={12}>
          {Object.entries(groupWorkspacePrebidModules(workspacePrebidModules)).map(
            ([kind, workspacePrebidModules]: [string, WorkspacePrebidModule[]]) => (
              <div key={kind}>
                <h3 className='form-section__title'>
                  {t(`domains.edit.domainVersionPrebidModules.prebidModuleGroups.${kind}`)}
                </h3>
                {workspacePrebidModules
                  .sort(sortWorkspacePrebidModules)
                  .map((workspacePrebidModule: WorkspacePrebidModule) => (
                    <WorkspacePrebidModule
                      key={workspacePrebidModule.id}
                      onCheckboxChangeHandler={() => onWorkspacePrebidModuleSelectionChange(workspacePrebidModule)}
                      value={isChecked(workspacePrebidModule)}
                      workspacePrebidModule={workspacePrebidModule.prebidModule}
                    />
                  ))}
              </div>
            )
          )}
        </Grid>
      )}
    </Grid>
  )
}
