import React, { useMemo } from 'react'
import classNames from 'classnames'

import { Label } from 'components/Form'

interface Props {
  name: string
  id: string
  checked: boolean
  disabled?: boolean
  label?: string
  labelClassName?: string
  className?: string
  error?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox: React.FC<Props> = ({
  name,
  disabled,
  id,
  label,
  labelClassName,
  checked,
  children,
  className,
  error,
  onChange
}) => {
  const classString = useMemo(() => {
    return classNames('qw-checkbox', className, {
      'qw-checkbox--checked': checked,
      'qw-checkbox--disabled': disabled
    })
  }, [checked, disabled, className])

  const labelClass = classNames('qw-checkbox__label', labelClassName)

  return (
    <div className={classString}>
      <input
        type='checkbox'
        name={name}
        id={id}
        className='qw-checkbox__input'
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <Label className={labelClass} htmlFor={id} disabled={disabled}>
        <span className='qw-checkbox__checkmark' />
        {label && <span className='qw-checkbox__label-text'>{label}</span>}
        {children}
      </Label>
      {error && <span className='qw-checkbox__error'>{error}</span>}
    </div>
  )
}
