import React from 'react'
import { useField, FieldConfig } from 'formik'

import { DateRangePicker } from 'components/DateRangePicker'
import { Label } from 'components/Form'

interface Props {
  defaultStartDate?: Date
  defaultEndDate?: Date
  getDateRange?: (startDate: Date | null, endDate: Date | null) => void
  label: string
  tooltipContent?: string
  disabled?: boolean
  field: FieldConfig
}

export const FormikDateRangePickerContainer: React.FC<Props> = ({
  getDateRange,
  label,
  tooltipContent,
  disabled,
  defaultStartDate,
  defaultEndDate,
  ...other
}) => {
  const [, , helpers] = useField(other.field.name)

  const onGetDateRangeHandler = (startDate: Date | null, endDate: Date | null) => {
    helpers.setValue({ startDate, endDate })
    getDateRange && getDateRange(startDate, endDate)
  }

  return (
    <>
      <Label htmlFor='date-range-picker' tooltipContent={tooltipContent} disabled={disabled}>
        {label}
      </Label>
      <DateRangePicker
        getDateRange={onGetDateRangeHandler}
        disabled={disabled}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
      />
    </>
  )
}
