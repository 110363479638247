import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { Field, useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { Errors } from 'utilities/errorUtils'
import { FormikSelectContainer, FormikShareInputContainer } from 'containers/FormikContainers'
import { getDomainVersionOption } from '../formSchema'

interface Props {
  active: boolean
  domainVersions: DomainVersion[]
  errors: Errors
  index: number
}

export const ABTestVersionsRow: React.FC<Props> = ({ active, domainVersions, errors, index }) => {
  const { t } = useTranslation('abTesting')
  const isFirstRow = useMemo(() => index === 0, [index])
  const [fieldInput] = useField<ABTestFormData['abTestVersionsAttributes']>('abTestVersionsAttributes')
  const [domainVersionIdFieldInput] = useField(`abTestVersionsAttributes.${index}.domainVersionId`)

  const domainVersionOptions = useMemo(() => domainVersions.map(getDomainVersionOption), [domainVersions])

  const filteredDomainVersionOptions = useMemo((): SelectOption<string>[] => {
    const selectedVersions = fieldInput.value.map((version) => version.domainVersionId?.value)
    return domainVersionOptions?.filter((version) => !selectedVersions.includes(version.value)) ?? []
  }, [domainVersionOptions, fieldInput.value])

  const domainVersionOptionsWithSelectedPrepended = useMemo(() => {
    if (domainVersionIdFieldInput.value) {
      return [domainVersionIdFieldInput.value, ...filteredDomainVersionOptions]
    }
    return filteredDomainVersionOptions
  }, [filteredDomainVersionOptions, domainVersionIdFieldInput.value])

  const selectedDomainVersionCounter = domainVersionIdFieldInput.value
    ? domainVersions.find(({ id }) => id === domainVersionIdFieldInput.value.value)?.counter
    : undefined

  return (
    <>
      <Grid item md={5} lg={4} xl={3}>
        <Field
          id={`ab-test-domainVersionId-${index}`}
          name={`abTestVersionsAttributes.${index}.domainVersionId`}
          component={FormikSelectContainer}
          disabled={active}
          errors={errors.errorsFor(['abTestVersionsAttributes', index.toString(), 'domainVersionId'])}
          label={isFirstRow && t('form.inputs.domainVersionId.label')}
          labelStyle='bold'
          options={domainVersionOptionsWithSelectedPrepended}
          tooltipContent={isFirstRow && t('form.inputs.domainVersionId.tooltip')}
        />
      </Grid>

      <Grid item md={5} lg={4} xl={3}>
        <Field
          id={`ab-test-share-${index}`}
          name={`abTestVersionsAttributes.${index}.share`}
          component={FormikShareInputContainer}
          containerClassName={'ab-test-share__container'}
          disabled={active}
          errors={errors.errorsFor(['abTestVersionsAttributes', index.toString(), 'share'])}
          label={isFirstRow && t('form.inputs.share.label')}
          labelStyle='bold'
          tooltipContent={isFirstRow && t('form.inputs.share.tooltip')}
        />
      </Grid>

      <Grid item md={1} lg={1} xl={1} className='key-value'>
        qw_ver = {selectedDomainVersionCounter}
      </Grid>
    </>
  )
}
