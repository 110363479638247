import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'gql/fragments/common'
import { DOMAIN_FRAGMENT } from 'gql/fragments/domains/DomainFragment'

const PAYLOAD_TYPE = 'UpsertCustomCodePayload'

export const UPSERT_CUSTOM_CODE = gql`
  ${DOMAIN_FRAGMENT}
  ${MUTATION_STATE(PAYLOAD_TYPE)}

  mutation UpsertCustomCode($input: UpsertCustomCodeInput!) {
    upsertCustomCode(input: $input) {
      domain {
        ...DomainFragment
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
