import { Product } from './Product'

export const RouteProductPrefix: Record<Product, string> = {
  [Product.FillRateBooster]: 'unfilled-recovery',
  [Product.OSS]: 'yieldbird-platform',
  [Product.PriceGenius]: 'price-genius',
  [Product.Quickwrap]: 'prebid-stack',
  [Product.Revive]: 'refresher',
  [Product.ViewabilityBooster]: 'viewability-tools'
}
