import React, { useContext } from 'react'

import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { UploadFileButton } from 'components/Button/UploadFileButton'
import { AdUnitsTable } from 'components/quickwrap/Inventory/AdUnits/Table/AdUnitsTable'
import { NotificationType } from 'components/ToastNotifier'
import { Product } from 'constants/Product'
import { BulkUploadStatusContainer } from 'containers/quickwrap/Inventory/Domains/BulkUploadStatusContainer'
import { Download } from 'icons/Download'
import { useHistory } from 'react-router'
import { downloadFileFromUrl } from 'utilities/downloadBlob'
import { isEmpty } from 'utilities/isEmpty'
import { routesBuilder } from 'utilities/routesBuilder'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { useBulkUploadAdUnitJob } from './useBulkUploadAdUnitJob'

import BulkUploadTemplate from 'gql/queries/domains/BulkUploadTemplate.gql'

interface Props {
  domain: Domain
  readOnly?: boolean
}

export const AdUnitsContainer: React.FC<Props> = ({ domain, readOnly }) => {
  const { t } = useTranslation('inventory')
  const history = useHistory()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { clearBulkUploadStatus, createBulkUploadAdUnitJob, isSubmitting, message } = useBulkUploadAdUnitJob()

  const redirectToAdd = () =>
    void history.push(routesBuilder.oss.inventory.adUnit.add(domain.id), { fromProduct: Product.Quickwrap })

  const [bulkUploadTemplate] = useLazyQuery(BulkUploadTemplate, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ bulkUploadTemplate }) => {
      const filename = `${domain.name}_${domain.versionName}_bulk_upload_template.xlsx`
      downloadFile(bulkUploadTemplate, filename)
    }
  })

  const downloadFile = async (url: string, filename: string) => {
    const response = await downloadFileFromUrl(url, { filename, type: 'xlsx' })
    if (response?.error) {
      const { error } = response
      createNotifier(
        t(`domains.edit.adUnits.${error.code}`, { filename: error.options?.filename }),
        NotificationType.ERROR
      )
    }
  }

  const downloadBulkUploadTemplate = () => bulkUploadTemplate({ variables: { domainVersionId: domain.versionId } })

  const uploadFile = (file: File) => {
    if (file) {
      const input: BulkUploadFileData = {
        domainVersionId: domain.versionId,
        xlsxFile: file
      }

      createBulkUploadAdUnitJob(input)
    }
  }

  const shouldRefetchData = () => !isEmpty(message)

  return (
    <Box className='ad-units' list={true}>
      <BoxHeader
        title={t('domains.edit.adUnits.title')}
        containerClassName='box-header__container--space-between'
        rowClassName='header__row--width-100 bulk-upload__container-button'
      >
        <Button onClickHandler={redirectToAdd} theme={ButtonTheme.Orange} type={ButtonType.Primary}>
          {t('common:buttons.add')}
        </Button>

        <div>
          <Button
            type={ButtonType.Secondary}
            theme={ButtonTheme.Blue}
            onClickHandler={downloadBulkUploadTemplate}
            className='ad-units__button--download'
          >
            <Download />
            <span>{t('domains.edit.adUnits.download')}</span>
          </Button>

          <UploadFileButton label={t('domains.edit.adUnits.upload')} onSubmitHandler={uploadFile} />
        </div>
      </BoxHeader>

      <BulkUploadStatusContainer isSubmitting={isSubmitting} message={message} onCloseHandler={clearBulkUploadStatus} />

      <AdUnitsTable domainVersionId={domain.versionId} shouldRefetchData={shouldRefetchData()} readOnly={readOnly} />
    </Box>
  )
}
