import React, { useContext, useMemo } from 'react'

import { Arrow } from 'icons/Arrow'
import { BoxContext } from 'components/Box/BoxContext'

interface Props {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  SubtitleComponent?: React.FC
  rowClassName?: string
  containerClassName?: string
}

export const BoxHeader: React.FC<Props> = ({
  title,
  subtitle,
  SubtitleComponent,
  rowClassName,
  containerClassName,
  children
}) => {
  const { isExpandable, isExpanded, toggleExpand } = useContext(BoxContext)

  const rowClassNames = `header__row ${rowClassName}`
  const containerClassNames = `box-header__container ${containerClassName}`
  const toggleArrowClass = useMemo(() => (isExpanded ? 'arrow--up' : 'arrow--down'), [isExpanded])

  return (
    <>
      <div className='box-header'>
        <div className={containerClassNames}>
          {title && <h2 className='box-header__title'>{title}</h2>}
          {children && <div className={rowClassNames}>{children}</div>}
        </div>
        {subtitle && <small className='box-header__small'>{subtitle}</small>}
        {SubtitleComponent && <SubtitleComponent />}
      </div>
      {isExpandable && (
        <div className='box__icon--collapse' onClick={toggleExpand}>
          <Arrow classNames={toggleArrowClass} alt='toggle arrow' />
        </div>
      )}
    </>
  )
}

BoxHeader.defaultProps = {
  rowClassName: '',
  containerClassName: ''
}
