import { currencyUtils } from 'utilities/currencyUtils'

export const DomainFactory = (domain?: VersionedDomain): Partial<DomainFormData> => {
  return {
    abTests: domain?.abTests,
    amazonSid: domain?.amazonSid ?? '',
    auctionMarginPercent: domain?.auctionMarginPercent ?? 200,
    auctionPerAdUnit: domain?.auctionPerAdUnit ?? true,
    currency: currencyUtils.get(domain?.currency),
    customLazyLoadEnabled: domain?.customLazyLoadEnabled ?? false,
    domainConfigurationId: domain?.domainConfigurationId,
    fetchMarginPercent: domain?.fetchMarginPercent ?? 160,
    fillrateBoosterEnabled: domain?.fillrateBoosterEnabled ?? false,
    id: domain?.id,
    lazyAuctionEnabled: domain?.lazyAuctionEnabled ?? false,
    lazyLoadEnabled: domain?.lazyLoadEnabled ?? false,
    mobileScaling: domain?.mobileScaling ?? 2,
    name: domain?.name ?? '',
    prebidManagerEnabled: domain?.prebidManagerEnabled ?? true,
    renderMarginPercent: domain?.renderMarginPercent ?? 60,
    versionId: domain?.versionId ?? '',
    viewabilityBoosterEnabled: domain?.viewabilityBoosterEnabled ?? false,
    withoutConsentEnabled: domain?.withoutConsentEnabled ?? false,
    workspace: domain?.workspace,
    ybAmazonSid: domain?.ybAmazonSid,
    ybDomainSid: domain?.ybDomainSid
  }
}
