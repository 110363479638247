import React, { useContext, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ResetPasswordForm } from 'components/Auth/ResetPasswordForm'

interface Props {
  setFinalStep: (flag: boolean) => void
}

export const ResetPasswordContainer: React.FC<Props> = ({ setFinalStep }) => {
  const { t } = useTranslation('auth')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { token } = queryString.parse(location.search)
  const history = useHistory<History>()

  useEffect(() => {
    if (!token || token.length === 0) {
      history.push('/')
      createNotifier(t('resetPassword.token.error'), NotificationType.ERROR)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={4} className='password-recovery'>
      <Grid item xl={6} lg={7} xs={8}>
        <header className='password-recovery__header'>
          <h2 className='user-flow__title'>{t('resetPassword.title')}</h2>
          <span className='user-flow__subtitle'>{t('resetPassword.subtitle')}</span>
        </header>

        <ResetPasswordForm token={token as string} onSuccess={() => setFinalStep(true)} />
      </Grid>
    </Grid>
  )
}
