import { gql } from '@apollo/client'

import { PRICE_GENIUS_DOMAIN_CONFIGURATION_FRAGMENT } from 'gql/fragments/priceGenius/PriceGeniusDomainConfigurationFragment'
import { MUTATION_STATE } from 'webapp/gql/fragments/common'

const PAYLOAD_TYPE = 'UpdatePgDomainConfigurationPayload'

export const UPDATE_PRICE_GENIUS_DOMAIN_CONFIGURATION = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  ${PRICE_GENIUS_DOMAIN_CONFIGURATION_FRAGMENT}

  mutation UpdatePgDomainConfiguration($input: UpdatePgDomainConfigurationInput!) {
    updatePgDomainConfiguration(input: $input) {
      pgDomainConfiguration {
        ...PriceGeniusDomainConfigurationFragment
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
