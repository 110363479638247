import React, { useState } from 'react'
import classNames from 'classnames'

import { BidderFormContainer } from 'components/BidderForm/BidderFormContainer'
import { ErrorMessages, Section, StatusForm } from 'components/Form'
import { ErrorUtils } from 'utilities/errorUtils'
import { ParamActionButtons } from './ParamActionButtons'
import { ParamEditor } from './ParamEditor'
import { ParamFormLogo } from './ParamFormLogo'

export interface Props {
  dirty: boolean
  formErrors?: Array<string>
  item: Bidder | PrebidModule
  isBidder?: boolean
  isCheckboxEnabled?: boolean
  isChecked?: boolean
  isDemand?: boolean
  isSaved: boolean
  hideParams?: boolean
  params?: Params
  translation: string
  onChangeHandler: (saved: boolean, bidderId?: string, value?: Params, isChecked?: boolean) => void
  onDeleteHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  validateParams: (id: string, params: Params) => Promise<any>
}

export const ParamForm: React.FC<Props> = ({
  dirty,
  formErrors,
  item,
  isBidder,
  isDemand,
  isCheckboxEnabled,
  isChecked,
  isSaved,
  hideParams,
  params,
  translation,
  onChangeHandler,
  onDeleteHandler,
  validateParams
}) => {
  const [value, setValue] = useState(params || {})
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [saved, setSaved] = useState(isSaved)
  const [checked, setChecked] = useState(isChecked)

  const onSubmitHandler = (params: Params) => {
    setValue(params)
    validateParams(item.id || '', params).then((response) => {
      const validateParams = response.data.validateBidderParams || response.data.validatePrebidModuleParams
      const errors = validateParams.errors
      setErrors(new ErrorUtils(errors))

      if (errors.length === 0) {
        setSaved(true)
        onChangeHandler(true, item._id, params, checked)
      }
    })
  }

  const onEditHandler = () => {
    setSaved(false)
    onChangeHandler(false, item._id)
  }

  const onCheckboxChangeHandler = () => {
    onChangeHandler(true, item._id, undefined, !checked)
    setChecked((checked) => !checked)
  }

  const hasError = Boolean(formErrors && formErrors.length > 0)

  const sectionClass = classNames('param-form', {
    'param-form--checked': isCheckboxEnabled && checked,
    'param-form--error': hasError,
    'param-form--no-params': hideParams
  })
  const validator = item.validator || ''

  const hasAnyParams =
    !isDemand &&
    ((item.requiredParams && item.requiredParams.length > 0) || (item.paramsNames && item.paramsNames.length > 0))

  return (
    <>
      <StatusForm name={`bidder-param-${item.id}`} dirty={dirty} Container={BidderFormContainer}>
        <ErrorMessages errors={formErrors} className='param-form__error-messages' />

        <Section className={sectionClass}>
          {isCheckboxEnabled && (
            <input
              type='checkbox'
              className='param-form__connected'
              checked={checked}
              onChange={onCheckboxChangeHandler}
            />
          )}

          <ParamFormLogo item={item} isBidder={isBidder} isSaved={saved} />

          {!hideParams && (
            <>
              <ParamEditor
                element={validator}
                requiredParams={item.requiredParams}
                paramsNames={item.paramsNames}
                onDeleteHandler={onDeleteHandler}
                onSubmitHandler={onSubmitHandler}
                params={value}
                errors={errors}
                isSaved={saved}
                translation={translation}
              />

              {saved && (
                <ParamActionButtons
                  hasAnyParams={hasAnyParams}
                  onEditHandler={onEditHandler}
                  onDeleteHandler={onDeleteHandler as () => void}
                />
              )}
            </>
          )}
        </Section>
      </StatusForm>
    </>
  )
}
