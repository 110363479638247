import React from 'react'

import { Input } from 'components/Form/Input'

import glassImg from 'images/magnifying-glass.svg'

type CheckboxTreeListFilterType = {
  filterHandler: (filter: string) => void
  placeholder: string
}

export const CheckboxTreeListFilter = ({ filterHandler, placeholder }: CheckboxTreeListFilterType): JSX.Element => {
  return (
    <div className='CheckboxTreeList__filter'>
      <img className='' src={glassImg} />
      <Input
        id='CheckboxTreeListFilter'
        name='checkboxTreeListFilter'
        className='CheckboxTreeList__filterInput'
        containerClassName='CheckboxTreeList__filterInputContainer'
        type='text'
        onChangeHandler={filterHandler}
        placeholder={placeholder}
      />
    </div>
  )
}
