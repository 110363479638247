import React from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
  fullWidth?: boolean
  title?: string
}

export const Section: React.FC<Props> = ({ className, children, fullWidth = false, title }) => {
  const classString = classNames('form-section', className, {
    'form-section--card': !fullWidth,
    'form-section--full-width': fullWidth
  })

  return (
    <div className={classString}>
      {title && <h2 className='form-section__title'>{title}</h2>}
      {children}
    </div>
  )
}
