import React, { useContext } from 'react'

import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'

import Copy from 'images/icon-copy.svg'

interface Props {
  value?: string | number
}

export const CopyIcon: React.FC<Props> = ({ value }) => {
  const { createNotifier } = useContext(NotificationContext) as Notification

  return (
    <img
      alt='copy'
      onClick={() => {
        if (value) {
          navigator.clipboard.writeText(value.toString())
          createNotifier('Copied to clipboard', NotificationType.SUCCESS, 1)
        }
      }}
      src={Copy}
    />
  )
}
