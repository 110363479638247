import { gql } from '@apollo/client'

export const ACTIVE_OSS_PAGES_FOR_AD_UNIT_CONNECTING_TABLE = gql`
  query activeOssPagesForAdUnitConnectingTable($ossAdUnitId: ID!) {
    ossAdUnit(ossAdUnitId: $ossAdUnitId) {
      ossPages {
        nodes {
          id
          pageName
          path
        }
      }
    }
  }
`
