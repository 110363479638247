import { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { PrebidModuleKind } from 'webapp/constants/PrebidModuleKind'
import { UserContext } from 'webapp/context/UserContext'

import WorkspacePrebidModules from 'gql/queries/prebidModules/WorkspacePrebidModules.gql'

export const useWorkspacePrebidModules = (kind?: PrebidModuleKind) => {
  const { user } = useContext(UserContext)

  const { data, refetch, loading } = useQuery<{ workspacePrebidModules: Connection<WorkspacePrebidModule> }>(
    WorkspacePrebidModules,
    {
      fetchPolicy: 'no-cache',
      variables: { kind, workspaceId: user?.workspace?.id }
    }
  )

  return { loading, refetch, workspacePrebidModules: data?.workspacePrebidModules.nodes }
}
