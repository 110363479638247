import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Role, RoleType } from 'webapp/constants/Role'

export const useRoleTypeOptions = () => {
  const { t } = useTranslation()

  return useMemo(
    () => [RoleType.Publisher, RoleType.Superuser].map((value) => ({ value, label: t(`users:roleTypes.${value}`) })),
    [t]
  )
}

export const usePublisherRoleOptions = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      [Role.Editor, Role.Owner, Role.Viewer].map((value) => ({
        value,
        label: t(`users:roles.${value}`)
      })),
    [t]
  )
}

export const useSuperuserRoleOptions = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      [Role.Admin, Role.CS, Role.CP, Role.BO, Role.Adops].map((value) => ({ value, label: t(`users:roles.${value}`) })),
    [t]
  )
}
