import { capitalize } from 'utilities/capitalize'

export class OwnBidderSettingRowData {
  bidderSetting: BidderSetting

  constructor(bidderSetting: BidderSetting) {
    this.bidderSetting = bidderSetting
  }

  get active(): boolean {
    return this.bidderSetting.active
  }

  get bidderLogo(): BidderLogo {
    return {
      fileName: this.bidderSetting.bidder.fileName,
      bidderName: this.bidderSetting.bidder.name
    }
  }

  get bidderCode(): string {
    return this.bidderSetting.bidderCode
  }

  get bidderSettingNetworks(): BidderSettingNetwork[] {
    return this.bidderSetting?.bidderSettingNetworks ?? []
  }

  get bidderSid(): string | undefined {
    return this.bidderSetting.bidderSid
  }

  get bidCpmAdjustment(): number {
    return this.bidderSetting.bidCpmAdjustment
  }

  get consentMode(): string {
    return this.bidderSetting.consentMode
  }

  get id(): string {
    return this.bidderSetting.id
  }

  get networks(): string[] {
    return this.bidderSettingNetworks
      .sort((a, b) => a.position - b.position)
      .map((bidderSettingNetwork) => bidderSettingNetwork.network.name)
  }

  get priority(): string {
    return capitalize(this.bidderSetting.priority)
  }
}
