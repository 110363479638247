import { TCF_TIMEOUT_DEFAULT, TCF_TYPE_DEFAULT, WAIT_FOR_TCF_DEFAULT } from 'constants/ConsentManagement'
import { Error } from 'utilities/errorUtils'
import { TCFWaitType } from 'webapp/constants/TCFWaitType'

export type CmAttributes = {
  tcfTimeout: number
  type: TCFWaitType
  waitForTcf: boolean
}

export const cmAttributesDefault: CmAttributes = {
  tcfTimeout: TCF_TIMEOUT_DEFAULT,
  type: TCF_TYPE_DEFAULT,
  waitForTcf: WAIT_FOR_TCF_DEFAULT
}

export type CreateDomainVars = {
  amazonSid: Domain['amazonSid']
  currency: Domain['currency']
  name: Domain['name']
  ossConsentManagementAttributes: CmAttributes
  ossConsentManagementEnabled: boolean
  ybAmazonSid?: Domain['ybAmazonSid']
  ybDomainSid?: Domain['ybDomainSid']
}

export const createDomainVarsDefault: CreateDomainVars = {
  amazonSid: '',
  currency: '',
  name: '',
  ossConsentManagementAttributes: cmAttributesDefault,
  ossConsentManagementEnabled: false,
  ybAmazonSid: '',
  ybDomainSid: ''
}

export type CreateDomainData = {
  createDomain: {
    domain: Domain
    errors: Error[]
  }
}

export type UseRedirectOnSaveProps = {
  redirectOnSave: (domainId: Domain['id'], domainVersionId: DomainVersion['id']) => void
}
