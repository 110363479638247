import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export const NothingFoundRow: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TableRow className='nothing-found-row'>
      <TableCell colSpan={9999}>{t('common:table.nothingFound')}</TableCell>
    </TableRow>
  )
}
