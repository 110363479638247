import pick from 'lodash/pick'

import { DomainFormData } from 'containers/oss/Inventory/Domains/DomainFormContainer'
import { currencyUtils } from 'utilities/currencyUtils'

export const DomainFormDataFactory = (domain: Domain): DomainFormData => ({
  ...pick(domain, ['amazonSid', 'name', 'ybAmazonSid', 'ybDomainSid']),
  currency: currencyUtils.get(domain.currency) as SelectOption<string>,
  domainId: domain.id
})
