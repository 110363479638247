import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { HeaderGroup } from 'react-table'

interface Props {
  hasSummaryRow?: boolean
  footerGroups: Array<HeaderGroup<Record<string, never>>>
}

export const TableFooter: React.FC<Props> = ({ hasSummaryRow, footerGroups }) => {
  if (hasSummaryRow) {
    return (
      <tfoot>
        {footerGroups.map((footerGroup, index) => (
          <TableRow {...footerGroup.getFooterGroupProps()} key={index} className='table__footer'>
            {footerGroup.headers.map((column: HeaderGroup<Record<string, never>>, index: number) => (
              <TableCell {...column.getFooterProps()} key={index}>
                {column.render('Footer')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </tfoot>
    )
  } else {
    return <></>
  }
}
