import { gql } from '@apollo/client'

export const REVIVE_DOMAIN_CONFIGURATION_TARGETING_FORM_FRAGMENT = gql`
  fragment ReviveDomainConfigurationTargetingFormFragment on ReviveDomainConfiguration {
    advertisers
    domain {
      id
      name
      reviveEnabled
    }
    id
    lineItemIds
    matchType
    monitoringUserScrollEnabled
    orderIds
    refreshAdThreshold
    refreshTimeout
    targetingEnabled
  }
`
