import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BackgroundImage from 'images/forms/login.svg'
import { LoginContainer } from 'containers/Auth/LoginContainer'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { UserFlowTemplate } from 'components/UserFlowTemplate'

export const LoginPage: React.FC = () => {
  const history = useHistory<History>()
  const { t } = useTranslation('auth')
  const { createNotifier } = useContext(NotificationContext) as Notification

  useEffect(() => {
    history.action === 'REPLACE' && createNotifier(t('login.userNotSignedIn'), NotificationType.WARNING)
  }, [])

  return (
    <UserFlowTemplate image={BackgroundImage} view='login'>
      <LoginContainer />
    </UserFlowTemplate>
  )
}
