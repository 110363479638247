import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationType } from 'components/ToastNotifier'
import { Notification, NotificationContext } from 'context/NotificationContext'
import {
  UpdatePriceGeniusDomainConfigurationData as Data,
  UsePriceGeniusApi,
  PriceGeniusDomainConfigurationVars as Vars
} from './types'

import { UPDATE_PRICE_GENIUS_DOMAIN_CONFIGURATION } from 'gql/mutations/priceGenius/UpdatePgDomainConfiguration'

export const usePriceGeniusApi = (): UsePriceGeniusApi => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('priceGenius')

  const [updateTraffic] = useMutation<Data, Vars>(UPDATE_PRICE_GENIUS_DOMAIN_CONFIGURATION, {
    onCompleted: ({ updatePgDomainConfiguration: { pgDomainConfiguration, errors } }) => {
      if (errors.length > 0) {
        createNotifier(t('configuration.columns.trafficPercent.update.error'), NotificationType.ERROR)
      } else if (pgDomainConfiguration) {
        const {
          trafficPercent,
          domain: { name: domainName }
        } = pgDomainConfiguration

        createNotifier(
          t('configuration.columns.trafficPercent.update.success', {
            trafficPercent: Math.round(trafficPercent * 100),
            domainName
          }),
          NotificationType.SUCCESS
        )
      }
    }
  })

  return { updateTraffic }
}
