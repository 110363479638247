import React from 'react'

import DateRangeIcon from '@material-ui/icons/DateRange'
import { useTranslation } from 'react-i18next'

import { formatDateDotsShortYear } from 'utilities/formatDate'
import { TrialOptionProps } from './types'

import './styles.scss'

export const TrialOption = ({ duration, onModalOpen }: TrialOptionProps): JSX.Element => {
  const { t } = useTranslation('products')

  return (
    <div className='TrialOption'>
      {t('status.trial')}
      <DateRangeIcon className='TrialOption__icon' />
      <Duration duration={duration} />
      <EditButton onModalOpen={onModalOpen} />
    </div>
  )
}

const Duration = ({ duration }: Pick<TrialOptionProps, 'duration'>): JSX.Element => {
  const { from, to } = duration

  return (
    <div className='TrialOption__duration'>
      {formatDateDotsShortYear(from)} - {formatDateDotsShortYear(to)}
    </div>
  )
}

const EditButton = ({ onModalOpen }: Pick<TrialOptionProps, 'onModalOpen'>): JSX.Element => {
  const { t } = useTranslation('products')

  const handleEdit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation()
    onModalOpen(true)
  }

  return (
    <div className='TrialOption__edit-button' onClick={handleEdit}>
      {t('common:buttons.edit')}
    </div>
  )
}
