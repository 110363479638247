import { MatchType } from 'types/common'
import { ReviveDomainConfiguration } from 'types/ReviveDomainConfiguration'

export class ReviveDomainConfigurationRow {
  constructor(private reviveDomainConfiguration: ReviveDomainConfiguration) {}

  get domainId(): string {
    return this.reviveDomainConfiguration.domain.id
  }
  get domainName(): string {
    return this.reviveDomainConfiguration.domain.name
  }
  get id(): string {
    return this.reviveDomainConfiguration.id
  }
  get matchType(): MatchType {
    return this.reviveDomainConfiguration.matchType
  }
  get ossAdUnitsCount(): number {
    return this.reviveDomainConfiguration.ossAdUnitsCount
  }
  get ossAdUnitsEnabledCount(): number {
    return this.reviveDomainConfiguration.ossAdUnitsEnabledCount
  }
  get reviveEnabled(): boolean {
    return this.reviveDomainConfiguration.domain.reviveEnabled
  }
  get targetingEnabled(): boolean {
    return this.reviveDomainConfiguration.targetingEnabled
  }
  get updatedAt(): Date {
    return this.reviveDomainConfiguration.updatedAt
  }
}
