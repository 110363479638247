import React, { useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Collapse } from '@material-ui/core'
import { FormikHelpers } from 'formik'

import { Box, BoxHeader, BoxBody } from 'components/Box'
import { DomainVersionPrebidModulesForm } from './DomainVersionPrebidModulesForm'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ToggleSwitch } from 'components/Form'

import DomainVersionQuery from 'gql/queries/domainVersions/DomainVersion.gql'
import UpsertDomainVersionPrebidModules from 'gql/mutations/domainVersions/UpsertDomainVersionPrebidModules.gql'
import WorkspacePrebidModulesQuery from 'gql/queries/prebidModules/WorkspacePrebidModules.gql'

type DomainVersionPrebidModulesContainerType = {
  domain: VersionedDomain
  updateVersionedDomainHandler: (domain: DomainFormData) => void
}

export const DomainVersionPrebidModulesContainer = ({
  domain,
  updateVersionedDomainHandler
}: DomainVersionPrebidModulesContainerType): JSX.Element => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('inventory')

  const [domainVersionPrebidModules, setDomainVersionPrebidModules] =
    React.useState<DomainVersionPrebidModulesFormData>({
      domainVersionId: domain.versionId,
      workspacePrebidModuleIds: []
    })
  const [workspacePrebidModules, setWorkspacePrebidModules] = React.useState<PrebidModule[]>([])

  const [upsertDomainVersionPrebidModules] = useMutation(UpsertDomainVersionPrebidModules, {
    onCompleted: ({ upsertDomainVersionPrebidModules: { errors } }) => {
      if (!errors.length) {
        createNotifier(t('domains.edit.domainVersionPrebidModules.successMessage'), NotificationType.SUCCESS)
      } else {
        createNotifier(t('common:errors.common'), NotificationType.ERROR)
      }
    }
  })

  useQuery(WorkspacePrebidModulesQuery, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceId: domain.workspace.id
    },
    onCompleted: ({ workspacePrebidModules }) => {
      setWorkspacePrebidModules(workspacePrebidModules.nodes)
    }
  })

  useQuery(DomainVersionQuery, {
    fetchPolicy: 'network-only',
    variables: {
      domainVersionId: domain.versionId
    },
    onCompleted: ({ domainVersion }) => {
      const workspacePrebidModuleIds = domainVersion.workspacePrebidModules.map((el: { id: string }) => el.id)

      setDomainVersionPrebidModules({ domainVersionId: domain.versionId, workspacePrebidModuleIds })
    }
  })

  const toggleCustomPrebidModulesEnabled = () => {
    const domainFormData = {
      ...domain,
      customPrebidModulesEnabled: !domain.customPrebidModulesEnabled
    } as unknown as DomainFormData

    updateVersionedDomainHandler(domainFormData)
  }

  const onSubmit = (
    values: DomainVersionPrebidModulesFormData,
    { resetForm }: FormikHelpers<DomainVersionPrebidModulesFormData>
  ) => {
    upsertDomainVersionPrebidModules({ variables: { input: values } }).finally(() => {
      resetForm({ values })
    })
  }

  return (
    <>
      {workspacePrebidModules && workspacePrebidModules.length > 0 && (
        <Box className='domain-prebid-modules'>
          <BoxHeader title={t('domains.edit.domainVersionPrebidModules.title')}>
            <ToggleSwitch
              id='custom-prebid-modules-enabled'
              name='customPrebidModulesEnabled'
              checked={domain.customPrebidModulesEnabled}
              onChange={toggleCustomPrebidModulesEnabled}
            />
          </BoxHeader>
          <Collapse in={domain.customPrebidModulesEnabled}>
            <BoxBody>
              <div className='box__body'>
                <DomainVersionPrebidModulesForm
                  initialValues={domainVersionPrebidModules}
                  onSubmit={onSubmit}
                  workspacePrebidModules={workspacePrebidModules}
                />
              </div>
            </BoxBody>
          </Collapse>
        </Box>
      )}
    </>
  )
}
