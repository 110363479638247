import React from 'react'
import { useTranslation } from 'react-i18next'

import { TotalTrafficComponent } from './TotalTrafficComponent'

interface Props {
  percent: number
}

export const TotalTraffic: React.FC<Props> = ({ percent }) => {
  const { t } = useTranslation('abTesting')
  const label = t('form.inputs.totalTraffic.label')

  return <TotalTrafficComponent label={label} percent={percent} />
}
