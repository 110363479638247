import { useTranslation } from 'react-i18next'

import { FilterType } from 'webapp/constants/FilterType'

export const useFilterConfig = (): AdvancedFilterConfiguration[] => {
  const { t } = useTranslation('inventory')

  return [
    {
      label: t('filters.label.adUnitPath'),
      name: 'ad_unit_path',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.adUnitPath'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.domainName'),
      name: 'domain.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.domainName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.adUnitDivId'),
      name: 'element_id',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.adUnitDivId'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.pageName'),
      name: 'oss_pages.page_name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.pageName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.deviceName'),
      name: 'ad_units.media_types.workspace_device.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.deviceName'),
      type: FilterType.Input
    }
  ]
}
