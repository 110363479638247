import React from 'react'
import { useTranslation } from 'react-i18next'
import { PropsValue } from 'react-select'

import { Select } from 'components/Form'
import { Button, ButtonType, ButtonTheme } from 'components/Button'

interface Props {
  availableOptions: Array<any>
  disabledSaveButton: boolean | undefined
  formId: string
  hideNewOptions?: boolean
  selectedItem: SelectOption | null
  translations: string
  translationRoot?: string
  onAddHandler: (event: React.MouseEvent) => void
  onChangeHandler: (value: PropsValue<SelectOption>) => void
  renderParamForm: () => JSX.Element
  filterOption?: (candidate: { [key: string]: any }, input: string) => boolean
  renderRequestContainer?: () => JSX.Element
}

export const ParamFormSection: React.FC<Props> = ({
  availableOptions,
  disabledSaveButton,
  formId,
  hideNewOptions,
  selectedItem,
  translations,
  translationRoot = '',
  onAddHandler,
  onChangeHandler,
  renderParamForm,
  filterOption,
  renderRequestContainer
}) => {
  const { t } = useTranslation(translationRoot)
  return (
    <>
      {renderParamForm()}

      {!hideNewOptions && (
        <div className={formId}>
          <Select
            className={`${formId}__select bidders-select`}
            filterOption={filterOption}
            id={`${formId}-select`}
            label={t(`${translations}.label`)}
            labelStyle='bold'
            name='add-bidder'
            onChangeHandler={onChangeHandler}
            options={availableOptions}
            placeholder={t(`${translations}.placeholder`)}
            selectedItem={selectedItem}
            tooltipContent={t(`${translations}.tooltip`)}
          />

          <Button
            buttonType='submit'
            className='ml-20'
            disabled={disabledSaveButton}
            onClickHandler={onAddHandler}
            theme={ButtonTheme.Blue}
            type={ButtonType.Primary}
          >
            {t(`${translations}.button`)}
          </Button>
        </div>
      )}

      {renderRequestContainer && renderRequestContainer()}
    </>
  )
}
