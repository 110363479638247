import React, { useCallback, useContext, useMemo } from 'react'
import { Row } from 'react-table'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ToggleSwitch } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { UserContext } from 'webapp/context/UserContext'

import UpdateWorkspace from 'gql/mutations/workspaces/UpdateWorkspace.gql'

interface Props {
  packageName: string
  row: Row<Workspace>
}

const SPACE_REGEX = / /g

export const PackageCell: React.FC<Props> = ({ packageName, row }) => {
  const history = useHistory<History>()
  const { t } = useTranslation('workspaces')

  const { reloadUser } = useContext(UserContext)
  const { createNotifier } = useContext(NotificationContext) as Notification

  const standardizedPackageName = useMemo(() => packageName.replace(SPACE_REGEX, '').toLowerCase(), [packageName])

  const [updateWorkspace] = useMutation(UpdateWorkspace, {
    onCompleted: ({ updateWorkspace: { workspace } }) => {
      if (workspace) {
        reloadUser()
        createNotifier(t(`list.packages.${standardizedPackageName}.successMessage`), NotificationType.SUCCESS)
        history.push(history.location)
      }
    }
  })

  const findPackage = (packageName: string) => row.original.packages?.find((element) => element.name === packageName)

  const getValue = (packageName: string) => findPackage(packageName)?.active || false

  const preparePackage = (packageName: string) => {
    const item = findPackage(packageName)
    const active = getValue(packageName)

    return {
      packageId: item?.packageId,
      active: !active
    }
  }

  const getVariables = useMemo(
    () => ({
      input: { workspaceId: row.original.id, packages: [{ ...preparePackage(packageName) }] }
    }),
    [row, packageName]
  )

  const onChangeHandler = useCallback(() => {
    updateWorkspace({ variables: getVariables })
  }, [getVariables, updateWorkspace])

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        key={standardizedPackageName}
        id={`${standardizedPackageName}-${row.id}`}
        name={`${standardizedPackageName}-${row.id}`}
        checked={getValue(packageName)}
        onChange={onChangeHandler}
        className={`${standardizedPackageName}__switch`}
        labelClassName={`${standardizedPackageName}-${row.id}`}
      />
    </div>
  )
}
