import { Ability } from '@casl/ability'
import { useContext } from 'react'

import { ButtonTheme } from 'components/Button'
import { AbilityContext } from 'context/AbilityContext'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Product } from 'webapp/constants/Product'

import PriceGeniusIllustration from 'images/products/illustrations/price-genius.svg'
import QuickwrapIllustration from 'images/products/illustrations/quickwrap.svg'
import ReviveIllustration from 'images/products/illustrations/revive.svg'
import ViewabilityBoosterIllustration from 'images/products/illustrations/viewability-booster.svg'

import FillRateBoosterLogo from 'images/logos/fill-rate-booster.svg'
import OssLogo from 'images/logos/oss.svg'
import PriceGeniusLogo from 'images/logos/price-genius.svg'
import QuickwrapLogo from 'images/logos/quickwrap.svg'
import ReviveLogo from 'images/logos/revive.svg'
import ViewabilityBoosterLogo from 'images/logos/viewability-booster.svg'

import FillRateBoosterIcon from 'images/logos/no-text/fill-rate-booster.svg'
import OssIcon from 'images/logos/no-text/oss.svg'
import PriceGeniusIcon from 'images/logos/no-text/price-genius.svg'
import QuickwrapIcon from 'images/logos/no-text/quickwrap.svg'
import ReviveIcon from 'images/logos/no-text/revive.svg'
import ViewabilityBoosterIcon from 'images/logos/no-text/viewability-booster.svg'

type ProductData = {
  buttonTheme: ButtonTheme
  homePageUrl: string | null
  illustration?: string
  knowledgeBaseUrl: string
  learnMoreUrl?: string
  logo: string
  logoIcon: string
}

const KNOWLEDGE_BASE_DOMAIN_URL = 'https://knowledge-base.yieldbird.com'

const LearnMoreUrl: Record<Product, string> = {
  [Product.FillRateBooster]: '2PACX-1vR5bSNBb9bgJmIJ7XP6Pm4PiVetqxBPxJ3gKpH6uEFgj2hFMfxRTFn0SQkO9TTDe9nzDZ60jJ2PsfF_',
  [Product.OSS]: '/',
  [Product.PriceGenius]: '2PACX-1vRVVX5tFF7jcW4U32lyn9uZ6FxV9_Q9VcdBymECvqyVuvc5UjnZeO-ZQNyBnLlyjuRAylSSCFygoU_V',
  [Product.Quickwrap]: 'https://yieldbird.com/quickwrap',
  [Product.Revive]: '2PACX-1vSsHagVPnY0I0BTX_wkMkNYfrChjkxybYDS9qVdq98HEMuU4Wph2t33if0zjTrCHQKgyLIpk3kxtQez',
  [Product.ViewabilityBooster]: '2PACX-1vSxDmmyCmHS1v8lYY6r6iSzWwIuJFyaoQrr-BzCSPqKTBkffqBdZ8t0pA7TFzxWU19eZ73Y8UHDdKl3'
}

const createLearnMoreUrl = (documentId: string) => `https://docs.google.com/presentation/d/e/${documentId}/pub`

const createProductSettings = (ability: Ability, product: Product): ProductData => {
  switch (product) {
    case Product.FillRateBooster: {
      return {
        buttonTheme: ButtonTheme.FillRateBooster,
        homePageUrl: ability.can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_PATH)
          ? routesBuilder.fill_rate_booster.performance.dataStudio
          : null,
        illustration: QuickwrapIllustration,
        learnMoreUrl: createLearnMoreUrl(LearnMoreUrl.fill_rate_booster),
        logo: FillRateBoosterLogo,
        logoIcon: FillRateBoosterIcon,
        knowledgeBaseUrl: `${KNOWLEDGE_BASE_DOMAIN_URL}/unfilled-recovery`
      }
    }

    case Product.OSS: {
      return {
        buttonTheme: ButtonTheme.Blue,
        homePageUrl: LearnMoreUrl.oss,
        logo: OssLogo,
        logoIcon: OssIcon,
        knowledgeBaseUrl: `${KNOWLEDGE_BASE_DOMAIN_URL}/`
      }
    }

    case Product.PriceGenius: {
      return {
        buttonTheme: ButtonTheme.PriceGenius,
        homePageUrl: routesBuilder.price_genius.configuration.root,
        illustration: PriceGeniusIllustration,
        learnMoreUrl: createLearnMoreUrl(LearnMoreUrl.price_genius),
        logo: PriceGeniusLogo,
        logoIcon: PriceGeniusIcon,
        knowledgeBaseUrl: `${KNOWLEDGE_BASE_DOMAIN_URL}/pricegenius`
      }
    }

    case Product.Quickwrap: {
      return {
        buttonTheme: ButtonTheme.Orange,
        homePageUrl: routesBuilder.quickwrap.inventory.domains.root,
        illustration: QuickwrapIllustration,
        learnMoreUrl: LearnMoreUrl.quickwrap,
        logo: QuickwrapLogo,
        logoIcon: QuickwrapIcon,
        knowledgeBaseUrl: `${KNOWLEDGE_BASE_DOMAIN_URL}/prebid-stack`
      }
    }

    case Product.Revive: {
      return {
        buttonTheme: ButtonTheme.Red,
        homePageUrl: routesBuilder.revive.configuration.root,
        illustration: ReviveIllustration,
        learnMoreUrl: createLearnMoreUrl(LearnMoreUrl.revive),
        logo: ReviveLogo,
        logoIcon: ReviveIcon,
        knowledgeBaseUrl: `${KNOWLEDGE_BASE_DOMAIN_URL}/refresher`
      }
    }

    case Product.ViewabilityBooster: {
      return {
        buttonTheme: ButtonTheme.ViewabilityBooster,
        homePageUrl: ability.can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_PATH)
          ? routesBuilder.viewability_booster.performance.dataStudio
          : null,
        illustration: ViewabilityBoosterIllustration,
        learnMoreUrl: createLearnMoreUrl(LearnMoreUrl.viewability_booster),
        logo: ViewabilityBoosterLogo,
        logoIcon: ViewabilityBoosterIcon,
        knowledgeBaseUrl: `${KNOWLEDGE_BASE_DOMAIN_URL}/viewability-tools`
      }
    }
  }
}

export const getProductSettings = (product: ProductConnection['product']): ProductData => {
  const ability = useContext(AbilityContext) // eslint-disable-line react-hooks/rules-of-hooks

  return createProductSettings(ability, product)
}
