import React, { useContext, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { useLocation, useParams } from 'react-router-dom'

import { BoxHeader } from 'components/Box'
import { AdUnitsContainer } from 'containers/oss/Inventory/AdUnits/AdUnitsContainer'
import { DomainFormContainer } from 'containers/oss/Inventory/Domains/DomainFormContainer'
import { CustomCodeContainer } from 'containers/oss/Inventory/Domains/CustomCodeContainer'
import { LoadingContainer } from 'webapp/components/LoadingContainer'
import { Product } from 'webapp/constants/Product'
import { DomainConsentManagementContainer } from 'webapp/containers/oss/Inventory/Domains/DomainConsentManagementContainer'
import { DomainCommentContainer } from 'webapp/containers/shared/Inventory/Domains/DomainCommentContainer'
import { UserContext } from 'webapp/context/UserContext'
import { NO_CACHE } from 'webapp/utils/apolloClient'
import { isCustomCodeEnabled } from './service'
import { DomainData, DomainVars } from './types'

import { DOMAIN } from 'gql/queries/domains/Domain'
import { DOMAIN_AS_ADMIN } from 'gql/queries/domains/DomainAsAdmin'

export const EditPage = (): JSX.Element => {
  const { isSuperuser } = useContext(UserContext)
  const { domainId: urlDomainId } = useParams<DomainVars>()
  const [domain, setDomain] = useState<Domain>()

  const GET_DOMAIN = isSuperuser ? DOMAIN_AS_ADMIN : DOMAIN

  const [fetchDomain] = useLazyQuery<DomainData, DomainVars>(GET_DOMAIN, {
    ...NO_CACHE,
    onCompleted: ({ domain }: DomainData): void => setDomain(domain)
  })

  useEffect(() => {
    fetchDomain({ variables: { domainId: urlDomainId } })
  }, [urlDomainId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (domain) {
    const { id, name } = domain

    return (
      <>
        <BoxHeader title={name} />

        <DomainCommentContainer domainId={id} product={Product.OSS} />

        <DomainFormContainer domain={domain} setDomain={setDomain} />

        <DomainConsentManagementContainer domain={domain} />

        {isCustomCodeEnabled(domain) && <CustomCodeContainer domain={domain} />}

        <AdUnitsContainer perDomain={id} readOnly />
      </>
    )
  } else return <LoadingContainer fullHeight loading />
}
