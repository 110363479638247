import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useLazyQuery, useMutation } from '@apollo/client'

import { AbilityProvider } from 'webapp/context/AbilityContext'
import { IndexedProductConnections, useIndexedProductConnections } from 'utilities/products/utils'
import { isWorkspacePackageActive } from 'utilities/workspacePackageUtils'
import jwtToken from 'utilities/jwtToken'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Product } from 'webapp/constants/Product'
import { Role, SuperuserRoles } from 'webapp/constants/Role'
import { useIsProductActive } from 'utilities/products/activation'

import CurrentUserQuery from 'gql/queries/users/CurrentUser.gql'
import RefreshToken from 'gql/mutations/auth/RefreshToken.gql'

interface Props {
  indexedProductConnections?: IndexedProductConnections
  isImpersonating: () => boolean
  isPackageActive: (packageName: string) => boolean
  isProductActive: (productName: Product) => boolean
  isSuperuser: boolean
  logout: () => void
  reloadUser: () => void
  setUser: (user: User) => void
  updateToken: (token: string) => void
  user: null | User
}

const defaults = {
  isImpersonating: () => false,
  isPackageActive: () => false,
  isProductActive: () => false,
  isSuperuser: false,
  logout: () => {},
  reloadUser: () => {},
  setUser: () => {},
  updateToken: (_token: string) => {},
  user: null
}

const INTERVAL = 4.5 * 60 * 1000 // 4.5 minutes, token is valid for 5 minutes

export const UserContext = React.createContext<Props>(defaults)

export const UserProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<User | null>(null)

  const [refreshToken] = useMutation(RefreshToken, {
    onCompleted: ({ refreshToken: { credentials } }) => {
      jwtToken.setToken(credentials.accessToken)
      if (isLoading) {
        currentUserQuery()
      }
    },
    onError: () => {
      jwtToken.setToken('')
      setIsLoading(false)
    }
  })

  const [currentUserQuery] = useLazyQuery(CurrentUserQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ currentUser }) => {
      setUser(currentUser)
      setIsLoading(false)
    }
  })

  useEffect(() => {
    refreshToken(nestGqlInput({}))

    const interval = setInterval(() => {
      refreshToken(nestGqlInput({}))
    }, INTERVAL)

    return () => clearInterval(interval)
  }, [])

  const isImpersonating = () => {
    const token = jwtToken.decodeToken() as User
    return (token.impersonation && token.impersonation.id !== token.id) || false
  }

  const isPackageActive = (packageName: string) => {
    return isWorkspacePackageActive(user?.workspace?.packages, packageName)
  }

  const indexedProductConnections = useIndexedProductConnections(user)
  const isProductActive = useIsProductActive(indexedProductConnections)

  const isSuperuser = SuperuserRoles.includes(user?.role as Role)

  const logout = () => {
    jwtToken.setToken('')
    setUser(null)
  }

  const updateToken = (token: string) => {
    setIsLoading(true)
    jwtToken.setToken(token)
    currentUserQuery()
  }

  return (
    <UserContext.Provider
      value={{
        indexedProductConnections,
        isImpersonating,
        isPackageActive,
        isProductActive,
        isSuperuser,
        logout,
        reloadUser: currentUserQuery,
        setUser,
        updateToken,
        user
      }}
    >
      {isLoading ? (
        <CircularProgress className='initial-progress' size='5rem' disableShrink />
      ) : (
        <AbilityProvider>{children}</AbilityProvider>
      )}
    </UserContext.Provider>
  )
}
