import React, { useMemo } from 'react'

import { BulkUploadResults } from 'components/quickwrap/Inventory/Domains/BulkUploadResults'
import { LoadingStatus } from 'components/quickwrap/Inventory/Domains/LoadingStatus'

interface Props {
  isSubmitting: boolean
  message?: BulkUploadMessage
  onCloseHandler: () => void
}

export const BulkUploadStatusContainer: React.FC<Props> = ({ isSubmitting, message, onCloseHandler }) => {
  const showResults = useMemo(
    () => !isSubmitting && message && (message.errors.length > 0 || message.details),
    [isSubmitting, message]
  )

  return (
    <div className='bulk-upload-status'>
      {isSubmitting && <LoadingStatus />}
      {showResults && <BulkUploadResults message={message} onCloseHandler={onCloseHandler} />}
    </div>
  )
}
