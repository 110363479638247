import { domainVersionName } from 'utilities/domainVersionName'

export class PageRowData {
  page: PageRow

  constructor(page: PageRow) {
    this.page = page
  }

  get id(): string {
    return this.page.id
  }

  get rawId(): string {
    return this.page.rawId
  }

  get pageName(): string | undefined {
    return this.page.ossPage.pageName
  }

  get path(): string {
    return this.page.ossPage.path
  }

  get ossAdUnitsCount(): number {
    return this.page.ossAdUnitsCount
  }

  get domainName(): string {
    return this.page.domain.name
  }

  get prebidDeviceCount(): string {
    return `${this.page.prebidDeviceCount}`
  }

  get prebidTimeout(): string {
    return `${this.page.usedPrebidTimeout} ms`
  }

  get updatedAt(): Date {
    return this.page.updatedAt
  }

  get domainVersionName(): string {
    return domainVersionName(this.page.domainVersion?.counter, this.page.domainVersion?.name)
  }

  get workspaceName(): string {
    return this.page.domain.workspace.name
  }
}
