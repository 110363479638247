import i18n from 'webapp/i18n'

import { BidderInventoryTabs } from '../quickwrap/Setup/BidderInventory/bidderInventoryTabs'
import { prebidAnalyticsTabs } from '../quickwrap/Performance/PrebidAnalytics/prebidAnalyticsTabs'
import { removeQueryParams } from 'utilities/removeQueryParams'
import { routesBuilder } from 'utilities/routesBuilder'

const abTestingRoutes = [
  { path: routesBuilder.quickwrap.abTesting.root, breadcrumb: i18n.t('breadcrumbs:abTesting.root') },
  { path: routesBuilder.quickwrap.abTesting.new, breadcrumb: i18n.t('breadcrumbs:abTesting.new') },
  { path: routesBuilder.quickwrap.abTesting.edit(), breadcrumb: i18n.t('breadcrumbs:abTesting.edit') }
]

const adUnitRoutes = [
  {
    path: removeQueryParams(routesBuilder.quickwrap.inventory.adUnit.root),
    breadcrumb: i18n.t('breadcrumbs:adUnits.root')
  },
  { path: routesBuilder.quickwrap.inventory.adUnit.edit(), breadcrumb: i18n.t('breadcrumbs:adUnits.edit') },
  { path: removeQueryParams(routesBuilder.oss.inventory.adUnit.root), breadcrumb: i18n.t('breadcrumbs:adUnits.root') },
  { path: routesBuilder.oss.inventory.adUnit.add(), breadcrumb: i18n.t('breadcrumbs:adUnits.new') },
  { path: routesBuilder.oss.inventory.adUnit.edit(), breadcrumb: i18n.t('breadcrumbs:adUnits.edit') }
]

const devicesRoutes = [
  { path: removeQueryParams(routesBuilder.oss.inventory.devices.root), breadcrumb: i18n.t('breadcrumbs:devices') }
]

const domainRoutes = [
  {
    path: removeQueryParams(routesBuilder.quickwrap.inventory.domains.root),
    breadcrumb: i18n.t('breadcrumbs:domains.root')
  },
  { path: routesBuilder.quickwrap.inventory.domains.edit(), breadcrumb: i18n.t('breadcrumbs:domains.edit') },
  { path: removeQueryParams(routesBuilder.oss.inventory.domains.root), breadcrumb: i18n.t('breadcrumbs:domains.root') },
  { path: routesBuilder.oss.inventory.domains.new, breadcrumb: i18n.t('breadcrumbs:domains.new') },
  { path: routesBuilder.oss.inventory.domains.edit(), breadcrumb: i18n.t('breadcrumbs:domains.edit') }
]

const downloadTagsRoutes = [
  { path: routesBuilder.quickwrap.downloadTags.root, breadcrumb: i18n.t('breadcrumbs:downloadTags.show') },
  { path: routesBuilder.oss.downloadTags.root, breadcrumb: i18n.t('breadcrumbs:downloadTags.show') }
]

const historyRoutes = [{ path: routesBuilder.oss.history.root, breadcrumb: i18n.t('breadcrumbs:history.root') }]

const financeRoutes = [
  { path: routesBuilder.oss.finance.root, breadcrumb: i18n.t('breadcrumbs:finance.root') },
  {
    path: routesBuilder.oss.finance.dataStudioLinks.root,
    breadcrumb: i18n.t('breadcrumbs:finance.dataStudio.links.root')
  },
  {
    path: routesBuilder.oss.finance.dataStudioLinks.new,
    breadcrumb: i18n.t('breadcrumbs:finance.dataStudio.links.new')
  },
  {
    path: routesBuilder.oss.finance.dataStudioLinks.edit(),
    breadcrumb: i18n.t('breadcrumbs:finance.dataStudio.links.edit')
  }
]

const pagesRoutes = [
  {
    path: removeQueryParams(routesBuilder.quickwrap.inventory.pages.root),
    breadcrumb: i18n.t('breadcrumbs:pages.root')
  },
  { path: routesBuilder.quickwrap.inventory.pages.edit(), breadcrumb: i18n.t('breadcrumbs:pages.edit') },
  { path: removeQueryParams(routesBuilder.oss.inventory.pages.root), breadcrumb: i18n.t('breadcrumbs:pages.root') },
  { path: routesBuilder.oss.inventory.pages.add, breadcrumb: i18n.t('breadcrumbs:pages.new') },
  { path: routesBuilder.oss.inventory.pages.edit(), breadcrumb: i18n.t('breadcrumbs:pages.edit') }
]

const performanceRoutes = [
  { path: routesBuilder.fill_rate_booster.performance.root, breadcrumb: i18n.t('breadcrumbs:performance.root') },
  { path: routesBuilder.oss.performance.root, breadcrumb: i18n.t('breadcrumbs:performance.root') },
  { path: routesBuilder.quickwrap.performance.root, breadcrumb: i18n.t('breadcrumbs:performance.root') },
  { path: routesBuilder.viewability_booster.performance.root, breadcrumb: i18n.t('breadcrumbs:performance.root') },
  { path: routesBuilder.quickwrap.performance.prebid.root, breadcrumb: i18n.t('breadcrumbs:performance.prebid.root') },
  {
    path: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.dashboard),
    breadcrumb: i18n.t('breadcrumbs:performance.prebid.dashboard')
  },
  {
    path: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.bidderAnalysis),
    breadcrumb: i18n.t('breadcrumbs:performance.prebid.bidderAnalysis')
  },
  {
    path: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.adTypeAnalysis),
    breadcrumb: i18n.t('breadcrumbs:performance.prebid.adTypeAnalysis')
  },
  {
    path: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.siteAnalysis),
    breadcrumb: i18n.t('breadcrumbs:performance.prebid.siteAnalysis')
  },
  {
    path: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.auctionReports),
    breadcrumb: i18n.t('breadcrumbs:performance.prebid.auctionReports')
  },
  { path: routesBuilder.quickwrap.performance.reporting, breadcrumb: i18n.t('breadcrumbs:performance.reporting.root') },
  {
    path: routesBuilder.quickwrap.performance.reportingNew,
    breadcrumb: i18n.t('breadcrumbs:performance.reporting.new')
  },
  {
    path: routesBuilder.quickwrap.performance.reportingResults,
    breadcrumb: i18n.t('breadcrumbs:performance.reporting.results')
  },
  {
    path: routesBuilder.oss.performance.dataStudioLinks.root,
    breadcrumb: i18n.t('breadcrumbs:performance.dataStudio.links.root')
  },
  {
    path: routesBuilder.oss.performance.dataStudioLinks.new,
    breadcrumb: i18n.t('breadcrumbs:performance.dataStudio.links.new')
  },
  {
    path: routesBuilder.oss.performance.dataStudioLinks.edit(),
    breadcrumb: i18n.t('breadcrumbs:performance.dataStudio.links.edit')
  },
  {
    path: routesBuilder.fill_rate_booster.performance.dataStudio,
    breadcrumb: i18n.t('breadcrumbs:performance.dataStudio.viewer')
  },
  {
    path: routesBuilder.viewability_booster.performance.dataStudio,
    breadcrumb: i18n.t('breadcrumbs:performance.dataStudio.viewer')
  },
  {
    path: routesBuilder.oss.performance.dataStudioLinks.preview(),
    breadcrumb: i18n.t('breadcrumbs:performance.dataStudio.preview')
  }
]

const priceGeniusRoutes = [
  {
    path: routesBuilder.price_genius.configuration.root,
    breadcrumb: i18n.t('breadcrumbs:priceGenius.configuration.root')
  },
  {
    path: routesBuilder.price_genius.performance.dataStudio,
    breadcrumb: i18n.t('breadcrumbs:priceGenius.performance.dataStudio')
  }
]

const productRoutes = [
  { path: routesBuilder.oss.products.root, breadcrumb: i18n.t('breadcrumbs:products.root') },
  { path: routesBuilder.oss.products.management, breadcrumb: i18n.t('breadcrumbs:products.management') }
]

const profileRoutes = [{ path: routesBuilder.oss.profile.root, breadcrumb: i18n.t('breadcrumbs:profile.root') }]

const reviveRoutes = [
  {
    path: routesBuilder.revive.configuration.root,
    breadcrumb: i18n.t('breadcrumbs:revive.configuration.root')
  },
  {
    path: routesBuilder.revive.configuration.edit(),
    breadcrumb: i18n.t('breadcrumbs:revive.configuration.edit')
  },
  {
    path: routesBuilder.revive.performance.dataStudio,
    breadcrumb: i18n.t('breadcrumbs:revive.performance.dataStudio')
  }
]

const setupRoutes = [
  { path: routesBuilder.quickwrap.setup.root, breadcrumb: i18n.t('breadcrumbs:setup.root') },
  {
    path: removeQueryParams(routesBuilder.quickwrap.setup.bidders(BidderInventoryTabs.inventoryConnection)),
    breadcrumb: i18n.t('breadcrumbs:setup.bidders')
  },
  { path: routesBuilder.quickwrap.setup.general, breadcrumb: i18n.t('breadcrumbs:setup.general') },
  { path: routesBuilder.quickwrap.setup.modules, breadcrumb: i18n.t('breadcrumbs:setup.modules') }
]

const userRoutes = [
  { path: routesBuilder.oss.users.root, breadcrumb: i18n.t('breadcrumbs:users.root') },
  { path: routesBuilder.oss.users.invite, breadcrumb: i18n.t('breadcrumbs:users.invite') }
]

const workspaceRoutes = [
  { path: routesBuilder.oss.workspaces.edit(), breadcrumb: i18n.t('breadcrumbs:workspaces.edit') },
  { path: routesBuilder.oss.workspaces.new, breadcrumb: i18n.t('breadcrumbs:workspaces.new') },
  { path: routesBuilder.oss.workspaces.root, breadcrumb: i18n.t('breadcrumbs:workspaces.root') },
  { path: routesBuilder.oss.workspace.root, breadcrumb: i18n.t('breadcrumbs:workspace.root') }
]

export const routes = [
  ...abTestingRoutes,
  ...adUnitRoutes,
  ...devicesRoutes,
  ...domainRoutes,
  ...downloadTagsRoutes,
  ...financeRoutes,
  ...historyRoutes,
  ...pagesRoutes,
  ...performanceRoutes,
  ...priceGeniusRoutes,
  ...productRoutes,
  ...profileRoutes,
  ...reviveRoutes,
  ...setupRoutes,
  ...userRoutes,
  ...workspaceRoutes
]
