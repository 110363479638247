import React from 'react'

export const Close: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17.904' height='17.904' viewBox='0 0 17.904 17.904'>
      <g id='Group_10889' data-name='Group 10889' transform='translate(-1223.606 -997.968) rotate(-45)'>
        <g id='Group_10492' data-name='Group 10492' transform='translate(149 1573)'>
          <rect
            id='Rectangle_4365'
            data-name='Rectangle 4365'
            width='4.22'
            height='21.1'
            rx='2.11'
            transform='translate(0 12.66) rotate(-90)'
          />
          <rect
            id='Rectangle_4366'
            data-name='Rectangle 4366'
            width='4.22'
            height='21.1'
            rx='2.11'
            transform='translate(12.66 21.1) rotate(180)'
          />
        </g>
      </g>
    </svg>
  )
}
