import React, { useMemo, ReactNode, RefObject, HTMLInputTypeAttribute } from 'react'
import classNames from 'classnames'

import { Arrows } from './Arrows'
import { CopyIcon } from './CopyIcon'
import { InputContainer } from 'components/Form/InputContainer'

type HTMLInputOrTextareaElement = HTMLInputElement | HTMLTextAreaElement

export type InputProps = {
  children?: ReactNode
  className?: string
  containerClassName?: string
  copyButton?: boolean
  disabled?: boolean
  errors?: Array<string>
  hasErrors?: boolean
  id?: string
  isBeta?: boolean
  label?: string
  labelStyle?: string
  name: string
  onBlur?: React.FocusEventHandler
  onChangeHandler?: (value: string) => void
  onFocus?: React.FocusEventHandler
  onKeyDown?: React.KeyboardEventHandler
  placeholder?: string
  prefix?: string
  ref?: RefObject<HTMLInputOrTextareaElement>
  step?: number
  tooltipContent?: string
  type: HTMLInputTypeAttribute
  value?: string | number
  min?: number
  max?: number
}

const Input = React.forwardRef<HTMLInputOrTextareaElement, InputProps>(
  (
    {
      children,
      className,
      containerClassName,
      copyButton,
      disabled,
      errors,
      hasErrors,
      id,
      isBeta,
      label,
      labelStyle,
      name,
      onBlur,
      onChangeHandler,
      onFocus,
      onKeyDown,
      placeholder,
      prefix,
      step,
      tooltipContent,
      type,
      value,
      min,
      max
    },
    ref
  ) => {
    const hasError = useMemo(() => Boolean(hasErrors || (errors && errors.length > 0)), [hasErrors, errors])
    const classString = useMemo(() => {
      return classNames('input', className, {
        'input--disabled': disabled,
        'input--error': hasError,
        'input-textarea': type === 'textarea'
      })
    }, [disabled, className, hasError, type])

    const onChange = (event: React.ChangeEvent<HTMLInputOrTextareaElement>) => {
      if (disabled) {
        return
      }
      event.preventDefault()
      onChangeHandler && onChangeHandler(event.target.value)
    }

    const inputStep = useMemo(() => {
      if (type === 'number') {
        return step ? step : 1
      }
      return step
    }, [step, type])

    const inputProps = {
      id,
      name,
      className: classString,
      disabled,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      value,
      min,
      max
    }

    const inputElement =
      type === 'textarea' ? (
        <div>
          <textarea {...inputProps} ref={ref as React.ForwardedRef<HTMLTextAreaElement>} />
          {copyButton && <CopyIcon value={inputProps.value} />}
        </div>
      ) : (
        <input {...inputProps} ref={ref as React.ForwardedRef<HTMLInputElement>} step={inputStep} type={type} />
      )

    return (
      <InputContainer
        inputId={id}
        isBeta={isBeta}
        label={label}
        labelStyle={labelStyle}
        className={`input__container ${containerClassName}`}
        disabled={disabled}
        tooltipContent={tooltipContent}
        errors={errors}
      >
        <div className={classNames('field__container', { 'field__container--prefixed': prefix })}>
          {prefix && <span className='prefix'>{prefix}</span>}
          {inputElement}
          {type === 'number' ? (
            <>
              <Arrows value={value} step={step} disabled={disabled} onChangeHandler={onChangeHandler} />
            </>
          ) : null}
        </div>
        {children}
      </InputContainer>
    )
  }
)

Input.defaultProps = {
  containerClassName: ''
}

Input.displayName = 'Input'

export { Input }
