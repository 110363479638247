import { gql } from '@apollo/client'
import { BASE_OSS_AD_UNIT_FRAGMENT } from 'webapp/gql/fragments/adUnits/BaseOssAdUnitFragment'

export const DOMAINS_WITH_OSS_AD_UNITS = gql`
  ${BASE_OSS_AD_UNIT_FRAGMENT}

  query DomainsQuery {
    domains {
      nodes {
        id
        name

        ossAdUnits {
          nodes {
            ...BaseOssAdUnitFragment
          }
        }
      }
    }
  }
`
