import React from 'react'
import { Field, Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { FormikDomainVersionPrebidModulesContainer } from 'containers/FormikContainers/FormikDomainVersionPrebidModulesContainer'

type DomainVersionPrebidModulesFormType = {
  initialValues: DomainVersionPrebidModulesFormData
  onSubmit: (
    values: DomainVersionPrebidModulesFormData,
    helpers: FormikHelpers<DomainVersionPrebidModulesFormData>
  ) => void
  workspacePrebidModules: PrebidModule[]
}

export const DomainVersionPrebidModulesForm = ({
  initialValues,
  onSubmit,
  workspacePrebidModules
}: DomainVersionPrebidModulesFormType): JSX.Element => {
  const { t } = useTranslation('inventory')

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty, isSubmitting }) => (
          <StatusForm name={t('domains.edit.domainVersionPrebidModules.title')} dirty={dirty}>
            <Field
              id='workspace-prebid-modules-ids'
              name='workspacePrebidModuleIds'
              component={FormikDomainVersionPrebidModulesContainer}
              workspacePrebidModules={workspacePrebidModules}
              labelStyle='bold'
            />

            <Button
              buttonType='submit'
              disabled={!dirty || isSubmitting}
              theme={ButtonTheme.Orange}
              type={ButtonType.Primary}
            >
              {t('common:buttons.save')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
