import React, { useContext, useEffect, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import pick from 'lodash/pick'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Box, BoxHeader } from 'components/Box'
import { AdUnitForm } from 'components/quickwrap/Inventory/AdUnits/Form'
import { AdUnitFactory } from 'components/shared/Inventory/AdUnits/Form/formSchema'
import { DomainVersionSelect } from 'components/shared/Inventory/Domains/DomainVersionSelect'
import { NotificationType } from 'components/ToastNotifier'
import { AdUnitsAmazonDemandContainer } from 'containers/Inventory/AdUnits/AdUnitsAmazonDemandContainer'
import { AdUnitsOwnBiddersContainer } from 'containers/Inventory/AdUnits/AdUnitsOwnBiddersContainer'
import { AdUnitsYieldbirdBiddersContainer } from 'containers/Inventory/AdUnits/AdUnitsYieldbirdBiddersContainer'
import { VersionedDomainProvider } from 'webapp/context/VersionedDomainContext'
import { AdUnitSerializer } from 'serializers/AdUnitSerializer'
import { NO_CACHE } from 'utilities/apolloClient'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { routesBuilder } from 'utilities/routesBuilder'
import { useDomains } from 'utilities/useDomains'
import { useScrollUp } from 'utilities/useScrollUp'
import { useWorkspaceDevices } from 'utilities/useWorkspaceDevices'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'

import UPDATE_AD_UNIT from 'gql/mutations/adUnits/UpdateAdUnit.gql'
import GET_AD_UNIT from 'gql/queries/adUnits/AdUnit.gql'

export const EditPage = (): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const history = useHistory<History>()
  const workspaceDevices = useWorkspaceDevices()
  const { adUnitId: urlAdUnitId } = useParams<{ adUnitId: string }>()
  const { domainVersionId: urlDomainVersionId } = useParams<{ domainVersionId: string }>()
  useScrollUp()

  const [adUnit, setAdUnit] = useState<AdUnit>()
  const { domain, domains } = useDomains(adUnit?.domainVersion?.id)
  const [formData, setFormData] = useState<AdUnitForm>()
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const [getAdUnit] = useLazyQuery(GET_AD_UNIT, {
    ...NO_CACHE,
    onCompleted: ({ adUnit }) => {
      setAdUnit(adUnit)
      setFormData(AdUnitFactory(adUnit))
    }
  })

  useEffect(() => {
    if (!adUnit) {
      if (urlAdUnitId && urlDomainVersionId) {
        getAdUnit({ variables: { adUnitId: urlAdUnitId, domainVersionId: urlDomainVersionId } })
      }
    } else {
      if (adUnit.ossAdUnit) {
        const { adUnitPath, elementId } = adUnit.ossAdUnit
        getAdUnit({ variables: { adUnitId: null, domainVersionId: urlDomainVersionId, adUnitPath, elementId } })
      }
    }
  }, [urlAdUnitId, urlDomainVersionId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [updateAdUnit, { loading }] = useMutation(UPDATE_AD_UNIT, {
    onCompleted: ({ updateAdUnit: { adUnit, errors } }) => {
      if (adUnit) {
        createNotifier(
          t('adUnits.edit.successMessage', { adUnitPath: adUnit.ossAdUnit.adUnitPath }),
          NotificationType.SUCCESS
        )
        setFormData(AdUnitFactory(adUnit))
        formStatus.reset('adUnitForm')
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmitHandler = (adUnitForm: AdUnitForm) => {
    const serializedAdUnit = new AdUnitSerializer(adUnitForm, formData?.sizeMapping).updateParams()

    updateAdUnit(
      nestGqlInput({
        ...pick(serializedAdUnit, [
          'adUnitId',
          'apsEnabled',
          'lazyLoadEnabled',
          'mediaTypesAttributes',
          'outstreamVideoAttributes',
          'outstreamVideoEnabled',
          'remoteControlAttributes',
          'remoteControlEnabled',
          'sizeType'
        ])
      })
    )
  }

  return (
    <>
      {adUnit && (
        <>
          {domain?.id && domain.activeVersion.id && (
            <VersionedDomainProvider domainId={domain.id}>
              <BoxHeader>
                <DomainVersionSelect
                  initialVersionId={urlDomainVersionId}
                  onChangeManaged={(versionId): void =>
                    history.push(routesBuilder.quickwrap.inventory.adUnit.edit(urlAdUnitId, versionId))
                  }
                />
              </BoxHeader>
            </VersionedDomainProvider>
          )}

          <Box>
            <BoxHeader title={t('adUnits.edit.title')} />

            {formData && (
              <AdUnitForm
                adUnit={formData}
                domain={domain}
                domains={domains}
                errors={errors}
                isSubmitting={loading}
                onSubmit={onSubmitHandler}
                setErrors={setErrors}
                workspaceDevices={workspaceDevices}
              />
            )}
          </Box>

          <AdUnitsOwnBiddersContainer adUnit={adUnit} domain={domain} />

          <AdUnitsYieldbirdBiddersContainer adUnit={adUnit} domain={domain} />

          <AdUnitsAmazonDemandContainer adUnit={adUnit} domain={domain} />
        </>
      )}
    </>
  )
}
