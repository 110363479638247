import React from 'react'

type CheckboxTreeListContainerType = {
  children?: JSX.Element[]
}

export const CheckboxTreeListContainer = ({ children }: CheckboxTreeListContainerType): JSX.Element => {
  return (
    <ul className='CheckboxTreeList__container' role='tree'>
      {children}
    </ul>
  )
}
