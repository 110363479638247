import { ABTestingRoutes } from './ABTestingRoutes'
import { InventoryRoutes } from './InventoryRoutes'
import { PerformanceRoutes } from './PerformanceRoutes'
import { PortalRoutes } from './PortalRoutes'
import { ProtectionRoutes } from './ProtectionRoutes'
import { SetupRoutes } from './SetupRoutes'

export const QuickwrapRoutes = [
  ABTestingRoutes,
  InventoryRoutes,
  PerformanceRoutes,
  PortalRoutes,
  ProtectionRoutes,
  SetupRoutes
]
