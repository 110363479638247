import React, { useContext, useState } from 'react'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AbilityAction } from 'utilities/security'
import { Can } from 'webapp/context/AbilityContext'
import { ConfirmationModal } from 'components/Modals'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { Role } from 'webapp/constants/Role'
import { Select } from 'components/Form'
import { usePublisherRoleOptions } from 'utilities/roles/options'

import ChangeRole from 'gql/mutations/users/ChangeRole.gql'

interface Props {
  row: Row<User>
}

export const RoleCell: React.FC<Props> = ({ row }) => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const history = useHistory<History>()
  const { t } = useTranslation('users')

  const roleOptions = usePublisherRoleOptions()
  const user = row.original
  const currentRole = user.role

  const [newRole, setNewRole] = useState(currentRole)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [changeRole] = useMutation(ChangeRole, {
    onCompleted: ({ changeRole: { user, errors } }) => {
      if (user) {
        createNotifier(t('modal.changeRole.notification.success'), NotificationType.SUCCESS)
        setIsModalVisible(false)
        history.push(history.location)
      } else {
        const errorText = errors[0]?.message && `(${errors[0].message})`
        createNotifier(t('modal.changeRole.notification.error', { errorText }), NotificationType.ERROR)
      }
    }
  })

  const changeHandler = (value: SelectOption) => {
    setNewRole(value.value as Role)
    setIsModalVisible(true)
  }

  const confirmHandler = () => changeRole(nestGqlInput({ userId: user.id, role: newRole }))

  return (
    <div>
      <Can I={AbilityAction.CHANGE_ROLE} this={user}>
        <Select
          className={`user-table__role-select--${currentRole}`}
          id='role'
          isSearchable={false}
          name='role'
          onChangeHandler={changeHandler}
          options={roleOptions}
          selectedItem={roleOptions.find(({ value }) => value === currentRole)}
          theme='bright'
        />

        <ConfirmationModal
          content={t('modal.changeRole.content', {
            currentRole: t(`roles.${currentRole}`),
            newRole: t(`roles.${newRole}`)
          })}
          onConfirmHandler={confirmHandler}
          setVisible={setIsModalVisible}
          visible={isModalVisible}
        />
      </Can>

      <Can not I={AbilityAction.CHANGE_ROLE} this={user}>
        <div className={`user-table__role user-table__role--${currentRole}`}>{t(`roles.${currentRole}`)}</div>
      </Can>
    </div>
  )
}
