import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import ProductBoardTokenQuery from 'gql/queries/productBoard/ProductBoardToken.gql'

interface Props {
  productBoardId: string
}

export const PortalContainer: React.FC<Props> = ({ productBoardId }) => {
  const [token, setToken] = useState('')

  const iframeLink = `https://portal.productboard.com/${productBoardId}?hide_header=0&token=${token}`

  useQuery(ProductBoardTokenQuery, {
    onCompleted: ({ productBoardTokenQuery }) => setToken(productBoardTokenQuery)
  })

  return <div>{token && <iframe className='portal-iframe' frameBorder='0' src={iframeLink} />}</div>
}
