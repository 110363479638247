import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import IconError from 'images/icon-fire.svg'
import IconSuccess from 'images/icon-tick.svg'
import { Message } from './Message'
import { MessageContainer } from './MessageContainer'

interface Props {
  message?: BulkUploadMessage
  onCloseHandler: () => void
}

export const BulkUploadResults: React.FC<Props> = ({ message = {}, onCloseHandler }) => {
  const { t } = useTranslation('inventory')

  const { details, errors } = message
  const hasErrors = useMemo(() => errors && errors.length > 0, [errors])

  return (
    <div>
      {hasErrors && (
        <MessageContainer
          icon={IconError}
          message={t('domains.edit.adUnits.bulkUploadStatus.error.message')}
          onCloseHandler={onCloseHandler}
        >
          {errors?.map((error, index) => (
            <Message key={index} type='error' content={error} />
          ))}
        </MessageContainer>
      )}

      {!hasErrors && (
        <MessageContainer
          icon={IconSuccess}
          message={t('domains.edit.adUnits.bulkUploadStatus.success.message')}
          onCloseHandler={onCloseHandler}
        >
          <Message
            type='success'
            content={t('domains.edit.adUnits.bulkUploadStatus.success.added', { value: details?.created })}
          />
          <Message
            type='success'
            content={t('domains.edit.adUnits.bulkUploadStatus.success.changed', { value: details?.updated })}
          />
          <Message
            type='success'
            content={t('domains.edit.adUnits.bulkUploadStatus.success.deleted', { value: details?.deleted })}
          />
        </MessageContainer>
      )}
    </div>
  )
}
