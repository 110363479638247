import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { BidderContext } from 'pages/quickwrap/Setup/BiddersPage/BiddersPage'
import { DomainVersionInput } from 'components/quickwrap/Setup/InventoryConnectionBox/DataTable/DomainVersionInput'
import { InventoryConnectionTable } from 'components/quickwrap/Setup/InventoryConnectionBox/DataTable/InventoryConnectionTable'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import InventoryConnectionsQuery from 'gql/queries/inventoryConnections/InventoryConnections.gql'
import UpsertInventoryConnections from 'gql/mutations/inventoryConnections/UpsertInventoryConnections.gql'

export const InventoryConnectionTableContainer: React.FC = () => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [domainVersionId, setDomainVersionId] = useState<string>()
  const [inventoryConnections, setInventoryConnections] = useState<InventoryConnections>()

  const { shouldRefreshConnections } = useContext(BidderContext)

  const [fetchInventoryConnections] = useLazyQuery(InventoryConnectionsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ inventoryConnections }) => {
      setInventoryConnections(inventoryConnections)
    }
  })

  useEffect(() => {
    if (domainVersionId) {
      setInventoryConnections(undefined)
      fetchInventoryConnections({ variables: { domainVersionId } })
    }
  }, [domainVersionId, fetchInventoryConnections, shouldRefreshConnections])

  const [upsertInventoryConnections] = useMutation(UpsertInventoryConnections, {
    onCompleted: ({ upsertInventoryConnections: { errors } }) => {
      if (errors.length) {
        createNotifier(t('bidders.inventoryConnection.dataTable.error'), NotificationType.ERROR)
      } else {
        createNotifier(t('bidders.inventoryConnection.dataTable.success'), NotificationType.SUCCESS)
      }
    }
  })

  const onSubmit = (values: InventoryConnectionsForm) =>
    upsertInventoryConnections(nestGqlInput({ domainVersionId, ...values }))

  return (
    <div className='inventory-connection-table'>
      <DomainVersionInput onChange={setDomainVersionId} />
      {domainVersionId ? (
        inventoryConnections ? (
          <InventoryConnectionTable inventoryConnections={inventoryConnections} onSubmit={onSubmit} />
        ) : (
          <CircularProgress />
        )
      ) : (
        <div>{t('bidders.inventoryConnection.dataTable.placeholder')}</div>
      )}
    </div>
  )
}
