import React from 'react'

import { Option } from './Option'
import { Select } from 'components/Form'
import { ValueContainer } from './ValueContainer'

export const MultiSelect: React.FC<SelectProps> = ({
  className,
  containerClassName,
  defaultValue,
  disabled,
  errors,
  filterOption,
  id,
  isSearchable,
  isClearable,
  label,
  labelStyle,
  name,
  options,
  onChangeHandler,
  placeholder,
  selectedItem,
  tooltipContent
}) => {
  return (
    <Select
      className={className}
      closeMenuOnSelect={false}
      components={{ ValueContainer, Option }}
      containerClassName={containerClassName}
      defaultValue={defaultValue}
      disabled={disabled}
      errors={errors}
      filterOption={filterOption}
      hideSelectedOptions={false}
      id={id}
      isMulti={true}
      isSearchable={isSearchable}
      isClearable={isClearable}
      label={label}
      labelStyle={labelStyle}
      name={name}
      options={options}
      onChangeHandler={onChangeHandler}
      placeholder={placeholder}
      selectedItem={selectedItem}
      tooltipContent={tooltipContent}
    />
  )
}
