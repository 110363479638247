export class DataStudioLinkRowData {
  constructor(public productConnection: ProductConnection) {}

  get id(): string {
    return this.productConnection.id
  }

  get workspaceName(): string {
    return this.productConnection.workspace.name
  }

  get product(): string {
    return this.productConnection.product
  }

  get dataStudioUrl(): string {
    return this.productConnection.dataStudioUrl
  }

  get updatedBy(): string {
    return this.productConnection.urlUpdatedBy.fullName
  }

  get updatedAt(): Date {
    return new Date(this.productConnection.urlUpdatedAt)
  }
}
