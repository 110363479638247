/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { capitalize } from 'utilities/capitalize'
import { defaultSizeType } from 'utilities/sizeTypeUtils'

export type DomainVersionSelect = { id: string; name: string; versionId: string }

const createTemplate = (): AdUnitForm => ({
  id: undefined,
  adUnitPath: '',
  domainVersionIds: [],
  apsEnabled: false,
  bidderParamsAttributes: [],
  demandBidderParamsAttributes: [],
  domainVersionId: { value: '', label: '' },
  elementId: '',
  lazyLoadEnabled: true,
  mediaTypes: {
    bannerEnabled: false,
    bannerSizes: [],
    videoEnabled: false,
    playerSize: [],
    nativeEnabled: false,
    native: ''
  },
  ossAdUnit: {
    adUnitPath: '',
    elementId: ''
  },
  outstreamVideoEnabled: false,
  outstreamVideoAttributes: '',
  pageIds: [],
  remoteControlEnabled: false,
  remoteControl: {
    id: '',
    appliedMethod: { value: '', label: '' },
    elementSelector: '',
    jsCodeEnabled: false,
    jsCode: '',
    cssCodeEnabled: false,
    cssCode: ''
  },
  sizeMapping: [],
  sizeMappingNative: '',
  sizeType: defaultSizeType
})

const assignToTemplate = (adUnitTemplate: AdUnitForm, adUnit: AdUnit, clone?: boolean) => {
  const { bidderParams, domain, domainVersion, mediaTypes, outstreamVideo, pages, remoteControl, ...adUnitParams } =
    adUnit
  const generalMediaType = mediaTypes!.find((mediaType) => mediaType.workspaceDevice == null)
  const sizeMappingNative = (mediaTypes!.find((mediaType) => mediaType.workspaceDevice && mediaType.native) || {})
    .native

  Object.assign(adUnitTemplate, adUnitParams)
  adUnitTemplate.domainVersionId = {
    value: domainVersion?.id ?? '',
    label: domain?.name ?? '',
    domainId: domain?.id ?? ''
  }

  if (remoteControl) {
    adUnitTemplate.remoteControl = {
      id: remoteControl.id,
      appliedMethod: { value: remoteControl.appliedMethod, label: '' },
      elementSelector: remoteControl.elementSelector,
      jsCodeEnabled: remoteControl.jsCodeEnabled,
      jsCode: remoteControl.jsCode,
      cssCodeEnabled: remoteControl.cssCodeEnabled,
      cssCode: remoteControl.cssCode
    }
  }

  if (generalMediaType) {
    adUnitTemplate.mediaTypes = buildMediaType(generalMediaType, clone)
  }

  if (adUnitTemplate.domainVersionId?.value) {
    adUnitTemplate.domainVersionIds = [adUnitTemplate.domainVersionId?.value]
  }

  adUnitTemplate.outstreamVideoEnabled = Boolean(outstreamVideo?.content)
  adUnitTemplate.outstreamVideoAttributes = JSON.stringify(outstreamVideo?.content, null, 2) ?? ''
  adUnitTemplate.pageIds = pages?.nodes.map((page) => ({ value: page.id.toString(), label: page.path })) || []
  adUnitTemplate.sizeMapping = mediaTypes!
    .filter((mediaType) => mediaType.workspaceDevice)
    .map((mediaType) => {
      return Object.assign(
        {
          label: `${capitalize(mediaType?.workspaceDevice?.name || '')} (min ${
            mediaType?.workspaceDevice?.minViewPort
          })`,
          minViewPort: mediaType?.workspaceDevice?.minViewPort,
          value: mediaType?.workspaceDevice?.id
        },
        buildMediaType(mediaType, clone)
      )
    })
  adUnitTemplate.sizeMappingNative = buildNative(sizeMappingNative)
}

const buildMediaType = (mediaType: MediaTypes, clone?: boolean): MediaTypeForm => {
  const bannerSizes = mediaType.bannerSizes
  const native = mediaType.native
  const playerSize = mediaType.playerSize

  let bannerSizesProcessed: string[] = []
  if (bannerSizes?.length) {
    bannerSizesProcessed = bannerSizes?.map((size: number[] | string) =>
      Array.isArray(size) ? `${size[0]}x${size[1]}` : size
    )
  }

  let playerSizeProcessed: string[] = []
  if (playerSize?.length) {
    playerSizeProcessed = Array.isArray(playerSize) ? [`${playerSize[0]}x${playerSize[1]}`] : [playerSize]
  }

  return {
    bannerEnabled: !!bannerSizes,
    bannerSizes: bannerSizesProcessed,
    id: (!clone && mediaType.id) || undefined,
    native: buildNative(native),
    nativeEnabled: !!native,
    playerSize: playerSizeProcessed,
    videoEnabled: !!playerSize
  }
}

const buildNative = (native: unknown): string => {
  return native ? JSON.stringify(native, null, 2) || '' : ''
}

export const AdUnitFactory = (
  adUnit?: AdUnit,
  domain?: DomainVersionSelect,
  adUnitPath?: string,
  elementId?: string,
  domainVersionId?: string
): AdUnitForm => {
  const adUnitTemplate = createTemplate()

  if (domain) {
    adUnitTemplate.domainVersionId = { value: domain.versionId, label: domain.name, domainId: domain.id }
  }

  if (adUnit) {
    const { bidderParams, ...adUnitParams } = adUnit
    assignToTemplate(adUnitTemplate, adUnitParams)
  }

  if (adUnitPath) {
    adUnitTemplate.adUnitPath = adUnitPath
  }

  if (elementId) {
    adUnitTemplate.elementId = elementId
  }

  if (domainVersionId) {
    adUnitTemplate.domainVersionIds = [domainVersionId]
  }

  return adUnitTemplate
}

export const ClonedAdUnitFactory = (adUnit: AdUnit): AdUnitForm => {
  const adUnitTemplate = createTemplate()
  const { adUnitPath, bidderParams, elementId, id, ...adUnitParams } = adUnit
  assignToTemplate(adUnitTemplate, adUnitParams, true)
  return adUnitTemplate
}
