import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useLazyQuery } from '@apollo/client'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { BidderContext } from 'pages/quickwrap/Setup/BiddersPage/BiddersPage'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { DomainComparisonTable } from 'components/quickwrap/Setup/DomainComparisonTable'
import { useAdvancedFilterConfig } from './useAdvancedFilterConfig'
import { useQueryTableParams } from 'containers/QueryTable/useQueryTableParams'

import BidderSettingsQuery from 'gql/queries/bidderSettings/BidderSettings.gql'
import ComparisonDomains from 'gql/queries/domainComparison/ComparisonDomains.gql'

interface Props {
  isVisible?: boolean
}

export const DomainComparisonContainer: React.FC<Props> = ({ isVisible = true }) => {
  const [domains, setDomains] = useState<Domain[]>()
  const [bidderSettings, setBidderSettings] = useState<BidderSetting[]>()

  const { shouldRefreshConnections } = useContext(BidderContext)
  const queryTableParams = useQueryTableParams(CollectionNames.DomainComparison, undefined, 100)

  const [fetchDomains, { loading: domainsLoading }] = useLazyQuery(ComparisonDomains, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setDomains(data.domains.nodes)
    },
    variables: { active: true, filter: queryTableParams.filter }
  })

  useEffect(() => {
    fetchDomains()
  }, [fetchDomains, queryTableParams])

  const [fetchBidders, { loading: biddersLoading }] = useLazyQuery(BidderSettingsQuery, {
    fetchPolicy: 'network-only',
    onCompleted({ bidderSettings: { nodes } }) {
      setBidderSettings(
        nodes.sort((a: BidderSetting, b: BidderSetting) => {
          const aName = a.bidder.demand ? a.bidder.name : a.bidderCode
          const bName = b.bidder.demand ? b.bidder.name : b.bidderCode
          return aName.localeCompare(bName)
        })
      )
    }
  })

  useEffect(() => {
    fetchBidders({ variables: { unscoped: true } })
  }, [fetchBidders, shouldRefreshConnections])

  const loading = domainsLoading || biddersLoading || !domains || !bidderSettings

  const filterConfig = useAdvancedFilterConfig()

  return (
    <div hidden={!isVisible} className='domain-comparison'>
      <AdvancedFilter collectionName={CollectionNames.DomainComparison} config={filterConfig} />

      <div>
        {loading ? <CircularProgress /> : <DomainComparisonTable bidderSettings={bidderSettings} domains={domains} />}
      </div>
    </div>
  )
}
