import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { DropdownMenu } from 'components/DropdownMenu'
import { Edit } from 'icons/Edit'

interface Props {
  hasAnyParams?: boolean
  onEditHandler: () => void
  onDeleteHandler: () => void
}

export const ParamActionButtons: React.FC<Props> = ({ hasAnyParams, onEditHandler, onDeleteHandler }) => {
  const { t } = useTranslation()

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  return (
    <Grid item xs={5} className='d-flex justify-content-end align-items-center'>
      <DropdownMenu setVisible={setIsDropdownVisible} visible={isDropdownVisible}>
        {hasAnyParams && (
          <MenuItem onClick={onEditHandler}>
            {t('common:buttons.edit')} <Edit />
          </MenuItem>
        )}

        <MenuItem onClick={onDeleteHandler}>
          {t('common:buttons.delete')} <Bin />
        </MenuItem>
      </DropdownMenu>
    </Grid>
  )
}
