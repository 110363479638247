import React from 'react'

import { CellProps, Column } from 'react-table'

import { DateCell } from 'components/Table'
import { CroppedCell } from 'components/Table/CroppedCell'
import i18n from 'webapp/i18n'
import { CustomCodeActionsCell } from './CustomCodeActionsCell'

export const columnsConfig: Column<CustomCode>[] = [
  {
    Header: () => i18n.t('setup:customCodes.tableHeader.name'),
    accessor: 'name',
    Cell: ({ value }) => value
  },
  {
    Header: () => i18n.t('setup:customCodes.tableHeader.lastModified'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    Header: () => i18n.t('setup:customCodes.tableHeader.comment'),
    accessor: 'comment',
    Cell: ({ value }) => <CroppedCell textElements={value} />
  },
  {
    id: 'actions',
    Cell: ({ row }: CellProps<CustomCode>) => <CustomCodeActionsCell row={row} />,
    disableSortBy: true
  }
]
