import React, { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'

import {
  DomainVersionOptionsFactory,
  DomainVersionSelectOption
} from 'components/shared/Inventory/Domains/DomainVersionSelect/DomainVersionOptionsFactory'
import { NO_CACHE } from 'utilities/apolloClient'
import { useTestedDomainVersionIds as getTestedDomainVersionIds } from 'utilities/useTestedDomainVersionIds'
import {
  DomainData,
  DomainVars,
  DomainVersionsData,
  DomainVersionsVars,
  VersionedDomainContextProps,
  VersionedDomainProviderProps
} from './types'

import GET_VERSIONED_DOMAIN from 'gql/queries/domains/DomainABTests.gql'
import GET_DOMAIN_VERSIONS from 'gql/queries/domainVersions/DomainVersions.gql'

export const VersionedDomainContext = React.createContext<VersionedDomainContextProps>(
  {} as VersionedDomainContextProps
)

export const VersionedDomainProvider: React.FC<VersionedDomainProviderProps> = ({ domainId, children }) => {
  const [domainVersions, setDomainVersions] = useState<DomainVersion[]>([])
  const [versionedDomain, setVersionedDomain] = useState<VersionedDomain | undefined>(undefined)
  const [domainVersionSelectOptions, setDomainVersionSelectOptions] = useState<DomainVersionSelectOption[]>([])

  const [refreshDomainVersionQuery] = useLazyQuery<DomainVersionsData, DomainVersionsVars>(GET_DOMAIN_VERSIONS, {
    ...NO_CACHE,
    variables: { domainId },
    onCompleted: ({ domainVersions: { nodes } }: DomainVersionsData): void => setDomainVersions(nodes)
  })

  const [getVersionedDomain] = useLazyQuery<DomainData, DomainVars>(GET_VERSIONED_DOMAIN, {
    ...NO_CACHE,
    onCompleted: ({ versionedDomain }: DomainData): void => setVersionedDomain(versionedDomain)
  })

  useEffect(() => {
    refreshDomainVersionQuery()
  }, [domainId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (domainVersions.length > 0) {
      getVersionedDomain({ variables: { domainId, domainVersionId: domainVersions[0].id } })
    }
  }, [domainVersions]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const ids: string[] = getTestedDomainVersionIds(versionedDomain)
    const options: DomainVersionSelectOption[] = DomainVersionOptionsFactory(domainVersions, ids)

    setDomainVersionSelectOptions(options)
  }, [versionedDomain]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <VersionedDomainContext.Provider value={{ domainVersions, refreshDomainVersionQuery, domainVersionSelectOptions }}>
      {children}
    </VersionedDomainContext.Provider>
  )
}
