import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { routesBuilder } from 'utilities/routesBuilder'

import UpdateWorkspace from 'gql/mutations/workspaces/UpdateWorkspace.gql'

type ReturnType = [(workspace: DataStudioLinkFormData) => void, ErrorUtils, boolean]

export const useUpdateWorkspaceUrl = (action: 'edit' | 'new'): ReturnType => {
  const [updateWorkspace, { loading }] = useMutation(UpdateWorkspace)
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('dataStudio')

  const [errors, setErrors] = useState(new ErrorUtils([]))

  const callback = async (formData: DataStudioLinkFormData) => {
    const { data } = await updateWorkspace(
      nestGqlInput({ dataStudioUrl: formData.dataStudioUrl, workspaceId: formData.workspaceId?.value })
    )

    const errors = data.updateWorkspace.errors
    if (errors.length) {
      setErrors(new ErrorUtils(errors))
      createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
    } else {
      createNotifier(t(`${action}.successMessage`), NotificationType.SUCCESS)
      formStatus.clear()
      history.push(routesBuilder.oss.finance.dataStudioLinks.root)
    }
  }

  return [callback, errors, loading]
}
