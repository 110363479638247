import React, { useMemo, useState } from 'react'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { AvailablePrebidModules, PrebidModuleKind } from 'webapp/constants/PrebidModuleKind'
import { Box, BoxBody, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { camelize } from 'webapp/utils/camelize'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikPrebidModulesContainer } from 'containers/FormikContainers'
import { PrebidModuleFactory } from 'webapp/containers/quickwrap/Setup/ModulesContainer/formSchema'
import { StatusForm } from 'components/Form'
import { usePrebidModules } from 'utilities/prebid/usePrebidModules'
import { useSubmitPrebidModuleParams } from 'containers/quickwrap/Setup/ModulesContainer/useSubmitPrebidModuleParams'
import { useWorkspacePrebidModules } from 'utilities/prebid/useWorkspacePrebidModules'

interface Props {
  prebidModuleKind: PrebidModuleKind
}

export const PrebidModuleFormContainer: React.FC<Props> = ({ prebidModuleKind }) => {
  const prebidModuleKindName = camelize(prebidModuleKind) as AvailablePrebidModules
  const { t } = useTranslation('setup')

  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const prebidModules = usePrebidModules(prebidModuleKind)
  const { loading, refetch, workspacePrebidModules } = useWorkspacePrebidModules(prebidModuleKind)

  const initialValues = useMemo(
    () => PrebidModuleFactory(workspacePrebidModules, prebidModuleKindName),
    [prebidModuleKindName, workspacePrebidModules]
  )

  const { onSubmit, submitting } = useSubmitPrebidModuleParams(setIsDirty, refetch, prebidModuleKindName)

  return (
    <Box className={`${prebidModuleKindName} prebid-modules`} isExpandable={true}>
      <BoxHeader
        title={t(`${prebidModuleKindName}.form.title`)}
        subtitle={t(`${prebidModuleKindName}.form.subtitle`)}
      />
      {workspacePrebidModules && prebidModules && (
        <BoxBody>
          <div className='box__body'>
            <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
              <StatusForm name={`qw-${prebidModuleKindName}`} dirty={isDirty}>
                <Field
                  component={FormikPrebidModulesContainer}
                  errorType={`${prebidModuleKindName}Error`}
                  errors={errors}
                  formId={`add-${prebidModuleKind}-form`}
                  handleIsDirty={setIsDirty}
                  isDirty={isDirty}
                  moduleKind={prebidModuleKind}
                  name={prebidModuleKindName}
                  prebidModules={prebidModules}
                  selectId={`${prebidModuleKindName}FormSelect`}
                  setErrors={setErrors}
                  translations={`${prebidModuleKindName}`}
                />

                <Button
                  buttonType='submit'
                  disabled={!isDirty || submitting || loading}
                  theme={ButtonTheme.Orange}
                  type={ButtonType.Primary}
                >
                  {t('common:buttons.save')}
                </Button>
              </StatusForm>
            </Formik>
          </div>
        </BoxBody>
      )}
    </Box>
  )
}
