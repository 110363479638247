import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { BidderSettingsForm } from 'components/sharedInterfaces/BidderSettingsForm'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { createSectionInitialValues, createSectionNewBidderFactory } from 'components/Bidders/YbDemandForm/formSchema'
import { CollectionField } from 'components/FormikComponents'
import { Errors } from 'utilities/errorUtils'
import { LoadingButton } from 'components/Button/LoadingButton'
import { RequestNewBidderContainer } from 'containers/quickwrap/Setup/RequestNewBidderContainer'
import { StatusForm } from 'components/Form'
import { YbDemandBiddersRow } from 'components/Bidders/YbDemandForm/YbDemandBiddersRow'
import { YbDemandFilteredBidderSelect } from 'components/Bidders/YbDemandForm/YbDemandFilteredBidderSelect'

interface Props {
  demandBidderSettings: BidderSetting[]
  demandBidders: Bidder[]
  errors: Errors
  isSubmitting: boolean
  onSubmitHandler: (form: BidderSettingsForm, formikHelpers: FormikHelpers<BidderSettingsForm>) => void
}

export const YbDemandCreateSection: React.FC<Props> = ({
  demandBidderSettings,
  demandBidders,
  errors,
  onSubmitHandler,
  isSubmitting
}) => {
  const { t } = useTranslation('setup')

  return (
    <div className='yb-demand-bidders__create-section'>
      <Formik initialValues={createSectionInitialValues} onSubmit={onSubmitHandler}>
        {({ dirty, setFieldValue, values }) => {
          const bidders = values.bidders

          const onAddNewBidder = () => {
            setFieldValue('bidders', [...values.bidders, createSectionNewBidderFactory(values.newBidder!.value)])
            setFieldValue('newBidder', null)
          }

          return (
            <StatusForm name='yb-demand-bidders-add' dirty={dirty}>
              <Grid className='yb-demand-bidders__grid' container spacing={2}>
                <Grid item xs={9}>
                  <YbDemandFilteredBidderSelect
                    demandBidderSettings={demandBidderSettings}
                    demandBidders={demandBidders}
                    key={values.newBidder?.value ?? ''}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Button
                    buttonType='button'
                    className='yb-demand-bidders__add-button'
                    disabled={!values.newBidder?.value}
                    onClickHandler={onAddNewBidder}
                    theme={ButtonTheme.Blue}
                    type={ButtonType.Primary}
                  >
                    {t('yieldbirdDemand.form.add')}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <RequestNewBidderContainer demand={true} />
                </Grid>

                {values.bidders.length > 0 && (
                  <Grid item xs={12}>
                    <CollectionField
                      addNextHidden={true}
                      confirmRemoval
                      collectionName={'demandBidders'}
                      emptyElementTemplate={{}}
                      name='bidders'
                    >
                      {({ index }: { index: number }) => (
                        <YbDemandBiddersRow
                          index={index}
                          demandBidders={demandBidders}
                          hideAdditionalOptions={true}
                          errors={errors}
                        />
                      )}
                    </CollectionField>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <div className='yb-demand-bidders__save-button'>
                    <LoadingButton
                      buttonType='submit'
                      disabled={!dirty || bidders.length === 0}
                      loading={isSubmitting}
                      theme={ButtonTheme.Orange}
                      type={ButtonType.Primary}
                    >
                      {t('common:buttons.sendRequest')}
                    </LoadingButton>
                  </div>
                </Grid>
              </Grid>
            </StatusForm>
          )
        }}
      </Formik>
    </div>
  )
}
