import React, { useMemo, useState, useContext, useCallback } from 'react'
import queryString from 'query-string'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ReportForm } from 'components/quickwrap/Performance/Reports/ReportForm'
import { routesBuilder } from 'utilities/routesBuilder'
import { useGetAdManagerReport } from 'containers/quickwrap/Performance/Reports/useGetAdManagerReport'

import AvailableNetworks from 'webapp/gql/queries/networks/AvailableNetworks.gql'

export const ReportFormContainer: React.FC = () => {
  const { t } = useTranslation('reports')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [availableNetworks, setAvailableNetworks] = useState<Array<AvailableNetwork>>([])
  const [currencyCode, setCurrencyCode] = useState('')

  const returnToReportingWithError = useCallback(
    (error: string) => {
      history.push(routesBuilder.quickwrap.performance.reporting)
      createNotifier(error, NotificationType.ERROR)
    },
    [createNotifier, history]
  )

  const refreshToken = useMemo(() => {
    const { refreshToken } = queryString.parse(history.location.search)
    if (refreshToken) {
      return refreshToken
    }

    returnToReportingWithError(t('formPage.errors.noRefreshToken'))
    return undefined
  }, [history.location.search, returnToReportingWithError, t])

  useQuery(AvailableNetworks, {
    variables: { refreshToken },
    onCompleted: ({ availableNetworks }) => {
      if (availableNetworks.length > 0) {
        setAvailableNetworks(availableNetworks)
      } else {
        returnToReportingWithError(t('formPage.errors.noConnectedNetworks'))
      }
    }
  })

  const { isSubmitting, setIsSubmitting, setReportFormData, generateAdManagerReport } = useGetAdManagerReport(
    refreshToken,
    currencyCode
  )

  const onSubmitHandler = (reportForm: ReportFormType) => {
    setIsSubmitting(true)
    createNotifier(t('formPage.waitingForReport'), NotificationType.WARNING, 3)
    setReportFormData(reportForm)
    const input = {
      refreshToken,
      networkCode: reportForm.networkCode,
      startDate: reportForm.timePeriod.startDate,
      endDate: reportForm.timePeriod.endDate
    }
    generateAdManagerReport({ variables: { input } })
  }

  const submit = (values: ReportFormValues) => {
    const currencyCode = availableNetworks
      .filter((network) => network.networkCode === values?.network.value)
      .map((network) => network.currencyCode)[0]

    setCurrencyCode(currencyCode)

    const reportForm = {
      networkName: values.network.label,
      networkCode: values.network.value,
      timePeriod: values.timePeriod
    }

    onSubmitHandler(reportForm)
  }

  return (
    <Box>
      <BoxHeader title={t('formPage.title')} subtitle={t('formPage.subtitle')} />
      <div className='box__body'>
        <Formik
          initialValues={{
            network: { value: '', label: '' },
            timePeriod: { startDate: undefined, endDate: undefined }
          }}
          onSubmit={submit}
        >
          <ReportForm availableNetworks={availableNetworks} isSubmitting={isSubmitting} />
        </Formik>
      </div>
    </Box>
  )
}
