import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useField, FieldConfig } from 'formik'

import { Checkbox } from 'components/Form'
import { InputContainer } from 'components/Form/InputContainer'

interface Props {
  id: string
  disabled: boolean
  errors?: Array<string>
  label: string
  tooltipContent?: React.ReactNode
  field: FieldConfig
}

export const AdFormat: React.FC<Props> = ({ id, disabled, errors, label, tooltipContent, ...other }) => {
  const { t } = useTranslation('setup')
  const [field, , helpers] = useField(other.field.name)
  const formats = ['display', 'mobileWeb', 'videoInStream', 'mobileApp', 'videoOutstream', 'other']

  const updateAdFormatType = useCallback((fieldValue: string[], adFormat: string) => {
    let result = [...fieldValue]

    if (result.includes(adFormat)) {
      result = result.filter((el) => el !== adFormat)
    } else {
      result.push(adFormat)
    }

    return result
  }, [])

  const onChange = useCallback(
    (_event: React.ChangeEvent, adFormat: string) => {
      helpers.setValue(updateAdFormatType(field.value, adFormat))
    },
    [helpers, field, updateAdFormatType]
  )

  return (
    <InputContainer
      inputId={id}
      label={label}
      labelStyle='bold'
      className={'input__container'}
      disabled={disabled}
      tooltipContent={tooltipContent}
      errors={errors}
    >
      <Grid container spacing={1}>
        {formats.map((format: string) => {
          return (
            <Grid item lg={4} xs={6} key={`ad-format-${format}`}>
              <Checkbox
                name={`adFormat${format.charAt(0).toUpperCase()}${format.slice(1)}`}
                id={`ad-format-${format}`}
                checked={field.value.includes(format)}
                disabled={disabled}
                label={t(`recommendBidders.form.adFormat.values.${format}`)}
                onChange={(event: React.ChangeEvent) => onChange(event, format)}
              />
            </Grid>
          )
        })}
      </Grid>
    </InputContainer>
  )
}
