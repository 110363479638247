import React from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Section, StatusForm } from 'components/Form'
import TCFSection, { TCFSectionPlacement } from 'components/shared/Inventory/Domains/TCFSection'
import { Errors } from 'utilities/errorUtils'

interface Props {
  consentManagementEnabled: boolean
  errors: Errors
  formData: OSSConsentManagementFormData
  isSubmitting: boolean
  onSubmit: (formData: OSSConsentManagementFormData) => void
}

export const DomainConsentManagementForm: React.FC<Props> = ({
  consentManagementEnabled,
  errors,
  formData,
  isSubmitting,
  onSubmit
}) => {
  const { t } = useTranslation('inventory')

  return (
    <div className='oss-consent-management-form'>
      <Formik initialValues={formData} onSubmit={onSubmit} enableReinitialize={true}>
        {({ values, dirty }) => (
          <StatusForm name='oss-domain-consent-management' dirty={dirty && consentManagementEnabled}>
            <TCFSection errors={errors} placement={TCFSectionPlacement.EDIT_DOMAIN} values={values} />

            <Section fullWidth>
              <Button
                className='oss-consent-management-form__submit'
                disabled={isSubmitting || !dirty}
                type={ButtonType.Primary}
                theme={ButtonTheme.Blue}
                buttonType='submit'
              >
                {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
              </Button>
            </Section>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
