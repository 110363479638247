import { AbilityAction, AbilitySubject } from 'utilities/security'
import { CanFunctionType } from 'webapp/context/AbilityContext/AbilityContext'

export const processEditorPermissions = (can: CanFunctionType, user: User): void => {
  can(AbilityAction.ACCESS, AbilitySubject.BIDDER_SETTINGS_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.BIDDER_SETTINGS)
  can(AbilityAction.ACCESS, AbilitySubject.BLOCK_URL_GROUP_EDIT)
  can(AbilityAction.ACCESS, AbilitySubject.BLOCK_URL_GROUP_NEW)
  can(AbilityAction.ACCESS, AbilitySubject.BLOCK_URL_GROUP)
  can(AbilityAction.ACCESS, AbilitySubject.CUSTOM_CODES_EDIT)
  can(AbilityAction.ACCESS, AbilitySubject.CUSTOM_CODES_NEW)
  can(AbilityAction.ACCESS, AbilitySubject.DEVICES)
  can(AbilityAction.ACCESS, AbilitySubject.DOMAINS_NEW)
  can(AbilityAction.ACCESS, AbilitySubject.FINANCE_DATA_STUDIO_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.GAM_CONNECTOR)
  can(AbilityAction.ACCESS, AbilitySubject.GENERAL_SETTINGS_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.MODULES_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.PRODUCT)
  can(AbilityAction.ACCESS, AbilitySubject.SETUP)
  can(AbilityAction.ADD, AbilitySubject.DOMAIN)
  can(AbilityAction.DELETE, AbilitySubject.DOMAIN)
  can(AbilityAction.EDIT, AbilitySubject.ADUNIT)
  can(AbilityAction.EDIT, AbilitySubject.DOMAIN)
  can(AbilityAction.EDIT, AbilitySubject.GENERAL_SETTINGS)
  can(AbilityAction.EDIT, AbilitySubject.PAGE)

  if (user.workspace?.adNetworkEnabled) {
    can(AbilityAction.ACCESS, AbilitySubject.NETWORKS_PATH)
    can(AbilityAction.ACCESS, AbilitySubject.NETWORKS_PATH_EDIT)
    can(AbilityAction.ACCESS, AbilitySubject.NETWORKS_PATH_NEW)
  }
}
