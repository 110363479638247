import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { Box, BoxHeader } from 'components/Box'
import { GamConnectButton } from 'components/oss/Gam/GamConnectButton'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { oauthReportsCallbackUrl } from 'webapp/constants/oauthCallbackUrl'

export const ConnectReportContainer: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation('reports')
  const { createNotifier } = useContext(NotificationContext) as Notification

  useEffect(() => {
    const urlParams = queryString.parse(location.search)
    urlParams.error && createNotifier(t(urlParams.error), NotificationType.ERROR)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <Box>
      <BoxHeader title={t('connectPage.title')} subtitle={t('connectPage.subtitle')} />

      <div className='box__body'>
        <GamConnectButton oauthCallbackUrl={oauthReportsCallbackUrl} translateKey={'connector.button.connect'} />
      </div>
    </Box>
  )
}
