import { Product } from 'constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'
import useProductHistory from 'webapp/hooks/useProductHistory'
import { UseRedirectOnSaveProps } from './types'

export const useRedirectOnSave = (): UseRedirectOnSaveProps => {
  const { history, prevProduct } = useProductHistory()

  const redirectOnSave = (domainId: Domain['id'], domainVersionId: DomainVersion['id']): void => {
    const routeBasedOnPreviousProduct = (prevProduct: Product | undefined): string => {
      switch (prevProduct) {
        case Product.Quickwrap:
          return routesBuilder.quickwrap.inventory.domains.edit(domainId, domainVersionId)
        case Product.Revive:
          return routesBuilder.revive.configuration.root
        case Product.PriceGenius:
          return routesBuilder.price_genius.configuration.root
        default:
          return routesBuilder.oss.inventory.domains.root
      }
    }

    history.push(routeBasedOnPreviousProduct(prevProduct))
  }

  return { redirectOnSave }
}
