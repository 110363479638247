import React from 'react'
import { Row } from 'react-table'
import { useTranslation } from 'react-i18next'

interface Props {
  row: Row<User>
}

export const StatusCell: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation('users')
  const status = row.values.status

  return <div className={`cell--status-${status}`}>{t(`statuses.${status}`)}</div>
}
