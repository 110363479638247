import React from 'react'

import { DownloadTagPage } from 'pages/shared/DownloadTags'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { Product } from 'webapp/constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'

export const DownloadTagsRoutes = [
  <LayoutRoute
    key='download-tags'
    product={Product.OSS}
    path={routesBuilder.oss.downloadTags.root}
    exact
    component={DownloadTagPage}
  />
]
