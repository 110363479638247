import React, { useContext, useState } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import { ConnectedNetworksTableContext } from 'components/oss/Workspaces/ConnectedNetworksTable/ConnectedNetworksTableContext'
import { ErrorUtils } from 'utilities/errorUtils'
import { GAMConnectionSerializer } from 'serializers/GAMConnectionSerializer'
import { GAMConnectorFormType } from 'components/oss/Workspaces/WorkspaceGamConnector/formSchema'
import { GamConnectorForm } from 'components/oss/Workspaces/WorkspaceGamConnector/GamConnectorForm'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { routesBuilder } from 'utilities/routesBuilder'

import AvailableNetworks from 'webapp/gql/queries/networks/AvailableNetworks.gql'
import CreateGAMConnection from 'webapp/gql/mutations/workspaces/CreateGAMConnection.gql'
import UpdateGAMConnection from 'webapp/gql/mutations/workspaces/UpdateGAMConnection.gql'

export const GamConnectorContainer: React.FC = () => {
  const { t } = useTranslation('workspaces')
  const history = useHistory<History>()

  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [availableNetworks, setAvailableNetworks] = useState<AvailableNetwork[]>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [formValues, setFormValues] = useState<GAMConnectorFormType>()

  const { createNotifier } = useContext(NotificationContext) as Notification
  const { connectedNetworksQuery } = useContext(ConnectedNetworksTableContext)

  const returnToReportingWithError = (error: string) => {
    history.push(routesBuilder.oss.workspace.root)
    createNotifier(error, NotificationType.ERROR)
  }

  const getRefreshToken = () => {
    const { refreshToken } = queryString.parse(history.location.search)

    if (refreshToken) {
      return refreshToken
    }

    returnToReportingWithError(t('gamConnector.networkForm.errors.emptyRefreshToken'))

    return ''
  }

  useQuery(AvailableNetworks, {
    variables: { refreshToken: getRefreshToken() },
    onCompleted: ({ availableNetworks }) => {
      setAvailableNetworks(availableNetworks)

      if (!availableNetworks.length) {
        returnToReportingWithError(t('gamConnector.networkForm.errors.emptyNetworks'))
      }
    }
  })

  const [createGamConnection, { loading: isCreateLoading }] = useMutation(CreateGAMConnection, {
    onCompleted: ({ createGamConnection: { errors } }) => {
      connectedNetworksQuery()
      setIsModalVisible(false)
      setErrors(new ErrorUtils(errors))
    },
    onError: (error) => {
      createNotifier(error.message, NotificationType.ERROR)
      setIsModalVisible(false)
    }
  })

  const [updateGamConnection, { loading: isUpdateLoading }] = useMutation(UpdateGAMConnection, {
    onCompleted: ({ updateGamConnection: { errors } }) => {
      connectedNetworksQuery()
      setIsModalVisible(false)
      setErrors(new ErrorUtils(errors))
    },
    onError: (error) => {
      createNotifier(error.message, NotificationType.ERROR)
      setIsModalVisible(false)
    }
  })

  const onRunClicked = (form: GAMConnectorFormType) => {
    setFormValues(form)
    setIsModalVisible(true)
  }

  const onModalSubmit = () => {
    if (!formValues) return
    if (isCreateLoading || isUpdateLoading) return

    const serializer = new GAMConnectionSerializer(formValues, getRefreshToken() as string)
    if (formValues.id) {
      updateGamConnection(nestGqlInput(serializer.getParams()))
    } else {
      createGamConnection(nestGqlInput(serializer.getParams()))
    }
  }

  return (
    <GamConnectorForm
      availableNetworks={availableNetworks}
      errors={errors}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      onRunClicked={onRunClicked}
      onModalSubmit={onModalSubmit}
      setErrors={setErrors}
    />
  )
}
