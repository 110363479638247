import React, { useState } from 'react'

import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { DropdownMenu } from 'components/DropdownMenu'
import { Product } from 'constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'
import { ReviveDomainConfigurationRow } from '../ReviveDomainConfigurationRow'
import { ActionsCellProps } from './types'

import { Edit } from 'icons/Edit'

export const ActionsCell = ({ row }: ActionsCellProps): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory<History>()
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const { id }: ReviveDomainConfigurationRow = row.original

  const onEdit = (): void => history.push(routesBuilder.revive.configuration.edit(id))

  return (
    <div onClick={(e): void => e.stopPropagation()}>
      <DropdownMenu product={Product.Revive} setVisible={setIsDropdownVisible} visible={isDropdownVisible}>
        <MenuItem onClick={onEdit}>
          {t('common:buttons.edit')}
          <Edit />
        </MenuItem>
      </DropdownMenu>
    </div>
  )
}
