import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ToggleSwitch } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { UserContext } from 'context/UserContext'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error as QqlError } from 'utilities/errorUtils'
import { WrapperVersion } from 'webapp/constants/WrapperVersion'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { DomainWrapperVersionProps, UpdateWrapperVersionData, UpdateWrapperVersionVars } from './types'

import { UPDATE_DOMAIN_WRAPPER_VERSION } from 'gql/mutations/domains/UpdateDomainWrapperVersion'

import './style.scss'

type Data = { status: boolean; wrapperVersion: string }

export const DomainWrapperVersionToggle = ({ domainId, initialStatus }: DomainWrapperVersionProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { isSuperuser } = useContext(UserContext)
  const versionToBoolean = (version: string): boolean => (version == WrapperVersion.V1 ? false : true)
  const opositVersion = (version: string): string =>
    version == WrapperVersion.V1 ? WrapperVersion.V2 : WrapperVersion.V1
  const [data, setData] = useState<Data>({ status: versionToBoolean(initialStatus), wrapperVersion: initialStatus })

  const [updateWrapperVersion] = useMutation<UpdateWrapperVersionData, { input: UpdateWrapperVersionVars }>(
    UPDATE_DOMAIN_WRAPPER_VERSION,
    {
      onCompleted: ({ updateDomain: { domain, errors } }) => {
        if (errors.length > 0) {
          createNotifier(errors.map((e: QqlError) => e.message).join(', '), NotificationType.ERROR)
        } else if (domain) {
          const { name, wrapperVersion } = domain

          createNotifier(
            t('domains.wrapperVersionToggle.update.success', { wrapperVersion, name }),
            NotificationType.SUCCESS
          )
          setData({ status: versionToBoolean(wrapperVersion), wrapperVersion })
        }
      }
    }
  )

  const handleChange = () =>
    void updateWrapperVersion(
      nestGqlInput({
        domainId,
        ['wrapperVersion']: opositVersion(data.wrapperVersion)
      })
    )

  const TOGGLE_KEY = `domain-${domainId}-wrapper-version-toggle`

  return (
    <div className='DomainWrapperVersionToggle' onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        checked={data.status}
        disabled={!isSuperuser}
        id={TOGGLE_KEY}
        key={TOGGLE_KEY}
        name={TOGGLE_KEY}
        onChange={() => handleChange()}
      />
    </div>
  )
}
