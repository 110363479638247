import React, { useCallback, useRef } from 'react'
import classNames from 'classnames'

import { ErrorMessages, Input } from 'components/Form'
import { InputContainer } from 'components/Form/InputContainer'
import { Slider } from 'components/Form/Slider'

interface Props {
  id: string
  name: string
  onChangeHandler: (value: string) => void
  value: string
  containerClassName?: string
  disabled?: boolean
  errors?: Array<string>
  hasErrors?: boolean
  invert?: boolean
  label?: string
  labelStyle?: string
  tooltipContent?: string
}

export const PercentInput: React.FC<Props> = ({
  id,
  name,
  onChangeHandler,
  value,
  containerClassName,
  disabled,
  errors,
  hasErrors,
  invert,
  label,
  labelStyle,
  tooltipContent
}) => {
  const ref = useRef<HTMLInputElement>(null)

  const onBlur = useCallback(() => {
    onChangeHandler(cleanValue(value, true))
  }, [value, onChangeHandler])

  const onInputChangeHandler = useCallback(
    (value: string) => {
      const newValue = cleanValue(value)
      onChangeHandler(newValue)
      setCursor(newValue, ref)
    },
    [onChangeHandler, ref]
  )

  const onFocus = useCallback(() => {
    setCursor(value, ref)
  }, [ref, value])

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!['ArrowDown', 'ArrowUp'].includes(event.key)) return
      event.preventDefault()

      let newValue = event.key === 'ArrowDown' ? Number(value) - 1 : Number(value) + 1
      newValue = Math.min(Math.max(newValue, 0), 100)

      onChangeHandler(String(newValue))
      setCursor(String(newValue), ref)
    },
    [onChangeHandler, ref, value]
  )

  const onSliderChangeHandler = useCallback(
    (event: React.ChangeEvent<unknown>, value: number | number[]) => {
      onChangeHandler(String(value))
    },
    [onChangeHandler]
  )

  return (
    <InputContainer
      inputId={id}
      label={label}
      labelStyle={labelStyle}
      className={classNames('input__container', containerClassName)}
      disabled={disabled}
      tooltipContent={tooltipContent}
    >
      <div
        className={classNames('percent-input', {
          'percent-input--disabled': disabled,
          'percent-input--inverted': invert
        })}
      >
        <Input
          className='percent-input__input'
          containerClassName='percent-input__input-container'
          disabled={disabled}
          hasErrors={hasErrors}
          id={id}
          name={name}
          onBlur={onBlur}
          onChangeHandler={onInputChangeHandler}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          ref={ref}
          type='text'
          value={value + '%'}
        />
        <Slider
          containerClass='percent-input__slider-container'
          disabled={disabled}
          min={0}
          onChange={onSliderChangeHandler}
          value={Number(value)}
        />
        <ErrorMessages errors={errors} className='percent-input__errors' />
      </div>
    </InputContainer>
  )
}

const setCursor = (value: string, ref: React.RefObject<HTMLInputElement>) => {
  const input = ref.current
  if (input) {
    const length = value.length
    requestAnimationFrame(() => {
      if (input.selectionStart === input.selectionEnd && (input.selectionStart || 0) > length)
        input.setSelectionRange(length, length)
    })
  }
}

const cleanValue = (value: string | number, onBlur = false) => {
  const strippedValue = String(value).replace(/[^0-9]/g, '')
  let newValue
  if (strippedValue !== '') {
    newValue = Number(strippedValue)
    newValue = Math.min(Math.max(newValue, 0), 100)
  } else if (onBlur) {
    newValue = 0
  }
  return String(newValue ?? '')
}
