import React, { useContext } from 'react'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Can } from 'webapp/context/AbilityContext'
import { EditWorkspaceContainer } from 'containers/oss/Workspaces/EditWorkspaceContainer'
import { GamConnectionContainer } from 'containers/oss/Workspaces/GamConnectionContainer'
import { UserContext } from 'webapp/context/UserContext'
import { WorkspacePackagesContainer } from 'containers/oss/Workspaces/WorkspacePackagesContainer'
import { WorkspaceSettingsContainer } from 'containers/oss/Workspaces/WorkspaceSettingsContainer'

export const EditPage: React.FC = () => {
  const { isSuperuser } = useContext(UserContext)

  return (
    <>
      <EditWorkspaceContainer />

      <GamConnectionContainer />

      <Can I={AbilityAction.EDIT} a={AbilitySubject.ADD_ONS}>
        <WorkspacePackagesContainer />
      </Can>

      {isSuperuser && <WorkspaceSettingsContainer />}
    </>
  )
}
