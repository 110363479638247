import dayjs from 'dayjs'
import moment, { Moment } from 'moment'

export enum DATE_FORMAT {
  DATE_DOTS = 'DD.MM.YYYY',
  DATE_DOTS_SHORT_YEAR = 'DD.MM.YY',
  DATE_TIME = 'YYYY-MM-DD HH:mm',
  DATE = 'DD MMM YYYY',
  TIME_DATE = 'DD MMM YYYY, H:mm:ss A'
}

type DateFormat = Date | string | number

const format = (date: DateFormat) => (format: string) => dayjs(date).format(format)

export const formatDateDots = (date: DateFormat): string => format(date)(DATE_FORMAT.DATE_DOTS)
export const formatDateDotsShortYear = (date: DateFormat): string => format(date)(DATE_FORMAT.DATE_DOTS_SHORT_YEAR)
export const formatDateTime = (date: DateFormat): string => format(date)(DATE_FORMAT.DATE_TIME)
export const formatDate = (date: DateFormat): string => format(date)(DATE_FORMAT.DATE)
export const formatTimeDate = (date: DateFormat): string => format(date)(DATE_FORMAT.TIME_DATE)

/** 
  Cuts time without messing with timezones

  See: https://stackoverflow.com/questions/15130735/how-can-i-remove-time-from-date-with-moment-js#comment29443712_19699447
*/
export const cutTime = (date: Date): Date => {
  const momentDate: Moment = moment(date)
  const firstCut: string = momentDate.startOf('day').format('LL')
  const secondCut: Moment = moment(firstCut).startOf('day')

  return secondCut.toDate()
}
