import React, { useMemo } from 'react'
import { Field, useFormikContext } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Errors, ErrorUtils } from 'utilities/errorUtils'
import { FormSection } from '../FormSection'
import { MediaTypesRow } from './MediaTypesRow'
import { OutstreamVideo } from 'components/AdUnits/Form/Sections/MediaTypes/OutstreamVideo'
import { parseToDeviceForm } from 'components/quickwrap/Setup/GeneralSettings/WorkspaceDeviceForm/formSchema'
import { SizeMapping } from './SizeMapping'
import { SizeType } from './SizeType'
import { capitalize } from 'utilities/capitalize'
import { sizeTypeUtils } from 'utilities/sizeTypeUtils'

interface Props {
  errors: Errors
  setErrors: (errors: ErrorUtils) => void
  workspaceDevices: Array<WorkspaceDevice>
}

export const MediaTypes: React.FC<Props> = ({ errors, setErrors, workspaceDevices }) => {
  const { t } = useTranslation('inventory')
  const { values } = useFormikContext<AdUnitForm>()

  const generalSizeType = sizeTypeUtils.isGeneralSizeType(values.sizeType)

  const options = useMemo(() => {
    return workspaceDevices.map((device: WorkspaceDevice) => {
      const { playerSize, bannerSizes } = parseToDeviceForm(device)
      return {
        bannerSizes,
        bannerEnabled: !!bannerSizes?.length,
        playerSize,
        videoEnabled: !!playerSize?.length,
        value: device.id,
        label: `${capitalize(device.name || '')} (min ${device.minViewPort})`
      }
    }) as SelectOption[]
  }, [workspaceDevices])

  return (
    <FormSection className='media-types-section' title={t('adUnits.form.mediaTypes')}>
      <Grid item lg={6} xs={10} className='media-types-section__select'>
        <Field id='ad_unit-size-type' name='sizeType' component={SizeType} />
      </Grid>

      <Grid item lg={12} xs={12} className='pt-0'>
        {generalSizeType ? (
          <>
            <div className='device-list-item with-border'>
              <MediaTypesRow errors={errors} generalSizeType={true} />
            </div>
            <OutstreamVideo errors={errors} />
          </>
        ) : (
          <SizeMapping errors={errors} options={options} setErrors={setErrors} />
        )}
      </Grid>
    </FormSection>
  )
}
