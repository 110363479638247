import React from 'react'
import { useField, FieldConfig } from 'formik'

import { DynamicListInput } from 'components/Form/DynamicListInput'
import { ErrorMessages } from 'components/Form/ErrorMessages'

type FormikDynamicListInputContainerType = {
  errors: string[]
  field: FieldConfig
  filterPlaceholder: string
  title: string
}

export const FormikDynamicListInputContainer = ({
  errors,
  filterPlaceholder,
  title,
  ...other
}: FormikDynamicListInputContainerType): JSX.Element => {
  const [field, , helpers] = useField(other.field.name)

  const onUpdateHandler = (newItems: string[]) => {
    helpers.setValue(newItems)
  }

  return (
    <>
      <DynamicListInput
        filterPlaceholder={filterPlaceholder}
        items={field.value}
        onUpdateHandler={onUpdateHandler}
        title={title}
      />
      <ErrorMessages errors={errors} />
    </>
  )
}
