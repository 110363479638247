import { AdUnitData, UpsertOssAdUnitVars, upsertOssAdUnitVarsDefault } from './types'

import pick from 'lodash/pick'
import { useLocation } from 'react-router-dom'

export const prepareFormDomains = (
  formType: FormType,
  domains?: DomainBasic[],
  adUnit?: AdUnitData['ossAdUnit']
): DomainBasic[] => {
  if (formType === 'edit' && adUnit) {
    return [adUnit.domain]
  } else if (domains) {
    return domains
  } else return []
}

export const prepareInitValues = ({
  adUnit,
  formType,
  initActivePages,
  urlDomainId,
  isSuperuser
}: {
  adUnit?: AdUnitData['ossAdUnit']
  formType: FormType
  initActivePages: OssPage['id'][]
  urlDomainId: Domain['id'] | null
  isSuperuser: boolean
}): UpsertOssAdUnitVars => {
  if (formType === 'edit' && adUnit) {
    const {
      domain: { id: domainId },
      id: ossAdUnitId
    } = adUnit

    return {
      ...pick(adUnit, ['adUnitPath', 'elementId', 'outOfPage', 'outOfPageType']),
      adUnitName: adUnit.adUnitName ?? '',
      domainId,
      ossAdUnitId,
      ossPageIds: initActivePages,
      dedicatedForUnfilledRecovery: isSuperuser ? adUnit.dedicatedForUnfilledRecovery : undefined
    }
  }

  return { ...upsertOssAdUnitVarsDefault, ...(urlDomainId ? { domainId: urlDomainId } : {}) }
}

export const useUrlDomainId = (): string | null => {
  const { search } = useLocation()

  return new URLSearchParams(search).get('domain')
}
