import React, { useMemo, useState } from 'react'
import { Field, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer, FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'
import { getProductName } from 'utilities/products/utils'
import { Input, StatusForm } from 'components/Form'
import { Product } from 'webapp/constants/Product'

interface Props {
  errors: Errors
  formData: DataStudioLinkFormData
  isSubmitting: boolean
  onSubmit: (formData: DataStudioLinkFormData) => void
  workspaces: Workspace[]
}

export const DataStudioForm: React.FC<Props> = ({ errors, formData, isSubmitting, onSubmit, workspaces }) => {
  const { t } = useTranslation('dataStudio')
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(formData.workspaceId?.value)

  const workspaceOptions = useMemo(
    () => workspaces.map((workspace) => ({ value: workspace.id, label: workspace.name })),
    [workspaces]
  )

  const selectedWorkspace = workspaces.find((workspace) => workspace.id === selectedWorkspaceId)

  const emailsFieldValue = selectedWorkspace?.users.map((user) => user.email).join(', ') ?? ''

  const disableSelects = Boolean(formData.productConnectionId?.value)
  const productOptions = useMemo(() => {
    if (disableSelects) return [formData.productConnectionId]

    return (selectedWorkspace?.productConnections ?? [])
      .filter(({ product }) => product !== Product.Quickwrap)
      .filter(({ dataStudioUrl }) => !dataStudioUrl)
      .map(({ id, product }) => ({ value: id, label: getProductName(product) }))
  }, [disableSelects, selectedWorkspace])

  return (
    <div className='data-studio-form qw-form'>
      <Formik initialValues={formData} onSubmit={onSubmit}>
        {({ dirty, values, setFieldValue }) => (
          <StatusForm name='oss-data-studio' dirty={dirty}>
            <Grid container spacing={4} justifyContent='space-between'>
              <Grid item lg={4} xs={6}>
                <Field
                  component={FormikSelect}
                  disabled={disableSelects}
                  errors={errors.errorsFor('workspaceId')}
                  id='data-studio-workspace-id'
                  label={t('form.workspace.label')}
                  name='workspaceId'
                  onChangeHandler={(option: SelectOption) => {
                    if (option.value === values.workspaceId?.value) return

                    setFieldValue('productConnectionId', null)
                    setSelectedWorkspaceId(option?.value as string)
                  }}
                  options={workspaceOptions}
                  placeholder={t('form.workspace.placeholder')}
                />

                <Field
                  component={FormikSelect}
                  disabled={disableSelects}
                  errors={errors.errorsFor('productConnectionId')}
                  id='data-studio-product-connection-id'
                  key={selectedWorkspaceId}
                  label={t('form.productConnection.label')}
                  name='productConnectionId'
                  options={productOptions}
                  placeholder={t('form.productConnection.placeholder')}
                  tooltipContent={t('form.productConnection.tooltip')}
                />

                <Field
                  component={FormikInputContainer}
                  errors={errors.errorsFor('dataStudioUrl')}
                  id='data-studio-data-studio-url'
                  label={t('form.dataStudioUrl.label')}
                  name='dataStudioUrl'
                  tooltipContent={t('form.dataStudioUrl.tooltip')}
                />

                <Button
                  disabled={isSubmitting || !dirty || !values.dataStudioUrl || !values.productConnectionId?.value}
                  type={ButtonType.Primary}
                  theme={ButtonTheme.Blue}
                  buttonType='submit'
                >
                  {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                </Button>
              </Grid>
              <Grid item xs={6} className='mx-auto'>
                <Input
                  copyButton
                  disabled
                  id='data-studio-emails'
                  label={t('form.emails.label')}
                  name='emails'
                  type='textarea'
                  value={emailsFieldValue}
                />
              </Grid>
            </Grid>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
