import React, { ReactElement } from 'react'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { Modal } from 'components/Modals'

interface Props {
  confirmButtonText: string
  confirmationInputName?: string
  confirmationTarget: string
  content: ReactElement
  errors: ErrorUtils
  isSubmitting?: boolean
  onCancelHandler?: () => void
  onConfirmHandler: (values: { [key: string]: string }) => void
  setVisible: (visible: boolean) => void
  visible: boolean
}

export const SafeConfirmationModal: React.FC<Props> = ({
  confirmButtonText,
  confirmationInputName = 'name',
  confirmationTarget,
  content,
  errors,
  isSubmitting,
  onConfirmHandler,
  setVisible,
  visible
}) => {
  const { t } = useTranslation()

  const initialValues = { [confirmationInputName]: '' }

  return (
    <Modal
      title={t('confirmation.areYouSure')}
      visible={visible}
      setVisible={setVisible}
      shadow={true}
      backdrop={true}
      closeButton={true}
    >
      <Formik initialValues={initialValues} onSubmit={onConfirmHandler}>
        {({ values }) => (
          <Form className='safe-confirmation-modal'>
            <p>{content}</p>

            <p>
              {t('confirmation.pleaseType')}
              <strong> {confirmationTarget} </strong>
              {t('confirmation.toConfirm')}
            </p>

            <Field
              component={FormikInput}
              errors={errors.errorsFor(confirmationInputName)}
              name={confirmationInputName}
              type='text'
            />

            <Button
              className='safe-confirmation-modal__submit'
              disabled={isSubmitting || values[confirmationInputName].trim() !== confirmationTarget.trim()}
              theme={ButtonTheme.RedReverse}
              type={ButtonType.Primary}
            >
              {confirmButtonText}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
