import React from 'react'
import { useTranslation } from 'react-i18next'

import { BidderFormContainer } from 'components/BidderForm/BidderFormContainer'
import { Section } from 'components/Form'
import { ParamFormLogo } from './ParamFormLogo'
import { Tooltip } from 'components/Form'

export interface Props {
  item: Bidder | PrebidModule
}

export const DisabledParam: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation('inventory')

  return (
    <>
      <BidderFormContainer>
        <Section className='param-form param-form--disabled'>
          <ParamFormLogo item={item} isBidder={true} isSaved={true} />
          <Tooltip title={t('adUnits.form.inputs.bidders.disabled')} />
        </Section>
      </BidderFormContainer>
    </>
  )
}
