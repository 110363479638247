import React, { useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { DomainVersionSelect } from 'components/shared/Inventory/Domains/DomainVersionSelect'
import { Select } from 'components/Form'
import { VersionedDomainProvider } from 'webapp/context/VersionedDomainContext'

import DomainsSelectQuery from 'gql/queries/domains/DomainSelect.gql'

interface Props {
  onChange: (versionId?: string) => void
}

export const DomainVersionInput: React.FC<Props> = ({ onChange: onVersionChange }) => {
  const { t } = useTranslation('inventory')
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([])
  const [domainId, setDomainId] = useState<string>()

  const [getDomains] = useLazyQuery(DomainsSelectQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ domains }) => {
      setSelectOptions(domains.nodes.map((item: Domain) => ({ value: item.id, label: item.name })))
    }
  })

  useEffect(() => {
    getDomains()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onDomainChange = (domainId?: string) => {
    setDomainId(domainId)
    onVersionChange(undefined)
  }

  return (
    <div className='domain-version-input'>
      <Grid item xs={6} md={3}>
        <Select
          label={t('inventory:adUnits.list.tableHeader.domain')}
          options={selectOptions}
          name='domain'
          onChangeHandler={(option) => {
            const newDomainId = (option as SelectOption).value.toString()
            if (newDomainId !== domainId) {
              onDomainChange(newDomainId)
            }
          }}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        {domainId && (
          <VersionedDomainProvider domainId={domainId}>
            <DomainVersionSelect
              onChange={onVersionChange}
              label={t('inventory:adUnits.list.tableHeader.domainVersion')}
            />
          </VersionedDomainProvider>
        )}
      </Grid>
    </div>
  )
}
