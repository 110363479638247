import React from 'react'

import { Box, BoxHeader } from 'components/Box'
import { ProductManagementTable } from 'components/oss/Products/ProductManagementTable'
import { useTranslation } from 'react-i18next'

export const ProductManagementContainer = () => {
  const { t } = useTranslation('products')

  return (
    <Box className='product-management' list={true}>
      <BoxHeader title={t('management.title')} />

      <ProductManagementTable />
    </Box>
  )
}
