import React from 'react'
import { useTranslation } from 'react-i18next'

import { ProductStatus } from 'webapp/constants/ProductStatus'

import { ItemProps } from './types'

const SECONDS_IN_DAY = 24 * 60 * 60

export const ProductStatusLabel: React.FC<ItemProps> = ({ productConnection }) => {
  const { t } = useTranslation('products')

  switch (productConnection.status) {
    case ProductStatus.Trial:
      return (
        <div className='ProductList__product-status ProductList__product-status--trial'>
          {productConnection.trialEndsIn
            ? t('list.items.trial', { count: Math.ceil(productConnection.trialEndsIn / SECONDS_IN_DAY) })
            : t('list.items.trialEnded')}
        </div>
      )
    case ProductStatus.InSetup:
      return <div className='ProductList__product-status'>{t('list.items.inSetup')}</div>
    case ProductStatus.UnlimitedPlanInProgress:
      return <div className='ProductList__product-status'>{t('list.items.unlimitedAccessInProgress')}</div>
    default:
      return null
  }
}
