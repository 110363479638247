import React, { useContext } from 'react'

import {
  CustomCodeContainer,
  GeneralSettingsContainer,
  WorkspaceDeviceContainer
} from 'containers/quickwrap/Setup/GeneralSettings'
import { UserContext } from 'webapp/context/UserContext'
import { WorkspacePackageEnum } from 'utilities/workspacePackageUtils'

export const GeneralSettingsPage: React.FC = () => {
  const { isPackageActive } = useContext(UserContext)

  return (
    <>
      <GeneralSettingsContainer />
      <WorkspaceDeviceContainer expandable />
      {isPackageActive(WorkspacePackageEnum.CUSTOM_CODE) && <CustomCodeContainer />}
    </>
  )
}
