import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useField, FieldConfig } from 'formik'

import { InputContainer } from 'components/Form/InputContainer'
import { RadioButton } from 'components/Form'
import { SizeTypeEnum } from 'utilities/sizeTypeUtils'

interface Props {
  id: string
  disabled: boolean
  errors?: Array<string>
  label: string
  tooltipContent: string
  field: FieldConfig
}

export const SizeType: React.FC<Props> = ({ id, disabled, errors, label, tooltipContent, ...other }) => {
  const { t } = useTranslation('inventory')
  const [field, , helpers] = useField(other.field.name)

  const onSizeTypeChange = useCallback((type) => helpers.setValue(type), [helpers])

  return (
    <InputContainer
      inputId={id}
      label={label}
      className={'input__container'}
      disabled={disabled}
      tooltipContent={tooltipContent}
      errors={errors}
      labelStyle='bold'
    >
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6}>
          <RadioButton
            name='sizeTypeGeneral'
            id='size-type-general'
            checked={field.value.includes(SizeTypeEnum.GENERAL)}
            disabled={disabled}
            label={t('adUnits.form.sizeType.general.label')}
            tooltipContent={t('adUnits.form.sizeType.general.tooltip')}
            value={SizeTypeEnum.GENERAL}
            onChange={onSizeTypeChange}
          />
        </Grid>

        <Grid item lg={6} xs={6}>
          <RadioButton
            name='sizeTypeMapping'
            id='size-type-mapping'
            checked={field.value.includes(SizeTypeEnum.SELECTED)}
            disabled={disabled}
            label={t('adUnits.form.sizeType.mapping.label')}
            tooltipContent={t('adUnits.form.sizeType.mapping.tooltip')}
            value={SizeTypeEnum.SELECTED}
            onChange={onSizeTypeChange}
          />
        </Grid>
      </Grid>
    </InputContainer>
  )
}
