import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { DropdownMenu } from 'components/DropdownMenu'
import { ConfirmationModal } from 'components/Modals'
import { NotificationType } from 'components/ToastNotifier'
import { Bin } from 'icons/Bin'
import { Edit } from 'icons/Edit'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error } from 'utilities/errorUtils'
import { routesBuilder } from 'utilities/routesBuilder'
import { Product } from 'webapp/constants/Product'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import {
  ActionsCellProps,
  DestroyAdUnitData,
  DestroyAdUnitVars,
  DestroyOssAdUnitData,
  DestroyOssAdUnitVars
} from './types'

import { DESTROY_AD_UNIT } from 'gql/mutations/adUnits/DestroyAdUnit'
import { DESTROY_OSS_AD_UNIT } from 'gql/mutations/adUnits/DestroyOssAdUnit'
import { AdUnitRowData } from './AdUnitRowData'

export const ActionsCell = ({ product = Product.Quickwrap, row }: ActionsCellProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const {
    id: adUnitId,
    domainVersionId,
    ossAdUnit: { adUnitName }
  } = row.original as unknown as AdUnitRowData

  const editPath = routesBuilder[product].inventory.adUnit.edit(adUnitId, domainVersionId)

  const onEdit = (): void => history.push(editPath)
  const onDelete = (): void => setVisibleDeleteModal(true)

  const [destroyAdUnit] = useMutation<DestroyAdUnitData, { input: DestroyAdUnitVars }>(DESTROY_AD_UNIT, {
    onCompleted: ({ destroyAdUnit: { errors } }) => {
      destroyResolver(errors)
    }
  })

  const [destroyOssAdUnit] = useMutation<DestroyOssAdUnitData, { input: DestroyOssAdUnitVars }>(DESTROY_OSS_AD_UNIT, {
    onCompleted: ({ destroyOssAdUnit: { errors } }) => {
      destroyResolver(errors)
    }
  })

  const destroyResolver = (errors: Error[]) => {
    setVisibleDeleteModal(false)
    if (errors.length) {
      createNotifier(t('adUnits.list.notification.delete.error', { adUnitName }), NotificationType.ERROR)
    } else {
      createNotifier(t('adUnits.list.notification.delete.success', { adUnitName }), NotificationType.SUCCESS)
      history.push(history.location)
    }
  }

  const onConfirmDelete = (): void => {
    if (product === Product.Quickwrap) {
      destroyAdUnit(nestGqlInput({ adUnitId }))
    } else {
      destroyOssAdUnit(nestGqlInput({ ossAdUnitId: adUnitId }))
    }
  }

  return (
    <div onClick={(e): void => e.stopPropagation()}>
      <DropdownMenu setVisible={setDropdownVisible} visible={dropdownVisible}>
        <MenuItem onClick={onEdit}>
          {t('common:buttons.edit')} <Edit />
        </MenuItem>

        <MenuItem onClick={onDelete}>
          {t('common:buttons.delete')} <Bin />
        </MenuItem>
      </DropdownMenu>

      <ConfirmationModal
        cancelButtonText={t('common:buttons.cancel')}
        confirmButtonText={t('common:buttons.delete')}
        content={t('adUnits.list.modal.deleteConfirm.content', { adUnitName })}
        onConfirmHandler={onConfirmDelete}
        setVisible={setVisibleDeleteModal}
        visible={visibleDeleteModal}
      />
    </div>
  )
}
