import React from 'react'
import { Column, CellProps } from 'react-table'
import i18n from 'webapp/i18n'

import { DemandStatusRow } from 'components/sharedInterfaces/DemandStatus'
import StatusCell from './StatusCell'

const columnFor = (header: string, accessor: string) => {
  return {
    Header: () => header,
    accessor: accessor,
    Cell: ({ row }: CellProps<DemandStatusRow>) => <StatusCell bidder={header} row={row} />,
    disableSortBy: true
  }
}

export const columnsConfig = (settings: BidderSetting[]): Array<Column<DemandStatusRow>> => {
  return [
    {
      Header: () => i18n.t('setup:yieldbirdDemand.status.headers.domain'),
      accessor: 'domain.name',
      disableSortBy: true
    }
  ].concat(settings.map((elem: BidderSetting, index: number) => columnFor(elem.bidder.name, `demandStatus.${index}`)))
}
