import React, { useContext } from 'react'

import { Collapse, Grid } from '@material-ui/core'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { Section } from 'components/Form'
import {
  FormikCheckboxContainer,
  FormikInputContainer,
  FormikSelectContainer,
  FormikToggleContainer
} from 'containers/FormikContainers'
import { UserContext } from 'context/UserContext'
import PagesAdUnitsConnectingTable from 'webapp/components/oss/Inventory/PagesAdUnitsConnectingTable'

import { AdUnitFormProps } from './types'
import { OutOfPageTypeOptions } from 'webapp/constants/OutOfPageTypeOptions'

const AdUnitForm = ({
  adUnitId,
  domainId,
  domains,
  errors,
  formType,
  onInitActivePages,
  onSetFieldValue,
  values
}: AdUnitFormProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { isSuperuser } = useContext(UserContext)

  const domainsOptions = domains.map((d: DomainBasic): SelectOption<Domain['id']> => ({ value: d.id, label: d.name }))

  return (
    <Box>
      <BoxHeader title={t(`adUnits.${formType}.title`)} />

      <Section fullWidth className='ad_unit-form'>
        <Grid container spacing={4}>
          <Grid item lg={4} xs={4}>
            <Field
              component={FormikSelectContainer}
              defaultValue={formType === 'new' && domainsOptions.find((o) => o.value === domainId)}
              disabled={formType === 'edit'}
              errors={errors.errorsFor('domainId')}
              id='domainId'
              label={t('adUnits.form.inputs.domainId.label')}
              name='domainId'
              onChangeHandler={(domain: SelectOption<string>): void => onSetFieldValue('domainId', domain.value)}
              options={domainsOptions}
              selectedItem={formType === 'edit' && domainsOptions[0]}
            />

            <Field
              component={FormikInputContainer}
              errors={errors.errorsFor('adUnitName')}
              id='adUnitName'
              label={t('adUnits.form.inputs.adUnitName.label')}
              name='adUnitName'
              placeholder={t('adUnits.form.inputs.adUnitName.placeholder')}
              type='text'
            />

            <Field
              component={FormikInputContainer}
              disabled={formType === 'edit'}
              errors={errors.errorsFor('adUnitPath')}
              id='adUnitPath'
              label={t('adUnits.form.inputs.adUnitPath.label')}
              name='adUnitPath'
              placeholder={t('adUnits.form.inputs.adUnitPath.placeholder')}
              type='text'
            />

            <Field
              component={FormikInputContainer}
              errors={errors.errorsFor('elementId')}
              id='elementId'
              label={t('adUnits.form.inputs.elementId.label')}
              name='elementId'
              placeholder={t('adUnits.form.inputs.elementId.placeholder')}
              type='text'
            />

            {isSuperuser && (
              <Field
                component={FormikCheckboxContainer}
                errors={errors.errorsFor('dedicatedForUnfilledRecovery')}
                id='dedicatedForUnfilledRecovery'
                label={t('adUnits.form.inputs.dedicatedForUnfilledRecovery.label')}
                name='dedicatedForUnfilledRecovery'
              />
            )}

            <Section>
              <Field
                id='outOfPage'
                name='outOfPage'
                label={t('adUnits.form.inputs.outOfPage.label')}
                component={FormikToggleContainer}
                labelStyle='bold'
              />

              <Collapse in={values.outOfPage}>
                <Field
                  component={FormikSelectContainer}
                  defaultValue={OutOfPageTypeOptions.find((o) => o.value === values.outOfPageType)}
                  errors={errors.errorsFor('outOfPageType')}
                  id='outOfPageType'
                  label={t('adUnits.form.inputs.outOfPageType.label')}
                  name='outOfPageType'
                  options={OutOfPageTypeOptions}
                  onChangeHandler={(option: SelectOption<string>): void =>
                    onSetFieldValue('outOfPageType', option.value)
                  }
                  selectedItem={OutOfPageTypeOptions.find((o) => o.value === values.outOfPageType)}
                />
              </Collapse>
            </Section>
          </Grid>

          {domainId && (
            <Grid item xs={6}>
              <PagesAdUnitsConnectingTable
                domainId={domainId}
                onChange={(ids) => void onSetFieldValue('ossPageIds', ids)}
                onInitActives={onInitActivePages}
                ossAdUnitId={adUnitId ?? null}
                tableFor='OSS_AD_UNIT'
              />
            </Grid>
          )}
        </Grid>
      </Section>
    </Box>
  )
}

export default AdUnitForm
