import React, { useState } from 'react'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'

import { CheckboxTreeListContainer } from './CheckboxTreeListContainer'
import { CheckboxStates } from './CheckboxStates'
import { TreeHelper } from './TreeHelper'

type CheckboxTreeListElementType = {
  children?: JSX.Element[]
  filter: string
  item: CheckboxTreeListDataItem
  treeHelper: TreeHelper
  updateHandler: (newItems: string[]) => void
}

export const CheckboxTreeListElement = ({
  children,
  filter,
  item,
  treeHelper,
  updateHandler
}: CheckboxTreeListElementType): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const renderCheckbox = (): JSX.Element => {
    const state = treeHelper.findByKey(item.key).checkboxState()

    if (state === CheckboxStates.CheckedState) return <CheckBoxIcon />
    else if (state === CheckboxStates.MiddleState) return <IndeterminateCheckBoxIcon />
    else return <CheckBoxOutlineBlankIcon />
  }

  const renderExpandIcon = (isExpanded: boolean): JSX.Element => (isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)

  const checkHandler = () => {
    treeHelper.findByKey(item.key).check(filter)

    updateHandler(treeHelper.values())
  }

  return (
    <>
      <li key={item.key} className='CheckboxTreeList__element' role='treeitem'>
        <div className='CheckboxTreeList__checkbox' onClick={checkHandler}>
          {renderCheckbox()}
        </div>

        {item.label}

        {item?.children && (
          <div className='CheckboxTreeList__counter'>
            ({treeHelper.findByKey(item.key).checkedChildrenNumber()}/{item?.children.length})
          </div>
        )}

        {children && (
          <div
            className='CheckboxTreeList__expand'
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            {renderExpandIcon(isExpanded)}
          </div>
        )}
      </li>

      {children && isExpanded ? <CheckboxTreeListContainer>{children}</CheckboxTreeListContainer> : null}
    </>
  )
}
