import { SchainNetworkConnectionTypes } from 'webapp/constants/SchainNetworkConnectionTypes'

type NetworkAttribute = {
  id: string
  networkId: string
  position?: number
  _destroy?: boolean
}

export class BidderSettingSerializer {
  bidderSettingFormData: OwnBidderFormData
  bidderSetting?: BidderSetting

  constructor(bidderSettingFormData: OwnBidderFormData, bidderSetting?: BidderSetting) {
    this.bidderSetting = bidderSetting
    this.bidderSettingFormData = bidderSettingFormData
  }

  params = (): OwnBidderInput => ({
    active: this.bidderSettingFormData.active,
    bidCpmAdjustment: parseFloat(String(this.bidderSettingFormData.bidCpmAdjustment)),
    bidderCode: this.bidderSettingFormData.bidderCode,
    bidderId: this.bidderSettingFormData.bidderId.value,
    bidderSettingId: this.bidderSettingFormData.id,
    bidderSettingNetworksAttributes: this.bidderSettingNetworksAttributes(),
    bidderSid: this.bidderSettingFormData.bidderSid,
    consentMode: this.bidderSettingFormData.consentMode.value,
    priority: this.bidderSettingFormData.priority.value
  })

  bidderSettingNetworksAttributes = (): NetworkAttribute[] =>
    this.bidderSettingFormData.schainNetworkConnection === SchainNetworkConnectionTypes.Direct
      ? this.directBidderSettingNetworksAttributesParams()
      : this.indirectBidderSettingNetworksAttributesParams()

  directBidderSettingNetworksAttributesParams = (): NetworkAttribute[] =>
    this.bidderSetting?.bidderSettingNetworks?.map(this.deletedBidderSettingNetworks) ?? []

  indirectBidderSettingNetworksAttributesParams = (): NetworkAttribute[] =>
    (this.bidderSettingFormData?.bidderSettingNetworks ?? [])
      .map(this.updatedBidderSettingNetworks)
      .concat(this.networksToBeDestroyed()?.map(this.deletedBidderSettingNetworks) ?? [])

  updatedBidderSettingNetworks = (
    networkFormData: { label: string; value: string },
    index: number
  ): NetworkAttribute => {
    const bidderSettingNetwork = this.bidderSetting?.bidderSettingNetworks?.find(
      (bidderSettingNetwork: BidderSettingNetwork) => bidderSettingNetwork.network.id === networkFormData.value
    )

    return {
      id: bidderSettingNetwork?.id as string,
      networkId: networkFormData.value,
      position: index + 1
    }
  }

  networksToBeDestroyed = (): BidderSettingNetwork[] | undefined => {
    const selectedNetworks = (this.bidderSettingFormData?.bidderSettingNetworks ?? []).map(
      (bidderSettingNetworkFormData) => bidderSettingNetworkFormData.value
    )

    return this.bidderSetting?.bidderSettingNetworks?.filter(
      (bidderSettingNetwork: BidderSettingNetwork) =>
        !selectedNetworks.includes(bidderSettingNetwork.network.id as string)
    )
  }

  deletedBidderSettingNetworks = (bidderSettingNetwork: BidderSettingNetwork, index: number): NetworkAttribute => ({
    id: bidderSettingNetwork.id as string,
    networkId: bidderSettingNetwork.network.id as string,
    position: 1000 - index,
    _destroy: true
  })
}
