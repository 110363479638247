import React, { useContext, useState } from 'react'
import { Collapse, Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader, BoxBody } from 'components/Box'
import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikToggleContainer, FormikCodeEditorContainer } from 'containers/FormikContainers'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { StatusForm } from 'components/Form'
import { PriceFloorFactory } from './formSchema'
import { PriceFloorSerializer } from 'serializers/PriceFloorSerializer'

import UpsertPriceFloor from 'gql/mutations/priceFloor/UpsertPriceFloor.gql'

type DomainPriceFloorContainerType = {
  domain: Domain
}

export const DomainPriceFloorContainer = ({ domain }: DomainPriceFloorContainerType): JSX.Element => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('inventory')
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [priceFloor, setPriceFloor] = useState(PriceFloorFactory(domain.priceFloor, domain))

  const [upsertPriceFloor] = useMutation(UpsertPriceFloor, {
    onCompleted: ({ upsertPriceFloor: { priceFloor, errors } }) => {
      setIsSubmitting(false)
      const processedErrors = new ErrorUtils(errors)

      priceFloor ? onSuccess(priceFloor, processedErrors) : onFailure(processedErrors)
    }
  })

  const onFailure = (errors: ErrorUtils): void => {
    setErrors(errors)

    createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
  }

  const onSuccess = (priceFloor: PriceFloor, errors: ErrorUtils): void => {
    setPriceFloor(priceFloor)
    setErrors(errors)

    createNotifier(t('domains.priceFloor.successMessage'), NotificationType.SUCCESS)
  }

  const handleOnSubmit = (form: PriceFloor) => {
    setIsSubmitting(true)
    const input = new PriceFloorSerializer(form, domain).upsertParams()

    upsertPriceFloor({
      variables: { input }
    })
  }

  return (
    <Box className='domain-price-floor'>
      <Formik initialValues={PriceFloorFactory(priceFloor, domain)} onSubmit={handleOnSubmit} enableReinitialize={true}>
        {({ dirty, values, submitForm }) => (
          <StatusForm name='qw-price-floor' dirty={dirty}>
            <BoxHeader title={t('domains.priceFloor.title')}>
              <Field
                id='price-floor-enabled'
                name='enabled'
                component={FormikToggleContainer}
                errors={errors.errorsFor('enabled')}
                onChange={() => values.enabled && submitForm()}
              />
            </BoxHeader>
            <Collapse in={values.enabled}>
              <BoxBody>
                <div className='box__body'>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <div className='input-code-editor'>
                        <Field
                          id='price-floor-code'
                          name='code'
                          type='text'
                          label={t('domains.priceFloor.code.label')}
                          tooltipContent={t('domains.priceFloor.code.tooltip')}
                          component={FormikCodeEditorContainer}
                          errors={errors.errorsFor('code')}
                          labelStyle='bold'
                          mode='javascript'
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Button
                    disabled={isSubmitting || !dirty}
                    className='mt-4'
                    type={ButtonType.Primary}
                    theme={ButtonTheme.Orange}
                    buttonType='submit'
                  >
                    {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                  </Button>
                </div>
              </BoxBody>
            </Collapse>
          </StatusForm>
        )}
      </Formik>
    </Box>
  )
}
