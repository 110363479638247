import React, { useContext, useState } from 'react'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Edit } from 'icons/Edit'
import { ErrorUtils } from 'utilities/errorUtils'
import { LinkButton } from 'components/LinkButton'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { routesBuilder } from 'utilities/routesBuilder'
import { SafeConfirmationModal } from 'components/Modals'

import DeleteWorkspace from 'gql/mutations/workspaces/DeleteWorkspace.gql'

interface Props {
  row: Row<Workspace>
}

export const ActionsCell: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation('workspaces')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const workspaceName = row.original.name
  const workspaceId = row.original.id

  const [destroyWorkspace, { loading: isSubmitting }] = useMutation(DeleteWorkspace, {
    onCompleted: ({ destroyWorkspace: { workspace, errors } }) => {
      setErrors(new ErrorUtils(errors))
      if (workspace) {
        setIsDeleteModalVisible(false)
        createNotifier(t('list.modal.deleteConfirm.notification.success', { workspaceName }), NotificationType.SUCCESS)
        history.push(history.location)
      }
    },
    onError: () => {
      createNotifier(t('list.modal.deleteConfirm.notification.error', { workspaceName }), NotificationType.ERROR)
    }
  })

  const onDeleteHandler = () => setIsDeleteModalVisible(true)
  const onConfirmHandler = ({ name }: { [key: string]: string }) => {
    destroyWorkspace(
      nestGqlInput({
        workspaceId,
        name
      })
    )
  }

  return (
    <div className='cell--actions ' onClick={(e) => e.stopPropagation()}>
      <LinkButton
        to={routesBuilder.oss.workspaces.edit(row.original.id)}
        type={ButtonType.SingleAction}
        theme={ButtonTheme.Blue}
        className='cell--actions__button cell--actions__button--icon'
        tooltipContent={t('common:buttons.edit')}
      >
        <Edit />
      </LinkButton>

      <Button
        type={ButtonType.SingleAction}
        theme={ButtonTheme.Blue}
        className='cell--actions__button cell--actions__button--icon'
        onClickHandler={onDeleteHandler}
        tooltipContent={t('common:buttons.delete')}
      >
        <Bin />
      </Button>

      <SafeConfirmationModal
        confirmButtonText={t('list.modal.deleteConfirm.confirm')}
        confirmationTarget={row.original.name}
        content={t('list.modal.deleteConfirm.content')}
        errors={errors}
        isSubmitting={isSubmitting}
        onConfirmHandler={onConfirmHandler}
        setVisible={setIsDeleteModalVisible}
        visible={isDeleteModalVisible}
      />
    </div>
  )
}
