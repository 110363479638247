import React from 'react'

import { useTranslation } from 'react-i18next'

import { Input } from 'components/Form'
import { Modal } from 'components/Modals'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
  generalSettings: GeneralSetting
}

export const GeneralSettingsModal: React.FC<Props> = ({ visible, setVisible, generalSettings }) => {
  const { t } = useTranslation()
  const { failsafeTimeout, prebidTimeout } = generalSettings

  return (
    <Modal
      visible={visible}
      shadow
      setVisible={setVisible}
      containerClass='general-settings__modal-position'
      closeButton
    >
      <div className='general-settings__header'>{t('settings.generalSettings')}</div>
      <small className='general-settings__small'>{t('settings.preview')}</small>

      <Input
        id='prebidTimeout'
        name='prebidTimeout'
        type='number'
        value={prebidTimeout}
        label={t('settings.prebidTimeout')}
        disabled={true}
        className='general-settings__input-delimeter'
      />

      <Input
        id='failsafeTimeout'
        name='failsafeTimeout'
        type='number'
        value={failsafeTimeout}
        label={t('settings.failsafeTimeout')}
        disabled={true}
        className='general-settings__input-delimeter'
      />
    </Modal>
  )
}
