import React, { useContext, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'

import ResetPasswordMutation from 'gql/mutations/auth/ResetPassword.gql'
import { ResetPasswordFormFactory } from './formSchema'

interface Props {
  token: string
  onSuccess: () => void
}

export const ResetPasswordForm: React.FC<Props> = ({ token, onSuccess }) => {
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('auth')

  const [ResetPassword, { loading: isSubmitting }] = useMutation(ResetPasswordMutation, {
    onCompleted: ({ resetPassword: { errors } }) => {
      if (errors.length) {
        setErrors(new ErrorUtils(errors))
      } else {
        onSuccess()
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = graphQLErrors[0]?.message || t('commons:error.common')
      createNotifier(message, NotificationType.ERROR)
    }
  })

  const onSubmitHandler = (values: ResetPasswordData) => {
    ResetPassword({ variables: { input: values } })
  }

  return (
    <div className='reset-password-form'>
      <Formik initialValues={ResetPasswordFormFactory(token)} onSubmit={onSubmitHandler}>
        <Form>
          <Field
            id='password'
            name='password'
            type='password'
            placeholder={t('resetPassword.form.password.placeholder')}
            label={t('resetPassword.form.password.label')}
            component={FormikInput}
            errors={errors.errorsFor('password')}
          />
          <Field
            id='password-confirmation'
            name='passwordConfirmation'
            type='password'
            placeholder={t('resetPassword.form.passwordConfirmation.placeholder')}
            label={t('resetPassword.form.passwordConfirmation.label')}
            component={FormikInput}
            errors={errors.errorsFor('passwordConfirmation')}
          />

          <Button disabled={isSubmitting} type={ButtonType.Primary} theme={ButtonTheme.Blue} buttonType='submit'>
            {isSubmitting ? t('common:buttons.reset') : t('common:buttons.reset')}
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
