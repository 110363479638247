import React from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
  absolute: boolean
  href: string
  isActive: boolean
  isThirdLevelSubmenuMember?: boolean
}

export const Link: React.FC<Props> = ({ absolute, href, isActive, isThirdLevelSubmenuMember, children }) => {
  const thirdLevelSubmenuMemberClassName = isThirdLevelSubmenuMember ? 'link__content_third-level-sub-menu-member' : ''

  const activeClassName = isActive ? 'link__active' : ''

  return absolute ? (
    <a href={href} target='blank' className='link__content'>
      {children}
    </a>
  ) : (
    <NavLink to={href} className={`link__content ${thirdLevelSubmenuMemberClassName} ${activeClassName}`}>
      {children}
    </NavLink>
  )
}
