import React, { useContext, useEffect, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import PageForm from 'components/oss/Inventory/Pages/PageForm'
import { NotificationType } from 'components/ToastNotifier'
import { Product } from 'constants/Product'
import { useParams } from 'react-router-dom'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { LoadingContainer } from 'webapp/components/LoadingContainer'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import useProductHistory from 'webapp/hooks/useProductHistory'
import { routesBuilder } from 'webapp/utils/routesBuilder'
import { prepareFormDomains, prepareInitValues } from './service'
import {
  DomainsData,
  DomainWithOssAdUnits,
  PageAddEditProps,
  PageData,
  PageVars,
  UpsertOssPageData,
  UpsertOssPageVars
} from './types'

import { OSS_PAGE_DOMAIN } from 'gql/queries/ossPages/OssPageDomain'
import { UPSERT_OSS_PAGE } from 'webapp/gql/mutations/pages/UpsertOssPage'
import { DOMAINS_WITH_OSS_AD_UNITS } from 'webapp/gql/queries/domains/DomainWithOssAdUnits'
import { NO_CACHE } from 'webapp/utils/apolloClient'

const PageFormContainer = ({ formType }: PageAddEditProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { history, prevProduct } = useProductHistory()
  const { pageId: urlPageId } = useParams<{ pageId: Page['id'] }>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [domains, setDomains] = useState<DomainWithOssAdUnits[]>()
  const [page, setPage] = useState<PageData['ossPage']>()
  const [initActiveAdUnits, setInitActiveAdUnits] = useState<OssAdUnit['id'][]>([])
  const [errors, setErrors] = useState(new ErrorUtils([]))

  useEffect(() => {
    if (formType === 'new') {
      fetchDomains()
    } else if (formType === 'edit' && urlPageId) {
      fetchPage({ variables: { pageId: urlPageId } })
    }
  }, [formType, urlPageId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [fetchDomains] = useLazyQuery<DomainsData>(DOMAINS_WITH_OSS_AD_UNITS, {
    ...NO_CACHE,
    onCompleted: (data: DomainsData): void => setDomains(data.domains.nodes)
  })

  const [fetchPage] = useLazyQuery<PageData, PageVars>(OSS_PAGE_DOMAIN, {
    ...NO_CACHE,
    onCompleted: (data: PageData): void => setPage(data.ossPage)
  })

  const [upsertOssPage, { loading: loadingUpsertOssPage }] = useMutation<
    UpsertOssPageData,
    { input: UpsertOssPageVars }
  >(UPSERT_OSS_PAGE, {
    onCompleted: ({ upsertOssPage: { ossPage, errors } }) => {
      setErrors(new ErrorUtils(errors))

      if (errors.length) {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      } else if (ossPage) {
        const { pageName, activePageId } = ossPage

        createNotifier(t(`pages.${formType}.successMessage`, { name: pageName }), NotificationType.SUCCESS)
        formStatus.clear()
        redirectOnSave(activePageId)
      }
    }
  })

  const redirectOnSave = (pageId: Page['id']): void =>
    history.push(
      prevProduct === Product.Quickwrap
        ? routesBuilder.quickwrap.inventory.pages.edit(pageId)
        : routesBuilder.oss.inventory.pages.root
    )

  return domains || page ? (
    <Formik
      enableReinitialize={true}
      initialValues={prepareInitValues({
        formType,
        initActiveAdUnits,
        page
      })}
      onSubmit={(values: UpsertOssPageVars) => void upsertOssPage(nestGqlInput(values))}
    >
      {({ dirty, setFieldValue, values }) => (
        <StatusForm name='oss-page' dirty={dirty}>
          <PageForm
            domainId={page?.domain.id || values.domainId}
            domains={prepareFormDomains(formType, domains, page)}
            errors={errors}
            formType={formType}
            onInitActiveAdUnits={setInitActiveAdUnits}
            onSetFieldValue={setFieldValue}
            pageId={page?.id ?? null}
          />

          <Button
            buttonType='submit'
            disabled={!dirty || loadingUpsertOssPage}
            theme={ButtonTheme.Blue}
            type={ButtonType.Primary}
          >
            {loadingUpsertOssPage ? t('common:buttons.saving') : t('common:buttons.save')}
          </Button>
        </StatusForm>
      )}
    </Formik>
  ) : (
    <LoadingContainer loading fullHeight />
  )
}

export const PageAdd = (): JSX.Element => <PageFormContainer formType={'new'} />
export const PageEdit = (): JSX.Element => <PageFormContainer formType={'edit'} />
