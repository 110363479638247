export class BaseSerializer<T> {
  data: T

  constructor(data: T) {
    this.data = data
  }

  public getParams(): unknown {
    const body: Record<keyof T, unknown> = {} as Record<keyof T, unknown>

    this.selectParams().forEach((paramName) => {
      body[paramName] = BaseSerializer.getSelectValue(this.data[paramName] as unknown as SelectOption)
    })

    this.rawParams().forEach((paramName) => {
      body[paramName] = this.data[paramName]
    })

    return body
  }

  protected rawParams(): Array<keyof T> {
    return []
  }

  protected selectParams(): Array<keyof T> {
    return []
  }

  private static getSelectValue(select: SelectOption | string): SelectOption['value'] {
    if (typeof select === 'object') {
      return typeof select.value === 'string' ? select.value.toLowerCase() : select.value
    } else {
      return select
    }
  }
}
