import { gql } from '@apollo/client'
import { OSS_AD_UNIT_FRAGMENT } from 'gql/fragments/adUnits/OssAdUnitFragment'
import { TABLE_INPUTS_N_RESOLVERS } from 'gql/fragments/common'

export const OSS_AD_UNITS = gql`
  ${OSS_AD_UNIT_FRAGMENT}

  query ossAdUnits($domainId: ID, ${TABLE_INPUTS_N_RESOLVERS[0]}) {
    ossAdUnits(domainId: $domainId, ${TABLE_INPUTS_N_RESOLVERS[1]}) {
        totalCount
        nodes {
            ...OssAdUnitFragment
            ossPagesCount
            updatedAt
        }
    }
  }
`
