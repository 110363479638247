import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ToggleSwitch } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error as QqlError } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { PriceGeniusGlobalTargetingToggleProps, UpdateWorkspaceData, UpdateWorkspaceVars } from './types'

import UPDATE_WORKSPACE from 'gql/mutations/workspaces/UpdateWorkspace.gql'

export const PriceGeniusGlobalTargetingToggle = ({
  status: initialStatus,
  workspaceId
}: PriceGeniusGlobalTargetingToggleProps): JSX.Element => {
  const { t } = useTranslation('workspaces')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [status, setStatus] = useState<boolean>(initialStatus)

  const [updateStatus] = useMutation<UpdateWorkspaceData, { input: UpdateWorkspaceVars }>(UPDATE_WORKSPACE, {
    onCompleted: ({ updateWorkspace: { workspace, errors } }) => {
      if (errors.length > 0) {
        createNotifier(errors.map((e: QqlError) => e.message).join(', '), NotificationType.ERROR)
      } else if (workspace) {
        const { name: workspaceName, pgGlobalTargetingEnabled } = workspace

        createNotifier(
          t('priceGeniusGlobalTargetingToggle.update.success', { workspaceName }),
          NotificationType.SUCCESS
        )
        setStatus(pgGlobalTargetingEnabled)
      }
    }
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => void event.stopPropagation()

  const handleChange = () =>
    void updateStatus(
      nestGqlInput({
        workspaceId,
        pgGlobalTargetingEnabled: !status
      })
    )

  const KEY = `price-genius-global-targeting-for-workspace-${workspaceId}`

  return (
    <div className='PriceGeniusGlobalTargetingToggle' onClick={handleClick}>
      <ToggleSwitch checked={status} id={KEY} key={KEY} name={KEY} onChange={handleChange} />
    </div>
  )
}
