import React from 'react'
import { FieldConfig, useField } from 'formik'

import { ToggleSwitch } from 'components/Form'
import { TooltipTogglePlacement } from 'components/Form/ToggleSwitch/ToggleSwitch'

interface Props {
  id: string
  disabled?: boolean
  className?: string
  errors?: Array<string>
  isBeta?: boolean
  label?: string
  labelStyle?: string
  labelClassName?: string
  tooltipContent?: string
  tooltipTogglePlacement?: TooltipTogglePlacement
  warningContent?: string
  field: FieldConfig
  onChange?: (value: boolean) => void
}

export const FormikToggleContainer: React.FC<Props> = ({
  disabled,
  id,
  className,
  errors,
  isBeta,
  label,
  labelStyle,
  labelClassName,
  tooltipContent,
  tooltipTogglePlacement,
  warningContent,
  onChange,
  ...other
}) => {
  const [field, , helpers] = useField(other.field.name)

  const onChangeHandler = () => {
    onChange && onChange(!field.value)
    helpers.setValue(!field.value)
  }

  return (
    <ToggleSwitch
      id={id}
      name={other.field.name}
      disabled={disabled}
      checked={field.value}
      className={className}
      errors={errors}
      isBeta={isBeta}
      label={label}
      labelStyle={labelStyle}
      labelClassName={labelClassName}
      tooltipContent={tooltipContent}
      tooltipTogglePlacement={tooltipTogglePlacement}
      warningContent={warningContent}
      onChange={onChangeHandler}
    />
  )
}
