import React from 'react'

import { Box, Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { ErrorUtils } from 'utilities/errorUtils'
import { SettingsFactory } from './formSchema'

interface Props {
  settings: GeneralSetting
  errors: ErrorUtils
  isSubmitting?: boolean
  onSubmit: (settings: GeneralSettingForm) => void
  onDefaultHandler?: () => void
}

export const GeneralSettingsForm: React.FC<Props> = ({
  settings,
  errors,
  isSubmitting,
  onSubmit,
  onDefaultHandler
}) => {
  const { t } = useTranslation('setup')

  const disableSubmitBtn = (dirty: boolean): boolean => (isSubmitting ? true : !dirty)

  return (
    <div className='custom-settings-form qw-form'>
      <Formik initialValues={SettingsFactory(settings)} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='qw-custom-settings' dirty={dirty}>
            <Grid item xs={6} lg={4}>
              <Field
                id='custom-settings-timeout'
                name='prebidTimeout'
                type='number'
                placeholder={t('generalSettings.form.fields.prebidTimeout.placeholder')}
                label={t('generalSettings.form.fields.prebidTimeout.label')}
                tooltipContent={t('generalSettings.form.fields.prebidTimeout.tooltip')}
                component={FormikInput}
                errors={errors.errorsFor('prebidTimeout')}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <Field
                id='custom-settings-failsafe-timeout'
                name='failsafeTimeout'
                type='number'
                placeholder={t('generalSettings.form.fields.failsafeTimeout.placeholder')}
                label={t('generalSettings.form.fields.failsafeTimeout.label')}
                tooltipContent={t('generalSettings.form.fields.failsafeTimeout.tooltip')}
                component={FormikInput}
                errors={errors.errorsFor('failsafeTimeout')}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <Box display='flex' justifyContent='space-between'>
                <Button
                  type={ButtonType.Primary}
                  theme={ButtonTheme.Orange}
                  disabled={disableSubmitBtn(dirty)}
                  buttonType='submit'
                >
                  {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                </Button>
                {onDefaultHandler && (
                  <Button
                    type={ButtonType.Primary}
                    theme={ButtonTheme.OrangeReverse}
                    onClickHandler={onDefaultHandler}
                    buttonType='button'
                  >
                    {t('generalSettings.form.default')}
                  </Button>
                )}
              </Box>
            </Grid>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
