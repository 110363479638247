import React from 'react'

import { EditPage } from 'pages/oss/Workspaces/EditPage'
import { GamConnectorRedirectPage } from 'pages/oss/Workspaces/GamConnectorRedirectPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { ListPage } from 'pages/oss/Workspaces/ListPage'
import { NewPage } from 'pages/oss/Workspaces/NewPage'
import { Product } from 'webapp/constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'

export const WorkspaceRoutes = [
  <LayoutRoute
    key='workspaces-edit'
    product={Product.OSS}
    path={routesBuilder.oss.workspaces.edit()}
    component={EditPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='workspace-new'
    product={Product.OSS}
    path={routesBuilder.oss.workspaces.new}
    exact
    component={NewPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='workspace-root'
    product={Product.OSS}
    path={routesBuilder.oss.workspace.root}
    exact
    component={EditPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='workspace-list'
    product={Product.OSS}
    path={routesBuilder.oss.workspaces.root}
    exact
    component={ListPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='gam-connection-callback'
    product={Product.OSS}
    path={routesBuilder.oauth.gamConnect}
    component={GamConnectorRedirectPage}
  />
]
