import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { ReportResultTable, ReportFilter } from 'components/quickwrap/Performance/Reports/ReportResultTable'

interface Props {
  reportData: Array<ReportData>
  reportFormData: ReportFormType
  currencyCode: string
}

export const ReportResultsContainer: React.FC<Props> = ({ reportData, reportFormData, currencyCode }) => {
  const { t } = useTranslation('reports')
  const [data, setData] = useState(reportData)
  const [showSummaryRow, setShowSummaryRow] = useState(false)

  const filterByAdUnits = (adUnits: Array<string>) => {
    if (adUnits.length > 0) {
      const results = reportData.filter((record) => adUnits.some((adUnit) => record.name.includes(adUnit)))
      setData(results)
      setShowSummaryRow(true)
    } else {
      setData(reportData)
      setShowSummaryRow(false)
    }
  }

  return (
    <Box className='report-results__container' isExpandable={true}>
      <BoxHeader
        title={t('resultPage.inventory.title', { networkName: reportFormData.networkName })}
        containerClassName={'box-header__container--space-between'}
        rowClassName={'header__row--width-100 header__row--flex-end'}
      >
        <ReportFilter collectionName={CollectionNames.ReportResults} filter={filterByAdUnits} />
      </BoxHeader>
      <BoxBody>
        <ReportResultTable
          collectionName={CollectionNames.ReportResults}
          currencyCode={currencyCode}
          reportData={data}
          showSummaryRow={showSummaryRow}
        />
      </BoxBody>
    </Box>
  )
}
