import React, { useContext, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ActionsCellProps } from './types'
import { Bin } from 'icons/Bin'
import { DeleteModal } from './Modals/DeleteModal'
import { DropdownMenu } from 'webapp/components/DropdownMenu'
import { Edit } from 'icons/Edit'
import { EditModal } from './Modals/EditModal'
import { VersionedDomainContext } from 'webapp/context/VersionedDomainContext'

export const ActionsCell: React.FC<ActionsCellProps> = ({ row, testedDomainVersionIds }) => {
  const { domainVersions } = useContext(VersionedDomainContext)
  const { t } = useTranslation('inventory')
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const domainVersion: DomainVersion = row.original
  const isBeingTested: boolean = testedDomainVersionIds.includes(domainVersion.id)

  return (
    <>
      <DropdownMenu setVisible={setDropdownVisible} visible={dropdownVisible}>
        <MenuItem onClick={() => setEditModalVisible(true)} disabled={isBeingTested}>
          {t('common:buttons.edit')}
          <Edit />
        </MenuItem>

        {domainVersions.length > 1 && (
          <MenuItem onClick={() => setDeleteModalVisible(true)} disabled={isBeingTested}>
            {t('common:buttons.delete')}
            <Bin />
          </MenuItem>
        )}
      </DropdownMenu>

      <EditModal domainVersion={domainVersion} setModalVisible={setEditModalVisible} modalVisible={editModalVisible} />

      <DeleteModal
        domainVersion={domainVersion}
        setModalVisible={setDeleteModalVisible}
        modalVisible={deleteModalVisible}
      />
    </>
  )
}
