import React from 'react'

import ArrowSrc from 'images/arrow.svg'

interface Props {
  classNames: string
  alt: string
  onClickHandler?: (e: React.MouseEvent<Element, MouseEvent>) => void
}

const Arrow: React.FC<Props> = ({ classNames, alt, onClickHandler }) => {
  return <img className={`icon-arrow ${classNames}`} src={ArrowSrc} alt={alt} onClick={onClickHandler} />
}

export { Arrow }
