import React, { useContext, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Can } from 'webapp/context/AbilityContext'
import { ConfirmationModal } from 'components/Modals'
import { DomainRowData } from 'components/shared/Inventory/Domains/DomainsTable/DomainRowData'
import { DropdownMenu } from 'components/DropdownMenu'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { Product } from 'webapp/constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'

import { Bin } from 'icons/Bin'
import { Edit } from 'icons/Edit'

import DestroyDomain from 'gql/mutations/domains/DestroyDomain.gql'

interface Props {
  product: Product.OSS | Product.Quickwrap
  row: Row<DomainRowData>
}

export const ActionsCell: React.FC<Props> = ({ product, row }) => {
  const domain = row.original.domain

  const history = useHistory<History>()
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const [destroyDomain] = useMutation(DestroyDomain, {
    onCompleted: ({ destroyDomain: { domain, error } }) => {
      if (domain) {
        createNotifier(
          t('domains.modal.deleteConfirm.notification.success', { domainName: domain.name }),
          NotificationType.SUCCESS
        )
        history.push(history.location)
      } else if (error) {
        createNotifier(
          t('domains.modal.deleteConfirm.notification.error', { domainName: domain.name }),
          NotificationType.ERROR
        )
      }
      setVisibleDeleteModal(false)
    }
  })

  const onConfirm = (): void => {
    destroyDomain(nestGqlInput({ domainId: domain.id }))
  }

  const onDelete = (): void => {
    setVisibleDeleteModal(true)
  }

  const onEdit = (): void => {
    history.push(routesBuilder[product].inventory.domains.edit(domain.id, domain.activeVersion.id))
  }

  return (
    <div onClick={(e): void => e.stopPropagation()}>
      <DropdownMenu setVisible={setIsDropdownVisible} visible={isDropdownVisible}>
        <MenuItem onClick={onEdit}>
          {t('common:buttons.edit')}
          <Edit />
        </MenuItem>
        <Can I={AbilityAction.DELETE} a={AbilitySubject.DOMAIN}>
          <MenuItem onClick={onDelete}>
            {t('common:buttons.delete')}
            <Bin />
          </MenuItem>
        </Can>
      </DropdownMenu>
      <ConfirmationModal
        cancelButtonText={t('common:buttons.cancel')}
        confirmButtonText={t('common:buttons.delete')}
        content={t('domains.modal.deleteConfirm.content', { domainName: domain.name })}
        onConfirmHandler={onConfirm}
        setVisible={setVisibleDeleteModal}
        visible={visibleDeleteModal}
      />
    </div>
  )
}
