import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'

import { BidderPriorityTypes } from 'webapp/constants/BidderPriorityTypes'
import { BidderSettingsForm } from 'components/sharedInterfaces/BidderSettingsForm'
import { CollectionField } from 'components/FormikComponents'
import { ButtonType, ButtonTheme } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import { LoadingButton } from 'components/Button/LoadingButton'
import { StatusForm } from 'components/Form'
import { YbDemandBiddersRow } from './YbDemandBiddersRow'

interface Props {
  allowSubmit: boolean
  demandBidders: Bidder[]
  demandBidderSettings: BidderSettingsForm
  onChangeHandler: (flag: boolean) => void
  onSubmitHandler: (form: BidderSettingsForm, formikHelpers: FormikHelpers<BidderSettingsForm>) => void
  onRemoveHandler: (field: number) => void
  errors: Errors
  isSubmitting?: boolean
}

export const YbDemandEditSection: React.FC<Props> = ({
  allowSubmit,
  demandBidders,
  demandBidderSettings,
  onChangeHandler,
  onSubmitHandler,
  onRemoveHandler,
  errors,
  isSubmitting
}) => {
  const { t } = useTranslation('setup')
  const ybBidderInputTemplate = {
    bidderId: { value: '' },
    priority: { value: BidderPriorityTypes.primary }
  }

  return (
    <div className='yb-demand-bidders-form yb-demand-bidders__edit-section'>
      <h2 className='yb-demand-bidders__subheading'>{t('yieldbirdDemand.edit.title')}</h2>

      <Formik initialValues={demandBidderSettings} onSubmit={onSubmitHandler} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='yb-demand-bidders-edit' dirty={dirty}>
            <CollectionField
              addNextHidden={true}
              collectionName='demandBidders'
              confirmRemoval
              emptyElementTemplate={ybBidderInputTemplate}
              name='bidders'
              onChangeHandler={onChangeHandler}
              onRemoveHandler={onRemoveHandler}
            >
              {({ index }: { index: number }) => (
                <YbDemandBiddersRow index={index} demandBidders={demandBidders} errors={errors} />
              )}
            </CollectionField>

            <div className='yb-demand-bidders__update-button'>
              <LoadingButton
                type={ButtonType.Primary}
                theme={ButtonTheme.Orange}
                buttonType='submit'
                disabled={!(errors.raw.length || allowSubmit)}
                loading={isSubmitting}
              >
                {t('common:buttons.save')}
              </LoadingButton>
            </div>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
