import React, { ChangeEvent, useMemo, useCallback } from 'react'
import classNames from 'classnames'

import { Label } from 'components/Form'

interface Props {
  checked: boolean
  className?: string
  disabled?: boolean
  id: string
  label?: JSX.Element
  name: string
  onChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void
  tooltipContent?: string
  value: string
}

export const RadioButton: React.FC<Props> = ({
  name,
  disabled,
  id,
  label,
  checked,
  className,
  value,
  onChange,
  tooltipContent
}) => {
  const classString = useMemo(() => {
    return classNames('qw-radio-button', className, {
      'qw-radio-button--checked': checked,
      'qw-radio-button--disabled': disabled
    })
  }, [checked, disabled, className])

  const changeHandler = useCallback(
    (_event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(value)
    },
    [onChange, value]
  )

  return (
    <div className={classString}>
      <input
        type='radio'
        name={name}
        id={id}
        className='qw-radio-button__input'
        disabled={disabled}
        checked={checked}
        onChange={changeHandler}
      />
      <Label className='qw-radio-button__label' htmlFor={id} disabled={disabled} tooltipContent={tooltipContent}>
        <span className='qw-radio-button__checkmark' />
        {label && <span className='qw-radio-button__label-text'>{label}</span>}
      </Label>
    </div>
  )
}
