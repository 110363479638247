import React from 'react'
import { CellProps, Column } from 'react-table'

import { ActionsCell } from './ActionsCell'
import { CroppedCell } from 'components/Table/CroppedCell'
import { DateCell } from 'components/Table'
import { VersionStatusCell } from './VersionStatusCell'
import i18n from 'webapp/i18n'

export const columnsConfig = (isABTestRunning: boolean, testedDomainVersionIds: string[]): Column<DomainVersion>[] => [
  {
    Header: () => i18n.t('inventory:domains.tableHeader.name'),
    accessor: 'name'
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.versionStatus'),
    accessor: 'active',
    Cell: ({ row }) => (
      <VersionStatusCell row={row} isABTestRunning={isABTestRunning} testedDomainVersionIds={testedDomainVersionIds} />
    )
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.description'),
    accessor: 'description',
    Cell: ({ value }: { value: string }) => <CroppedCell textElements={value || ''} />
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }: { value: Date }) => <DateCell value={value} />
  },
  {
    Header: () => i18n.t('inventory:domains.tableHeader.whoModified'),
    accessor: 'user.fullName' as 'user'
  },
  {
    Header: () => i18n.t('Actions'),
    id: 'actions',
    Cell: ({ row }: CellProps<DomainVersion>) => (
      <ActionsCell row={row} testedDomainVersionIds={testedDomainVersionIds} />
    ),
    disableSortBy: true
  }
]
