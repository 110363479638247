import React from 'react'

import { GamRedirect } from 'components/oss/Gam/GamRedirect'
import { oauthGamCallbackUrl } from 'webapp/constants/oauthCallbackUrl'
import { routesBuilder } from 'utilities/routesBuilder'

export const GamConnectorRedirectPage: React.FC = () => {
  return (
    <GamRedirect
      errorTargetUrl={routesBuilder.oss.workspace.root}
      oauthCallbackUrl={oauthGamCallbackUrl}
      targetUrl={routesBuilder.oss.workspace.root}
    />
  )
}
