import i18n from 'webapp/i18n'
import { OutOfPageTypes } from './OutOfPageTypes'

export const OutOfPageTypeOptions: SelectOption[] = [
  {
    value: OutOfPageTypes.standard,
    label: i18n.t('inventory:adUnits.form.outOfPageType.value', { type: OutOfPageTypes.standard })
  },
  {
    value: OutOfPageTypes.bottomAnchor,
    label: i18n.t('inventory:adUnits.form.outOfPageType.value', { type: OutOfPageTypes.bottomAnchor })
  },
  {
    value: OutOfPageTypes.interstitial,
    label: i18n.t('inventory:adUnits.form.outOfPageType.value', { type: OutOfPageTypes.interstitial })
  },
  {
    value: OutOfPageTypes.rewarded,
    label: i18n.t('inventory:adUnits.form.outOfPageType.value', { type: OutOfPageTypes.rewarded })
  },
  {
    value: OutOfPageTypes.topAnchor,
    label: i18n.t('inventory:adUnits.form.outOfPageType.value', { type: OutOfPageTypes.topAnchor })
  }
]
