import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  content: string
  type: 'success' | 'error'
}

export const Message: React.FC<Props> = ({ content, type }) => {
  const { t } = useTranslation('inventory')

  return (
    <p className='bulk-upload-status__message'>
      <span className={`bulk-upload-status--${type}`}>{t(`domains.edit.adUnits.bulkUploadStatus.${type}.type`)}</span>
      <span>{content}</span>
    </p>
  )
}
