import React, { useRef } from 'react'

import { Menu } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { MoreButton } from 'components/Button/MoreButton'
import { Product } from 'types/common'

interface Props {
  children: React.ReactNode
  horizontalOffset?: number
  product?: Product
  setVisible: (value: boolean) => void
  visible: boolean
}

export const DropdownMenu: React.FC<Props> = ({ children, horizontalOffset = -70, product, setVisible, visible }) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLButtonElement>(null)

  const onClickHandler = () => {
    setVisible(true)
  }

  const onCloseHandler = () => {
    setVisible(false)
  }

  return (
    <>
      <MoreButton
        aria-label={t('buttons.showMenu')}
        aria-controls='dropdown-menu'
        aria-haspopup
        innerRef={ref}
        onClick={onClickHandler}
      />
      <Menu
        id='dropdown-menu'
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontalOffset
        }}
        classes={{
          paper: `MuiPopover-paper qw-dropdown-menu ${product === 'revive' ? ' qw-dropdown-menu--revive' : ''}`
        }}
        disableScrollLock={false}
        getContentAnchorEl={null}
        onClose={onCloseHandler}
        open={visible}
      >
        {children}
      </Menu>
    </>
  )
}
