import React, { ReactNode } from 'react'
import { useFormikContext } from 'formik'

import { Bin } from 'icons/Bin'
import { Button, ButtonTheme, ButtonType } from 'components/Button'

interface Props {
  index: number
  onDeleteHandler?: (index: number) => void
  name: string
  children: (props: { index: number }) => ReactNode
}

type FormType = Record<string, Record<string, string>[]>

export const DeviceListItem: React.FC<Props> = ({ name, index, onDeleteHandler, children }) => {
  const { values } = useFormikContext<FormType>()

  return (
    <div className='device-list-item with-border'>
      <div className='device-list-item__header'>
        <h2 className='device-list-item__title'>{values[name][index]['label']}</h2>
        <div>
          <Button
            type={ButtonType.SingleAction}
            theme={ButtonTheme.BlueDark}
            buttonType='button'
            onClickHandler={() => onDeleteHandler?.(index)}
          >
            <Bin />
          </Button>
        </div>
      </div>
      {children({ index })}
    </div>
  )
}
