import React, { useMemo } from 'react'
import { Field, Formik, FormikHelpers } from 'formik'
import { Grid } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput, FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'
import { InviteUserFactory } from './formSchema'
import { Role, RoleType } from 'webapp/constants/Role'
import { StatusForm } from 'components/Form'
import { usePublisherRoleOptions, useRoleTypeOptions, useSuperuserRoleOptions } from 'utilities/roles/options'

interface Props {
  errors: Errors
  isSubmitting: boolean
  onSubmit: (values: InviteUserSchema, actions: FormikHelpers<InviteUserSchema>) => void
  workspaces: Workspace[]
}

export const InviteFormForSuperusers: React.FC<Props> = ({ errors, isSubmitting, onSubmit, workspaces }) => {
  const { t } = useTranslation('users')

  const prefilledRoleType = useLocation<{ roleType?: RoleType }>().state?.roleType

  const roleTypeOptions = useRoleTypeOptions()
  const publisherRoleOptions = usePublisherRoleOptions()
  const superuserRoleOptions = useSuperuserRoleOptions()

  const workspaceOptions = useMemo(
    () => workspaces.map(({ id, name, ownerCount }) => ({ value: id, label: name, ownerCount })),
    [workspaces]
  )

  return (
    <div className='invite-form qw-form'>
      <Formik initialValues={InviteUserFactory(prefilledRoleType)} onSubmit={onSubmit}>
        {({ dirty, setFieldValue, values }) => {
          const lockOwner = Boolean(values.workspaceId && !values.workspaceId.ownerCount)

          return (
            <StatusForm name='oss-invite-superuser' dirty={dirty}>
              <Grid container spacing={4}>
                <Grid item lg={4} xs={6}>
                  <Field
                    component={FormikSelect}
                    id='roleType'
                    label={t('invite.roleType.label')}
                    name='roleType'
                    onChangeHandler={(option: SelectOption) => {
                      if (option.value === values.roleType!.value) return

                      setFieldValue('role', null)
                      setFieldValue('workspaceId', null)
                    }}
                    options={roleTypeOptions}
                    placeholder={t('invite.role.placeholder')}
                  />

                  {values.roleType?.value === RoleType.Superuser && (
                    <Field
                      component={FormikSelect}
                      errors={errors.errorsFor('role')}
                      id='role'
                      label={t('invite.role.superuser.label')}
                      name='role'
                      options={superuserRoleOptions}
                      placeholder={t('invite.role.superuser.placeholder')}
                    />
                  )}

                  <Field
                    component={FormikInput}
                    errors={errors.errorsFor('email')}
                    id='email'
                    label={t('invite.email.label')}
                    name='email'
                    placeholder={t('invite.email.placeholder')}
                    type='text'
                  />

                  {values.roleType?.value === RoleType.Publisher && (
                    <>
                      <Field
                        component={FormikSelect}
                        errors={errors.errorsFor('workspaceId')}
                        id='data-studio-workspace-id'
                        label={t('invite.workspace.label')}
                        name='workspaceId'
                        onChangeHandler={(value: InviteUserSchema['workspaceId']) => {
                          if (!value?.ownerCount) {
                            setFieldValue('role', { value: Role.Owner })
                          }
                        }}
                        options={workspaceOptions}
                        placeholder={t('invite.workspace.placeholder')}
                      />

                      <Field
                        component={FormikSelect}
                        disabled={lockOwner}
                        errors={errors.errorsFor('role')}
                        id='role'
                        label={t('invite.role.publisher.label')}
                        name='role'
                        options={publisherRoleOptions}
                        placeholder={t('invite.role.publisher.placeholder')}
                        tooltipContent={lockOwner ? t('invite.role.noOwner') : undefined}
                      />
                    </>
                  )}
                </Grid>
              </Grid>

              <Button
                buttonType='submit'
                className='qw-submit'
                disabled={isSubmitting || !dirty}
                theme={ButtonTheme.Blue}
                type={ButtonType.Primary}
              >
                {isSubmitting ? t('invite.submitting') : t('invite.submit')}
              </Button>
            </StatusForm>
          )
        }}
      </Formik>
    </div>
  )
}
