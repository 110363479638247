import React, { useContext, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { ConfirmationModal } from 'components/Modals'
import { DropdownMenu } from 'components/DropdownMenu'
import { Edit } from 'icons/Edit'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { routesBuilder } from 'webapp/utils/routesBuilder'

import DeleteBlockUrlGroup from 'gql/mutations/blockUrlGroups/DeleteBlockUrlGroup.gql'

interface Props {
  blockUrlGroup: BlockUrlGroup
}

export const ActionsCell: React.FC<Props> = ({ blockUrlGroup }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const { createNotifier } = useContext(NotificationContext) as Notification

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

  const onEditHandler = () => {
    history.push(routesBuilder.quickwrap.blockUrlGroups.edit(blockUrlGroup.id))
  }
  const onDeleteHandler = () => setVisibleDeleteModal(true)

  const [destroyBlockUrlGroup] = useMutation(DeleteBlockUrlGroup, {
    onCompleted: ({ destroyQwBlockUrlGroup: { errors } }) => {
      if (!errors.length) {
        setVisibleDeleteModal(false)
        createNotifier(
          t('protection:blockUrlGroups:list.notification.delete.success', blockUrlGroup.name),
          NotificationType.SUCCESS
        )
        history.push(history.location)
      } else {
        setVisibleDeleteModal(false)
        createNotifier(
          t('protection:blockUrlGroups:list.notification.delete.error', blockUrlGroup.name),
          NotificationType.ERROR
        )
      }
    }
  })

  const confirmHandler = () => destroyBlockUrlGroup(nestGqlInput({ qwBlockUrlGroupId: blockUrlGroup.id }))

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownMenu setVisible={setIsDropdownVisible} visible={isDropdownVisible}>
        <MenuItem onClick={onEditHandler}>
          {t('common:buttons.edit')} <Edit />
        </MenuItem>

        <MenuItem onClick={onDeleteHandler}>
          {t('common:buttons.delete')} <Bin />
        </MenuItem>
      </DropdownMenu>
      <ConfirmationModal
        cancelButtonText={t('buttons.cancel')}
        confirmButtonText={t('buttons.delete')}
        content={t('protection:blockUrlGroups:list.modal.deleteConfirm.content', {
          blockUrlGroupName: blockUrlGroup.name
        })}
        onConfirmHandler={confirmHandler}
        setVisible={() => {
          setIsDropdownVisible(false)
          setVisibleDeleteModal(false)
        }}
        visible={visibleDeleteModal}
      />
    </div>
  )
}
