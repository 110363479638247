import React from 'react'

import { Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { FormikToggleContainer as FormikToggle } from 'containers/FormikContainers'
import { Can } from 'context/AbilityContext'
import type { Errors } from 'utilities/errorUtils'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { LazyLoad } from './LazyLoad'

interface Props {
  domain: DomainFormData
  errors: Errors
  isSubmitting?: boolean
  onSubmit: (domain: DomainFormData) => void
}

export const DomainForm: React.FC<Props> = ({ domain, errors, isSubmitting, onSubmit }) => {
  const { t } = useTranslation('inventory')

  return (
    <div className='domain-form qw-form'>
      <Formik initialValues={domain} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='qw-domain' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={6}>
                <Can I={AbilityAction.ACCESS} a={AbilitySubject.FILLRATE_BOOSTER_PACKAGE}>
                  <Field
                    id='domain-fillrate-booster'
                    name='fillrateBoosterEnabled'
                    label={t('domains.form.fillrateBooster.label')}
                    tooltipContent={t('domains.form.fillrateBooster.tooltip')}
                    component={FormikToggle}
                  />
                </Can>

                <Can I={AbilityAction.ACCESS} a={AbilitySubject.VIEWABILITY_BOOSTER_PACKAGE}>
                  <Field
                    id='domain-viewability-booster'
                    name='viewabilityBoosterEnabled'
                    label={t('domains.form.viewabilityBooster.label')}
                    tooltipContent={t('domains.form.viewabilityBooster.tooltip')}
                    component={FormikToggle}
                  />
                </Can>

                <Can I={AbilityAction.ACCESS} a={AbilitySubject.PREBID_MANAGER_PACKAGE}>
                  <Field
                    id='domain-prebid-manager'
                    name='prebidManagerEnabled'
                    label={t('domains.form.prebidManager.label')}
                    tooltipContent={t('domains.form.prebidManager.tooltip')}
                    component={FormikToggle}
                  />
                </Can>

                <Field
                  id='domain-auction-per-ad-unit'
                  name='auctionPerAdUnit'
                  label={t('domains.form.auctionPerAdUnit.label')}
                  tooltipContent={t('domains.form.auctionPerAdUnit.tooltip')}
                  component={FormikToggle}
                  isBeta
                />

                <Field
                  id='without-consent-enabled'
                  name='withoutConsentEnabled'
                  label={t('domains.consentManagement.form.withoutConsentEnabled.label')}
                  tooltipContent={t('domains.consentManagement.form.withoutConsentEnabled.tooltip')}
                  component={FormikToggle}
                />
              </Grid>

              <Grid item lg={4} xs={6}>
                <LazyLoad errors={errors} />
              </Grid>
            </Grid>

            <Button
              disabled={isSubmitting || !dirty}
              type={ButtonType.Primary}
              theme={ButtonTheme.Orange}
              buttonType='submit'
            >
              {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
