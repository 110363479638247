import React from 'react'
import classnames from 'classnames'

import { Image } from 'components/Image'

import MainLogo from 'images/logos/oss.svg'

interface Props {
  image: string
  alignItems?: string
  view?: string
}

export const UserFlowTemplate: React.FC<Props> = ({ image, alignItems, view, children }) => {
  const containerClassNames = classnames('user-flow__container', { [`user-flow__container--${view}`]: !!view })
  const colContainerClassNames = classnames('user-flow__col-container', { [`align-items-${alignItems}`]: !!alignItems })

  return (
    <div className={containerClassNames}>
      <div className='user-flow__col '>
        <div className='user-flow__logo'>
          <a href='/'>
            <Image src={MainLogo} alt='Yieldbird logo' />
          </a>
        </div>
        <div className={colContainerClassNames}>{children}</div>
      </div>

      <div className='user-flow__col'>
        <div className='user-flow__image-wrapper'>
          <Image src={image} alt='Signup illustration' className='user-flow__image' />
        </div>
      </div>
    </div>
  )
}
