import React from 'react'
import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { ActionsCell } from './ActionsCell'
import { DateCell } from 'components/Table'
import { InventoryCell } from './InventoryCell'

export const columnsConfig = (domains: Domain[]): Column<BlockUrlGroup>[] => [
  {
    Header: () => i18n.t('protection:blockUrlGroups:list.tableHeader.name'),
    accessor: 'name'
  },
  {
    Header: () => i18n.t('protection:blockUrlGroups:list.tableHeader.blocks'),
    accessor: 'urls',
    Cell: ({ row }) => row.original.urls.length ?? '0'
  },
  {
    Header: () => i18n.t('protection:blockUrlGroups:list.tableHeader.inventory'),
    id: 'inventory',
    disableSortBy: true,
    Cell: ({ row }: CellProps<BlockUrlGroup>) => (
      <InventoryCell blockUrlGroup={row.original as BlockUrlGroup} domains={domains} />
    )
  },
  {
    Header: () => i18n.t('protection:blockUrlGroups:list.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }: CellProps<BlockUrlGroup>) => <DateCell value={value} />
  },
  {
    Header: () => i18n.t('protection:blockUrlGroups:list.tableHeader.actions'),
    id: 'actions',
    disableSortBy: true,
    Cell: ({ row }: CellProps<BlockUrlGroup>) => <ActionsCell blockUrlGroup={row.original as BlockUrlGroup} />
  }
]
