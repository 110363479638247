import React from 'react'
import { useTranslation } from 'react-i18next'

type InventoryCellType = {
  blockUrlGroup: BlockUrlGroup
  domains: Domain[]
}

export const InventoryCell = ({ blockUrlGroup, domains }: InventoryCellType): JSX.Element => {
  const { t } = useTranslation('protection')

  const inventoryState = () => {
    let state = 'none'

    if (blockUrlGroup.domains.length) {
      state = domains.length === blockUrlGroup.domains.length ? 'all' : 'specified'
    }

    return t(`blockUrlGroups.list.inventoryStates.${state}`)
  }

  return <>{inventoryState()}</>
}
