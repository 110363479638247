import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { DownloadTagSerializer, FormValues } from 'serializers/DownloadTagSerializer'
import {
  FormikSelectContainer as FormikSelect,
  FormikToggleContainer as FormikToggle
} from 'containers/FormikContainers'
import { emptySelectOption } from 'webapp/constants/emptySelectOption'
import { integrationMethodUtils } from 'utilities/integrationMethodUtils'

interface Props {
  domains: Domain[]
  isSubmitting?: boolean
  onSubmit: (domain: DownloadTagSerializer) => void
}

export const DownloadTagsForm: React.FC<Props> = ({ domains, isSubmitting, onSubmit }) => {
  const { t } = useTranslation()

  const onSubmitHandler = (values: FormValues) => {
    onSubmit(new DownloadTagSerializer(values))
  }

  const domainSelectOptions = domains.map((domain) => {
    return { value: domain.id, label: domain.name }
  })

  const onChangeSelectHandler = (options: SelectOption) => {
    if (options.value) {
      setDisabled(false)
    }
  }

  const [isDisabled, setDisabled] = useState(true)

  const initialValues = {
    domain: emptySelectOption,
    tagsType: integrationMethodUtils.get('open_tag')
  }

  return (
    <div className='download-tags-form qw-form'>
      <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
        {({ values }) => (
          <Form>
            <Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Field
                    id='domain'
                    name='domain'
                    placeholder={t('downloadTags.domain.placeholder')}
                    options={domainSelectOptions}
                    label={t('downloadTags.domain.label')}
                    tooltipContent={t('downloadTags.domain.tooltip')}
                    component={FormikSelect}
                    onChangeHandler={onChangeSelectHandler}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={4}>
                  <Field
                    id='tagsType'
                    name='tagsType'
                    placeholder={t('downloadTags.tagsType.placeholder')}
                    options={integrationMethodUtils.getList()}
                    label={t('downloadTags.tagsType.label')}
                    component={FormikSelect}
                  />
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Button disabled={isDisabled} type={ButtonType.Primary} theme={ButtonTheme.Blue} buttonType='submit'>
                  {isSubmitting ? t('buttons.downloading') : t('buttons.download')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  )
}
