import { Error } from 'utilities/errorUtils'

export type PageAddEditProps = {
  formType: FormType
}

export type DomainWithOssAdUnits = Pick<Domain, 'id' | 'name'> & {
  ossAdUnits?: {
    nodes: Pick<OssAdUnit, 'elementId' | 'id'>[]
  }
}

export type DomainsData = {
  domains: { nodes: DomainWithOssAdUnits[] }
}

export type PageData = {
  ossPage: {
    domain: Pick<Domain, 'id' | 'name'>
  } & Pick<OssPage, 'id' | 'pageName' | 'path' | 'matchType'>
}

export type PageVars = {
  pageId: Page['id']
}

export type UpsertOssPageData = {
  upsertOssPage: {
    ossPage: Pick<OssPage, 'activePageId' | 'id' | 'pageName'>
    errors: Error[]
  }
}

export type UpsertOssPageVars = {
  customVersioningEnabled?: boolean
  domainId: Domain['id']
  matchType?: MatchType
  ossAdUnitIds?: OssAdUnit['id'][]
  ossPageId?: OssPage['id']
  pageName?: OssPage['pageName']
  path: OssPage['path']
}

export const upsertOssPageVarsDefault: UpsertOssPageVars = {
  domainId: '',
  matchType: 'include',
  ossAdUnitIds: [],
  path: ''
}
