import React from 'react'
import { Column, Row } from 'react-table'

import BirdSrc from 'images/bird.svg'
import { CheckBoxCell } from './CheckboxCell'
import { ConnectionHeader } from 'components/quickwrap/Setup/InventoryConnectionTable/ConnectionHeader'
import { FormSchema } from './formSchema'
import { UnconfiguredCell } from './UnconfiguredCell'

export const bidderColumn = (
  bidderConnection: BidderConnection,
  values: Record<string, boolean | SelectOption>,
  configured: Record<string, boolean>
): Column<AdUnitConnection> => {
  return {
    id: bidderConnection.id,
    Header: (
      <div className='inventory-connection-table__bidder-heading'>
        {bidderConnection.bidder.demand && <img src={BirdSrc} alt='Yieldbird' />}
        <span>{bidderConnection.name}</span>
      </div>
    ),
    disableSortBy: true,
    columns: [
      {
        id: bidderConnection.id,
        Header() {
          return <CheckBoxCell id={bidderConnection.id} disabled={!bidderConnection.active} />
        },
        disableSortBy: true,
        Cell({ row }: { row: Row<AdUnitConnection> }) {
          const connectionId = bidderConnection.id + 'conn'
          const connection = values[connectionId] as SelectOption<string>

          if ('_header' in row.original && connection.value === 'client_server')
            return <span className='hybrid'>C2S S2S</span>
          if ('_header' in row.original) return null

          if ('_connection' in row.original)
            return (
              <ConnectionHeader
                id={connectionId}
                disabled={!bidderConnection.bidder.s2sAvailable}
                connection={connection}
              />
            )

          const formId = FormSchema.getFormId(bidderConnection.id, row.original.adUnitId)
          const bidderDisabled = !bidderConnection.active || !values[bidderConnection.id]
          const c2sId = formId + 'c2s'
          const s2sId = formId + 's2s'

          return (
            <div>
              {connection.value.includes('client') &&
                (configured[c2sId] ? (
                  <CheckBoxCell disabled={bidderDisabled} id={c2sId} />
                ) : (
                  <UnconfiguredCell disabled={bidderDisabled} />
                ))}
              {connection.value.includes('server') &&
                (configured[s2sId] ? (
                  <CheckBoxCell disabled={bidderDisabled} id={s2sId} />
                ) : (
                  <UnconfiguredCell disabled={bidderDisabled} />
                ))}
            </div>
          )
        }
      }
    ]
  }
}
