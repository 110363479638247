import React, { memo, useMemo } from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
  errors?: Array<string>
}

const MESSAGE_SEPARATOR = '\n'

const ErrorMessages: React.FC<Props> = memo(({ className, errors = [] }) => {
  const errorClassNames = useMemo(() => classNames('error__messages', className), [className])
  const message = useMemo(() => errors?.join(MESSAGE_SEPARATOR), [errors])

  return message ? <span className={errorClassNames}>{message}</span> : null
})

ErrorMessages.displayName = 'ErrorMessages'

export { ErrorMessages }
