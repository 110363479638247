import { SetAmazonPublisherServicesForm } from 'components/sharedInterfaces/SetAmazonPublisherServicesForm'

export class AmazonDemandSerializer {
  input: SetAmazonPublisherServicesForm

  constructor(input: SetAmazonPublisherServicesForm) {
    this.input = input
  }

  params() {
    return this._prepareBody()
  }

  private _prepareBody() {
    const body: { [key: string]: string | boolean | undefined } = {}

    this._rawParams().forEach((paramName) => (body[paramName] = this.input[paramName]))

    return body
  }

  private _rawParams(): Array<keyof SetAmazonPublisherServicesForm> {
    return ['apsDemandType', 'apsPubId', 'ybApsPubId']
  }
}
