import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldConfig, useField } from 'formik'
import { Grid } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { BidderLogo } from 'components/BidderLogo/BidderLogo'
import { bidderSelectFilterOption } from 'utilities/bidderSelectFilterOption'
import { DisabledParam, ParamForm, ParamFormSection } from 'components/Form/ParamFormSection'
import { Errors, ErrorUtils } from 'utilities/errorUtils'

import ValidateBidderParams from 'gql/mutations/bidders/ValidateBidderParams.gql'

interface Props {
  adUnit?: AdUnit
  field: FieldConfig
  errors: Errors
  hideParams: boolean
  setErrors: (errors: ErrorUtils) => void
  setManualDirty: (value: boolean) => void
  showFullForm?: boolean
  data: Bidder[]
  translations: string
  formId: string
  dirty: boolean
}

export const FormikBidderParamContainer: React.FC<Props> = ({
  adUnit,
  errors,
  hideParams,
  setErrors,
  setManualDirty,
  data,
  translations,
  formId,
  dirty,
  ...other
}) => {
  const [field, , helpers] = useField(other.field.name)
  const { t } = useTranslation('inventory')
  const saveErrorMessage = t(`adUnits.form.inputs.${translations}.saveError`)
  const [isDirty, setIsDirty] = useState(dirty)
  const [newBidder, setNewBidder] = useState<SelectOption | null>(null)
  const changeNewBidderHandler = useCallback(
    (item) => {
      setNewBidder(item)
    },
    [setNewBidder]
  )

  useEffect(() => {
    setManualDirty(isDirty)
  }, [isDirty, setManualDirty])

  const availableBidders = useMemo(() => {
    const selectedBidders = (field.value || [])
      .filter((bidder: AdUnitFormBidder) => !bidder._destroy)
      .map((bidder: AdUnitFormBidder) => bidder['bidderSettingId'])

    return data.filter((bidder: Bidder) => bidder.active && !selectedBidders.includes(bidder.settingId))
  }, [data, field])

  const addBidderParam = useCallback(
    (newBidderParam: AdUnitFormBidder) => {
      setIsDirty(true)
      helpers.setValue(field.value.concat([newBidderParam]))
    },
    [helpers, field]
  )

  const deleteBidderParam = (newBidderParam: BidderParam) => {
    helpers.setValue(
      field.value.filter(
        (bidderParam: BidderParam) => bidderParam['bidderSettingId'] !== newBidderParam['bidderSettingId']
      )
    )
  }

  const updateBidderParam = (newBidderParam: BidderParam) => {
    helpers.setValue(
      field.value.map((bidderParam: BidderParam) =>
        bidderParam['bidderSettingId'] === newBidderParam['bidderSettingId'] ? newBidderParam : bidderParam
      )
    )
  }

  const onSaveHandler = (index: number) => {
    errors.removeError([other.field.name, String(index)], saveErrorMessage)
    setErrors(new ErrorUtils(errors.raw))
  }

  const onDeleteHandler = (index: number) => {
    errors.deleteAt(index, other.field.name)
    setErrors(new ErrorUtils(errors.raw))
  }

  const onBidderParamsAddHandler = (event: React.MouseEvent) => {
    event.preventDefault()
    if (newBidder?.value) {
      const bidder = availableBidders.find((bidder: Bidder) => bidder.settingId === newBidder.value)
      const bidderParam = field.value.find(
        (bidderParam: BidderParam) => bidderParam['bidderSettingId'] === bidder?.settingId
      )
      if (bidderParam) {
        delete bidderParam._destroy
        bidderParam.params = {}
        bidderParam.saved = false
        setIsDirty(true)
        updateBidderParam(bidderParam)
      } else {
        if (bidder) {
          addBidderParam({
            bidderSettingId: bidder.settingId,
            connected: true,
            params: {},
            saved: false
          })
        }
      }
      setNewBidder(null)
    }
  }

  const onBidderParamsDeleteHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    bidderId: string,
    index: number
  ) => {
    event.preventDefault()
    const bidderParam = field.value.find((bidderParam: BidderParam) => bidderParam['bidderSettingId'] === bidderId)
    if (bidderParam) {
      bidderParam.saved = true
      setIsDirty(true)
      bidderParam._destroy = bidderParam.id
      bidderParam.id ? updateBidderParam(bidderParam) : deleteBidderParam(bidderParam)
    }
    onDeleteHandler(index)
  }

  const onBidderParamsChangeHandler = (
    saved: boolean,
    index: number,
    bidderId?: string,
    values?: BidderParams,
    isChecked?: boolean
  ) => {
    const bidderParam = field.value.find((bidderParam: BidderParam) => bidderParam['bidderSettingId'] === bidderId)
    if (bidderParam) {
      if (values) {
        bidderParam.params = values
      }
      bidderParam['ad_unit_id'] = adUnit?.id
      bidderParam.saved = saved
      bidderParam.connected = isChecked
      setIsDirty(true)
      updateBidderParam(bidderParam)
    }
    if (saved) {
      onSaveHandler(index)
    }
  }

  const disableSaveButton = (): boolean => newBidder === null

  const [validateBidderParams] = useMutation(ValidateBidderParams)
  const onValidateBidder = (id: string, params: Params) =>
    validateBidderParams({ variables: { input: { bidderId: id, params } } })

  const getBidderBySettingsId = (bidder: AdUnitFormBidder) => (bid: Bidder) =>
    bid.active && bid.settingId === bidder['bidderSettingId']

  const getItem = (bidder: AdUnitFormBidder): Bidder => {
    const bid = data.find(getBidderBySettingsId(bidder)) || ({} as BidderParams)
    return {
      ...bid,
      _id: bid.settingId as string
    } as Bidder
  }

  const filterByNotRemoved = (bidder: AdUnitFormBidder) => !bidder._destroy
  const filterBySettingsId = (bidder: AdUnitFormBidder) => {
    return data.some(getBidderBySettingsId(bidder))
  }

  const renderParamForm = () => {
    if ((field.value || []).length > 0) {
      return field.value
        .filter(filterByNotRemoved)
        .filter(filterBySettingsId)
        .map((bidder: AdUnitFormBidder, index: number) => (
          <ParamForm
            key={bidder.bidderSettingId}
            dirty={isDirty}
            item={getItem(bidder)}
            isBidder={true}
            isChecked={bidder.connected}
            hideParams={hideParams}
            onChangeHandler={(saved, bidderId, values, isChecked) =>
              onBidderParamsChangeHandler(saved, index, bidderId, values, isChecked)
            }
            onDeleteHandler={(event) => onBidderParamsDeleteHandler(event, bidder.bidderSettingId, index)}
            params={Object.keys(bidder.params).length > 0 ? bidder.params : undefined}
            formErrors={errors
              .errorsFor([other.field.name, String(index)])
              .concat(errors.errorsFor([other.field.name, String(index), 'bidderSettingId']))}
            validateParams={onValidateBidder}
            translation='bidders'
            isSaved={Boolean(bidder.saved)}
            isCheckboxEnabled
          />
        ))
        .concat(
          field.value
            .filter((bidder: AdUnitFormBidder) => !filterBySettingsId(bidder))
            .map((bidder: AdUnitFormBidder, index: number) => (
              <DisabledParam item={bidder.bidder as Bidder} key={index} />
            ))
        )
    } else {
      return <div className='ad_unit-form__bidders--empty'>{t(`adUnits.form.inputs.${translations}.emptyParams`)}</div>
    }
  }
  const availableOptions = availableBidders.map((bidder: Bidder) => ({
    label: (
      <BidderLogo
        fileName={bidder.fileName}
        placeholder={bidder.name}
        bidderName={bidder.bidderCode}
        className='bidders-select-logo'
      />
    ),
    value: bidder.settingId
  }))

  return (
    <Grid item xs={12}>
      {data && (
        <Grid item lg={10} md={12} xs={12}>
          <ParamFormSection
            availableOptions={availableOptions}
            disabledSaveButton={disableSaveButton()}
            filterOption={bidderSelectFilterOption}
            formId={formId}
            hideNewOptions={hideParams}
            onAddHandler={onBidderParamsAddHandler}
            onChangeHandler={changeNewBidderHandler}
            renderParamForm={renderParamForm}
            selectedItem={newBidder}
            translations={`inventory:adUnits.form.inputs.${translations}.addBidder`}
          />
        </Grid>
      )}
    </Grid>
  )
}
