import jwt_decode from 'jwt-decode'

class JwtToken {
  private _token = ''

  public setToken(token: string) {
    this._token = token
  }

  public decodeToken() {
    return jwt_decode(this._token)
  }

  get token() {
    return this._token
  }
}

export default new JwtToken()
