import React from 'react'

import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Modal } from 'components/Modals'
import logoQW from 'images/logos/no-text/quickwrap.svg'
import { SaveAdUnitModalProps } from './types'

import './styles.scss'

export const SaveAdUnitModal = ({
  loading,
  onSave,
  onSaveAndGoToQW,
  setVisible,
  visible
}: SaveAdUnitModalProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { dirty } = useFormikContext()

  const QUICK_WRAP: string = t('adUnits.form.saveModal.quickWrap')

  const disabled: boolean = !dirty || loading

  const primaryButtonProps: Pick<ButtonProps, 'type'> = {
    type: ButtonType.Primary
  }

  return (
    <Modal
      backdrop
      closeButton={true}
      setVisible={setVisible}
      shadow
      title={t('adUnits.form.saveModal.title')}
      visible={visible}
    >
      <div className='SaveAdUnitModal'>
        {t('adUnits.form.saveModal.body')}

        <div>
          {!loading && (
            <Button onClickHandler={() => setVisible(false)} theme={ButtonTheme.BlueReverse} {...primaryButtonProps}>
              {t('common:buttons.cancel')}
            </Button>
          )}

          <Button disabled={disabled} onClickHandler={onSave} theme={ButtonTheme.Blue} {...primaryButtonProps}>
            {loading ? t('common:buttons.saving') : t('common:buttons.save')}
          </Button>

          {!loading && (
            <Button
              disabled={disabled}
              onClickHandler={onSaveAndGoToQW}
              theme={ButtonTheme.Orange}
              {...primaryButtonProps}
            >
              {t('adUnits.form.saveModal.saveAndGoTo')}

              <img alt={QUICK_WRAP} src={logoQW} className='SaveAdUnitModal__logo' />

              {QUICK_WRAP}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}
