import React, { useEffect } from 'react'

import { MultiSelect, Select } from 'components/Form'

interface Props {
  cache: Record<string, unknown>
  filter: AdvancedFilter
  filterConfig?: AdvancedFilterSelectConfiguration
  setCache: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  updateFilter: (id: string, newFilter: Partial<AdvancedFilter>) => void
}

export const AdvancedFilterSelectValue: React.FC<Props> = ({ cache, filter, filterConfig, setCache, updateFilter }) => {
  const options =
    filterConfig
      ?.buildValueOptions(cache)
      .filter((option, index, array) => array.findIndex(({ value }) => value === option.value) === index) ?? []

  useEffect(() => {
    if (!filterConfig?.cacheConfig?.checkIfAlreadyCached(cache)) {
      filterConfig?.cacheConfig?.addToCache().then((newCache) => {
        setCache((cache) => ({ ...cache, ...newCache }))
      })
    }
  }, [filter.by])

  const onMultiSelectChangeHandler = (selected: SelectOption) =>
    updateFilter(filter.id, {
      values: (selected as unknown as SelectOption<string>[])?.map(({ value }) => value)
    })

  const onSelectChangeHandler = (selected: SelectOption) =>
    updateFilter(filter.id, { values: [(selected as SelectOption<string>)?.value] })

  return (
    <div>
      {filterConfig?.isMulti ? (
        <MultiSelect
          className='advanced-filter__value'
          isClearable={false}
          isSearchable={filterConfig?.isSearchable ?? false}
          name=''
          onChangeHandler={onMultiSelectChangeHandler}
          options={options}
          selectedItem={options.filter(({ value }) => filter.values.includes(value as string))}
        />
      ) : (
        <Select
          className='advanced-filter__value'
          isSearchable={filterConfig?.isSearchable ?? false}
          name='filterValue'
          onChangeHandler={onSelectChangeHandler}
          options={options}
          selectedItem={options.find(({ value }) => filter.values.includes(value as string))}
        />
      )}
    </div>
  )
}
