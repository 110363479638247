import { gql } from '@apollo/client'

import { MUTATION_STATE } from 'webapp/gql/fragments/common'

const PAYLOAD_TYPE = 'UpdateProductConnectionPayload'

export const UPDATE_PRODUCT_CONNECTION = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}

  mutation UpdateProductConnection($input: UpdateProductConnectionInput!) {
    updateProductConnection(input: $input) {
      productConnection {
        id
      }
      
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
