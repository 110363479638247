import React from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from 'components/Form'

interface Props {
  config: AdvancedFilterConfiguration[]
  filter: AdvancedFilter
  filters: AdvancedFilter[]
  updateFilter: (id: string, newFilter: Partial<AdvancedFilter>) => void
}

export const AdvancedFilterBy: React.FC<Props> = ({ config, filter, filters, updateFilter }) => {
  const { t } = useTranslation('common')

  const allOptions = config.map(({ label, name }) => ({ label, value: name }))
  const filteredOptions = allOptions.filter(({ value }) => !filters.some(({ by }) => by === value))

  const onChangeHandler = (selected: SelectOption) => {
    const by = (selected as SelectOption<string>)?.value
    const filterConfig = config.find((filterConfig) => filterConfig.name === by) as AdvancedFilterConfiguration
    updateFilter(filter.id!, {
      by,
      operator: filterConfig.operators?.[0] ?? 'is',
      values: []
    })
  }

  return (
    <div>
      <Select
        className='advanced-filter__by'
        name='filterName'
        onChangeHandler={onChangeHandler}
        options={filteredOptions}
        placeholder={t('advancedFilter.inputs.name.placeholder')}
        selectedItem={allOptions.find(({ value }) => value === filter.by)}
      />
    </div>
  )
}
