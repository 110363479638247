import React from 'react'
import { useTranslation } from 'react-i18next'

import { CommentItem } from './CommentItem'

interface Props {
  comments?: Comment[]
  showCount?: number
}

export const CommentList: React.FC<Props> = ({ comments, showCount }) => {
  const { t } = useTranslation('inventory')

  let commentLabel
  if (comments) {
    commentLabel = comments.length ? t('domains.edit.comments.list.label') : t('domains.edit.comments.list.emptyLabel')
  }

  return (
    <>
      <div className='comment-list-label'>{commentLabel}</div>
      {!!comments?.length && (
        <div className='comment-list'>
          {comments.slice(0, showCount).map((comment) => (
            <CommentItem key={comment.id} comment={comment} />
          ))}
        </div>
      )}
    </>
  )
}
