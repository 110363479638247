import React, { useMemo } from 'react'
import classNames from 'classnames'

interface Props {
  label: string
  className?: string
}

export const NavigationButton: React.FC<Props> = ({ label, className }) => {
  const classString = useMemo(() => classNames('CalendarMonth__nav', className), [className])

  return <div className={classString}>{label}</div>
}
