import i18n from 'webapp/i18n'

import { BidderPriorityTypes } from 'webapp/constants/BidderPriorityTypes'

export const BidderPriorityOptions: SelectOption[] = [
  {
    value: BidderPriorityTypes.primary,
    label: i18n.t('setup:bidders.form.priority.values.primary')
  },
  {
    value: BidderPriorityTypes.secondary,
    label: i18n.t('setup:bidders.form.priority.values.secondary')
  }
]
