import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { isProductConnectionActive } from 'utilities/products/activation'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { useCurrentProductConnection } from 'utilities/products/current'

export const useHasNoProductAccess = () => {
  const { t } = useTranslation()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const currentProductConnection = useCurrentProductConnection()
  const hasNoProductAccess = currentProductConnection && !isProductConnectionActive(currentProductConnection)

  useEffect(() => {
    if (hasNoProductAccess) {
      createNotifier(t('products:authorization.noAccess'), NotificationType.ERROR)
    }
  }, [hasNoProductAccess])

  return hasNoProductAccess
}
