import React, { Dispatch, SetStateAction } from 'react'
import { usePagination } from '@material-ui/lab'

import { filterStore } from 'utilities/filters/filterStore'
import { generateButtons } from './generateButtons'

interface Props {
  collectionName: string
  pageIndex: number
  pageSize: number
  gotoPage?: (page: number) => void
  setPageIndex?: Dispatch<SetStateAction<number>>
  toggleAllRowsExpanded: (toggleAll: boolean) => void
}

export const Pagination: React.FC<Props> = ({
  collectionName,
  pageIndex,
  pageSize,
  gotoPage,
  setPageIndex,
  toggleAllRowsExpanded
}) => {
  const handleChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    filterStore.setFilter(collectionName, 'page', String(page))

    toggleAllRowsExpanded(false)
    gotoPage && gotoPage(page - 1)
    setPageIndex && setPageIndex(page - 1)
  }

  const { items } = usePagination({
    page: pageIndex + 1,
    count: pageSize,
    onChange: handleChange
  })

  return (
    <nav className='pagination'>
      <ul className='pagination__list'>
        {items.map(({ page, type, selected, ...item }, index) => {
          return <li key={index}>{generateButtons(item, page, type, selected)}</li>
        })}
      </ul>
    </nav>
  )
}
