import { gql } from '@apollo/client'
import { BASE_OSS_PAGE_FRAGMENT } from '../pages/BaseOssPageFragment'

export const BASE_OSS_AD_UNIT_WITH_PAGES_FRAMGENT = gql`
  ${BASE_OSS_PAGE_FRAGMENT}

  fragment BaseOssAdUnitWithPagesFragment on OssAdUnit {
    elementId
    id

    ossPages {
      nodes {
        ...BaseOssPageFragment
      }
    }
  }
`
