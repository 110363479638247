import React from 'react'

import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { DateCell } from 'components/Table'
import { CroppedCell } from 'components/Table/CroppedCell/CroppedCell'
import { ActionsCell } from 'components/shared/Inventory/AdUnits/Table/ActionsCell'
import { AdUnitRowData } from 'components/shared/Inventory/AdUnits/Table/AdUnitRowData'
import { MediaTypesCell } from 'components/shared/Inventory/AdUnits/Table/MediaTypesCell'
import YieldbirdLogo from 'images/logos/no-text/oss.svg'

export const columnsConfig = (): Array<Column<AdUnitRowData>> => [
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.adUnitName'),
    accessor: 'ossAdUnit.adUnitName' as 'ossAdUnit',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.domain'),
    accessor: 'domainName'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.domainVersion'),
    accessor: 'domainVersionName',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.elementId'),
    accessor: 'ossAdUnit.elementId' as 'ossAdUnit'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.adUnitPath'),
    accessor: 'ossAdUnit.adUnitPath' as 'ossAdUnit'
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.pagesCount'),
    accessor: 'pagesCount',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.biddersCount'),
    accessor: 'biddersCount',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.ownBidders'),
    accessor: 'ownBidders',
    disableSortBy: true,
    Cell: ({ value }) => <CroppedCell textElements={value} />
  },
  {
    Header: <YbBiddersHeader />,
    accessor: 'ybBidders',
    disableSortBy: true,
    Cell: ({ value }) => <CroppedCell textElements={value} />
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.types'),
    accessor: 'types',
    disableSortBy: true,
    Cell: ({ value }) => <MediaTypesCell value={value} />
  },
  {
    Header: () => i18n.t('inventory:adUnits.list.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    Header: () => i18n.t('table.header.actions'),
    id: 'actions',
    Cell: ({ row }: CellProps<AdUnitRowData>) => <ActionsCell row={row} />,
    disableSortBy: true
  }
]

const YbBiddersHeader = (): JSX.Element => (
  <>
    <img src={YieldbirdLogo} style={{ height: 13, paddingRight: 8 }} />
    {i18n.t('inventory:adUnits.list.tableHeader.ybBidders')}
  </>
)
