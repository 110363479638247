import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { UserDetails } from 'components/Header/UserWidget/UserWidgetDropdown/UserDetails'
import { UserLinks } from 'components/Header/UserWidget/UserWidgetDropdown/UserLinks'
import { UserNotification } from 'components/Header/UserWidget/UserWidgetDropdown/UserNotification'

interface Props {
  user: User
  onLogoutHandler: () => void
  setIsDropdownOpen: (value: React.SetStateAction<boolean>) => void
}

export const UserWidegetDropdown: React.FC<Props> = ({ user, onLogoutHandler, setIsDropdownOpen }) => {
  const { t } = useTranslation()

  return (
    <div className='user-widget__dropdown-content-wrapper' id='user-dropdown-content'>
      <div className='user-widget__dropdown-content' onClick={() => setIsDropdownOpen((prev) => !prev)}>
        <UserDetails user={user} />
        <UserLinks />
        <Button theme={ButtonTheme.BlueReverse} type={ButtonType.Secondary} onClickHandler={onLogoutHandler}>
          {t('buttons.logOut')}
        </Button>
      </div>
      {user?.workspace && !user?.workspace?.gamConnected && <UserNotification />}
    </div>
  )
}
