import React, { useMemo } from 'react'
import classNames from 'classnames'

interface Props {
  disabled?: boolean
  hasError?: boolean
  focused?: boolean
  getRootProps: () => Record<string, unknown>
}

export const InputWrapper: React.FC<Props> = ({ children, disabled, hasError, focused, getRootProps }) => {
  const classString = useMemo(() => {
    return classNames('tag-input__wrapper', {
      'tag-input__wrapper--disabled': disabled,
      'tag-input__wrapper--error': hasError,
      'tag-input__wrapper--focused': focused
    })
  }, [disabled, hasError, focused])

  return (
    <div {...getRootProps()}>
      <div className={classString}>{children}</div>
    </div>
  )
}
