import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ApolloError, OperationVariables, QueryLazyOptions, useLazyQuery } from '@apollo/client'

import { GamConnectionStatus } from 'webapp/constants/GamConnectionStatus'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'

import ConnectedNetworkQuery from 'gql/queries/workspaces/ConnectedNetwork.gql'
import ConnectedNetworksQuery from 'gql/queries/workspaces/ConnectedNetworks.gql'

interface ConnectedNetworksTableContextType {
  connectedNetworkQuery: (variables: QueryLazyOptions<OperationVariables> | undefined) => void
  connectedNetworksQuery: () => void
  data: ConnectedNetwork[] | null
  isRunInProgress: boolean
  setIsRetrying: React.Dispatch<React.SetStateAction<boolean>>
}

const defaults = {
  connectedNetworkQuery: () => {},
  connectedNetworksQuery: () => {},
  data: null,
  isRunInProgress: false,
  setIsRetrying: () => {}
}

export const ConnectedNetworksTableContext = createContext<ConnectedNetworksTableContextType>(defaults)

export const ConnectedNetworksTableContextProvider: React.FC = ({ children }) => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [connections, setConnections] = useState<ConnectedNetwork[] | null>(null)
  const statusRef = useRef()

  const [isRetrying, setIsRetrying] = useState(false)

  const [connectedNetworksQuery] = useLazyQuery(ConnectedNetworksQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ gamConnections }) => setConnections(gamConnections.nodes)
  })

  useEffect(() => {
    connectedNetworksQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [connectedNetworkQuery, { stopPolling }] = useLazyQuery(ConnectedNetworkQuery, {
    pollInterval: 3000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ gamConnection }) => getConnectedNetworkStatusSuccessHandler(gamConnection),
    onError: (error) => getBulkUploadJobStatusErrorHandler(error)
  })

  const getConnectedNetworkStatusSuccessHandler = (gamConnection: ConnectedNetworkStatus) => {
    const { bannerStatus, nativeStatus } = gamConnection

    const endStates = ['finished', 'failed']
    const stopPollingCondition =
      (endStates.includes(bannerStatus) && nativeStatus === 'passive') ||
      (endStates.includes(nativeStatus) && endStates.includes(bannerStatus))

    if (statusRef.current !== bannerStatus) {
      connectedNetworksQuery()
    }

    if (stopPollingCondition) stopPolling?.()
  }

  const getBulkUploadJobStatusErrorHandler = (error: ApolloError) => {
    stopPolling && stopPolling()
    createNotifier(error.message, NotificationType.ERROR, 10)
  }

  const hasAnActiveConnection = useMemo(
    () =>
      connections?.some(
        ({ bannerStatus, nativeStatus }) =>
          bannerStatus === GamConnectionStatus.InProgress || nativeStatus === GamConnectionStatus.InProgress
      ) ?? false,
    [connections]
  )

  useEffect(() => {
    if (!hasAnActiveConnection) {
      setIsRetrying(false)
    }
  }, [hasAnActiveConnection, setIsRetrying])

  return (
    <ConnectedNetworksTableContext.Provider
      value={{
        connectedNetworkQuery,
        connectedNetworksQuery,
        data: connections,
        isRunInProgress: hasAnActiveConnection || isRetrying,
        setIsRetrying
      }}
    >
      {children}
    </ConnectedNetworksTableContext.Provider>
  )
}
