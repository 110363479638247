export const NewVersionFormFactory = (newVersionData?: SelectOption): NewVersionData => {
  if (newVersionData === undefined) {
    return {
      source: {
        value: '',
        label: ''
      },
      versionName: ''
    }
  }
  return {
    source: {
      value: newVersionData.value,
      label: newVersionData.label
    },
    versionName: ''
  }
}
