import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { columnsConfig } from './columnsConfig'
import { Modal } from 'components/Modals'
import { Table } from 'components/Table'

type ApplicableDomainsModalType = {
  disabled: boolean
  domains: Domain[]
  domainManagementModalVisible: boolean
  setDomainManagementModalVisible: (value: boolean) => void
  setPrebidAnalyticsAvailabilityHandler: (prebidManagerModuleEnabled: boolean, domainIds?: string[]) => void
}

const mapDomains = (domains: Domain[]): PrebidAnalyticsDomain[] =>
  domains.map((domain) => ({
    id: domain.id,
    name: domain.name,
    prebidManagerEnabled: domain.activeVersion.domainConfiguration.prebidManagerEnabled
  }))

export const ApplicableDomainsModal = ({
  disabled,
  domains,
  domainManagementModalVisible,
  setDomainManagementModalVisible,
  setPrebidAnalyticsAvailabilityHandler
}: ApplicableDomainsModalType): JSX.Element => {
  const { t } = useTranslation('performance')
  const [mappedDomains, setMappedDomains] = useState(mapDomains(domains))

  const cancelHandler = () => {
    setDomainManagementModalVisible(false)
  }

  const confirmWithAllDomainsHandler = (addAllDomains?: boolean) => {
    const domainIds = mappedDomains
      .filter((domain) => addAllDomains || domain.prebidManagerEnabled)
      .map((domain) => domain.id)

    setPrebidAnalyticsAvailabilityHandler(true, domainIds)
    setDomainManagementModalVisible(false)
  }

  const toggleDomainsHandler = (id: string) => {
    setMappedDomains(
      mappedDomains.map((domain) =>
        domain.id === id ? Object.assign(domain, { prebidManagerEnabled: !domain.prebidManagerEnabled }) : domain
      )
    )
  }

  return (
    <Modal
      visible={domainManagementModalVisible}
      setVisible={setDomainManagementModalVisible}
      title={t('prebidAnalytics.infoBar.applicableDomainsModal.title')}
    >
      <div className='prebid-analytics-manage-domains'>
        <Table<PrebidAnalyticsDomain>
          data={mappedDomains}
          columns={columnsConfig(toggleDomainsHandler) as Column[]}
          disablePagination={true}
          collectionName={CollectionNames.PrebidManagerDomains}
        />
      </div>
      <>
        <Button
          className='confirmation__modal__button'
          disabled={disabled}
          onClickHandler={cancelHandler}
          theme={ButtonTheme.BlueReverse}
          type={ButtonType.Primary}
        >
          {t('prebidAnalytics.infoBar.applicableDomainsModal.cancel')}
        </Button>
        <Button
          className='confirmation__modal__button'
          disabled={disabled}
          onClickHandler={() => confirmWithAllDomainsHandler()}
          theme={ButtonTheme.Blue}
          type={ButtonType.Primary}
        >
          {t('prebidAnalytics.infoBar.applicableDomainsModal.save')}
        </Button>
        <Button
          className='confirmation__modal__button'
          disabled={disabled}
          onClickHandler={() => confirmWithAllDomainsHandler(true)}
          theme={ButtonTheme.White}
          type={ButtonType.Primary}
        >
          {t('prebidAnalytics.infoBar.applicableDomainsModal.activateAll')}
        </Button>
      </>
    </Modal>
  )
}
