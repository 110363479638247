import { gql } from '@apollo/client'
import { MUTATION_STATE_SIMPLE } from 'webapp/gql/fragments/common'

const PAYLOAD_TYPE = 'DestroyOssAdUnitPayload'

export const DESTROY_OSS_AD_UNIT = gql`
  ${MUTATION_STATE_SIMPLE(PAYLOAD_TYPE)}
  mutation DestroyOssAdUnit($input: DestroyOssAdUnitInput!) {
    destroyOssAdUnit(input: $input) {
      ossAdUnit {
        id
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
