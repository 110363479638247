import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { PrebidModuleKind } from 'webapp/constants/PrebidModuleKind'
import { usePrebidModules } from 'utilities/prebid/usePrebidModules'
import { UserIdsForm } from 'components/quickwrap/Setup/Modules/UserIdsForm'
import { UserIdsModuleFactory } from '../formSchema'
import { useSubmitPrebidModuleParams } from 'containers/quickwrap/Setup/ModulesContainer/useSubmitPrebidModuleParams'
import { useWorkspacePrebidModules } from 'utilities/prebid/useWorkspacePrebidModules'

export const UserIdsFormContainer: React.FC = () => {
  const { t } = useTranslation('setup')

  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const prebidModules = usePrebidModules(PrebidModuleKind.UserId)
  const { loading, refetch, workspacePrebidModules } = useWorkspacePrebidModules(PrebidModuleKind.UserId)

  const initialValues = useMemo(() => UserIdsModuleFactory(workspacePrebidModules), [workspacePrebidModules])

  const { onSubmit, submitting } = useSubmitPrebidModuleParams(setIsDirty, refetch, 'userId')

  return (
    <Box className='user-ids prebid-modules' isExpandable={true}>
      <BoxHeader title={t('userId.form.title')} subtitle={t('userId.form.subtitle')} />

      <BoxBody>
        {workspacePrebidModules && prebidModules && (
          <UserIdsForm
            errors={errors}
            initialValues={initialValues}
            isDirty={isDirty}
            loading={loading}
            onSubmit={onSubmit}
            prebidModules={prebidModules}
            setErrors={setErrors}
            setIsDirty={setIsDirty}
            submitting={submitting}
          />
        )}
      </BoxBody>
    </Box>
  )
}
