import React from 'react'
import { Grid } from '@material-ui/core'
import { Formik, Field, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'

import { AdFormat } from './AdFormat'
import { BiddersRecommendationForm } from 'components/sharedInterfaces/BiddersRecommendationForm'
import { ButtonType, ButtonTheme } from 'components/Button'
import { CollectionField } from 'components/FormikComponents'
import { ErrorMessages } from 'components/Form'
import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput, FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'
import { LoadingButton } from 'components/Button/LoadingButton'
import { RecommendBiddersFormFactory } from './formSchema'
import { restrictedContentOptions } from './restrictedContentOptions'
import { Section } from 'components/Form'
import { StatusForm } from 'components/Form'
import { VisitsPerGeoRow } from './VisitsPerGeoRow'

interface Props {
  onSubmitHandler: (values: BiddersRecommendationForm, actions: FormikHelpers<BiddersRecommendationForm>) => void
  errors: Errors
  counter: number
  isSubmitting?: boolean
}

export const RecommendBiddersForm: React.FC<Props> = ({ onSubmitHandler, errors, counter, isSubmitting }) => {
  const { t } = useTranslation()

  return (
    <div className='recommend-bidders-form'>
      <Formik initialValues={RecommendBiddersFormFactory()} onSubmit={onSubmitHandler} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='recommend-bidders' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={6}>
                <Field
                  id='recommend-bidders-domain'
                  name='domain'
                  type='text'
                  placeholder={t('setup:recommendBidders.form.domain.placeholder')}
                  label={t('setup:recommendBidders.form.domain.label')}
                  tooltipContent={t('setup:recommendBidders.form.domain.tooltip')}
                  component={FormikInput}
                  errors={errors.errorsFor('domain')}
                  labelStyle='bold'
                />
                <Field
                  id='recommend-bidders-impressions'
                  name='impressions'
                  type='number'
                  placeholder={t('setup:recommendBidders.form.impressions.placeholder')}
                  label={t('setup:recommendBidders.form.impressions.label')}
                  tooltipContent={t('setup:recommendBidders.form.impressions.tooltip')}
                  component={FormikInput}
                  errors={errors.errorsFor('impressions')}
                  labelStyle='bold'
                />
              </Grid>
            </Grid>

            <Section title={t('setup:recommendBidders.form.visitsPerGeo.title')}>
              <CollectionField name='visitsPerGeo' emptyElementTemplate={{ geo: '', traffic: '' }}>
                {({ index }: { index: number }) => <VisitsPerGeoRow index={index} errors={errors} />}
                <ErrorMessages errors={errors.errorsFor('visitsPerGeo')} />
              </CollectionField>
            </Section>

            <div className='ad-formats'>
              <Field
                id='recommend-bidders-ad-format'
                name='adFormat'
                errors={errors.errorsFor('adFormat')}
                label={t('setup:recommendBidders.form.adFormat.label')}
                component={AdFormat}
                labelStyle='bold'
              />
            </div>

            <Grid container spacing={4}>
              <Grid item lg={4} xs={6}>
                <Field
                  id='recommend-bidders-restricted-content'
                  options={restrictedContentOptions}
                  name='restrictedContent'
                  key={counter}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  placeholder={t('setup:recommendBidders.form.restrictedContent.placeholder')}
                  label={t('setup:recommendBidders.form.restrictedContent.label')}
                  tooltipContent={t('setup:recommendBidders.form.restrictedContent.tooltip')}
                  component={FormikSelect}
                  errors={errors.errorsFor('restrictedContent')}
                  labelStyle='bold'
                />
              </Grid>
            </Grid>

            <div className='recommend-bidders-form__save-btn'>
              <LoadingButton
                type={ButtonType.Primary}
                theme={ButtonTheme.Orange}
                buttonType='submit'
                loading={isSubmitting}
                disabled={isSubmitting || !dirty}
              >
                {t('buttons.sendRequest')}
              </LoadingButton>
            </div>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
