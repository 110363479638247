import React from 'react'

import { useHistory } from 'react-router-dom'
import { Column, Row } from 'react-table'

import { AdUnitRowData } from 'components/shared/Inventory/AdUnits/Table/AdUnitRowData'
import { QueryTable } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { useTableState } from 'webapp/hooks/useTableState'
import { columnsConfig } from './columnsConfig'

import AD_UNITS_QUERY from 'gql/queries/adUnits/AdUnits.gql'

interface Props {
  domainVersionId?: string
  shouldRefetchData?: boolean
  readOnly?: boolean
}

export const AdUnitsTable: React.FC<Props> = ({ domainVersionId, shouldRefetchData, readOnly }) => {
  const hiddenColumns = domainVersionId ? ['domainName', 'domainVersionName'] : undefined
  const variables = domainVersionId ? { domainVersionId } : undefined

  const history = useHistory()
  const { pageIndex, setPageIndex } = useTableState()

  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    const { id, domainVersionId } = row.original as unknown as AdUnitRowData

    history.push(routesBuilder.quickwrap.inventory.adUnit.edit(id, domainVersionId), {
      pageIndex: readOnly ? 0 : pageIndex
    })
  }

  return (
    <QueryTable<AdUnitRowData>
      className='ad-units-table'
      collectionName={CollectionNames.AdUnits}
      columnsConfig={columnsConfig() as Column[]}
      dataQuery={AD_UNITS_QUERY}
      hiddenColumns={hiddenColumns}
      mapper={(node: AdUnit) => new AdUnitRowData(node)}
      onRowClick={openEditingPage}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      shouldRefetchData={shouldRefetchData}
      variables={variables}
    />
  )
}
