import React, { useEffect } from 'react'
import { Form } from 'formik'

import { formStatus } from 'utilities/FormStatus'

interface Props {
  name: string
  dirty: boolean
  Container?: React.FC
}

/**
 * Stores info about unsaved changes. Used when leaving site to display prompt
 * @param name Name of this form
 * @param dirty Whether it has unsaved changes
 */
export const StatusForm: React.FC<Props> = ({ name, dirty, Container = Form, children }) => {
  useEffect(() => {
    formStatus.setStatus(name, dirty)

    return function resetFormStatus() {
      formStatus.reset(name)
    }
  }, [name, dirty])

  return <Container>{children}</Container>
}
