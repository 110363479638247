import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import glassImg from 'images/magnifying-glass.svg'

import { filterStore } from 'utilities/filters/filterStore'
import { Input } from 'components/Form'

interface Props {
  collectionName: string
  searchPlaceholder: string
}

const inputTimeout = 500

export const GlobalFilter: React.FC<Props> = ({ collectionName, searchPlaceholder }) => {
  const history = useHistory<History>()
  const [value, setValue] = useState(filterStore.getFilters(collectionName).search ?? '')
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null)
  const searchRef = useRef<HTMLInputElement>(null)
  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }

    if (value !== undefined) {
      searchRef.current?.focus()
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null
        filterStore.setFilter(collectionName, 'search', value.trim())
      }, inputTimeout)
    }
  }, [value, history, collectionName])

  const callback = useCallback((value: string) => setValue(value), [setValue])

  return (
    <Input
      id='search-bar'
      name='search-bar'
      type='text'
      className='search-bar__input'
      containerClassName='search-bar'
      ref={searchRef}
      placeholder={searchPlaceholder}
      value={value || ''}
      onChangeHandler={callback}
    >
      <img className='search-bar__icon' src={glassImg} />
    </Input>
  )
}
