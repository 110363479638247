import { gql } from '@apollo/client'
import { CUSTOM_CODE_FRAGMENT } from 'gql/fragments/customCode/CustomCodeFragment'
import { MUTATION_STATE } from 'gql/fragments/common'

const PAYLOAD_TYPE = 'UpdateCustomCodeTemplatePayload'

export const UPDATE_CUSTOM_CODE_TEMPLATE = gql`
  ${CUSTOM_CODE_FRAGMENT}
  ${MUTATION_STATE(PAYLOAD_TYPE)}

  mutation UpdateCustomCodeTemplate($input: UpdateCustomCodeTemplateInput!) {
    updateCustomCodeTemplate(input: $input) {
      customCode {
        ...CustomCodeFragment
      }
    
      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
