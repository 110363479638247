import { gql } from '@apollo/client'

export const PAGE_FOR_EDIT = gql`
  query PageForEdit($pageId: ID, $domainVersionId: ID, $path: String) {
    page(pageId: $pageId, domainVersionId: $domainVersionId, path: $path) {
      domain {
        id
        workspaceId
      }
      domainVersion {
        id
        name
      }
      id
      ossPage {
        pageName
        path
      }
      prebidTimeout
      prebidTimeoutEnabled
    }
  }
`
