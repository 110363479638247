import { gql } from '@apollo/client'
import { CUSTOM_CODE_FRAGMENT } from 'gql/fragments/customCode/CustomCodeFragment'
import { OSS_CONSENT_MANAGEMENT_FRAGMENT } from 'gql/fragments/ossConsentManagement/OssConsentManagementFragment'

export const DOMAIN_FRAGMENT = gql`
  ${CUSTOM_CODE_FRAGMENT}
  ${OSS_CONSENT_MANAGEMENT_FRAGMENT}

  fragment DomainFragment on Domain {
    activeVersion {
      id
    }
    amazonSid
    currency
    customCode {
      ...CustomCodeFragment
    }
    customCodeEnabled
    id
    name
    ossConsentManagement {
      ...OssConsentManagementFragment
    }
    ossConsentManagementEnabled
    workspace {
      packages {
        active
        name
      }
    }
    workspaceId
  }
`
