import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { ConfirmationModal, Modal } from 'components/Modals'
import {
  DeleteModalProps,
  DestroyDomainVersionData,
  DestroyDomainVersionVars,
  UpdateDomainVersionData,
  UpdateDomainVersionVars
} from '../types'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { getDefaultOption, optionsForDeleteModal } from './service'
import { routesBuilder } from 'utilities/routesBuilder'
import { Select } from 'components/Form'
import { VersionedDomainContext } from 'webapp/context/VersionedDomainContext'

import DESTROY_DOMAIN_VERSION from 'gql/mutations/domainVersions/DestroyDomainVersion.gql'
import UPDATE_DOMAIN_VERSION from 'gql/mutations/domainVersions/UpdateDomainVersion.gql'

export const DeleteModal: React.FC<DeleteModalProps> = ({ domainVersion, setModalVisible, modalVisible }) => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { domainVersions, refreshDomainVersionQuery } = useContext(VersionedDomainContext)
  const [errors, setErrors] = useState<string[]>([])
  const history = useHistory()
  const { domainVersionId } = useParams<{ domainVersionId: string }>()

  const options: SelectOption[] = optionsForDeleteModal(domainVersion.id, domainVersions)
  const defaultOption: SelectOption | null = getDefaultOption(options)
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(defaultOption)

  const { domainId } = domainVersion

  useEffect(() => {
    setErrors([])
  }, [modalVisible, selectedOption])

  const errorNotifier = useCallback(() => {
    setModalVisible(false)

    createNotifier(
      t('domains.versions.delete.modal.notification.error', { domainVersionName: domainVersion.name }),
      NotificationType.ERROR
    )
  }, [domainVersion, setModalVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  const [updateDomainVersion] = useMutation<UpdateDomainVersionData, { input: UpdateDomainVersionVars }>(
    UPDATE_DOMAIN_VERSION,
    {
      onCompleted: ({ updateDomainVersion: { domainVersion, errors } }): void => {
        if (errors.length > 0) {
          errorNotifier()
        } else if (domainVersion) {
          destroyDomainVersion(deletePayload()).then((response): void => {
            if (response.data?.destroyDomainVersion.domainVersion && selectedOption) {
              window.location.href = routesBuilder.quickwrap.inventory.domains.edit(
                domainId,
                selectedOption.value as string
              )
            } else {
              errorNotifier()
            }
          })
        }
      }
    }
  )

  const onConfirmDeleteWithReplacement = () => {
    if (!selectedOption) {
      setErrors([t('domains.versions.delete.modal.errors.choose_version')])
      return
    }

    updateDomainVersion(
      nestGqlInput({
        domainVersionId: selectedOption.value as string,
        active: true,
        name: selectedOption.label as string
      })
    )
  }

  const [destroyDomainVersion] = useMutation<DestroyDomainVersionData, DestroyDomainVersionVars>(DESTROY_DOMAIN_VERSION)
  const deletePayload = () => nestGqlInput({ domainVersionId: domainVersion.id })

  const onDelete = () => {
    destroyDomainVersion(deletePayload()).then((response) => {
      if (response.data) {
        const { domainVersion: deletedDomainVersion, errors } = response.data?.destroyDomainVersion

        if (errors.length > 0) {
          errorNotifier()
        } else {
          if (deletedDomainVersion.id === domainVersionId) {
            const newCurrentVersion = domainVersions.filter(
              (version) => version.active && version.id !== domainVersionId
            )[0]

            history.push(routesBuilder.quickwrap.inventory.domains.edit(domainId, newCurrentVersion?.id))
          }

          refreshDomainVersionQuery && refreshDomainVersionQuery()
          setModalVisible(false)

          createNotifier(
            t('domains.versions.delete.modal.notification.success', { domainVersionName: domainVersion.name }),
            NotificationType.SUCCESS
          )
        }
      }
    })
  }

  const DeleteModal = () => (
    <Modal
      title={t('domains.versions.delete.modal.title')}
      setVisible={setModalVisible}
      visible={modalVisible}
      shadow={true}
      backdrop={true}
    >
      <div className='confirmation__modal-container'>
        <p>{t('domains.versions.delete.modal.contentBefore')}</p>

        <Select
          id='replacement-domain-version-select'
          name='replacement-domain-version-select'
          errors={errors}
          options={options}
          onChangeHandler={(el) => setSelectedOption(el as SelectOption)}
          labelStyle='bold'
          placeholder={t('domains.versions.delete.modal.placeholder')}
          selectedItem={selectedOption}
          isSearchable={false}
        />

        <p className='mt-4'>
          {t('domains.versions.delete.modal.contentAfter', { domainVersionName: domainVersion.name })}
        </p>

        <footer>
          <Button
            theme={ButtonTheme.BlueReverse}
            type={ButtonType.Primary}
            onClickHandler={() => setModalVisible(false)}
            className='confirmation__modal__button'
          >
            {t('common:buttons.cancel')}
          </Button>

          <Button
            theme={ButtonTheme.Blue}
            type={ButtonType.Primary}
            onClickHandler={onConfirmDeleteWithReplacement}
            className='confirmation__modal__button'
          >
            {t('common:buttons.delete')}
          </Button>
        </footer>
      </div>
    </Modal>
  )

  return domainVersion.active ? (
    <DeleteModal />
  ) : (
    <ConfirmationModal
      visible={modalVisible}
      setVisible={setModalVisible}
      content={t('domains.versions.delete.modal.content', { domainVersionName: domainVersion.name })}
      confirmButtonText={t('common:buttons.delete')}
      cancelButtonText={t('common:buttons.cancel')}
      onConfirmHandler={onDelete}
    />
  )
}
