import React, { useContext, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { DeviceList } from 'components/Form/DeviceList'
import { NotificationType } from 'components/ToastNotifier'
import { Product } from 'constants/Product'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { useCurrentProduct } from 'utilities/products/current'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { PrebidDeviceRow } from './PrebidDeviceRow'
import { PrebidDeviceOption, PrebidDevicesFormBuilder, PrebidDevicesFormikValues } from './PrebidDevicesFormBuilder'

import UPSERT_PREBID_DEVICES from 'gql/mutations/prebidDevices/UpsertPrebidDevices.gql'
import PREBID_DEVICES from 'gql/queries/prebidDevices/PrebidDevicesQuery.gql'
import WORKSPACE_DEVICES from 'gql/queries/workspaceDevices/WorkspaceDevices.gql'

interface PrebidDevicesContainerProps {
  prebidDeviceableId: Page['id'] | DomainVersion['id']
  workspaceId: Workspace['id']
}

export const PrebidDevicesContainer = ({
  prebidDeviceableId,
  workspaceId
}: PrebidDevicesContainerProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const product: Product = useCurrentProduct()

  const [prebidDevices, setPrebidDevices] = useState<PrebidDevice[]>()
  const [workspaceDevices, setWorkspaceDevices] = useState<WorkspaceDevice[]>()
  const [redundantDevices, setRedundantDevices] = useState<PrebidDeviceOption[]>([])

  useQuery(WORKSPACE_DEVICES, {
    fetchPolicy: 'network-only',
    variables: { workspaceId },
    onCompleted: ({ workspaceDevices }) => {
      setWorkspaceDevices(workspaceDevices.nodes)
    }
  })

  useQuery(PREBID_DEVICES, {
    fetchPolicy: 'network-only',
    variables: { resourceId: prebidDeviceableId },
    onCompleted: ({ prebidDevices }) => {
      setPrebidDevices(prebidDevices.nodes)
    }
  })

  const [upsertPrebidDevices, { loading: isSubmitting }] = useMutation(UPSERT_PREBID_DEVICES, {
    onCompleted: ({ upsertPrebidDevices: { errors, prebidDevices } }) => {
      setErrors(new ErrorUtils(errors))
      if (errors.length) {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      } else {
        setPrebidDevices(prebidDevices)
        setRedundantDevices([])
        createNotifier(t('domains.edit.prebidDevices.success'), NotificationType.SUCCESS)
      }
    },
    onError: () => {
      createNotifier(t('domains.edit.prebidDevices.error'), NotificationType.ERROR)
    }
  })

  const onSubmit = (values: PrebidDevicesFormikValues) => {
    upsertPrebidDevices(nestGqlInput(PrebidDevicesFormBuilder.submitForm(values, redundantDevices, prebidDeviceableId)))
  }

  const onDelete = (device: PrebidDeviceOption) => {
    if (device.id) {
      setRedundantDevices((prevState) =>
        prevState.concat({
          ...device,
          _destroy: true
        })
      )
    }
  }

  return (
    <Box className='prebid-devices' isExpandable={true}>
      <BoxHeader title={t('domains.edit.prebidDevices.title')} />

      {workspaceDevices && prebidDevices && (
        <BoxBody>
          <Formik
            enableReinitialize
            initialValues={PrebidDevicesFormBuilder.initialValues(prebidDevices)}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ dirty, values }) => (
              <StatusForm name={`${product}-prebid-devices`} dirty={dirty}>
                <div className='box__body'>
                  <DeviceList
                    errors={errors}
                    name='devices'
                    onDeleteHandler={(index) => onDelete(values.devices[index])}
                    options={PrebidDevicesFormBuilder.options(workspaceDevices)}
                  >
                    {({ index }) => <PrebidDeviceRow index={index} errors={errors} />}
                  </DeviceList>

                  <Button
                    buttonType='submit'
                    disabled={!dirty || isSubmitting}
                    theme={ButtonTheme.Orange}
                    type={ButtonType.Primary}
                  >
                    {t('common:buttons.save')}
                  </Button>
                </div>
              </StatusForm>
            )}
          </Formik>
        </BoxBody>
      )}
    </Box>
  )
}
