import React from 'react'
import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { CheckboxGroup } from 'components/Form/CheckboxGroup/CheckboxGroup'
import { FormSection } from 'components/shared/Inventory/AdUnits/Form/Sections/FormSection'

interface Props {
  domainVersionOptions: SelectOption[]
  errors: string[]
}

export const ApplicableVersions: React.FC<Props> = ({ domainVersionOptions, errors }) => {
  const { t } = useTranslation('inventory')
  const { setFieldValue } = useFormikContext()

  return (
    <FormSection title={t('adUnits.form.inputs.applicableVersions.title')} className='applicable-versions'>
      {domainVersionOptions.length > 0 && (
        <Grid item lg={8} xs={12}>
          <CheckboxGroup
            selectAllLabel={t('adUnits.form.inputs.applicableVersions.label')}
            name='domainVersionIds'
            items={domainVersionOptions as SelectOption<string>[]}
            onChange={() => setFieldValue('pageIds', [])}
            errors={errors}
          />
        </Grid>
      )}
    </FormSection>
  )
}
