import React from 'react'

import { useField } from 'formik'
import omit from 'lodash/omit'

import { TagsInputContainer } from 'components/Form/TagsInput'
import { FormikTagsContainerProps } from './types'

export const FormikTagsContainer = (props: FormikTagsContainerProps): JSX.Element => {
  const [field, _, helpers] = useField(props.field.name)

  return (
    <TagsInputContainer
      {...omit(props, 'field')}
      onChange={(value: string[]) => void helpers.setValue(value)}
      value={field.value}
    />
  )
}
