import React from 'react'
import { useField, FieldConfig } from 'formik'

import { Checkbox } from 'components/Form'
import { InputContainer } from 'components/Form/InputContainer'

interface Props {
  id: string
  disabled?: boolean
  className?: string
  errors?: Array<string>
  label?: string
  labelClassName?: string
  inputLabel?: string
  tooltipContent?: string
  field: FieldConfig
  onChange?: (value: boolean) => void
}

export const FormikCheckboxContainer: React.FC<Props> = ({
  id,
  className,
  disabled,
  errors,
  label,
  labelClassName,
  inputLabel,
  tooltipContent,
  children,
  onChange,
  ...other
}) => {
  const [field, , helpers] = useField(other.field.name)

  const onChangeHandler = () => {
    onChange && onChange(!field.value)
    helpers.setValue(!field.value)
  }

  return (
    <InputContainer
      inputId={id}
      className={className}
      disabled={disabled}
      errors={errors}
      label={inputLabel}
      tooltipContent={tooltipContent}
    >
      <Checkbox
        id={id}
        name={other.field.name}
        disabled={disabled}
        checked={field.value}
        label={label}
        labelClassName={labelClassName}
        onChange={onChangeHandler}
      >
        {children}
      </Checkbox>
    </InputContainer>
  )
}
