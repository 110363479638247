import React, { useContext, useState } from 'react'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { ABTestRowData } from './ABTestRowData'
import { ABTestSerializer } from 'serializers/ABTestSerializer'
import { EndTestModal } from 'components/quickwrap/ABTesting/Form/Modals'
import { ErrorUtils } from 'utilities/errorUtils'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ToggleSwitch } from 'components/Form'

import UpdateABTest from 'gql/mutations/abTesting/UpdateABTest.gql'
import { NotificationType } from 'components/ToastNotifier'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { useTranslation } from 'react-i18next'

interface Props {
  row: Row<ABTestRowData>
}

export const StatusCell: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation('abTesting')

  const history = useHistory()
  const abTest = row.original.abTest

  const { createNotifier } = useContext(NotificationContext) as Notification

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [isActive, setIsActive] = useState(abTest.active || false)

  const [updateABTest, { loading }] = useMutation(UpdateABTest, {
    onCompleted: ({ updateABTest: { abTest, errors } }) => {
      setErrors(new ErrorUtils(errors))
      if (abTest) {
        setIsModalVisible(false)
        setIsActive(!isActive)
        history.push(history.location)
      } else {
        createNotifier(t(errors[0].message), NotificationType.ERROR)
      }
    }
  })

  const onToggle = () => {
    if (isActive) {
      setIsModalVisible(true)
    } else {
      updateABTest(nestGqlInput({ abTestId: abTest.id, active: true }))
    }
  }

  const onModalConfirm = (formData: ABEndTestFormData) => {
    updateABTest({ variables: { input: ABTestSerializer.endTestParams(formData) } })
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        id={`status-${abTest.id}`}
        name={`status-${abTest.id}`}
        checked={isActive}
        onChange={onToggle}
        className='status__switch'
      />
      <EndTestModal
        abTest={abTest}
        errors={errors}
        isSubmitting={loading}
        onEndTest={onModalConfirm}
        setVisible={setIsModalVisible}
        visible={isModalVisible}
      />
    </div>
  )
}
