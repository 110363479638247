import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ProductActivationModal } from 'components/oss/Products/ProductActivationModal'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { ProductPlan } from 'webapp/constants/ProductPlan'
import { Can } from 'webapp/context/AbilityContext'

import { ItemProps } from './types'

export const UnlimitedPlanActivation: React.FC<ItemProps> = ({ productConnection }) => {
  const { t } = useTranslation('products')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsModalVisible(true)
  }

  return (
    <Can I={AbilityAction.EDIT} a={AbilitySubject.PRODUCT}>
      <Button theme={ButtonTheme.Blue} type={ButtonType.Primary} onClickHandler={handleClick}>
        {t('buttons.unlimitedAccess')}
      </Button>

      <ProductActivationModal
        plan={ProductPlan.Unlimited}
        productConnection={productConnection}
        setVisible={setIsModalVisible}
        visible={isModalVisible}
      />
    </Can>
  )
}
