import React, { useCallback, useContext, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { EditModalProps, UpdateDomainVersionData, UpdateDomainVersionVars } from '../types'
import { Errors, ErrorUtils } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { Modal } from 'components/Modals'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'

import UPDATE_DOMAIN_VERSION from 'gql/mutations/domainVersions/UpdateDomainVersion.gql'

export const EditModal: React.FC<EditModalProps> = ({ domainVersion, setModalVisible, modalVisible }) => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [errors, setErrors] = useState<Errors>(new ErrorUtils([]))

  const { id: domainVersionId, name, counter, description } = domainVersion

  const [updateDomainVersion] = useMutation<UpdateDomainVersionData, { input: UpdateDomainVersionVars }>(
    UPDATE_DOMAIN_VERSION,
    {
      onCompleted: ({ updateDomainVersion: { domainVersion, errors } }): void => {
        if (errors.length > 0) {
          setErrors(new ErrorUtils(errors))
        } else if (domainVersion) {
          setModalVisible(false)

          createNotifier(
            t('domains.versions.edit.modal.notification.success', { domainVersionName: domainVersion.name }),
            NotificationType.SUCCESS
          )
        }
      }
    }
  )

  const onUpdateDomainVersion = useCallback(
    (domainVersion) => {
      updateDomainVersion(
        nestGqlInput({
          domainVersionId: domainVersion.id,
          description: domainVersion.description,
          name: domainVersion.name
        })
      )
    },
    [updateDomainVersion]
  )

  return (
    <Modal
      backdrop={true}
      setVisible={setModalVisible}
      shadow={true}
      title={t('domains.versions.edit.modal.title')}
      visible={modalVisible}
    >
      <div className='confirmation__modal-container'>
        <Formik
          initialValues={{
            id: domainVersionId,
            name: name ?? '',
            description: description ?? ''
          }}
          onSubmit={onUpdateDomainVersion}
        >
          <Form>
            <div>
              <Field
                component={FormikInput}
                errors={errors.errorsFor('name')}
                id='name'
                label={t('domains.versions.edit.modal.name.label')}
                name='name'
                placeholder={t('domains.versions.edit.modal.name.placeholder')}
                prefix={`v${counter}_`}
                type='name'
              />

              <Field
                component={FormikInput}
                containerClassName='mt-2'
                errors={errors.errorsFor('description')}
                id='description'
                label={t('domains.versions.edit.modal.description.label')}
                name='description'
                placeholder={t('domains.versions.edit.modal.description.placeholder')}
                type='description'
              />
            </div>

            <footer className='mt-4'>
              <Button
                buttonType='button'
                className='confirmation__modal__button'
                onClickHandler={() => setModalVisible(false)}
                theme={ButtonTheme.BlueReverse}
                type={ButtonType.Secondary}
              >
                {t('common:buttons.cancel')}
              </Button>

              <Button
                buttonType='submit'
                className='confirmation__modal__button'
                theme={ButtonTheme.Blue}
                type={ButtonType.Primary}
              >
                {t('common:buttons.save')}
              </Button>
            </footer>
          </Form>
        </Formik>
      </div>
    </Modal>
  )
}
