import React, { useContext, useEffect, useState } from 'react'
import { Column } from 'react-table'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { columnsConfig } from './columnsConfig'
import { DomainsVersionsTableProps } from './types'
import { QueryTable } from 'containers/QueryTable'
import { VersionedDomainContext } from 'webapp/context/VersionedDomainContext'

import DOMAIN_VERSIONS from 'gql/queries/domainVersions/DomainVersions.gql'

export const DomainsVersionsTable: React.FC<DomainsVersionsTableProps> = ({
  domainId,
  isABTestRunning,
  testedDomainVersionIds
}) => {
  const { domainVersions } = useContext(VersionedDomainContext)
  const [refetchData, setRefetchData] = useState(false)

  useEffect(() => {
    setRefetchData((prevState) => !prevState)
  }, [domainVersions])

  return domainVersions.length > 0 ? (
    <QueryTable<DomainVersion>
      className='DomainsVersionsTable'
      collectionName={CollectionNames.DomainVersions}
      columnsConfig={columnsConfig(isABTestRunning, testedDomainVersionIds) as Column[]}
      dataQuery={DOMAIN_VERSIONS}
      shouldRefetchData={refetchData}
      variables={{ domainId }}
    />
  ) : null
}
