import { FilterType } from 'webapp/constants/FilterType'
import { useTranslation } from 'react-i18next'

export const useFilterConfig = (): AdvancedFilterConfiguration[] => {
  const { t } = useTranslation('inventory')

  return [
    {
      label: t('filters.label.domainName'),
      name: 'domain.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.domainName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.pageName'),
      name: 'pageName',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.pageName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.pagePath'),
      name: 'path',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.pagePath'),
      type: FilterType.Input
    }
  ]
}
