import React from 'react'
import { Field, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import { Input } from 'components/Form'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer, FormikSelectContainer } from 'containers/FormikContainers'
import { StatusForm } from 'components/Form'
import { emptySelectOption } from 'webapp/constants/emptySelectOption'

interface Props {
  errors: Errors
  isSubmitting: boolean
  loadingWorkspace?: boolean
  onSubmit: (formData: DataStudioLinkFormData) => void
  onWorkspaceChange?: (workspaceId: string) => void
  workspace?: Workspace
  workspaces?: Workspace[]
}

export const DataStudioForm: React.FC<Props> = ({
  errors,
  isSubmitting,
  loadingWorkspace,
  onSubmit,
  onWorkspaceChange,
  workspace,
  workspaces
}) => {
  const { t } = useTranslation('dataStudio')

  const emailsFieldValue = workspace?.users.map((user) => user.email).join(', ') ?? ''
  const selectedWorkspaceOption = workspace ? workspaceToOption(workspace) : undefined
  const workspaceOptions = workspaces?.map(workspaceToOption)

  const initialValues = {
    dataStudioUrl: workspace?.dataStudioUrl ?? '',
    workspaceId: selectedWorkspaceOption || emptySelectOption
  }

  return (
    <div className='data-studio-form qw-form'>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        {({ dirty }) => (
          <StatusForm name='oss-data-studio' dirty={dirty}>
            <Grid container spacing={4} justifyContent='space-between'>
              <Grid item lg={4} xs={6}>
                <Field
                  component={FormikSelectContainer}
                  disabled={!workspaces}
                  id='data-studio-workspaces'
                  label={t('form.workspace.label')}
                  name='workspaceId'
                  onChangeHandler={(option: StringSelectOption) => onWorkspaceChange?.(option.value)}
                  options={workspaceOptions}
                  selectedItem={selectedWorkspaceOption}
                />
                <Field
                  component={FormikInputContainer}
                  disabled={!workspace}
                  errors={errors.errorsFor('dataStudioUrl')}
                  id='data-studio-data-studio-url'
                  label={t('form.dataStudioUrl.label')}
                  name='dataStudioUrl'
                  tooltipContent={t('form.dataStudioUrl.tooltip')}
                />

                <Button
                  buttonType='submit'
                  disabled={isSubmitting || !dirty || loadingWorkspace}
                  theme={ButtonTheme.Blue}
                  type={ButtonType.Primary}
                >
                  {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                </Button>
              </Grid>
              <Grid item xs={6} className='mx-auto'>
                <Input
                  copyButton
                  disabled
                  id='data-studio-emails'
                  label={t('form.emails.label')}
                  name='emails'
                  type='textarea'
                  value={emailsFieldValue}
                />
              </Grid>
            </Grid>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}

const workspaceToOption = (workspace: Workspace) => ({ value: workspace.id, label: workspace.name })
