import { RefObject, useEffect } from 'react'

type RefType = RefObject<HTMLDivElement>

export const useOnClickOutside = (ref: RefType, handler: () => void): void => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
        return
      }
      handler()
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}
