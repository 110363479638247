import React, { useRef } from 'react'
import { Row, TableBodyProps, TableRowProps } from 'react-table'
import MaUTableBody from '@material-ui/core/TableBody'

import { NothingFoundRow } from './NothingFoundRow'
import TableRow from './TableRow'
import { useHighlightRows } from 'components/Table/useHighlightRows'

interface Props extends TableBodyProps {
  collectionName: string
  rows: Array<Row<Record<string, unknown>>>
  prepareRow: (row: Row<Record<string, unknown>>) => void
  getTableBodyProps: () => TableBodyProps
  getRowProps?: (row: Row<Record<string, unknown>>) => Partial<TableRowProps>
  onRowClick?: (row: Row<Record<string, unknown>>) => void
}

export const TableBody = ({
  collectionName,
  rows,
  prepareRow,
  getTableBodyProps,
  getRowProps,
  onRowClick
}: Props): JSX.Element => {
  const tableBodyRef = useRef<HTMLTableSectionElement>()

  const highlightedRows = useHighlightRows(collectionName, rows, tableBodyRef)

  return (
    <MaUTableBody innerRef={tableBodyRef} {...getTableBodyProps()}>
      {highlightedRows.length ? (
        highlightedRows.map((row, index) => {
          prepareRow(row)
          return <TableRow row={row} getRowProps={getRowProps} onRowClick={onRowClick} key={index} />
        })
      ) : (
        <NothingFoundRow />
      )}
    </MaUTableBody>
  )
}
