import { useTranslation } from 'react-i18next'

import { FilterType } from 'webapp/constants/FilterType'

export const useFilterConfig = (): AdvancedFilterConfiguration[] => {
  const { t } = useTranslation('priceGenius')

  return [
    {
      label: t('configuration.filters.label.domainName'),
      name: 'domain.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('configuration.filters.placeholder.domainName'),
      type: FilterType.Input
    }
  ]
}
