import { makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

import { COLOR_ORANGE_1, COLOR_PRICE_GENIUS, MIN_SAFE_TRAFFIC } from './constants'

export const useSliderDynamicStyles = (currentValue: number): ((props?: unknown) => ClassNameMap<'root'>) =>
  makeStyles({
    root: {
      '& .MuiSlider-track, & .MuiSlider-thumb, & .PrivateValueLabel-circle-5': {
        color: currentValue <= MIN_SAFE_TRAFFIC ? COLOR_ORANGE_1 : COLOR_PRICE_GENIUS
      }
    }
  })
