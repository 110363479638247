import React, { useContext, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { ConfirmationModal } from 'components/Modals'
import { DropdownMenu } from 'components/DropdownMenu'
import { Edit } from 'icons/Edit'
import { NotificationType } from 'components/ToastNotifier'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { routesBuilder } from 'utilities/routesBuilder'
import { useMutation } from '@apollo/client'

import DestroyNetwork from 'gql/mutations/networks/DestroyNetwork.gql'

type ActionsCellType = {
  network: Network
}

export const ActionsCell = ({ network }: ActionsCellType): JSX.Element => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation()
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const history = useHistory()

  const onEditHandler = () => {
    history.push(routesBuilder.quickwrap.setup.networks.edit(network.id))
  }

  const onDeleteHandler = () => setVisibleDeleteModal(true)

  const [destroyNetwork] = useMutation(DestroyNetwork, {
    onCompleted: ({ destroyNetwork: { network, errors } }) => {
      setVisibleDeleteModal(false)

      if (!errors.length) {
        createNotifier(
          t('setup:networks:list.notification.delete.success', { networkName: network.name }),
          NotificationType.SUCCESS
        )
        history.push(history.location)
      } else {
        createNotifier(
          t('setup:networks:list.notification.delete.error', { networkName: network.name }),
          NotificationType.ERROR
        )
      }
    }
  })

  const confirmHandler = () => destroyNetwork(nestGqlInput({ networkId: network.id }))

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownMenu setVisible={setIsDropdownVisible} visible={isDropdownVisible}>
        <MenuItem onClick={onEditHandler}>
          {t('common:buttons.edit')} <Edit />
        </MenuItem>

        <MenuItem onClick={onDeleteHandler}>
          {t('common:buttons.delete')} <Bin />
        </MenuItem>
      </DropdownMenu>
      <ConfirmationModal
        cancelButtonText={t('buttons.cancel')}
        confirmButtonText={t('buttons.delete')}
        content={t('setup:networks:list.modal.deleteConfirm.content', {
          networkName: network.name
        })}
        onConfirmHandler={confirmHandler}
        setVisible={() => {
          setIsDropdownVisible(false)
          setVisibleDeleteModal(false)
        }}
        visible={visibleDeleteModal}
      />
    </div>
  )
}
