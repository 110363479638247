import React from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Arrow } from 'icons/Arrow'
import { Link } from './Link'
import { SidebarItemType } from 'components/Sidebar/types'
import { SidebarLinkContent } from 'components/Sidebar/SidebarLinkContent'
import { removeQueryParams } from 'utilities/removeQueryParams'
import { extractQueryParams } from 'webapp/utils/extractQueryParams'

interface Props extends SidebarItemType {
  hasSubMenu?: boolean
  hasThirdLevelSubMenu?: boolean
  isExpanded?: boolean
  isThirdLevelSubmenuMember?: boolean
  onToggleSubmenu?: () => void
}

export const SidebarLink: React.FC<Props> = ({
  absolute = false,
  className,
  hasSubMenu,
  hasThirdLevelSubMenu,
  href,
  icon,
  isExpanded,
  isThirdLevelSubmenuMember,
  onToggleSubmenu,
  subtitle,
  title
}) => {
  const location = useLocation()

  const checkDoSearchParamsMatch = () => {
    const locationSearchParams = new URLSearchParams(location.search)
    const hrefSearchParams = new URLSearchParams(extractQueryParams(href ?? ''))

    let doSearchParamsMatch = true
    hrefSearchParams.forEach((value, param) => {
      if (!value) return

      if (!locationSearchParams.has(param) || locationSearchParams.get(param) !== value) {
        doSearchParamsMatch = false
      }
    })

    return doSearchParamsMatch
  }

  const doesPathMatch = Boolean(href) && location.pathname.includes(removeQueryParams(href ?? ''))
  const doSearchParamsMatch = checkDoSearchParamsMatch()
  const isLinkActive = doesPathMatch && (isThirdLevelSubmenuMember ? doSearchParamsMatch : true)

  const isSubmenuActive = Boolean(
    location.pathname.includes(title.toLocaleLowerCase()) ||
      (href && location.pathname.includes(removeQueryParams(href)))
  )
  const toggleArrowClass = isExpanded ? 'arrow--up' : 'arrow--down'
  const subMenuActiveClass = isSubmenuActive ? 'link__active' : ''
  const containerActiveClass = isLinkActive ? 'active' : ''
  const containerThirdLevelSubmenuMemberClass = isThirdLevelSubmenuMember
    ? 'link__container_third-level-sub-menu-member'
    : ''

  const linkContainerClass = classNames(
    'link__container',
    containerActiveClass,
    className,
    containerThirdLevelSubmenuMemberClass
  )

  return (
    <div className={linkContainerClass} onClick={onToggleSubmenu}>
      {hasSubMenu && (
        <>
          <span className={`link__content ${subMenuActiveClass}`}>
            <SidebarLinkContent icon={icon} title={title} subtitle={subtitle} />
          </span>
          <Arrow classNames={toggleArrowClass} alt='toggle arrow' />
        </>
      )}

      {hasThirdLevelSubMenu && (
        <>
          <Link href={href || ''} absolute={absolute} isActive={isSubmenuActive}>
            <SidebarLinkContent icon={icon} title={title} subtitle={subtitle} />
          </Link>
          {isSubmenuActive && <Arrow classNames='arrow--right active' alt='arrow' />}
        </>
      )}

      {!hasSubMenu && !hasThirdLevelSubMenu && (
        <>
          <Link
            href={href || ''}
            absolute={absolute}
            isActive={isLinkActive}
            isThirdLevelSubmenuMember={isThirdLevelSubmenuMember}
          >
            <SidebarLinkContent icon={icon} title={title} subtitle={subtitle} />
          </Link>
          {isLinkActive && !isThirdLevelSubmenuMember && <Arrow classNames='arrow--right active' alt='arrow' />}
        </>
      )}
    </div>
  )
}
