import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import DomainsQuery from 'gql/queries/domains/DomainWithActiveVersionSelect.gql'

interface ReturnType {
  domain?: Domain
  domains: Domain[]
  onDomainChangeHandler: (selectedDomain: SelectOption) => void
}

export const useDomains = (domainVersionIdToWatch?: string): ReturnType => {
  const { data } = useQuery(DomainsQuery, { fetchPolicy: 'network-only' })

  const [domain, setDomain] = useState<Domain>()
  const domains = useMemo(() => (data?.domains?.nodes ?? []).map((domain: Domain) => domain), [data])

  const onDomainChangeHandler = (selectedDomain: SelectOption) => {
    const domain = domains.find((domain: Domain) =>
      domain.versions.nodes.some((version: DomainVersion) => version.id === selectedDomain.value)
    )
    setDomain(domain)
  }

  useEffect(() => {
    setDomain(
      domains.find((domain: Domain) =>
        domain.versions.nodes.some((version: DomainVersion) => version.id === domainVersionIdToWatch)
      )
    )
  }, [domainVersionIdToWatch, domains])

  return { domain, domains, onDomainChangeHandler }
}
