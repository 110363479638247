import React from 'react'
import { useTranslation } from 'react-i18next'

import { BidderConnectionCell } from 'components/quickwrap/Setup/DomainComparisonTable/BidderConnectionCell'
import { BidderHeadingCell } from 'components/quickwrap/Setup/DomainComparisonTable/BidderHeadingCell'
import { DomainTestCell } from 'components/quickwrap/Setup/DomainComparisonTable/DomainTestCell'
import { DomainVersionCell } from 'components/quickwrap/Setup/DomainComparisonTable/DomainVersionCell'
import { StickyCell } from 'components/quickwrap/Setup/DomainComparisonTable/StickyCell'
import { useGetConnectionType } from 'components/quickwrap/Setup/DomainComparisonTable/useGetConnectionType'

interface Props {
  bidderSettings: BidderSetting[]
  domains: Domain[]
}

export const DomainComparisonTable: React.FC<Props> = ({ bidderSettings, domains }) => {
  const { t } = useTranslation('setup')
  const getConnectionType = useGetConnectionType(domains)

  if (!domains.length) {
    return <div>{t('bidders.domainComparison.noDomains')}</div>
  }

  if (!bidderSettings.length) {
    return <div>{t('bidders.domainComparison.noBidders')}</div>
  }

  return (
    <div className='domain-comparison-table-wrap'>
      <table className='domain-comparison-table'>
        <thead>
          <tr>
            <StickyCell className='domain-comparison-table__main-heading' stickToLeft colSpan={3}>
              {t('bidders.domainComparison.biddersOnDomain')}
            </StickyCell>
            {bidderSettings.map((bidderSetting) => (
              <BidderHeadingCell bidderSetting={bidderSetting} key={bidderSetting.id} />
            ))}
          </tr>
          <tr>
            <StickyCell className='domain-comparison-table__domain-heading' stickToLeft>
              {t('bidders.domainComparison.domain')}
            </StickyCell>
            <StickyCell className='domain-comparison-table__version-heading' stickToLeft>
              {t('bidders.domainComparison.version')}
            </StickyCell>
            <StickyCell className='domain-comparison-table__test-heading' stickToLeft>
              {t('bidders.domainComparison.abTest')}
            </StickyCell>
          </tr>
        </thead>
        <tbody>
          {domains.map((domain) => {
            const rowspan = domain.versions.nodes.length

            return domain.versions.nodes.map((version, versionIndex) => {
              const offset = rowspan > 1 && versionIndex !== 0 ? 150 : 0

              return (
                <tr key={version.id}>
                  {versionIndex === 0 && (
                    <StickyCell
                      className='domain-comparison-table__domain'
                      startingOffset={offset}
                      stickToLeft
                      rowSpan={rowspan}
                    >
                      {domain.name}
                    </StickyCell>
                  )}
                  <DomainVersionCell startingOffset={offset} version={version} />
                  <DomainTestCell startingOffset={offset} version={version} />
                  {bidderSettings.map((bidderSetting, index) => {
                    const connection = getConnectionType(version.id, bidderSetting.id)

                    if (connection)
                      return (
                        <BidderConnectionCell
                          active={bidderSetting.active && connection.active}
                          connection={connection.connectionType}
                          index={index}
                          key={bidderSetting.id}
                        />
                      )
                  })}
                </tr>
              )
            })
          })}
        </tbody>
      </table>
    </div>
  )
}
