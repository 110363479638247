import React, { useContext, useEffect, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { Row } from 'react-table'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { ConfirmationModal } from 'components/Modals'
import { DropdownMenu } from 'components/DropdownMenu'
import { Edit } from 'icons/Edit'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { OwnBidderTableContext } from 'webapp/context/OwnBiddersContext'
import { OwnBidderSettingRowData } from './OwnBidderSettingRowData'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import DeleteBidderSetting from 'gql/mutations/bidderSettings/DeleteBidderSetting.gql'

interface Props {
  row: Row<OwnBidderSettingRowData>
}

export const ActionsCell: React.FC<Props> = ({ row }) => {
  const bidderSetting = row.original.bidderSetting

  const { t } = useTranslation()
  const { closeDropdownCounter, openEditModal, refetchBidderSettings } = useContext(OwnBidderTableContext)
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

  const onEditHandler = () => openEditModal(row.original.id)
  const onDeleteHandler = () => setVisibleDeleteModal(true)

  useEffect(() => setIsDropdownVisible(false), [closeDropdownCounter])

  const [destroyBidderSetting] = useMutation(DeleteBidderSetting, {
    onCompleted: ({ destroyBidderSetting: { errors } }) => {
      setVisibleDeleteModal(false)

      if (!errors.length) {
        createNotifier(
          t('setup:bidders.modal.deleteConfirm.notification.success', bidderSetting),
          NotificationType.SUCCESS
        )
        refetchBidderSettings()
      } else {
        createNotifier(t('setup:bidders.modal.deleteConfirm.notification.error', bidderSetting), NotificationType.ERROR)
      }
    }
  })

  const confirmHandler = () => destroyBidderSetting(nestGqlInput({ bidderSettingId: bidderSetting.id }))

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownMenu setVisible={setIsDropdownVisible} visible={isDropdownVisible}>
        <MenuItem onClick={onEditHandler}>
          {t('common:buttons.edit')} <Edit />
        </MenuItem>

        <MenuItem onClick={onDeleteHandler}>
          {t('common:buttons.delete')} <Bin />
        </MenuItem>
      </DropdownMenu>
      <ConfirmationModal
        cancelButtonText={t('buttons.cancel')}
        confirmButtonText={t('buttons.delete')}
        content={t('setup:bidders.modal.deleteConfirm.content', { bidderSettingCode: bidderSetting.bidderCode })}
        onConfirmHandler={confirmHandler}
        setVisible={() => {
          setIsDropdownVisible(false)
          setVisibleDeleteModal(false)
        }}
        visible={visibleDeleteModal}
      />
    </div>
  )
}
