import React from 'react'
import Tooltip from 'react-tooltip-lite'
import { useTranslation } from 'react-i18next'

import Active from 'images/icon-active.svg'
import Inactive from 'images/icon-inactive.svg'

interface Props {
  live: boolean
}

export const LiveCell: React.FC<Props> = ({ live }) => {
  const { t } = useTranslation('inventory')

  const alt = t(`domains.tableBody.live.alt.${String(live)}`)
  const tooltip = t(`domains.tableBody.live.tooltip.${String(live)}`)

  return (
    <Tooltip arrow={false} className='qw-tooltip' content={tooltip} direction='bottom' distance={5}>
      <img src={live ? Active : Inactive} alt={alt} />
    </Tooltip>
  )
}
