import React from 'react'
import { Column } from 'react-table'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { QueryTable } from 'containers/QueryTable/QueryTable'
import { PublisherRoles, RoleType, SuperuserRoles } from 'webapp/constants/Role'
import { useColumnConfig } from './columnsConfig'

import UsersListQuery from 'gql/queries/users/UsersList.gql'

const SEARCHABLE_FIELDS = ['full_name', 'email', 'workspaces.name']

interface Props {
  roleType?: RoleType
}

export const UserTable: React.FC<Props> = ({ roleType }) => {
  const columns = useColumnConfig()

  return (
    <QueryTable<User>
      className='user-table'
      collectionName={CollectionNames.Users}
      columnsConfig={columns as Column[]}
      dataQuery={UsersListQuery}
      globalFilter={
        roleType && [
          {
            by: 'role',
            operator: 'is',
            values: roleType === RoleType.Publisher ? PublisherRoles : SuperuserRoles
          } as AdvancedFilter
        ]
      }
      pageSize={25}
      searchableFields={SEARCHABLE_FIELDS}
      subCollectionName={roleType}
    />
  )
}
