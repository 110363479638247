import React from 'react'

import { DevicesPage } from 'pages/oss/Inventory/Devices'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { routesBuilder } from 'utilities/routesBuilder'
import { Product } from 'webapp/constants/Product'

export const DevicesRoutes = [
  <LayoutRoute
    key='ad-units-list'
    product={Product.OSS}
    exact
    path={routesBuilder.oss.inventory.devices.root}
    component={DevicesPage}
  />
]
