import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AvailablePrebidModules } from 'webapp/constants/PrebidModuleKind'
import { capitalize } from 'utilities/capitalize'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { PrebidModuleSerializer } from 'serializers/PrebidModuleSerializer'

import UpdatePrebidModuleParams from 'gql/mutations/prebidModules/UpdatePrebidModuleParams.gql'

export const useSubmitPrebidModuleParams = (
  setIsDirty: (isDirty: boolean) => void,
  refetch: () => void,
  prebidModuleKindName: AvailablePrebidModules
) => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [updatePrebidModuleParams, { loading: submitting }] = useMutation(UpdatePrebidModuleParams, {
    onCompleted: ({ updateWorkspacePrebidModules: { errors } }) => {
      if (!errors.length) {
        setIsDirty(false)
        createNotifier(t(`${prebidModuleKindName}.form.successMessage`), NotificationType.SUCCESS)
        refetch()
      } else {
        createNotifier(capitalize(errors[0].message), NotificationType.ERROR)
      }
    }
  })

  const onSubmit = (values: PrebidModuleForm) => {
    const serializer = new PrebidModuleSerializer(values)

    if (serializer.prebidModules.some((module) => !module.saved)) {
      createNotifier(t(`${prebidModuleKindName}.form.failureMessage`), NotificationType.ERROR)
    } else {
      updatePrebidModuleParams(nestGqlInput({ attributes: serializer.updateParams() }))
    }
  }

  return { onSubmit, submitting }
}
