import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Role, SuperuserRoles } from 'webapp/constants/Role'
import { CanFunctionType } from 'webapp/context/AbilityContext/AbilityContext'

const superusersAndOwner = [...SuperuserRoles, Role.Owner]

export const processSuperuserPermissions = (can: CanFunctionType, user: User): void => {
  can(AbilityAction.ACCESS, AbilitySubject.ALIAS_LIST)
  can(AbilityAction.ACCESS, AbilitySubject.FINANCE_DATA_STUDIO_LINKS_EDIT)
  can(AbilityAction.ACCESS, AbilitySubject.FINANCE_DATA_STUDIO_LINKS_NEW)
  can(AbilityAction.ACCESS, AbilitySubject.FINANCE_DATA_STUDIO_LINKS_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.FINANCE_DATA_STUDIO_LINKS_PREVIEW)
  can(AbilityAction.ACCESS, AbilitySubject.INVITE_USER_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_LINKS_EDIT)
  can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_LINKS_NEW)
  can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_LINKS_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_LINKS_PREVIEW)
  can(AbilityAction.ACCESS, AbilitySubject.PRODUCT_MANAGEMENT)
  can(AbilityAction.ACCESS, AbilitySubject.WORKSPACES_EDIT_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.WORKSPACES_NEW_PATH)
  can(AbilityAction.ACCESS, AbilitySubject.WORKSPACES_PATH)
  can(AbilityAction.EDIT, AbilitySubject.ADUNIT)
  can(AbilityAction.EDIT, AbilitySubject.DOMAIN)
  can(AbilityAction.EDIT, AbilitySubject.PAGE)

  const notHimselfAndNotSuperuserOrOwner = {
    id: { $ne: user.id },
    role: { $nin: superusersAndOwner }
  }
  can(AbilityAction.CHANGE_ROLE, AbilitySubject.USER, notHimselfAndNotSuperuserOrOwner)
  can(AbilityAction.DELETE, AbilitySubject.DOMAIN)
  can(AbilityAction.DELETE, AbilitySubject.USER, notHimselfAndNotSuperuserOrOwner)

  const ownerFromWorkspaceWithOtherOwners = {
    role: Role.Owner,
    'workspace.ownerCount': { $gt: 1 }
  }
  can(AbilityAction.CHANGE_ROLE, AbilitySubject.USER, ownerFromWorkspaceWithOtherOwners)
  can(AbilityAction.DELETE, AbilitySubject.USER, ownerFromWorkspaceWithOtherOwners)

  can(AbilityAction.EDIT, AbilitySubject.YB_SCHAIN)
  can(AbilityAction.VIEW, AbilitySubject.ADMIN_BOX)
}
