/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class BlockUrlGroupSerializer {
  constructor(public formData: BlockUrlGroupData) {}

  createParams() {
    return this.prepareBody()
  }

  updateParams() {
    return {
      qwBlockUrlGroupId: this.formData.id,
      ...this.prepareBody()
    }
  }

  private prepareBody() {
    return {
      domainIds: this.formData.domains || [],
      name: this.formData.name,
      urls: this.formData.urls
    }
  }
}
