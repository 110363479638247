import React from 'react'
import { Grid } from '@material-ui/core'

import { BidderPriorityField } from 'components/FormikComponents/BidderPriorityField/BidderPriorityField'
import { BiddersSelect } from 'components/FormikComponents'
import { Errors } from 'utilities/errorUtils'

interface Props {
  index: number
  demandBidders: Bidder[]
  errors: Errors
  hideAdditionalOptions?: boolean
  isNew?: boolean
}

export const YbDemandBiddersRow: React.FC<Props> = ({ index, demandBidders, errors, hideAdditionalOptions, isNew }) => {
  return (
    <>
      <Grid item xs={hideAdditionalOptions ? 9 : 5}>
        <BiddersSelect
          id={`yb-demand-bidder-select-${index}`}
          bidders={demandBidders}
          name={`bidders.${index}.bidderId`}
          errors={errors.errorsFor([index.toString(), 'bidderId'])}
          disabled={!isNew}
        />
      </Grid>

      {!hideAdditionalOptions && (
        <Grid item xs={4}>
          <BidderPriorityField
            className='yb-demand-bidder-priority'
            disabled={!isNew}
            index={index}
            isFirstRow={false}
            errors={errors}
          />
        </Grid>
      )}
    </>
  )
}
