import { gql } from '@apollo/client'
import { CUSTOM_CODE_FRAGMENT } from 'gql/fragments/customCode/CustomCodeFragment'

export const CUSTOM_CODE = gql`
  ${CUSTOM_CODE_FRAGMENT}

  query CustomCode($customCodeId: ID!) {
    customCode(customCodeId: $customCodeId) {
      ...CustomCodeFragment
    }
  }
`
