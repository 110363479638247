import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import {
  BlockUrlGroupFactory,
  BlockUrlGroupsForm
} from 'components/quickwrap/Protection/BlockUrlGroups/BlockUrlGroupsForm'
import { BlockUrlGroupSerializer } from 'serializers/BlockUrlGroupSerializer'
import { Box, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import BlockUrlGroupQuery from 'gql/queries/blockUrlGroups/BlockUrlGroup.gql'
import UpsertBlockUrlGroup from 'gql/mutations/blockUrlGroups/UpsertBlockUrlGroup.gql'

export const EditPage = (): JSX.Element => {
  const { t } = useTranslation('protection')
  const [blockUrlGroup, setBlockUrlGroup] = useState<BlockUrlGroup>()
  const { blockUrlGroupId } = useParams<{ blockUrlGroupId: string }>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [errors, setErrors] = useState(new ErrorUtils([]))

  useQuery(BlockUrlGroupQuery, {
    variables: { qwBlockUrlGroupId: blockUrlGroupId },
    fetchPolicy: 'network-only',
    onCompleted: ({ qwBlockUrlGroup }: { qwBlockUrlGroup: BlockUrlGroup }) => {
      setBlockUrlGroup(qwBlockUrlGroup)
    }
  })

  const [updateBlockUrlGroup, { loading }] = useMutation(UpsertBlockUrlGroup, {
    onCompleted: ({ upsertQwBlockUrlGroup: { blockUrlGroup, errors } }) => {
      if (blockUrlGroup) {
        formStatus.clear()
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmit = (formData: BlockUrlGroupData) => {
    const serializer = new BlockUrlGroupSerializer(formData)

    updateBlockUrlGroup(nestGqlInput(serializer.updateParams())).then((result) => {
      const blockUrlGroup = result.data.upsertQwBlockUrlGroup.qwBlockUrlGroup

      if (blockUrlGroup) {
        setBlockUrlGroup(blockUrlGroup)
        createNotifier(t('blockUrlGroups.edit.successMessage', { name: blockUrlGroup.name }), NotificationType.SUCCESS)
      }
    })
  }

  return (
    <Box>
      <BoxHeader title={t('blockUrlGroups.edit.title')} />

      {blockUrlGroup && (
        <BlockUrlGroupsForm
          blockUrlGroup={BlockUrlGroupFactory(blockUrlGroup)}
          errors={errors}
          isSubmitting={loading}
          onSubmit={onSubmit}
        />
      )}
    </Box>
  )
}
