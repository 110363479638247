import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Box, BoxHeader } from 'components/Box'
import { CustomCodeTemplateForm } from 'components/shared/CustomCode/TemplateForm'
import { NotificationType } from 'components/ToastNotifier'
import { CustomCodeSerializer } from 'serializers/CustomCodeSerializer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { routesBuilder } from 'utilities/routesBuilder'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'

import { CREATE_CUSTOM_CODE_TEMPLATE } from 'gql/mutations/customCode/CreateCustomCodeTemplate'

interface Props {
  domain: Domain
}

export const CreatePage: React.FC<Props> = () => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const history = useHistory<History>()
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { customCodeId } = useParams<{ customCodeId: string }>()

  const [createCustomCode] = useMutation(CREATE_CUSTOM_CODE_TEMPLATE, {
    onCompleted: ({ createCustomCodeTemplate: { customCode, errors } }) => {
      setIsSubmitting(false)
      if (customCode) {
        createNotifier(t('customCodes.new.successMessage'), NotificationType.SUCCESS)
        formStatus.clear()
        history.push(routesBuilder.quickwrap.setup.general)
      } else {
        setErrors(new ErrorUtils(errors))
      }
    }
  })

  const handleSubmit = (form: CustomCodeBasicSubmitForm) => {
    setIsSubmitting(true)
    const serializer = new CustomCodeSerializer(form)
    createCustomCode(nestGqlInput({ customCodeId, ...serializer.templateParams() }))
  }

  return (
    <Box className='custom-code'>
      <BoxHeader
        title={t('inventory:domains.edit.customCode.title')}
        containerClassName='box-header__container--space-between'
        rowClassName='header__row--width-100'
      />
      <div className='box__body'>
        <CustomCodeTemplateForm onSubmit={handleSubmit} {...{ errors, isSubmitting }} />
      </div>
    </Box>
  )
}
