/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TCFWaitType } from 'webapp/constants/TCFWaitType'

export class ConsentManagementSerializer {
  static upsertParams(formData: ConsentManagementFormData) {
    return {
      consentManagementId: formData.consentManagementId,
      domainVersionId: formData.domainVersionId,
      tcfTimeout:
        formData.waitForTcf && formData.type === TCFWaitType.Timeout ? Number(formData.tcfTimeout) : undefined,
      type: formData.waitForTcf ? formData.type : undefined,
      waitForTcf: formData.waitForTcf,
      withoutConsentEnabled: formData.withoutConsentEnabled
    }
  }
}

export class OSSConsentManagementSerializer {
  static upsertParams(formData: OSSConsentManagementFormData) {
    return {
      domainId: formData.domainId,
      ossConsentManagementId: formData.ossConsentManagementId,
      tcfTimeout:
        formData.waitForTcf && formData.type === TCFWaitType.Timeout ? Number(formData.tcfTimeout) : undefined,
      type: formData.waitForTcf ? formData.type : undefined,
      waitForTcf: formData.waitForTcf
    }
  }
}
