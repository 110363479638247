import React, { MouseEvent, RefObject } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

interface Props {
  'aria-label'?: string
  'aria-controls'?: string
  'aria-haspopup'?: boolean
  onClick: (event: MouseEvent) => void
  innerRef?: RefObject<HTMLButtonElement>
}

export const MoreButton: React.FC<Props> = ({ onClick, innerRef, ...rest }) => {
  return (
    <IconButton {...rest} className='more-button' ref={innerRef} onClick={onClick} size='medium'>
      <MoreVertIcon />
    </IconButton>
  )
}
