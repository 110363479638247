import React from 'react'

import { EditPage } from 'pages/quickwrap/Inventory/Pages/EditPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { PagesListPage } from 'pages/quickwrap/Inventory/Pages/ListPage'
import { routesBuilder } from 'utilities/routesBuilder'

export const PageRoutes = [
  <LayoutRoute key='pages-list' exact path={routesBuilder.quickwrap.inventory.pages.root} component={PagesListPage} />,
  <LayoutRoute key='pages-edit' path={routesBuilder.quickwrap.inventory.pages.edit()} component={EditPage} />
]
