import React from 'react'

import { ContainerProps } from './types'

export const Container = ({ children, withSidebar }: ContainerProps): JSX.Element => (
  <div className='container-layout'>
    <div className={`container-layout__wrapper${withSidebar ? ' container-layout__wrapper--with-sidebar' : ''}`}>
      {children}
    </div>
  </div>
)
