import React, { useContext } from 'react'

import BackgroundImage from 'images/forms/register_user_background.svg'
import { UserFlowTemplate } from 'components/UserFlowTemplate'
import { RegisterUserContainer } from 'containers/Auth/RegisterContainer'
import { UserContext } from 'webapp/context/UserContext'

export const RegisterPage: React.FC = () => {
  const userContext = useContext(UserContext)

  const onComplete = () => {
    userContext.reloadUser()
  }

  return (
    <UserFlowTemplate alignItems='center' image={BackgroundImage} view='register'>
      <RegisterUserContainer onComplete={onComplete} />
    </UserFlowTemplate>
  )
}
