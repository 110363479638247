import jwtToken from 'utilities/jwtToken'

interface FileOptions {
  filename: string
  type: string
}

interface File extends FileOptions {
  data: string | Blob
}

export const downloadBlob = (file: File): void => {
  const blob = new Blob([file.data], { type: file.type })
  const href = window.URL.createObjectURL(blob)
  downloadFile(href, file.filename)
}

export const downloadFile = (href: string, filename: string): void => {
  const link = document.createElement('a')
  link.href = href
  link.download = filename
  link.click()
  link.remove()
}

interface ResponseMessage {
  error: {
    code: string
    options?: {
      filename?: string
    }
  }
}

export const downloadFileFromUrl = (url: string, fileOptions: FileOptions): Promise<ResponseMessage | void> => {
  return fetch(url, {
    headers: {
      Authorization: jwtToken.token
    }
  })
    .then((response) => response.blob())
    .then((data) => {
      const file = { data, ...fileOptions }
      downloadBlob(file)
    })
    .catch((_error) => ({
      error: {
        code: 'downloadFileError',
        options: {
          filename: fileOptions.filename
        }
      }
    }))
}
