export enum Role {
  Admin = 'admin',
  Adops = 'adops',
  BO = 'bo',
  CP = 'cp',
  CS = 'cs',
  Editor = 'editor',
  Owner = 'owner',
  Viewer = 'viewer'
}

export const SuperuserRoles = [Role.Admin, Role.Adops, Role.BO, Role.CP, Role.CS]
export const PublisherRoles = [Role.Editor, Role.Owner, Role.Viewer]

export enum RoleType {
  Superuser = 'superuser',
  Publisher = 'publisher'
}
