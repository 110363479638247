import React, { useContext, useState } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import { CircularProgress } from '@material-ui/core'
import {
  AdTypeComponent,
  AuctionsReportScreen,
  BiddersComponent,
  SitesComponent,
  VersionComparisonComponent,
  TotalDashboard
} from '@adend/asteriobid-ui-lib'
import { useLocation } from 'react-router-dom'
import '@adend/asteriobid-ui-lib/dist/index.css'

import i18n from 'webapp/i18n'
import { Box } from 'components/Box'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { PrebidAnalyticsInfoBar } from './PrebidAnalyticsInfoBar'
import { prebidAnalyticsTabs } from 'components/quickwrap/Performance/PrebidAnalytics/prebidAnalyticsTabs'
import { TabNavigation } from 'components/Box/TabNavigation'
import { UserContext } from 'webapp/context/UserContext'
import { useSidebarConfiguration } from 'containers/Sidebar/configuration'
import { WorkspacePackageEnum } from 'utilities/workspacePackageUtils'

import PrebidManagerPublisherToken from 'webapp/gql/queries/performance/PrebidManagerPublisherToken.gql'

export const PrebidAnalyticsContainer: React.FC = () => {
  const { user, reloadUser } = useContext(UserContext)
  const configuration = useSidebarConfiguration(user as User)
  const hasActivePackage = Boolean(
    user?.workspace?.packages?.find((element) => element.name === WorkspacePackageEnum.PREBID_MANAGER)?.active
  )

  const tabSearchParam = 'tab'
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const currentTab = searchParams.get(tabSearchParam)

  const tabs =
    configuration
      .find((item) => item.title === i18n.t('sidebar:performance.label'))
      ?.subItems?.find((item) => item.title === i18n.t('sidebar:performance.prebid.label'))
      ?.subItems?.map((item) => ({ href: item.href ?? '', title: item.title })) ?? []

  const { createNotifier } = useContext(NotificationContext) as Notification
  const [token, setToken] = useState('')

  const { loading } = useQuery(PrebidManagerPublisherToken, {
    onCompleted: ({ publisherTokenQuery }) => setToken(publisherTokenQuery),
    fetchPolicy: 'network-only',
    onError: (error: ApolloError) => {
      const message = error.graphQLErrors[0]?.message || error.message
      createNotifier(message, NotificationType.ERROR)
    }
  })

  const API_URL = 'https://api.asteriobid.com'
  const onAccessError = () => console.error('Error accessing prebid manager api. Please check api url and token.')

  return (
    <>
      <PrebidAnalyticsInfoBar hasActivePackage={hasActivePackage} reloadUser={reloadUser} />
      <Box className='prebid-analytics'>
        <TabNavigation tabs={tabs} searchParam={tabSearchParam} />

        {loading && <CircularProgress className='prebid-analytics__progress' />}

        {token && (
          <>
            {currentTab === prebidAnalyticsTabs.dashboard && (
              <TotalDashboard token={token} apiUrl={API_URL} onAccessError={onAccessError} profileCurrency='USD' />
            )}
            {currentTab === prebidAnalyticsTabs.adTypeAnalysis && (
              <AdTypeComponent token={token} apiUrl={API_URL} onAccessError={onAccessError} profileCurrency='USD' />
            )}
            {currentTab === prebidAnalyticsTabs.bidderAnalysis && (
              <BiddersComponent token={token} apiUrl={API_URL} onAccessError={onAccessError} profileCurrency='USD' />
            )}
            {currentTab === prebidAnalyticsTabs.siteAnalysis && (
              <SitesComponent token={token} apiUrl={API_URL} onAccessError={onAccessError} profileCurrency='USD' />
            )}
            {currentTab === prebidAnalyticsTabs.auctionReports && (
              <AuctionsReportScreen
                token={token}
                apiUrl={API_URL}
                onAccessError={onAccessError}
                profileCurrency='USD'
              />
            )}
            {currentTab === prebidAnalyticsTabs.versionComparison && (
              <VersionComparisonComponent
                token={token}
                apiUrl={API_URL}
                onAccessError={onAccessError}
                profileCurrency='USD'
              />
            )}
          </>
        )}
      </Box>
    </>
  )
}
