import React from 'react'

import { formatDateDots, formatDateTime } from 'utilities/formatDate'

type DateCellProps = {
  value: Date
  format?: DateCellFormat
}

export enum DateCellFormat {
  DATE_TIME,
  DOTS
}

const formattedDate = (date: Date, format: DateCellFormat) => {
  switch (format) {
    case DateCellFormat.DATE_TIME:
      return formatDateTime(date)
    case DateCellFormat.DOTS:
      return formatDateDots(date)
  }
}

export const DateCell: React.FC<DateCellProps> = ({ value, format = DateCellFormat.DATE_TIME }) => (
  <time>{formattedDate(value, format)}</time>
)
