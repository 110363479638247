import { gql } from '@apollo/client'

export const OSS_PAGE_DOMAIN = gql`
  query OssPageDomain($pageId: ID) {
    ossPage(ossPageId: $pageId) {
      domain {
        id
        name
      }
      id
      matchType
      pageName
      path
    }
  }
`
