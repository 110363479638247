import {
  BidderSettingsForm,
  BidderSettingsInput,
  BidderSettingsQuery
} from 'components/sharedInterfaces/BidderSettingsForm'

export class BidderSettingsSerializer {
  utilisedBidderSettingsIds: string[]

  constructor(
    private input: BidderSettingsInput[],
    private existingBidderSettings: BidderSetting[] = [],
    private shouldCreateOnly = false
  ) {
    this.existingBidderSettings = existingBidderSettings || []
    this.utilisedBidderSettingsIds = []
  }

  params(): BidderSettingsQuery[] {
    return [...this.recordsToSave(), ...(this.shouldCreateOnly ? [] : this.recordsToRemove())]
  }

  static parseToForm(records: BidderSetting[]): BidderSettingsForm {
    return {
      bidders: records.map((record: BidderSetting): BidderSettingsInput => {
        return {
          id: record.id,
          bidderId: { value: record.bidder.id },
          bidCpmAdjustment: record.bidCpmAdjustment,
          bidderCode: record.bidderCode ?? '',
          bidderSid: record.bidderSid ?? '',
          priority: { value: record.priority },
          s2sAvailable: record.bidder.s2sAvailable
        }
      })
    }
  }

  private recordsToSave(): BidderSettingsQuery[] {
    return this.input.map((elem: BidderSettingsInput): BidderSettingsQuery => {
      const body: BidderSettingsQuery = {}
      const bidderSettingId = elem.id
      const bidCpmAdjustment = parseFloat(String(elem.bidCpmAdjustment))

      if (bidderSettingId) {
        this.addToUtilisedIfExist(bidderSettingId)
        body.id = bidderSettingId
      }

      if (bidCpmAdjustment) {
        body.bidCpmAdjustment = bidCpmAdjustment
      }

      body.bidderId = elem.bidderId.value
      body.bidderCode = elem.bidderCode
      body.bidderSid = elem.bidderSid
      body.priority = elem.priority.value

      return body
    })
  }

  private recordsToRemove(): BidderSettingsQuery[] {
    return this.existingBidderSettings
      .filter((elem) => !this.utilisedBidderSettingsIds.includes(elem.id))
      .map((elem) => {
        return { id: elem.id, _destroy: true }
      })
  }

  private addToUtilisedIfExist(bidderSettingId: string): string | void {
    const bidderSetting = this.existingBidderSettings.find((elem) => elem.id === bidderSettingId)

    if (bidderSetting && !this.utilisedBidderSettingsIds.includes(bidderSettingId)) {
      this.utilisedBidderSettingsIds.push(bidderSettingId)
    }
  }
}
