import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { GeneralSettingsForm } from 'components/quickwrap/Setup/GeneralSettings'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { SettingsSerializer } from 'serializers/SettingsSerializer'
import { UserContext } from 'webapp/context/UserContext'

import GeneralSettingsQuery from 'gql/queries/settings/GeneralSettings.gql'
import UpdateGeneralSetting from 'gql/mutations/settings/UpdateGeneralSetting.gql'

interface SettingsData {
  generalSettings: GeneralSetting
}

export const GeneralSettingsContainer: React.FC = () => {
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [settings, setSettings] = useState<GeneralSetting | undefined>(undefined)
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const user: User = useContext(UserContext).user as User

  useQuery<SettingsData>(GeneralSettingsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ generalSettings }) => {
      setSettings(generalSettings)
    }
  })

  const [updateGeneralSetting, { loading: mutationLoading }] = useMutation(UpdateGeneralSetting, {
    onCompleted: ({ updateGeneralSetting: { generalSetting, errors } }) => {
      if (generalSetting) {
        setSettings(generalSetting)
        createNotifier(t('generalSettings.edit.successMessage'), NotificationType.SUCCESS)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const updateSettingHandler = useCallback(
    (settings: GeneralSettingForm) => {
      const serializer = new SettingsSerializer(settings, user.workspace?.id || '')
      updateGeneralSetting({ variables: { input: serializer.updateParams() } })
    },
    [updateGeneralSetting, user]
  )

  return (
    <Box className='general-settings' isExpandable={true}>
      <BoxHeader title={t('generalSettings.title')} subtitle={t('generalSettings.subtitle')} />
      {settings && (
        <BoxBody>
          <GeneralSettingsForm
            settings={settings}
            errors={errors}
            onSubmit={updateSettingHandler}
            isSubmitting={mutationLoading}
          />
        </BoxBody>
      )}
    </Box>
  )
}
