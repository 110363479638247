import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Column } from 'react-table'

import { DomainRowData } from 'components/shared/Inventory/Domains/DomainsTable/DomainRowData'
import { QueryTable, QueryTableProps } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { UserContext } from 'webapp/context/UserContext'
import { useTableState } from 'webapp/hooks/useTableState'
import { columnsConfig } from './columnsConfig'

import DOMAINS_QUERY from 'gql/queries/domains/Domains.gql'
import DOMAINS_AS_ADMIN_QUERY from 'gql/queries/domains/DomainsAsAdmin.gql'

const HIDDEN_COLUMNS = ['workspace', 'wrapperVersion']
const SEARCHABLE_FIELDS = ['name']

export const DomainsTable = (): JSX.Element => {
  const { isSuperuser } = useContext(UserContext)
  const history = useHistory()

  const { pageIndex, setPageIndex } = useTableState()

  const hiddenColumns = isSuperuser ? [] : HIDDEN_COLUMNS
  const mapper = (node: VersionedDomain) => new DomainRowData(node)

  const redirectToEditDomain: QueryTableProps<DomainRowData>['onRowClick'] = (row) => {
    const { id } = row.original.domain as Domain

    history.push(routesBuilder.oss.inventory.domains.edit(id), { pageIndex })
  }

  return (
    <QueryTable<DomainRowData>
      collectionName={CollectionNames.Domains}
      columnsConfig={columnsConfig as Column[]}
      dataQuery={isSuperuser ? DOMAINS_AS_ADMIN_QUERY : DOMAINS_QUERY}
      hiddenColumns={hiddenColumns}
      mapper={mapper}
      onRowClick={redirectToEditDomain}
      pageIndex={pageIndex}
      searchableFields={SEARCHABLE_FIELDS}
      setPageIndex={setPageIndex}
    />
  )
}
