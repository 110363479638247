import React, { useCallback } from 'react'

import RequestModal from './RequestModal'
import { ErrorUtils } from 'utilities/errorUtils'

interface Props {
  description: string
  errorKey: string
  errors: ErrorUtils
  isSubmitting: boolean
  link?: string
  modalTitle: string
  modalPlaceholder?: string
  visible: boolean
  onSubmitHandler: (value: string) => void
  setVisible: (visible: boolean) => void
}

export const RequestNewElementContainer: React.FC<Props> = ({
  description,
  errorKey,
  errors,
  isSubmitting,
  link = 'Click here',
  modalTitle,
  modalPlaceholder,
  visible,
  onSubmitHandler,
  setVisible
}) => {
  const openRequestModal = useCallback(() => setVisible(true), [])

  return (
    <div className='request-new-element__container'>
      <span>{description}</span>
      <span className='request-new-element__link' onClick={openRequestModal}>
        {link}
      </span>

      <RequestModal
        visible={visible}
        setVisible={setVisible}
        onSendRequestHandler={onSubmitHandler}
        title={modalTitle}
        placeholder={modalPlaceholder}
        errorKey={errorKey}
        errors={errors}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}
