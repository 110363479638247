import React, { useContext, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { ConfirmationModal } from 'components/Modals/ConfirmationModal'
import { DataStudioLinkRowData } from 'components/oss/Performance/DataStudioLinks/Table/DataStudioLinkRowData'
import { DropdownMenu } from 'components/DropdownMenu'
import { Edit } from 'icons/Edit'
import { Eye } from 'icons/Eye'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ProductConnectionSerializer } from 'serializers/ProductConnectionSerializer'
import { routesBuilder } from 'utilities/routesBuilder'

import { UPDATE_PRODUCT_CONNECTION } from 'gql/mutations/productConnections/UpdateProductConnection'

interface Props {
  row: Row<DataStudioLinkRowData>
}

export const ActionsCell: React.FC<Props> = ({ row }) => {
  const productRow = row.original

  const { t } = useTranslation('dataStudio')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const onPreviewHandler = () => {
    history.push(routesBuilder.oss.performance.dataStudioLinks.preview(productRow.id))
  }

  const onEditHandler = () => {
    history.push(routesBuilder.oss.performance.dataStudioLinks.edit(productRow.id))
  }

  const onDeleteHandler = () => {
    setIsDeleteModalVisible(true)
    setIsDropdownVisible(false)
  }

  const productConnectionInfo = {
    subject: `${productRow.workspaceName} ${t(`products:product.${productRow.product}`)}`
  }

  const [updateProductConnection] = useMutation(UPDATE_PRODUCT_CONNECTION, {
    onCompleted: ({ updateProductConnection: { productConnection } }) => {
      setIsDeleteModalVisible(false)
      if (productConnection) {
        createNotifier(
          t('links.modal.deleteConfirm.notification.success', productConnectionInfo),
          NotificationType.SUCCESS
        )
        history.push(history.location)
      } else {
        createNotifier(t('links.modal.deleteConfirm.notification.error', productConnectionInfo), NotificationType.ERROR)
      }
    },
    onError: () => {
      createNotifier(t('links.modal.deleteConfirm.notification.error', productConnectionInfo), NotificationType.ERROR)
    }
  })

  const confirmHandler = () => {
    updateProductConnection(nestGqlInput(ProductConnectionSerializer.unsetDataStudioUrl(productRow.productConnection)))
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownMenu setVisible={setIsDropdownVisible} visible={isDropdownVisible}>
        <MenuItem onClick={onPreviewHandler}>
          {t('common:buttons.preview')} <Eye />
        </MenuItem>

        <MenuItem onClick={onEditHandler}>
          {t('common:buttons.edit')} <Edit />
        </MenuItem>

        <MenuItem onClick={onDeleteHandler}>
          {t('common:buttons.delete')} <Bin />
        </MenuItem>
      </DropdownMenu>

      <ConfirmationModal
        visible={isDeleteModalVisible}
        setVisible={setIsDeleteModalVisible}
        content={t('links.modal.deleteConfirm.content', productConnectionInfo)}
        confirmButtonText={t('common:buttons.delete')}
        cancelButtonText={t('common:buttons.cancel')}
        onConfirmHandler={confirmHandler}
      />
    </div>
  )
}
