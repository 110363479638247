import React, { memo, useState } from 'react'
import classNames from 'classnames'
import Tooltip from 'react-tooltip-lite'

interface Props {
  className?: string
  direction?: string
  warning?: boolean
  title?: string | React.ReactNode
}

const QWTooltip: React.FC<Props> = memo(({ children, className, direction = 'right-start', warning, title }) => {
  const classString = classNames('qw-tooltip', { 'qw-tooltip--warning': warning }, className)
  const tooltipIcon = warning ? '!' : '?'
  const [isOpen, setIsOpen] = useState(false)

  if (!title) {
    return null
  }

  return (
    <Tooltip
      content={title}
      direction={direction}
      className={classString}
      tipContentHover={true}
      arrow={false}
      isOpen={isOpen}
      zIndex={1301}
    >
      <span
        onPointerEnter={() => setIsOpen(true)}
        onPointerLeave={() => setIsOpen(false)}
        className={children ? '' : 'qw-tooltip__icon'}
      >
        {children ?? tooltipIcon}
      </span>
    </Tooltip>
  )
})

QWTooltip.displayName = 'Tooltip'

export { QWTooltip }
