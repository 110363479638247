import React from 'react'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'

import { formStatus } from 'utilities/FormStatus'
import { PromptModal } from 'components/Modals'

const getUserConfirmation: (message: string, callback: (result: boolean) => void) => void = (promptType, callback) => {
  if (formStatus.isAnyDirty) {
    const node = document.getElementById('prompt-placeholder')

    const cleanUp = (answer: boolean) => {
      if (node) {
        ReactDOM.unmountComponentAtNode(node)
      }
      callback(answer)
    }

    ReactDOM.render(<PromptModal name={promptType} cleanUp={cleanUp} />, node)
  } else {
    callback(true)
  }
}

const customHistory = createBrowserHistory({ getUserConfirmation })

export { customHistory, getUserConfirmation }
