import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { DataStudioForm } from 'components/oss/Finance/DataStudioLinks/Form/DataStudioForm'
import { LoadingContainer } from 'components/LoadingContainer'
import { useUpdateWorkspaceUrl } from 'components/oss/Finance/DataStudioLinks/Form/useUpdateWorkspaceUrl'

import WorkspaceQuery from 'gql/queries/workspaces/Workspace.gql'

export const EditPage: React.FC = () => {
  const { t } = useTranslation('dataStudio')
  const { workspaceId } = useParams<{ workspaceId: string }>()

  const [workspace, setWorkspace] = useState<Workspace>()
  useQuery(WorkspaceQuery, {
    fetchPolicy: 'network-only',
    variables: { workspaceId },
    onCompleted: ({ workspace }) => {
      setWorkspace(workspace)
    }
  })

  const [updateWorkspace, errors, isSubmitting] = useUpdateWorkspaceUrl('edit')

  return (
    <Box className='data-studio-links-edit'>
      <BoxHeader title={t('edit.title')} />
      <LoadingContainer loading={!workspace}>
        <DataStudioForm errors={errors} isSubmitting={isSubmitting} onSubmit={updateWorkspace} workspace={workspace} />
      </LoadingContainer>
    </Box>
  )
}
