import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { NetworkFactory } from 'components/quickwrap/Setup/Networks/NetworksForm/formSchema'
import { NetworksForm } from 'components/quickwrap/Setup/Networks/NetworksForm'

export const NewPage = (): JSX.Element => {
  const { t } = useTranslation('setup')
  const [network] = useState<Network>()

  return (
    <Box>
      <BoxHeader title={t('networks.new.title')} />

      <NetworksForm network={NetworkFactory(network)} />
    </Box>
  )
}
