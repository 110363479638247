import React, { useMemo } from 'react'
import classNames from 'classnames'

import { Tooltip } from 'components/Form'

interface Props {
  htmlFor?: string
  disabled?: boolean
  className?: string
  tooltipContent?: string | React.ReactNode
}

export const Label: React.FC<Props> = ({ htmlFor, disabled, className, children, tooltipContent }) => {
  const classString = useMemo(() => {
    return classNames('qw-label', className, {
      'qw-label--disabled': disabled
    })
  }, [disabled, className])

  return (
    <div className='qw-label__container'>
      <label htmlFor={htmlFor} className={classString}>
        {children}
      </label>
      <Tooltip title={tooltipContent} />
    </div>
  )
}
