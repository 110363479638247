import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Column, Row } from 'react-table'

import { DomainRowData } from 'components/shared/Inventory/Domains/DomainsTable/DomainRowData'
import { QueryTable } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { UserContext } from 'webapp/context/UserContext'
import { useTableState } from 'webapp/hooks/useTableState'
import { columnsConfig } from './columnsConfig'

import DomainsQuery from 'gql/queries/domains/Domains.gql'

const HIDDEN_COLUMNS = ['workspace']
const SEARCHABLE_FIELDS = ['name']

export const DomainsTable: React.FC = () => {
  const history = useHistory()
  const { isSuperuser } = useContext(UserContext)

  const { pageIndex, setPageIndex } = useTableState()

  const hiddenColumns = isSuperuser ? [] : HIDDEN_COLUMNS
  const mapper = (node: VersionedDomain) => new DomainRowData(node)

  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    const domain = row.original.domain as unknown as Domain
    history.push(routesBuilder.quickwrap.inventory.domains.edit(domain.id, domain.activeVersion.id), { pageIndex })
  }

  return (
    <QueryTable<DomainRowData>
      collectionName={CollectionNames.Domains}
      columnsConfig={columnsConfig as Column[]}
      dataQuery={DomainsQuery}
      hiddenColumns={hiddenColumns}
      mapper={mapper}
      onRowClick={openEditingPage}
      pageIndex={pageIndex}
      searchableFields={SEARCHABLE_FIELDS}
      setPageIndex={setPageIndex}
    />
  )
}
