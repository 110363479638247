import React from 'react'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import { FormikToggleContainer as FormikToggle } from 'containers/FormikContainers'
import { StatusForm } from 'components/Form'

interface Props {
  apsPubId?: string
  errors: Errors
  formData: AdUnitAmazonDemandFormData
  isSubmitting: boolean
  onSubmit: (formData: AdUnitAmazonDemandFormData) => void
}

export const AdUnitsAmazonDemandForm: React.FC<Props> = ({ apsPubId, errors, formData, isSubmitting, onSubmit }) => {
  const { t } = useTranslation('inventory')

  return (
    <div className='ad_unit-form ad_unit-form__amazon'>
      <Formik initialValues={formData} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='ad-unit-amazon-demand' dirty={dirty}>
            <Field
              id='ad_unit-aps-enabled'
              className='amazon-demand-form'
              name='apsEnabled'
              errors={errors.errorsFor('apsEnabled')}
              label={t('adUnits.form.inputs.apsEnabled.label')}
              tooltipContent={t('adUnits.form.inputs.apsEnabled.tooltip')}
              component={FormikToggle}
              labelStyle='bold'
              disabled={!apsPubId}
            />
            <Button
              className='amazon-form__submit'
              disabled={isSubmitting || !dirty}
              type={ButtonType.Primary}
              theme={ButtonTheme.Orange}
              buttonType='submit'
            >
              {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
