import { useMemo } from 'react'

type KeyedConnectionTypes = {
  [versionId: string]: {
    [bidderSettingId: string]: {
      active: boolean
      connectionType: DomainVersionBidderConnection['connectionType']
    }
  }
}

export const useGetConnectionType = (domains: Domain[]) => {
  return useMemo(() => {
    const keyedConnectionTypes = domains.reduce((acc, domain) => {
      domain.versions.nodes.forEach((version) => {
        version.bidderConnections.forEach((bidderConnection) => {
          if (!acc[version.id]) {
            acc[version.id] = {}
          }
          acc[version.id][bidderConnection.bidderSettingId] = {
            active: bidderConnection.active,
            connectionType: bidderConnection.connectionType
          }
        })
      })
      return acc
    }, {} as KeyedConnectionTypes)

    return (versionId: string, bidderSettingId: string) => {
      return keyedConnectionTypes[versionId]?.[bidderSettingId] || null
    }
  }, [domains])
}
