import { Product } from 'webapp/constants/Product'
import { RouteProductPrefix } from 'webapp/constants/RouteProductPrefix'

export const reviveRoutes = {
  revive: {
    configuration: {
      edit: (reviveDomainConfigurationId = ':reviveDomainConfigurationId'): string =>
        `/${RouteProductPrefix[Product.Revive]}/configuration/${reviveDomainConfigurationId}/edit`,
      root: `/${RouteProductPrefix[Product.Revive]}/configuration`
    },

    performance: {
      dataStudio: `/${RouteProductPrefix[Product.Revive]}/performance/data-studio`
    },

    portal: {
      root: `/${RouteProductPrefix[Product.Revive]}/portal`
    }
  }
}
