import Avatar1 from 'images/avatars/avatar1.png'
import Avatar2 from 'images/avatars/avatar2.png'
import Avatar3 from 'images/avatars/avatar3.png'
import Avatar4 from 'images/avatars/avatar4.png'
import Avatar5 from 'images/avatars/avatar5.png'
import Avatar6 from 'images/avatars/avatar6.png'
import Avatar7 from 'images/avatars/avatar7.png'
import Avatar8 from 'images/avatars/avatar8.png'
import Avatar9 from 'images/avatars/avatar9.png'

const avatars = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6, Avatar7, Avatar8, Avatar9]

export default avatars
