import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { CollectionField } from 'components/FormikComponents'
import { DomainNetworksFactory } from './formSchema'
import { DomainNetworkRow } from './DomainNetworkRow'
import { DomainNetworkSerializer } from 'serializers/DomainNetworkSerializer'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { StatusForm } from 'components/Form'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import DomainNetworks from 'gql/queries/domainNetworks/DomainNetworks.gql'
import NetworksQuery from 'gql/queries/networks/Networks.gql'
import UpsertDomainNetworks from 'gql/mutations/domainNetworks/UpsertDomainNetworks.gql'

type DomainNetworksContainerType = {
  domain: Domain
}

export const DomainNetworksContainer = ({ domain }: DomainNetworksContainerType): JSX.Element => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('inventory')
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [domainNetworks, setDomainNetworks] = useState<DomainNetwork[]>([])
  const [networks, setNetworks] = useState<Network[]>([])

  useQuery(DomainNetworks, {
    fetchPolicy: 'network-only',
    variables: { domainId: domain.id },
    onCompleted: ({ domainNetworks }) => {
      setDomainNetworks(domainNetworks.nodes)
    }
  })

  useQuery(NetworksQuery, {
    fetchPolicy: 'network-only',
    variables: { workspaceId: domain.workspace.id },
    onCompleted: ({ networks }) => {
      setNetworks(networks.nodes)
    }
  })

  const [upsertDomainNetworks, { loading: isFormLoading }] = useMutation(UpsertDomainNetworks, {
    onCompleted: ({ upsertDomainNetworks: { domainNetworks, errors } }) => {
      if (domainNetworks) {
        setDomainNetworks(domainNetworks)
        createNotifier(t('domains.edit.domainNetworks.successMessage'), NotificationType.SUCCESS)
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      }

      errors && setErrors(new ErrorUtils(errors))
    }
  })

  const handleOnSubmit = (formData: { domainNetworks: DomainNetworkForm[] }) => {
    upsertDomainNetworks(
      nestGqlInput(new DomainNetworkSerializer(formData, domain, networks, domainNetworks).updateParams())
    )
  }

  return (
    <>
      {networks?.length > 0 && domainNetworks && (
        <Box className='domain-schain'>
          <BoxHeader title={t('domains.edit.domainNetworks.title')} />
          <BoxBody>
            <Formik
              initialValues={{ domainNetworks: DomainNetworksFactory(domainNetworks, networks) }}
              onSubmit={handleOnSubmit}
              enableReinitialize={true}
            >
              {({ dirty }) => {
                return (
                  <StatusForm name='domainNetworksForm' dirty={dirty}>
                    <div className='box__body'>
                      <CollectionField
                        addNextHidden={true}
                        removeHidden={true}
                        collectionName={'domainNetworks'}
                        emptyElementTemplate={{}}
                        name='domainNetworks'
                      >
                        {({ index }) => <DomainNetworkRow errors={errors} index={index} />}
                      </CollectionField>

                      <Button
                        disabled={!dirty || isFormLoading}
                        type={ButtonType.Primary}
                        theme={ButtonTheme.Orange}
                        buttonType='submit'
                      >
                        {t('common:buttons.save')}
                      </Button>
                    </div>
                  </StatusForm>
                )
              }}
            </Formik>
          </BoxBody>
        </Box>
      )}
    </>
  )
}
