import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { NotificationType } from 'components/ToastNotifier'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { UserContext } from 'webapp/context/UserContext'
import { WorkspacePackagesFactory } from './formSchema'
import { WorkspacePackagesForm } from 'components/oss/Workspaces/WorkspacePackagesForm'
import { WorkspacePackagesSerializer } from 'serializers/WorkspacePackagesSerializer'

import CurrentWorkspaceQuery from 'gql/queries/workspaces/CurrentWorkspace.gql'
import UpdateWorkspace from 'gql/mutations/workspaces/UpdateWorkspace.gql'

export const WorkspacePackagesContainer: React.FC = () => {
  const { t } = useTranslation('workspaces')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { reloadUser } = useContext(UserContext)

  const [workspacePackages, setWorkspacePackages] = useState<Array<WorkspacePackage>>([])

  const [currentWorkspaceQuery] = useLazyQuery(CurrentWorkspaceQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ workspace }) => {
      setWorkspacePackages(workspace.packages)
    }
  })

  useEffect(() => {
    currentWorkspaceQuery({})
  }, [])

  const [updateWorkspace] = useMutation(UpdateWorkspace, {
    onCompleted: ({ updateWorkspace: { workspace } }) => {
      if (workspace) {
        currentWorkspaceQuery()
        createNotifier(t('packages.successMessage'), NotificationType.SUCCESS)
        reloadUser()
      }
    }
  })

  const onSubmit = (values: Partial<WorkspacePackagesForm>) => {
    const serializer = new WorkspacePackagesSerializer(workspacePackages)
    updateWorkspace(nestGqlInput(serializer.updateParams(values)))
  }

  return (
    <Box className='add-ons__container' isExpandable={true}>
      <BoxHeader title={t('packages.title')} subtitle={t('packages.subtitle')} />
      <BoxBody>
        <WorkspacePackagesForm onSubmitHandler={onSubmit} defaultValues={WorkspacePackagesFactory(workspacePackages)} />
      </BoxBody>
    </Box>
  )
}
