/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ABTestForm } from 'components/quickwrap/ABTesting/Form'
import { AbTestFactory } from 'components/quickwrap/ABTesting/Form/formSchema'
import { ABTestSerializer } from 'serializers/ABTestSerializer'
import { Box, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { routesBuilder } from 'utilities/routesBuilder'
import { useScrollUp } from 'utilities/useScrollUp'

import CreateABTest from 'gql/mutations/abTesting/CreateABTest.gql'
import DomainsQuery from 'gql/queries/domains/DomainsWithVersionsSelect.gql'

export const NewPage: React.FC = () => {
  const { t } = useTranslation('abTesting')

  const history = useHistory<History>()
  const { state }: { state: any } = history.location

  const [errors, setErrors] = useState(new ErrorUtils([]))
  const { createNotifier } = useContext(NotificationContext) as Notification

  const { data: domainsData } = useQuery(DomainsQuery, { fetchPolicy: 'network-only' })
  const domains = useMemo(() => {
    return (domainsData?.domains?.nodes ?? []).map((domain: Domain) => domain)
  }, [domainsData])

  useScrollUp()

  const [createABTest, { loading }] = useMutation(CreateABTest, {
    onCompleted: ({ createABTest: { abTest, errors } }) => {
      if (abTest) {
        createNotifier(t('new.successMessage', { name: abTest.name }), NotificationType.SUCCESS)
        formStatus.clear()
        history.push(routesBuilder.quickwrap.abTesting.edit(abTest.id))
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
        setErrors(new ErrorUtils(errors))
      }
    }
  })

  const createABTestHandler = (abTest: ABTestFormData) => {
    const serializer = new ABTestSerializer(abTest)
    createABTest({ variables: { input: serializer.createParams() } })
  }

  return (
    <Box>
      <BoxHeader title={t('new.title')} />
      <ABTestForm
        errors={errors}
        isSubmitting={loading}
        abTest={AbTestFactory(undefined, state?.domain)}
        domains={domains}
        onSubmit={createABTestHandler}
      />
    </Box>
  )
}
