import React, { useState } from 'react'
import { useField, FieldConfig } from 'formik'
import { Grid } from '@material-ui/core'

import { CheckboxTreeList } from 'components/Form/CheckboxTreeList'
import { ErrorMessages } from 'components/Form/ErrorMessages'
import { RadioButton } from 'components/Form'
import { useTranslation } from 'react-i18next'

type FormikTreeInventoryPickerContainerType = {
  blockUrlGroup: BlockUrlGroup
  domains: Domain[]
  errors: string[]
  field: FieldConfig
  placeholder: string
}

enum InventorySelectMethod {
  All = 'all',
  Specified = 'specified'
}

export const FormikTreeInventoryPickerContainer = ({
  blockUrlGroup,
  domains,
  errors,
  placeholder,
  ...other
}: FormikTreeInventoryPickerContainerType): JSX.Element => {
  const { t } = useTranslation('protection')
  const [field, , helpers] = useField(other.field.name)
  const [method, setMethod] = useState(
    blockUrlGroup.domains.length === domains.length ? InventorySelectMethod.All : InventorySelectMethod.Specified
  )
  const [specifiedData, setSpecifiedData] = useState(field.value)

  const createCheckpointListStructure = (domains: Pick<Domain, 'id' | 'name'>[]): CheckboxTreeListDataItem[] => [
    {
      key: 'domains',
      label: 'Domains',
      children: domains.map((domain) => ({
        key: `domain:${domain.id}`,
        label: domain.name,
        value: domain.id
      }))
    }
  ]

  const onUpdateHandler = (newItems: string[]) => {
    helpers.setValue(newItems)
  }

  const changeMethodHandler = (value: string) => {
    setMethod(value as InventorySelectMethod)

    if (value === InventorySelectMethod.All) {
      setSpecifiedData(field.value)
      helpers.setValue(domains.map((domain) => domain.id))
    } else {
      helpers.setValue(specifiedData)
    }
  }

  return (
    <>
      <label className='qw-label'>{t('blockUrlGroups.form.inputs.domains.title')}</label>
      <Grid container className='mt-2'>
        <Grid item lg={3} xs={3}>
          <RadioButton
            name='method'
            id='method-all'
            checked={method === InventorySelectMethod.All}
            label={'All'}
            value={InventorySelectMethod.All}
            onChange={changeMethodHandler}
          />
        </Grid>
        <Grid item lg={3} xs={3}>
          <RadioButton
            name='method'
            id='method-specified'
            checked={method === InventorySelectMethod.Specified}
            label={'Specified'}
            value={InventorySelectMethod.Specified}
            onChange={changeMethodHandler}
          />
        </Grid>
      </Grid>
      {method === InventorySelectMethod.Specified && (
        <CheckboxTreeList
          dataStructure={createCheckpointListStructure(domains)}
          placeholder={placeholder}
          updateHandler={onUpdateHandler}
          values={field.value}
        />
      )}
      <ErrorMessages errors={errors} />
    </>
  )
}
