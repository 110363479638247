import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Can } from 'webapp/context/AbilityContext'
import FinanceIcon from 'images/finance_icon.svg'
import ProfileIcon from 'images/profile_icon.svg'
import UserManagementIcon from 'images/user_management_icon.svg'
import WorkspaceIcon from 'images/workspace_icon.svg'
import { UserLink } from './UserLink'

import { routesBuilder } from 'utilities/routesBuilder'

const UserLinks: React.FC = memo(() => {
  const { t } = useTranslation()

  return (
    <div className='user-links__container'>
      <UserLink
        className={'user-links__link--profile'}
        path={routesBuilder.oss.profile.root}
        icon={ProfileIcon}
        alt={t('userWidget.profile')}
        label={t('userWidget.profile')}
      />
      <UserLink
        path={routesBuilder.oss.users.root}
        icon={UserManagementIcon}
        alt={t('userWidget.userManagement')}
        label={t('userWidget.userManagement')}
      />
      <Can I={AbilityAction.ACCESS} a={AbilitySubject.WORKSPACE_PATH}>
        <UserLink
          path={routesBuilder.oss.workspace.root}
          icon={WorkspaceIcon}
          alt={t('userWidget.workspace')}
          label={t('userWidget.workspace')}
        />
      </Can>
      <Can I={AbilityAction.ACCESS} a={AbilitySubject.WORKSPACES_PATH}>
        <UserLink
          path={routesBuilder.oss.workspaces.root}
          icon={WorkspaceIcon}
          alt={t('userWidget.workspaces')}
          label={t('userWidget.workspaces')}
        />
      </Can>
      <Can I={AbilityAction.ACCESS} a={AbilitySubject.FINANCE_DATA_STUDIO_PATH}>
        <UserLink
          path={routesBuilder.oss.finance.root}
          icon={FinanceIcon}
          alt={t('userWidget.finance')}
          label={t('userWidget.finance')}
        />
      </Can>
    </div>
  )
})

UserLinks.displayName = 'UserLinks'

export { UserLinks }
