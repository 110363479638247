import React, { memo } from 'react'

interface Props {
  src: string
  alt: string
  className?: string
  containerClassName?: string
  id?: string
}

const Image: React.FC<Props> = memo(({ src, alt, className, containerClassName, id }) => {
  return (
    <picture className={containerClassName} id={id}>
      <img className={className} src={src} alt={alt} />
    </picture>
  )
})

Image.displayName = 'Image'

Image.defaultProps = {
  className: '',
  containerClassName: ''
}

export { Image }
