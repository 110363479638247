import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ReportSummary } from 'components/quickwrap/Performance/Reports/ReportSummary'
import { currencyUtils } from 'utilities/currencyUtils'
import { formatTimeDate } from 'utilities/formatDate'
import { separatorNumberFormat } from 'utilities/formatNumbers'

interface Props {
  reportData: Array<ReportData>
  reportFormData: ReportFormType
  currencyCode: string
  isSubmitting: boolean
  onSubmitHandler: (reportForm: ReportFormValues) => void
  isEditReport: boolean
  setIsEditReport: (isEdit: boolean) => void
}

type ReportDataKeys = 'total_impressions' | 'total_revenue'
const REVENUE_DIVIDER = 1000000

export const ReportSummaryContainer: React.FC<Props> = ({
  reportData,
  reportFormData,
  currencyCode,
  isSubmitting,
  onSubmitHandler,
  isEditReport,
  setIsEditReport
}) => {
  const { t } = useTranslation('reports')

  const calculateValue = (data: Array<ReportData> = [], fieldName: ReportDataKeys) =>
    data.reduce((total, next) => total + parseInt(next[fieldName]?.toString() || '0'), 0)

  const totalRevenue = useMemo(() => {
    const result = parseInt(calculateValue(reportData, 'total_revenue').toString()) / REVENUE_DIVIDER
    return currencyUtils.currencyFormat(result, currencyCode)
  }, [currencyCode, reportData])

  const totalImpressions = useMemo(
    () => separatorNumberFormat(calculateValue(reportData, 'total_impressions')).toString(),
    [reportData]
  )

  const timeGenerated = useMemo(
    () => formatTimeDate(reportFormData.timeGenerated || ''),
    [reportFormData.timeGenerated]
  )

  return (
    <Box isExpandable={true}>
      <BoxHeader title={t('resultPage.summary.title', { networkName: reportFormData.networkName })} />
      <BoxBody>
        <div className='box__body'>
          <ReportSummary
            totalRevenue={totalRevenue}
            totalImpressions={totalImpressions}
            timeGenerated={timeGenerated}
            reportFormData={reportFormData}
            onSubmitHandler={onSubmitHandler}
            isSubmitting={isSubmitting}
            isEditReport={isEditReport}
            setIsEditReport={setIsEditReport}
          />
        </div>
      </BoxBody>
    </Box>
  )
}
