import React from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import NameFilter from 'components/NameFilter'
import { AdUnitsTable } from 'components/oss/Inventory/AdUnits/Table'
import { CollectionNames } from 'constants/CollectionNames'
import { Product } from 'constants/Product'
import { Can } from 'context/AbilityContext'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { AdUnitsContainerProps } from './types'
import { useFilterConfig } from './useFilterConfig'

const COLLECTION = CollectionNames.OssAdUnits

export const AdUnitsContainer = ({ perDomain, readOnly }: AdUnitsContainerProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const history = useHistory()
  const filterConfig = useFilterConfig()

  const redirectToAdd = (): void =>
    history.push(
      perDomain ? routesBuilder.oss.inventory.adUnit.add(perDomain) : routesBuilder.oss.inventory.adUnit.add(),
      { fromProduct: Product.OSS }
    )

  return (
    <Box className='ad-units' list={true}>
      <BoxHeader
        title={t('adUnits.list.title')}
        containerClassName='box-header__container'
        rowClassName='header__row--width-100'
      >
        <Can I={AbilityAction.EDIT} a={AbilitySubject.ADUNIT}>
          <Button onClickHandler={redirectToAdd} theme={ButtonTheme.Blue} type={ButtonType.Primary}>
            {t('common:buttons.add')}
          </Button>
        </Can>

        {!readOnly && (
          <div className='box-header__filters'>
            <AdvancedFilter alignPopup='right' collectionName={COLLECTION} config={filterConfig} />

            <NameFilter
              collection={COLLECTION}
              operator={'contains'}
              path={'ad_unit_path'}
              placeholder={t('adUnits.list.search.byAdUnitPath')}
            />
          </div>
        )}
      </BoxHeader>

      <AdUnitsTable perDomain={perDomain} readOnly={readOnly} />
    </Box>
  )
}
