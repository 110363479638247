import { useApolloClient } from '@apollo/client'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FilterType } from 'webapp/constants/FilterType'
import { RoleType } from 'webapp/constants/Role'
import { usePublisherRoleOptions, useSuperuserRoleOptions } from 'utilities/roles/options'
import { UserContext } from 'webapp/context/UserContext'
import { UserStatus } from 'webapp/constants/UserStatus'

import WorkspaceListQuery from 'gql/queries/workspaces/WorkspaceList.gql'

interface Cache {
  workspaceOptions?: SelectOption[]
}

export function useAdvancedFilterConfig(roleType?: RoleType): AdvancedFilterConfiguration<Cache>[] {
  const { t } = useTranslation('users')
  const client = useApolloClient()

  const { isSuperuser } = useContext(UserContext)

  const workspacesCacheConfig: AdvancedFilterSelectConfiguration<Cache>['cacheConfig'] = {
    checkIfAlreadyCached: (cache) => Boolean(cache.workspaceOptions),
    addToCache: () =>
      client.query({ query: WorkspaceListQuery, fetchPolicy: 'network-only' }).then((data) => ({
        workspaceOptions: data.data.workspaces.nodes.map(({ name }: Workspace) => ({
          label: name,
          value: name
        }))
      }))
  }

  const publisherRoleOptions = usePublisherRoleOptions()
  const superUserRoleOptions = useSuperuserRoleOptions()

  const statusOptions = [UserStatus.Active, UserStatus.PendingInvitation].map((status) => ({
    label: t(`statuses.${status}`),
    value: status
  }))

  return useMemo(() => {
    const showWorkspaces = isSuperuser && roleType !== RoleType.Superuser
    const roleOptions = roleType === RoleType.Superuser ? superUserRoleOptions : publisherRoleOptions

    return (
      [
        {
          label: t('filter.fullName'),
          name: 'fullName',
          operators: ['contains', 'not_contains'],
          type: FilterType.Input
        },
        {
          buildValueOptions: () => roleOptions,
          isMulti: true,
          label: t('filter.role'),
          name: 'role',
          type: FilterType.Select
        },
        {
          buildValueOptions: () => statusOptions,
          label: t('filter.status'),
          name: 'status',
          type: FilterType.Select
        },
        {
          label: t('filter.email'),
          name: 'email',
          operators: ['contains', 'not_contains'],
          type: FilterType.Input
        },
        showWorkspaces && {
          buildValueOptions: (cache) => cache.workspaceOptions ?? [],
          cacheConfig: workspacesCacheConfig,
          isMulti: true,
          label: t('filter.workspace'),
          name: 'workspace.name',
          type: FilterType.Select
        }
      ] as AdvancedFilterConfiguration[]
    ).filter(Boolean)
  }, [isSuperuser, publisherRoleOptions, roleType, superUserRoleOptions, workspacesCacheConfig])
}
