import React from 'react'

import { useTranslation } from 'react-i18next'

import { ConfirmationModal } from 'components/Modals'
import { Logo } from 'components/oss/Products/Logo'
import { getProductName } from 'utilities/products/utils'
import { ProductConfirmationProps } from './types'

import './style.scss'

const ProductConfirmationModal = ({
  productConnection: { product },
  setVisible,
  visible
}: ProductConfirmationProps): JSX.Element => {
  const { t } = useTranslation('products')

  return (
    <ConfirmationModal
      confirmButtonText={t('buttons.back')}
      content={t('activationModal.success', { product: getProductName(product) })}
      header={
        <div className='ProductConfirmationModal__header'>
          <Logo className='ProductConfirmationModal__logo' product={product} />
          <div className='ProductConfirmationModal__product-name'>{t(`product.${product}`)}</div>
        </div>
      }
      onConfirmHandler={() => setVisible(false)}
      setVisible={setVisible}
      visible={visible}
    />
  )
}

export default ProductConfirmationModal
