import React from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmationModal } from 'components/Modals'

interface Props {
  name: string
  cleanUp: (answer: boolean) => void
}

export const PromptModal: React.FC<Props> = ({ name, cleanUp }) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      content={t(`prompt.${name}.content`)}
      confirmButtonText={t(`prompt.${name}.confirm`)}
      cancelButtonText={t(`prompt.${name}.cancel`)}
      visible={true}
      setVisible={() => {}}
      onConfirmHandler={() => cleanUp(true)}
      onCancelHandler={() => cleanUp(false)}
    />
  )
}
