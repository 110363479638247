import React from 'react'

import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { ActionsCell } from 'components/shared/Inventory/Pages/PagesTable/ActionsCell'
import { PageRowData } from 'components/shared/Inventory/Pages/PagesTable/PageRowData'
import { DateCell } from 'components/Table'

export const columnsConfig: Array<Column<PageRowData>> = [
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.pageName'),
    accessor: 'pageName'
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.path'),
    accessor: 'path'
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.domain'),
    accessor: 'domainName'
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.domainVersion'),
    accessor: 'domainVersionName',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.prebidTimeout'),
    accessor: 'prebidTimeout',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.adUnitsCount'),
    accessor: 'ossAdUnitsCount',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    id: 'actions',
    Cell: ({ row }: CellProps<PageRowData>) => <ActionsCell row={row} />,
    disableSortBy: true
  }
]
