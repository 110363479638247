import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { PageData, PageSlice, PageVars, UseFetchPageProps } from './types'

import { PAGE_FOR_EDIT } from 'gql/queries/pages/Page'
import { NO_CACHE } from 'utilities/apolloClient'

export const useFetchPage = (): UseFetchPageProps => {
  const { pageId: urlPageId } = useParams<{ pageId: string }>()
  const [page, setPage] = useState<PageSlice>()
  const [domainId, setDomainId] = useState<Domain['id']>()
  const [currentVersion, setCurrentVersion] = useState<DomainVersion['id']>()

  useEffect(() => {
    getPageByUrlId()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getPageByVersion(currentVersion)
  }, [currentVersion]) // eslint-disable-line react-hooks/exhaustive-deps

  const [getPage, { loading: getPageLoading }] = useLazyQuery<PageData, PageVars>(PAGE_FOR_EDIT, {
    ...NO_CACHE,
    onCompleted: ({ page }: PageData): void => void setPage(page)
  })

  const getPageByUrlId = (): void => {
    getPage({ variables: { pageId: urlPageId } }).then(({ data }: { data?: PageData }) => {
      if (data) {
        const {
          page: {
            domain: { id: domainId },
            domainVersion
          }
        } = data

        setDomainId(domainId)
        setCurrentVersion(domainVersion?.id)
      }
    })
  }

  const getPageByVersion = (currentVersion?: DomainVersion['id']): void => {
    if (page?.domainVersion && currentVersion) {
      const {
        domainVersion: { id: pageDomainVersionId }
      } = page

      if (pageDomainVersionId !== currentVersion) {
        getPage({ variables: { domainVersionId: currentVersion, path: page.ossPage.path } })
      }
    }
  }

  return {
    currentVersion,
    domainId,
    getPageLoading,
    page,
    setCurrentVersion
  }
}
