import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ABTestsTable } from 'components/quickwrap/ABTesting/Table'
import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { routesBuilder } from 'utilities/routesBuilder'

export const ABTestingContainer: React.FC = () => {
  const { t } = useTranslation('abTesting')
  const history = useHistory<History>()

  const onClickHandler = () => history.push(routesBuilder.quickwrap.abTesting.new)

  return (
    <Box list={true}>
      <BoxHeader
        title={t('list.title')}
        containerClassName='box-header__container'
        rowClassName='header__row--width-100'
      >
        <div>
          <Button
            type={ButtonType.Primary}
            theme={ButtonTheme.Orange}
            className='ad-units__header-button'
            onClickHandler={onClickHandler}
          >
            {t('list.addNew')}
          </Button>
        </div>
      </BoxHeader>

      <ABTestsTable />
    </Box>
  )
}
