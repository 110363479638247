import React, { useEffect, useMemo, useRef } from 'react'
import { Grid } from '@material-ui/core'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { ABTestTotalTraffic } from 'components/quickwrap/ABTesting/Form/Sections/ABTestTotalTraffic'
import { ABTestVersionsRow } from 'components/quickwrap/ABTesting/Form/Sections/ABTestVersionsRow'
import { CollectionField } from 'components/FormikComponents'
import { ErrorMessages } from 'components/Form'
import { Errors } from 'utilities/errorUtils'
import { getEmptyVersionTemplate } from 'components/quickwrap/ABTesting/Form/formSchema'

interface Props {
  domains: Domain[]
  errors: Errors
  selectedDomainId: string
}

export const ABTestVersions: React.FC<Props> = ({ domains, errors, selectedDomainId }) => {
  const { t } = useTranslation('abTesting')
  const [fieldInput, , fieldHelpers] = useField<ABTestFormData['abTestVersionsAttributes']>('abTestVersionsAttributes')
  const [{ value: active = false }] = useField('active')
  const previousSelectedDomainId = useRef(selectedDomainId)

  useEffect(() => {
    if (previousSelectedDomainId.current !== selectedDomainId) {
      previousSelectedDomainId.current = selectedDomainId

      // Leave this, otherwise react-select shows stale UI
      fieldHelpers.setValue([])
      window.requestAnimationFrame(() => {
        fieldHelpers.setValue([getEmptyVersionTemplate(), getEmptyVersionTemplate()])
      })
    }
  }, [fieldHelpers, selectedDomainId, previousSelectedDomainId])

  const domainVersions = useMemo(() => {
    const selectedDomain = domains.find((domain: Domain) => domain.id === selectedDomainId)
    return selectedDomain?.versions.nodes ?? []
  }, [domains, selectedDomainId])

  const abTestVersionsErrors = [
    ...(domains.length && domainVersions.length < 2
      ? [t('form.inputs.abTestVersionsAttributes.notEnoughVersionsError')]
      : []),
    ...errors.errorsFor('abTestVersionsAttributes')
  ]

  if (!selectedDomainId) {
    return null
  }

  return (
    <div className='ab-test-versions'>
      <CollectionField
        name={'abTestVersionsAttributes'}
        addNextDisabled={domainVersions.length <= fieldInput.value.length}
        addNextHidden={active}
        collectionName='abTestVersionsAttributes'
        emptyElementTemplate={getEmptyVersionTemplate()}
        removeHidden={active}
      >
        {({ index }: { index: number }) => (
          <ABTestVersionsRow active={active} index={index} errors={errors} domainVersions={domainVersions} />
        )}

        <Grid container>
          <Grid item md={5} lg={4} xl={3}>
            <ErrorMessages errors={abTestVersionsErrors} />
          </Grid>
          <Grid item xs={5} className={'ab-test-share__total-traffic'}>
            <ABTestTotalTraffic />
          </Grid>
        </Grid>
      </CollectionField>
    </div>
  )
}
