import React from 'react'
import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { ActionsCell } from './ActionsCell'

export const columnsConfig = (): Column<Network>[] => [
  {
    Header: () => i18n.t('setup:networks:list.tableHeader.name'),
    accessor: 'name'
  },
  {
    Header: () => i18n.t('setup:networks:list.tableHeader.asi'),
    accessor: 'asi'
  },
  {
    Header: () => i18n.t('setup:networks:list.tableHeader.actions'),
    id: 'actions',
    disableSortBy: true,
    Cell: ({ row }: CellProps<Network>) => <ActionsCell network={row.original as Network} />
  }
]
