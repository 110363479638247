import React, { useCallback, useState, ReactType, useMemo } from 'react'
import useAutocomplete from '@material-ui/lab/useAutocomplete'
import classNames from 'classnames'

import { InputContainer } from 'components/Form/InputContainer'
import { Input } from './Input'
import { InputWrapper } from './InputWrapper'
import { Listbox } from './Listbox'
import { Tag } from './Tag'

interface Props {
  className?: string
  disabled?: boolean
  errors?: Array<string>
  id: string
  keyCollection?: Array<number>
  label?: string
  name: string
  onChangeHandler: (sizeValues: string[]) => void
  options?: Array<string>
  placeholder?: string
  single?: boolean
  TagComponent?: ReactType
  tooltipContent?: string
  values: string[]
}

export const TagInput: React.FC<Props> = ({
  TagComponent,
  className,
  disabled,
  errors,
  id,
  keyCollection = [32, 188],
  label,
  name,
  onChangeHandler,
  options,
  placeholder,
  single,
  tooltipContent,
  values
}) => {
  const [inputValue, setInputValue] = useState('')
  const { focused, getRootProps, getInputProps, getTagProps, getListboxProps, getOptionProps, groupedOptions, value } =
    useAutocomplete({
      id,
      inputValue,
      freeSolo: true,
      multiple: true,
      onChange: (_event, values) => {
        onChangeHandler(getFinalValueHandler(values))
      },
      onInputChange: (_event, value) => {
        setInputValue(value)
      },
      options: options?.filter((option) => !values.includes(option)) || [],
      value: values || []
    })

  const getFinalValueHandler = useCallback(
    (finalValues: string[]) => {
      return single && finalValues.length > 0 ? [finalValues[0]] : finalValues
    },
    [single]
  )

  const cleanInputHandler = useCallback(() => {
    setInputValue('')
  }, [setInputValue])

  const addInputValueHandler = useCallback(() => {
    let finalValues = values

    if (inputValue && !values?.find((value) => value === inputValue)) {
      finalValues = finalValues.concat([inputValue])
    }
    cleanInputHandler()
    onChangeHandler(getFinalValueHandler(finalValues))
  }, [cleanInputHandler, getFinalValueHandler, inputValue, onChangeHandler, values])

  // 8 - backspace, 13 - enter, 32 - space, 188 - comma.
  const onKeysDownHandler = useCallback(
    (event: React.KeyboardEvent<Element>) => {
      if (single && values.length > 0 && event.keyCode !== 8) {
        event.preventDefault()
      } else {
        if (keyCollection?.includes(event.keyCode)) {
          event.preventDefault()

          addInputValueHandler()
        }
      }
    },
    [addInputValueHandler, keyCollection, single, values.length]
  )

  const generateTag = (option: string, index: number) => {
    if (TagComponent) {
      return (
        <TagComponent
          added
          disabled={disabled}
          key={index}
          label={option}
          {...(getTagProps({ index }) as { onDelete: () => void })}
        />
      )
    } else {
      return (
        <Tag
          added
          disabled={disabled}
          key={index}
          label={option}
          {...(getTagProps({ index }) as { onDelete: () => void })}
        />
      )
    }
  }

  const inputContainerClass = useMemo(() => classNames('input__container', className), [className])

  const memoPlaceholder = useMemo(() => (value.length > 0 ? undefined : placeholder), [placeholder, value])

  return (
    <InputContainer
      className={inputContainerClass}
      disabled={disabled}
      errors={errors}
      inputId={name}
      label={label}
      tooltipContent={tooltipContent}
    >
      <InputWrapper
        disabled={disabled}
        hasError={errors && errors.length > 0}
        focused={focused}
        getRootProps={getRootProps}
      >
        {value.map((option, index) => generateTag(option, index))}

        <Input
          cleanInputHandler={cleanInputHandler}
          createNewTag={addInputValueHandler}
          disabled={disabled}
          getInputProps={getInputProps as () => { onBlur: () => void }}
          name={name}
          onKeysDownHandler={onKeysDownHandler}
          value={inputValue}
          TagComponent={TagComponent}
          placeholder={memoPlaceholder}
        />
        <Listbox getListboxProps={getListboxProps} getOptionProps={getOptionProps} groupedOptions={groupedOptions} />
      </InputWrapper>
    </InputContainer>
  )
}
