export const redirectRoutes = {
  redirects: {
    adUnit: {
      edit: (adUnitId = ':adUnitId'): string => `/ad_units/${adUnitId}/edit`,
      new: '/ad_units/new',
      root: '/ad_units'
    },

    domains: {
      edit: (domainId = ':domainId'): string => `/domains/${domainId}/edit`,
      new: '/domains/new',
      root: '/domains'
    },

    pages: {
      edit: (pageId = ':pageId'): string => `/pages/${pageId}/edit`,
      new: '/pages/new',
      root: '/pages'
    }
  }
}
