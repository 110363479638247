import React from 'react'
import { useField, FieldConfig } from 'formik'

import { Input } from 'components/Form'

interface InputBaseProps {
  id: string
  type: string
  className?: string
  containerClassName?: string
  onChangeHandler?: (value: string) => void
  disabled?: boolean
  placeholder?: string
  prefix?: string
  errors?: Array<string>
  hasErrors?: boolean
  label?: string
  labelStyle?: string
  tooltipContent?: string
  step?: number
  field: FieldConfig
}

interface InputProps {
  className: string
  containerClassName: string
  onChangeHandler: (value: string) => void
  disabled: boolean
  placeholder: string
  errors: Array<string>
  hasErrors: boolean
  isBeta?: boolean
  label: string
  labelStyle: string
  tooltipContent: string
  step: number
}

export const FormikInputContainer: React.FC<InputBaseProps & Partial<InputProps>> = ({
  id,
  type,
  className,
  containerClassName,
  onChangeHandler,
  disabled,
  placeholder,
  isBeta,
  label,
  labelStyle,
  prefix,
  tooltipContent,
  errors,
  hasErrors,
  step,
  ...other
}) => {
  const [field, , helpers] = useField(other.field.name)

  const onChange = (value: string) => {
    helpers.setValue(value)
    onChangeHandler && onChangeHandler(value)
  }

  return (
    <>
      <Input
        id={id}
        name={field.name}
        type={type}
        className={className}
        containerClassName={containerClassName}
        value={field.value ?? ''}
        onChangeHandler={onChange}
        disabled={disabled}
        placeholder={placeholder}
        prefix={prefix}
        isBeta={isBeta}
        label={label}
        labelStyle={labelStyle}
        tooltipContent={tooltipContent}
        errors={errors}
        hasErrors={hasErrors}
        step={step}
      />
    </>
  )
}
