import React from 'react'

interface Props {
  value: string[]
}

export const MediaTypesCell: React.FC<Props> = ({ value }) => {
  return (
    <div className='cell--ad-unit-type'>
      {value.map((type) => (
        <span key={type}>{type}</span>
      ))}
    </div>
  )
}
