import React, { memo } from 'react'

interface Props {
  title: string
  subtitle?: string
  icon?: string
}

const SidebarLinkContent: React.FC<Props> = memo(({ title, subtitle, icon }) => {
  return (
    <>
      {icon && (
        <div className='icon-svg'>
          <img src={icon} />
        </div>
      )}
      <div className='link__text'>
        <span className='link__title'>{title}</span>
        {subtitle && <span className='link__subtitle'>{subtitle}</span>}
      </div>
    </>
  )
})

SidebarLinkContent.displayName = 'SidebarLinkContent'

export { SidebarLinkContent }
