import React, { useCallback, useContext, useState } from 'react'

import { useLazyQuery, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { NotificationType } from 'components/ToastNotifier'
import { DownloadTagsForm } from 'components/shared/DownloadTags/Form/DownloadTagsForm'
import { DownloadTagSerializer } from 'serializers/DownloadTagSerializer'
import { downloadBlob } from 'utilities/downloadBlob'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'

import DomainsSelectQuery from 'gql/queries/domains/Domains.gql'
import DownloadTagsQuery from 'gql/queries/download_tags/DownloadTags.gql'

export const DownloadTagPage: React.FC = () => {
  const { loading, data } = useQuery(DomainsSelectQuery, { fetchPolicy: 'network-only' })
  const { t } = useTranslation()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [downloading, isDownloading] = useState(false)

  const [downloadTagQuery] = useLazyQuery(DownloadTagsQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ downloadTags }) => (isDownloading(false), downloadBlob(downloadTags)),
    onError: (error) => (isDownloading(false), createNotifier(error.message, NotificationType.ERROR))
  })

  const handleOnSubmit = useCallback(
    (serializer: DownloadTagSerializer) => {
      isDownloading(true)
      downloadTagQuery({ variables: serializer.getParams() })
    },
    [downloadTagQuery]
  )

  return (
    <Box>
      <BoxHeader title={t('downloadTags.title')} subtitle={t('downloadTags.subtitle')} />

      {!loading && (
        <BoxBody>
          <DownloadTagsForm isSubmitting={downloading} onSubmit={handleOnSubmit} domains={data.domains.nodes} />
        </BoxBody>
      )}
    </Box>
  )
}
