import React, { useState } from 'react'

import { filterStore } from 'utilities/filters/filterStore'
import { TagInput } from 'components/Form'

import glassImg from 'images/magnifying-glass.svg'

interface Props {
  collectionName: string
  filter: (values: string[]) => void
}

const DEFAULT_PAGE_SEARCH = 1
const EMPTY_KEY_COLLECTION: number[] = []

export const ReportFilter: React.FC<Props> = ({ collectionName, filter }) => {
  const [values, setValues] = useState<Array<string>>([])

  const onChangeHanlder = (values: Array<string>) => {
    setValues(values)
    filterStore.setFilter(collectionName, 'page', String(DEFAULT_PAGE_SEARCH))

    filter && filter(values)
  }

  return (
    <div className='search-bar search-bar__report-filter'>
      <TagInput
        id='report-filter'
        single={false}
        onChangeHandler={onChangeHanlder}
        name='report-filter'
        values={values}
        keyCollection={EMPTY_KEY_COLLECTION}
        placeholder='Search'
      />
      <img className='search-bar__icon' src={glassImg} />
    </div>
  )
}
