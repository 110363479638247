import React from 'react'

import { BoxProvider } from './BoxContext'

interface Props {
  isExpandable?: boolean
  defaultExpand?: boolean
  className?: string
  onToggle?: (isOpen: boolean) => void
  list?: boolean
}

export const Box: React.FC<Props> = ({ isExpandable, defaultExpand, className, children, onToggle, list }) => {
  const classNames = 'box' + (className ? ` ${className}` : '') + (list ? ' box-list' : '')

  return (
    <BoxProvider isExpandable={isExpandable} defaultExpand={defaultExpand} onToggle={onToggle}>
      <div className={classNames}>{children}</div>
    </BoxProvider>
  )
}
