import React from 'react'
import classNames from 'classnames'

import { InputContainer } from 'components/Form/InputContainer'

interface Props {
  label: string
  percent: number
}

export const TotalTrafficComponent: React.FC<Props> = ({ label, percent }) => {
  const isWrong = percent !== 0 && percent !== 100
  const percentsClassName = classNames('total-traffic__percents', { 'total-traffic__percents--wrong': isWrong })
  const barClassName = classNames('total-traffic__bar', { 'total-traffic__bar--wrong': isWrong })

  return (
    <InputContainer inputId={label} label={label} labelStyle='bold'>
      <div className={percentsClassName}>{percent}%</div>
      <div className='total-traffic__bar-container'>
        <div className={barClassName} style={{ width: `${percent}%` }} />
      </div>
    </InputContainer>
  )
}
