import React from 'react'
import { Field } from 'formik'

import { Errors } from 'webapp/utils/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'

type DomainNetworkRowType = {
  errors: Errors
  index: number
}

export const DomainNetworkRow = ({ errors, index }: DomainNetworkRowType): JSX.Element => {
  const isFirst = index === 0

  return (
    <div className='DomainNetworkRow'>
      <Field
        id={`domainNetworks-network-${index}`}
        name={`domainNetworks.${index}.network`}
        component={FormikInput}
        disabled={true}
        label={isFirst && 'Network'}
        labelStyle='bold'
        containerClassName='ml-3 mr-3 mt-3'
      />

      <Field
        id={`domainNetworks-asi-${index}`}
        name={`domainNetworks.${index}.asi`}
        component={FormikInput}
        disabled={true}
        label={isFirst && 'ASI'}
        labelStyle='bold'
        containerClassName='mr-3 ml-3 mt-3'
      />

      <Field
        id={`domainNetworks-sid-${index}`}
        name={`domainNetworks.${index}.sid`}
        component={FormikInput}
        label={isFirst && 'SID'}
        labelStyle='bold'
        containerClassName='ml-3 mt-3'
        errors={errors.errorsFor(['domainNetworksAttributes', index.toString(), 'sid'])}
      />
    </div>
  )
}
