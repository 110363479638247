import i18n from 'webapp/i18n'

const optionNames = [
  'offensive',
  'discrimination',
  'pornography',
  'tobacco',
  'weapons',
  'provocations',
  'illegal',
  'copyrights',
  'counterfeit',
  'spyware',
  'harassment',
  'paytosurf',
  'slanders',
  'spam',
  'alcohol',
  'gambling',
  'lotteries',
  'medicines',
  'finances',
  'politics'
]

export const restrictedContentOptions: SelectOption[] = optionNames.map((name: string): SelectOption => {
  return {
    label: i18n.t(`setup:recommendBidders.form.restrictedContent.values.${name}`),
    value: name
  }
})
