import React from 'react'

import { ReviveDomainConfigurationRow } from '../ReviveDomainConfigurationRow'
import { AdUnitsCounterCellProps } from './types'

import './style.scss'

export const AdUnitsCounterCell = ({ row }: AdUnitsCounterCellProps): JSX.Element => {
  const { ossAdUnitsCount, ossAdUnitsEnabledCount }: ReviveDomainConfigurationRow = row.original

  return (
    <>
      <span className='AdUnitsCounterCell--enabled-ad-units'>{ossAdUnitsEnabledCount}</span>/{ossAdUnitsCount}
    </>
  )
}
