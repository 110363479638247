import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { DomainsContainer } from 'containers/oss/Inventory/Domains/DomainsContainer'
import { EditPage } from 'pages/oss/Inventory/Domains/EditPage'
import { NewPage } from 'pages/oss/Inventory/Domains/NewPage'
import { routesBuilder } from 'utilities/routesBuilder'
import { Product } from 'webapp/constants/Product'

export const DomainRoutes = [
  <LayoutRoute
    key='domains-list'
    product={Product.OSS}
    path={routesBuilder.oss.inventory.domains.root}
    exact={true}
    component={DomainsContainer}
  />,
  <LayoutRoute
    key='domains-edit'
    product={Product.OSS}
    path={routesBuilder.oss.inventory.domains.edit()}
    component={EditPage}
  />,
  <LayoutRoute
    key='domains-new'
    product={Product.OSS}
    path={routesBuilder.oss.inventory.domains.new}
    component={NewPage}
    userPermissionRequired={true}
  />
]
