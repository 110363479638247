import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ButtonTheme, ButtonType } from 'components/Button'
import { LinkButton } from 'components/LinkButton'

export const ResetPasswordCompletedContainer: React.FC = () => {
  const { t } = useTranslation('auth')

  return (
    <Grid container spacing={4} className='password-recovery'>
      <Grid item lg={6} xs={8}>
        <header className='password-recovery__header'>
          <h2 className='user-flow__title'>{t('resetPasswordCompleted.title')}</h2>
          <span className='user-flow__subtitle'>{t('resetPasswordCompleted.subtitle')}</span>
        </header>

        <LinkButton to='/log_in' theme={ButtonTheme.Blue} type={ButtonType.Primary}>
          {t('resetPasswordCompleted.button')}
        </LinkButton>
      </Grid>
    </Grid>
  )
}
