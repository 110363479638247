import React from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { useTranslation } from 'react-i18next'
import { Errors } from 'utilities/errorUtils'
import { FormikCodeEditorContainer as CodeEditor } from 'containers/FormikContainers'

interface Props {
  errors: Errors
  disabled?: boolean
}

export const CommonInputs: React.FC<Props> = ({ errors, disabled }) => {
  const { t } = useTranslation('setup')

  return (
    <>
      <Grid item xs={12} md={12}>
        <Field
          id='custom-code-code-before'
          name='codeBefore'
          type='text'
          label={t('customCodes.form.codeBefore.label')}
          tooltipContent={t('customCodes.form.codeBefore.tooltip')}
          component={CodeEditor}
          errors={errors.errorsFor('codeBefore')}
          labelStyle='bold'
          mode='javascript'
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Field
          id='custom-code-code-after'
          name='codeAfter'
          type='text'
          label={t('customCodes.form.codeAfter.label')}
          tooltipContent={t('customCodes.form.codeAfter.tooltip')}
          component={CodeEditor}
          errors={errors.errorsFor('codeAfter')}
          labelStyle='bold'
          mode='javascript'
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <Field
          id='custom-code-comment'
          name='comment'
          type='textarea'
          label={t('customCodes.form.comment.label')}
          component={FormikInput}
          errors={errors.errorsFor('comment')}
          labelStyle='bold'
          disabled={disabled}
        />
      </Grid>
    </>
  )
}
