import React, { useCallback, useContext, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AmazonDemandForm } from 'components/Bidders/AmazonDemandForm'
import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ToggleSwitch } from 'components/Form'
import type { SetAmazonPublisherServicesForm } from 'components/sharedInterfaces/SetAmazonPublisherServicesForm'
import { NotificationType } from 'components/ToastNotifier'
import { AmazonDemandSerializer } from 'serializers/AmazonDemandSerializer'
import { ErrorUtils } from 'utilities/errorUtils'
import { AmazonDemandTypes } from 'webapp/constants/AmazonDemandTypes'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'

import SET_AMAZON_PUBLISHER_SERVICES from 'gql/mutations/settings/SetAmazonPublisherServices.gql'
import GENERAL_SETTINGS from 'gql/queries/settings/GeneralSettings.gql'

export type GeneralSettings = SetAmazonPublisherServicesForm & { apsDemandEnabled: boolean }

type GeneralSettingsData = {
  generalSettings: GeneralSettings
}

export const AmazonDemandContainer = (): JSX.Element => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [isContainerOpened, setIsContainerOpened] = useState(false)
  const [apsDemandEnabled, setApsDemandEnabled] = useState<GeneralSettings['apsDemandEnabled']>(false)
  const [apsDemandType, setApsDemandType] = useState<AmazonDemandTypes>(AmazonDemandTypes.OwnDemand)
  const [apsPubId, setApsPubId] = useState<GeneralSettings['apsPubId']>('')
  const [forceFormReset, setForceFormReset] = useState(0)
  const [errors, setErrors] = useState(new ErrorUtils([]))

  useQuery<GeneralSettingsData>(GENERAL_SETTINGS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ generalSettings }) => {
      setApsDemandEnabled(generalSettings.apsDemandEnabled)
      setApsDemandType(generalSettings.apsDemandType)
      setApsPubId(generalSettings.apsPubId)
    }
  })

  const [setAmazonPublisherServices] = useMutation(SET_AMAZON_PUBLISHER_SERVICES, {
    onCompleted: ({ setAmazonPublisherServices: { errors } }) => {
      if (errors.length === 0) {
        if (apsDemandEnabled) {
          createNotifier(t('amazonDemand.form.enabledMessage'), NotificationType.SUCCESS)
        } else {
          createNotifier(t('amazonDemand.form.disabledMessage'), NotificationType.SUCCESS)
        }
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const [setAmazonPublisherServicesWithNotifier, { loading }] = useMutation(SET_AMAZON_PUBLISHER_SERVICES, {
    onCompleted: ({ setAmazonPublisherServices: { errors } }) => {
      if (errors.length === 0) {
        createNotifier(t('amazonDemand.form.successMessage'), NotificationType.SUCCESS)
        setForceFormReset((forceFormReset) => forceFormReset + 1)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const setAmazonPublisherServicesHandler = useCallback(
    (values: SetAmazonPublisherServicesForm) => {
      const serializer = new AmazonDemandSerializer(values)
      setAmazonPublisherServicesWithNotifier({ variables: { input: serializer.params() } })
    },
    [setAmazonPublisherServicesWithNotifier]
  )

  const toogleApsDemandEnabled = () => {
    setAmazonPublisherServices({ variables: { input: { apsDemandEnabled: !apsDemandEnabled } } })
    setApsDemandEnabled((prev) => !prev)
    setIsContainerOpened(!apsDemandEnabled)
  }

  return (
    <>
      <Box
        className='amazon-demand-container'
        isExpandable={apsDemandEnabled}
        defaultExpand={isContainerOpened}
        onToggle={(isOpen) => setIsContainerOpened(isOpen)}
      >
        <BoxHeader title={t('amazonDemand.form.title')} subtitle={t('amazonDemand.form.subtitle')}>
          <ToggleSwitch
            id={'amazon-demand-status'}
            name={'amazon-demand-status'}
            checked={apsDemandEnabled}
            onChange={toogleApsDemandEnabled}
            className='amazon-demand-status__switch'
          />
        </BoxHeader>

        <BoxBody>
          <AmazonDemandForm
            apsPubId={apsPubId}
            apsDemandType={apsDemandType}
            forceFormReset={forceFormReset}
            isSubmitting={loading}
            onSubmitHandler={setAmazonPublisherServicesHandler}
            errors={errors}
          />
        </BoxBody>
      </Box>
    </>
  )
}
