import React from 'react'
import { Column } from 'react-table'

import { DateCell } from 'components/Table'
import { getProductName } from 'utilities/products/utils'
import { Product } from 'webapp/constants/Product'
import i18n from 'webapp/i18n'
import { ProductManagementRowData } from './ProductManagementRowData'
import { StatusCellContainer } from './StatusCellContainer'

export const columnsConfig: Column<ProductManagementRowData>[] = [
  {
    Header: () => i18n.t('products:management.tableHeader.name'),
    accessor: 'name'
  },
  {
    Header: () => getProductName(Product.Quickwrap),
    accessor: Product.Quickwrap,
    Cell: ({ row }) => <StatusCellContainer productConnection={row.original[Product.Quickwrap]} />,
    disableSortBy: true
  },
  {
    Header: () => getProductName(Product.PriceGenius),
    accessor: Product.PriceGenius,
    Cell: ({ row }) => <StatusCellContainer productConnection={row.original[Product.PriceGenius]} />,
    disableSortBy: true
  },
  {
    Header: () => getProductName(Product.FillRateBooster),
    accessor: Product.FillRateBooster,
    Cell: ({ row }) => <StatusCellContainer productConnection={row.original[Product.FillRateBooster]} />,
    disableSortBy: true
  },
  {
    Header: () => getProductName(Product.ViewabilityBooster),
    accessor: Product.ViewabilityBooster,
    Cell: ({ row }) => <StatusCellContainer productConnection={row.original[Product.ViewabilityBooster]} />,
    disableSortBy: true
  },
  {
    Header: () => getProductName(Product.Revive),
    accessor: Product.Revive,
    Cell: ({ row }) => <StatusCellContainer productConnection={row.original[Product.Revive]} />,
    disableSortBy: true
  },
  {
    Header: () => i18n.t('products:management.tableHeader.lastModified'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={new Date(value)} />
  }
]
