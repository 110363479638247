import React from 'react'

import { useTranslation } from 'react-i18next'

export const InfoHover: React.FC = () => {
  const { t } = useTranslation('dataStudio')

  return (
    <div className='info-hover'>
      <div className='info-hover-main'>
        <div className='info-hover-main__left'>
          <div className='info-hover-main__circle'>i</div>
        </div>

        <div className='info-hover-main__right'>
          <div className='info-hover-main__title'>{t('viewer.info.title')}</div>
          <div className='info-hover-main__description'>{t('viewer.info.description')}</div>
        </div>
      </div>
    </div>
  )
}
