import React from 'react'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { DemandStatus } from 'components/sharedInterfaces/DemandStatus'
import { columnsConfig } from './columnsConfig'
import { Table } from 'components/Table/Table'

interface Props {
  data: DemandStatus[]
  demandBidderSettings: BidderSetting[]
}

export const YbDemandTable: React.FC<Props> = ({ data, demandBidderSettings }) => {
  return (
    <Table
      data={data}
      columns={columnsConfig(demandBidderSettings)}
      className='yb-demand-table'
      disablePagination={true}
      collectionName={CollectionNames.DemandStatus}
    />
  )
}
