import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActiveItem } from 'components/Header/ProductMenu/ActiveItem'
import { Product } from 'webapp/constants/Product'
import { useDelayedCurrentProduct } from 'components/Header/ProductMenu/useDelayCurrentProduct'

interface Props {
  closeMenu: () => void
}

export const SwitchToOss: React.FC<Props> = ({ closeMenu }) => {
  const { t } = useTranslation('products')
  const currentProduct = useDelayedCurrentProduct()

  if (currentProduct === Product.OSS) return null

  return (
    <div className='product-menu__section product-menu__section--switch-to'>
      <div className='product-menu__heading'>{t('menu.switchTo')}</div>

      <ActiveItem closeMenu={closeMenu} product={Product.OSS} />
    </div>
  )
}
