import { BiddersRecommendationForm, VisitsPerGeo } from 'components/sharedInterfaces/BiddersRecommendationForm'

export class BiddersRecommendationSerializer {
  form: { [key: string]: any }

  constructor(form: BiddersRecommendationForm) {
    this.form = form
  }

  params() {
    return this._prepareBody()
  }

  private _prepareBody() {
    const body: { [key: string]: string | number | boolean | undefined } = {}

    body['visitsPerGeo'] = this._visitsPerGeo()
    body['restrictedContent'] = this._restrictedContent()

    this._rawParams().forEach((paramName) => (body[paramName] = this.form[paramName]))
    this._intParams().forEach((paramName) => (body[paramName] = parseInt(this.form[paramName], 10)))

    return body
  }

  private _visitsPerGeo() {
    return this.form.visitsPerGeo.map((elem: VisitsPerGeo) => {
      return { geo: elem.geo, traffic: parseInt(elem.traffic!, 10) }
    })
  }

  private _restrictedContent() {
    return this.form.restrictedContent.map((elem: SelectOption) => elem.label)
  }

  private _rawParams(): Array<keyof BiddersRecommendationForm> {
    return ['domain', 'adFormat']
  }

  private _intParams(): Array<keyof BiddersRecommendationForm> {
    return ['impressions']
  }
}
