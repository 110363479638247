import React, { useCallback } from 'react'
import { FieldConfig, useField } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AmazonDemandTypes } from 'webapp/constants/AmazonDemandTypes'
import { InputContainer } from 'components/Form/InputContainer'
import { RadioButton } from 'components/Form'

import BirdSrc from 'images/bird.svg'

type DemandTypeType = {
  id: string
  disabled: boolean
  errors?: Array<string>
  label: string
  field: FieldConfig
}

export const DemandType = ({
  id,
  disabled,
  errors,
  label,
  field: { name: fieldName }
}: DemandTypeType): JSX.Element => {
  const { t } = useTranslation('setup')
  const [field, , helpers] = useField(fieldName)
  const onDemandTypeChange = useCallback((type) => helpers.setValue(type), [helpers])

  return (
    <InputContainer
      inputId={id}
      label={label}
      className={'input__container'}
      disabled={disabled}
      errors={errors}
      labelStyle='bold'
    >
      <Grid container alignContent='center' spacing={3}>
        <Grid item xs={6}>
          <RadioButton
            name='ownDemand'
            id='own-demand'
            checked={field.value?.includes(AmazonDemandTypes.OwnDemand)}
            disabled={false}
            label={t('amazonDemand.form.demandType.ownDemand')}
            value={AmazonDemandTypes.OwnDemand}
            onChange={onDemandTypeChange}
          />
        </Grid>

        <Grid item xs={6}>
          <RadioButton
            name='yieldbirdDemand'
            id='yieldbird-demand'
            checked={field.value?.includes(AmazonDemandTypes.YbDemand)}
            disabled={false}
            label={
              <div className='amazon-yb-label'>
                <img className='mr-2' src={BirdSrc} alt='Yieldbird' />
                {t('amazonDemand.form.demandType.ybBidders')}
              </div>
            }
            value={AmazonDemandTypes.YbDemand}
            onChange={onDemandTypeChange}
          />
        </Grid>
      </Grid>
    </InputContainer>
  )
}
