import React from 'react'

import classNames from 'classnames'

import { BetaChip } from 'components/Chips/BetaChip'
import { ErrorMessages } from 'components/Form/ErrorMessages'
import { Label } from 'components/Form/Label'
import { InputContainerProps } from './types'

export const InputContainer: React.FC<InputContainerProps> = ({
  children,
  className,
  disabled,
  errors,
  inputId,
  isBeta,
  label,
  labelStyle,
  tooltipContent
}) => {
  const labelClass = classNames('input__label', labelStyle && `input__label--${labelStyle}`)

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={inputId} disabled={disabled} className={labelClass} tooltipContent={tooltipContent}>
          {label}
          {isBeta && <BetaChip />}
        </Label>
      )}

      {children}

      <ErrorMessages errors={errors} />
    </div>
  )
}
