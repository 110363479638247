import React, { useContext } from 'react'
import { useField, FieldConfig } from 'formik'
import { useTranslation } from 'react-i18next'

import { CodeEditor } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'

interface Props {
  id: string
  type: string
  className?: string
  containerClassName?: string
  onChangeHandler?: (value: string) => void
  disabled?: boolean
  placeholder?: string
  errors?: Array<string>
  hasErrors?: boolean
  label?: string
  labelStyle?: string
  tooltipContent?: string
  step?: number
  field: FieldConfig
  mode?: string
}

export const FormikCodeEditorContainer: React.FC<Props> = ({
  id,
  type,
  className,
  containerClassName,
  onChangeHandler,
  disabled,
  placeholder,
  label,
  labelStyle,
  tooltipContent,
  errors,
  hasErrors,
  step,
  mode,
  ...other
}) => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('inventory')
  const [field, , helpers] = useField(other.field.name)

  const onChange = (value: string) => {
    helpers.setValue(value)
    onChangeHandler && onChangeHandler(value)
  }

  const onBlur = () => {
    try {
      const fieldValue = JSON.parse(field.value)
      if (Object.prototype.hasOwnProperty.call(fieldValue, 'sizeConfig')) {
        createNotifier(t('adUnits.form.inputs.mediaTypes.native.warning'), NotificationType.WARNING)
      }
    } catch {
      return
    }
  }

  return (
    <CodeEditor
      {...{ id, className, containerClassName, disabled, label, labelStyle, tooltipContent, errors, hasErrors, mode }}
      key={id}
      name={field.name}
      value={field.value}
      onChangeHandler={onChange}
      onBlurHandler={onBlur}
    />
  )
}
