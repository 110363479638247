import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ConnectedNetworksTable } from 'components/oss/Workspaces/ConnectedNetworksTable/ConnectedNetworksTable'
import { ConnectedNetworksTableContext } from 'components/oss/Workspaces/ConnectedNetworksTable/ConnectedNetworksTableContext'
import { LoadingContainer } from 'components/LoadingContainer'

export const ConnectedNetworksContainer: React.FC = () => {
  const { t } = useTranslation('workspaces')
  const { data, isRunInProgress } = useContext(ConnectedNetworksTableContext)

  return (
    <div
      className={`connected-networks__container connected-networks__container--${
        isRunInProgress ? 'in-progress' : 'idle'
      }`}
    >
      <header className='connected-networks__header'>{t('connectedNetworks.title')}</header>
      <LoadingContainer loading={!data}>
        <ConnectedNetworksTable data={data || []} />
      </LoadingContainer>
    </div>
  )
}
