import React, { useContext, useEffect } from 'react'
import { Row } from 'react-table'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ConnectedNetworksTableContext } from './ConnectedNetworksTableContext'
import { GamConnectionStatus } from 'webapp/constants/GamConnectionStatus'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { oauthGamCallbackUrl } from 'webapp/constants/oauthCallbackUrl'
import { useGamConnect } from 'components/oss/Gam/GamConnectButton/useGamConnect'

import UpdateGAMConnection from 'gql/mutations/workspaces/UpdateGAMConnection.gql'

interface Props {
  row: Row<ConnectedNetwork>
}

const isStatusFailed = (status: string) => status === GamConnectionStatus.Failed

const shouldRetry = (id: string): boolean => Boolean(sessionStorage.getItem(id))
const setRetry = (id: string, value: string) => sessionStorage.setItem(id, value)
const clearRetry = (id: string) => sessionStorage.removeItem(id)

const getRefreshToken = (urlSearchParams: string) => {
  const queryParams = new URLSearchParams(urlSearchParams)
  return queryParams.get('refreshToken')
}

export const RetryCell: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { connectedNetworksQuery, isRunInProgress, setIsRetrying } = useContext(ConnectedNetworksTableContext)
  const { createNotifier } = useContext(NotificationContext) as Notification

  const { id, bannerStatus, nativeStatus } = row.original
  const refreshToken = getRefreshToken(search)

  const [retryGamConnection, { loading: isRetryLoading }] = useMutation(UpdateGAMConnection, {
    onCompleted: () => {
      connectedNetworksQuery()
      clearRetry(id)
    },
    onError: (error) => createNotifier(error.message, NotificationType.ERROR)
  })

  const [connect] = useGamConnect(oauthGamCallbackUrl)

  useEffect(() => {
    if (isRetryLoading) {
      setIsRetrying(true)
    }
  }, [isRetryLoading, setIsRetrying])

  const connectHandler = () => {
    if (refreshToken) {
      retryGamConnection(
        nestGqlInput({
          gamConnectionId: id,
          refreshToken
        })
      )
    } else {
      setRetry(id, '1')
      connect()
    }
  }

  useEffect(() => {
    if (refreshToken && shouldRetry(id)) {
      retryGamConnection(
        nestGqlInput({
          gamConnectionId: id,
          refreshToken
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isStatusFailed(bannerStatus) || isStatusFailed(nativeStatus) ? (
    <Button
      buttonType='button'
      className='connected-networks__retry'
      disabled={isRunInProgress}
      onClickHandler={connectHandler}
      theme={ButtonTheme.Blue}
      type={ButtonType.Secondary}
    >
      {t('common:buttons.retry')}
    </Button>
  ) : (
    <></>
  )
}
