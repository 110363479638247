import React from 'react'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { routesBuilder } from 'utilities/routesBuilder'
import { saveGamParamsBeforeRedirect } from 'components/oss/Workspaces/WorkspaceGamConnector/utils'
import { useGamConnect } from './useGamConnect'

interface Props {
  className?: string
  connectedNetwork?: ConnectedNetwork
  disabled?: boolean
  native?: boolean
  oauthCallbackUrl: string
  translateKey: string
  type?: ButtonType
}

export const GamConnectButton: React.FC<Props> = ({
  children,
  className,
  connectedNetwork,
  disabled,
  native,
  oauthCallbackUrl,
  translateKey,
  type = ButtonType.Primary
}) => {
  const history = useHistory<History>()
  const { t } = useTranslation('gam')
  const [connect] = useGamConnect(oauthCallbackUrl)

  const connectionId = connectedNetwork?.id

  const onClickHandler = () => {
    const refreshToken = (queryString.parse(history.location.search)?.refreshToken as string) ?? ''
    if (refreshToken) {
      updateWorkspaceQueryParams(history, refreshToken, connectionId, native)
    } else {
      saveGamParamsBeforeRedirect(connectionId, native)
      connect()
    }
  }

  return (
    <Button
      className={className}
      disabled={disabled}
      onClickHandler={onClickHandler}
      type={type}
      theme={ButtonTheme.Blue}
    >
      {children || t(translateKey)}
    </Button>
  )
}

const updateWorkspaceQueryParams = (
  history: ReturnType<typeof useHistory<History>>,
  refreshToken: string,
  connectionId?: string,
  native?: boolean
) => {
  const searchParams = queryString.stringify(
    {
      connectionId,
      native,
      refreshToken
    },
    {
      skipEmptyString: true,
      skipNull: true
    }
  )

  history.push({
    pathname: routesBuilder.oss.workspace.root,
    search: searchParams
  })

  document.querySelector('.gam-connection-container')?.scrollIntoView({
    behavior: 'smooth'
  })
}
