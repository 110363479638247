import React, { memo, useMemo } from 'react'

import DefaultAvatar from 'images/avatar.svg'
import { Image } from 'components/Image'

interface Props {
  containerClassName?: string
  id?: string
  user?: User
}

const Avatar: React.FC<Props> = memo(({ user, id, containerClassName }) => {
  const imageSrc = useMemo(() => {
    try {
      return user ? require(`images/avatars/avatar${user.avatar}.png`) : DefaultAvatar
    } catch {
      return DefaultAvatar
    }
  }, [user])
  const className = `avatar__container ${containerClassName || ''}`

  return (
    <div id={id || 'user-avatar'} className={className}>
      <Image src={imageSrc} alt='User avatar' className='avatar__img' />
    </div>
  )
})

Avatar.displayName = 'Avatar'

export { Avatar }
