import React from 'react'

import { Image } from 'components/Image'

interface Props {
  fileName: string
  placeholder: string
  className: string
}

export const ParamLogo: React.FC<Partial<Props>> = ({ fileName = '', placeholder = '', className }) => {
  let src
  try {
    src = require(`images/prebidModules/${fileName}.png`)
  } catch {} // eslint-disable-line no-empty

  return (
    <>
      {src ? (
        <Image src={src} alt={placeholder} className={className} />
      ) : (
        <div className={className}>{placeholder}</div>
      )}
    </>
  )
}
