import { useContext } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { NotificationType } from 'components/ToastNotifier'
import { ProductConnectionSerializer } from 'serializers/ProductConnectionSerializer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { IndexedProductConnections } from 'utilities/products/utils'
import { Product } from 'webapp/constants/Product'
import { ProductPlan } from 'webapp/constants/ProductPlan'
import { ProductStatus } from 'webapp/constants/ProductStatus'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { UserContext } from 'webapp/context/UserContext'
import { routesBuilder } from 'webapp/utils/routesBuilder'

import ActivateProductConnection from 'gql/mutations/productConnections/ActivateProductConnection.gql'

type ActivateProductResponse = { activateProductConnection: { productConnection?: ProductConnection } }

export const useActivateProduct = () => {
  const { t } = useTranslation('products')
  const history = useHistory()

  const { createNotifier } = useContext(NotificationContext) as Notification
  const { reloadUser } = useContext(UserContext)

  const [activateProductConnection, { loading: isActivatingProduct }] =
    useMutation<ActivateProductResponse>(ActivateProductConnection)

  const activateProduct = async (productConnectionToUpdate: ProductConnection, plan: ProductPlan) => {
    try {
      const activateParams = ProductConnectionSerializer.activateProductPlanParams(productConnectionToUpdate, plan)
      const { data } = await activateProductConnection(nestGqlInput(activateParams))

      await reloadUser()

      const productConnection = data?.activateProductConnection.productConnection
      if (!productConnection) {
        createNotifier(t('common:errors.common'), NotificationType.ERROR)
        return
      }

      if (isProductConnectionActive(productConnection)) {
        history.push(routesBuilder.oss.products.root)
      }
    } catch {
      createNotifier(t('common:errors.common'), NotificationType.ERROR)
    }
  }

  return { activateProduct, isActivatingProduct }
}

export const isProductConnectionActive = (productConnection?: ProductConnection): boolean => {
  return productConnection?.status !== ProductStatus.Inactive
}

export const useIsProductActive = (indexedProductConnections?: IndexedProductConnections) => {
  return (productName: Product) => {
    if (!indexedProductConnections?.[productName]) return false

    return isProductConnectionActive(indexedProductConnections[productName])
  }
}
