import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Filter } from 'icons/Filter'

interface Props {
  filters: AdvancedFilter[]
  theme?: ButtonTheme
  toggleFilter: () => void
}

export const ShowFilterButton: React.FC<Props> = ({ filters, theme = ButtonTheme.Blue, toggleFilter }) => {
  const { t } = useTranslation('common')
  const filtersCount = filters.filter((filter) => filter.values.length > 0).length

  return (
    <Button
      type={ButtonType.Secondary}
      theme={theme}
      onClickHandler={toggleFilter}
      className='advanced-filter__filter-button'
    >
      <div className={classNames('icon-container', { 'mr-2': filtersCount > 0 })}>
        <Filter />
      </div>

      {filtersCount > 0 ? filtersCount : ''}

      <span>{filtersCount > 1 ? t('advancedFilter.buttons.filters') : t('advancedFilter.buttons.filter')}</span>
    </Button>
  )
}
