import React from 'react'
import { useHistory } from 'react-router-dom'
import { HeaderGroup, TableHeaderProps, ColumnInstance } from 'react-table'
import { TableCell, TableHead, TableRow } from '@material-ui/core'

import { filterStore } from 'utilities/filters/filterStore'
import { Image } from 'components/Image'
import { Tooltip } from 'components/Form'
import TriangleUp from 'images/small_triangle_up.svg'
import TriangleDown from 'images/small_triangle_down.svg'
import useSortParams from 'containers/QueryTable/useSortParams'

interface Props extends TableHeaderProps {
  headerGroups: Array<HeaderGroup<Record<string, never>>>
  collectionName: string
  columnTooltips?: Record<string, string>
}

export function TableHeader({ headerGroups, collectionName, columnTooltips }: Props): JSX.Element {
  const history = useHistory<History>()
  const [order, isSorted, updateSortParams] = useSortParams(history.location.search, collectionName)

  const getSortedIcon = () => (order.direction === 'desc' ? TriangleDown : TriangleUp)

  const onSortHandler = (column: ColumnInstance) => {
    if (!column.disableSortBy) {
      const sortParams = updateSortParams(column.id)
      filterStore.setFilter(collectionName, 'order', sortParams?.split(','))
    }
  }

  return (
    <TableHead className='qw-table__head'>
      {headerGroups.map((headerGroup, index) => (
        <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
          {headerGroup.headers.map((column, index) => (
            <TableCell
              {...column.getHeaderProps([
                { className: `header-cell--${column.id.replace('.', '-')}` },
                column.getSortByToggleProps()
              ])}
              key={index}
              onClick={() => !column.disableSortBy && onSortHandler(column)}
            >
              {column.render('Header')}
              {columnTooltips?.[column.id] && (
                <div className='qw-table__label'>
                  <Tooltip title={columnTooltips[column.id]} />
                </div>
              )}
              {isSorted(column.id) && (
                <Image src={getSortedIcon()} alt='sorting icon' className='qw-table__icon--sort' />
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  )
}
