export enum AbilitySubject {
  ADD_ONS = 'add-ons',
  ADMIN_BOX = 'adminBox',
  ADUNIT = 'adunit',
  ALIAS_LIST = 'aliasList',
  ALL = 'all',
  BIDDER_SETTINGS = 'bidderSettings',
  BIDDER_SETTINGS_PATH = '/setup/bidders',
  BLOCK_URL_GROUP = '/protection/block_url_groups',
  BLOCK_URL_GROUP_EDIT = '/protection/block_url_groups/:blockUrlGroupId/edit',
  BLOCK_URL_GROUP_NEW = '/protection/block_url_groups/new',
  CUSTOM_CODES_EDIT = '/setup/custom_codes/:customCodeId/edit',
  CUSTOM_CODES_NEW = '/setup/custom_codes/new',
  DEVICES = '/inventory/devices',
  DOMAIN = 'domain',
  DOMAINS_NEW = '/inventory/domains/new',
  FILLRATE_BOOSTER_PACKAGE = 'FillrateBooster',
  FINANCE_DATA_STUDIO_LINKS_EDIT = '/finance/data-studio-links/:workspaceId/edit',
  FINANCE_DATA_STUDIO_LINKS_NEW = '/finance/data-studio-links/new',
  FINANCE_DATA_STUDIO_LINKS_PATH = '/finance/data-studio-links',
  FINANCE_DATA_STUDIO_LINKS_PREVIEW = '/finance/data-studio-links/:workspaceId/preview',
  FINANCE_DATA_STUDIO_PATH = '/finance/data-studio',
  GAM_CONNECTOR = 'GAM Connector',
  GENERAL_SETTINGS = 'generalSettings',
  GENERAL_SETTINGS_PATH = '/setup/general',
  INVITE_USER_PATH = '/users/invite',
  MODULES_PATH = '/setup/modules',
  NETWORKS_PATH = '/setup/networks',
  NETWORKS_PATH_EDIT = '/setup/networks/:networkId/edit',
  NETWORKS_PATH_NEW = '/setup/networks/new',
  PAGE = '/inventory/pages',
  PERFORMANCE_DATA_STUDIO_LINKS_EDIT = '/performance/data-studio-links/:productConnectionId/edit',
  PERFORMANCE_DATA_STUDIO_LINKS_NEW = '/performance/data-studio-links/new',
  PERFORMANCE_DATA_STUDIO_LINKS_PATH = '/performance/data-studio-links',
  PERFORMANCE_DATA_STUDIO_LINKS_PREVIEW = '/performance/data-studio-links/:productConnectionId/preview',
  PERFORMANCE_DATA_STUDIO_PATH = '/performance/data-studio',
  PREBID_MANAGER_PACKAGE = 'Prebid manager',
  PRICE_GENIUS_PACKAGE = 'PriceGenius',
  PRICE_GENIUS_PRODUCT = 'price_genius',
  PRODUCT = '/products',
  PRODUCT_MANAGEMENT = '/product-management',
  S2S_PACKAGE = 'S2S',
  SETUP = 'Setup',
  USER = 'User',
  VIEWABILITY_BOOSTER_PACKAGE = 'ViewabilityBooster',
  WORKSPACE_CRM_ID = 'workspaceCrmId',
  WORKSPACE_NAME = 'Workspace name',
  WORKSPACE_PATH = '/workspace',
  WORKSPACES_EDIT_PATH = '/workspaces/:workspaceId/edit',
  WORKSPACES_NEW_PATH = '/workspaces/new',
  WORKSPACES_PATH = '/workspaces',
  YB_DEMAND_PACKAGE = 'YB Demand',
  YB_SCHAIN = 'YB Schain'
}
