import React from 'react'

import { DeviceList } from 'components/Form/DeviceList'
import { Errors, ErrorUtils } from 'utilities/errorUtils'
import { MediaTypesRow } from './MediaTypesRow'
import { OutstreamVideo } from 'components/AdUnits/Form/Sections/MediaTypes/OutstreamVideo'

interface Props {
  errors: Errors
  options: Array<SelectOption>
  setErrors: (errors: ErrorUtils) => void
}

export const SizeMapping: React.FC<Props> = ({ errors, options, setErrors }) => {
  const schemaBuilder = () => ({ nativeEnabled: false, native: '' })

  const removeHandler = (index: number) => {
    errors.deleteAt(index, ['selected', 'mediaTypesAttributes'])
    setErrors(new ErrorUtils(errors.raw))
  }

  return (
    <>
      <DeviceList
        errors={errors}
        name='sizeMapping'
        onDeleteHandler={removeHandler}
        options={options}
        schemaBuilder={schemaBuilder}
      >
        {({ index }) => <MediaTypesRow errors={errors} index={index} generalSizeType={false} />}
      </DeviceList>
      <OutstreamVideo errors={errors} />
    </>
  )
}
