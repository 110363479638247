import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReportDetailsInfo } from './ReportDetailsInfo'
import { ReportTimePeriod } from 'components/quickwrap/Performance/Reports/ReportTimePeriod'

interface Props {
  totalRevenue: string
  totalImpressions: string
  timeGenerated: string
  reportFormData: ReportFormType
  onSubmitHandler: (formValues: ReportFormValues) => void
  isSubmitting: boolean
  isEditReport: boolean
  setIsEditReport: (isEdit: boolean) => void
}

export const ReportSummary: React.FC<Props> = ({
  totalRevenue,
  totalImpressions,
  timeGenerated,
  reportFormData,
  onSubmitHandler,
  isSubmitting,
  isEditReport,
  setIsEditReport
}) => {
  const { t } = useTranslation('reports')

  return (
    <div className='report-summary'>
      <div className='report-summary__container'>
        <ReportDetailsInfo id='total-revenue' label={t('resultPage.summary.totalRevenue')} value={totalRevenue} />
        <ReportDetailsInfo
          id='total-impressions'
          label={t('resultPage.summary.totalImpressions')}
          value={totalImpressions}
        />
      </div>
      <div className='report-summary__container'>
        <ReportDetailsInfo id='time-generated' label={t('resultPage.summary.timeGenerated')} value={timeGenerated} />
        <ReportTimePeriod
          label={t('resultPage.summary.timePeriod.label')}
          tooltipContent={t('resultPage.summary.timePeriod.tooltip')}
          reportFormData={reportFormData}
          onSubmitHandler={onSubmitHandler}
          isSubmitting={isSubmitting}
          isEditReport={isEditReport}
          setIsEditReport={setIsEditReport}
        />
      </div>
    </div>
  )
}
