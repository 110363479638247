import React from 'react'
import { Prompt, Route, Router, Switch } from 'react-router-dom'

import { FillRateBoosterRoutes } from 'webapp/routes/fill-rate-booster'
import { OssRoutes } from 'webapp/routes/oss'
import { PriceGeniusRoutes } from 'webapp/routes/price-genius'
import { QuickwrapRoutes } from 'webapp/routes/quickwrap'
import { RedirectRoutes } from 'webapp/routes/RedirectRoutes'
import { ReviveRoutes } from 'webapp/routes/revive'
import { UserRoutes } from 'webapp/routes/UserRoutes'
import { ViewabilityBoosterRoutes } from 'webapp/routes/viewability-booster'
import { WorkspaceRoutes } from 'webapp/routes/WorkspaceRoutes'

import { customHistory as history } from 'utilities/history'
import { initGA } from 'utilities/reactGa'
import { GuestRoute } from './GuestRoute'
import { LayoutRoute } from './Layout/LayoutRoute'
import { LoginPage } from 'pages/Auth/LoginPage'
import { NetworkErrorPage, NotFoundPage, UnauthorizedErrorPage } from 'pages/Errors'
import { PasswordRecoveryPage } from 'pages/Auth/PasswordRecoveryPage'
import { Product } from 'webapp/constants/Product'
import { ProfilePage } from 'pages/oss/ProfilePage'
import { PromptPlaceholder } from './Layout/PromptPlaceholder'
import { RegisterPage } from 'pages/Auth/RegisterPage'
import { ResetPasswordPage } from 'pages/Auth/ResetPasswordPage'
import { routesBuilder } from 'utilities/routesBuilder'

export const AppRouter: React.FC = () => {
  initGA()

  return (
    <Router history={history}>
      <PromptPlaceholder />
      <Prompt message='unsavedChanges' />
      <Switch>
        {RedirectRoutes}
        <GuestRoute exact path='/' component={LoginPage} />

        {FillRateBoosterRoutes}
        {OssRoutes}
        {PriceGeniusRoutes}
        {QuickwrapRoutes}
        {ReviveRoutes}
        {UserRoutes}
        {ViewabilityBoosterRoutes}
        {WorkspaceRoutes}

        <LayoutRoute product={Product.OSS} path={routesBuilder.oss.profile.root} component={ProfilePage} />

        <GuestRoute path='/log_in' component={LoginPage} />

        <GuestRoute path='/password/recovery' component={PasswordRecoveryPage} />
        <GuestRoute path='/password/reset' component={ResetPasswordPage} />

        <GuestRoute path='/register' component={RegisterPage} />

        <Route path='/error' component={NetworkErrorPage} />
        <Route path='/unauthorized' component={UnauthorizedErrorPage} />
        <Route path='*' component={NotFoundPage} />
      </Switch>
    </Router>
  )
}
