import React, { MouseEvent, RefObject } from 'react'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'

import { Menu } from 'icons/Menu'

interface Props {
  'aria-label'?: string
  'aria-controls'?: string
  'aria-haspopup'?: boolean
  className?: string
  onClick: (event: MouseEvent) => void
  innerRef?: RefObject<HTMLButtonElement>
}

export const MenuButton: React.FC<Props> = ({ className, onClick, innerRef, ...rest }) => {
  return (
    <IconButton
      {...rest}
      className={classNames('menu-button', className)}
      ref={innerRef}
      onClick={onClick}
      size='medium'
    >
      <Menu />
    </IconButton>
  )
}
