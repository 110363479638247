export class OssPageRow {
  constructor(private ossPage: OssPage) {}

  get id(): string {
    return this.ossPage.id
  }

  get domainName(): string {
    return this.ossPage.domain.name
  }

  get pageName(): string | undefined {
    return this.ossPage.pageName
  }

  get path(): string {
    return this.ossPage.path
  }

  get ossAdUnitsCount(): number {
    return this.ossPage.ossAdUnitsCount
  }

  get updatedAt(): Date {
    return this.ossPage.updatedAt
  }

  get workspaceName(): string {
    return this.ossPage.domain.workspace.name
  }
}
