import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { AbilityContext } from 'webapp/context/AbilityContext'
import { ActiveItem } from 'components/Header/ProductMenu/ActiveItem'
import { SecondaryProducts } from 'webapp/constants/SecondaryProducts'
import { UserContext } from 'webapp/context/UserContext'
import { useDelayedCurrentProduct } from './useDelayCurrentProduct'
import { isProductConnectionActive } from 'utilities/products/activation'

interface Props {
  closeMenu: () => void
}

export const ActiveProducts: React.FC<Props> = ({ closeMenu }) => {
  const { t } = useTranslation('products')

  const ability = useContext(AbilityContext)
  const { indexedProductConnections } = useContext(UserContext)

  const currentProduct = useDelayedCurrentProduct()

  const activeProducts = useMemo(
    () =>
      SecondaryProducts.filter(
        (product) =>
          product !== currentProduct &&
          (isProductConnectionActive(indexedProductConnections?.[product]) ||
            ability.can(AbilityAction.ACCESS, AbilitySubject.PRODUCT_MANAGEMENT))
      ),
    [ability, currentProduct, indexedProductConnections]
  )

  if (!activeProducts.length) return null

  return (
    <div className='product-menu__section product-menu__section--active'>
      <div className='product-menu__heading'>{t('menu.activeProducts')}</div>

      {activeProducts.map((product) => (
        <ActiveItem closeMenu={closeMenu} key={product} product={product} />
      ))}
    </div>
  )
}
