import React, { useMemo, useState } from 'react'
import { Column } from 'react-table'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ButtonTheme, ButtonType } from 'components/Button'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { FormSchema } from 'components/quickwrap/Setup/InventoryConnectionBox/DataTable/formSchema'
import { LoadingButton } from 'components/Button/LoadingButton'
import { StatusForm } from 'components/Form'
import { Table } from 'components/Table'
import {
  columnsConfig,
  SortColumn,
  SortConfig,
  SortDirection
} from 'components/quickwrap/Setup/InventoryConnectionBox/DataTable/columnsConfig'

interface Props {
  inventoryConnections: InventoryConnections
  onSubmit: (values: InventoryConnectionsForm) => Promise<unknown>
}

export const InventoryConnectionTable: React.FC<Props> = ({ inventoryConnections, onSubmit }) => {
  const { t } = useTranslation('setup')
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    column: SortColumn.NAME,
    direction: SortDirection.ASC
  })
  const formSchema = useMemo(() => new FormSchema(inventoryConnections), [inventoryConnections])

  if (!formSchema.bidders.length) {
    return <div>{t('bidders.inventoryConnection.dataTable.noBidders')}</div>
  }

  if (!formSchema.uniqueAdUnits.length) {
    return <div>{t('bidders.inventoryConnection.dataTable.noAdUnits')}</div>
  }

  const onSubmitHandler = async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
    await onSubmit(formSchema.getSubmitForm(values))
    helpers.resetForm({ values })
  }

  const adUnitsHeader = { adUnitPath: t('bidders.inventoryConnection.dataTable.adUnitsHeader'), _header: true }
  const connectionsHeader = {
    adUnitPath: t('bidders.inventoryConnection.dataTable.connectionsHeader'),
    _connection: true
  }

  return (
    <div className='mt-1'>
      <Formik initialValues={{ ...formSchema.connections, ...formSchema.initialValues }} onSubmit={onSubmitHandler}>
        {({ values, dirty, isSubmitting }) => (
          <StatusForm name='qw-inventory-connection' dirty={dirty}>
            <Grid item>
              <div className='table-container'>
                <Table
                  data={[connectionsHeader, adUnitsHeader, ...formSchema.uniqueAdUnits]}
                  columns={columnsConfig(formSchema, values, sortConfig, setSortConfig) as Column[]}
                  collectionName={CollectionNames.InventoryConnections}
                  disablePagination
                />
              </div>
            </Grid>
            <Grid>
              <LoadingButton
                type={ButtonType.Primary}
                theme={ButtonTheme.Orange}
                buttonType='submit'
                loading={isSubmitting}
                disabled={!dirty}
              >
                {t('common:buttons.save')}
              </LoadingButton>
            </Grid>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
