import React from 'react'
import { Collapse, Grid } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { appliedMethodOptions } from 'webapp/constants/appliedMethodOptions'
import { Errors } from 'utilities/errorUtils'
import {
  FormikCodeEditorContainer as CodeEditor,
  FormikInputContainer as FormikInput,
  FormikSelectContainer as FormikSelect,
  FormikToggleContainer as FormikToggle
} from 'containers/FormikContainers'
import { Section } from 'components/Form'

interface Props {
  errors: Errors
}

export const RemoteControl: React.FC<Props> = ({ errors }) => {
  const { t } = useTranslation('inventory')
  const { values } = useFormikContext<AdUnitForm>()

  const errorsFor = (field: string) => errors.errorsFor(['remoteControlAttributes', field])
  const remoteControl = values.remoteControl

  return (
    <div className='ad_unit-form-section remote-control'>
      <div className='ad_unit-form-section__header'>
        <div className='ad_unit-form-section__title'>{t('adUnits.form.inputs.remoteControl.title')}</div>

        <Field
          id='remoteControlEnabled'
          name='remoteControlEnabled'
          component={FormikToggle}
          tooltipContent={t('adUnits.form.inputs.remoteControl.tooltip')}
        />
      </div>

      <Collapse in={values.remoteControlEnabled}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <Field
              id='appliedMethod'
              name='remoteControl.appliedMethod'
              options={appliedMethodOptions}
              placeholder={t('adUnits.form.inputs.remoteControl.appliedMethod.placeholder')}
              label={t('adUnits.form.inputs.remoteControl.appliedMethod.label')}
              tooltipContent={t('adUnits.form.inputs.remoteControl.appliedMethod.tooltip')}
              errors={errorsFor('appliedMethod')}
              labelStyle='bold'
              component={FormikSelect}
            />

            <Field
              id='elementSelector'
              name='remoteControl.elementSelector'
              placeholder={t('adUnits.form.inputs.remoteControl.elementSelector.placeholder')}
              label={t('adUnits.form.inputs.remoteControl.elementSelector.label')}
              tooltipContent={t('adUnits.form.inputs.remoteControl.elementSelector.tooltip')}
              errors={errorsFor('elementSelector')}
              labelStyle='bold'
              component={FormikInput}
            />
          </Grid>
        </Grid>

        <Section className='remote-control__js-code-enabled'>
          <Grid container spacing={10}>
            <Grid item xs={4}>
              <Field
                id='jsCodeEnabled'
                name='remoteControl.jsCodeEnabled'
                label={t('adUnits.form.inputs.remoteControl.jsCodeEnabled.label')}
                tooltipContent={t('adUnits.form.inputs.remoteControl.jsCodeEnabled.tooltip')}
                component={FormikToggle}
                labelStyle='bold'
              />
            </Grid>
          </Grid>

          <Collapse in={remoteControl?.jsCodeEnabled}>
            <Field
              id='jsCode'
              name='remoteControl.jsCode'
              errors={errorsFor('jsCode')}
              label={t('adUnits.form.inputs.remoteControl.jsCode.label')}
              mode='javascript'
              component={CodeEditor}
              labelStyle='bold'
            />
          </Collapse>
        </Section>

        <Section>
          <Grid container spacing={10}>
            <Grid item xs={4}>
              <Field
                id='cssCodeEnabled'
                name='remoteControl.cssCodeEnabled'
                label={t('adUnits.form.inputs.remoteControl.cssCodeEnabled.label')}
                tooltipContent={t('adUnits.form.inputs.remoteControl.cssCodeEnabled.tooltip')}
                component={FormikToggle}
                labelStyle='bold'
              />
            </Grid>
          </Grid>

          <Collapse in={remoteControl?.cssCodeEnabled}>
            <Field
              id='cssCode'
              name='remoteControl.cssCode'
              errors={errorsFor('cssCode')}
              label={t('adUnits.form.inputs.remoteControl.cssCode.label')}
              mode='css'
              component={CodeEditor}
              labelStyle='bold'
            />
          </Collapse>
        </Section>
      </Collapse>
    </div>
  )
}
