import React from 'react'

import { Redirect } from 'react-router-dom'

import { routesBuilder } from 'utilities/routesBuilder'

export const RedirectRoutes = [
  <Redirect
    key='adunit-root'
    exact
    from={routesBuilder.redirects.adUnit.root}
    to={routesBuilder.oss.inventory.adUnit.root}
  />,
  <Redirect
    key='adunit-new'
    exact
    from={routesBuilder.redirects.adUnit.new}
    to={routesBuilder.oss.inventory.adUnit.add()}
  />,
  <Redirect
    key='adunit-edit'
    exact
    from={routesBuilder.redirects.adUnit.edit()}
    to={routesBuilder.oss.inventory.adUnit.edit()}
  />,

  <Redirect
    key='domains-root'
    exact
    from={routesBuilder.redirects.domains.root}
    to={routesBuilder.oss.inventory.domains.root}
  />,
  <Redirect
    key='domains-new'
    exact
    from={routesBuilder.redirects.domains.new}
    to={routesBuilder.oss.inventory.domains.new}
  />,
  <Redirect
    key='domains-edit'
    exact
    from={routesBuilder.redirects.domains.edit()}
    to={routesBuilder.oss.inventory.domains.edit()}
  />,

  <Redirect
    key='pages-root'
    exact
    from={routesBuilder.redirects.pages.root}
    to={routesBuilder.oss.inventory.pages.root}
  />,
  <Redirect
    key='pages-new'
    exact
    from={routesBuilder.redirects.pages.new}
    to={routesBuilder.oss.inventory.pages.add}
  />,
  <Redirect
    key='pages-edit'
    exact
    from={routesBuilder.redirects.pages.edit()}
    to={routesBuilder.oss.inventory.pages.edit()}
  />
]
