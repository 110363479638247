import { gql } from '@apollo/client'
import { OSS_AD_UNIT_FRAGMENT } from 'gql/fragments/adUnits/OssAdUnitFragment'

export const OSS_AD_UNIT = gql`
  ${OSS_AD_UNIT_FRAGMENT}

  query ossAdUnit($adUnitId: ID!) {
    ossAdUnit(ossAdUnitId: $adUnitId) {
      ...OssAdUnitFragment
    }
  }
`
