import React from 'react'
import { useTranslation } from 'react-i18next'

export const UnlimitedPlanInProgress: React.FC = () => {
  const { t } = useTranslation('products')

  return (
    <div className='product-status-bar product-status-bar--unlimited-plan-in-progress'>
      {t('statusBar.unlimitedAccessInProgress')}
    </div>
  )
}
