import React from 'react'
import classNames from 'classnames'
import { Row } from 'react-table'

import { Arrow } from 'icons/Arrow'

interface Props {
  value: string
  row: Row<ReportData>
}

export const NameCell: React.FC<Props> = ({ value, row }) => {
  const hasSubRows = row.subRows.length > 0
  const { isExpanded } = row

  const arrowClasses = classNames('report-results__icon', {
    'report-results__icon--expanded': isExpanded
  })

  return (
    <>
      {hasSubRows ? (
        <span {...row.getToggleRowExpandedProps()}>
          <Arrow classNames={arrowClasses} alt='toggle arrow' />
          <span>{value}</span>
        </span>
      ) : (
        <span className='sub-row'>{value}</span>
      )}
    </>
  )
}
