import React from 'react'
import classNames from 'classnames'

import { truncateWithEllipsis } from 'utilities/stringUtils'

import BirdSrc from 'images/bird.svg'

interface Props {
  bidderSetting: BidderSetting
}

export const BidderHeadingCell: React.FC<Props> = ({ bidderSetting }) => {
  const isDemand = bidderSetting.bidder.demand
  const name = isDemand ? bidderSetting.bidder.name.replace(/Yb$/, '') : bidderSetting.bidderCode
  const truncatedName = truncateWithEllipsis(name, 18)

  return (
    <th
      className={classNames(
        'domain-comparison-table__bidder-heading',
        !isDemand && 'domain-comparison-table__bidder-heading--own'
      )}
      rowSpan={2}
      key={bidderSetting.id}
    >
      {isDemand && <img src={BirdSrc} alt='Yieldbird' />}
      <div>{truncatedName}</div>
    </th>
  )
}
