export class CustomCodeSerializer {
  form: Partial<CustomCodeSubmitForm>

  constructor(customCode: Partial<CustomCodeSubmitForm>) {
    this.form = customCode
  }

  upsertParams() {
    return {
      ...this.commonParams(),
      enabled: true
    }
  }

  templateParams() {
    return {
      ...this.commonParams(),
      name: this.form.name || ''
    }
  }

  templateParamsForAdmin(workspaceId: string) {
    return {
      workspaceId,
      ...this.templateParams()
    }
  }

  static emptyParams() {
    return {
      codeAfter: '',
      codeBefore: '',
      comment: '',
      name: ''
    }
  }

  private commonParams() {
    return {
      codeBefore: this.form.codeBefore,
      codeAfter: this.form.codeAfter,
      comment: this.form.comment
    }
  }
}
