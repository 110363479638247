import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Checkbox } from 'components/Form'
import NameFilter from 'components/NameFilter'
import { PagesTable } from 'components/quickwrap/Inventory/Pages/PagesTable'
import { Product } from 'constants/Product'
import { useHistory } from 'react-router-dom'
import { filterStore } from 'utilities/filters/filterStore'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { Can } from 'webapp/context/AbilityContext'
import { useFilterConfig } from './useFilterConfig'

const COLLECTION = CollectionNames.Pages

export const PagesContainer: React.FC = () => {
  const { t } = useTranslation('inventory')
  const history = useHistory()
  const [includeInactive, setIncludeInactive] = useState(
    filterStore.getFilters(CollectionNames.Pages).includeInactive || false
  )
  const filterConfig = useFilterConfig()

  const onIncludeInactiveChange = () => {
    filterStore.setFilter(CollectionNames.Pages, 'includeInactive', !includeInactive)
    setIncludeInactive((previousValue) => !previousValue)
  }

  const redirectToAdd = (): void =>
    history.push(routesBuilder.oss.inventory.pages.add, { fromProduct: Product.Quickwrap })

  return (
    <Box className='pages' list={true}>
      <BoxHeader
        title={t('pages.list.title')}
        containerClassName='box-header__container'
        rowClassName='header__row--width-100'
      >
        <div>
          <Can I={AbilityAction.EDIT} a={AbilitySubject.PAGE}>
            <Button
              className='pages__header-button'
              onClickHandler={redirectToAdd}
              theme={ButtonTheme.Orange}
              type={ButtonType.Primary}
            >
              {t('common:buttons.add')}
            </Button>
          </Can>
        </div>

        <div className='box-header__filters'>
          <Checkbox
            name='includeInactive'
            id='includeInactive'
            className='mr-4'
            checked={includeInactive}
            onChange={onIncludeInactiveChange}
            label={t('adUnits.list.appliedVersions')}
          />

          <AdvancedFilter alignPopup='right' collectionName={COLLECTION} config={filterConfig} />

          <NameFilter
            collection={COLLECTION}
            operator={'contains'}
            path={'oss_page.path'}
            placeholder={t('pages.list.search.byUrlPath')}
          />
        </div>
      </BoxHeader>

      <PagesTable />
    </Box>
  )
}
