import React, { useMemo } from 'react'
import classNames from 'classnames'

import CloseIcon from '@material-ui/icons/Close'

import { sizeSchema } from './sizeSchema'

interface Props {
  added?: boolean
  disabled?: boolean
  label: string
  onDelete: () => void
}

export const SizeTag: React.FC<Props> = ({ added, disabled, label, onDelete, ...props }) => {
  const classString = useMemo(() => {
    return classNames('tag-input__tag', added ? 'tag-input__tag--added' : 'tag-input__tag--progress', {
      'tag-input__tag--invalid': !label.match(sizeSchema)
    })
  }, [added, label])

  return (
    <div className={classString} {...props}>
      {label}
      {!disabled && <CloseIcon onClick={onDelete} />}
    </div>
  )
}
