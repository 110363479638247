import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'webapp/gql/fragments/common'

const PAYLOAD_TYPE = 'UpsertOssAdUnitPayload'

export const UPSERT_OSS_AD_UNIT = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation UpsertOssAdUnit($input: UpsertOssAdUnitInput!) {
    upsertOssAdUnit(input: $input) {
      ossAdUnit {
        activeAdUnitId
        activeDomainVersionId
        adUnitName
        id
        reviveEnabled
        dedicatedForUnfilledRecovery
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
