import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ApplicableDomainsModal } from './ApplicableDomainsModal/ApplicableDomainsModal'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { InfoBar } from 'components/InfoBar'
import { InfoBarConfirmModal } from './InfoBarConfirmModal'

import { nestGqlInput } from 'utilities/commonGqlObjects'

import DomainsQuery from 'gql/queries/domains/DomainsWithPrebidManager.gql'
import SetPrebidManagerAvailability from 'gql/mutations/domains/SetPrebidManagerAvailability.gql'

type PrebidAnalyticsInfoBarType = {
  hasActivePackage: boolean
  reloadUser: () => void
}

const isPrebidAnalyticsEnabledOnAllDomains = (domains: Domain[]) => {
  return domains.every((domain) => domain.activeVersion.domainConfiguration.prebidManagerEnabled)
}

export const PrebidAnalyticsInfoBar = ({ hasActivePackage, reloadUser }: PrebidAnalyticsInfoBarType): JSX.Element => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [domainManagementModalVisible, setDomainManagementModalVisible] = useState(false)

  const [domains, setDomains] = useState<Domain[]>([])
  const { t } = useTranslation('performance')

  const [setPrebidAnalyticsAvailability, { loading: isSubmitting }] = useMutation(SetPrebidManagerAvailability, {
    onCompleted: () => {
      reloadUser()
      refetch()
    }
  })

  const { refetch } = useQuery(DomainsQuery, {
    onCompleted: ({ domains }) => setDomains(domains.nodes),
    fetchPolicy: 'network-only'
  })

  const printMessage = (hasActivePackage: boolean): string => {
    return !hasActivePackage
      ? t('prebidAnalytics.infoBar.messagePackageOff')
      : t('prebidAnalytics.infoBar.messageSomeDomainsOff')
  }

  const togglePrebidAnalyticsPackageHandler = () => {
    hasActivePackage ? setPrebidAnalyticsAvailabilityHandler(false) : setConfirmModalVisible(true)
  }

  const setPrebidAnalyticsAvailabilityHandler = (prebidManagerModuleEnabled: boolean, domainIds?: string[]) => {
    const params = { prebidManagerModuleEnabled }

    if (domainIds) {
      Object.assign(params, { domainIds })
    }

    setPrebidAnalyticsAvailability(nestGqlInput(params))
  }

  const showApplicableDomainsModal = () => {
    setDomainManagementModalVisible(true)
  }

  const confirmWithAllDomainsHandler = () => {
    const domainIds = domains.map((domain) => domain.id)

    setPrebidAnalyticsAvailabilityHandler(true, domainIds)
    setConfirmModalVisible(false)
  }

  return (
    <>
      {(!hasActivePackage || (domains.length > 0 && !isPrebidAnalyticsEnabledOnAllDomains(domains))) && (
        <InfoBar>
          <div className='info-bar__container'>
            {printMessage(hasActivePackage)}

            <div className='info-bar__buttonGroup'>
              {!hasActivePackage ? (
                <Button
                  disabled={isSubmitting}
                  type={ButtonType.Secondary}
                  theme={ButtonTheme.White}
                  buttonType='button'
                  onClickHandler={togglePrebidAnalyticsPackageHandler}
                >
                  {t('prebidAnalytics.infoBar.packageButtonOn')}
                </Button>
              ) : (
                <Button
                  disabled={isSubmitting}
                  type={ButtonType.Secondary}
                  theme={ButtonTheme.BlueOutline}
                  buttonType='button'
                  onClickHandler={showApplicableDomainsModal}
                >
                  {t('prebidAnalytics.infoBar.manageDomains')}
                </Button>
              )}
            </div>
          </div>
        </InfoBar>
      )}
      <InfoBarConfirmModal
        disabled={isSubmitting}
        domains={domains}
        confirmModalVisible={confirmModalVisible}
        confirmWithAllDomainsHandler={confirmWithAllDomainsHandler}
        setConfirmModalVisible={setConfirmModalVisible}
        showApplicableDomainsModal={showApplicableDomainsModal}
      />
      {domains.length > 0 && (
        <ApplicableDomainsModal
          disabled={isSubmitting}
          domains={domains}
          domainManagementModalVisible={domainManagementModalVisible}
          setDomainManagementModalVisible={setDomainManagementModalVisible}
          setPrebidAnalyticsAvailabilityHandler={setPrebidAnalyticsAvailabilityHandler}
        />
      )}
    </>
  )
}
