import React, { useContext, useState } from 'react'
import { Row } from 'react-table'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ConfirmationModal } from 'components/Modals'
import { Edit } from 'icons/Edit'
import { filterStore } from 'utilities/filters/filterStore'
import { LinkButton } from 'components/LinkButton'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { routesBuilder } from 'utilities/routesBuilder'

import DeleteCustomCode from 'gql/mutations/customCodes/DeleteCustomCode.gql'

interface Props {
  row: Row<CustomCode>
}

export const CustomCodeActionsCell: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const [deleteCustomCode] = useMutation(DeleteCustomCode, {
    onCompleted: ({ destroyCustomCode: { errors } }) => {
      setDeleteModalVisible(false)
      errors.length === 0 ? onDeleteSuccess() : onDeleteFailure()
    }
  })

  const onDeleteSuccess = () => {
    createNotifier(t('customCodes.modal.delete.success', { name: row.values.name }), NotificationType.SUCCESS)
    filterStore.forceRefresh()
  }

  const onDeleteFailure = () => {
    createNotifier(t('customCodes.modal.delete.error', { name: row.values.name }), NotificationType.ERROR)
  }

  const confirmHandler = () => deleteCustomCode(nestGqlInput({ customCodeId: row.original.id }))

  return (
    <div className='cell--actions cell--custom_code-actions'>
      <LinkButton
        to={routesBuilder.quickwrap.setup.customCodes.edit(row.original.id)}
        type={ButtonType.Secondary}
        theme={ButtonTheme.Blue}
        className='cell--actions__button'
      >
        <Edit />
        <span className='cell--actions__label'>{t('common:buttons.edit')}</span>
      </LinkButton>

      <Button
        type={ButtonType.Secondary}
        theme={ButtonTheme.BlueReverse}
        className='cell--actions__button'
        onClickHandler={() => setDeleteModalVisible(true)}
      >
        <Bin />
        <span className='cell--actions__label'>{t('common:buttons.delete')}</span>
      </Button>

      <ConfirmationModal
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        content={t('customCodes.modal.delete.confirm', { name: row.values.name })}
        confirmButtonText={t('common:buttons.delete')}
        cancelButtonText={t('common:buttons.cancel')}
        onConfirmHandler={confirmHandler}
      />
    </div>
  )
}
