import React from 'react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { BidderConsentModeOptions } from 'webapp/constants/BidderConsentModeOptions'
import { Errors } from 'utilities/errorUtils'
import { FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'

interface Props {
  errors: Errors
}

export const OwnBidderConsentModeField: React.FC<Props> = ({ errors }) => {
  const { t } = useTranslation('setup')

  return (
    <Field
      component={FormikSelect}
      errors={errors.errorsFor('consentMode')}
      id='own-bidder-consent-mode'
      label={t('bidders.form.consentMode.label')}
      name='consentMode'
      options={BidderConsentModeOptions}
      placeholder={t('bidders.form.consentMode.placeholder')}
      tooltipContent={t('bidders.form.consentMode.tooltip')}
    />
  )
}
