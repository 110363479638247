import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  cellName: 'totalImpressions' | 'totalRevenue'
}

export const TotalValueHeader: React.FC<Props> = ({ cellName }) => {
  const { t } = useTranslation('reports')
  return <div className='header--total-value'>{t(`resultPage.inventory.table.${cellName}`)}</div>
}
