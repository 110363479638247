import React from 'react'

import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Modal } from 'components/Modals'
import { Logo } from 'components/oss/Products/Logo'
import { useActivateProduct } from 'utilities/products/activation'
import { Product } from 'webapp/constants/Product'
import { ProductPlan } from 'webapp/constants/ProductPlan'

import { ProductActivationModalProps } from './types'

const PRODUCTS_WITHOUT_TRIAL = [Product.FillRateBooster]

const pickHighlightedTranslation = (plan: ProductPlan, product: Product) => {
  if (plan === ProductPlan.Unlimited) {
    return 'activationModal.pro.description.use'
  }

  if (PRODUCTS_WITHOUT_TRIAL.includes(product)) {
    return 'activationModal.trial.description.use'
  } else {
    return 'activationModal.trial.description.try'
  }
}

export const ProductActivationModal: React.FC<ProductActivationModalProps> = ({
  plan,
  productConnection,
  setVisible,
  visible
}) => {
  const { t } = useTranslation('products')

  const { activateProduct, isActivatingProduct } = useActivateProduct()

  const { product }: ProductConnection = productConnection
  const productName = t(`product.${product}`)

  const onCancelHandler = () => {
    setVisible(false)
  }

  const onConfirmHandler = async () => {
    if (!isActivatingProduct) {
      await activateProduct(productConnection, plan)
    }
  }

  return (
    <Modal backdrop containerClass='ProductActivationModal' setVisible={setVisible} shadow visible={visible}>
      <div className='ProductActivationModal__header'>
        <Logo product={product} />
        <div className='ProductActivationModal__header-product-name'>{productName}</div>
      </div>

      <div className='ProductActivationModal--highlight'>
        {t(pickHighlightedTranslation(plan, product), { product: productName })}
      </div>

      <div className='ProductActivationModal__description'>
        {t(`activationModal.${plan}.description.info`, { product: productName })}
      </div>

      <div>
        <Button
          className='ProductActivationModal__button'
          onClickHandler={onCancelHandler}
          theme={ButtonTheme.BlueReverse}
          type={ButtonType.Primary}
        >
          {t('common:buttons.cancel')}
        </Button>

        <Button
          className='ProductActivationModal__button'
          onClickHandler={onConfirmHandler}
          theme={ButtonTheme.Blue}
          type={ButtonType.Primary}
        >
          {t('buttons.request')}
        </Button>
      </div>
    </Modal>
  )
}
