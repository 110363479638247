import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'gql/fragments/common'
import { UPDATE_DOMAIN_FRAGMENT } from 'gql/fragments/domains/UpdateDomainFragment'

const PAYLOAD_TYPE = 'UpdateDomainPayload'

export const UPDATE_DOMAIN = gql`
  ${UPDATE_DOMAIN_FRAGMENT}
  ${MUTATION_STATE(PAYLOAD_TYPE)}

  mutation UpdateDomain($input: UpdateDomainInput!) {
    updateDomain(input: $input) {
      domain {
        ...UpdateDomainFragment
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
