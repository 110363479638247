import React from 'react'

import { EditPage } from 'pages/quickwrap/Protection/BlockUrlGroups/EditPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { ListPage } from 'pages/quickwrap/Protection/BlockUrlGroups/ListPage'
import { NewPage } from 'pages/quickwrap/Protection/BlockUrlGroups/NewPage'
import { routesBuilder } from 'utilities/routesBuilder'

export const BlockUrlGroupsRoutes = [
  <LayoutRoute
    userPermissionRequired={true}
    key='block-urls-edit'
    path={routesBuilder.quickwrap.blockUrlGroups.edit()}
    component={EditPage}
  />,
  <LayoutRoute
    userPermissionRequired={true}
    key='block-urls-new'
    path={routesBuilder.quickwrap.blockUrlGroups.new}
    component={NewPage}
  />,
  <LayoutRoute
    userPermissionRequired={true}
    key='block-urls-list'
    exact
    path={routesBuilder.quickwrap.blockUrlGroups.root}
    component={ListPage}
  />
]
