import React, { useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { AbilityContext } from 'webapp/context/AbilityContext'
import { routesBuilder } from 'utilities/routesBuilder'
import { useGoogleAnalytics } from 'utilities/reactGa'
import { UserContext } from 'webapp/context/UserContext'

interface Props extends RouteProps {
  component: any
}

export const GuestRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const ability = useContext(AbilityContext)
  const user = useContext(UserContext).user
  useGoogleAnalytics(location.pathname)

  const redirectUrl = ability.can(AbilityAction.ACCESS, AbilitySubject.PRODUCT_MANAGEMENT)
    ? routesBuilder.oss.products.management
    : routesBuilder.oss.products.root

  return <Route {...rest} render={(props) => (user ? <Redirect to={redirectUrl} /> : <Component {...props} />)} />
}
