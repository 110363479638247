import React, { useContext } from 'react'

import { Subject } from '@casl/ability'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { Layout } from 'components/Layout'
import { useHasNoProductAccess } from 'components/Layout/LayoutRoute/useHasNoProductAccess'
import { NotFoundPage } from 'pages/Errors'
import { removeProductPrefix } from 'utilities/products/utils'
import { useGoogleAnalytics } from 'utilities/reactGa'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction } from 'utilities/security/abilityAction'
import { Product } from 'webapp/constants/Product'
import { AbilityContext } from 'webapp/context/AbilityContext'
import { UserContext } from 'webapp/context/UserContext'

interface LayoutRouteProps extends RouteProps {
  product?: Product
  userPermissionRequired?: boolean
  withSidebar?: boolean
}

export const LayoutRoute: React.FC<LayoutRouteProps> = ({
  component: Component,
  product = Product.Quickwrap,
  userPermissionRequired,
  withSidebar = true,
  ...rest
}) => {
  const ability = useContext(AbilityContext)
  const { user } = useContext(UserContext)
  const hasNoProductAccess = useHasNoProductAccess()

  useGoogleAnalytics(location.pathname)

  if (!user) {
    return <Redirect to='/log_in' />
  }

  if (hasNoProductAccess) {
    return <Redirect to={routesBuilder.oss.products.root} />
  }

  if (userPermissionRequired && !ability.can(AbilityAction.ACCESS, removeProductPrefix(String(rest.path)) as Subject)) {
    return <Route {...rest} render={() => <NotFoundPage />}></Route>
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout product={product} withSidebar={withSidebar}>
          {Component && <Component {...props} />}
        </Layout>
      )}
    />
  )
}
