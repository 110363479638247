import { getProductName } from 'utilities/products/utils'

export const DataStudioLinksFactory = (productConnection?: ProductConnection): DataStudioLinkFormData => {
  return {
    dataStudioUrl: productConnection?.dataStudioUrl ?? '',
    productConnectionId: {
      value: productConnection?.id ?? '',
      label: productConnection?.product ? getProductName(productConnection.product) : ''
    },
    workspaceId: {
      value: productConnection?.workspace.id ?? '',
      label: productConnection?.workspace.name ?? ''
    }
  }
}
