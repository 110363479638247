import React, { memo } from 'react'

import { Input } from 'components/Form'

interface Props {
  label: string
  value: string
  id: string
}

const ReportDetailsInfo: React.FC<Props> = memo(({ label, value, id }) => {
  return (
    <div className='report-summary__input-container'>
      <Input id={id} name={id} className='report-summary__input' type='text' label={label} value={value} disabled />
    </div>
  )
})

ReportDetailsInfo.displayName = 'ReportDetailsInfo'

export { ReportDetailsInfo }
