import React from 'react'

interface Props {
  getListboxProps: () => Record<string, unknown>
  getOptionProps: (option: { option: string; index: number }) => Record<string, unknown>
  groupedOptions: string[]
}

export const Listbox: React.FC<Props> = ({ getListboxProps, getOptionProps, groupedOptions }) => {
  return groupedOptions.length > 0 ? (
    <ul className='tag-input__listbox' {...getListboxProps()}>
      {groupedOptions.map((option, index) => (
        <li key={index} {...getOptionProps({ option, index })}>
          {option}
        </li>
      ))}
    </ul>
  ) : null
}
