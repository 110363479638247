import {
  AdUnitAddEditProps,
  AdUnitData,
  AdUnitVars,
  DomainsData,
  UpsertOssAdUnitData,
  UpsertOssAdUnitVars
} from './types'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import React, { useContext, useEffect, useState } from 'react'
import { prepareFormDomains, prepareInitValues, useUrlDomainId } from './service'
import { useLazyQuery, useMutation } from '@apollo/client'

import AdUnitForm from 'components/oss/Inventory/AdUnits/AdUnitForm'
import { DOMAINS_WITH_OSS_AD_UNITS_AND_OSS_PAGES } from 'gql/queries/domains/DomainWithOssAdUnitsAndOssPages'
import { ErrorUtils } from 'utilities/errorUtils'
import { Formik } from 'formik'
import { LoadingContainer } from 'components/LoadingContainer'
import { NO_CACHE } from 'utilities/apolloClient'
import { NotificationType } from 'components/ToastNotifier'
import { OSS_AD_UNIT } from 'gql/queries/adUnits/ossAdUnit'
import { Product } from 'constants/Product'
import SaveAdUnitModal from '../SaveAdUnitModal'
import { StatusForm } from 'components/Form'
import { UPSERT_OSS_AD_UNIT } from 'gql/mutations/adUnits/UpsertOssAdUnit'
import { UserContext } from 'context/UserContext'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { routesBuilder } from 'utilities/routesBuilder'
import { useParams } from 'react-router-dom'
import useProductHistory from 'webapp/hooks/useProductHistory'
import { useTranslation } from 'react-i18next'

const AdUnitFormContainer = ({ formType }: AdUnitAddEditProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { history, prevProduct } = useProductHistory()
  const { adUnitId: urlAdUnitId } = useParams<{ adUnitId: OssAdUnit['id'] }>()
  const urlDomainId = useUrlDomainId()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [domains, setDomains] = useState<Domain[]>()
  const [adUnit, setAdUnit] = useState<AdUnitData['ossAdUnit']>()
  const [initActivePages, setInitActivePages] = useState<OssAdUnit['id'][]>([])
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false)
  const [redirectTo, setRedirectTo] = useState<Product>(Product.OSS)
  const { isSuperuser } = useContext(UserContext)

  useEffect(() => {
    if (formType === 'new') {
      fetchDomains()
    } else if (formType === 'edit' && urlAdUnitId) {
      fetchAdUnit({ variables: { adUnitId: urlAdUnitId } })
    }
  }, [formType, urlAdUnitId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [fetchDomains] = useLazyQuery<DomainsData>(DOMAINS_WITH_OSS_AD_UNITS_AND_OSS_PAGES, {
    ...NO_CACHE,
    onCompleted: (data: DomainsData): void => setDomains(data.domains.nodes)
  })

  const [fetchAdUnit] = useLazyQuery<AdUnitData, AdUnitVars>(OSS_AD_UNIT, {
    ...NO_CACHE,
    onCompleted: (data: AdUnitData): void => setAdUnit(data.ossAdUnit)
  })

  const [upsertOssAdUnit, { loading }] = useMutation<UpsertOssAdUnitData, { input: UpsertOssAdUnitVars }>(
    UPSERT_OSS_AD_UNIT,
    {
      onCompleted: ({ upsertOssAdUnit: { ossAdUnit, errors } }) => {
        setErrors(new ErrorUtils(errors))

        if (errors.length) {
          setShowSaveModal(false)
          createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
        } else if (ossAdUnit) {
          const { activeAdUnitId, activeDomainVersionId, adUnitName } = ossAdUnit

          createNotifier(t(`pages.${formType}.successMessage`, { name: adUnitName }), NotificationType.SUCCESS)
          formStatus.clear()
          redirectOnSave(activeAdUnitId, activeDomainVersionId)
        }
      }
    }
  )

  const redirectOnSave = (adUnitId: AdUnit['id'], domainVersionId: DomainVersion['id']): void => {
    const routeBasedOnProduct = (redirectTo: Product): void => {
      switch (redirectTo) {
        case Product.Quickwrap:
          history.push(routesBuilder.quickwrap.inventory.adUnit.edit(adUnitId, domainVersionId))
          break
        case Product.Revive:
          history.push(routesBuilder.revive.configuration.root)
          break
        default:
          history.goBack()
          break
      }
    }

    routeBasedOnProduct(redirectTo)
  }

  const handleSave = (values: UpsertOssAdUnitVars, product: Product): void => {
    setRedirectTo(product)
    upsertOssAdUnit(nestGqlInput(values))
  }

  return domains || adUnit ? (
    <Formik
      enableReinitialize={true}
      initialValues={prepareInitValues({
        adUnit,
        formType,
        initActivePages,
        urlDomainId,
        isSuperuser
      })}
      onSubmit={(values: UpsertOssAdUnitVars) => void (formType === 'edit' && upsertOssAdUnit(nestGqlInput(values)))}
    >
      {({ dirty, setFieldValue, values }) => {
        const disabled: boolean = !dirty || loading

        const buttonProps: Pick<ButtonProps, 'theme' | 'type'> = {
          theme: ButtonTheme.Blue,
          type: ButtonType.Primary
        }

        return (
          <StatusForm name='oss-ad-unit' dirty={dirty}>
            <AdUnitForm
              adUnitId={adUnit?.id ?? null}
              domainId={adUnit?.domain.id || values.domainId}
              domains={prepareFormDomains(formType, domains, adUnit)}
              errors={errors}
              formType={formType}
              onInitActivePages={setInitActivePages}
              onSetFieldValue={setFieldValue}
              values={values}
            />

            {formType === 'new' ? (
              <Button
                buttonType={'button'}
                disabled={disabled}
                onClickHandler={() => void setShowSaveModal(true)}
                {...buttonProps}
              >
                {t('common:buttons.save')}
              </Button>
            ) : (
              <Button buttonType='submit' disabled={disabled} {...buttonProps}>
                {loading ? t('common:buttons.saving') : t('common:buttons.save')}
              </Button>
            )}

            {formType === 'new' && (
              <SaveAdUnitModal
                loading={loading}
                onSave={() => void handleSave(values, prevProduct ?? Product.OSS)}
                onSaveAndGoToQW={() => void handleSave(values, Product.Quickwrap)}
                setVisible={setShowSaveModal}
                visible={showSaveModal}
              />
            )}
          </StatusForm>
        )
      }}
    </Formik>
  ) : (
    <LoadingContainer loading fullHeight />
  )
}

export const AdUnitAdd = (): JSX.Element => <AdUnitFormContainer formType={'new'} />
export const AdUnitEdit = (): JSX.Element => <AdUnitFormContainer formType={'edit'} />
