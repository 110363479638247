import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { ErrorUtils } from 'utilities/errorUtils'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { PrebidModuleKind } from 'webapp/constants/PrebidModuleKind'
import { RequestNewElementContainer } from 'containers/RequestNewElementContainer'
import { RequestPrebidModuleFactory } from 'containers/quickwrap/Setup/ModulesContainer/formSchema'

import RequestPrebidModule from 'gql/mutations/prebidModules/RequestPrebidModule.gql'

interface Props {
  kind: PrebidModuleKind
}

export const RequestPrebidModulesContainer: React.FC<Props> = ({ kind }) => {
  const { t } = useTranslation('setup')
  const [value, setValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const { createNotifier } = useContext(NotificationContext) as Notification

  const description = useMemo(() => t(`requestModule.${kind}.description`), [kind])
  const title = useMemo(() => t(`requestModule.${kind}.modal.title`), [kind])
  const placeholder = useMemo(() => t(`requestModule.${kind}.modal.placeholder`), [kind])

  const [requestPrebidModule, { loading: isSubmitting }] = useMutation(RequestPrebidModule, {
    onCompleted: ({ prebidModuleRequest: { errors } }) => {
      if (!errors.length) {
        setVisible(false)
        createNotifier(getMessage(), NotificationType.SUCCESS)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const getMessage = () => t(`requestModule.${kind}.notification`, { name: value })

  const onSubmitHandler = (value: string) => {
    setValue(value)
    requestPrebidModule({ variables: { input: RequestPrebidModuleFactory(value, kind) } })
  }

  return (
    <div className='request-prebid-module__form'>
      <RequestNewElementContainer
        description={description}
        errorKey={'name'}
        errors={errors}
        isSubmitting={isSubmitting}
        modalTitle={title}
        modalPlaceholder={placeholder}
        visible={visible}
        onSubmitHandler={onSubmitHandler}
        setVisible={setVisible}
      />
    </div>
  )
}
