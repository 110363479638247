import React from 'react'
import { Column, Row } from 'react-table'
import { useHistory } from 'react-router-dom'

import { ABTestRowData } from './ABTestRowData'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { columnsConfig } from './columnsConfig'
import { QueryTable } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'

import ABTestsQuery from 'gql/queries/abTesting/ABTests.gql'

const mapper = (node: ABTest) => new ABTestRowData(node)

export const ABTestsTable: React.FC = () => {
  const history = useHistory()

  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    history.push(routesBuilder.quickwrap.abTesting.edit((row.original as unknown as ABTest).id))
  }

  return (
    <QueryTable<ABTestRowData>
      collectionName={CollectionNames.ABTests}
      dataQuery={ABTestsQuery}
      columnsConfig={columnsConfig() as Column[]}
      mapper={mapper}
      onRowClick={openEditingPage}
    />
  )
}
