import React from 'react'
import { FieldConfig, useField } from 'formik'

import { PercentInput } from 'components/Form/PercentInput'

interface ShareInputProps {
  id: string
  field: FieldConfig
  containerClassName?: string
  disabled?: boolean
  errors?: Array<string>
  invert: boolean
  hasErrors?: boolean
  label?: string
  labelStyle?: string
  onChangeHandler?: (value: number) => void
  tooltipContent?: string
}

export const FormikShareInputContainer: React.FC<ShareInputProps> = ({
  id,
  containerClassName,
  disabled,
  errors,
  invert,
  hasErrors,
  label,
  labelStyle,
  onChangeHandler,
  tooltipContent,
  ...other
}) => {
  const [field, , helpers] = useField(other.field.name)

  const onChange = (value: string) => {
    helpers.setValue(Number(value))
    onChangeHandler && onChangeHandler(Number(value))
  }

  return (
    <>
      <PercentInput
        id={id}
        name={field.name}
        onChangeHandler={onChange}
        value={field.value}
        containerClassName={containerClassName}
        disabled={disabled}
        errors={errors}
        invert={invert}
        hasErrors={hasErrors}
        label={label}
        labelStyle={labelStyle}
        tooltipContent={tooltipContent}
      />
    </>
  )
}
