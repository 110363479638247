import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from 'webapp/locales/en/translations.json'
import enAbTesting from 'webapp/locales/en/abTesting.json'
import enAuth from 'webapp/locales/en/auth.json'
import enBidders from 'webapp/locales/en/bidders.json'
import enBreadcrumbs from 'webapp/locales/en/breadcrumbs.json'
import enDataStudio from 'webapp/locales/en/dataStudio.json'
import enGam from 'webapp/locales/en/gam.json'
import enHistory from 'webapp/locales/en/history.json'
import enInventory from 'webapp/locales/en/inventory.json'
import enPerformance from 'webapp/locales/en/performance.json'
import enPrebidModules from 'webapp/locales/en/prebidModules.json'
import enPriceGenius from 'webapp/locales/en/priceGenius.json'
import enProducts from 'webapp/locales/en/products.json'
import enProfile from 'webapp/locales/en/profile.json'
import enProtection from 'webapp/locales/en/protection.json'
import enReports from 'webapp/locales/en/reports.json'
import enRevive from 'webapp/locales/en/revive.json'
import enSetup from 'webapp/locales/en/setup.json'
import enSidebar from 'webapp/locales/en/sidebar.json'
import enUsers from 'webapp/locales/en/users.json'
import enWorkspaces from 'webapp/locales/en/workspaces.json'

const resources = {
  en: {
    abTesting: enAbTesting,
    auth: enAuth,
    bidders: enBidders,
    breadcrumbs: enBreadcrumbs,
    common: en,
    dataStudio: enDataStudio,
    gam: enGam,
    history: enHistory,
    inventory: enInventory,
    performance: enPerformance,
    prebidModules: enPrebidModules,
    priceGenius: enPriceGenius,
    products: enProducts,
    profile: enProfile,
    protection: enProtection,
    reports: enReports,
    revive: enRevive,
    setup: enSetup,
    sidebar: enSidebar,
    users: enUsers,
    workspaces: enWorkspaces
  }
}

i18n.use(initReactI18next).init({
  resources,
  ns: [
    'abTesting',
    'auth',
    'bidders',
    'breadcrumbs',
    'common',
    'dataStudio',
    'enProtection',
    'gam',
    'history',
    'inventory',
    'performance',
    'prebidModules',
    'priceGenius',
    'reports',
    'revive',
    'setup',
    'sidebar',
    'workspaces'
  ],
  defaultNS: 'common',
  lng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
