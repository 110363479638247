import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { ButtonTheme, ButtonType } from 'components/Button'
import { DataStudioLinksTable } from 'components/oss/Performance/DataStudioLinks/Table'
import { LinkButton } from 'components/LinkButton'
import { routesBuilder } from 'utilities/routesBuilder'

export const DataStudioLinksContainer: React.FC = () => {
  const { t } = useTranslation('dataStudio')

  return (
    <Box className='data-studio-links' list={true}>
      <BoxHeader
        title={t('links.list.title')}
        containerClassName='box-header__container'
        rowClassName='header__row--width-100'
      >
        <LinkButton
          type={ButtonType.Primary}
          theme={ButtonTheme.Blue}
          to={routesBuilder.oss.performance.dataStudioLinks.new}
        >
          {t('common:buttons.addNew')}
        </LinkButton>
      </BoxHeader>

      <DataStudioLinksTable />
    </Box>
  )
}
