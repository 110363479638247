import React from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/Form'
import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { Edit } from 'icons/Edit'

interface Props {
  label: string
  value: string
  tooltipContent?: string
  onEditHandler: () => void
}

export const ReportTimePeriodInfo: React.FC<Props> = ({ label, value, tooltipContent, onEditHandler }) => {
  const { t } = useTranslation('reports')

  return (
    <div className='report-summary__container report-summary__container-time'>
      <div className='report-summary__input-container'>
        <Input
          id='time-period'
          name='time-period'
          className='report-summary__input'
          type='text'
          tooltipContent={tooltipContent}
          label={label}
          value={value}
          disabled
        />
      </div>
      <Button
        type={ButtonType.SingleAction}
        theme={ButtonTheme.Blue}
        className='button--time-period '
        onClickHandler={onEditHandler}
        tooltipContent={t('resultPage.summary.timePeriod.buttonTooltip')}
      >
        <Edit />
      </Button>
    </div>
  )
}
