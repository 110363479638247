const sizeArrayToString = (size: number[] | string) => {
  return Array.isArray(size) ? `${size[0]}x${size[1]}` : String(size)
}

export const parseToDeviceForm = (record: WorkspaceDevice) => {
  return {
    ...record,
    minViewPort: String(record.minViewPort),
    bannerSizes: record.bannerSizes?.map(sizeArrayToString) || [],
    playerSize: record.playerSize ? [sizeArrayToString(record.playerSize)] : []
  }
}

export const getEmptyDeviceTemplate = () => ({
  name: '',
  minViewPort: '',
  bannerSizes: [],
  playerSize: []
})

export const WorkspaceDevicesFactory = (records: WorkspaceDevice[]) => {
  if (records.length < 1) return { sizeMapping: [getEmptyDeviceTemplate()] }

  return {
    sizeMapping: records.map(parseToDeviceForm)
  }
}
