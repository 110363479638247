import React, { memo } from 'react'

import { capitalize } from 'utilities/capitalize'
import { Image } from 'components/Image'

interface Props {
  fileName: string
  placeholder: string
  bidderName: string
  className: string
}

const BidderLogo: React.FC<Partial<Props>> = memo(({ fileName = '', placeholder = '', bidderName, className }) => {
  let src
  try {
    src = require(`images/bidders/${fileName}.png`)
  } catch {} // eslint-disable-line no-empty

  return (
    <>
      {src ? (
        <>
          <Image src={src} alt={placeholder} className={className} containerClassName='bidders-select-logo-container' />
          {bidderName && <span className='bidders-select-alias-name'>{capitalize(bidderName)}</span>}
        </>
      ) : (
        <div className={className}>{placeholder}</div>
      )}
    </>
  )
})

BidderLogo.displayName = 'BidderLogo'

export { BidderLogo }
