import React from 'react'

import { TagsInput } from 'components/Form/TagsInput'

type AdvancedFilterTagsProps = {
  filter: AdvancedFilter
  filterConfig?: AdvancedFilterConfiguration
  updateFilter: (id: string, newFilter: Partial<AdvancedFilter>) => void
}

export const AdvancedFilterTagsInputValue: React.FC<AdvancedFilterTagsProps> = ({
  filter,
  filterConfig,
  updateFilter
}) => (
  <TagsInput
    id={`advanced-filter__value--${filter.by}`}
    onChange={(values: AdvancedFilter['values']): void => updateFilter(filter.id, { values })}
    placeholder={filterConfig?.placeholder}
    value={filter.values}
  />
)
