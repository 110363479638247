import React, { useCallback } from 'react'

import { currencyUtils } from 'utilities/currencyUtils'

interface Props {
  value: ValueType
  currency?: string
  isExpanded?: boolean
  divider?: number
}

type ValueType = string | number | undefined | null

export const TotalValueCell: React.FC<Props> = ({ value, currency, isExpanded, divider }) => {
  const getValue = useCallback(
    (value: ValueType, divider = 1, currencyCode?: string) => {
      if (!value || isExpanded) {
        return '-'
      }
      const result = parseInt(value.toString()) / divider
      return currencyUtils.currencyFormat(result, currencyCode)
    },
    [isExpanded]
  )

  return <div className='cell--total-value'>{getValue(value, divider, currency)}</div>
}
