import React, { memo, useState, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { Avatar } from 'components/Avatar'
import { useOnClickOutside } from 'utilities/useOnClickOutside'
import { UserContext } from 'webapp/context/UserContext'
import { UserWidegetDropdown } from 'components/Header/UserWidget/UserWidgetDropdown'

import LogoutMutation from 'gql/mutations/auth/Logout.gql'

const UserWidget: React.FC = memo(() => {
  const userWidgetRef = useRef<HTMLDivElement>(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useOnClickOutside(userWidgetRef, () => setIsDropdownOpen(false))

  const history = useHistory<History>()
  const userContext = useContext(UserContext)
  const user: User = userContext.user as User

  const [Logout] = useMutation(LogoutMutation, {
    variables: { input: {} },
    onCompleted: () => {
      userContext.logout()
      history.push('/')
    }
  })

  const getUserName = (user: User) => user?.fullName || user?.email

  return (
    <div id='user-widget' className='user-widget' ref={userWidgetRef}>
      <div onClick={() => setIsDropdownOpen((prev) => !prev)} className='user-widget__dropdown-header'>
        {user && <Avatar user={user} />}
        <div className='user-widget__name'>{getUserName(user)}</div>
      </div>
      {isDropdownOpen && (
        <UserWidegetDropdown user={user} setIsDropdownOpen={setIsDropdownOpen} onLogoutHandler={Logout} />
      )}
    </div>
  )
})

UserWidget.displayName = 'UserWidget'

export { UserWidget }
