import React from 'react'

import { CellProps, Column } from 'react-table'

import { DateCell } from 'components/Table'
import { CroppedCell } from 'components/Table/CroppedCell/CroppedCell'
import { AdNetworkCell } from 'components/oss/Workspaces/WorkspacesTable/AdNetworkCell'
import { WorkspacePackageEnum } from 'utilities/workspacePackageUtils'
import i18n from 'webapp/i18n'
import { ActionsCell } from './ActionsCell'
import { ActivationCell } from './ActivationCell'
import { LoginCell } from './LoginCell'
import { PackageCell } from './PackageCell'
import PriceGeniusGlobalTargetingToggle from './PriceGeniusGlobalTargetingToggle'

type WorkspaceDomain = {
  name: string
}

const createDomainCell = (value: WorkspaceDomain[]) => {
  const elements = value?.map((val: { name: string }) => val.name) as string[]
  return <CroppedCell textElements={elements} />
}

export const columnsConfig: Column<Workspace>[] = [
  {
    Header: () => i18n.t('workspaces:list.tableHeader.name'),
    accessor: 'name'
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.crmId'),
    accessor: 'crmId'
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.domains'),
    accessor: 'domains',
    disableSortBy: true,
    Cell: ({ value }) => createDomainCell(value as Array<WorkspaceDomain>)
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.login'),
    id: 'login',
    Cell: ({ row }: CellProps<Workspace>) => <LoginCell row={row} />,
    disableSortBy: true
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.priceGeniusGlobalTargeting'),
    accessor: 'pgGlobalTargetingEnabled',
    Cell: ({
      row: {
        original: { id, pgGlobalTargetingEnabled }
      }
    }: CellProps<Workspace>): JSX.Element => (
      <PriceGeniusGlobalTargetingToggle status={pgGlobalTargetingEnabled} workspaceId={id} />
    ),
    disableSortBy: true
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.ybBidders'),
    id: 'ybDemandEnable',
    Cell: ({ row }: CellProps<Workspace>) => <PackageCell packageName={WorkspacePackageEnum.YB_DEMAND} row={row} />,
    disableSortBy: true
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.customCode'),
    id: 'customCode',
    Cell: ({ row }: CellProps<Workspace>) => <PackageCell packageName={WorkspacePackageEnum.CUSTOM_CODE} row={row} />
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.adNetwork'),
    id: 'adNetworkEnabled',
    Cell: ({ row }: CellProps<Workspace>) => <AdNetworkCell row={row} />,
    disableSortBy: true
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.csManager'),
    accessor: 'csManager',
    Cell: ({ value }) => (value?.fullName || value?.email) ?? ''
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.owner'),
    accessor: 'owner',
    Cell: ({ value }) => (value?.fullName || value?.email) ?? ''
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.createdAt'),
    accessor: 'createdAt',
    Cell: ({ value }) => <DateCell value={value} />
  },

  {
    Header: () => i18n.t('workspaces:list.tableHeader.active'),
    accessor: 'active',
    Cell: ({ row }) => <ActivationCell row={row} />
  },

  {
    id: 'actions',
    Cell: ({ row }: CellProps<Workspace>) => <ActionsCell row={row} />
  }
]
