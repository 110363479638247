import React from 'react'

import { PrebidModuleFormContainer, UserIdsFormContainer } from 'containers/quickwrap/Setup/ModulesContainer'
import { PrebidModuleKind } from 'webapp/constants/PrebidModuleKind'

export const ModulesPage: React.FC = () => {
  return (
    <>
      <UserIdsFormContainer />
      <PrebidModuleFormContainer prebidModuleKind={PrebidModuleKind.BrandProtection} />
      <PrebidModuleFormContainer prebidModuleKind={PrebidModuleKind.Analytics} />
    </>
  )
}
