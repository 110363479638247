import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import classNames from 'classnames'

import { Close } from 'icons/Close'
import { ModalProps } from './types'

export const Modal: React.FC<ModalProps> = ({
  backdrop,
  bodyClass,
  children,
  closeButton,
  containerClass,
  id,
  maxWidth,
  onClose,
  setVisible,
  shadow,
  title,
  visible
}) => {
  const onCloseHandler = () => {
    if (onClose) onClose()
    setVisible?.(false)
  }

  /**
   * Workaround to prevent children onClicks functions propagated to Dialog onClicks
   */
  const handleDialogClick = (event: React.MouseEvent<HTMLElement>): void => event.stopPropagation()

  return (
    <Dialog
      id={id}
      open={visible}
      onClick={handleDialogClick}
      onClose={onCloseHandler}
      maxWidth={maxWidth}
      classes={{
        ...(backdrop && { root: 'modal__root' }),
        ...(containerClass && { container: containerClass }),
        paper: `modal__paper${shadow ? '--shadow' : ''}`
      }}
    >
      {closeButton && (
        <button className='modal__close' onClick={onCloseHandler}>
          <Close />
        </button>
      )}
      {title && <p className='modal__header-title'>{title}</p>}
      <div className={classNames('modal__container', [bodyClass])}>{children}</div>
    </Dialog>
  )
}
