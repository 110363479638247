import React, { useContext, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Formik, Field } from 'formik'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AvatarSlider } from './AvatarSlider'
import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ProfileFactory } from './formSchema'
import { ProfileSerializer } from 'serializers/ProfileSerializer'
import { Section } from 'components/Form'
import { StatusForm } from 'components/Form'

import EditProfileMutation from 'gql/mutations/profile/EditProfile.gql'

interface Props {
  user: User
  onSuccess: (user: User) => void
}

export const ProfileForm: React.FC<Props> = ({ user, onSuccess }) => {
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('profile')

  const [EditProfile, { loading: isSubmitting }] = useMutation(EditProfileMutation, {
    onCompleted: ({ editProfile: { profile, errors } }) => {
      if (profile) {
        createNotifier(t('mainForm.successMessage'), NotificationType.SUCCESS)
        onSuccess(profile)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmitHandler = (values: Profile) => {
    const serializer = new ProfileSerializer(values)
    EditProfile({ variables: { input: serializer.updateParams() } })
  }

  return (
    <div className='password-form'>
      <Formik initialValues={ProfileFactory(user)} onSubmit={onSubmitHandler} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='oss-profile' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={6}>
                <Section className='profile-basic-information' title={t('mainForm.basicSectionTitle')}>
                  <Field
                    id='full-name'
                    name='fullName'
                    type='text'
                    label={t('mainForm.fullName.label')}
                    component={FormikInput}
                    errors={errors.errorsFor('fullName')}
                  />
                  <Field
                    id='email'
                    name='email'
                    type='text'
                    label={t('mainForm.email.label')}
                    component={FormikInput}
                    disabled={true}
                    errors={errors.errorsFor('email')}
                  />
                </Section>
              </Grid>
              <Grid item lg={4} xs={6}>
                <Section title={t('mainForm.avatarTitle')}>
                  <Field id='avatar' name='avatar' type='hidden' />
                  <AvatarSlider />
                </Section>
              </Grid>
            </Grid>

            <Button
              disabled={isSubmitting || !dirty}
              type={ButtonType.Primary}
              theme={ButtonTheme.Blue}
              buttonType='submit'
            >
              {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
