import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { UserContext } from 'webapp/context/UserContext'
import { PasswordForm } from 'components/oss/Profile/PasswordForm'
import { ProfileForm } from 'components/oss/Profile/ProfileForm'

export const ProfileContainer: React.FC = () => {
  const { t } = useTranslation('profile')
  const userContext = useContext(UserContext)
  const user = userContext.user

  return (
    <>
      <Box className='profile-form' isExpandable={true}>
        <BoxHeader title={t('mainForm.title')} subtitle={t('mainForm.subtitle')} />

        <BoxBody>
          <div className='box__body'>{user && <ProfileForm user={user} onSuccess={userContext.setUser} />}</div>
        </BoxBody>
      </Box>

      <Box className='profile-password-form' isExpandable={true}>
        <BoxHeader title={t('passwordForm.title')} subtitle={t('passwordForm.subtitle')} />

        <BoxBody>
          <div className='box__body'>
            <PasswordForm />
          </div>
        </BoxBody>
      </Box>
    </>
  )
}
