import React, { useContext } from 'react'
import { createContextualCan } from '@casl/react'
import { Ability, AbilityBuilder, MongoQuery } from '@casl/ability'

import { AbilityAction, AbilitySubject, subjectTypeFromGraphql } from 'utilities/security'
import { processEditorPermissions } from 'webapp/context/AbilityContext/processEditorPermissions'
import { processOwnerPermissions } from 'webapp/context/AbilityContext/processOwnerPermissions'
import { processPackagePermissions } from 'webapp/context/AbilityContext/processPackagePermissions'
import { processProductPermissions } from 'webapp/context/AbilityContext/processProductPermissions'
import { processSuperuserPermissions } from 'webapp/context/AbilityContext/processSuperuserPermissions'
import { processViewerPermissions } from 'webapp/context/AbilityContext/processViewerPermissions'
import { Product } from 'webapp/constants/Product'
import { Role } from 'webapp/constants/Role'
import { UserContext } from 'webapp/context/UserContext'

export type CanFunctionType = (action: AbilityAction, subject: AbilitySubject, conditions?: MongoQuery) => void
export type IsPackageActiveFunctionType = (packageName: string) => boolean
export type IsProductActiveFunctionType = (productName: Product) => boolean

const abilityBuilder = (
  user?: User,
  isPackageActive?: IsPackageActiveFunctionType,
  isProductActive?: IsProductActiveFunctionType
): Ability => {
  const { can, build } = new AbilityBuilder<Ability>(Ability)

  if (user) {
    switch (user.role) {
      case Role.Viewer:
        processViewerPermissions(can)
        break
      case Role.Editor:
        processEditorPermissions(can, user)
        break
      case Role.Owner:
        processEditorPermissions(can, user)
        processOwnerPermissions(can, user)
        break
      case Role.Admin:
      case Role.Adops:
      case Role.BO:
      case Role.CP:
      case Role.CS:
        processSuperuserPermissions(can, user)
        break
    }
  }

  if (isPackageActive) {
    processPackagePermissions(can, isPackageActive)
  }

  if (isProductActive) {
    processProductPermissions(can, isProductActive)
  }

  can(AbilityAction.READ, AbilitySubject.ALL)

  return build({ detectSubjectType: subjectTypeFromGraphql })
}

export const AbilityContext = React.createContext<Ability>(abilityBuilder())

export const AbilityProvider: React.FC = ({ children }) => {
  const userContext = useContext(UserContext)
  const { isPackageActive, isProductActive, user } = userContext

  const ability = abilityBuilder(user as User, isPackageActive, isProductActive)

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

export const Can = createContextualCan(AbilityContext.Consumer)
