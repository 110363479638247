import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { removeQueryParams } from 'utilities/removeQueryParams'
import { extractQueryParams } from 'utilities/extractQueryParams'

interface Props {
  defaultTab?: string
  href: string
  title: string
  searchParam: string
}

export const TabNavigationItem: React.FC<Props> = ({ defaultTab, href, title, searchParam }) => {
  const location = useLocation()
  const locationSearchParams = new URLSearchParams(location.search)
  const hrefSearchParams = new URLSearchParams(extractQueryParams(href))

  const doesPathMatch = location.pathname.includes(removeQueryParams(href)) || Boolean(defaultTab)
  const doSearchParamsMatch =
    (locationSearchParams.get(searchParam) ?? defaultTab) === hrefSearchParams.get(searchParam)
  const isActive = doesPathMatch && doSearchParamsMatch

  const activeClassName = isActive ? 'tab-navigation__item_active' : ''

  let hrefWithSearchParams = href
  locationSearchParams.forEach((value, param) => {
    if (!hrefSearchParams.has(param)) {
      hrefWithSearchParams += `&${param}=${value}`
    }
  })

  return (
    <li>
      <NavLink to={hrefWithSearchParams} className={`tab-navigation__item ${activeClassName}`}>
        {title}
      </NavLink>
    </li>
  )
}
