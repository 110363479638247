import { fillRateBoosterRoutes } from './fillRateBoosterRoutes'
import { oAuthRoutes } from './oAuthRoutes'
import { ossRoutes } from './ossRoutes'
import { priceGeniusRoutes } from './priceGeniusRoutes'
import { quickWrapRoutes } from './quickWrapRoutes'
import { redirectRoutes } from './redirectRoutes'
import { reviveRoutes } from './reviveRoutes'
import { viewabilityBoosterRoutes } from './viewabilityRoutes'

export const routesBuilder = {
  ...fillRateBoosterRoutes,
  ...oAuthRoutes,
  ...ossRoutes,
  ...priceGeniusRoutes,
  ...quickWrapRoutes,
  ...redirectRoutes,
  ...reviveRoutes,
  ...viewabilityBoosterRoutes
}
