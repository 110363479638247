import { Row } from 'react-table'
import { useContext, useLayoutEffect, useRef, useState } from 'react'

import { HighlightSearchContext } from 'webapp/context/HighlightSearchContext'

export const useHighlightRows = (
  key: string,
  rows: Row<Record<string, unknown>>[],
  tableBodyRef: React.MutableRefObject<HTMLTableSectionElement | undefined>
) => {
  const { highlightSearches, unsetSearch, setSearch } = useContext(HighlightSearchContext)
  const currentSearch = highlightSearches[key]

  const [highlightedRows, setHighlightedRows] = useState<Row<Record<string, unknown>>[]>()
  const previousQuery = useRef('')
  const previousCurrent = useRef<number>()

  const query = currentSearch?.query
  const current = currentSearch?.current

  useLayoutEffect(() => {
    const tableBody = tableBodyRef.current

    if (!query || !tableBody) {
      unsetSearch(key)
      setHighlightedRows(undefined)
      return
    }

    let counter = 0

    const newHighlightedRows = Array.from(tableBody.querySelectorAll('tr'))
      .filter((tr) => !tr.classList.contains('nothing-found-row'))
      .map((tr, index) => {
        const highlight = (tr.textContent ?? '').toLowerCase().includes(query.toLowerCase())
        counter += Number(highlight)

        const currentHighlight = highlight && (currentSearch?.current ?? 0) + 1 === counter

        if (currentHighlight) {
          window.scrollTo(0, tr.offsetTop + 90)
        }

        return {
          ...rows[index],
          currentHighlight,
          highlight,
          useMemoization: previousCurrent.current !== current || previousQuery.current !== query
        }
      })

    setSearch({
      current: previousQuery.current !== query ? 0 : current,
      key,
      query,
      total: counter
    })

    setHighlightedRows(newHighlightedRows)

    previousCurrent.current = current
    previousQuery.current = query
  }, [current, key, previousCurrent, previousQuery, query, rows, tableBodyRef])

  return highlightedRows ?? rows
}
