import { useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import { UserContext } from 'webapp/context/UserContext'

export const initGA = (): void => {
  process.env.GA_TAG &&
    ReactGA.initialize(process.env.GA_TAG, {
      debug: false
    })
}

const gaPageView = (page: string, user: User | null) => {
  user && ReactGA.set({ userId: user.id })
  ReactGA.pageview(page)
}

export const useGoogleAnalytics = (pathname: string): void => {
  const user = useContext(UserContext).user

  useEffect(() => {
    gaPageView(pathname, user)
  }, [pathname, user])
}
