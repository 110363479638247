import { defaultTableOrder } from 'webapp/constants/defaultTableOrder'
import { getCurrentProduct } from 'utilities/products/current'

export type Filters = {
  advancedFilter?: AdvancedFilter[]
  page?: string
  search?: string
  order?: string[]
  includeInactive?: boolean
  select?: Record<string, string>
}

interface Callback {
  (): void
  collectionName?: string
}

class FilterStore {
  private subscribers: Callback[] = []

  constructor() {
    this.setDefaultOrder()
  }

  subscribe(collectionName: string, callback: Callback) {
    callback.collectionName = collectionName
    this.subscribers.push(callback)
  }

  unsubscribe(callback: Callback) {
    this.subscribers = this.subscribers.filter((subscriber) => subscriber !== callback)
  }

  setFilter<T extends keyof Filters>(collectionName: string, filterType: T, value: Filters[T]): void {
    const currentFilters = this.getFilters(collectionName)
    currentFilters[filterType] = value
    localStorage.setItem(this.getLocalStorageKey(collectionName), JSON.stringify(currentFilters))

    this.subscribers.forEach((subscriber) => {
      if (subscriber.collectionName === collectionName) subscriber()
    })
  }

  getFilters(collectionName: string): Filters {
    const json = localStorage.getItem(this.getLocalStorageKey(collectionName))
    return json ? JSON.parse(json) : {}
  }

  forceRefresh() {
    this.subscribers.forEach((subscriber) => subscriber())
  }

  resetFilter(collectionName: string) {
    localStorage.removeItem(this.getLocalStorageKey(collectionName))
    this.setDefaultOrder()
  }

  private getLocalStorageKey(collectionName: string) {
    return `${getCurrentProduct()}.${collectionName}StoredFilters`
  }

  private setDefaultOrder() {
    Object.entries(defaultTableOrder).forEach(([collectionName, defaultOrder]) => {
      const { order } = this.getFilters(collectionName)
      if (order) return

      this.setFilter(collectionName, 'order', defaultOrder)
    })
  }
}

export const filterStore = new FilterStore()
