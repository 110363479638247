import React, { useContext, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { Error } from 'utilities/errorUtils'
import {
  FormikCheckboxContainer as FormikCheckbox,
  FormikInputContainer as FormikInput
} from 'containers/FormikContainers'
import jwtToken from 'utilities/jwtToken'
import { LoginFormFactory } from './formSchema'
import LoginMutation from 'gql/mutations/auth/Login.gql'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { UserContext } from 'webapp/context/UserContext'

export const LoginForm: React.FC = () => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('auth')
  const userContext = useContext(UserContext)

  const [hasErrors, setHasErrors] = useState(false)

  const [Login, { loading: isSubmitting }] = useMutation(LoginMutation, {
    onCompleted: ({ login: { credentials, errors } }) => {
      if (errors.length === 0) {
        jwtToken.setToken(credentials.accessToken)
        userContext.reloadUser()
        createNotifier(t('login.successMessage'), NotificationType.SUCCESS)
      } else {
        setHasErrors(true)
        createNotifier(errors.map((err: Error) => err.message).join(', '), NotificationType.ERROR)
      }
    }
  })

  const onSubmitHandler = (values: LoginData) => {
    Login({ variables: { input: values } })
  }

  return (
    <div className='login-form'>
      <Formik initialValues={LoginFormFactory()} onSubmit={onSubmitHandler}>
        <Form>
          <Field
            id='email'
            name='email'
            type='email'
            label={t('login.form.email.label')}
            placeholder={t('login.form.email.placeholder')}
            component={FormikInput}
            hasErrors={hasErrors}
          />
          <Field
            id='password'
            name='password'
            type='password'
            label={t('login.form.password.label')}
            placeholder={t('login.form.password.placeholder')}
            component={FormikInput}
            hasErrors={hasErrors}
          />

          <div className='login-form__link'>
            <Link to='/password/recovery'>{t('login.forgotPassword')}</Link>
          </div>

          <Field
            id='remember-me'
            name='rememberMe'
            label={t('login.form.rememberMe.label')}
            component={FormikCheckbox}
          />

          <Button disabled={isSubmitting} type={ButtonType.Primary} theme={ButtonTheme.Blue} buttonType='submit'>
            {t('login.button')}
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
