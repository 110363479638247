import { Error } from 'utilities/errorUtils'

export type AdUnitAddEditProps = {
  formType: FormType
}

export type DomainsData = {
  domains: {
    nodes: Domain[]
  }
}

export type AdUnitData = {
  ossAdUnit: Pick<
    OssAdUnit,
    | 'adUnitName'
    | 'adUnitPath'
    | 'domain'
    | 'elementId'
    | 'id'
    | 'outOfPage'
    | 'outOfPageType'
    | 'dedicatedForUnfilledRecovery'
  >
}

export type AdUnitVars = {
  adUnitId: AdUnit['id']
}

export type UpsertOssAdUnitData = {
  upsertOssAdUnit: {
    ossAdUnit: Pick<
      OssAdUnit,
      'activeAdUnitId' | 'activeDomainVersionId' | 'adUnitName' | 'id' | 'dedicatedForUnfilledRecovery'
    >
    errors: Error[]
  }
}

export type UpsertOssAdUnitVars = {
  adUnitName?: string
  adUnitPath?: string
  domainId: Domain['id']
  elementId?: string
  ossAdUnitId?: string
  ossPageIds?: OssPage['id'][]
  outOfPage?: boolean
  outOfPageType?: string
  dedicatedForUnfilledRecovery?: boolean
}

export const upsertOssAdUnitVarsDefault: UpsertOssAdUnitVars = {
  domainId: '',
  outOfPageType: 'standard',
  ossPageIds: []
}
