import React, { FC } from 'react'
import classNames from 'classnames'
import SelectComponent, { ActionMeta, PropsValue } from 'react-select'

import { InputContainer } from 'components/Form/InputContainer'

export const Select: FC<SelectProps> = ({
  className,
  closeMenuOnSelect,
  components,
  containerClassName,
  defaultValue,
  disabled,
  errors,
  filterOption,
  hideSelectedOptions,
  id,
  isMulti,
  isSearchable,
  isClearable,
  label,
  labelStyle,
  menuIsOpen,
  name,
  onChangeHandler,
  options,
  placeholder,
  selectedItem,
  theme,
  tooltipContent
}) => {
  const hasError = Boolean(errors && errors.length > 0)

  const classString = classNames('select__container', className, {
    'select--error': hasError,
    [`select--theme--${theme}`]: theme
  })

  const containerClassString = classNames('input__container', containerClassName)

  const onChange = (items: PropsValue<SelectOption>, _actionMeta: ActionMeta<SelectOption>) => {
    if (disabled) return

    const itemslist = items || []
    onChangeHandler && onChangeHandler(itemslist as SelectOption)
  }

  return (
    <InputContainer
      inputId={name}
      label={label}
      labelStyle={labelStyle}
      className={containerClassString}
      disabled={disabled}
      tooltipContent={tooltipContent}
      errors={errors}
    >
      <SelectComponent
        id={id}
        className={classString}
        classNamePrefix='select'
        components={components}
        hideSelectedOptions={hideSelectedOptions}
        name={name}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        closeMenuOnSelect={closeMenuOnSelect}
        filterOption={filterOption}
        isDisabled={disabled}
        value={selectedItem}
        isMulti={isMulti}
        menuIsOpen={menuIsOpen}
      />
    </InputContainer>
  )
}

Select.defaultProps = {
  defaultValue: null,
  className: '',
  isSearchable: true,
  closeMenuOnSelect: true
}
