import React from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { Sizes } from 'components/shared/Inventory/AdUnits/Form/Sections/MediaTypes/MediaTypesRow/Sizes'

interface Props {
  disabled?: boolean
  errors: Errors
  index: number
}

export const WorkspaceDeviceRow: React.FC<Props> = ({ disabled, index, errors }) => {
  const { t } = useTranslation('setup')

  const isFirstRow = index === 0

  return (
    <Grid container item xs={11} spacing={4}>
      <Grid item xs={2}>
        <Field
          id={`device-name-${index}`}
          name={`sizeMapping.${index}.name`}
          disabled={disabled}
          type='text'
          placeholder={t('sizeMapping.form.deviceName.placeholder')}
          label={isFirstRow && t('sizeMapping.form.deviceName.label')}
          tooltipContent={isFirstRow && t('sizeMapping.form.deviceName.tooltip')}
          component={FormikInput}
          errors={errors.errorsFor([index.toString(), 'name'])}
        />
      </Grid>

      <Grid item xs={2}>
        <Field
          id={`min-view-port-${index}`}
          name={`sizeMapping.${index}.minViewPort`}
          disabled={disabled}
          type='number'
          step={1}
          placeholder={t('sizeMapping.form.minimumWidth.placeholder')}
          label={isFirstRow && t('sizeMapping.form.minimumWidth.label')}
          tooltipContent={isFirstRow && t('sizeMapping.form.minimumWidth.tooltip')}
          component={FormikInput}
          errors={errors.errorsFor([index.toString(), 'minViewPort'])}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          component={Sizes}
          disabled={disabled}
          errors={errors.errorsFor([index.toString(), 'bannerSizes'])}
          id={`banner-sizes-${index}`}
          label={isFirstRow && t('inventory:adUnits.form.inputs.mediaTypes.banner.sizes.label')}
          name={`sizeMapping.${index}.bannerSizes`}
          tooltipContent={isFirstRow && t('inventory:adUnits.form.inputs.mediaTypes.banner.sizes.tooltip')}
        />
      </Grid>

      <Grid item xs={2}>
        <Field
          component={Sizes}
          disabled={disabled}
          errors={errors.errorsFor([index.toString(), 'playerSize'])}
          id={`player-size-${index}`}
          label={isFirstRow && t('inventory:adUnits.form.inputs.mediaTypes.video.playerSize.label')}
          name={`sizeMapping.${index}.playerSize`}
          single
          tooltipContent={isFirstRow && t('inventory:adUnits.form.inputs.mediaTypes.video.playerSize.tooltip')}
        />
      </Grid>
    </Grid>
  )
}
