export class ProfileSerializer {
  profile: Record<string, any>

  constructor(profile: Profile) {
    this.profile = profile
  }

  updateParams(): Partial<Profile> {
    return this._prepareBody()
  }

  private _prepareBody() {
    const body: Partial<Profile> = {}

    this._rawParams().forEach((paramName) => (body[paramName] = this.profile[paramName]))

    return body
  }

  private _rawParams(): Array<keyof Profile> {
    return ['fullName', 'avatar']
  }
}
