import { DocumentNode, gql } from '@apollo/client'

export const MUTATION_STATE = (payloadType: string): DocumentNode => gql`
fragment MutationState${payloadType}Fields on ${payloadType} {
    errors {
        message
        path
    }
}
`

export const MUTATION_STATE_SIMPLE = (payloadType: string): DocumentNode => gql`
fragment MutationState${payloadType}Fields on ${payloadType} {
    errors
}
`

export const TABLE_INPUTS_N_RESOLVERS: [string, string] = [
  '$filter: [Filter!], $before: String, $last: Int, $offset: String, $order: Order, $pageSize: Int',
  'filter: $filter, order: $order, after: $offset, before: $before, first: $pageSize, last: $last'
]
