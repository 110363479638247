import React, { useContext, useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { AbilityContext } from 'webapp/context/AbilityContext'
import { InfoHover } from 'components/oss/Performance/DataStudioLinks/InfoHover'
import { LoadingContainer } from 'components/LoadingContainer'
import { UserContext } from 'webapp/context/UserContext'

import WorkspaceQuery from 'gql/queries/workspaces/Workspace.gql'

export const DataStudioContainer: React.FC = () => {
  const { t } = useTranslation('dataStudio')

  const ability = useContext(AbilityContext)
  const canAccessPreview = ability.can(AbilityAction.ACCESS, AbilitySubject.FINANCE_DATA_STUDIO_LINKS_PREVIEW)

  const { user } = useContext(UserContext)
  const { workspaceId } = useParams<{ workspaceId: string }>()

  const [workspace, setWorkspace] = useState<Workspace>()

  const dataStudioUrl = workspaceId ? workspace?.dataStudioUrl : user?.workspace?.dataStudioUrl

  const [queryWorkspace] = useLazyQuery(WorkspaceQuery, {
    fetchPolicy: 'network-only',
    variables: { workspaceId },
    onCompleted: ({ workspace }) => {
      setWorkspace(workspace)
    }
  })

  useEffect(() => {
    if (workspaceId) {
      queryWorkspace()
    }
  }, [])

  return (
    <LoadingContainer fullHeight loading={Boolean(workspaceId) && !workspace}>
      <InfoHover />
      <div className='data-studio'>
        {workspace && canAccessPreview && <div className='data-studio__info'>{workspace.name}</div>}

        {dataStudioUrl ? (
          <iframe className='data-studio__iframe' src={dataStudioUrl} />
        ) : (
          <div className='data-studio__empty-notice'>
            <h1>{t('viewer.empty.title')}</h1>
            <h2>{t('viewer.empty.subTitle')}</h2>
          </div>
        )}
      </div>
    </LoadingContainer>
  )
}
