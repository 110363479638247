import React from 'react'

import { TagsInputContainerProps } from './types'

import { InputContainer } from 'components/Form/InputContainer'
import { TagsInput } from './TagsInput'

export const TagsInputContainer = ({
  disabled,
  errors,
  id,
  label,
  onChange,
  placeholder,
  tooltipContent,
  value
}: TagsInputContainerProps): JSX.Element => (
  <InputContainer
    className='input__container'
    disabled={disabled}
    errors={errors}
    inputId={id}
    label={label}
    tooltipContent={tooltipContent}
  >
    <TagsInput disabled={disabled} id={id} onChange={onChange} placeholder={placeholder} value={value} />
  </InputContainer>
)
