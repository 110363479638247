import { Product } from 'webapp/constants/Product'
import { RouteProductPrefix } from 'webapp/constants/RouteProductPrefix'

export const ossRoutes = {
  oss: {
    downloadTags: {
      root: `/${RouteProductPrefix[Product.OSS]}/download_tags`
    },

    finance: {
      dataStudioLinks: {
        edit: (workspaceId = ':workspaceId'): string =>
          `/${RouteProductPrefix[Product.OSS]}/finance/data-studio-links/${workspaceId}/edit`,
        new: `/${RouteProductPrefix[Product.OSS]}/finance/data-studio-links/new`,
        preview: (workspaceId = ':workspaceId'): string =>
          `/${RouteProductPrefix[Product.OSS]}/finance/data-studio-links/${workspaceId}/preview`,
        root: `/${RouteProductPrefix[Product.OSS]}/finance/data-studio-links`
      },
      root: `/${RouteProductPrefix[Product.OSS]}/finance/data-studio`
    },

    history: {
      root: '/history'
    },

    inventory: {
      adUnit: {
        add: (domainId?: Domain['id']): string =>
          domainId
            ? `/${RouteProductPrefix[Product.OSS]}/inventory/ad_units/new?domain=${domainId}`
            : `/${RouteProductPrefix[Product.OSS]}/inventory/ad_units/new`,
        edit: (adUnitId = ':adUnitId'): string =>
          `/${RouteProductPrefix[Product.OSS]}/inventory/ad_units/${adUnitId}/edit`,
        root: `/${RouteProductPrefix[Product.OSS]}/inventory/ad_units`
      },
      devices: {
        root: `/${RouteProductPrefix[Product.OSS]}/inventory/devices`
      },
      domains: {
        edit: (domainId = ':domainId'): string =>
          `/${RouteProductPrefix[Product.OSS]}/inventory/domains/${domainId}/edit`,
        new: `/${RouteProductPrefix[Product.OSS]}/inventory/domains/new`,
        root: `/${RouteProductPrefix[Product.OSS]}/inventory/domains`
      },
      pages: {
        add: `/${RouteProductPrefix[Product.OSS]}/inventory/pages/new`,
        edit: (pageId = ':pageId'): string => `/${RouteProductPrefix[Product.OSS]}/inventory/pages/${pageId}/edit`,
        root: `/${RouteProductPrefix[Product.OSS]}/inventory/pages`
      }
    },

    performance: {
      dataStudioLinks: {
        edit: (productConnectionId = ':productConnectionId'): string =>
          `/${RouteProductPrefix[Product.OSS]}/performance/data-studio-links/${productConnectionId}/edit`,
        new: `/${RouteProductPrefix[Product.OSS]}/performance/data-studio-links/new`,
        preview: (productConnectionId = ':productConnectionId'): string =>
          `/${RouteProductPrefix[Product.OSS]}/performance/data-studio-links/${productConnectionId}/preview`,
        root: `/${RouteProductPrefix[Product.OSS]}/performance/data-studio-links`
      },
      root: `/${RouteProductPrefix[Product.OSS]}/performance`
    },

    portal: {
      root: `/${RouteProductPrefix[Product.OSS]}/portal`
    },

    products: {
      management: `/${RouteProductPrefix[Product.OSS]}/product-management`,
      root: `/${RouteProductPrefix[Product.OSS]}/products`
    },

    profile: {
      root: `/${RouteProductPrefix[Product.OSS]}/profile`
    },

    users: {
      invite: `/${RouteProductPrefix[Product.OSS]}/users/invite`,
      publishersRoot: `/${RouteProductPrefix[Product.OSS]}/users?roleType=publisher`,
      root: `/${RouteProductPrefix[Product.OSS]}/users`,
      superusersRoot: `/${RouteProductPrefix[Product.OSS]}/users?roleType=superuser`
    },

    workspace: {
      root: `/${RouteProductPrefix[Product.OSS]}/workspace`
    },

    workspaces: {
      edit: (workspaceId = ':workspaceId'): string =>
        `/${RouteProductPrefix[Product.OSS]}/workspaces/${workspaceId}/edit`,
      new: `/${RouteProductPrefix[Product.OSS]}/workspaces/new`,
      root: `/${RouteProductPrefix[Product.OSS]}/workspaces`
    }
  }
}
