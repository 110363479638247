import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import classNames from 'classnames'

import { currencyUtils } from 'utilities/currencyUtils'

type ReportDataKeys = 'total_impressions' | 'total_revenue'

interface Props {
  label?: string
  page?: Array<Row<ReportData>>
  type?: ReportDataKeys
  currency?: string
  divider?: number
  className?: string
}

export const FooterCell: React.FC<Props> = ({ label, page, type, currency, divider = 1, className }) => {
  const { t } = useTranslation('reports')
  const value = label && t(`resultPage.inventory.table.${label}`)

  const columnValues = useMemo(() => page?.map((row) => row.original) || [], [page])

  const sumColumn = useCallback(
    (columnType: ReportDataKeys) => {
      return calculateValue(columnValues, columnType).toString()
    },
    [columnValues]
  )

  const calculateValue = (data: Array<ReportData> = [], fieldName: ReportDataKeys) =>
    data.reduce((total, next) => total + parseInt(next[fieldName]?.toString() || '0'), 0)

  const getValue = useCallback(() => {
    if (value) return value

    const result = parseInt(sumColumn(type as ReportDataKeys)) / divider
    return currencyUtils.currencyFormat(result, currency)
  }, [currency, divider, sumColumn, type, value])

  const classString = useMemo(() => classNames('table__footer--cell', className), [className])

  return <div className={classString}>{getValue()}</div>
}
