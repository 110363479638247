import React from 'react'

import { useHistory } from 'react-router-dom'
import { Column, Row } from 'react-table'

import { OssAdUnitRowData } from 'components/oss/Inventory/AdUnits/Table/OssAdUnitRowData'
import { QueryTable } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { useTableState } from 'webapp/hooks/useTableState'
import { columnsConfig } from './columnsConfig'

import { OSS_AD_UNITS } from 'gql/queries/adUnits/ossAdUnits'
import { AdUnitsTableProps } from './types'

export const AdUnitsTable = ({ perDomain, readOnly }: AdUnitsTableProps): JSX.Element => {
  const history = useHistory()
  const { pageIndex, setPageIndex } = useTableState()

  const toHide = readOnly ? ['ossPagesCount'] : []
  const queryVars = perDomain ? { domainId: perDomain } : {}

  const redirectToEdit = (row: Row<Record<string, unknown>>) =>
    void history.push(routesBuilder.oss.inventory.adUnit.edit((row.original as unknown as OssAdUnitRowData).id), {
      pageIndex: readOnly ? 0 : pageIndex
    })

  return (
    <QueryTable<OssAdUnitRowData>
      className='ad-units-table'
      collectionName={CollectionNames.OssAdUnits}
      columnsConfig={columnsConfig() as Column[]}
      dataQuery={OSS_AD_UNITS}
      hiddenColumns={toHide}
      mapper={(node: OssAdUnit) => new OssAdUnitRowData(node)}
      onRowClick={redirectToEdit}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      variables={queryVars}
    />
  )
}
