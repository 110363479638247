import React, { useCallback, useEffect, useState } from 'react'

import { Slider } from '@material-ui/core'
import { debounce } from 'lodash'

import { Input } from 'components/Form/Input'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { DEFAULT_TRAFFIC, UPDATE_TIMEOUT } from './constants'
import { SliderOnChange, TrafficSliderProps } from './types'
import { usePriceGeniusApi } from './usePriceGeniusApi'
import { useSliderDynamicStyles } from './useSliderDynamicStyles'
import { useSliderMarks } from './useSliderMarks'
import { shouldSnapToDefaultValue } from './utils'

import './styles.scss'

export const TrafficSlider = ({ priceGeniusDomainId, trafficPercent }: TrafficSliderProps): JSX.Element => {
  const [tempValue, setTempValue] = useState<number>(trafficPercent)
  const [commitedValue, setCommitedValue] = useState<number>(tempValue)
  const { updateTraffic } = usePriceGeniusApi()

  useEffect(() => {
    if (tempValue !== trafficPercent && tempValue === commitedValue) {
      updateTraffic(
        nestGqlInput({
          pgDomainConfigurationId: priceGeniusDomainId,
          trafficPercent: commitedValue / 100
        })
      )
    }
  }, [tempValue, commitedValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (newValue: string) => {
    const newValueNum = Number(newValue)

    setTempValue(newValueNum)
    setCommitedValueDebounced(newValueNum)
  }

  const handleSliderChange: SliderOnChange = (_, newValue) => {
    const newValueNum = newValue as number
    setTempValue(shouldSnapToDefaultValue(newValueNum) ? DEFAULT_TRAFFIC : newValueNum)
  }

  const handleSliderCommitedChange: SliderOnChange = (_, newValue) => {
    const newValueNum = newValue as number
    setCommitedValue(shouldSnapToDefaultValue(newValueNum) ? DEFAULT_TRAFFIC : newValueNum)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setCommitedValueDebounced = useCallback(
    debounce((newValue: number) => void setCommitedValue(newValue), UPDATE_TIMEOUT),
    []
  )

  return (
    <div className='TrafficSlider'>
      <Input
        className='TrafficSlider__input'
        max={100}
        min={0}
        name='traffic-slider'
        onChangeHandler={handleInputChange}
        type='number'
        value={tempValue}
      />

      <div className='TrafficSlider__slider-container'>
        <Slider
          classes={useSliderDynamicStyles(tempValue)()}
          className='TrafficSlider__slider'
          marks={useSliderMarks()}
          max={100}
          min={0}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderCommitedChange}
          step={1}
          value={tempValue}
          valueLabelDisplay='auto'
        />
      </div>
    </div>
  )
}
