import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ProductActivationModal } from 'components/oss/Products/ProductActivationModal'
import { getProductName } from 'utilities/products/utils'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { ProductPlan } from 'webapp/constants/ProductPlan'
import { Can } from 'webapp/context/AbilityContext'

interface Props {
  productConnection: ProductConnection
}

const secondsInDay = 24 * 60 * 60

export const Trial: React.FC<Props> = ({ productConnection }) => {
  const { t } = useTranslation('products')

  const daysLeft = Math.ceil(productConnection.trialEndsIn / secondsInDay)
  const product = getProductName(productConnection.product)
  const text = productConnection.trialEndsIn
    ? t('statusBar.trial', { count: daysLeft, product })
    : t('statusBar.trialEnded')

  const [isModalVisible, setIsModalVisible] = useState(false)
  const onClickHandler = () => {
    setIsModalVisible(true)
  }

  return (
    <div className='product-status-bar product-status-bar--trial'>
      {text}
      <Can I={AbilityAction.EDIT} a={AbilitySubject.PRODUCT}>
        <Button theme={ButtonTheme.Red} type={ButtonType.Primary} onClickHandler={onClickHandler}>
          {t('buttons.unlimitedAccess')}
        </Button>

        <ProductActivationModal
          plan={ProductPlan.Unlimited}
          productConnection={productConnection}
          setVisible={setIsModalVisible}
          visible={isModalVisible}
        />
      </Can>
    </div>
  )
}
