import React from 'react'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Box, BoxHeader } from 'components/Box'
import { Can } from 'webapp/context/AbilityContext'
import { ConnectedNetworksContainer } from 'containers/oss/Workspaces/ConnectedNetworksContainer'
import { ConnectedNetworksTableContextProvider } from 'components/oss/Workspaces/ConnectedNetworksTable'
import { Tooltip } from 'components/Form'
import { WorkspaceGamConnector } from 'components/oss/Workspaces/WorkspaceGamConnector'

export const GamConnectionContainer: React.FC = () => {
  const { t } = useTranslation('workspaces')

  return (
    <Box className='gam-connection-container'>
      <BoxHeader title={t('gamConnector.title')} subtitle={t('gamConnector.subtitle')}>
        <Tooltip className='ml-4' title={t('gamConnector.tooltip')} />
      </BoxHeader>

      <Can I={AbilityAction.ACCESS} a={AbilitySubject.GAM_CONNECTOR}>
        <ConnectedNetworksTableContextProvider>
          <WorkspaceGamConnector />
          <ConnectedNetworksContainer />
        </ConnectedNetworksTableContextProvider>
      </Can>
    </Box>
  )
}
