import { Product } from 'webapp/constants/Product'
import { RouteProductPrefix } from 'webapp/constants/RouteProductPrefix'

export const fillRateBoosterRoutes = {
  fill_rate_booster: {
    performance: {
      dataStudio: `/${RouteProductPrefix[Product.FillRateBooster]}/performance/data-studio`,
      root: `/${RouteProductPrefix[Product.FillRateBooster]}/performance`
    }
  }
}
