import React from 'react'
import { useFormikContext } from 'formik'

interface Props {
  index: number
  sizeMapping: boolean
  type: 'bannerEnabled' | 'videoEnabled' | 'nativeEnabled'
}
export const FieldsContainer: React.FC<Props> = ({ children, index, sizeMapping, type }) => {
  const { values } = useFormikContext() as { values: AdUnitForm }

  const visible = () => {
    return sizeMapping
      ? values.sizeMapping && values.sizeMapping[index][type]
      : values.mediaTypes && values.mediaTypes[type]
  }

  return <>{visible() && children}</>
}
