import React, { useContext, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Row } from 'react-table'

import { ConnectedNetworksTableContext } from './ConnectedNetworksTableContext'
import { GamConnectionStatus } from 'webapp/constants/GamConnectionStatus'
import { Tooltip } from 'components/Form'

interface Props {
  row: Row<ConnectedNetwork>
  type: 'bannerStatus' | 'nativeStatus'
}

const isStatusPending = (status: string) =>
  status === GamConnectionStatus.Queued || status === GamConnectionStatus.InProgress
const isStatusFailed = (status: string) => status === GamConnectionStatus.Failed

export const StatusCell: React.FC<Props> = ({ row, type }) => {
  const { id, [type]: status, bannerFailureReason, nativeFailureReason } = row.original
  const { connectedNetworkQuery } = useContext(ConnectedNetworksTableContext)

  const failureMessage = (type: string) => {
    const failureReason = type === 'bannerStatus' ? bannerFailureReason : nativeFailureReason
    return failureReason.split('@')[0].substring(1)
  }

  useEffect(() => {
    if (isStatusPending(status)) {
      connectedNetworkQuery({ variables: { gamConnectionId: id } })
    }
  }, [connectedNetworkQuery, id, status])

  return (
    <>
      <div className='d-flex align-items-center'>
        {isStatusPending(status) && <CircularProgress size={10} />}
        <div className='d-flex'>
          <span className='banner-status-name mr-2'>{status}</span>
          {isStatusFailed(status) && <Tooltip title={failureMessage(type)} />}
        </div>
      </div>
    </>
  )
}
