export class PriceFloorSerializer {
  private domain: Domain
  private priceFloor: PriceFloor

  constructor(priceFloor: PriceFloor, domain: Domain) {
    this.domain = domain
    this.priceFloor = priceFloor
  }

  public upsertParams(): PriceFloor {
    return {
      code: this.priceFloor.code,
      domainVersionId: this.domain.versionId,
      enabled: this.priceFloor.enabled
    }
  }
}
