import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/Form'

interface Props {
  containerClassName?: string
  disabled?: boolean
  element: string
  errors?: Array<string>
  hidden?: boolean
  id: string
  initialValue?: string
  name: string
  onChangeHandler: (name: string, value: string) => void
  translation?: string
}

const stringifyIfNotString = (obj: unknown) => {
  return typeof obj === 'string' ? obj : JSON.stringify(obj)
}

export const ParamInput: React.FC<Props> = ({
  containerClassName,
  disabled,
  element,
  errors,
  hidden,
  id,
  initialValue,
  name,
  onChangeHandler,
  translation
}) => {
  const { t } = useTranslation(translation)
  const [value, setValue] = useState(stringifyIfNotString(initialValue) ?? '')

  const onChange = (newValue: string) => {
    setValue(newValue)
    onChangeHandler(name, newValue)
  }

  return (
    <>
      {!hidden && (
        <Input
          id={id}
          name={id}
          type='text'
          disabled={disabled}
          placeholder={name}
          value={value}
          onChangeHandler={onChange}
          label={name}
          tooltipContent={t(`${element}.tooltips.${name}`)}
          errors={errors}
          containerClassName={containerClassName}
        />
      )}
    </>
  )
}
