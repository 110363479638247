import React from 'react'

import { Collapse, Grid } from '@material-ui/core'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { Section } from 'components/Form'
import { TCFWaitType } from 'constants/TCFWaitType'
import { FormikInputContainer, FormikRadioContainer, FormikToggleContainer } from 'containers/FormikContainers'
import { FieldProps, TCFSectionPlacement, TCFSectionProps } from './types'

const WaitForTCFField = ({ accessor, t }: FieldProps): JSX.Element => (
  <Field
    component={FormikToggleContainer}
    id='wait-for-tcf'
    label={t('domains.consentManagement.form.waitForTcf.label')}
    name={`${accessor}waitForTcf`}
  />
)

const TCFTypeField = ({ accessor, t }: FieldProps): JSX.Element => (
  <div className='oss-consent-management-form__tcf-type'>
    <Field
      component={FormikRadioContainer}
      id='tcf-type-timeout'
      label={t('domains.consentManagement.form.timeout.label')}
      name={`${accessor}type`}
      tooltipContent={t('domains.consentManagement.form.timeout.tooltip')}
      type='radio'
      value={TCFWaitType.Timeout}
    />

    <Field
      component={FormikRadioContainer}
      id='tcf-type-infinity'
      label={t('domains.consentManagement.form.infinity.label')}
      name={`${accessor}type`}
      tooltipContent={t('domains.consentManagement.form.infinity.tooltip')}
      type='radio'
      value={TCFWaitType.Infinity}
    />
  </div>
)

const TCFTimeoutField = ({ accessor, errors, t }: Required<FieldProps>): JSX.Element => (
  <Field
    component={FormikInputContainer}
    errors={errors.errorsFor([accessor, 'tcfTimeout'])}
    id='tcf-timeout'
    label={t('domains.consentManagement.form.tcfTimeout.label')}
    name={`${accessor}tcfTimeout`}
    tooltipContent={t('domains.consentManagement.form.tcfTimeout.tooltip')}
    type='number'
  />
)

export const TCFSection = ({ errors, placement, values }: TCFSectionProps): JSX.Element => {
  const { t } = useTranslation('inventory')

  const ACCESSOR = placement === TCFSectionPlacement.ADD_NEW_DOMAIN ? 'ossConsentManagementAttributes.' : ''

  return (
    <Section fullWidth>
      <Grid container spacing={4}>
        <Grid item lg={4} xs={6}>
          <WaitForTCFField accessor={ACCESSOR} t={t} />

          <Collapse in={values.waitForTcf}>
            <TCFTypeField accessor={ACCESSOR} t={t} />

            <Collapse in={values.type === TCFWaitType.Timeout}>
              <TCFTimeoutField errors={errors} accessor={ACCESSOR} t={t} />
            </Collapse>
          </Collapse>
        </Grid>
      </Grid>
    </Section>
  )
}
