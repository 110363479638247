import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { AbilityContext } from 'webapp/context/AbilityContext'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { InfoHover } from 'components/oss/Performance/DataStudioLinks/InfoHover'
import { LoadingContainer } from 'components/LoadingContainer'
import { getProductName } from 'utilities/products/utils'
import { useTranslation } from 'react-i18next'
import { useCurrentProduct } from 'utilities/products/current'

import ProductConnectionQuery from 'gql/queries/productConnections/ProductConnection.gql'
import ProductConnectionsQuery from 'gql/queries/productConnections/ProductConnections.gql'

export const DataStudioPage: React.FC = () => {
  const { t } = useTranslation('dataStudio')

  const ability = useContext(AbilityContext)
  const canAccessPreview = ability.can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_LINKS_PREVIEW)

  const productConnection = useProductConnection(canAccessPreview)

  return (
    <LoadingContainer fullHeight loading={!productConnection}>
      <InfoHover />
      <div className='data-studio'>
        {productConnection?.dataStudioUrl ? (
          <>
            {canAccessPreview && (
              <div className='data-studio__info'>
                {productConnection.workspace.name}: {getProductName(productConnection.product)}
              </div>
            )}
            <iframe className='data-studio__iframe' src={productConnection.dataStudioUrl} />
          </>
        ) : (
          <div className='data-studio__empty-notice'>
            <h1>{t('viewer.empty.title')}</h1>
            <h2>{t('viewer.empty.subTitle')}</h2>
          </div>
        )}
      </div>
    </LoadingContainer>
  )
}

const useProductConnection = (canAccessPreview: boolean) => {
  const [productConnection, setProductConnection] = useState<ProductConnection>()

  // Regular user query
  const product = useCurrentProduct()
  const filter = { by: 'product', operator: 'is', values: product }
  useQuery<{ productConnections: Connection<ProductConnection> }>(ProductConnectionsQuery, {
    fetchPolicy: 'network-only',
    variables: { filter },
    skip: canAccessPreview,
    onCompleted: ({ productConnections }) => {
      setProductConnection(productConnections.nodes[0])
    }
  })

  // Superuser preview query
  const { productConnectionId } = useParams<{ productConnectionId: string }>()
  useQuery<{ productConnection: ProductConnection }>(ProductConnectionQuery, {
    fetchPolicy: 'network-only',
    skip: !canAccessPreview,
    variables: { productConnectionId },
    onCompleted: ({ productConnection }) => {
      setProductConnection(productConnection)
    }
  })

  return productConnection
}
