import { useContext } from 'react'
import { useLazyQuery } from '@apollo/client'

import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'

import ConnectReport from 'gql/queries/gam/ConnectReport.gql'

type GamHookReturnType = [() => void]

export const useGamConnect = (oauthCallbackUrl: string): GamHookReturnType => {
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [connectGAM] = useLazyQuery(ConnectReport, {
    onCompleted: ({ googleAdManagers }) => (window.location = googleAdManagers.redirectUrl),
    onError: (error) => createNotifier(error.message, NotificationType.ERROR)
  })

  const connect = () => connectGAM({ variables: { oauthCallbackUrl } })

  return [connect]
}
