import React, { useContext, useMemo, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { ButtonTheme, ButtonType } from 'components/Button'
import { ConnectedNetworksTableContext } from 'components/oss/Workspaces/ConnectedNetworksTable'
import { ConnectionField } from 'components/oss/Workspaces/WorkspaceGamConnector/ConnectionField'
import { currencyUtils } from 'utilities/currencyUtils'
import { Errors, ErrorUtils } from 'utilities/errorUtils'
import {
  FormikCheckboxContainer,
  FormikInputContainer,
  FormikSelectContainer as FormikSelect,
  FormikToggleContainer
} from 'containers/FormikContainers'
import { GamAutomationModal } from './GamAutomationModal'
import { GAMConnectorFormType } from './formSchema'
import { LoadingButton } from 'components/Button/LoadingButton'
import { LoadingContainer } from 'components/LoadingContainer'
import { Section } from 'components/Form'
import { useFormState, useFormTitle } from 'components/oss/Workspaces/WorkspaceGamConnector/utils'

import IconExitWhite from 'images/icon-exit-white.svg'

interface Props {
  availableNetworks?: AvailableNetwork[]
  errors: Errors
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
  onModalSubmit: () => void
  onRunClicked: (formValues: GAMConnectorFormType) => void
  setErrors: (errors: ErrorUtils) => void
}

export const GamConnectorForm: React.FC<Props> = ({
  availableNetworks,
  errors,
  isModalVisible,
  setIsModalVisible,
  onModalSubmit,
  onRunClicked
}) => {
  const { t } = useTranslation('workspaces')

  const [isPopupVisible, setIsPopupVisible] = useState(true)

  const { data: connections, isRunInProgress } = useContext(ConnectedNetworksTableContext)

  const { gamConnectionForm } = useFormState(connections)
  const formTitle = useFormTitle(gamConnectionForm)

  const networkOptions = useMemo(
    () =>
      availableNetworks?.map(({ displayName, networkCode }) => ({
        label: displayName,
        value: networkCode
      })),
    [availableNetworks]
  )

  const isLoading = !gamConnectionForm || !connections || !availableNetworks

  return (
    <div className='WorkspaceGamConnector__container'>
      <LoadingContainer loading={isLoading}>
        <div className='WorkspaceGamConnector__form-container'>
          {!isLoading && (
            <>
              <h4 className='WorkspaceGamConnector__form-title'>{formTitle}</h4>
              <Formik initialValues={gamConnectionForm} onSubmit={onRunClicked} enableReinitialize>
                {({ values: { currency, creativeTemplateEnabled, native, network } }) => {
                  const disableSubmit = !network.value || !currency.value

                  return (
                    <Form>
                      <ConnectionField currency={currency} network={network} />

                      <Section className='WorkspaceGamConnector__form'>
                        <Field
                          id='network'
                          name='network'
                          errors={errors.errorsFor('networkCode')}
                          placeholder={t('gamConnector.networkForm.network.label')}
                          options={networkOptions}
                          label={t('gamConnector.networkForm.network.label')}
                          tooltipContent={t('gamConnector.networkForm.network.tooltip')}
                          component={FormikSelect}
                        />

                        <Field
                          id='currency'
                          name='currency'
                          errors={errors.errorsFor('currency')}
                          placeholder={t('gamConnector.networkForm.currency.placeholder')}
                          options={currencyUtils.getList()}
                          label={t('gamConnector.networkForm.currency.label')}
                          tooltipContent={t('gamConnector.networkForm.currency.tooltip')}
                          component={FormikSelect}
                        />

                        <Grid container spacing={3} justifyContent='space-between'>
                          <Grid item>
                            <Field
                              id='banner-video'
                              name='outstreamVideoEnabled'
                              label={t('gamConnector.networkForm.outstreamVideo.label')}
                              labelClassName='WorkspaceGamConnector__checkbox-label'
                              component={FormikCheckboxContainer}
                              disabled
                            />
                          </Grid>

                          <Grid item>
                            <Field
                              id='template-enabled'
                              name='creativeTemplateEnabled'
                              label={t('gamConnector.networkForm.creativeTemplateEnabled.label')}
                              labelClassName='WorkspaceGamConnector__template-enabled-label'
                              component={FormikToggleContainer}
                            />
                          </Grid>
                        </Grid>

                        {creativeTemplateEnabled && (
                          <Field
                            id='creativeTemplateId'
                            name='creativeTemplateId'
                            errors={errors.errorsFor('creativeTemplateId')}
                            label={t('gamConnector.networkForm.creativeTemplate.label')}
                            tooltipContent={t('gamConnector.networkForm.creativeTemplate.tooltip')}
                            component={FormikInputContainer}
                          />
                        )}

                        <Field
                          id='native'
                          name='native'
                          label={t('gamConnector.networkForm.nativeEnabled.label')}
                          labelClassName='WorkspaceGamConnector__checkbox-label'
                          component={FormikCheckboxContainer}
                        />

                        {native && (
                          <Field
                            id='nativeCodeTemplateId'
                            errors={errors.errorsFor('nativeCodeTemplateId')}
                            name='nativeCodeTemplateId'
                            label={t('gamConnector.networkForm.nativeTemplateId.label')}
                            tooltipContent={t('gamConnector.networkForm.nativeTemplateId.tooltip')}
                            component={FormikInputContainer}
                          />
                        )}
                      </Section>

                      <footer>
                        <LoadingButton
                          buttonType='submit'
                          className='mt-4'
                          disabled={disableSubmit || isRunInProgress}
                          theme={ButtonTheme.Orange}
                          type={ButtonType.Primary}
                        >
                          {t('gamConnector.networkForm.button')}
                        </LoadingButton>
                      </footer>
                    </Form>
                  )
                }}
              </Formik>
            </>
          )}

          <GamAutomationModal
            visible={isModalVisible}
            setVisible={setIsModalVisible}
            onConfirmHandler={onModalSubmit}
          />
        </div>

        {isPopupVisible && (
          <div className='WorkspaceGamConnector__popup-container'>
            <div className='WorkspaceGamConnector__popup'>
              {t('gamConnector.networkForm.popup')}
              <button className='WorkspaceGamConnector__popup-close' onClick={() => setIsPopupVisible(false)}>
                <img src={IconExitWhite} alt='' />
              </button>
            </div>
          </div>
        )}
      </LoadingContainer>
    </div>
  )
}
