import React from 'react'

import { Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import pick from 'lodash/pick'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Label, Section, StatusForm } from 'components/Form'
import { MatchType } from 'constants/MatchType'
import { FormikRadioContainer } from 'containers/FormikContainers'
import { FormikTagsContainer } from 'containers/FormikContainers/FormikTagsContainer/FormikTagsContainer'
import { TargetingFormProps } from './types'

export const TargetingForm = ({ data, errors, loading, onSubmit }: TargetingFormProps): JSX.Element => {
  const { t } = useTranslation('revive')

  const TypeOfActionFields = (): JSX.Element => (
    <>
      <Label>{t('configuration.edit.targetingForm.matchType.title')}</Label>

      <Field
        component={FormikRadioContainer}
        id='match-type-include'
        label={t('configuration.columns.targeting.type.include.label')}
        name={'matchType'}
        tooltipContent={t('configuration.columns.targeting.type.include.tooltip')}
        type='radio'
        value={MatchType.Include}
      />

      <Field
        component={FormikRadioContainer}
        id='match-type-exclude'
        label={t('configuration.columns.targeting.type.exclude.label')}
        name={'matchType'}
        tooltipContent={t('configuration.columns.targeting.type.exclude.tooltip')}
        type='radio'
        value={MatchType.Exclude}
      />

      <div className='targeting-form__hint'>{t('configuration.edit.targetingForm.matchType.hint')}</div>
    </>
  )

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...pick(data, ['advertisers', 'lineItemIds', 'matchType', 'orderIds']),
        reviveDomainConfigurationId: data.id
      }}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <div className='targeting-form'>
          <StatusForm dirty={dirty} name='revive-configuration-edit'>
            <Section className='common-form' fullWidth>
              <Grid container spacing={4}>
                <Grid item lg={4} xs={4}>
                  <TypeOfActionFields />

                  <Field
                    component={FormikTagsContainer}
                    errors={errors.errorsFor('advertisers')}
                    id='advertisers'
                    label={t('configuration.edit.targetingForm.advertiserIds.label')}
                    name='advertisers'
                    placeholder={t('configuration.edit.targetingForm.placeholder')}
                    tooltipContent={t('configuration.edit.targetingForm.advertiserIds.tooltip')}
                  />

                  <Field
                    component={FormikTagsContainer}
                    errors={errors.errorsFor('orderIds')}
                    id='orderIds'
                    label={t('configuration.edit.targetingForm.orderIds.label')}
                    name='orderIds'
                    placeholder={t('configuration.edit.targetingForm.placeholder')}
                    tooltipContent={t('configuration.edit.targetingForm.orderIds.tooltip')}
                  />

                  <Field
                    component={FormikTagsContainer}
                    errors={errors.errorsFor('lineItemIds')}
                    id='lineItemIds'
                    label={t('configuration.edit.targetingForm.lineItemIds.label')}
                    name='lineItemIds'
                    placeholder={t('configuration.edit.targetingForm.placeholder')}
                    tooltipContent={t('configuration.edit.targetingForm.lineItemIds.tooltip')}
                  />
                </Grid>
              </Grid>
            </Section>

            <Button buttonType='submit' disabled={!dirty || loading} theme={ButtonTheme.Red} type={ButtonType.Primary}>
              {loading ? t('common:buttons.saving') : t('common:buttons.save')}
            </Button>
          </StatusForm>
        </div>
      )}
    </Formik>
  )
}
