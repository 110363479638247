import React from 'react'

import { useHistory } from 'react-router-dom'
import { Column, Row } from 'react-table'

import { QueryTable } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { useTableState } from 'webapp/hooks/useTableState'
import { OssPageRow } from './OssPageRow'
import { useColumnsConfig } from './columnsConfig'

import OSS_PAGES_QUERY from 'gql/queries/ossPages/OssPages.gql'

const PagesTable = (): JSX.Element => {
  const history = useHistory()
  const { pageIndex, setPageIndex } = useTableState()

  const redirectToEdit = (row: Row<Record<string, unknown>>) => {
    history.push(routesBuilder.oss.inventory.pages.edit((row.original as unknown as OssPageRow).id), { pageIndex })
  }

  return (
    <QueryTable<OssPageRow>
      collectionName={CollectionNames.OssPages}
      dataQuery={OSS_PAGES_QUERY}
      className='page-table'
      columnsConfig={useColumnsConfig() as Column[]}
      mapper={(node: OssPage): OssPageRow => new OssPageRow(node)}
      onRowClick={redirectToEdit}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
    />
  )
}

export default PagesTable
