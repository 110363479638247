import React, { useContext, useState } from 'react'
import { Column, Row } from 'react-table'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { QueryTable } from 'containers/QueryTable/QueryTable'
import { UserContext } from 'webapp/context/UserContext'
import { columnsConfig } from './columnsConfig'
import { routesBuilder } from 'utilities/routesBuilder'

import BlockUrlGroupsQuery from 'gql/queries/blockUrlGroups/BlockUrlGroups.gql'
import DomainsSelectQuery from 'gql/queries/domains/DomainSelect.gql'

const SEARCHABLE_FIELDS = ['name']

export const BlockUrlGroupsTable: React.FC = () => {
  const user: User = useContext(UserContext).user as User
  const history = useHistory()
  const [domains, setDomains] = useState<Domain[]>([])

  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    const { id } = row.original as unknown as BlockUrlGroup
    history.push(routesBuilder.quickwrap.blockUrlGroups.edit(id))
  }

  useQuery(DomainsSelectQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ domains }) => {
      setDomains(domains.nodes)
    }
  })

  return (
    <QueryTable<BlockUrlGroup>
      collectionName={CollectionNames.BlockUrls}
      dataQuery={BlockUrlGroupsQuery}
      columnsConfig={columnsConfig(domains) as Column[]}
      className='block-url-groups-table'
      searchableFields={SEARCHABLE_FIELDS}
      variables={{ workspaceId: user.workspace?.id }}
      onRowClick={openEditingPage}
    />
  )
}
