import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Checkbox } from 'components/Form'
import NameFilter from 'components/NameFilter'
import { AdUnitsTable } from 'components/quickwrap/Inventory/AdUnits/Table'
import { Product } from 'constants/Product'
import { filterStore } from 'utilities/filters/filterStore'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { Can } from 'webapp/context/AbilityContext'
import { useFilterConfig } from './useFilterConfig'

const COLLECTION = CollectionNames.AdUnits

export const AdUnitsContainer: React.FC = () => {
  const { t } = useTranslation('inventory')
  const history = useHistory()
  const filterConfig = useFilterConfig()
  const [includeInactive, setIncludeInactive] = useState(
    filterStore.getFilters(CollectionNames.AdUnits).includeInactive || false
  )

  const redirectToAdd = (): void =>
    history.push(routesBuilder.oss.inventory.adUnit.add(), { fromProduct: Product.Quickwrap })

  const onAppliedVersions = () => {
    filterStore.setFilter(CollectionNames.AdUnits, 'includeInactive', !includeInactive)
    setIncludeInactive((previousValue) => !previousValue)
  }

  return (
    <Box className='ad-units' list={true}>
      <BoxHeader
        title={t('adUnits.list.title')}
        containerClassName={'box-header__container--space-between'}
        rowClassName='header__row--width-100'
      >
        <Can I={AbilityAction.EDIT} a={AbilitySubject.ADUNIT}>
          <Button type={ButtonType.Primary} theme={ButtonTheme.Orange} onClickHandler={redirectToAdd}>
            {t('common:buttons.add')}
          </Button>
        </Can>

        <Checkbox
          name='includeInactive'
          id='includeInactive'
          className='ad-units__applied-versions'
          checked={includeInactive}
          onChange={onAppliedVersions}
          label={t('adUnits.list.appliedVersions')}
        />

        <div className='box-header__filters'>
          <AdvancedFilter alignPopup='right' collectionName={COLLECTION} config={filterConfig} />

          <NameFilter
            collection={COLLECTION}
            operator={'contains'}
            path={'oss_ad_unit.ad_unit_path'}
            placeholder={t('adUnits.list.search.byAdUnitPath')}
          />
        </div>
      </BoxHeader>

      <AdUnitsTable />
    </Box>
  )
}
