import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { ButtonType, ButtonTheme } from 'components/Button'
import { BlockUrlGroupsTable } from 'components/quickwrap/Protection/BlockUrlGroups/BlockUrlGroupsTable'
import { LinkButton } from 'components/LinkButton'

import { routesBuilder } from 'utilities/routesBuilder'

export const BlockUrlGroupsContainer = (): JSX.Element => {
  const { t } = useTranslation('protection')

  return (
    <Box className='blockUrls' list={true}>
      <BoxHeader
        title={t('blockUrlGroups.list.title')}
        containerClassName='box-header__container'
        rowClassName='header__row--width-100'
      >
        <div>
          <LinkButton
            type={ButtonType.Primary}
            theme={ButtonTheme.Orange}
            className='blockUrls__header-button'
            to={routesBuilder.quickwrap.blockUrlGroups.new}
          >
            {t('blockUrlGroups.list.addNew')}
          </LinkButton>
        </div>
      </BoxHeader>

      <BlockUrlGroupsTable />
    </Box>
  )
}
