import React from 'react'
import { Slider as MaterialSlider } from '@material-ui/core'
import { SliderProps } from '@material-ui/core/Slider/Slider'
import classNames from 'classnames'

interface FixedSliderProps extends Omit<SliderProps, 'onChange'> {
  onChange?: (event: React.ChangeEvent<unknown>, value: number | number[]) => void
}

interface Props extends FixedSliderProps {
  containerClass?: string
}

export const Slider: React.FC<Props> = ({ containerClass, ...props }) => {
  return (
    <div className={classNames('slider', containerClass)}>
      <MaterialSlider {...props} />
    </div>
  )
}
