import { gql } from '@apollo/client'

import { REVIVE_DOMAIN_CONFIGURATION_TARGETING_FORM_FRAGMENT } from 'gql/fragments/revive/ReviveDomainConfigurationTargetingFormFragment'
import { MUTATION_STATE } from 'webapp/gql/fragments/common'

const PAYLOAD_TYPE = 'UpdateReviveDomainConfigurationPayload'

export const UPDATE_REVIVE_DOMAIN_CONFIGURATION = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  ${REVIVE_DOMAIN_CONFIGURATION_TARGETING_FORM_FRAGMENT}

  mutation UpdateReviveDomainConfiguration($input: UpdateReviveDomainConfigurationInput!) {
    updateReviveDomainConfiguration(input: $input) {
      reviveDomainConfiguration {
        ...ReviveDomainConfigurationTargetingFormFragment

      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
