import React, { useCallback, useContext, useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Can } from 'webapp/context/AbilityContext'
import { Product } from 'webapp/constants/Product'
import { SidebarItem } from 'components/Sidebar'
import { UserContext } from 'webapp/context/UserContext'
import { useSidebarConfiguration } from './configuration'

interface Props {
  product?: Product
}

export const Sidebar: React.FC<Props> = ({ product }) => {
  const { isSuperuser, user } = useContext(UserContext)
  const { t } = useTranslation('sidebar')

  const configuration = useSidebarConfiguration(user as User, product)

  const getMenuItems = useCallback(
    (itemType: string) => configuration.filter((item) => item.type === itemType),
    [configuration]
  )
  const mainMenuItems = useMemo(() => getMenuItems('mainMenuItem'), [getMenuItems])
  const sideMenuItems = useMemo(() => getMenuItems('sideMenuItem'), [getMenuItems])

  const sidebarClass = classNames('sidebar__nav', {
    'sidebar__nav--superuser': isSuperuser
  })

  return (
    <div className='sidebar'>
      <Can I={AbilityAction.VIEW} a={AbilitySubject.ADMIN_BOX}>
        <div className='sidebar__superuser'>{t('admin')}</div>
      </Can>

      <div className={sidebarClass}>
        <nav>
          <ul>
            {mainMenuItems.map((item) => (
              <SidebarItem item={item} key={item.key ?? item.title} />
            ))}
          </ul>
        </nav>

        <nav>
          <ul>
            {sideMenuItems.map((item) => (
              <SidebarItem item={item} key={item.key ?? item.title} />
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}
