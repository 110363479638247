import { gql } from '@apollo/client'

export const PRICE_GENIUS_DOMAIN_CONFIGURATION_FRAGMENT = gql`
  fragment PriceGeniusDomainConfigurationFragment on PgDomainConfiguration {
    domain {
      id
      name
      pgEnabled
    }
    id
    trafficPercent
    updatedAt
  }
`
