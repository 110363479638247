/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BidderParamMode } from 'webapp/constants/BidderParamMode'
import { ErrorUtils } from 'utilities/errorUtils'
import { TFunction } from 'react-i18next'

type BiddersAdUnit = Pick<AdUnit, 'id' | 'bidderParams'>

export const filterBidderParams = (bidderParam: BidderParam) => !filterDemandBidderParams(bidderParam)
const filterDemandBidderParams = (bidderParam: BidderParam) => Boolean(bidderParam?.bidderSetting?.bidder?.demand)

const assignToBidderParamsTemplate = (bidderParam: BidderParam) => ({
  ...assignToBidderParamsCloneTemplate(bidderParam),
  id: bidderParam.id,
  mode: bidderParam.mode,
  saved: true
})

export const assignToBidderParamsCloneTemplate = (bidderParam: BidderParam): AdUnitFormBidder => ({
  bidderSettingId: bidderParam.bidderSetting.id ?? '',
  bidder: {
    bidderCode: bidderParam.bidderSetting.bidderCode,
    fileName: bidderParam.bidderSetting.bidder?.fileName ?? '',
    name: bidderParam.bidderSetting.bidder?.name ?? '',
    demand: bidderParam.bidderSetting.bidder?.demand ?? false
  },
  connected: bidderParam.connected,
  mode: bidderParam.mode,
  params: bidderParam.params
})

const filterAndMapBidders = (bidderParams: BidderParam[] | undefined, mode: BidderParamMode) => {
  return bidderParams?.filter((bidderParam) => bidderParam.mode === mode).map(assignToBidderParamsTemplate) || []
}

const splitAndProcessBidders = (
  bidderParams: BidderParam[] | undefined,
  predicate: (bidderParam: BidderParam) => boolean
): AdUnitFormBidders => {
  const filteredParams = bidderParams?.filter(predicate)
  return {
    client: filterAndMapBidders(filteredParams, BidderParamMode.Client),
    server: filterAndMapBidders(filteredParams, BidderParamMode.Server)
  }
}

export const AdUnitOwnBiddersFactory = (adUnit: BiddersAdUnit): AdUnitBiddersFormData => {
  return {
    id: adUnit.id!,
    bidderParamsAttributes: splitAndProcessBidders(adUnit.bidderParams, filterBidderParams),
    demandBidderParamsAttributes: { client: [], server: [] }
  }
}

export const AdUnitYieldbirdBiddersFactory = (adUnit: BiddersAdUnit): AdUnitBiddersFormData => {
  return {
    id: adUnit.id!,
    bidderParamsAttributes: { client: [], server: [] },
    demandBidderParamsAttributes: splitAndProcessBidders(adUnit.bidderParams, filterDemandBidderParams)
  }
}

export const validateUnsavedAdUnitBidders = (
  formData: AdUnitBiddersFormData,
  attribute: 'bidderParamsAttributes' | 'demandBidderParamsAttributes',
  errors: ErrorUtils,
  t: TFunction<'inventory'>
) => {
  let hasUnsaved = false

  for (const type of [BidderParamMode.Client, BidderParamMode.Server]) {
    formData[attribute][type]
      .filter((param) => !param['_destroy'])
      .forEach((param, index) => {
        errors.removeError([`${attribute}.${type}`, String(index)], t('adUnits.form.inputs.bidders.saveError'))
        if (!param['saved']) {
          errors.setError([`${attribute}.${type}`, String(index)], t('adUnits.form.inputs.bidders.saveError'))
          hasUnsaved = true
        }
      })
  }

  return hasUnsaved
}

export const matchValidationErrorsToSplitBidders = (errors: any, bidderParams: AdUnitFormBidders) => {
  errors.forEach((error: any) => {
    if (['bidderParamsAttributes', 'demandBidderParamsAttributes'].includes(error.path?.[1])) {
      if (error.path[2] < bidderParams.client.length) {
        error.path[1] += '.client'
      } else {
        error.path[1] += '.server'
        error.path[2] = String(error.path[2] - bidderParams.client.length)
      }
    }
  })
  return errors
}
