import React from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ErrorUtils } from 'utilities/errorUtils'
import { StatusForm } from 'components/Form'
import { UserIdsSection } from 'components/quickwrap/Setup/Modules/UserIdsForm/UserIdsSection'

interface Props {
  errors: ErrorUtils
  initialValues: UserIdFormData
  isDirty: boolean
  loading: boolean
  onSubmit: (values: PrebidModuleForm) => void
  prebidModules: PrebidModule[]
  setErrors: React.Dispatch<React.SetStateAction<ErrorUtils>>
  setIsDirty: (isDirty: boolean) => void
  submitting: boolean
}

export const UserIdsForm: React.FC<Props> = ({
  errors,
  initialValues,
  isDirty,
  loading,
  onSubmit,
  prebidModules,
  setErrors,
  setIsDirty,
  submitting
}) => {
  const { t } = useTranslation('setup')

  return (
    <div className='user-id-form'>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
        <StatusForm name='qw-user-id' dirty={isDirty}>
          <UserIdsSection
            errors={errors}
            initialValues={initialValues}
            isDirty={isDirty}
            isDemand={false}
            prebidModules={prebidModules}
            setErrors={setErrors}
            setIsDirty={setIsDirty}
            title={t('userId.form.own.title')}
          />

          <UserIdsSection
            errors={errors}
            hideRequestModules={true}
            initialValues={initialValues}
            isDemand={true}
            isDirty={isDirty}
            prebidModules={prebidModules}
            setErrors={setErrors}
            setIsDirty={setIsDirty}
            title={t('userId.form.yb.title')}
          />

          <div className='form-section--submit'>
            <Button
              disabled={!isDirty || submitting || loading}
              type={ButtonType.Primary}
              theme={ButtonTheme.Orange}
              buttonType='submit'
            >
              {t('common:buttons.save')}
            </Button>
          </div>
        </StatusForm>
      </Formik>
    </div>
  )
}
