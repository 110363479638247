import { useEffect, useState } from 'react'

import { filterStore } from 'utilities/filters/filterStore'
import { processOrderParams } from './queryTableParams'

export interface SortParams {
  by: string
  direction: string
  isSorted: boolean
}

type SortParamsHookType = [SortParams, (columnId: string) => boolean, (columnId: string) => string | undefined]

const useSortParams = (params: string, collectionName: string): SortParamsHookType => {
  const [order, setOrder] = useState({ by: '', direction: '', isSorted: false })

  const isSorted = (columnId: string) => order.isSorted && order.by === columnId

  const updateSortParams = (columnId: string) => {
    let paramObject = undefined

    if (order.by === columnId) {
      if (!order.isSorted) {
        paramObject = [columnId, 'desc']
      } else if (order.direction === 'desc') {
        paramObject = [columnId, 'asc']
      } else {
        paramObject = undefined
      }
    } else {
      paramObject = [columnId, 'desc']
    }

    return paramObject && paramObject.join(',')
  }

  useEffect(() => {
    const filters = filterStore.getFilters(collectionName)
    const variables = processOrderParams(filters)
    if (variables) {
      const { by, direction } = variables.order
      setOrder({ by, direction, isSorted: true })
    } else {
      setOrder({ by: '', direction: '', isSorted: false })
    }
  }, [params, collectionName])

  return [order, isSorted, updateSortParams]
}

export default useSortParams
