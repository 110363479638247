import React from 'react'
import { components, OptionProps } from 'react-select'
import { Grid } from '@material-ui/core'

export const Option: React.ComponentType<OptionProps> = (props) => {
  return (
    <components.Option {...props}>
      <Grid container justifyContent='space-between' alignContent='center'>
        <label className={'Option__label'}>{props.label}</label>
        <input type='checkbox' checked={props.isSelected} onChange={() => null} />
      </Grid>
    </components.Option>
  )
}
