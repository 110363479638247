import React from 'react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { BidderPriorityOptions } from 'webapp/constants/BidderPriorityOptions'
import { Errors } from 'utilities/errorUtils'
import { FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'

interface Props {
  disabled?: boolean
  errors: Errors
  index?: number
  isFirstRow: boolean
}

export const BidderPriorityField: React.FC<Props> = ({ disabled, index, isFirstRow, errors }) => {
  const { t } = useTranslation('setup')
  const id = index === undefined ? 'priority' : `bidders-${index}-priority`
  const name = index === undefined ? 'priority' : `bidders.${index}.priority`
  const errorPath = index === undefined ? 'priority' : [index?.toString(), 'priority']

  return (
    <Field
      component={FormikSelect}
      disabled={disabled}
      errors={errors.errorsFor(errorPath)}
      id={id}
      label={isFirstRow && t('bidders.form.priority.label')}
      name={name}
      options={BidderPriorityOptions}
      placeholder={t('bidders.form.priority.placeholder')}
      tooltipContent={isFirstRow ? t('bidders.form.priority.tooltip') : null}
    />
  )
}
