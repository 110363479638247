import { Product } from 'constants/Product'
import { formatDateTime } from 'utilities/formatDate'

export class HistoryRowData {
  constructor(public history: History | HistoryField) {}

  get id(): string {
    return 'id' in this.history ? this.history.id : ''
  }

  get name(): string {
    return 'name' in this.history ? this.history.name : ''
  }

  get userName(): string {
    return 'user' in this.history ? this.history.user?.fullName || '' : ''
  }

  get amountOfChanges(): string {
    return 'fields' in this.history ? String(this.history.fields.length) : ''
  }

  get createdAt(): string {
    return 'createdAt' in this.history ? formatDateTime(this.history.createdAt) : ''
  }

  get subRows(): HistoryField[] | null {
    return 'fields' in this.history ? this.history.fields : null
  }

  get fieldName(): string {
    return 'fieldName' in this.history ? this.history.fieldName : ' '
  }

  get previousValue(): string {
    return 'previousValue' in this.history ? this.history.previousValue : ' '
  }

  get currentValue(): string {
    return 'currentValue' in this.history ? this.history.currentValue : ' '
  }

  get product(): Product | string {
    return 'product' in this.history ? this.history.product : ''
  }
}
