import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  BlockUrlGroupFactory,
  BlockUrlGroupsForm
} from 'components/quickwrap/Protection/BlockUrlGroups/BlockUrlGroupsForm'
import { BlockUrlGroupSerializer } from 'serializers/BlockUrlGroupSerializer'
import { Box, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import UpsertBlockUrlGroup from 'gql/mutations/blockUrlGroups/UpsertBlockUrlGroup.gql'
import { routesBuilder } from 'utilities/routesBuilder'

export const NewPage = (): JSX.Element => {
  const { t } = useTranslation('protection')
  const [blockUrlGroup] = useState<BlockUrlGroup>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const history = useHistory<History>()

  const [updateBlockUrlGroup, { loading }] = useMutation(UpsertBlockUrlGroup, {
    onCompleted: ({ upsertQwBlockUrlGroup: { qwBlockUrlGroup, errors } }) => {
      if (qwBlockUrlGroup) {
        formStatus.clear()
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmit = (formData: BlockUrlGroupData) => {
    const serializer = new BlockUrlGroupSerializer(formData)

    updateBlockUrlGroup(nestGqlInput(serializer.updateParams())).then((result) => {
      const blockUrlGroup = result.data.upsertQwBlockUrlGroup.qwBlockUrlGroup

      if (blockUrlGroup) {
        createNotifier(t('blockUrlGroups.new.successMessage', { name: blockUrlGroup.name }), NotificationType.SUCCESS)
        formStatus.clear()
        history.push(routesBuilder.quickwrap.blockUrlGroups.edit(blockUrlGroup.id))
      }
    })
  }

  return (
    <Box>
      <BoxHeader title={t('blockUrlGroups.new.title')} />

      <BlockUrlGroupsForm
        blockUrlGroup={BlockUrlGroupFactory(blockUrlGroup)}
        errors={errors}
        isSubmitting={loading}
        onSubmit={onSubmit}
      />
    </Box>
  )
}
