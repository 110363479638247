import React, { useContext } from 'react'

import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Modal } from 'components/Modals'
import { FormikInputContainer as FormikInput, FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'
import { CreateNewVersionLimitReachedModal } from 'containers/quickwrap/Inventory/Domains/CreateNewVersionModal/CreateNewVersionLimitReachedModal'
import { ErrorUtils } from 'utilities/errorUtils'
import { VersionedDomainContext } from 'webapp/context/VersionedDomainContext'
import { NewVersionFormFactory } from './formSchema'

interface Props {
  errorKey: string
  errors: ErrorUtils
  isSubmitting: boolean
  onSendRequestHandler: (data: NewVersionData) => void
  placeholder?: string
  prefix: string
  setVisible: (visible: boolean) => void
  visible: boolean
}

const VERSION_LIMIT = 100

export const CreateNewVersionModal: React.FC<Props> = ({
  errorKey,
  errors,
  onSendRequestHandler,
  placeholder,
  prefix,
  setVisible,
  visible
}) => {
  const { t } = useTranslation('inventory')
  const { domainVersionSelectOptions: options = [], domainVersions } = useContext(VersionedDomainContext)
  const cancelHandler = () => setVisible(false)
  const initialValues = NewVersionFormFactory()

  if ((domainVersions?.length ?? 0) >= VERSION_LIMIT) {
    return <CreateNewVersionLimitReachedModal visible={visible} setVisible={setVisible} />
  }

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      shadow={true}
      backdrop={true}
      title={t('domains.versions.new.modal.title')}
    >
      <div className='new-version__modal-container' id='new-version-form'>
        <Formik initialValues={initialValues} onSubmit={onSendRequestHandler} enableReinitialize={true}>
          {({ values }) => (
            <Form>
              <div>
                <Field
                  name='source'
                  component={FormikSelect}
                  options={options}
                  label={t('domains.versions.new.modal.description')}
                  labelStyle='bold'
                  placeholder=''
                  isSearchable={false}
                  errors={[...errors.errorsFor('domainVersion'), ...errors.errorsFor('source')]}
                />

                <Field
                  id='versionName'
                  containerClassName='mt-2'
                  name='versionName'
                  type='text'
                  label={t('domains.versions.new.modal.versionName')}
                  placeholder={placeholder}
                  prefix={`v${prefix}`}
                  component={FormikInput}
                  errors={errors.errorsFor(errorKey)}
                />
              </div>

              <footer className='new-version-element__footer'>
                <Button
                  theme={ButtonTheme.BlueReverse}
                  type={ButtonType.Primary}
                  onClickHandler={cancelHandler}
                  className='new-version-element__footer__btn'
                  buttonType='button'
                >
                  {t('common:buttons:cancel')}
                </Button>

                <Button
                  theme={ButtonTheme.Blue}
                  type={ButtonType.Primary}
                  className='new-version-element__footer__btn'
                  buttonType='submit'
                  disabled={values.source.label == ''}
                >
                  {t('common:buttons:create')}
                </Button>
              </footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
