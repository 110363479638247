import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { AmazonDemandContainer } from 'containers/quickwrap/Setup/AmazonDemandContainer'
import { BidderInventory } from 'components/quickwrap/Setup/BidderInventory'
import { OwnBiddersContainer } from 'containers/quickwrap/Setup/OwnBiddersContainer'
import { RecommendBiddersContainer } from 'containers/quickwrap/Setup/RecommendBiddersContainer'
import { YieldbirdDemandContainer } from 'containers/quickwrap/Setup/YieldbirdDemandContainer'

import BidderSettingsQuery from 'gql/queries/bidderSettings/BidderSettings.gql'

interface ContextProps {
  shouldRefreshConnections: number
  forceConnectionsRefresh: () => void
}

export const BidderContext = React.createContext<ContextProps>({
  shouldRefreshConnections: 0,
  forceConnectionsRefresh: () => {}
})

export const BiddersPage: React.FC = () => {
  const [shouldRefreshConnections, setShouldRefreshConnections] = useState(0)
  const [demandBidderSettings, setDemandBidderSettings] = useState<BidderSetting[]>()

  const forceConnectionsRefresh = () => {
    setShouldRefreshConnections((prevState) => prevState + 1)
  }

  const { refetch } = useQuery(BidderSettingsQuery, {
    fetchPolicy: 'network-only',
    variables: { demand: true, order: { by: 'bidders.name', direction: 'asc' } },
    onCompleted: ({ bidderSettings: { nodes } }) => setDemandBidderSettings(nodes)
  })

  const updateDemandBidderSettingsHandler = (demandBidderSettings: BidderSetting[]) => {
    setDemandBidderSettings(demandBidderSettings)
    refetch()
  }

  return (
    <BidderContext.Provider value={{ shouldRefreshConnections, forceConnectionsRefresh }}>
      <BidderInventory />
      <OwnBiddersContainer />
      {demandBidderSettings && (
        <>
          <YieldbirdDemandContainer
            demandBidderSettings={demandBidderSettings}
            updateDemandBidderSettingsHandler={updateDemandBidderSettingsHandler}
          />
        </>
      )}
      <AmazonDemandContainer />
      <RecommendBiddersContainer />
    </BidderContext.Provider>
  )
}
