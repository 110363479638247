import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Input } from 'components/Form'

type MainInputType = {
  items: string[]
  onUpdateHandler: (items: string[]) => void
  title: string
}

export const MainInput = ({ items, onUpdateHandler, title }: MainInputType): JSX.Element => {
  const { t } = useTranslation()

  const [newItems, setNewItems] = useState('')

  const inputChangeHandler = (value: string) => setNewItems(value)
  const updateHandler = () => {
    onUpdateHandler(items.concat(convertStringToArray(newItems)))
    setNewItems('')
  }

  const convertStringToArray = (text: string): string[] => {
    return text
      .split(/;|,|\n/)
      .filter((el) => el)
      .map((el) => el.trim())
  }

  return (
    <div className='DynamicListInput__section DynamicListInput__input'>
      <h4 className='DynamicListInput__header'>{title}</h4>
      <Input
        id='dynamicListInput'
        className='DynamicListInput__textArea'
        name='dynamicListInput'
        type='textarea'
        value={newItems}
        onChangeHandler={inputChangeHandler}
      />
      <div className='DynamicListInput__actions'>
        <Button
          buttonType='button'
          className='DynamicListInput__buttona'
          type={ButtonType.Primary}
          theme={ButtonTheme.Blue}
          onClickHandler={updateHandler}
        >
          {t('common:buttons.add')}
        </Button>
      </div>
    </div>
  )
}
