import { AmazonDemandTypes } from 'webapp/constants/AmazonDemandTypes'
import { SetAmazonPublisherServicesForm } from 'components/sharedInterfaces/SetAmazonPublisherServicesForm'

export const AmazonDemandFactory = (
  apsPubId: string,
  forceFormReset: number,
  demandType?: AmazonDemandTypes
): SetAmazonPublisherServicesForm => ({
  apsDemandType: demandType || AmazonDemandTypes.OwnDemand,
  apsPubId: apsPubId || '',
  forceFormReset
})
