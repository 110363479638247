import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ToggleSwitch } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { UserContext } from 'context/UserContext'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error as QqlError } from 'utilities/errorUtils'
import { Product } from 'webapp/constants/Product'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { OssAdUnitProductToggleProps, UpdateProductStatusData, UpdateProductStatusVars } from './types'

import { UPDATE_OSS_AD_UNIT_PRODUCT_STATUS } from 'gql/mutations/adUnits/UpdateOssAdUnitProductStatus'

import './style.scss'

export const OssAdUnitProductToggle = ({
  ossAdUnitId,
  product,
  initialStatus
}: OssAdUnitProductToggleProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { isProductActive, isSuperuser } = useContext(UserContext)
  const [status, setStatus] = useState<boolean>(initialStatus)

  const [updateStatus] = useMutation<UpdateProductStatusData, { input: UpdateProductStatusVars }>(
    UPDATE_OSS_AD_UNIT_PRODUCT_STATUS,
    {
      onCompleted: ({ upsertOssAdUnit: { ossAdUnit, errors } }) => {
        if (errors.length > 0) {
          createNotifier(errors.map((e: QqlError) => e.message).join(', '), NotificationType.ERROR)
        } else if (ossAdUnit) {
          const { adUnitName, frbEnabled, pgEnabled, qwEnabled, reviveEnabled } = ossAdUnit

          createNotifier(t(`adUnits.productToggle.update.success.${product}`, { adUnitName }), NotificationType.SUCCESS)

          switch (product) {
            case Product.FillRateBooster: {
              setStatus(frbEnabled)
              return
            }
            case Product.PriceGenius: {
              setStatus(pgEnabled)
              return
            }
            case Product.Quickwrap: {
              setStatus(qwEnabled)
              return
            }
            case Product.Revive: {
              setStatus(reviveEnabled)
              return
            }
          }
        }
      }
    }
  )

  const handleChange = (product: Product) =>
    void updateStatus(
      nestGqlInput({
        ossAdUnitId,
        [fieldToUpdate(product)]: !initialStatus
      })
    )

  const fieldToUpdate = (product: Product): keyof OssAdUnit => {
    switch (product) {
      case Product.FillRateBooster:
        return 'frbEnabled'
      case Product.PriceGenius:
        return 'pgEnabled'
      case Product.Quickwrap:
        return 'qwEnabled'
      case Product.Revive:
        return 'reviveEnabled'
      default:
        throw Error(`Unsupported product: ${product}`)
    }
  }

  const TOGGLE_KEY = `oss-ad-unit-${ossAdUnitId}-${product}-toggle`

  return (
    <div className='OssAdUnitProductToggle' onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        checked={status}
        disabled={!(isProductActive(product) || isSuperuser)}
        id={TOGGLE_KEY}
        key={TOGGLE_KEY}
        name={TOGGLE_KEY}
        onChange={() => handleChange(product)}
      />
    </div>
  )
}
