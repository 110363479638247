import { PriceGeniusDomainConfiguration } from 'types/PriceGeniusDomainConfiguration'

export class PriceGeniusDomainConfigurationRow {
  constructor(private priceGeniusDomainConfiguration: PriceGeniusDomainConfiguration) {}

  get domainId(): string {
    return this.priceGeniusDomainConfiguration.domain.id
  }

  get domainName(): string {
    return this.priceGeniusDomainConfiguration.domain.name
  }

  get id(): string {
    return this.priceGeniusDomainConfiguration.id
  }

  get trafficPercent(): number {
    return Math.round(this.priceGeniusDomainConfiguration.trafficPercent * 100)
  }

  get priceGeniusEnabled(): boolean {
    return this.priceGeniusDomainConfiguration.domain.pgEnabled
  }

  get updatedAt(): Date {
    return this.priceGeniusDomainConfiguration.updatedAt
  }
}
