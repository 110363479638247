import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { DomainsVersionsTable } from 'components/shared/Inventory/Domains/DomainsVersionsTable'
import { NotificationType } from 'components/ToastNotifier'
import { CreateNewVersionModal } from 'containers/quickwrap/Inventory/Domains/CreateNewVersionModal'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { VersionedDomainContext } from 'webapp/context/VersionedDomainContext'

import CreateDomainVersion from 'gql/mutations/domainVersions/CreateDomainVersion.gql'

interface Props {
  domainId: string
  isABTestRunning: boolean
  testedDomainVersionIds: string[]
}

export const DomainVersionsContainer: React.FC<Props> = ({ domainId, isABTestRunning, testedDomainVersionIds }) => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { refreshDomainVersionQuery, domainVersions = [] } = useContext(VersionedDomainContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const [newVersionModalVisible, setNewVersionModalVisible] = useState(false)
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const [addNewVersion] = useMutation(CreateDomainVersion, {
    onCompleted: ({ createDomainVersion: { domainVersion, errors } }) => {
      if (domainVersion) {
        setIsExpanded(true)

        createNotifier(
          t('domains.versions.new.modal.successMessage', { name: domainVersion.name }),
          NotificationType.SUCCESS
        )
        setNewVersionModalVisible(false)
        refreshDomainVersionQuery?.()
      } else {
        setErrors(new ErrorUtils(errors))
      }
    }
  })

  const onAddNewVersion = (newVersion: NewVersionData) => {
    addNewVersion(nestGqlInput({ domainVersionId: newVersion.source.value, name: newVersion.versionName }))
  }

  const nextCounter = useMemo(() => {
    let nextCounter = Math.max(...domainVersions.map((n) => n.counter)) + 1
    if (nextCounter > 100) {
      nextCounter = 1
      const sortedDomainVersions = domainVersions.concat().sort((item1, item2) => item1.counter - item2.counter)
      for (const domainVersion of sortedDomainVersions) {
        if (nextCounter !== domainVersion.counter) {
          return nextCounter
        }
        nextCounter += 1
      }
    }
    return nextCounter
  }, [domainVersions])

  useEffect(() => {
    if (newVersionModalVisible) {
      setErrors(new ErrorUtils([]))
    }
  }, [newVersionModalVisible])

  return (
    <Box className='versions' defaultExpand={isExpanded} isExpandable={true} onToggle={setIsExpanded} list>
      <CreateNewVersionModal
        visible={newVersionModalVisible}
        setVisible={setNewVersionModalVisible}
        onSendRequestHandler={onAddNewVersion}
        isSubmitting={false}
        prefix={`${nextCounter}_`}
        errorKey='name'
        errors={errors}
      />

      <BoxHeader title={t('domains.edit.versions.title')} subtitle={t('domains.edit.versions.description')}>
        <Button
          type={ButtonType.Primary}
          theme={ButtonTheme.Orange}
          onClickHandler={() => setNewVersionModalVisible(true)}
        >
          {t('domains.new.createNewVersion')}
        </Button>
      </BoxHeader>
      {isExpanded && (
        <DomainsVersionsTable
          domainId={domainId}
          isABTestRunning={isABTestRunning}
          testedDomainVersionIds={testedDomainVersionIds}
        />
      )}
    </Box>
  )
}
