import React from 'react'
import { useField, FieldConfig } from 'formik'

import { InputContainer } from 'components/Form/InputContainer'
import { RadioButton } from 'components/Form'

type FormikRadioContainerType = {
  id: string
  field: FieldConfig
  value: string
  children?: JSX.Element
  className?: string
  disabled?: boolean
  errors?: Array<string>
  label?: JSX.Element
  onChange?: (value: boolean) => void
  tooltipContent?: string
}

export const FormikRadioContainer = ({
  id,
  value,
  children,
  className,
  disabled,
  errors,
  label,
  onChange,
  tooltipContent,
  ...other
}: FormikRadioContainerType): JSX.Element => {
  const [field, , helpers] = useField(other.field.name)

  const onChangeHandler = () => {
    if (onChange) {
      onChange(field.value)
    }
    helpers.setValue(value)
  }

  return (
    <InputContainer inputId={id} className={className} disabled={disabled} errors={errors}>
      <RadioButton
        id={id}
        name={other.field.name}
        value={value}
        checked={field.value === value}
        disabled={disabled}
        label={label}
        onChange={onChangeHandler}
        tooltipContent={tooltipContent}
      >
        {children}
      </RadioButton>
    </InputContainer>
  )
}
