import React from 'react'

import { useHighlightSearch } from './useHighlightSearch'

export interface HighlightSearchItem {
  current: number
  query: string
  total: number
}

export type HighlightSearches = {
  [key: string]: HighlightSearchItem | null
}

export interface ContextProps {
  goToNext: (key: string) => void
  goToPrevious: (key: string) => void
  highlightSearches: HighlightSearches
  setSearch: ({ current, key, total, query }: { current?: number; key: string; total?: number; query?: string }) => void
  unsetSearch: (key: string) => void
}

export const HighlightSearchContext = React.createContext<ContextProps>({
  goToNext: () => {},
  goToPrevious: () => {},
  highlightSearches: {},
  setSearch: () => {},
  unsetSearch: () => {}
})

export const HighlightSearchProvider: React.FC = ({ children }) => {
  const { goToNext, goToPrevious, highlightSearches, setSearch, unsetSearch } = useHighlightSearch()

  return (
    <HighlightSearchContext.Provider
      value={{
        goToNext,
        goToPrevious,
        highlightSearches,
        setSearch,
        unsetSearch
      }}
    >
      {children}
    </HighlightSearchContext.Provider>
  )
}
