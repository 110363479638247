import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Modals'
import { Illustration } from 'components/oss/Products/Illustration'
import { useCurrentProductConnection } from 'utilities/products/current'
import { getProductName } from 'utilities/products/utils'
import { ProductStatus } from 'webapp/constants/ProductStatus'

export const ProductWelcomeModal: React.FC = () => {
  const { t } = useTranslation('products')
  const [isVisible, setIsVisible] = useState(true)
  const currentProductConnection = useCurrentProductConnection()

  if (!currentProductConnection) return null

  const isInSetup = currentProductConnection.status === ProductStatus.InSetup
  const product = currentProductConnection.product

  if (!isInSetup) return null

  const inSetupText = t('welcome.inSetup', { product: getProductName(currentProductConnection.product) })
  const assistanceText = (isInSetup ? inSetupText + ' ' : '') + t('welcome.assistance')

  return (
    <Modal
      id='product-welcome'
      containerClass='product-welcome'
      visible={isVisible}
      setVisible={isInSetup ? undefined : setIsVisible}
      backdrop={true}
      shadow={true}
      closeButton={!isInSetup}
    >
      <aside className='product-welcome__inner'>
        <h2 className='product-welcome__heading'>{t(`welcome.to.${product}`)}</h2>

        <div>{t(`welcome.text.${product}`)}</div>

        <Illustration className='product-welcome__illustration' product={product} />

        <div dangerouslySetInnerHTML={{ __html: assistanceText }} />
      </aside>
    </Modal>
  )
}
