import React from 'react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { AmazonDemandTypes } from 'webapp/constants/AmazonDemandTypes'
import { Can } from 'webapp/context/AbilityContext'
import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'

interface Props {
  amazonEnabled?: boolean
  amazonDemandType?: AmazonDemandTypes
  errors: Errors
}

export const Schain: React.FC<Props> = ({
  amazonDemandType = AmazonDemandTypes.OwnDemand,
  amazonEnabled = false,
  errors
}) => {
  const { t } = useTranslation('inventory')
  const isAmazonSidVisible = (type: AmazonDemandTypes) => amazonEnabled && amazonDemandType === type

  return (
    <div className='schain-fields'>
      {isAmazonSidVisible(AmazonDemandTypes.OwnDemand) && (
        <Field
          id='amazon-sid'
          name='amazonSid'
          type='text'
          placeholder={t('domains.form.amazonSid.placeholder')}
          label={t('domains.form.amazonSid.label')}
          tooltipContent={t('domains.form.amazonSid.tooltip')}
          component={FormikInput}
          errors={errors.errorsFor('amazonSid')}
          labelStyle='bold'
        />
      )}

      <Can I={AbilityAction.EDIT} a={AbilitySubject.YB_SCHAIN}>
        <Field
          id='yb-domain-sid'
          name='ybDomainSid'
          type='text'
          placeholder={t('domains.form.yieldbird.domainSid.placeholder')}
          label={t('domains.form.yieldbird.domainSid.label')}
          tooltipContent={t('domains.form.yieldbird.domainSid.tooltip')}
          component={FormikInput}
          errors={errors.errorsFor('ybDomainSid')}
          labelStyle='bold'
        />

        {isAmazonSidVisible(AmazonDemandTypes.YbDemand) && (
          <Field
            id='yb-amazon-sid'
            name='ybAmazonSid'
            type='text'
            placeholder={t('domains.form.yieldbird.amazonSid.placeholder')}
            label={t('domains.form.yieldbird.amazonSid.label')}
            tooltipContent={t('domains.form.yieldbird.amazonSid.tooltip')}
            component={FormikInput}
            errors={errors.errorsFor('ybAmazonSid')}
            labelStyle='bold'
          />
        )}
      </Can>
    </div>
  )
}
