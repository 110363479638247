import React, { useContext, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Collapse } from '@material-ui/core'
import { FormikHelpers } from 'formik'
import { PropsValue } from 'react-select'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ToggleSwitch } from 'components/Form'
import { CustomCodeForm } from 'components/shared/CustomCode/Form'
import { NotificationType } from 'components/ToastNotifier'
import { CustomCodeTemplateModal } from 'containers/oss/Inventory/Domains/CustomCodeContainer/CustomCodeTemplateModal'
import { useTranslation } from 'react-i18next'
import { CustomCodeSerializer } from 'serializers/CustomCodeSerializer'
import { NETWORK_ONLY_CACHE } from 'utilities/apolloClient'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error, ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { UserContext } from 'webapp/context/UserContext'
import { isCustomCodeForSuperuserOnly } from './service'
import {
  CreateTemplateData,
  CreateTemplateVars,
  CustomCodeContainerProps,
  TemplatesData,
  TemplatesVars,
  UpdateDomainData,
  UpdateDomainVars,
  UpsertCustomCodeData,
  UpsertCustomCodeVars
} from './types'

import { CREATE_CUSTOM_CODE_TEMPLATE } from 'gql/mutations/customCode/CreateCustomCodeTemplate'
import { UPSERT_CUSTOM_CODE } from 'gql/mutations/customCode/UpsertCustomCode'
import { UPDATE_DOMAIN } from 'gql/mutations/domains/UpdateDomain'
import { CUSTOM_CODES } from 'gql/queries/customCode/CustomCodes'

const FORM_ID = 'customCodeForm'

export const CustomCodeContainer = ({ domain: domainInit }: CustomCodeContainerProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { isSuperuser } = useContext(UserContext)
  const [domain, setDomain] = useState<Domain>(domainInit)

  const {
    customCode: domainCustomCode,
    customCodeEnabled: domainCustomCodeEnabled,
    id: domainId,
    name: domainName,
    workspaceId
  } = domain

  const [enabled, setEnabled] = useState(domainCustomCodeEnabled)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [templateModalVisible, setTemplateModalVisible] = useState(false)
  const [templateModal, setTemplateModal] = useState<CustomCodeSelectOption>()
  const [dirtyCache, setDirtyCache] = useState(false)
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const customCodeOnlyForAdmin = isCustomCodeForSuperuserOnly(domain, isSuperuser)

  const [updateDomain] = useMutation<UpdateDomainData, { input: UpdateDomainVars }>(UPDATE_DOMAIN, {
    onCompleted: ({ updateDomain: { domain } }) => {
      const { customCodeEnabled } = domain

      setEnabled(customCodeEnabled)
      formStatus.setStatus(FORM_ID, customCodeEnabled && dirtyCache)

      if (!customCodeEnabled) {
        setDirtyCache(formStatus.getStatus(FORM_ID))
      }
    }
  })

  const { data: templates, refetch: fetchTemplates } = useQuery<TemplatesData, TemplatesVars>(CUSTOM_CODES, {
    variables: { workspaceId },
    ...NETWORK_ONLY_CACHE
  })

  const [createTemplate] = useMutation<CreateTemplateData, { input: CreateTemplateVars }>(CREATE_CUSTOM_CODE_TEMPLATE)

  const [upsertCustomCode] = useMutation<UpsertCustomCodeData, { input: UpsertCustomCodeVars }>(UPSERT_CUSTOM_CODE, {
    onCompleted: ({ upsertCustomCode: { domain } }): void => setDomain(domain)
  })

  const onToggleCustomCode = (): void => {
    updateDomain(
      nestGqlInput({
        domainId: domainId,
        customCodeEnabled: !enabled
      })
    )
  }

  const handleSubmit = async (
    form: CustomCodeSubmitForm,
    { resetForm }: FormikHelpers<CustomCodeSubmitForm>
  ): Promise<void> => {
    setIsSubmitting(true)
    const serializer = new CustomCodeSerializer(form)
    const errContainer: Error[] = []

    const upsertCustomCodeVars: UpsertCustomCodeVars = { domainId, ...serializer.upsertParams() }
    const upsertCustomCodeErrs: Error[] = await onupsertCustomCode(upsertCustomCodeVars)
    upsertCustomCodeErrs.length > 0 && errContainer.push(...upsertCustomCodeErrs)

    if (form.saveAsTemplate) {
      const createTemplateVars: CreateTemplateVars = isSuperuser
        ? serializer.templateParamsForAdmin(workspaceId || '')
        : serializer.templateParams()

      const createTemplateErrs: Error[] = await onCreateTemplate(createTemplateVars)
      createTemplateErrs.length > 0 && errContainer.push(...createTemplateErrs)
    }

    onSubmitFinish(errContainer, resetForm)
  }

  const onupsertCustomCode = async (vars: UpsertCustomCodeVars): Promise<Error[]> => {
    const { data } = await upsertCustomCode(nestGqlInput(vars))
    if (data) {
      const {
        upsertCustomCode: { errors }
      } = data

      if (errors.length > 0) {
        return errors
      }
    }

    return []
  }

  const onCreateTemplate = async (vars: CreateTemplateVars): Promise<Error[]> => {
    const { data } = await createTemplate(nestGqlInput(vars))

    if (data) {
      const {
        createCustomCodeTemplate: { errors }
      } = data

      if (errors.length > 0) {
        return errors
      }
    }

    return []
  }

  const onSubmitFinish = (errors: Error[], resetForm: FormikHelpers<CustomCodeSubmitForm>['resetForm']): void => {
    setErrors(new ErrorUtils(errors))
    setIsSubmitting(false)

    if (errors.length > 0) {
      createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
    } else {
      fetchTemplates()
      formStatus.setStatus(FORM_ID, false)
      resetForm()
      createNotifier(t('domains.edit.successMessage', { name: domainName }), NotificationType.SUCCESS)
    }
  }

  const onTemplateChange = (customCode: PropsValue<SelectOption>): void => {
    setTemplateModal(customCode as CustomCodeSelectOption)
    setTemplateModalVisible(true)
  }

  return (
    <Box className='custom-code' isExpandable={enabled}>
      <BoxHeader
        containerClassName='box-header__container--space-between'
        rowClassName='header__row--width-100'
        title={t('domains.edit.customCode.title')}
      >
        <ToggleSwitch
          checked={enabled}
          disabled={customCodeOnlyForAdmin}
          id='custom-code-enabled'
          name='customCodeEnabled'
          onChange={onToggleCustomCode}
        />
      </BoxHeader>

      <BoxBody>
        <div className='box__body'>
          <Collapse in={enabled}>
            <CustomCodeForm
              customCodeTemplates={templates?.customCodes.nodes || []}
              errors={errors}
              existingCustomCode={domainCustomCode}
              isSubmitting={isSubmitting}
              onlySuperusersCanEdit={customCodeOnlyForAdmin}
              onSubmit={handleSubmit}
              onTemplateChange={onTemplateChange}
            />
          </Collapse>

          {templateModal && (
            <CustomCodeTemplateModal
              visible={templateModalVisible}
              setVisible={setTemplateModalVisible}
              confirmButtonText={t('Close')}
              customCode={templateModal}
            />
          )}
        </div>
      </BoxBody>
    </Box>
  )
}
