import React, { Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react'
import classNames from 'classnames'
import { Column, Row, TableProps, TableRowProps } from 'react-table'
import MaUTable from '@material-ui/core/Table'

import { filterStore } from 'utilities/filters/filterStore'
import { Pagination } from 'components/Pagination'
import { TableBody } from './TableBody'
import { TableFooter } from './TableFooter'
import { TableHeader } from './TableHeader'
import { useTable } from './useTable'

interface Props<T> extends TableProps {
  className?: string
  collectionName: string
  columns: Column[]
  columnTooltips?: Record<string, string>
  data: T[]
  disablePagination?: boolean
  getRowProps?: (row: Row<Record<string, unknown>>) => Partial<TableRowProps>
  hasSummaryRow?: boolean
  hiddenColumns?: string[]
  onRowClick?: (row: Row<Record<string, unknown>>) => void
  pageIndex?: number
  paginateExpandedRows?: boolean
  queryPageSize?: number
  queryPagination?: boolean
  setPageIndex?: Dispatch<SetStateAction<number>>
}

export function Table<T>({
  className,
  collectionName,
  columns,
  columnTooltips,
  data,
  disablePagination,
  getRowProps,
  hasSummaryRow,
  hiddenColumns,
  onRowClick,
  pageIndex,
  paginateExpandedRows,
  queryPageSize,
  queryPagination,
  setPageIndex
}: PropsWithChildren<Props<T>>): JSX.Element {
  const classString = classNames('qw-table', className)

  const {
    footerGroups,
    getGotoPage,
    getPageIndex,
    getPageSize,
    getRows,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    isPaginationEnabled,
    prepareRow,
    setHiddenColumns,
    toggleAllRowsExpanded
  } = useTable({
    collectionName,
    columns,
    data,
    hiddenColumns,
    paginateExpandedRows,
    disablePagination,
    pageIndex,
    queryPagination,
    queryPageSize
  })

  useEffect(() => {
    setHiddenColumns(hiddenColumns || [])
  }, [hiddenColumns]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getPageIndex > 0 && getRows.length === 0) {
      filterStore.setFilter(collectionName, 'page', String(getPageIndex))
    }
  }, [getRows, getPageIndex]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MaUTable {...getTableProps()} className={classString}>
        <TableHeader
          headerGroups={headerGroups}
          collectionName={collectionName}
          columnTooltips={columnTooltips}
          key='table-header'
        />
        <TableBody
          collectionName={collectionName}
          rows={getRows}
          prepareRow={prepareRow}
          getTableBodyProps={getTableBodyProps}
          getRowProps={getRowProps}
          onRowClick={onRowClick}
        />
        <TableFooter hasSummaryRow={hasSummaryRow} footerGroups={footerGroups} />
      </MaUTable>

      {isPaginationEnabled && (
        <Pagination
          collectionName={collectionName}
          pageIndex={getPageIndex}
          pageSize={getPageSize()}
          setPageIndex={setPageIndex}
          {...getGotoPage()}
          toggleAllRowsExpanded={toggleAllRowsExpanded}
        />
      )}
    </>
  )
}
