import { gql } from '@apollo/client'

import { REVIVE_DOMAIN_CONFIGURATION_TARGETING_FORM_FRAGMENT } from 'gql/fragments/revive/ReviveDomainConfigurationTargetingFormFragment'

export const REVIVE_DOMAIN_CONFIGURATION = gql`
  ${REVIVE_DOMAIN_CONFIGURATION_TARGETING_FORM_FRAGMENT}

  query reviveDomainConfiguration($reviveDomainConfigurationId: ID!) {
    reviveDomainConfiguration(reviveDomainConfigurationId: $reviveDomainConfigurationId) {
      ...ReviveDomainConfigurationTargetingFormFragment
    }
  }
`
