import React, { useMemo } from 'react'

import { Grid } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { FormSection } from 'components/shared/Inventory/AdUnits/Form/Sections/FormSection'
import { FormikInputContainer, FormikSelectContainer, FormikToggleContainer } from 'containers/FormikContainers'
import { Errors } from 'utilities/errorUtils'

interface Props {
  domain?: Domain
  domains: Domain[]
  errors: Errors
}
export const MainForm: React.FC<Props> = ({ domain, domains, errors }) => {
  const history = useHistory<History>()
  const { values } = useFormikContext<AdUnitForm>()
  const { t } = useTranslation('inventory')

  const { domainId, domainVersionId } = useMemo(
    () => queryString.parse(history.location.search) as { domainId?: string; domainVersionId?: string },
    [history]
  )

  const showField = (fieldName: keyof DomainConfiguration) =>
    domain && domain?.activeVersion?.domainConfiguration[fieldName]

  const prepareDomainOptions = (domain: Domain) => {
    // case when user edit Ad unit
    if (domain.id === domainId) {
      return { label: domain.name, value: domainVersionId }
    }

    // case when user creating new Ad unit
    if (domain.id === values.domainVersionId?.domainId) {
      return { label: domain.name, value: values.domainVersionId?.value }
    }

    return { label: domain.name, value: domain.activeVersion.id }
  }

  return (
    <FormSection title={t('adUnits.form.basicSettings')}>
      <Grid item lg={4} xs={6}>
        <Field
          component={FormikSelectContainer}
          disabled
          errors={errors.errorsFor('domainVersionId')}
          id='ad_unit-domain-version-id'
          label={t('adUnits.form.inputs.domainId.label')}
          name='domainVersionId'
          options={domains.map(prepareDomainOptions)}
          placeholder={t('adUnits.form.inputs.domainId.placeholder')}
          tooltipContent={t('adUnits.form.inputs.domainId.tooltip')}
        />

        {showField('customLazyLoadEnabled') && (
          <Field
            id='ad_unit-lazy-load-enabled'
            name='lazyLoadEnabled'
            errors={errors.errorsFor('lazyLoadEnabled')}
            label={t('adUnits.form.inputs.lazyLoadEnabled.label')}
            tooltipContent={t('adUnits.form.inputs.lazyLoadEnabled.tooltip')}
            component={FormikToggleContainer}
            labelStyle='bold'
            checked={!values.lazyLoadEnabled}
          />
        )}
      </Grid>

      <Grid item lg={4} xs={6}>
        <Field
          component={FormikInputContainer}
          disabled
          errors={errors.errorsFor('adUnitPath')}
          id='ad_unit-ad_unit_path'
          label={t('adUnits.form.inputs.adUnitPath.label')}
          name='ossAdUnit.adUnitPath'
          placeholder={t('adUnits.form.inputs.adUnitPath.placeholder')}
          tooltipContent={t('adUnits.form.inputs.adUnitPath.tooltip')}
          type='text'
        />

        <Field
          component={FormikInputContainer}
          disabled
          errors={errors.errorsFor('elementId')}
          id='ad_unit-element-id'
          label={t('adUnits.form.inputs.elementId.label')}
          name='ossAdUnit.elementId'
          placeholder={t('adUnits.form.inputs.elementId.placeholder')}
          tooltipContent={t('adUnits.form.inputs.elementId.tooltip')}
          type='text'
        />
      </Grid>
    </FormSection>
  )
}
