import React from 'react'
import { Collapse } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import type { Errors } from 'utilities/errorUtils'
import {
  FormikInputContainer as FormikInput,
  FormikRadioContainer,
  FormikToggleContainer as FormikToggle
} from 'containers/FormikContainers'
import { Section } from 'components/Form'

interface Props {
  errors: Errors
}

export const LazyLoad: React.FC<Props> = ({ errors }) => {
  const {
    values: { lazyAuctionEnabled, lazyLoadEnabled }
  } = useFormikContext<DomainFormData>()
  const { t } = useTranslation('inventory')

  return (
    <Section>
      <Field
        id='domain-lazy-load-enabled'
        name='lazyLoadEnabled'
        label={t('domains.form.lazyLoad.label')}
        tooltipContent={t('domains.form.lazyLoad.tooltip')}
        component={FormikToggle}
        labelStyle='bold'
      />

      <Collapse in={lazyLoadEnabled}>
        <Field
          id='custom-lazy-load-disabled'
          name='customLazyLoadEnabled'
          type='radio'
          label={t('domains.form.customLazyLoadDisabled.label')}
          tooltipContent={t('domains.form.customLazyLoadDisabled.tooltip')}
          value={false}
          component={FormikRadioContainer}
        />

        <Field
          id='custom-lazy-load-enabled'
          name='customLazyLoadEnabled'
          type='radio'
          label={t('domains.form.customLazyLoadEnabled.label')}
          tooltipContent={t('domains.form.customLazyLoadEnabled.tooltip')}
          value={true}
          component={FormikRadioContainer}
        />

        <Field
          id='domain-lazy-auction-enabled'
          name='lazyAuctionEnabled'
          label={t('domains.form.lazyAuctionEnabled.label')}
          tooltipContent={t('domains.form.lazyAuctionEnabled.tooltip')}
          component={FormikToggle}
          labelStyle='bold'
          isBeta
        />

        <Collapse in={lazyAuctionEnabled}>
          <Field
            id='domain-auction-margin-percent'
            name='auctionMarginPercent'
            type='number'
            placeholder={t('domains.form.auctionMarginPercent.placeholder')}
            label={t('domains.form.auctionMarginPercent.label')}
            tooltipContent={t('domains.form.auctionMarginPercent.tooltip')}
            component={FormikInput}
            errors={errors.errorsFor('auctionMarginPercent')}
            isBeta
          />
        </Collapse>

        <Field
          id='domain-fetch-margin-percent'
          name='fetchMarginPercent'
          type='number'
          placeholder={t('domains.form.fetchMarginPercent.placeholder')}
          label={t('domains.form.fetchMarginPercent.label')}
          tooltipContent={t('domains.form.fetchMarginPercent.tooltip')}
          component={FormikInput}
          errors={errors.errorsFor('fetchMarginPercent')}
        />

        <Field
          id='domain-render-margin-percent'
          name='renderMarginPercent'
          type='number'
          placeholder={t('domains.form.renderMarginPercent.placeholder')}
          label={t('domains.form.renderMarginPercent.label')}
          tooltipContent={t('domains.form.renderMarginPercent.tooltip')}
          component={FormikInput}
          errors={errors.errorsFor('renderMarginPercent')}
        />

        <Field
          id='domain-mobile-scaling'
          name='mobileScaling'
          type='number'
          step={0.01}
          placeholder={t('domains.form.mobileScaling.placeholder')}
          label={t('domains.form.mobileScaling.label')}
          tooltipContent={t('domains.form.mobileScaling.tooltip')}
          component={FormikInput}
          errors={errors.errorsFor('mobileScaling')}
        />
      </Collapse>
    </Section>
  )
}
