import { useMutation, useQuery } from '@apollo/client'
import { FormikHelpers } from 'formik'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { LoadingContainer } from 'components/LoadingContainer'
import { NotificationType } from 'components/ToastNotifier'
import { InviteFormForPublishers } from 'components/oss/Users/InviteFormForPublishers'
import { InviteFormForSuperusers } from 'components/oss/Users/InviteFormForSuperusers'
import { InviteUserSerializer } from 'serializers/InviteUserSerializer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { UserContext } from 'webapp/context/UserContext'

import InviteUserMutation from 'gql/mutations/users/InviteUser.gql'
import WorkspacesWithOwnerCount from 'gql/queries/workspaces/WorkspacesWithOwnerCount.gql'
import { useHistory } from 'react-router-dom'

export const InviteUserPage: React.FC = () => {
  const { t } = useTranslation('users')
  const history = useHistory()

  const { createNotifier } = useContext(NotificationContext) as Notification
  const { isSuperuser } = useContext(UserContext)

  const [errors, setErrors] = useState(new ErrorUtils([]))

  const [InviteUser, { loading: isSubmitting }] = useMutation(InviteUserMutation)

  const onSubmitHandler = async (values: InviteUserSchema, actions: FormikHelpers<InviteUserSchema>) => {
    const serializer = new InviteUserSerializer(values, isSuperuser)
    const { data } = await InviteUser(nestGqlInput(serializer.getParams()))
    const invitedUser = data.inviteUser.invitedUser
    const errors = data.inviteUser.errors

    if (errors.length) {
      createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
    } else {
      actions.resetForm()
      history.goBack()
      createNotifier(t('invite.successMessage', { email: invitedUser.email }), NotificationType.SUCCESS)
    }
    setErrors(new ErrorUtils(errors))
  }

  const { data } = useQuery(WorkspacesWithOwnerCount, {
    fetchPolicy: 'network-only',
    skip: !isSuperuser
  })
  const workspaces = data?.workspaces.nodes

  const InviteForm = isSuperuser ? InviteFormForSuperusers : InviteFormForPublishers

  return (
    <Box className='invite-user'>
      <BoxHeader title={t('invite.title')} subtitle={t('invite.subtitle')} />

      <LoadingContainer loading={isSuperuser && !workspaces}>
        <InviteForm errors={errors} isSubmitting={isSubmitting} onSubmit={onSubmitHandler} workspaces={workspaces} />
      </LoadingContainer>
    </Box>
  )
}
