import React, { useContext, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Box, BoxBody, BoxHeader } from 'components/Box'
import { useTranslation } from 'react-i18next'

import { LoadingContainer } from 'components/LoadingContainer'
import { NotificationType } from 'components/ToastNotifier'
import { Notification, NotificationContext } from 'context/NotificationContext'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { BasicForm } from './BasicForm'
import * as T from './types'

import { UPDATE_REVIVE_DOMAIN_CONFIGURATION } from 'gql/mutations/revive/UpdateReviveDomainConfiguration'
import { REVIVE_DOMAIN_CONFIGURATION } from 'gql/queries/revive/reviveDomainConfiguration'

import './styles.scss'

export const BasicFormContainer = ({ urlReviveDomainConfigurationId }: T.BasicFormContainerProps): JSX.Element => {
  const { t } = useTranslation('revive')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [reviveDomainConfiguration, setReviveDomainConfiguration] = useState<T.ReviveDomainConfigurationData>()
  const [errors, setErrors] = useState(new ErrorUtils([]))

  useQuery<{ reviveDomainConfiguration: T.ReviveDomainConfigurationData }, T.ReviveDomainConfigurationVars>(
    REVIVE_DOMAIN_CONFIGURATION,
    {
      variables: {
        reviveDomainConfigurationId: urlReviveDomainConfigurationId
      },
      onCompleted: (data) => {
        const {
          reviveDomainConfiguration,
          reviveDomainConfiguration: { refreshAdThreshold }
        } = data

        setReviveDomainConfiguration({
          ...reviveDomainConfiguration,
          refreshAdThreshold: Math.trunc(Math.round(refreshAdThreshold * 100))
        })
      }
    }
  )

  const [updateReviveDomainConfiguration, { loading: updateLoading }] = useMutation<
    T.UpdateReviveDomainConfigurationData,
    { input: T.UpdateReviveDomainConfigurationVars }
  >(UPDATE_REVIVE_DOMAIN_CONFIGURATION, {
    onCompleted: ({ updateReviveDomainConfiguration: { reviveDomainConfiguration, errors } }) => {
      setErrors(new ErrorUtils(errors))

      if (errors.length > 0) {
        createNotifier(t('configuration.edit.basicForm.update.error'), NotificationType.ERROR)
      } else if (reviveDomainConfiguration) {
        const {
          domain: { name }
        } = reviveDomainConfiguration

        createNotifier(t('configuration.edit.basicForm.update.success', { name }), NotificationType.SUCCESS)
        formStatus.clear()
      }
    }
  })

  const handleFormSubmit = (
    values: T.UpdateReviveDomainConfigurationVars,
    reviveDomainConfigurationId: T.ReviveDomainConfigurationData['id']
  ): void => {
    const { refreshAdThreshold } = values

    updateReviveDomainConfiguration(
      nestGqlInput({
        ...values,
        refreshAdThreshold: Math.trunc(refreshAdThreshold) / 100,
        reviveDomainConfigurationId
      })
    )
  }

  const Container = ({ reviveDomainConfiguration }: { reviveDomainConfiguration: T.ReviveDomainConfigurationData }) => {
    const { id, refreshAdThreshold } = reviveDomainConfiguration

    return (
      <Box>
        <BoxHeader title={t('configuration.edit.basicForm.header.title')} />

        <BoxBody>
          {updateLoading ? (
            <LoadingContainer loading />
          ) : (
            <BasicForm
              data={{ ...reviveDomainConfiguration, refreshAdThreshold }}
              errors={errors}
              loading={false}
              onSubmit={(values): void => handleFormSubmit(values, id)}
            />
          )}
        </BoxBody>
      </Box>
    )
  }

  return reviveDomainConfiguration ? (
    <Container reviveDomainConfiguration={reviveDomainConfiguration} />
  ) : (
    <LoadingContainer loading />
  )
}
