export class WorkspaceDeviceSerializer {
  items: WorkspaceDevice[]

  constructor(items: WorkspaceDevice[]) {
    this.items = items || []
  }

  updateParams(records: WorkspaceDeviceRowData[]): WorkspaceDevice[] {
    return this.recordsToSave(records).concat(this.recordsToRemove(records))
  }

  private recordsToSave(records: WorkspaceDeviceRowData[]): WorkspaceDevice[] {
    return records.map(
      (record) =>
        ({
          id: record.id,
          name: record.name,
          minViewPort: parseInt(String(record.minViewPort)),
          playerSize: record.playerSize[0] ? WorkspaceDeviceSerializer.sizeStringToArray(record.playerSize[0]) : null,
          bannerSizes: record.bannerSizes.map(WorkspaceDeviceSerializer.sizeStringToArray)
        } as WorkspaceDevice)
    )
  }

  private recordsToRemove(records: WorkspaceDeviceRowData[]): WorkspaceDevice[] {
    const workspaceDeviceIds = records.map((record) => record.id)

    return this.items
      .filter((elem) => !workspaceDeviceIds.includes(elem.id))
      .map((elem) => {
        return { id: elem.id, _destroy: true }
      }) as unknown as WorkspaceDevice[]
  }

  static sizeStringToArray(size: string): number[] | string {
    if (size.includes('x')) {
      return size?.split('x').map((sizePart) => Number(sizePart)) || []
    } else {
      return size
    }
  }
}
