import React from 'react'

import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import DatePickerSingle from 'components/DatePickerSingle'
import { Label } from 'components/Form'
import { ConfirmationModal } from 'components/Modals'
import { trialDurationDefault } from '../utils'
import { TrialDurationModalProps } from './types'

import './react_dates_overrides.scss'
import './styles.scss'

export const TrialDurationModal = ({
  product,
  workspaceName,
  duration,
  onDurationChange,
  onConfirmHandler,
  onCancelHandler,
  isOpen,
  setIsOpen,
  isLoading
}: TrialDurationModalProps): JSX.Element => {
  const { t } = useTranslation('products')

  const { from, to } = duration
  const durationDiff: number = moment(to).diff(moment(from), 'days')

  const handleFromChange = (newValue: Date) => void onDurationChange({ from: newValue, to })
  const handleToChange = (newValue: Date) => void onDurationChange({ from, to: newValue })
  const handleSetTo30Days = () => void onDurationChange(trialDurationDefault({}))

  const handleCancel = (): void => {
    onCancelHandler()
    setIsOpen(false)
  }

  const DatePickerFrom = (): JSX.Element => (
    <div className='TrialDurationModal__date-picker-container'>
      <Label>{t('trialDurationModal.from')}</Label>
      <DatePickerSingle value={from} onChange={handleFromChange} disabled />
    </div>
  )

  const DatePickerTo = (): JSX.Element => (
    <div className='TrialDurationModal__date-picker-container'>
      <Label>{t('trialDurationModal.to')}</Label>

      <div className='TrialDurationModal__date-picker-container--to'>
        <DatePickerSingle
          value={to}
          onChange={handleToChange}
          isOutsideRange={(day: Moment) => day.startOf('day').isSameOrBefore(from, 'day')}
        />

        {durationDiff !== 30 && <SetTo30Days />}
      </div>
    </div>
  )

  const SetTo30Days = (): JSX.Element => (
    <div className='TrialDurationModal__date-picker-set' onClick={handleSetTo30Days}>
      {t('trialDurationModal.setTo30Days')}
    </div>
  )

  const Duration = (): JSX.Element => (
    <div className='TrialDurationModal__duration'>
      <span className='TrialDurationModal__duration-title'>{t('trialDurationModal.duration')}: </span>
      {durationDiff} {t('trialDurationModal.days')}
    </div>
  )

  return (
    <ConfirmationModal
      bodyClassName='TrialDurationModal layout-oss'
      title={t('trialDurationModal.editTrial', { product: t(`product.${product}`), workspace: workspaceName })}
      confirmButtonText={t('trialDurationModal.set')}
      onConfirmHandler={onConfirmHandler}
      onCancelHandler={handleCancel}
      visible={isOpen}
      setVisible={setIsOpen}
      closeButton
      isSubmitting={isLoading}
    >
      <DatePickerFrom />
      <DatePickerTo />
      <Duration />
    </ConfirmationModal>
  )
}
