import React from 'react'

import { useTranslation } from 'react-i18next'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import NameFilter from 'components/NameFilter'
import PagesTable from 'components/oss/Inventory/Pages/PagesTable'
import { Product } from 'constants/Product'
import { useFilterConfig } from 'containers/oss/Inventory/Pages/PagesContainer/useFilterConfig'
import { useHistory } from 'react-router-dom'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { Can } from 'webapp/context/AbilityContext'

const COLLECTION = CollectionNames.OssPages

export const PagesContainer = (): JSX.Element => {
  const { t } = useTranslation('inventory')
  const history = useHistory()
  const filterConfig = useFilterConfig()

  const redirectToAdd = (): void => history.push(routesBuilder.oss.inventory.pages.add, { fromProduct: Product.OSS })

  return (
    <Box className='pages' list={true}>
      <BoxHeader
        title={t('pages.list.title')}
        containerClassName='box-header__container'
        rowClassName='header__row--width-100'
      >
        <Can I={AbilityAction.EDIT} a={AbilitySubject.PAGE}>
          <Button type={ButtonType.Primary} theme={ButtonTheme.Blue} onClickHandler={redirectToAdd}>
            {t('common:buttons.add')}
          </Button>
        </Can>

        <div className='box-header__filters'>
          <AdvancedFilter alignPopup='right' collectionName={COLLECTION} config={filterConfig} />

          <NameFilter
            collection={COLLECTION}
            operator={'contains'}
            path={'path'}
            placeholder={t('pages.list.search.byUrlPath')}
          />
        </div>
      </BoxHeader>

      <PagesTable />
    </Box>
  )
}
