import { AvailablePrebidModules } from 'webapp/constants/PrebidModuleKind'

const isDemand = (workspacePrebidModule: WorkspacePrebidModule) => workspacePrebidModule.prebidModule.demand

export const SharedPrebidModuleFactory = (workspacePrebidModules: WorkspacePrebidModule[] = []): PrebidModuleParams[] =>
  workspacePrebidModules.map((module) => ({
    id: module.id,
    moduleId: module.prebidModule.id,
    name: module.prebidModule.name,
    params: module.params,
    saved: true
  }))

export const PrebidModuleFactory = (
  workspacePrebidModules: WorkspacePrebidModule[] = [],
  field: AvailablePrebidModules
) => ({
  [field]: SharedPrebidModuleFactory(workspacePrebidModules)
})

export const UserIdsModuleFactory = (workspacePrebidModules: WorkspacePrebidModule[] = []) => {
  const demandModules = workspacePrebidModules.filter(isDemand)
  const ownModules = workspacePrebidModules.filter((workspacePrebidModule) => !isDemand(workspacePrebidModule))

  return {
    demand: SharedPrebidModuleFactory(demandModules),
    own: SharedPrebidModuleFactory(ownModules)
  }
}

export const RequestPrebidModuleFactory = (name: string, kind: string) => ({ name, kind })
