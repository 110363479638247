import React from 'react'

import { Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { Schain } from 'components/quickwrap/Inventory/Domains/Form/Schain'
import { FormikInputContainer as FormikInput, FormikSelectContainer } from 'containers/FormikContainers'
import { DomainFormData } from 'containers/oss/Inventory/Domains/DomainFormContainer'
import { currencyUtils } from 'utilities/currencyUtils'
import { Errors } from 'utilities/errorUtils'
import useGeneralSettings from 'webapp/hooks/useGeneralSettings'

interface Props {
  data: DomainFormData
  errors: Errors
  loading: boolean
  onSubmit: (data: DomainFormData) => void
  workspaceId: Domain['workspaceId']
}

export const DomainForm: React.FC<Props> = ({ errors, data, loading, onSubmit, workspaceId }) => {
  const { t } = useTranslation('inventory')
  const { generalSettings } = useGeneralSettings(workspaceId)

  return (
    <div className='qw-form domain-form'>
      <Formik initialValues={data} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='oss-domain' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={6}>
                <Field
                  component={FormikInput}
                  errors={errors.errorsFor('name')}
                  id='domain-url'
                  label={t('domains.form.domainName.label')}
                  name='name'
                  placeholder={t('domains.form.domainName.placeholder')}
                  tooltipContent={t('domains.form.domainName.tooltip')}
                  type='text'
                />

                <Schain
                  amazonDemandType={generalSettings?.apsDemandType}
                  amazonEnabled={generalSettings?.apsDemandEnabled}
                  errors={errors}
                />

                <Field
                  component={FormikSelectContainer}
                  containerClassName='domain-currency'
                  errors={errors.errorsFor('currency')}
                  id='domain-currency'
                  label={t('domains.form.currency.label')}
                  name='currency'
                  options={currencyUtils.getList()}
                  placeholder={t('domains.form.currency.placeholder')}
                  tooltipContent={t('domains.form.currency.tooltip')}
                />
              </Grid>
            </Grid>

            <Button disabled={loading || !dirty} type={ButtonType.Primary} theme={ButtonTheme.Blue} buttonType='submit'>
              {loading ? t('common:buttons.saving') : t('common:buttons.save')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
