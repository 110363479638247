import React from 'react'

import { getProductSettings } from 'utilities/products/settings'

interface Props {
  className?: string
  product: ProductConnection['product']
}

export const Illustration: React.FC<Props> = ({ className, product }) => {
  const imgSrc = getProductSettings(product).illustration

  return (
    <div className={className}>
      <img src={imgSrc} alt={product} />
    </div>
  )
}
