import { PortalRoutes } from 'webapp/routes/oss/PortalRoutes'
import { DownloadTagsRoutes } from './DownloadTagsRoutes'
import { FinanceRoutes } from './FinanceRoutes'
import { HistoryRoutes } from './HistoryRoutes'
import { InventoryRoutes } from './InventoryRoutes'
import { PerformanceRoutes } from './PerformanceRoutes'
import { ProductRoutes } from './ProductRoutes'

export const OssRoutes = [
  DownloadTagsRoutes,
  FinanceRoutes,
  HistoryRoutes,
  InventoryRoutes,
  PerformanceRoutes,
  PortalRoutes,
  ProductRoutes
]
