import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Formik, Field } from 'formik'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { FormikToggleContainer as FormikToggle } from 'containers/FormikContainers'
import { StatusForm } from 'components/Form'

interface Props {
  defaultValues: Partial<WorkspacePackagesForm>
  onSubmitHandler: (values: Partial<WorkspacePackagesForm>) => void
}

export const WorkspacePackagesForm: React.FC<Props> = ({ defaultValues, onSubmitHandler }) => {
  const { t } = useTranslation('workspaces')

  return (
    <div className='workspace-form qw-form'>
      <Formik initialValues={defaultValues} onSubmit={onSubmitHandler} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='oss-workspace-packages' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={3} xs={6}>
                <Field
                  id='prebid-analytics-enabled'
                  name='prebidAnalyticsEnabled'
                  label={t('packages.toggle.prebidAnalytics.label')}
                  tooltipContent={t('packages.toggle.prebidAnalytics.tooltip')}
                  component={FormikToggle}
                  labelStyle='bold'
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item lg={3} xs={6}>
                <Field
                  id='s2s-enabled'
                  name='s2sEnabled'
                  label={t('packages.toggle.s2s.label')}
                  tooltipContent={t('packages.toggle.s2s.tooltip')}
                  component={FormikToggle}
                  labelStyle='bold'
                />
              </Grid>
            </Grid>

            <div className='workspace__pricing'>
              <span>{t('packages.pricing.info')}</span>

              <a
                href={t('packages.pricing.link.href')}
                className='workspace__pricing--link'
                target='_blank'
                rel='noreferrer'
              >
                {t('packages.pricing.link.label')}
              </a>
            </div>

            <Button type={ButtonType.Primary} theme={ButtonTheme.Blue} buttonType='submit' disabled={!dirty}>
              {t('common:buttons.save')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
