import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody } from 'components/Box'
import { DomainForm } from 'components/oss/Inventory/Domains/DomainForm/DomainForm'
import { DomainFormDataFactory } from 'components/oss/Inventory/Domains/DomainForm/formSchema'
import { NotificationType } from 'components/ToastNotifier'
import { DomainFormData } from 'containers/oss/Inventory/Domains/DomainFormContainer'
import { UserContext } from 'context/UserContext'
import pick from 'lodash/pick'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { DomainFormContainerProps, UpdateDomainData, UpdateDomainVars } from './types'

import { UPDATE_DOMAIN } from 'gql/mutations/domains/UpdateDomain'
import { UPDATE_DOMAIN_AS_ADMIN } from 'gql/mutations/domains/UpdateDomainAsAdmin'

export const DomainFormContainer: React.FC<DomainFormContainerProps> = ({ domain, setDomain }) => {
  const { t } = useTranslation('inventory')
  const { isSuperuser } = useContext(UserContext)
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const UPDATE_DOMAIN_MUTATION = isSuperuser ? UPDATE_DOMAIN_AS_ADMIN : UPDATE_DOMAIN

  const [updateDomain, { loading }] = useMutation<UpdateDomainData, { input: UpdateDomainVars }>(
    UPDATE_DOMAIN_MUTATION,
    {
      onCompleted: ({ updateDomain: { domain: updatedDomain, errors } }) => {
        if (errors.length > 0) {
          createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
          setErrors(new ErrorUtils(errors))
        } else if (domain) {
          const { name } = domain
          createNotifier(t('domains.edit.successMessage', { name }), NotificationType.SUCCESS)

          setDomain({
            ...domain,
            ...(isSuperuser
              ? pick(updatedDomain, ['amazonSid', 'currency', 'name', 'ybAmazonSid', 'ybDomainSid'])
              : pick(updatedDomain, ['amazonSid', 'currency', 'name']))
          })
        }
      }
    }
  )

  const handleSubmit = (data: DomainFormData) =>
    void updateDomain(
      nestGqlInput({
        ...data,
        currency: data.currency.value.toLowerCase()
      })
    )

  return (
    <Box isExpandable={true}>
      <BoxBody>
        <DomainForm
          workspaceId={domain.workspaceId}
          data={DomainFormDataFactory(domain)}
          errors={errors}
          loading={loading}
          onSubmit={handleSubmit}
        />
      </BoxBody>
    </Box>
  )
}
