export const WorkspaceFactory = (workspace?: Workspace): WorkspaceFormData => ({
  adNetworkAsi: workspace?.adNetworkAsi ?? '',
  crmId: workspace?.crmId ?? '',
  csManagerId: workspace?.csManager && {
    label: workspace?.csManager.fullName || workspace?.csManager.email,
    value: workspace?.csManager.id
  },
  id: workspace?.id ?? '',
  name: workspace?.name ?? '',
  pgGlobalTargetingEnabled: workspace ? workspace.pgGlobalTargetingEnabled : true
})
