import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ToggleSwitch } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { UserContext } from 'context/UserContext'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error as QqlError } from 'utilities/errorUtils'
import { Product } from 'webapp/constants/Product'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { DomainProductToggleProps, UpdateProductStatusData, UpdateProductStatusVars } from './types'

import { UPDATE_DOMAIN_PRODUCT_STATUS } from 'gql/mutations/domains/UpdateDomainProductStatus'

import './style.scss'

export const DomainProductToggle = ({ domainId, product, initialStatus }: DomainProductToggleProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { isProductActive, isSuperuser } = useContext(UserContext)
  const [status, setStatus] = useState<boolean>(initialStatus)

  const [updateStatus] = useMutation<UpdateProductStatusData, { input: UpdateProductStatusVars }>(
    UPDATE_DOMAIN_PRODUCT_STATUS,
    {
      onCompleted: ({ updateDomain: { domain, errors } }) => {
        if (errors.length > 0) {
          createNotifier(errors.map((e: QqlError) => e.message).join(', '), NotificationType.ERROR)
        } else if (domain) {
          const { frbEnabled, name, pgEnabled, qwEnabled, reviveEnabled, vbEnabled } = domain

          createNotifier(t(`domains.productToggle.update.success.${product}`, { name }), NotificationType.SUCCESS)

          switch (product) {
            case Product.FillRateBooster: {
              setStatus(frbEnabled)
              return
            }
            case Product.PriceGenius: {
              setStatus(pgEnabled)
              return
            }
            case Product.Quickwrap: {
              setStatus(qwEnabled)
              return
            }
            case Product.Revive: {
              setStatus(reviveEnabled)
              return
            }
            case Product.ViewabilityBooster: {
              setStatus(vbEnabled)
              return
            }
          }
        }
      }
    }
  )

  const handleChange = (product: Product) =>
    void updateStatus(
      nestGqlInput({
        domainId,
        [fieldToUpdate(product)]: !initialStatus
      })
    )

  const fieldToUpdate = (product: Product): keyof Domain => {
    switch (product) {
      case Product.FillRateBooster:
        return 'frbEnabled'
      case Product.PriceGenius:
        return 'pgEnabled'
      case Product.Quickwrap:
        return 'qwEnabled'
      case Product.Revive:
        return 'reviveEnabled'
      case Product.ViewabilityBooster:
        return 'vbEnabled'
      default:
        throw Error(`Unsupported product: ${product}`)
    }
  }

  const TOGGLE_KEY = `domain-${domainId}-${product}-toggle`

  return (
    <div className='DomainProductToggle' onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        checked={status}
        disabled={!(isProductActive(product) || isSuperuser)}
        id={TOGGLE_KEY}
        key={TOGGLE_KEY}
        name={TOGGLE_KEY}
        onChange={() => handleChange(product)}
      />
    </div>
  )
}
