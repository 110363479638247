import { TCF_TIMEOUT_DEFAULT, TCF_TYPE_DEFAULT, WAIT_FOR_TCF_DEFAULT } from 'constants/ConsentManagement'

export const OSSDomainConsentManagementFactory = (
  domain: Domain,
  consentManagement?: OSSConsentManagement
): OSSConsentManagementFormData => {
  return {
    domainId: domain.id,
    ossConsentManagementId: consentManagement?.id,
    tcfTimeout: String(consentManagement?.tcfTimeout ?? TCF_TIMEOUT_DEFAULT),
    type: consentManagement?.type ?? TCF_TYPE_DEFAULT,
    waitForTcf: consentManagement?.waitForTcf || WAIT_FOR_TCF_DEFAULT
  }
}
