import React from 'react'
import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { CroppedCell } from 'components/Table/CroppedCell'
import { HistoryRowData } from './HistoryRowData'
import { ToggleCell } from 'components/Table/ToggleCell'

export const columnsConfig: Array<Column<HistoryRowData>> = [
  {
    Header: () => i18n.t('history:list.tableHeader.changedElement'),
    accessor: 'name',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('history:list.tableHeader.user'),
    accessor: 'userName',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('history:list.tableHeader.product'),
    accessor: 'product',
    Cell: ({ value }: CellProps<HistoryRowData>) => (value ? i18n.t(`products:product.${value}`) : ''),
    disableSortBy: false
  },
  {
    Header: () => i18n.t('history:list.tableHeader.lastModified'),
    accessor: 'createdAt',
    disableSortBy: false
  },
  {
    Header: () => i18n.t('history:list.tableHeader.amountOfChanges'),
    accessor: 'amountOfChanges',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('history:list.tableHeader.field'),
    accessor: 'fieldName',
    Cell: ({ value }) => <CroppedCell textElements={value || ''} stopPropagation={false} />,
    disableSortBy: true
  },
  {
    Header: () => i18n.t('history:list.tableHeader.previousValue'),
    accessor: 'previousValue',
    Cell: ({ value }) => <CroppedCell textElements={value || ''} stopPropagation={false} />,
    disableSortBy: true
  },
  {
    Header: () => i18n.t('history:list.tableHeader.currentValue'),
    accessor: 'currentValue',
    Cell: ({ value }) => <CroppedCell textElements={value || ''} stopPropagation={false} />,
    disableSortBy: true
  },
  {
    accessor: 'subRows',
    Cell: ({ row }) => <ToggleCell row={row} handleToggling={false} />,
    disableSortBy: true
  }
]
