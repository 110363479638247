import React, { useState } from 'react'
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { CollectionInputContainer } from 'components/FormikComponents'
import { ConfirmationModal } from 'components/Modals'

type CallableChild = ({ index }: { index: number }) => JSX.Element

interface Props {
  addNextDisabled?: boolean
  addNextHidden?: boolean
  children: [CallableChild, React.ReactElement?] | CallableChild
  collectionName?: string
  confirmRemoval?: boolean
  disabled?: boolean
  emptyElementTemplate: { [key: string]: any }
  name: string
  onChangeHandler?: (flag: boolean) => void
  onRemoveHandler?: (index: number) => void
  removeHidden?: boolean
}

export const CollectionField: React.FC<Props> = ({
  addNextDisabled,
  addNextHidden,
  children,
  collectionName,
  confirmRemoval,
  disabled,
  emptyElementTemplate,
  name,
  onChangeHandler,
  onRemoveHandler,
  removeHidden
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<{ [key: string]: any }>()
  const [keyCounter, setKeyCounter] = useState(1)

  const onDeleteHandler = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index)
    setKeyCounter((keyCounter) => keyCounter + 1)
    onRemoveHandler && onRemoveHandler(index)
    onChangeHandler && onChangeHandler(true)
  }

  const [deleteModalCallback, setDeleteModalCallback] = useState<null | (() => void)>(null)

  const onDelete = (arrayHelpers: ArrayHelpers, index: number) => {
    if (confirmRemoval) {
      setDeleteModalCallback(() => () => {
        setDeleteModalCallback(null)
        onDeleteHandler(arrayHelpers, index)
      })
    } else {
      onDeleteHandler(arrayHelpers, index)
    }
  }

  const onAddHandler = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push(Object.assign({}, emptyElementTemplate, { isNew: true }))
    onChangeHandler && onChangeHandler(true)
  }

  const collection = Array.isArray(children) ? children[0] : children
  const optional = Array.isArray(children) && children[1]

  return (
    <div className='collection-field'>
      <FieldArray
        name={name}
        render={(arrayHelpers: ArrayHelpers) => (
          <>
            {values[name].map((element: any, index: number) => (
              <CollectionInputContainer
                collectionName={collectionName}
                disabled={disabled}
                isFirstElement={index === 0}
                isNew={element.isNew}
                key={keyCounter * (index + 1)}
                onDeleteHandler={() => onDelete(arrayHelpers, index)}
                removeHidden={removeHidden}
              >
                {(collection as CallableChild)({ index })}
              </CollectionInputContainer>
            ))}

            {confirmRemoval && (
              <ConfirmationModal
                cancelButtonText={t('buttons.cancel')}
                confirmButtonText={t('buttons.delete')}
                content={t('setup:bidders.form.deleteBidderModal.content')}
                onConfirmHandler={deleteModalCallback as () => void}
                setVisible={() => setDeleteModalCallback(null)}
                visible={Boolean(deleteModalCallback)}
              />
            )}

            {optional}

            {!addNextHidden && !disabled && (
              <div className='collection-field__add-btn'>
                <Button
                  buttonType='button'
                  disabled={addNextDisabled}
                  onClickHandler={() => onAddHandler(arrayHelpers)}
                  theme={ButtonTheme.Blue}
                  type={ButtonType.Primary}
                >
                  {values[name].length > 0 ? t('buttons.addNext') : t('buttons.addNew')}
                </Button>
              </div>
            )}
          </>
        )}
      />
    </div>
  )
}
