import { BidderPriorityTypes } from 'webapp/constants/BidderPriorityTypes'
import { BidderSettingsForm } from 'components/sharedInterfaces/BidderSettingsForm'

export const createSectionInitialValues = {
  shouldCreateOnly: true,
  bidders: []
} as BidderSettingsForm

export const createSectionNewBidderFactory = (bidderId: string) => ({
  bidderId: { value: bidderId },
  priority: { value: BidderPriorityTypes.primary }
})
