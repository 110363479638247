import React from 'react'

import {
  ConnectReportPage,
  ReportFormPage,
  ReportRedirectPage,
  ReportResultsPage
} from 'pages/quickwrap/Performance/ReportsPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { routesBuilder } from 'utilities/routesBuilder'

export const ReportRoutes = [
  <LayoutRoute
    key='reports-results'
    path={routesBuilder.quickwrap.performance.reportingResults}
    component={ReportResultsPage}
  />,
  <LayoutRoute key='reports-new' path={routesBuilder.quickwrap.performance.reportingNew} component={ReportFormPage} />,
  <LayoutRoute key='reports-callback' path={routesBuilder.oauth.report} component={ReportRedirectPage} />,
  <LayoutRoute key='reports' path={routesBuilder.quickwrap.performance.reporting} exact component={ConnectReportPage} />
]
