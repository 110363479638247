import React from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/Form'

import glassImg from 'images/magnifying-glass.svg'

type DynamicListInputFilterType = {
  clearHandler: () => void
  filterHandler: (filter: string) => void
  placeholder: string
}

export const DynamicListInputFilter = ({
  clearHandler,
  filterHandler,
  placeholder
}: DynamicListInputFilterType): JSX.Element => {
  const { t } = useTranslation()

  const onFilterChangeHandler = (input: string) => {
    filterHandler(input)
  }

  return (
    <div className='DynamicListInput__filter'>
      <img className='' src={glassImg} />
      <Input
        id='DynamicListInputFilter'
        name='dynamicListInputFilter'
        className='DynamicListInput__filterInput'
        containerClassName='DynamicListInput__filterInputContainer'
        type='text'
        onChangeHandler={onFilterChangeHandler}
        placeholder={placeholder}
      />
      <span className='DynamicListInput__filterClear' onClick={clearHandler}>
        {t('common:buttons.clearAll').toLocaleLowerCase()}
      </span>
    </div>
  )
}
