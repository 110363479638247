import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { ProductManagementPage, ProductsPage } from 'pages/oss/Products'
import { Product } from 'webapp/constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'

export const ProductRoutes = [
  <LayoutRoute
    key='product-management'
    product={Product.OSS}
    path={routesBuilder.oss.products.management}
    exact
    component={ProductManagementPage}
    userPermissionRequired
  />,
  <LayoutRoute
    key='products'
    product={Product.OSS}
    path={routesBuilder.oss.products.root}
    exact
    component={ProductsPage}
    userPermissionRequired
  />
]
