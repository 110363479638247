import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ConfirmationModal } from 'components/Modals'
import { GeneralSettingsForm } from 'components/quickwrap/Setup/GeneralSettings'
import { NotificationType } from 'components/ToastNotifier'
import { SettingsSerializer } from 'serializers/SettingsSerializer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'

import DestroyGeneralSetting from 'gql/mutations/settings/DestroyGeneralSetting.gql'
import UpdateGeneralSetting from 'gql/mutations/settings/UpdateGeneralSetting.gql'

interface Props {
  settings: GeneralSetting
  setSettings: (settings: GeneralSetting | undefined) => void
  setIsSettingsForm: (isSettingsForm: boolean) => void
  custom?: boolean
}

export const UpdateForm: React.FC<Props> = ({ settings, setSettings, setIsSettingsForm, custom = false }) => {
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const { domainVersionId } = useParams<{ domainVersionId: string }>()

  const [updateGeneralSetting, { loading: mutationLoading }] = useMutation(UpdateGeneralSetting, {
    onCompleted: ({ updateGeneralSetting: { generalSetting, errors } }) => {
      if (generalSetting) {
        setSettings(generalSetting)
        createNotifier(t('customSettings.edit.successMessage'), NotificationType.SUCCESS)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const updateSettingHandler = (settings: GeneralSettingForm) => {
    const serializer = new SettingsSerializer(settings, domainVersionId)
    updateGeneralSetting(nestGqlInput(custom ? serializer.updateCustomParams() : serializer.updateParams()))
  }

  const [destroyGeneralSetting] = useMutation(DestroyGeneralSetting, {
    onCompleted: ({ destroyGeneralSetting: { generalSetting, error } }) => {
      if (generalSetting) {
        setVisibleDeleteModal(false)
        setSettings(undefined)
        setIsSettingsForm(false)
        createNotifier(t('customSettings.modal.notification.success'), NotificationType.SUCCESS)
      } else if (error) {
        setVisibleDeleteModal(false)
        createNotifier(t('customSettings.modal.notification.error'), NotificationType.ERROR)
      }
    }
  })

  const defaultHandler = () => setVisibleDeleteModal(true)
  const confirmHandler = () => destroyGeneralSetting({ variables: { input: { domainVersionId: domainVersionId } } })

  return (
    <>
      <GeneralSettingsForm
        isSubmitting={mutationLoading}
        onSubmit={updateSettingHandler}
        settings={settings}
        errors={errors}
        onDefaultHandler={defaultHandler}
      />
      <ConfirmationModal
        visible={visibleDeleteModal}
        setVisible={setVisibleDeleteModal}
        content={t('customSettings.modal.content')}
        confirmButtonText={t('customSettings.modal.confirm')}
        cancelButtonText={t('common:buttons.cancel')}
        onConfirmHandler={confirmHandler}
      />
    </>
  )
}
