import React from 'react'
import { CellProps, Column } from 'react-table'

import i18n from 'webapp/i18n'
import { ActionsCell } from './ActionsCell'
import { DateCell } from 'components/Table'
import { LinkCell } from 'components/Table/LinkCell'

export const columnsConfig: Column<Required<Workspace>>[] = [
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.workspace'),
    accessor: 'name'
  },
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.link'),
    accessor: 'dataStudioUrl',
    Cell: ({ value }) => <LinkCell href={value} />
  },
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.updatedBy'),
    accessor: 'urlUpdatedBy',
    Cell: ({ value }) => value.fullName
  },
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.updatedAt'),
    accessor: 'urlUpdatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    id: 'actions',
    Header: () => i18n.t('Actions'),
    Cell: ({ row }: CellProps<Workspace>) => <ActionsCell row={row} />,
    disableSortBy: true
  }
]
