import React from 'react'

import { useTranslation } from 'react-i18next'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import NameFilter from 'components/NameFilter'
import { DomainsTable } from 'components/quickwrap/Inventory/Domains/DomainsTable'
import { Product } from 'constants/Product'
import { useHistory } from 'react-router-dom'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { Can } from 'webapp/context/AbilityContext'
import { useFilterConfig } from './useFilterConfig'

const COLLECTION = CollectionNames.Domains

export const DomainsContainer: React.FC = () => {
  const { t } = useTranslation('inventory')
  const history = useHistory()
  const filterConfig = useFilterConfig()

  const redirectToAdd = (): void =>
    history.push(routesBuilder.oss.inventory.domains.new, { fromProduct: Product.Quickwrap })

  return (
    <Box className='domains' list={true}>
      <BoxHeader
        title={t('domains.title')}
        containerClassName={'box-header__container--space-between'}
        rowClassName={'header__row--width-100'}
      >
        <Can I={AbilityAction.ADD} a={AbilitySubject.DOMAIN}>
          <Button onClickHandler={redirectToAdd} theme={ButtonTheme.Orange} type={ButtonType.Primary}>
            {t('common:buttons.add')}
          </Button>
        </Can>

        <div className='box-header__filters'>
          <AdvancedFilter alignPopup='right' collectionName={COLLECTION} config={filterConfig} />

          <NameFilter collection={COLLECTION} operator={'contains'} path='name' />
        </div>
      </BoxHeader>

      <DomainsTable />
    </Box>
  )
}
