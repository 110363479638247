export class ABTestRowData {
  abTest: ABTest

  constructor(abTest: ABTest) {
    this.abTest = abTest
  }

  get id(): string {
    return this.abTest.id
  }

  get name(): string {
    return this.abTest.name
  }

  get domainName(): string {
    return this.abTest.domain.name
  }

  get active(): boolean {
    return this.abTest.active
  }

  get updatedAt(): Date {
    return this.abTest.updatedAt
  }
}
