import React, { useContext, useMemo } from 'react'

import { Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { UserCard } from 'components/oss/Users/UserCard'
import { WorkspaceFactory } from 'components/oss/Workspaces/Form/formSchema'
import {
  FormikInputContainer as FormikInput,
  FormikSelectContainer as FormikSelect,
  FormikToggleContainer
} from 'containers/FormikContainers'
import { Errors } from 'utilities/errorUtils'
import { UserContext } from 'webapp/context/UserContext'

interface Props {
  csManagers?: User[]
  errors: Errors
  isSubmitting: boolean
  onSubmit: (workspace: WorkspaceFormData) => void
  workspace?: Workspace
}

export const WorkspaceForm: React.FC<Props> = ({ csManagers, errors, isSubmitting, onSubmit, workspace }) => {
  const { t } = useTranslation('workspaces')
  const { isSuperuser } = useContext(UserContext)

  const isAdNetworkEnabled = workspace?.adNetworkEnabled

  const csManagerOptions = useMemo(
    () => csManagers?.map(({ email, fullName, id }) => ({ label: fullName || email, value: id })) ?? [],
    [csManagers]
  )

  return (
    <div className='workspace-form qw-form'>
      <Formik initialValues={WorkspaceFactory(workspace)} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty }) => (
          <StatusForm name='oss-workspace' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={3} xs={6}>
                <Field
                  id='workspace-name'
                  name='name'
                  type='text'
                  label={t('mainForm.workspace.label')}
                  tooltipContent={t('mainForm.workspace.tooltip')}
                  component={FormikInput}
                  errors={errors.errorsFor('name')}
                  labelStyle='bold'
                />

                {isSuperuser && (
                  <>
                    <Field
                      id='crm-id'
                      name='crmId'
                      type='text'
                      label={t('mainForm.crmId.label')}
                      tooltipContent={t('mainForm.crmId.tooltip')}
                      component={FormikInput}
                      errors={errors.errorsFor('crmId')}
                      labelStyle='bold'
                    />

                    <Field
                      component={FormikSelect}
                      errors={errors.errorsFor('csManagerId')}
                      id='csManagerId'
                      isClearable={true}
                      label={t('mainForm.csManagerId.label')}
                      name='csManagerId'
                      options={csManagerOptions}
                      placeholder={t('mainForm.csManagerId.placeholder')}
                    />

                    <Field
                      component={FormikToggleContainer}
                      errors={errors.errorsFor('pgGlobalTargetingEnabled')}
                      id='pgGlobalTargetingEnabled'
                      label={t('mainForm.pgGlobalTargetingEnabled.label')}
                      name='pgGlobalTargetingEnabled'
                    />
                  </>
                )}

                {isAdNetworkEnabled && (
                  <Field
                    id='amazonAsi'
                    name='amazonAsi'
                    type='text'
                    label={t('mainForm.amazonAsi.label')}
                    tooltipContent={t('mainForm.amazonAsi.tooltip')}
                    component={FormikInput}
                    errors={errors.errorsFor('amazonAsi')}
                    labelStyle='bold'
                  />
                )}

                <Button
                  buttonType='submit'
                  className='qw-submit'
                  disabled={isSubmitting || !dirty}
                  theme={ButtonTheme.Blue}
                  type={ButtonType.Primary}
                >
                  {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                </Button>

                {!isSuperuser && workspace?.csManager && (
                  <UserCard
                    className={'workspace-form__cs-manager'}
                    heading={t('mainForm.csManagerCard.heading')}
                    user={workspace.csManager}
                  />
                )}
              </Grid>
            </Grid>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
