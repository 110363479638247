import React from 'react'

import { AdBlockDetector } from 'components/AdBlockDetector'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Container } from 'components/Container'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { ImpersonalizationBar } from 'components/Layout/ImpersonalizationBar'
import { ProductStatusBar } from 'components/Layout/ProductStatusBar'
import { ProductWelcomeModal } from 'components/Layout/ProductWelcomeModal'
import { ProductStatus } from 'constants/ProductStatus'
import { Sidebar } from 'containers/Sidebar'
import { useCurrentProductConnection } from 'utilities/products/current'
import { Product } from 'webapp/constants/Product'

interface Props {
  product: Product
  withSidebar?: boolean
}

export const Layout: React.FC<Props> = ({ children, product, withSidebar = true }) => {
  const isInSetup: boolean = useCurrentProductConnection()?.status === ProductStatus.InSetup

  if (isInSetup) withSidebar = false

  return (
    <div className={`layout-main layout-${product}`}>
      {withSidebar && <Sidebar product={product} />}
      <Header product={product} />

      {!isInSetup && (
        <div className={`layout-panel${withSidebar ? ' layout-panel--with-sidebar' : ''}`}>
          <Container withSidebar={withSidebar}>
            <ProductStatusBar />
            <AdBlockDetector />
            <ImpersonalizationBar />
            <Breadcrumbs />
            {children}
          </Container>

          <Footer />
        </div>
      )}

      <ProductWelcomeModal />
    </div>
  )
}
