import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { GeneralSettingsForm } from 'components/quickwrap/Setup/GeneralSettings'
import { NotificationType } from 'components/ToastNotifier'
import { SettingsSerializer } from 'serializers/SettingsSerializer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'

import CreateGeneralSetting from 'gql/mutations/settings/CreateGeneralSetting.gql'

interface Props {
  settings: GeneralSetting
  setSettings: (settings: GeneralSetting | undefined) => void
  setIsSettingsForm: (isSettingsForm: boolean) => void
  custom?: boolean
}

export const CreateForm: React.FC<Props> = ({ settings, setSettings, setIsSettingsForm, custom = false }) => {
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const { domainVersionId } = useParams<{ domainVersionId: string }>()

  const [createGeneralSetting, { loading: mutationLoading }] = useMutation(CreateGeneralSetting, {
    onCompleted: ({ createGeneralSetting: { generalSetting, errors } }) => {
      if (generalSetting) {
        setSettings(generalSetting)
        createNotifier(t('customSettings.new.successMessage'), NotificationType.SUCCESS)
      } else {
        setErrors(new ErrorUtils(errors))
      }
    }
  })

  const createSettingHandler = (settings: GeneralSettingForm) => {
    const serializer = new SettingsSerializer(settings, domainVersionId)
    createGeneralSetting(nestGqlInput(custom ? serializer.createCustomParams() : serializer.createParams()))
  }

  const defaultHandler = () => {
    setSettings(undefined)
    setIsSettingsForm(false)
  }

  return (
    <>
      <GeneralSettingsForm
        isSubmitting={mutationLoading}
        onSubmit={createSettingHandler}
        settings={settings}
        errors={errors}
        onDefaultHandler={defaultHandler}
      />
    </>
  )
}
