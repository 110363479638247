import { FormikValues } from 'formik'

import { unique } from 'utilities/arrays'

export class FormSchema {
  public bidders: BidderConnection[]
  public uniqueAdUnits: AdUnitConnection[]
  public initialValues: Record<string, boolean> = {}
  public configured: Record<string, boolean> = {}
  public connections: Record<string, SelectOption> = {}

  static readonly modes: SelectOption[] = [
    { label: 'C2S', value: 'client' },
    { label: 'S2S', value: 'server' },
    { label: 'Hyb', value: 'client_server' }
  ]

  constructor({ adUnits, bidderSettings }: InventoryConnections) {
    adUnits.forEach((adUnit) => {
      const id = FormSchema.getFormId(adUnit.bidderSettingId, adUnit.adUnitId)
      this.initialValues[id + 'c2s'] = adUnit.client.enabled
      this.initialValues[id + 's2s'] = adUnit.server.enabled
      this.configured[id + 'c2s'] = adUnit.client.configured
      this.configured[id + 's2s'] = adUnit.server.configured
    })
    bidderSettings.forEach((bidder) => {
      this.initialValues[bidder.id] = bidder.enabled
      this.connections[bidder.id + 'conn'] = { label: FormSchema.getConnection(bidder.mode).label, value: bidder.mode }
    })

    this.bidders = bidderSettings
    this.uniqueAdUnits = unique(adUnits, (adUnit) => adUnit.adUnitId)
  }

  static getFormId(bidderSettingId: string, rowId: string): string {
    return `${bidderSettingId}-${rowId}`
  }

  static getBidderAndAdUnitIds(formId: string): string[] {
    return formId.split('-')
  }

  static getConnection(bidderSettingMode: string): SelectOption {
    return FormSchema.modes.find((mode) => mode.value === bidderSettingMode) || FormSchema.modes[0]
  }

  getSubmitForm(values: FormikValues): InventoryConnectionsForm {
    const bidderSettingAttributes: BidderSettingAttributes[] = []
    const blacklistedAdUnitAttributes: BlacklistedAdUnitAttributes[] = []

    Object.entries(values).forEach(([resourceId, value]) => {
      if (typeof value === 'boolean' && value) return

      const [bidderSettingId, adUnitId] = FormSchema.getBidderAndAdUnitIds(resourceId)
      if (adUnitId) {
        const clearedAdUnitId = FormSchema.clearAdUnitId(adUnitId)
        const mode = adUnitId.match('c2s') ? 'client' : 'server'
        this.findOrCreateAdUnitIds(blacklistedAdUnitAttributes, bidderSettingId, mode).push(clearedAdUnitId)
      } else {
        if (resourceId.match('conn'))
          bidderSettingAttributes.push({ id: resourceId.replace('conn', ''), mode: value.value })
        else bidderSettingAttributes.push({ id: resourceId, blacklisted: true })
      }
    })

    return { blacklistedAdUnitAttributes, bidderSettingAttributes }
  }

  private static clearAdUnitId(adUnitId: string) {
    if (adUnitId.match('c2s')) {
      return adUnitId.replace('c2s', '')
    } else {
      return adUnitId.replace('s2s', '')
    }
  }

  private findOrCreateAdUnitIds(
    adUnitAttributes: BlacklistedAdUnitAttributes[],
    bidderSettingId: string,
    mode: string
  ): string[] {
    return (
      this.findAdUnitIds(adUnitAttributes, bidderSettingId, mode) ||
      FormSchema.createAdUnitIds(adUnitAttributes, bidderSettingId, mode)
    )
  }

  private findAdUnitIds(
    adUnitAttributes: BlacklistedAdUnitAttributes[],
    bidderSettingId: string,
    mode: string
  ): string[] | undefined {
    return adUnitAttributes.find((attrs) => {
      return attrs.bidderSettingId === bidderSettingId && attrs.mode === mode
    })?.adUnitIds
  }

  private static createAdUnitIds(
    adUnitAttributes: BlacklistedAdUnitAttributes[],
    bidderSettingId: string,
    mode: string
  ): string[] {
    const createdIds: string[] = []
    adUnitAttributes.push({ adUnitIds: createdIds, bidderSettingId, mode })
    return createdIds
  }
}
