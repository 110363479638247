import React, { useMemo } from 'react'
import classNames from 'classnames'

import CloseIcon from '@material-ui/icons/Close'

interface Props {
  added?: boolean
  disabled?: boolean
  label: string
  onDelete: () => void
  onValidateHandler?: (value: string) => boolean
}

type OnCloseEvent = React.MouseEvent<SVGSVGElement, MouseEvent>

export const Tag: React.FC<Props> = ({ added, disabled, label, onDelete, ...props }) => {
  const classString = useMemo(() => {
    return classNames('tag-input__tag', added ? 'tag-input__tag--added' : 'tag-input__tag--progress')
  }, [added])

  const deleteHandler = (event: OnCloseEvent) => {
    event.preventDefault()
    onDelete()
  }

  return (
    <div className={classString} {...props}>
      {label}
      {!disabled && <CloseIcon onClick={deleteHandler} />}
    </div>
  )
}
