import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { Product } from 'constants/Product'
import ConfigurationContainer from 'pages/revive/Configuration/ConfigurationContainer'
import { ConfigurationEdit } from 'pages/revive/Configuration/ConfigurationEdit/ConfigurationEdit'
import Portal from 'pages/revive/Portal'
import { DataStudioPage } from 'pages/shared/Performance/DataStudio'
import { routesBuilder } from 'utilities/routesBuilder'

export const ReviveRoutes = [
  <LayoutRoute
    component={ConfigurationContainer}
    exact
    key='revive-configuration'
    path={routesBuilder.revive.configuration.root}
    product={Product.Revive}
  />,

  <LayoutRoute
    component={ConfigurationEdit}
    key='revive-configuration-edit'
    path={routesBuilder.revive.configuration.edit()}
    product={Product.Revive}
  />,

  <LayoutRoute
    component={DataStudioPage}
    key='revive-performance-data-studio'
    path={routesBuilder.revive.performance.dataStudio}
    product={Product.Revive}
    userPermissionRequired
  />,

  <LayoutRoute
    component={Portal}
    exact
    key='revive-portal'
    path={routesBuilder.revive.portal.root}
    product={Product.Revive}
  />
]
