import React, { useContext, useEffect, useState } from 'react'
import { PropsValue } from 'react-select'

import { DomainVersionSelectOption } from 'components/shared/Inventory/Domains/DomainVersionSelect/DomainVersionOptionsFactory'
import { Select } from 'components/Form'
import { VersionedDomainContext } from 'webapp/context/VersionedDomainContext'

interface Props {
  disabled?: boolean
  initialVersionId?: string
  label?: string
  onChange?: (newVersionId: string) => void
  onChangeManaged?: (newVersionId: string) => void
}

export const DomainVersionSelect: React.FC<Props> = ({
  disabled = false,
  initialVersionId,
  label,
  onChange,
  onChangeManaged
}) => {
  const { domainVersionSelectOptions: options = [] } = useContext(VersionedDomainContext)
  const [selectedItem, setSelectedItem] = useState<SelectOption | null>(null)

  useEffect(() => {
    setSelectedItem(options?.find((version) => version.value === initialVersionId) || null)
  }, [initialVersionId, options])

  const onChangeHandler = (option: PropsValue<SelectOption>) => {
    if (!(option && 'value' in option)) return

    const newVersionId = option.value
    if (newVersionId !== initialVersionId) {
      if (onChangeManaged) {
        onChangeManaged(String(newVersionId))
      } else {
        setSelectedItem(option as DomainVersionSelectOption)
        onChange?.(String(newVersionId))
      }
    }
  }

  return (
    <div className='domain-version-select'>
      {options.length > 0 && (
        <Select
          id='domain-version-select'
          name='domain-version-select'
          options={options}
          onChangeHandler={onChangeHandler}
          label={label}
          labelStyle='bold'
          selectedItem={selectedItem}
          isSearchable={false}
          disabled={disabled}
        />
      )}
    </div>
  )
}
