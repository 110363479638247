import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { PortalPage } from 'pages/oss/PortalPage'
import { Product } from 'webapp/constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'

export const PortalRoutes = [
  <LayoutRoute
    component={PortalPage}
    exact
    key='download-tags'
    path={routesBuilder.oss.portal.root}
    product={Product.OSS}
  />
]
