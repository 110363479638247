import React, { useRef } from 'react'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Upload } from 'icons/Upload'

type FileInputEvent = React.ChangeEvent<HTMLInputElement>

interface Props {
  label: string
  onSubmitHandler: (file: File) => void
}

const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const UploadFileButton: React.FC<Props> = ({ label, onSubmitHandler }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleChange = async (event: FileInputEvent) => {
    const files: FileList = event?.target?.files || new FileList()
    onSubmitHandler(files[0])
    event.target.value = ''
  }

  const handleClick = () => fileInputRef?.current?.click()

  return (
    <>
      <input
        type='file'
        name='file'
        id='file'
        className='button--file-input'
        ref={fileInputRef}
        onChange={handleChange}
        accept={FILE_TYPE}
      />

      <Button type={ButtonType.Secondary} theme={ButtonTheme.Blue} onClickHandler={handleClick}>
        <Upload />
        <span>{label}</span>
      </Button>
    </>
  )
}
