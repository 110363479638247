import { gql } from '@apollo/client'

export const OSS_AD_UNITS_FOR_PAGE_CONNECTING_TABLE = gql`
  query ossAdUnitsForPageConnectingTable($domainId: ID) {
    ossAdUnits(domainId: $domainId) {
      nodes {
        adUnitName
        adUnitPath
        id
      }
    }
  }
`
