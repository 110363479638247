import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ExternalUrls } from 'utilities/externalUrls'
import { LoginForm } from 'components/Auth/LoginForm'

export const LoginContainer: React.FC = () => {
  const { t } = useTranslation('auth')

  return (
    <Grid container spacing={4} className='login'>
      <Grid item xl={6} lg={7} xs={8}>
        <header className='login__header'>
          <h2 className='user-flow__title'>{t('login.title')}</h2>
          <span className='user-flow__subtitle'>
            {t('login.subtitle.description')}
            <a href={ExternalUrls.yieldBirdQuickWrap} className='user-flow__subtitle--link'>
              {t('login.subtitle.link')}
            </a>
          </span>
        </header>

        <LoginForm />
      </Grid>
    </Grid>
  )
}
