import React from 'react'

import { ButtonTheme } from 'components/Button'
import { AdvancedFilterBy } from './AdvancedFilterBy'
import { AdvancedFilterOperator } from './AdvancedFilterOperator'
import { AdvancedFilterValue } from './AdvancedFilterValue/AdvancedFilterValue'
import { RemoveFilterButton } from './RemoveFilterButton'

interface Props {
  cache: Record<string, unknown>
  config: AdvancedFilterConfiguration[]
  filter: AdvancedFilter
  filters: AdvancedFilter[]
  removeFilter: (id: string) => void
  setCache: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  theme: ButtonTheme
  updateFilter: (id: string, newFilter: Partial<AdvancedFilter>) => void
}

export const AdvancedFilterRow: React.FC<Props> = ({
  cache,
  config,
  filter,
  filters,
  removeFilter,
  setCache,
  theme = ButtonTheme.Blue,
  updateFilter
}) => {
  const filterConfig = config.find((filterConfig) => filterConfig.name === filter.by)

  return (
    <div className='advanced-filter__row'>
      <RemoveFilterButton removeFilter={() => removeFilter(filter.id)} theme={theme} />

      <AdvancedFilterBy filter={filter} filters={filters} config={config} updateFilter={updateFilter} />
      {filter.by && (
        <>
          <AdvancedFilterOperator filter={filter} filterConfig={filterConfig} updateFilter={updateFilter} />

          <AdvancedFilterValue
            filter={filter}
            filterConfig={filterConfig}
            cache={cache}
            setCache={setCache}
            updateFilter={updateFilter}
          />
        </>
      )}
    </div>
  )
}
