import React, { useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ABTestForm } from 'components/quickwrap/ABTesting/Form'
import { ABTestSerializer } from 'serializers/ABTestSerializer'
import { AbTestFactory } from 'components/quickwrap/ABTesting/Form/formSchema'
import { Box, BoxHeader } from 'components/Box'
import { ConfirmationModal } from 'components/Modals'
import { EndTestModal } from 'components/quickwrap/ABTesting/Form/Modals'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { routesBuilder } from 'utilities/routesBuilder'

import ABTestQuery from 'gql/queries/abTesting/ABTest.gql'
import DomainsQuery from 'gql/queries/domains/DomainsWithVersionsSelect.gql'
import UpdateABTest from 'gql/mutations/abTesting/UpdateABTest.gql'

export const EditPage: React.FC = () => {
  const { t } = useTranslation('abTesting')
  const history = useHistory<History>()

  const [abTest, setAbTest] = useState<ABTest>()
  const [startTestModalVisible, setStartTestModalVisible] = useState(false)
  const [endTestModalVisible, setEndTestModalVisible] = useState(false)
  const { abTestId } = useParams<{ abTestId: string }>()

  const [errors, setErrors] = useState(new ErrorUtils([]))
  const { createNotifier } = useContext(NotificationContext) as Notification

  const { data: domainsData } = useQuery(DomainsQuery, { fetchPolicy: 'network-only' })
  const domains = useMemo(() => {
    return (domainsData?.domains?.nodes ?? []).map((domain: Domain) => domain)
  }, [domainsData])

  useQuery(ABTestQuery, {
    variables: { abTestId },
    fetchPolicy: 'network-only',
    onCompleted: ({ abTest }) => {
      setAbTest(abTest)
    }
  })

  const [updateABTest, { loading }] = useMutation(UpdateABTest, {
    onCompleted: ({ updateABTest: { abTest, errors } }) => {
      if (abTest) {
        formStatus.clear()
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
        setStartTestModalVisible(false)
        setEndTestModalVisible(false)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const updateABTestHandler = (formData: ABTestFormData) => {
    const serializer = new ABTestSerializer(formData, abTest)
    updateABTest(nestGqlInput(serializer.updateParams())).then((result) => {
      const abTest = result.data.updateABTest.abTest
      if (abTest) {
        setAbTest(abTest)
        createNotifier(t('edit.successMessage', { name: abTest.name }), NotificationType.SUCCESS)
      }
    })
  }

  const showStartModalHandler = () => {
    setStartTestModalVisible(true)
  }

  const showEndModalHandler = () => {
    setEndTestModalVisible(true)
  }

  const startTestHandler = () => {
    if (abTest) {
      updateABTest(nestGqlInput(ABTestSerializer.startTestParams(abTest))).then((result) => {
        const abTest = result.data.updateABTest.abTest
        if (abTest) {
          createNotifier(t('edit.testStartedMessage', { name: abTest.name }), NotificationType.SUCCESS)
          history.push(routesBuilder.quickwrap.abTesting.root)
        } else {
          const message = new ErrorUtils(result.data.updateABTest.errors).errorsFor('active')
          createNotifier(t(message), NotificationType.ERROR)
        }
      })
    }
  }

  const endTestHandler = (formData: ABEndTestFormData) => {
    updateABTest(nestGqlInput(ABTestSerializer.endTestParams(formData))).then((result) => {
      const abTest = result.data.updateABTest.abTest
      if (abTest) {
        setAbTest(abTest)
        setEndTestModalVisible(false)
        createNotifier(t('edit.testEndedMessage', { name: abTest.name }), NotificationType.SUCCESS)
      }
    })
  }

  return (
    <>
      {abTest && (
        <Box>
          <BoxHeader title={abTest.active ? t('edit.titleActive') : t('edit.title')} />

          <ABTestForm
            abTest={AbTestFactory(abTest, undefined)}
            domains={domains}
            errors={errors}
            isSubmitting={loading}
            onShowEndModal={showEndModalHandler}
            onShowStartModal={showStartModalHandler}
            onSubmit={updateABTestHandler}
          />

          <ConfirmationModal
            cancelButtonText={t('common:buttons.cancel')}
            confirmButtonText={t('edit.modal.start.confirm')}
            content={t('edit.modal.start.content')}
            isSubmitting={loading}
            onConfirmHandler={startTestHandler}
            setVisible={setStartTestModalVisible}
            title={t('edit.modal.start.title')}
            visible={startTestModalVisible}
          />

          <EndTestModal
            abTest={abTest}
            errors={errors}
            isSubmitting={loading}
            onEndTest={endTestHandler}
            setVisible={setEndTestModalVisible}
            visible={endTestModalVisible}
          />
        </Box>
      )}
    </>
  )
}
