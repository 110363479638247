import React, { useState } from 'react'

import BackgroundImage from 'images/forms/forgotten_password.svg'
import { ResetPasswordContainer } from 'containers/Auth/ResetPasswordContainer'
import { ResetPasswordCompletedContainer } from 'containers/Auth/ResetPasswordCompletedContainer'
import { UserFlowTemplate } from 'components/UserFlowTemplate'

export const ResetPasswordPage: React.FC = () => {
  const [finalStep, setFinalStep] = useState(false)

  return (
    <UserFlowTemplate image={BackgroundImage} view='password-recovery'>
      {finalStep ? <ResetPasswordCompletedContainer /> : <ResetPasswordContainer setFinalStep={setFinalStep} />}
    </UserFlowTemplate>
  )
}
