import { useTranslation } from 'react-i18next'

import { FilterType } from 'webapp/constants/FilterType'

interface Cache {
  domains?: Domain[]
}

export function useAdvancedFilterConfig(): AdvancedFilterConfiguration<Cache>[] {
  const { t } = useTranslation('workspaces')

  const statusOptions = [
    {
      label: t('filter.options.status.active'),
      value: 'true'
    },
    {
      label: t('filter.options.status.inactive'),
      value: 'false'
    }
  ]

  return [
    {
      label: t('filter.by.name'),
      name: 'name',
      operators: ['contains', 'not_contains'],
      type: FilterType.Input
    },
    {
      label: t('filter.by.domainName'),
      name: 'domains.name',
      operators: ['contains', 'not_contains'],
      type: FilterType.Input
    },
    {
      buildValueOptions: () => statusOptions,
      label: t('filter.by.status'),
      name: 'active',
      type: FilterType.Select
    },
    {
      label: t('filter.by.ownerName'),
      name: 'owner.full_name',
      operators: ['contains', 'not_contains'],
      type: FilterType.Input
    }
  ]
}
