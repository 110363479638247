import React from 'react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { bidderSelectFilterOption } from 'utilities/bidderSelectFilterOption'
import { BidderLogo } from 'components/BidderLogo'
import { FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'

interface Props {
  id: string
  bidders: Bidder[]
  name: string
  errors?: Array<string>
  label?: boolean
  disabled?: boolean
  onChangeHandler?: (value: SelectOption) => void
}

export const BiddersSelect: React.FC<Props> = ({ id, bidders, name, errors, label, disabled, onChangeHandler }) => {
  const { t } = useTranslation('setup')

  const options = bidders.map((bidder: Bidder): SelectOption => {
    return {
      value: bidder.id as string,
      label: (
        <BidderLogo
          fileName={bidder.fileName}
          placeholder={bidder.name}
          className='bidders-select-logo'
          bidderName={bidder.name}
        />
      )
    }
  })

  return (
    <Field
      className='bidders-select'
      id={id}
      options={options}
      name={name}
      placeholder={t('bidders.form.bidderId.placeholder')}
      label={label && t('bidders.form.bidderId.label')}
      tooltipContent={label ? t('bidders.form.bidderId.tooltip') : null}
      component={FormikSelect}
      filterOption={bidderSelectFilterOption}
      errors={errors}
      disabled={disabled}
      onChangeHandler={onChangeHandler}
    />
  )
}
