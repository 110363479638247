import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ReportResultsContainer, ReportSummaryContainer } from 'containers/quickwrap/Performance/Reports'
import { useGetAdManagerReport } from 'containers/quickwrap/Performance/Reports/useGetAdManagerReport'

interface ReportResultsLocationState {
  reportData: Array<ReportData>
  reportFormData: ReportFormType
  currencyCode: string
}

export const ReportResultsPage: React.FC = () => {
  const { t } = useTranslation('reports')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const { reportData, reportFormData, currencyCode } = history.location
    .state as Partial<ReportResultsLocationState> as ReportResultsLocationState

  const { isSubmitting, setIsSubmitting, setReportFormData, generateAdManagerReport, isEditReport, setIsEditReport } =
    useGetAdManagerReport(reportFormData.refreshToken, currencyCode)

  const onSubmitHandler = (values: ReportFormValues) => {
    setIsSubmitting(true)
    createNotifier(t('formPage.waitingForReport'), NotificationType.WARNING, 3)

    const reportForm = {
      networkName: values.network.label,
      networkCode: values.network.value,
      timePeriod: values.timePeriod,
      timeGenerated: new Date(),
      refreshToken: reportFormData.refreshToken
    }

    setReportFormData(reportForm)
    const input = {
      refreshToken: reportFormData.refreshToken,
      networkCode: reportFormData.networkCode,
      startDate: values.timePeriod.startDate,
      endDate: values.timePeriod.endDate
    }
    generateAdManagerReport({ variables: { input } })
  }

  return (
    <>
      <ReportSummaryContainer
        reportData={reportData}
        reportFormData={reportFormData}
        currencyCode={currencyCode}
        isSubmitting={isSubmitting}
        onSubmitHandler={onSubmitHandler}
        isEditReport={isEditReport}
        setIsEditReport={setIsEditReport}
      />

      {!isSubmitting && (
        <ReportResultsContainer reportData={reportData} reportFormData={reportFormData} currencyCode={currencyCode} />
      )}
    </>
  )
}
