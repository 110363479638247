import ujs from '@rails/ujs'
ujs.start()

require.context('../images', true)

require('jquery')
require('popper.js')
require('../javascripts/jquery/jqueryScripts.ts')

import WebpackerReact from 'webpacker-react'
import ComponentsExportedToRails from 'javascripts/exported_components'

WebpackerReact.registerComponents(ComponentsExportedToRails)
window.addEventListener('DOMContentLoaded', () => {
  WebpackerReact.mountComponents()
})
