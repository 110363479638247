import React, { useContext, useState } from 'react'
import { Field, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ErrorUtils, Error } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { NotificationType } from 'components/ToastNotifier/enums'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { StatusForm } from 'components/Form'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { routesBuilder } from 'utilities/routesBuilder'

import UpsertNetwork from 'gql/mutations/networks/UpsertNetwork.gql'

type NetworksFormType = {
  network: Network
}

const convertErrorsPath = (errors: Error[]) => {
  if (errors.length > 0) {
    errors[0].path = [errors[0].path[0], errors[0].path[3]]
  }

  return errors
}

export const NetworksForm = ({ network }: NetworksFormType): JSX.Element => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('setup')
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const history = useHistory<History>()

  const [upsertNetwork, { loading }] = useMutation(UpsertNetwork, {
    onCompleted: ({ upsertNetworks: { networks, errors } }) => {
      if (networks) {
        formStatus.clear()
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      }

      setErrors(new ErrorUtils(convertErrorsPath(errors)))
    }
  })

  const onSubmit = (formData: Network) => {
    upsertNetwork(nestGqlInput({ attributes: [formData] })).then((result) => {
      const networks = result.data.upsertNetworks.networks

      if (networks) {
        createNotifier(t(`networks.${formData.id ? 'edit' : 'new'}.successMessage`), NotificationType.SUCCESS)
        formStatus.clear()
        history.push(routesBuilder.quickwrap.setup.networks.root)
      }
    })
  }

  return (
    <div className='qw-form'>
      <Formik initialValues={network} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty }) => {
          return (
            <StatusForm name='networksForm' dirty={dirty}>
              <Grid item md={6} lg={4} xl={3}>
                <Field
                  id='networks-name'
                  name='name'
                  component={FormikInput}
                  errors={errors.errorsFor('name')}
                  label={t('networks.form.inputs.name.label')}
                  type='text'
                  placeholder={t('networks.form.inputs.name.placeholder')}
                  tooltipContent={t('networks.form.inputs.name.tooltip')}
                />
              </Grid>

              <Grid className='mt-4' item md={6} lg={4} xl={3}>
                <Field
                  id='networks-asi'
                  name='asi'
                  component={FormikInput}
                  errors={errors.errorsFor('asi')}
                  label={t('networks.form.inputs.asi.label')}
                  filterPlaceholder={t('networks.form.inputs.asi.placeholder')}
                  title={t('networks.form.inputs.asi.title')}
                  placeholder={t('networks.form.inputs.asi.placeholder')}
                  tooltipContent={t('networks.form.inputs.asi.tooltip')}
                />
              </Grid>

              <Grid item md={6} lg={4} xl={3}>
                <Button
                  buttonType='submit'
                  className='networks-submit mt-4'
                  disabled={!dirty}
                  theme={ButtonTheme.Orange}
                  type={ButtonType.Primary}
                >
                  {loading ? t('common:buttons.saving') : t('common:buttons.save')}
                </Button>
              </Grid>
            </StatusForm>
          )
        }}
      </Formik>
    </div>
  )
}
