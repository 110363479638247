import React, { useEffect, useRef } from 'react'

import { SidebarItemType } from 'components/Sidebar/types'
import { SidebarLink } from 'components/Sidebar/SidebarLink'

interface Props {
  subItem: SidebarItemType
}

export const SidebarSubmenuItem: React.FC<Props> = ({ subItem }) => {
  const menuItemRef = useRef<HTMLLIElement>(null)
  const subMenuRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    const menuItem = menuItemRef.current
    const subMenu = subMenuRef.current
    const sidebarNav = document.querySelector('.sidebar__nav')

    const updateSubmenuPosition = () => {
      if (menuItem && subMenu) {
        subMenu.style.top = menuItem.getBoundingClientRect().top + 'px'
        subMenu.style.left = menuItem.clientWidth + 'px'
      }
    }

    updateSubmenuPosition()
    menuItem?.addEventListener('mouseenter', updateSubmenuPosition)
    sidebarNav?.addEventListener('scroll', updateSubmenuPosition)

    return () => {
      menuItem?.removeEventListener('mouseenter', updateSubmenuPosition)
      sidebarNav?.addEventListener('scroll', updateSubmenuPosition)
    }
  }, [])

  const hasThirdLevelSubMenu = Boolean(subItem.subItems)

  return (
    <li key={subItem.title} className={'sidebar__item'} ref={menuItemRef}>
      <SidebarLink
        title={subItem.title}
        subtitle={subItem.subtitle}
        href={subItem.href}
        hasThirdLevelSubMenu={hasThirdLevelSubMenu}
        absolute={subItem.absolute}
      />

      {subItem.subItems && (
        <ul className={'sidebar__third-level-sub-menu'} ref={subMenuRef}>
          {subItem.subItems
            .filter((subItem) => subItem.visible)
            .map((subItem) => (
              <SidebarLink key={subItem.title} title={subItem.title} href={subItem.href} isThirdLevelSubmenuMember />
            ))}
        </ul>
      )}
    </li>
  )
}
