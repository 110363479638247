import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { EditPage } from 'pages/quickwrap/Inventory/AdUnits/EditPage'
import { AdUnitsListPage } from 'pages/quickwrap/Inventory/AdUnits/ListPage'
import { routesBuilder } from 'utilities/routesBuilder'

export const AdUnitRoutes = [
  <LayoutRoute
    key='ad-units-list'
    exact
    path={routesBuilder.quickwrap.inventory.adUnit.root}
    component={AdUnitsListPage}
  />,
  <LayoutRoute key='ad-units-edit' path={routesBuilder.quickwrap.inventory.adUnit.edit()} component={EditPage} />
]
