import React, { PureComponent } from 'react'
import 'react-dates/initialize'
import { DateRangePicker as DatePicker, isInclusivelyBeforeDay } from 'react-dates'
import moment from 'moment'

import i18n from 'webapp/i18n'
import { CalendarDay } from './CalendarDay'
import { NavigationButton } from './NavigationButton'

type FocusInputType = 'startDate' | 'endDate' | null

interface Props {
  defaultStartDate?: Date
  defaultEndDate?: Date
  getDateRange?: (startDate: Date | null, endDate: Date | null) => void
  disabled?: boolean
}

interface State {
  focusedInput: FocusInputType
  startDate: moment.Moment | null
  endDate: moment.Moment | null
}

export class DateRangePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      startDate: props.defaultStartDate ? moment(props.defaultStartDate) : null,
      endDate: props.defaultEndDate ? moment(props.defaultEndDate) : null,
      focusedInput: null
    }
  }

  dateHandler = (startDate: moment.Moment | null, endDate: moment.Moment | null): void => {
    this.state.focusedInput === 'startDate'
      ? this.setState({ startDate, endDate: null })
      : this.setState({ startDate, endDate })

    const sDate = startDate ? startDate.toDate() : null
    const eDate = endDate ? endDate.toDate() : null
    this.props.getDateRange && this.props.getDateRange(sDate, eDate)
  }

  focusHandler = (focusedInput: FocusInputType): void => this.setState({ focusedInput })

  render() {
    const { disabled } = this.props
    const { startDate, endDate, focusedInput } = this.state

    return (
      <div className='reporting' id='date-range-picker'>
        <DatePicker
          startDate={startDate}
          startDateId='startDate'
          endDate={endDate}
          endDateId='endDate'
          onDatesChange={({ startDate, endDate }) => this.dateHandler(startDate, endDate)}
          focusedInput={focusedInput}
          onFocusChange={this.focusHandler}
          startDatePlaceholderText={i18n.t('reports:calendar.startDate')}
          endDatePlaceholderText={i18n.t('reports:calendar.endDate')}
          displayFormat='DD MMM YYYY'
          daySize={19}
          noBorder
          minimumNights={0}
          hideKeyboardShortcutsPanel={true}
          firstDayOfWeek={1}
          verticalSpacing={10}
          horizontalMonthPadding={4}
          navPrev={<NavigationButton label='<' className='CalendarMonth__nav--prev' />}
          navNext={<NavigationButton label='>' className='CalendarMonth__nav--next' />}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          initialVisibleMonth={() => moment().subtract(1, 'M')}
          renderDayContents={(day) => <CalendarDay day={day.format('D')} />}
          disabled={disabled}
        />
      </div>
    )
  }
}
