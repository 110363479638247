import React, { useContext, useMemo } from 'react'
import { Column, CellProps } from 'react-table'

import { ActionsCell } from './ActionsCell'
import i18n from 'webapp/i18n'
import { RoleCell } from './RoleCell'
import { Role } from 'webapp/constants/Role'
import { StatusCell } from 'components/oss/Users/Table/StatusCell'
import { UserContext } from 'webapp/context/UserContext'

const columnsConfig: Column<User>[] = [
  {
    Header: () => i18n.t('users:list.tableHeader.fullName'),
    accessor: 'fullName'
  },
  {
    Header: () => i18n.t('users:list.tableHeader.role'),
    accessor: 'role',
    Cell: ({ row }) => <RoleCell row={row} />
  },
  {
    Header: () => i18n.t('users:list.tableHeader.status'),
    accessor: 'status',
    Cell: ({ row }) => <StatusCell row={row} />
  },
  {
    Header: () => i18n.t('users:list.tableHeader.email'),
    accessor: 'email'
  }
]

const idColumn: Column<User> = {
  Header: () => i18n.t('users:list.tableHeader.id'),
  accessor: 'id'
}

const workspaceColumn: Column<User> = {
  Header: () => i18n.t('users:list.tableHeader.workspace'),
  accessor: 'workspace',
  Cell: ({ row }) => row.original.workspace?.name ?? ''
}

const actionsColumn: Column<User> = {
  Header: () => i18n.t('table.header.actions'),
  id: 'actions',
  Cell: ({ row }: CellProps<User>) => <ActionsCell row={row} />,
  disableSortBy: true
}

const spacerColumn: Column<User> = {
  id: 'spacer',
  Cell: () => '',
  disableSortBy: true
}

export const useColumnConfig = () => {
  const { user, isSuperuser } = useContext(UserContext)

  return useMemo(() => {
    return [
      isSuperuser && idColumn,
      ...columnsConfig,
      isSuperuser && workspaceColumn,
      (isSuperuser || user?.role === Role.Owner) && actionsColumn,
      !isSuperuser && user?.role !== Role.Owner && spacerColumn
    ].filter(Boolean)
  }, [user?.role])
}
