import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { predefinedSizes } from './predefinedSizes'
import { SizeTag } from './SizeTag'
import { sizeSchema } from './sizeSchema'
import { TagInput } from 'components/Form/TagInput'

interface Props {
  disabled?: boolean
  errors?: Array<string>
  id: string
  label?: string
  name: string
  onChangeHandler: (sizeValues: string[]) => void
  single?: boolean
  tooltipContent?: string
  values: string[]
}

export const SizeInput: React.FC<Props> = ({
  disabled,
  errors,
  id,
  label,
  name,
  onChangeHandler,
  single,
  tooltipContent,
  values
}) => {
  const { t } = useTranslation('inventory')
  const [sizesErrors, setSizesErrors] = useState<string[]>([])

  const onChange = (sizeValues: string[]) => {
    setSizesErrors(
      sizeValues.every((size) => size.match(sizeSchema)) ? [] : [t('adUnits.form.inputs.mediaTypes.errors.size')]
    )
    onChangeHandler(sizeValues)
  }

  return (
    <TagInput
      disabled={disabled}
      errors={(errors || []).concat(sizesErrors)}
      id={id}
      label={label}
      name={name}
      onChangeHandler={onChange}
      options={predefinedSizes}
      single={single}
      TagComponent={SizeTag}
      tooltipContent={tooltipContent}
      values={values}
    />
  )
}
