import React, { useMemo, ReactNode } from 'react'
import classNames from 'classnames'
import AceEditor from 'react-ace'
import 'ace-builds/webpack-resolver'

import { InputContainer } from 'components/Form/InputContainer'

interface Props {
  id: string
  name: string
  className?: string
  children?: ReactNode
  containerClassName?: string
  value?: string
  onChangeHandler?: (value: string) => void
  onBlurHandler?: () => void
  disabled?: boolean
  errors?: Array<string>
  hasErrors?: boolean
  label?: string
  labelStyle?: string
  tooltipContent?: string
  mode?: string
}

export const CodeEditor: React.FC<Props> = ({
  id,
  className,
  children,
  containerClassName = '',
  value,
  onChangeHandler,
  onBlurHandler,
  disabled,
  errors,
  hasErrors,
  label,
  labelStyle,
  tooltipContent,
  mode
}) => {
  const hasError = useMemo(() => Boolean(hasErrors || (errors && errors.length > 0)), [hasErrors, errors])
  const classString = useMemo(() => {
    return classNames('input', className, {
      'input--disabled': disabled,
      'input--error': hasError
    })
  }, [disabled, className, hasError])

  const onChange = (value: string) => {
    if (disabled) return
    onChangeHandler && onChangeHandler(value)
  }

  const onBlur = () => onBlurHandler && onBlurHandler()

  return (
    <InputContainer
      inputId={id}
      label={label}
      labelStyle={labelStyle}
      className={`input__container ${containerClassName}`}
      disabled={disabled}
      tooltipContent={tooltipContent}
      errors={errors}
    >
      <div className='field__container'>
        <AceEditor
          name={id}
          mode={mode || 'json'}
          theme='xcode'
          onChange={onChange}
          className={classString}
          readOnly={disabled}
          value={value}
          onBlur={onBlur}
        />
      </div>
      {children}
    </InputContainer>
  )
}
