import React from 'react'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { LoadingButton } from 'components/Button/LoadingButton'
import { Modal } from 'components/Modals'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
  onSendRequestHandler: (value: string) => void
  title: string
  placeholder?: string
  errorKey: string
  errors: ErrorUtils
  isSubmitting: boolean
}

const RequestModal: React.FC<Props> = ({
  visible,
  setVisible,
  onSendRequestHandler,
  title,
  errorKey,
  errors,
  isSubmitting,
  placeholder
}) => {
  const { t } = useTranslation()
  const cancelHandler = () => setVisible(false)
  const submitHandler = ({ name }: { name: string }) => onSendRequestHandler(name)

  return (
    <Modal visible={visible} setVisible={setVisible} shadow={true} backdrop={true}>
      <div className='confirmation__modal-container' id='request-form'>
        <Formik initialValues={{ name: '' }} onSubmit={submitHandler}>
          <Form>
            <p className='request-new-element__title'>{title}</p>
            <Field
              id='name'
              name='name'
              type='text'
              placeholder={placeholder}
              component={FormikInput}
              errors={errors.errorsFor(errorKey)}
              className='request-new-element__input'
            />

            <footer className='request-new-element__footer'>
              <Button
                theme={ButtonTheme.BlueReverse}
                type={ButtonType.Primary}
                onClickHandler={cancelHandler}
                className='confirmation__modal__button request-new-element__button'
                buttonType='button'
              >
                {t('common:buttons:cancel')}
              </Button>

              <LoadingButton
                theme={ButtonTheme.Blue}
                type={ButtonType.Primary}
                className='confirmation__modal__button request-new-element__button request-new-element__button--submit'
                buttonType='submit'
                loading={isSubmitting}
              >
                {t('common:buttons.sendRequest')}
              </LoadingButton>
            </footer>
          </Form>
        </Formik>
      </div>
    </Modal>
  )
}

export default RequestModal
