import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { ButtonTheme, ButtonType } from 'components/Button'

interface Props {
  to: string | Record<string, unknown>
  type: ButtonType
  theme: ButtonTheme
  className?: string
  tooltipContent?: string
}

export const LinkButton: React.FC<Props> = ({ to, type, theme, className, tooltipContent, children }) => {
  const classString = useMemo(() => {
    return classNames('button', className, `button--${type}`, `button--${theme}`, {
      ['button__tooltip']: tooltipContent ? true : false
    })
  }, [className, type, theme])

  return (
    <Link to={to} className={classString} data-tooltip={tooltipContent}>
      <div className='button__content'>{children}</div>
    </Link>
  )
}
