import React, { useState, useEffect, useCallback } from 'react'

import IconError from 'images/icon-error.svg'
import IconExitGreen from 'images/icon-exit-green.svg'
import IconExitRed from 'images/icon-exit-red.svg'
import IconExitOrange from 'images/icon-exit-orange.svg'
import IconExitGray from 'images/icon-exit-gray.svg'
import IconInfo from 'images/icon-info.svg'
import IconSuccess from 'images/icon-success.svg'
import IconWarning from 'images/icon-warning.svg'

import { ToastNotifierProps, NotifierIcons } from 'components/ToastNotifier/types'
import { NotificationType } from 'components/ToastNotifier/enums'

const DEFAULT_CLOSE_TIME = 5
let toastNotifierTimer: ReturnType<typeof setTimeout>

export const ToastNotifier: React.FC<ToastNotifierProps> = ({ config, onRenderFinished }) => {
  const [show, setShow] = useState<boolean>(true)
  const [shouldRender, setShouldRender] = useState<boolean>(true)

  const getIcons = (): NotifierIcons => {
    switch (config.type) {
      case NotificationType.SUCCESS:
        return { icon: IconSuccess, iconExit: IconExitGreen }
      case NotificationType.ERROR:
        return { icon: IconError, iconExit: IconExitRed }
      case NotificationType.WARNING:
        return { icon: IconWarning, iconExit: IconExitOrange }
      case NotificationType.INFO:
        return { icon: IconInfo, iconExit: IconExitGray }
    }
  }

  const { icon, iconExit } = getIcons()

  const onAnimationEnd = () => {
    if (!show) {
      if (onRenderFinished) onRenderFinished(true)
      setShouldRender(false)
    }
  }

  const setTimer = useCallback(() => {
    toastNotifierTimer = setTimeout(() => {
      setShow(false)
    }, (config.time ?? DEFAULT_CLOSE_TIME) * 1000)
  }, [config.time])

  useEffect(() => {
    if (config.time !== 0) {
      clearTimeout(toastNotifierTimer)
      setTimer()
    }
    return () => clearTimeout(toastNotifierTimer)
  }, [config.message, config.time, setTimer])

  const { type, message } = config

  return shouldRender ? (
    <div
      className={`toast-notifier ${show ? 'toast-notifier--fade-in' : 'toast-notifier--fade-out'}`}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={`toast-notifier__icon-container toast-notifier__icon-container--${type}`}>
        <img className={`toast-notifier__icon toast-notifier__icon--${type}`} src={icon}></img>
      </div>
      <div className={`toast-notifier__message toast-notifier__message--${type}`}>{message}</div>
      <img className={'toast-notifier__icon-exit'} src={iconExit} onClick={() => setShow(false)}></img>
    </div>
  ) : null
}
