export const converter = (params: Params): Params => {
  const result: Params = {}

  Object.keys(params).forEach((name: string) => {
    const value = params[name]

    if (value.toString().length > 0) {
      try {
        switch (typeof result[name]) {
          case 'number':
            result[name] = value
            break
          case 'boolean':
            result[name] = value.toString()
            break
          default:
            result[name] = JSON.parse(value)
        }
      } catch (_) {
        result[name] = value
      }
    }
  })

  return result
}
