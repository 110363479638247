import React, { useContext } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { TabNavigation } from 'components/Box/TabNavigation'
import { ButtonTheme, ButtonType } from 'components/Button'
import { LinkButton } from 'components/LinkButton'
import NameFilter from 'components/NameFilter'
import { UserTable } from 'components/oss/Users/Table'
import { useAdvancedFilterConfig } from 'containers/oss/Users/UsersListContainer/useAdvancedFilterConfig'
import { useUserTabs } from 'containers/oss/Users/UsersListContainer/useUserTabs'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { RoleType } from 'webapp/constants/Role'
import { Can } from 'webapp/context/AbilityContext'
import { UserContext } from 'webapp/context/UserContext'

export const UsersListContainer: React.FC = () => {
  const { t } = useTranslation('users')
  const { isSuperuser } = useContext(UserContext)

  const { currentTab, defaultTab, searchParam, tabs } = useUserTabs()
  const roleType = isSuperuser ? (currentTab as RoleType) : undefined

  const COLLECTION = `${CollectionNames.Users}${roleType}`

  const filterConfig = useAdvancedFilterConfig(roleType)

  return (
    <Box className={classNames('users-list', isSuperuser && 'users-list--superuser')}>
      {isSuperuser && <TabNavigation defaultTab={defaultTab} tabs={tabs} searchParam={searchParam} />}

      <BoxHeader
        title={t('list.title')}
        containerClassName={'box-header__container--space-between'}
        rowClassName={'header__row--width-100'}
      >
        <Can I={AbilityAction.ACCESS} a={AbilitySubject.INVITE_USER_PATH}>
          <LinkButton
            type={ButtonType.Primary}
            theme={ButtonTheme.Blue}
            className='users-list__header-button'
            to={{
              pathname: routesBuilder.oss.users.invite,
              state: { roleType }
            }}
          >
            {t('list.addNew')}
          </LinkButton>
        </Can>

        <div className='box-header__filters'>
          <AdvancedFilter
            alignPopup='right'
            collectionName={COLLECTION}
            config={filterConfig}
            key={`filter-${currentTab}`}
          />

          <NameFilter
            collection={COLLECTION}
            operator={'contains'}
            path={'email'}
            placeholder={t('list.searchByEmail')}
          />
        </div>
      </BoxHeader>

      <UserTable key={currentTab} roleType={roleType} />
    </Box>
  )
}
