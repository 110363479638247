import React, { useContext } from 'react'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { UserContext } from 'webapp/context/UserContext'
import { routesBuilder } from 'utilities/routesBuilder'
import { Tooltip } from 'components/Form'

import Impersonate from 'gql/mutations/impersonalization/Impersonate.gql'

interface Props {
  row: Row<Workspace>
}

export const LoginCell: React.FC<Props> = ({ row }) => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('workspaces')
  const { updateToken } = useContext(UserContext)
  const history = useHistory<History>()

  const owner = row.original.owner

  const [impersonate] = useMutation(Impersonate, {
    onCompleted: ({ impersonate }) => {
      updateToken(impersonate.credentials.accessToken)
      history.push(routesBuilder.oss.products.root)
    },
    onError: ({ graphQLErrors }) => {
      const message = graphQLErrors[0]?.message || t('commons:error.common')
      createNotifier(message, NotificationType.ERROR)
    }
  })

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    impersonate(nestGqlInput({ userId: owner?.id }))
  }

  const ResultButton = (
    <Button
      type={ButtonType.Secondary}
      theme={ButtonTheme.Blue}
      buttonType='button'
      className='login__button'
      onClickHandler={onClickHandler}
      disabled={!owner}
    >
      {t('list.login')}
    </Button>
  )

  return owner ? ResultButton : <Tooltip title={t('list.noOwner')}>{ResultButton}</Tooltip>
}
