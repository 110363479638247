import React from 'react'

import { DomainsPage } from 'pages/quickwrap/Inventory/DomainsPage'
import { EditPage } from 'pages/quickwrap/Inventory/Domains/EditPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { routesBuilder } from 'utilities/routesBuilder'

export const DomainRoutes = [
  <LayoutRoute
    key='domains-list'
    path={routesBuilder.quickwrap.inventory.domains.root}
    exact={true}
    component={DomainsPage}
  />,
  <LayoutRoute key='domains-edit' path={routesBuilder.quickwrap.inventory.domains.edit()} component={EditPage} />
]
