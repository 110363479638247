import React, { useMemo } from 'react'
import { Field, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ABTestVersions } from 'components/quickwrap/ABTesting/Form/Sections'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import type { Errors } from 'utilities/errorUtils'
import { canStartTest } from 'components/quickwrap/ABTesting/Form/formSchema'
import { FormikInputContainer as FormikInput, FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'
import { StatusForm } from 'components/Form'

interface Props {
  abTest: ABTestFormData
  domains: Domain[]
  errors: Errors
  isSubmitting?: boolean
  onShowEndModal?: () => void
  onShowStartModal?: () => void
  onSubmit: (abTest: ABTestFormData) => void
}

export const ABTestForm: React.FC<Props> = ({
  abTest,
  domains,
  errors,
  isSubmitting,
  onShowEndModal,
  onShowStartModal,
  onSubmit
}) => {
  const { t } = useTranslation('abTesting')

  const domainsOptions = useMemo(() => domains.map((domain) => ({ value: domain.id, label: domain.name })), [domains])

  return (
    <div className='ab-test-form'>
      <Formik initialValues={abTest} onSubmit={onSubmit} enableReinitialize={true}>
        {({ values, dirty }) => {
          const isSaveDisabled = isSubmitting || !dirty
          const isStartDisabled = isSubmitting || !values.id || dirty || !canStartTest(values.abTestVersionsAttributes)

          return (
            <StatusForm name='qw-ab-test' dirty={dirty}>
              <Grid item md={6} lg={4} xl={3}>
                <Field
                  id='ab-test-domain-id'
                  name='domainId'
                  component={FormikSelect}
                  disabled={abTest.active}
                  errors={errors.errorsFor('domainId')}
                  label={t('form.inputs.domainId.label')}
                  labelStyle='bold'
                  options={domainsOptions}
                  placeholder={t('form.inputs.domainId.placeholder')}
                  tooltipContent={t('form.inputs.domainId.tooltip')}
                />

                <Field
                  id='ab-test-name'
                  name='name'
                  component={FormikInput}
                  disabled={abTest.active}
                  errors={errors.errorsFor('name')}
                  label={t('form.inputs.name.label')}
                  labelStyle='bold'
                  placeholder={t('form.inputs.name.placeholder')}
                  tooltipContent={t('form.inputs.name.tooltip')}
                  type='text'
                />
              </Grid>

              <ABTestVersions errors={errors} domains={domains} selectedDomainId={values.domainId.value} />

              {abTest.active ? (
                <Button
                  buttonType='button'
                  disabled={isSubmitting}
                  theme={ButtonTheme.Orange}
                  type={ButtonType.Primary}
                  onClickHandler={onShowEndModal}
                >
                  {t('form.endTest')}
                </Button>
              ) : (
                <>
                  <Button
                    buttonType='submit'
                    className='ab-test-form__start'
                    disabled={isSaveDisabled}
                    theme={ButtonTheme.Orange}
                    type={ButtonType.Primary}
                  >
                    {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                  </Button>

                  <Button
                    buttonType='button'
                    disabled={isStartDisabled}
                    theme={ButtonTheme.Orange}
                    type={ButtonType.Primary}
                    onClickHandler={onShowStartModal}
                  >
                    {t('form.startTest')}
                  </Button>
                </>
              )}
            </StatusForm>
          )
        }}
      </Formik>
    </div>
  )
}
