import { BaseSerializer } from './BaseSerializer'
import { GAMConnectorFormType } from 'components/oss/Workspaces/WorkspaceGamConnector/formSchema'

export class GAMConnectionSerializer extends BaseSerializer<GAMConnectorFormType> {
  constructor(data: GAMConnectorFormType, private readonly refreshToken: string) {
    super(data)
    this.refreshToken = refreshToken
  }

  getParams(): CreateGamConnectionInput | UpdateGamConnectionInput {
    return this._prepareBody()
  }

  private _prepareBody(): CreateGamConnectionInput | UpdateGamConnectionInput {
    const { label, value } = this.data.network
    const isNative = Boolean(localStorage.getItem('native'))

    const mainBody = {
      refreshToken: this.refreshToken,
      ...(this.data.creativeTemplateEnabled && { creativeTemplateId: this.data.creativeTemplateId }),
      ...(this.data.native && { nativeCodeTemplateId: this.data.nativeCodeTemplateId })
    }

    if (this.data.id) {
      return {
        gamConnectionId: this.data.id,
        ...(!isNative && { currency: this.data.currency.value as string }),
        ...(!isNative && { action: 'edit' }),
        ...mainBody
      }
    } else {
      return {
        currency: this.data.currency.value as string,
        networkCode: value as string,
        networkName: label as string,
        ...mainBody
      }
    }
  }
}
