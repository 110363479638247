import React from 'react'

interface Props {
  href: string
}

export const LinkCell: React.FC<Props> = ({ href }) => {
  return (
    <span
      className='qw-table__link-cell'
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <a href={href} target='_blank' rel='noreferrer'>
        {href}
      </a>
    </span>
  )
}
