import { WorkspacePackageEnum } from 'utilities/workspacePackageUtils'

const packageNamesMapper: { [key: string]: string } = {
  prebidAnalyticsEnabled: WorkspacePackageEnum.PREBID_MANAGER,
  s2sEnabled: WorkspacePackageEnum.S2S
}

export class WorkspacePackagesSerializer {
  private packages: Array<WorkspacePackage>

  constructor(packages: Array<WorkspacePackage>) {
    this.packages = packages
  }

  updateParams(formValues: Partial<WorkspacePackagesForm>): { packages: Array<WorkspacePackage> } {
    let results: Array<WorkspacePackage> = [...this.packages]
    Object.keys(formValues).forEach((key) => {
      const packageName = packageNamesMapper[key]

      results = results.map((element) => {
        const result = { ...element }
        if (result.name === packageName) {
          result.active = formValues[key]
        }
        return result
      })
    })

    return { packages: results }
  }
}
