import React from 'react'

export const Copy: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19.113' height='19.113' viewBox='0 0 19.113 19.113'>
      <g id='Icon_feather-copy' data-name='Icon feather-copy' transform='translate(-2 -2)'>
        <path
          id='Path_19302'
          data-name='Path 19302'
          d='M15.211,13.5h7.7a1.711,1.711,0,0,1,1.711,1.711v7.7a1.711,1.711,0,0,1-1.711,1.711h-7.7A1.711,1.711,0,0,1,13.5,22.912v-7.7A1.711,1.711,0,0,1,15.211,13.5Z'
          transform='translate(-4.51 -4.51)'
          fill='none'
          stroke='#f7f7f7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_19303'
          data-name='Path 19303'
          d='M5.567,14.124H4.711A1.711,1.711,0,0,1,3,12.412v-7.7A1.711,1.711,0,0,1,4.711,3h7.7a1.711,1.711,0,0,1,1.711,1.711v.856'
          fill='none'
          stroke='#f7f7f7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  )
}

export default Copy
