import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import IconError from 'images/icon-error.svg'

async function sleep(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

function createDetector() {
  const detector = document.createElement('div')
  detector.className = 'ad-unit'
  detector.style.cssText =
    'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;'

  return detector
}

function isDetectorHidden(detectorProperties: CSSStyleDeclaration) {
  return detectorProperties.display === 'none' || detectorProperties.visibility === 'hidden'
}

async function detectAdblock(samples: number, delay: number) {
  const detector = createDetector()
  const computedProperties = getComputedStyle(detector)

  document.body.appendChild(detector)

  try {
    for (let i = 0; i < samples; i++) {
      if (isDetectorHidden(computedProperties)) return true
      await sleep(delay)
    }

    return false
  } finally {
    document.body.removeChild(detector)
  }
}

const SAMPLES = 10
const DELAY_BETWEEN_SAMPLES_IN_MILLISECONDS = 100

export const AdBlockDetector: React.FC = () => {
  const [adblockDetected, setAdblockDetected] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    detectAdblock(SAMPLES, DELAY_BETWEEN_SAMPLES_IN_MILLISECONDS).then(setAdblockDetected)
  }, [])

  if (!adblockDetected) {
    return null
  }

  return (
    <div className='toast-notifier ad-block-detector'>
      <div className='toast-notifier__icon-container toast-notifier__icon-container--error'>
        <img className='toast-notifier__icon toast-notifier__icon--error' src={IconError}></img>
      </div>
      <div className='toast-notifier__message toast-notifier__message--error'>{t('adBlockDetected')}</div>
    </div>
  )
}
