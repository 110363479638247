import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { Image } from 'components/Image'
import classNames from 'classnames'

interface Props {
  className?: string
  path: string
  icon: string
  alt: string
  label: string
}

const UserLink: React.FC<Props> = memo(({ className, path, icon, alt, label }) => {
  return (
    <Link to={path} className={classNames('user-links__link', className)}>
      <Image src={icon} alt={alt} containerClassName='user-links__link-icon' />
      <span className='user-links__link-label'>{label}</span>
    </Link>
  )
})

UserLink.displayName = 'UserLink'

export { UserLink }
