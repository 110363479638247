import React, { useMemo } from 'react'
import { Field, Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { ButtonType, ButtonTheme } from 'components/Button'
import {
  FormikSelectContainer as FormikSelect,
  FormikDateRangePickerContainer as FormikDatePicker
} from 'containers/FormikContainers'
import { LoadingButton } from 'components/Button/LoadingButton'

interface Props {
  availableNetworks: Array<AvailableNetwork>
  isSubmitting: boolean
}

export const ReportForm: React.FC<Props> = ({ availableNetworks, isSubmitting }) => {
  const { t } = useTranslation('reports')
  const { values } = useFormikContext<ReportFormValues>()

  const networkOptions = useMemo(
    () => availableNetworks.map((network) => ({ value: network.networkCode, label: network.displayName })),
    [availableNetworks]
  )

  const shouldBeDisabled = () => {
    const hasAllValues = values.network.value && values.timePeriod.startDate && values.timePeriod.endDate
    return !hasAllValues || isSubmitting
  }

  return (
    <Form>
      <div className='report-form__container'>
        <div className='report-form__field'>
          <Field
            id='network'
            name='network'
            placeholder={t('formPage.network.label')}
            options={networkOptions}
            label={t('formPage.network.label')}
            tooltipContent={t('formPage.network.tooltip')}
            component={FormikSelect}
            disabled={isSubmitting}
          />
        </div>

        <div className='report-form__field'>
          <Field
            id='time-period'
            name='timePeriod'
            label={t('formPage.timePeriod.label')}
            tooltipContent={t('formPage.timePeriod.tooltip')}
            component={FormikDatePicker}
            disabled={isSubmitting}
          />
        </div>
      </div>

      <footer className='report-form__footer'>
        <LoadingButton
          type={ButtonType.Primary}
          theme={ButtonTheme.Orange}
          buttonType='submit'
          loading={isSubmitting}
          disabled={shouldBeDisabled()}
          className='report-form__footer__btn'
        >
          {t('formPage.generateReport')}
        </LoadingButton>
      </footer>
    </Form>
  )
}
