import React, { useEffect, useMemo, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { ABEndTestFactory, getDomainVersionsSortedForTest } from 'components/quickwrap/ABTesting/Form/formSchema'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ConfirmationModal } from 'components/Modals'
import { Errors } from 'utilities/errorUtils'
import { FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'
import { Modal } from 'components/Modals'

interface Props {
  abTest: ABTest
  errors: Errors
  isSubmitting?: boolean
  onEndTest: (formData: ABEndTestFormData) => void
  setVisible: (visible: boolean) => void
  visible: boolean
}

export const EndTestModal: React.FC<Props> = ({ abTest, errors, isSubmitting, onEndTest, setVisible, visible }) => {
  const { t } = useTranslation('abTesting')
  const [isConfirmed, setIsConfirmed] = useState(false)

  useEffect(() => {
    setIsConfirmed(false)
  }, [visible])

  const options = useMemo((): SelectOption<string>[] => {
    return getDomainVersionsSortedForTest(abTest)
  }, [abTest])

  const onConfirm = () => {
    setIsConfirmed(true)
  }

  const onCancel = (event: React.MouseEvent) => {
    event.preventDefault()
    setVisible(false)
  }

  return isConfirmed ? (
    <Modal
      visible={visible}
      setVisible={setVisible}
      shadow={true}
      backdrop={true}
      title={t('edit.modal.end.plug.title')}
    >
      <div className='confirmation__modal-container'>
        <Formik initialValues={ABEndTestFactory(abTest)} onSubmit={onEndTest} enableReinitialize={true}>
          {({ values }) => (
            <Form>
              <p>{t('edit.modal.end.plug.content')}</p>
              <Field
                className='end-test-modal__domain_version'
                component={FormikSelect}
                errors={errors.errorsFor('activateDomainVersionId')}
                id='activate-domain-version-id'
                isSearchable={false}
                labelStyle='bold'
                name='activateDomainVersionId'
                options={options}
                placeholder={t('edit.modal.end.plug.versionPlaceholder')}
              />

              <footer>
                <Button
                  buttonType='button'
                  className='confirmation__modal__button'
                  onClickHandler={onCancel}
                  theme={ButtonTheme.BlueReverse}
                  type={ButtonType.Primary}
                >
                  {t('common:buttons.cancel')}
                </Button>

                <Button
                  buttonType='submit'
                  className='confirmation__modal__button'
                  disabled={!values.activateDomainVersionId || isSubmitting}
                  theme={ButtonTheme.Blue}
                  type={ButtonType.Primary}
                >
                  {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                </Button>
              </footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  ) : (
    <ConfirmationModal
      visible={visible}
      setVisible={setVisible}
      content={t('edit.modal.end.confirm.content', { name: abTest.name })}
      title={t('edit.modal.end.confirm.title')}
      confirmButtonText={t('edit.modal.end.confirm.confirm')}
      cancelButtonText={t('common:buttons.cancel')}
      onConfirmHandler={onConfirm}
    />
  )
}
