import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Select } from 'components/Form'
import { ConfirmationModal } from 'components/Modals'
import { NotificationType } from 'components/ToastNotifier'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error as QqlError } from 'utilities/errorUtils'
import { ProductStatus } from 'webapp/constants/ProductStatus'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import TrialDurationModal from './TrialDurationModal'
import TrialOption from './TrialOption'
import {
  StatusCellProps,
  TrialDuration,
  UpdateProductConnectionData,
  UpdateReviveDomainConfigurationVars
} from './types'
import { useProductStatusOptions } from './useProductStatusOptions'
import { trialDurationDefault } from './utils'

import { UPDATE_PRODUCT_CONNECTION } from 'gql/mutations/productConnections/UpdateProductConnection'

export const StatusCell = ({ productConnection }: StatusCellProps): JSX.Element => {
  const { t } = useTranslation('products')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const {
    id: productConnectionId,
    product,
    status: initialStatus,
    trialStartsAt,
    trialEndsAt,
    workspace: { name: workspaceName }
  } = productConnection

  const [currentStatus, setCurrentStatus] = useState<ProductStatus>(initialStatus)
  const [nextStatus, setNextStatus] = useState<ProductStatus>(currentStatus)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [isTrialDurationModalOpen, setIsTrialDurationModalOpen] = useState<boolean>(false)

  const [tempTrialDuration, setTempTrialDuration] = useState<TrialDuration>(
    trialDurationDefault({ trialStartsAt, trialEndsAt })
  )
  const [trialDuration, setTrialDuration] = useState<TrialDuration>(tempTrialDuration)

  const [updateProductConnection, { loading: loadingUpdateProductConnection }] = useMutation<
    UpdateProductConnectionData,
    { input: UpdateReviveDomainConfigurationVars }
  >(UPDATE_PRODUCT_CONNECTION, {
    onCompleted: ({ updateProductConnection: { errors } }) => {
      closeAllModals()

      if (errors.length > 0) {
        createNotifier(
          t('management.changeStatus.notification.error', {
            error: errors.map((e: QqlError) => e.message).join(', ')
          }),
          NotificationType.ERROR
        )
      } else {
        createNotifier(t('management.changeStatus.notification.success'), NotificationType.SUCCESS)
        setCurrentStatus(nextStatus)
        setTrialDuration(tempTrialDuration)
      }
    }
  })

  const handleSelect = ({ value }: SelectOption): void => {
    setNextStatus(value as ProductStatus)

    if (value === ProductStatus.Trial) {
      setIsTrialDurationModalOpen(true)
    } else {
      setIsConfirmationModalOpen(true)
    }
  }

  const handleCancel = (): void => {
    setNextStatus(currentStatus)
    setTempTrialDuration(trialDuration)
    closeAllModals()
  }

  const handleConfirmation = ({ withTrial }: { withTrial?: boolean }) => {
    if (withTrial) {
      setNextStatus(ProductStatus.Trial)
    }

    updateProductConnection(
      nestGqlInput({
        productConnectionId,
        status: nextStatus,
        ...(withTrial ? { trialEndsAt: tempTrialDuration.to.toISOString() } : {})
      })
    )
  }

  const closeAllModals = (): void => {
    setIsConfirmationModalOpen(false)
    setIsTrialDurationModalOpen(false)
  }

  const TrialOptionContainer: JSX.Element = (
    <TrialOption duration={tempTrialDuration} onModalOpen={setIsTrialDurationModalOpen} />
  )

  const selectOptions: SelectOption[] = useProductStatusOptions(TrialOptionContainer)
  const selectLabel: string | JSX.Element =
    currentStatus === ProductStatus.Trial ? TrialOptionContainer : t(`status.${currentStatus}`)

  const SELECT_ID = 'product-status'

  return (
    <>
      <Select
        className={`product-management-table__status-select product-management-table__status-select--${currentStatus}`}
        id={SELECT_ID}
        name={SELECT_ID}
        options={selectOptions}
        onChangeHandler={handleSelect}
        selectedItem={{ label: selectLabel, value: currentStatus }}
        isSearchable={false}
        theme='pastel'
      />

      <ConfirmationModal
        content={t('management.changeStatus.confirmation', {
          current: t(`status.${currentStatus}`),
          new: t(`status.${currentStatus}`),
          workspace: workspaceName
        })}
        onCancelHandler={handleCancel}
        onConfirmHandler={() => void handleConfirmation({})}
        isSubmitting={loadingUpdateProductConnection}
        visible={isConfirmationModalOpen}
        setVisible={setIsConfirmationModalOpen}
        closeButton
      />

      <TrialDurationModal
        product={product}
        workspaceName={workspaceName}
        duration={tempTrialDuration}
        onDurationChange={setTempTrialDuration}
        onConfirmHandler={() => void handleConfirmation({ withTrial: true })}
        onCancelHandler={handleCancel}
        isOpen={isTrialDurationModalOpen}
        setIsOpen={setIsTrialDurationModalOpen}
        isLoading={loadingUpdateProductConnection}
      />
    </>
  )
}
