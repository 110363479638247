import { useTranslation } from 'react-i18next'

import { FilterType } from 'webapp/constants/FilterType'

export const useFilterConfig = (): AdvancedFilterConfiguration[] => {
  const { t } = useTranslation('inventory')

  return [
    {
      label: t('filters.label.pageName'),
      name: 'oss_page.page_name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.pageName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.pagePath'),
      name: 'oss_page.path',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.pagePath'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.versionName'),
      name: 'domain_version.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.versionName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.domainName'),
      name: 'domain_version.domain.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.domainName'),
      type: FilterType.Input
    }
  ]
}
