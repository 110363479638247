import { BaseSerializer } from 'serializers/BaseSerializer'
import { emptySelectOption } from 'webapp/constants/emptySelectOption'

export interface FormValues {
  domain: typeof emptySelectOption
  tagsType: typeof emptySelectOption
}

interface QueryValues {
  domainId: string
  tagsType: string
}

export class DownloadTagSerializer extends BaseSerializer<FormValues> {
  getParams(): QueryValues {
    return {
      domainId: this.data.domain.value,
      tagsType: this.data.tagsType.value
    }
  }
}
