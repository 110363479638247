export enum SizeTypeEnum {
  GENERAL = 'general',
  SELECTED = 'selected'
}

export const defaultSizeType = SizeTypeEnum.GENERAL

export const sizeTypeUtils = Object.freeze({
  isGeneralSizeType: (sizeType?: string): boolean => sizeType === SizeTypeEnum.GENERAL
})
