import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { Loading } from 'components/LoadingContainer'
import EditPageForm from 'components/quickwrap/Inventory/Pages/EditPageForm'
import { DomainVersionSelect } from 'components/shared/Inventory/Domains/DomainVersionSelect'
import { PrebidDevicesContainer } from 'containers/shared/Inventory/Domains/DevicesContainer'
import { VersionedDomainProvider } from 'webapp/context/VersionedDomainContext'
import { PageSlice } from './types'
import { useFetchPage } from './useFetchPage'

export const EditPage = (): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { page, getPageLoading, domainId, currentVersion, setCurrentVersion } = useFetchPage()

  const VersionSelectContainer = (domainId: DomainVersion['id'], currentVersion?: DomainVersion['id']): JSX.Element => (
    <BoxHeader rowClassName={'page-edit-header__row'}>
      <VersionedDomainProvider domainId={domainId}>
        <DomainVersionSelect
          initialVersionId={currentVersion}
          onChange={(newVersionId) => setCurrentVersion(newVersionId)}
        />
      </VersionedDomainProvider>
    </BoxHeader>
  )

  const Container = ({ page, loading }: { page?: PageSlice; loading: boolean }): ReactElement => (
    <>
      <Box isExpandable={true}>
        <BoxHeader title={t('pages.edit.title')} />

        <BoxBody>{loading ? <Loading /> : page && <EditPageForm page={page} />}</BoxBody>
      </Box>

      {page && <PrebidDevicesContainer prebidDeviceableId={page.id} workspaceId={page.domain.workspaceId} />}
    </>
  )

  return (
    <>
      {domainId && VersionSelectContainer(domainId, currentVersion)}
      <Container page={page} loading={getPageLoading} />
    </>
  )
}
