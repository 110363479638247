import React from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Logo } from 'components/oss/Products/Logo'
import { getProductSettings } from 'utilities/products/settings'
import { ProductStatus } from 'webapp/constants/ProductStatus'
import { LearnMore } from './LearnMore'
import { ProductStatusLabel } from './ProductStatusLabel'
import { UnlimitedPlanActivation } from './UnlimitedPlanActivation'
import { TrialActivation } from 'components/oss/Products/ProductList/Item/TrialActivation'

import { ItemProps } from './types'

export const Item: React.FC<ItemProps> = ({ productConnection }) => {
  const { t } = useTranslation('products')
  const history = useHistory<History>()

  const { product, status } = productConnection
  const { homePageUrl, learnMoreUrl } = getProductSettings(product)

  const showUnlimitedPlanActivation = status === ProductStatus.Trial
  const isProductActive = ![ProductStatus.Inactive, ProductStatus.InSetup].includes(status)

  const handleClick = (): void => {
    if (!isProductActive || !homePageUrl) return

    history.push(homePageUrl)
  }

  return (
    <div
      className={classNames('ProductList__item', `ProductList__item--${product}`, {
        'ProductList__item--active': isProductActive && homePageUrl
      })}
      onClick={handleClick}
    >
      <Logo className='ProductList__logo' product={product} />

      <div>
        <div className='ProductList__header'>
          {t(`product.${product}`)}
          <ProductStatusLabel productConnection={productConnection} />
        </div>

        <div className='ProductList__title'>{t(`list.items.title.${product}`)}</div>

        <div>
          {t(`list.items.description.${product}`)}
          {learnMoreUrl && <LearnMore learnMoreUrl={learnMoreUrl} />}
        </div>

        {productConnection.status === ProductStatus.Inactive && (
          <TrialActivation productConnection={productConnection} />
        )}

        {showUnlimitedPlanActivation && <UnlimitedPlanActivation productConnection={productConnection} />}
      </div>
    </div>
  )
}
