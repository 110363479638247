import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { UserContext } from 'webapp/context/UserContext'
import { WorkspaceSettingsForm } from 'components/oss/Workspaces/WorkspaceSettingsForm'
import { WorkspaceSettingsSerializer } from 'serializers/WorkspaceSettingsSerializer'

import SetAmazonPublisherServices from 'gql/mutations/settings/SetAmazonPublisherServices.gql'
import WorkspaceQuery from 'gql/queries/workspaces/Workspace.gql'

export const WorkspaceSettingsContainer: React.FC = () => {
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [settings, setWorkspaceSettings] = useState<GeneralSetting | undefined>(undefined)

  const { createNotifier } = useContext(NotificationContext) as Notification
  const { user } = useContext(UserContext)

  const { t } = useTranslation('workspaces')

  const { workspaceId } = useParams<{ workspaceId: string }>()

  const [workspaceQuery] = useLazyQuery(WorkspaceQuery, {
    fetchPolicy: 'network-only',
    variables: { workspaceId },
    onCompleted: ({ workspace }) => {
      setWorkspaceSettings(workspace.settings)
    }
  })

  useEffect(() => {
    workspaceQuery({})
  }, [user?.role, workspaceQuery])

  const [setAmazonPublisherServices, { loading: loading }] = useMutation(SetAmazonPublisherServices, {
    onCompleted: ({ setAmazonPublisherServices: { errors } }) => {
      if (errors.length === 0) {
        createNotifier(t('workspaceSettings.successMessage'), NotificationType.SUCCESS)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const setAmazonPublisherServicesHandler = (values: WorkspaceSettings) => {
    const serializer = new WorkspaceSettingsSerializer(values, workspaceId)

    setAmazonPublisherServices({ variables: { input: serializer.updateParams() } })
    setWorkspaceSettings(values)
  }

  return (
    <Box className='workspace-settings-container' isExpandable={true}>
      <BoxHeader title={t('workspaceSettings.title')} />
      {settings && (
        <BoxBody>
          <WorkspaceSettingsForm
            settings={settings}
            errors={errors}
            isSubmitting={loading}
            onSubmit={setAmazonPublisherServicesHandler}
          />
        </BoxBody>
      )}
    </Box>
  )
}
