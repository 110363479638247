export enum CollectionNames {
  ABTests = 'abTests',
  AdUnits = 'adUnits',
  BidderSettings = 'bidderSettings',
  BlockUrls = 'qwBlockUrlGroups',
  ConnectedNetworks = 'connectedNetworks',
  CustomCodes = 'customCodes',
  DemandStatus = 'demandStatus',
  DomainComparison = 'domainComparison',
  Domains = 'domains',
  DomainVersions = 'domainVersions',
  HistoryActions = 'historyActions',
  InventoryConnections = 'inventoryConnections',
  Networks = 'networks',
  OssAdUnits = 'ossAdUnits',
  OssPages = 'ossPages',
  Pages = 'pages',
  PrebidManagerDomains = 'prebidManagerDomains',
  PriceGeniusDomainConfigurations = 'pgDomainConfigurations',
  ProductConnections = 'productConnections',
  ReportResults = 'reportResults',
  ReviveDomainConfigurations = 'reviveDomainConfigurations',
  Users = 'users',
  Workspaces = 'workspaces'
}
