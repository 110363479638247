import React, { useMemo } from 'react'
import { BidderSettingsForm } from 'components/sharedInterfaces/BidderSettingsForm'
import { BiddersSelect } from 'components/FormikComponents'
import { useField } from 'formik'

interface Props {
  demandBidderSettings: BidderSetting[]
  demandBidders: Bidder[]
}

export const YbDemandFilteredBidderSelect: React.FC<Props> = ({ demandBidders, demandBidderSettings }) => {
  const [{ value }] = useField<BidderSettingsForm['bidders']>('bidders')

  const filteredBidders = useMemo(() => {
    const savedBidderIds = demandBidderSettings.reduce((acc, { bidder }) => {
      return [...acc, bidder.id as string]
    }, [] as string[])

    const unsavedBidderIds = value.reduce((acc, { bidderId }) => {
      return [...acc, bidderId.value]
    }, [] as string[])

    return demandBidders.filter(
      (demandBidder) =>
        !savedBidderIds.includes(demandBidder.id as string) && !unsavedBidderIds.includes(demandBidder.id as string)
    )
  }, [demandBidders, demandBidderSettings, value])

  return <BiddersSelect bidders={filteredBidders} id='yb-demand-bidder-name' label={true} name='newBidder' />
}
