import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const USER_GAM_NOTIFICATION_KEY = 'cXdfZ2FtLW5vdGlmaWNhdGlvbi1rZXk=' // base46 of qw_gam-notification-key

const wasNotificationClosed = (): boolean => {
  return Boolean(window.localStorage.getItem(USER_GAM_NOTIFICATION_KEY))
}

const saveNotificationState = (): void => {
  window.localStorage.setItem(USER_GAM_NOTIFICATION_KEY, '1')
}

export const UserNotification: React.FC = () => {
  const { t } = useTranslation()
  const [isNotificationClosed, setIsNotificationClosed] = useState<boolean>(wasNotificationClosed())

  const closeNotification = () => {
    setIsNotificationClosed(true)
    saveNotificationState()
  }

  return !isNotificationClosed ? (
    <div className='user-widget__dropdown-notification'>
      <CloseIcon
        className={'user-widget__dropdown-notification-close'}
        onClick={closeNotification}
        style={{ fontSize: 12 }}
      />

      {t('userWidget.adManagerNotification')}
    </div>
  ) : (
    <></>
  )
}
