import React, { useContext } from 'react'
import { CellProps } from 'react-table'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { OwnBidderSettingRowData } from 'components/quickwrap/Setup/OwnBidderSettings/Table/OwnBidderSettingRowData'
import { OwnBidderTableContext } from 'webapp/context/OwnBiddersContext'
import { ToggleSwitch } from 'components/Form'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import UpsertBidderSetting from 'gql/mutations/bidderSettings/UpsertBidderSetting.gql'

type GQLInputData = {
  active: boolean
  bidCpmAdjustment: number
  bidderCode: string
  bidderSettingId: string
}

type ToggleCellType = CellProps<OwnBidderSettingRowData> & {
  id: string
  fieldName: keyof Pick<GQLInputData, 'active'>
}

export const ToggleCell = ({ fieldName, id, row, value }: ToggleCellType): JSX.Element => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const ownBidderContext = useContext(OwnBidderTableContext)

  const [updateBidderSetting, { loading }] = useMutation(UpsertBidderSetting, {
    onCompleted: ({ upsertBidderSetting: { bidderSetting } }) => {
      if (bidderSetting) {
        createNotifier(t('bidders.form.editMessage'), NotificationType.SUCCESS)
        ownBidderContext.refetchBidderSettings()
      } else {
        createNotifier(t('common:errors.common'), NotificationType.ERROR)
      }
    }
  })

  const bidderSetting = row.original

  const onChangeHandler = () => {
    const gqlInput: GQLInputData = {
      active: bidderSetting.active,
      bidCpmAdjustment: bidderSetting.bidCpmAdjustment,
      bidderCode: bidderSetting.bidderCode,
      bidderSettingId: bidderSetting.id
    }

    gqlInput[fieldName] = !value
    updateBidderSetting(nestGqlInput(gqlInput))
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        id={`${id}-${row.original.id}`}
        name={`${id}-${row.original.id}`}
        checked={value ?? false}
        onChange={onChangeHandler}
        disabled={loading}
        className='status__switch'
      />
    </div>
  )
}
