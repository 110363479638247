/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { HTMLProps, useState } from 'react'
import classNames from 'classnames'

interface Props extends HTMLProps<HTMLHeadingElement> {
  startingOffset?: number
  stickToLeft?: boolean
  stickToTop?: boolean
  tag?: 'th' | 'td'
}

export const StickyCell: React.FC<Props> = ({
  className,
  startingOffset,
  stickToLeft,
  stickToTop,
  style = {},
  tag = 'th',
  ...props
}) => {
  const [left, setLeft] = useState<number>()

  let measureLeftOffset: any
  let extendedStyle

  const extendedClassname = classNames(className, 'sticky-cell', stickToTop && 'sticky-cell--to-top')

  if (left !== undefined && style) {
    extendedStyle = { ...style, left }
  }

  if (stickToLeft) {
    measureLeftOffset = (element: HTMLTableHeaderCellElement) => {
      if (!element) {
        return
      }
      let offset = startingOffset ?? 0
      let previousSibling = element.previousElementSibling

      while (previousSibling) {
        offset += (previousSibling as HTMLElement).offsetWidth
        previousSibling = previousSibling.previousElementSibling
      }
      setLeft(offset)
    }
  }

  const Cell = tag

  return <Cell className={extendedClassname} style={extendedStyle} ref={measureLeftOffset} {...props}></Cell>
}
