import { gql } from '@apollo/client'
import { CUSTOM_CODE_FRAGMENT } from 'gql/fragments/customCode/CustomCodeFragment'

export const CUSTOM_CODES = gql`
  ${CUSTOM_CODE_FRAGMENT}

  query CustomCodes($workspaceId: ID, $order: Order) {
    customCodes(workspaceId: $workspaceId, templatesOnly: true, order: $order) {
      nodes {
        ...CustomCodeFragment
      }
    }
  }
`
