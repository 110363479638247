import React from 'react'
import CloseIcon from '@material-ui/icons/Close'

type PreviewListElementType = {
  clearFilterHandler: (items: string[]) => void
  item: string
}

export const PreviewListElement = ({ clearFilterHandler, item }: PreviewListElementType): JSX.Element => {
  const clearFilter = () => {
    clearFilterHandler([item])
  }

  return (
    <div className='DynamicListInput__previewListElemenet'>
      <span>{item}</span>

      <CloseIcon className='DynamicListInput__clearPreviewElement' onClick={clearFilter} />
    </div>
  )
}
