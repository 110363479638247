import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import Copy from 'icons/Copy'
import GAM from 'images/GAM.png'
import { Image } from 'components/Image'
import { Modal } from 'components/Modals'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
  aliases: Array<string>
}

const SEPARATOR = '\n'

export const GoogleAdManagerModal: React.FC<Props> = ({ visible, setVisible, aliases }) => {
  const { t } = useTranslation('setup')

  const closeHandler = useCallback(() => setVisible(false), [setVisible])
  const copyHandler = useCallback((text: string) => copy(text), [])

  const aliasNames = useMemo(() => aliases.join(SEPARATOR), [aliases])

  return (
    <Modal visible={visible} setVisible={setVisible} shadow={true} backdrop={true}>
      <div className='confirmation__modal-container own-bidders__popup'>
        <Image src={GAM} alt='Google Ad Manager logo' />
        <p className='own-bidders__popup__content' dangerouslySetInnerHTML={{ __html: t('bidders.popup.content') }}></p>

        <div className='own-bidders__popup__container'>
          <p className='own-bidders__popup__label'>{t('bidders.popup.label')}</p>
          <textarea
            name='own-bidders-aliases'
            id='own-bidders-aliases'
            className='own-bidders__popup__textarea'
            defaultValue={aliasNames}
            readOnly
          />
          <Button
            theme={ButtonTheme.BlueDark}
            type={ButtonType.SingleAction}
            className='own-bidders__popup__button--copy'
            onClickHandler={() => copyHandler(aliasNames)}
          >
            <Copy />
          </Button>
        </div>

        <footer>
          <Button
            theme={ButtonTheme.Blue}
            type={ButtonType.Primary}
            onClickHandler={closeHandler}
            className='confirmation__modal__button'
          >
            {t('bidders.popup.confirm')}
          </Button>
        </footer>
      </div>
    </Modal>
  )
}
