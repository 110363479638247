import React, { useContext } from 'react'
import { Column, Row } from 'react-table'
import { useHistory } from 'react-router'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { QueryTable } from 'containers/QueryTable'
import { UserContext } from 'webapp/context/UserContext'

import { columnsConfig } from './columnsConfig'
import { routesBuilder } from 'utilities/routesBuilder'

import NetworksQuery from 'gql/queries/networks/Networks.gql'

const SEARCHABLE_FIELDS = ['asi', 'name']

export const NetworksTable = (): JSX.Element => {
  const history = useHistory()
  const user: User = useContext(UserContext).user as User

  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    const { id } = row.original as unknown as Network

    history.push(routesBuilder.quickwrap.setup.networks.edit(id))
  }

  return (
    <QueryTable<Network>
      collectionName={CollectionNames.Networks}
      dataQuery={NetworksQuery}
      columnsConfig={columnsConfig() as Column[]}
      className='networks-table'
      searchableFields={SEARCHABLE_FIELDS}
      variables={{ workspaceId: user.workspace?.id }}
      onRowClick={openEditingPage}
    />
  )
}
