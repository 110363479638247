import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { ErrorUtils } from 'utilities/errorUtils'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { RequestNewElementContainer } from 'containers/RequestNewElementContainer'
import { RequestBidderFactory } from 'containers/RequestNewElementContainer/formSchema'

import BidderRequest from 'gql/mutations/bidders/BidderRequest.gql'

interface Props {
  demand?: boolean
}

export const RequestNewBidderContainer: React.FC<Props> = ({ demand = false }) => {
  const { t } = useTranslation('setup')
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState('')
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [RequestBidderMutation, { loading: isSubmitting }] = useMutation(BidderRequest, {
    onCompleted: ({ bidderRequest: { errors } }) => {
      if (!errors.length) {
        setVisible(false)
        createNotifier(getMessage(), NotificationType.SUCCESS)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const getMessage = () => {
    return demand
      ? t('requestBidder.notificationDemandBidder', { bidderName: value })
      : t('requestBidder.notificationOwnBidder', { bidderName: value })
  }

  const sendRequestBidder = (value: string) => {
    setValue(value)
    RequestBidderMutation({ variables: { input: RequestBidderFactory(value, demand) } })
  }

  const title = useMemo(
    () => (demand ? t('requestBidder.modal.titleDemandBidder') : t('requestBidder.modal.titleOwnBidder')),
    [demand, t]
  )

  useEffect(() => {
    if (visible === false) {
      setErrors(new ErrorUtils([]))
    }
  }, [visible])

  return (
    <RequestNewElementContainer
      description={t('requestBidder.description')}
      isSubmitting={isSubmitting}
      link={t('requestBidder.link')}
      modalTitle={title}
      modalPlaceholder={t('requestBidder.modal.placeholder')}
      visible={visible}
      onSubmitHandler={sendRequestBidder}
      setVisible={setVisible}
      errorKey='bidderName'
      errors={errors}
    />
  )
}
