import { gql } from '@apollo/client'

import { TABLE_INPUTS_N_RESOLVERS } from 'gql/fragments/common'

export const REVIVE_DOMAIN_CONFIGURATIONS = gql`
  query reviveDomainConfigurations(${TABLE_INPUTS_N_RESOLVERS[0]}) {
    reviveDomainConfigurations(${TABLE_INPUTS_N_RESOLVERS[1]}) {
      totalCount
      nodes {
        domain {
          id
          name
          reviveEnabled
        }
        id
        matchType
        ossAdUnitsCount
        ossAdUnitsEnabledCount
        targetingEnabled
        updatedAt
      }
    }
  }
`
