import { useQuery } from '@apollo/client'

import { PrebidModuleKind } from 'webapp/constants/PrebidModuleKind'

import PrebidModuleListQuery from 'gql/queries/prebidModules/PrebidModulesList.gql'

export const usePrebidModules = (kind: PrebidModuleKind) => {
  const { data } = useQuery<{ prebidModules: Connection<PrebidModule> }>(PrebidModuleListQuery, {
    fetchPolicy: 'network-only',
    variables: { kind }
  })

  return data?.prebidModules.nodes
}
