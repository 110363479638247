import { useState } from 'react'
import { useQuery } from '@apollo/client'

import WorkspaceDevicesQuery from 'gql/queries/workspaceDevices/WorkspaceDevices.gql'

export const useWorkspaceDevices = (): WorkspaceDevice[] => {
  const [workspaceDevices, setWorkspaceDevices] = useState<WorkspaceDevice[]>([])

  useQuery(WorkspaceDevicesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ workspaceDevices: { nodes } }) => setWorkspaceDevices(nodes)
  })

  return workspaceDevices
}
