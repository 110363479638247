import React from 'react'

import { Field, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { ButtonTheme, ButtonType } from 'components/Button'
import { LoadingButton } from 'components/Button/LoadingButton'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { Errors } from 'utilities/errorUtils'

type RegisterFormProps = {
  disabled?: string[]
  errors: Errors
  isSubmitting: boolean
}

export const RegisterForm = ({ disabled, errors, isSubmitting }: RegisterFormProps): JSX.Element => {
  const { t } = useTranslation('auth')
  const isDisabled = (element: string) => disabled?.includes(element)

  return (
    <Form className='user-flow__form register-form'>
      <Field
        id='email'
        name='email'
        type='email'
        className='register-form__input'
        placeholder={t('register.form.email.placeholder')}
        label={t('register.form.email.label')}
        component={FormikInput}
        errors={errors.errorsFor('email')}
        labelStyle='register'
        disabled={isDisabled('email')}
      />

      <Field
        id='password'
        name='password'
        type='password'
        className='register-form__input'
        placeholder={t('register.form.password.placeholder')}
        label={t('register.form.password.label')}
        component={FormikInput}
        errors={errors.errorsFor('password')}
        labelStyle='register'
      />

      <Field
        id='password-confirmation'
        name='passwordConfirmation'
        type='password'
        className='register-form__input'
        placeholder={t('register.form.passwordConfirmation.placeholder')}
        label={t('register.form.passwordConfirmation.label')}
        component={FormikInput}
        errors={errors.errorsFor('passwordConfirmation')}
        labelStyle='register'
      />

      <Field
        id='invitation-token'
        name='invitationToken'
        type='hidden'
        className='register-form__input'
        component={FormikInput}
        labelStyle='register'
        disabled={isDisabled('invitationToken')}
      />

      <LoadingButton type={ButtonType.Primary} theme={ButtonTheme.Blue} buttonType='submit' loading={isSubmitting}>
        {t('common:buttons.register')}
      </LoadingButton>
    </Form>
  )
}
