import React, { MouseEventHandler, useMemo } from 'react'
import Select, {
  components,
  ActionMeta,
  PropsValue,
  MultiValueGenericProps,
  MultiValueProps,
  Props
} from 'react-select'
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle
} from 'react-sortable-hoc'
import classNames from 'classnames'

import { InputContainer } from 'components/Form/InputContainer'

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice()
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0])
  return slicedArray
}

const SortableMultiValue = SortableElement((props: MultiValueProps<SelectOption>) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const innerProps = { ...props.innerProps, onMouseDown }
  return (
    <div className='SortableSelect'>
      <components.MultiValue {...props} innerProps={innerProps} />
    </div>
  )
})

const SortableMultiValueLabel = SortableHandle((props: MultiValueGenericProps) => (
  <components.MultiValueLabel {...props} />
))

/*  eslint-disable @typescript-eslint/indent */
const SortableSelectComponent = SortableContainer(Select) as React.ComponentClass<
  Props<SelectOption, true> & SortableContainerProps
>
/*  eslint-enable @typescript-eslint/indent */

export const SortableSelect = ({
  className,
  closeMenuOnSelect = true,
  containerClassName,
  defaultValue,
  disabled,
  errors,
  filterOption,
  hideSelectedOptions,
  id,
  isSearchable = true,
  isClearable,
  label,
  labelStyle,
  name,
  onChangeHandler,
  options,
  menuIsOpen,
  placeholder,
  selectedItem,
  tooltipContent,
  setSelected
}: SelectProps & { setSelected: (value: string[]) => void }): JSX.Element => {
  const hasError = useMemo(() => Boolean(errors && errors.length > 0), [errors])

  const classString = useMemo(() => {
    return classNames('select__container', className, {
      'select--error': hasError
    })
  }, [className, hasError])

  const containerClassString = useMemo(() => {
    return classNames('input__container', containerClassName)
  }, [containerClassName])

  const onChange = (items: PropsValue<SelectOption>, _actionMeta: ActionMeta<SelectOption>) => {
    if (disabled) return

    onChangeHandler && onChangeHandler((items || []) as SelectOption)
  }

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newSelectedItem = arrayMove(selectedItem as string[], oldIndex, newIndex)

    setSelected(newSelectedItem)
  }

  return (
    <InputContainer
      className={containerClassString}
      disabled={disabled}
      errors={errors}
      inputId={name}
      label={label}
      labelStyle={labelStyle}
      tooltipContent={tooltipContent}
    >
      <SortableSelectComponent
        axis='xy'
        className={classString}
        classNamePrefix='select'
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          MultiValue: SortableMultiValue,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          MultiValueLabel: SortableMultiValueLabel
        }}
        defaultValue={defaultValue}
        filterOption={filterOption}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        hideSelectedOptions={hideSelectedOptions}
        id={id}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={true}
        isSearchable={isSearchable}
        menuIsOpen={menuIsOpen}
        name={name}
        onChange={onChange}
        onSortEnd={onSortEnd}
        options={options}
        placeholder={placeholder}
        useDragHandle
        value={selectedItem}
      />
    </InputContainer>
  )
}
