import { DomainVersionSelectOption } from 'webapp/components/shared/Inventory/Domains/DomainVersionSelect/DomainVersionOptionsFactory'
import { NextActiveVersionData } from '../types'

export const optionsForDeleteModal = (
  domainVersionId: DomainVersion['id'],
  domainVersions: DomainVersion[]
): SelectOption[] =>
  domainVersions
    .filter((dv: DomainVersion) => dv.id !== domainVersionId)
    .map((dv: DomainVersion) => ({ value: dv.id, name: dv.name, label: dv.name } as SelectOption))

export const getDefaultOption = (options: SelectOption[]): SelectOption | null =>
  options.length === 1 ? options[0] : null

export const nextActiveVersionFormInitValues = (): NextActiveVersionData => ({
  nextActiveVersion: { name: '', value: '', label: '' }
})

export const nextActiveVersionFormOptions = (
  domainVersionSelectOptions: DomainVersionSelectOption[],
  currentVersionId: DomainVersion['id']
): DomainVersionSelectOption[] =>
  domainVersionSelectOptions.filter((opt: DomainVersionSelectOption) => isNotCurrentVersion(opt, currentVersionId)) ||
  []

export const isNotCurrentVersion = (
  option: { value: DomainVersionSelectOption['value'] },
  currentVersionId: DomainVersion['id']
): boolean => option.value !== currentVersionId
