import React, { useMemo } from 'react'

import { Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { ButtonTheme, ButtonType } from 'components/Button'
import { LoadingButton } from 'components/Button/LoadingButton'
import { StatusForm } from 'components/Form'
import { SetAmazonPublisherServicesForm } from 'components/sharedInterfaces/SetAmazonPublisherServicesForm'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { GeneralSettings } from 'containers/quickwrap/Setup/AmazonDemandContainer/AmazonDemandContainer'
import { Errors } from 'utilities/errorUtils'
import { AmazonDemandTypes } from 'webapp/constants/AmazonDemandTypes'
import { DemandType } from './DemandType'
import { AmazonDemandFactory } from './formSchema'

interface AmazonDemandFormProps {
  apsPubId: GeneralSettings['apsPubId']
  apsDemandType: AmazonDemandTypes
  errors: Errors
  forceFormReset: number
  isSubmitting?: boolean
  onSubmitHandler: (values: SetAmazonPublisherServicesForm) => void
}

export const AmazonDemandForm = ({
  apsPubId,
  apsDemandType,
  errors,
  forceFormReset,
  onSubmitHandler
}: AmazonDemandFormProps): JSX.Element => {
  const { t } = useTranslation('setup')

  const initialValues = useMemo(
    () => AmazonDemandFactory(apsPubId, forceFormReset, apsDemandType),
    [apsPubId, apsDemandType, forceFormReset]
  )

  return (
    <div className='amazon-demand-form'>
      <Formik initialValues={initialValues} onSubmit={onSubmitHandler} enableReinitialize={true}>
        {({ dirty, values: { apsDemandType } }) => (
          <StatusForm name='amazon-demand' dirty={dirty}>
            <Grid>
              <Grid item lg={3} xs={3}>
                <Field
                  id='apsDemandType'
                  name='apsDemandType'
                  type='radio'
                  label={t('amazonDemand.form.demandType.label')}
                  component={DemandType}
                  errors={errors.errorsFor('demandType')}
                />
              </Grid>

              {apsDemandType === AmazonDemandTypes.OwnDemand && (
                <Grid item lg={3} xs={3}>
                  <Field
                    id='apsPubId'
                    name='apsPubId'
                    type='text'
                    placeholder={t('amazonDemand.form.apsPubId.placeholder')}
                    label={t('amazonDemand.form.apsPubId.label')}
                    tooltipContent={t('amazonDemand.form.apsPubId.tooltip')}
                    component={FormikInput}
                    errors={errors.errorsFor('apsPubId')}
                  />
                </Grid>
              )}

              <Grid container className='submit-form' alignItems={'center'} spacing={2}>
                <Grid item>
                  <LoadingButton
                    type={ButtonType.Primary}
                    className='own-bidder-form__button'
                    theme={ButtonTheme.Orange}
                    buttonType='submit'
                    disabled={!dirty}
                  >
                    {t('common:buttons.save')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
