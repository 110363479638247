import React from 'react'
import classNames from 'classnames'

import { BidderLogo } from 'components/BidderLogo'
import { ParamLogo } from 'components/Form/ParamFormSection/ParamLogo'

interface Props {
  item?: Bidder | PrebidModule
  isBidder?: boolean
  isSaved: boolean
}

export const ParamFormLogo: React.FC<Props> = ({ item, isBidder, isSaved }) => {
  return (
    <div className={classNames('param-form__logo', { 'param-form__logo--saved': isSaved })}>
      {isBidder ? (
        <BidderLogo
          fileName={(item as Bidder)?.fileName}
          placeholder={item?.name}
          bidderName={(item as Bidder)?.bidderCode}
        />
      ) : (
        <ParamLogo fileName={(item as PrebidModule)?.adapter} placeholder={item?.name} />
      )}
    </div>
  )
}
