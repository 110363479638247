import React from 'react'

import { useHistory } from 'react-router-dom'
import { Column, Row } from 'react-table'

import { QueryTable } from 'containers/QueryTable'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { OssAdUnitRowData } from './OssAdUnitRowData'
import { columnsConfig } from './columnsConfig'
import { AdUnitsTableProps } from './types'

import { OSS_AD_UNITS } from 'gql/queries/adUnits/ossAdUnits'

export const AdUnitsTable = ({ domainId }: AdUnitsTableProps): JSX.Element => {
  const history = useHistory()

  const redirectToEdit = (row: Row<Record<string, unknown>>) =>
    void history.push(routesBuilder.oss.inventory.adUnit.edit((row.original as unknown as OssAdUnitRowData).id))

  return (
    <QueryTable<OssAdUnitRowData>
      collectionName={CollectionNames.OssAdUnits}
      columnsConfig={columnsConfig() as Column[]}
      dataQuery={OSS_AD_UNITS}
      mapper={(node: OssAdUnit) => new OssAdUnitRowData(node)}
      onRowClick={redirectToEdit}
      variables={{ domainId }}
    />
  )
}
