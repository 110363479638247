import React from 'react'

import { Column } from 'react-table'

import { QueryTable } from 'containers/QueryTable'
import { PriceGeniusDomainConfiguration } from 'types/PriceGeniusDomainConfiguration'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { PriceGeniusDomainConfigurationRow } from './PriceGeniusDomainConfigurationRow'
import { useColumnsConfig } from './useColumnsConfig'

import { PRICE_GENIUS_DOMAIN_CONFIGURATIONS } from 'gql/queries/priceGenius/priceGeniusDomainConfigurations'

import './styles.scss'

export const ConfigurationsTable = (): JSX.Element => (
  <QueryTable<PriceGeniusDomainConfigurationRow>
    className='price-genius-domain-configurations-table'
    collectionName={CollectionNames.PriceGeniusDomainConfigurations}
    columnsConfig={useColumnsConfig() as Column[]}
    dataQuery={PRICE_GENIUS_DOMAIN_CONFIGURATIONS}
    mapper={(node: PriceGeniusDomainConfiguration): PriceGeniusDomainConfigurationRow =>
      new PriceGeniusDomainConfigurationRow(node)
    }
  />
)
