import { filterStore, Filters } from 'utilities/filters/filterStore'

type Filter = {
  by: string
  value: string
}

export type Order = {
  by: string
  direction: string
}

type OrderParams = {
  order: Order
}

type FilterParams = {
  filter: (Filter | AdvancedFilter)[]
}

export type QueryParams = {
  pageSize: number
  offset: string
  filter: (Filter | AdvancedFilter)[]
  order: Order
}

export const processOrderParams = (allFilters: Filters): OrderParams => {
  if (!allFilters.order) return { order: { by: '', direction: '' } }

  const [by, direction] = allFilters.order
  return { order: { by, direction } }
}

const processSearchParams = (allFilters: Filters, searchBy: string[]): Filter[] => {
  if (allFilters.search && searchBy.length > 0) {
    const value = allFilters.search
    return searchBy.map((by) => ({ by, value }))
  }
  return []
}

const processFilterParams = (
  allFilters: Filters,
  searchBy: string[],
  group: string,
  globalFilter: AdvancedFilter[] = []
): FilterParams => {
  const filter =
    allFilters.advancedFilter?.filter(({ values }) => values.length > 0) ?? processSearchParams(allFilters, searchBy)

  return {
    filter: [...filter, ...globalFilter]
  }
}

const processOffsetParams = (allFilters: Filters, pageSize: number, pageIndex?: number): string => {
  if (allFilters.page) {
    const offset = (parseInt(allFilters.page) - 1) * pageSize
    return btoa(offset.toString())
  }

  if (pageIndex) {
    return btoa((pageIndex * pageSize).toString())
  }

  return btoa('0')
}

const processIncludeInactiveParams = (allFilters: Filters) => {
  return { includeInactive: allFilters.includeInactive }
}

export const processQueryParams = (
  group: string,
  searchBy: string[] = [],
  pageSize: number,
  globalFilter?: AdvancedFilter[],
  pageIndex?: number
): QueryParams => {
  const allFilters = filterStore.getFilters(group)
  const order = processOrderParams(allFilters)
  const filters = processFilterParams(allFilters, searchBy, group, globalFilter)
  const offset = processOffsetParams(allFilters, pageSize, pageIndex)
  const includeInactive = processIncludeInactiveParams(allFilters)
  const select = allFilters.select

  return { ...order, ...filters, ...includeInactive, ...select, pageSize, offset }
}
