import { useCallback, useEffect, useState } from 'react'

import { debounce } from 'lodash'

import { TagsInputProps } from 'components/Form/TagsInput'
import { FILTER_DEBOUNCE_TIMEOUT } from 'constants/config'
import { filterStore } from 'utilities/filters/filterStore'
import { removeAdvancedFilterRow, updateAdvancedFilterRow } from './utils'

import { AdvancedFilterRow, UseHandleFilterBothWays } from './types'

export const useHandleFilterBothWays: UseHandleFilterBothWays = ({ collection, filtersChanged, operator, path }) => {
  const advancedFilters: AdvancedFilter[] | undefined = filterStore.getFilters(collection).advancedFilter
  const linkedFilter: AdvancedFilter | undefined = advancedFilters?.find((af: AdvancedFilter) => af.by === path)
  const linkedFilterValues: string[] = linkedFilter?.values ?? []

  const [nameFilterValues, setNameFilterValues] = useState<TagsInputProps['value']>(linkedFilterValues)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFiltersDebounced = useCallback(
    debounce(
      (filters: AdvancedFilter[]) => filterStore.setFilter(collection, 'advancedFilter', filters),
      FILTER_DEBOUNCE_TIMEOUT
    ),
    []
  )

  useEffect(() => {
    if (filtersChanged) {
      if (linkedFilter) {
        setNameFilterValues(linkedFilter.operator === operator ? linkedFilterValues : [])
      } else {
        setNameFilterValues([])
      }
    }
  }, [filtersChanged]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Do not update AdvancedFilters if NameFilter and linked AdvancedFilterRow are empty or linked AdvancedFilterRow operator is different
    if (
      (nameFilterValues.length === 0 && linkedFilterValues.length === 0) ||
      (nameFilterValues.length === 0 && linkedFilter?.operator !== operator)
    ) {
      return
    }

    const updatedLinkedFilter: AdvancedFilter = {
      by: path,
      id: linkedFilter?.id,
      operator,
      values: nameFilterValues
    }

    if (advancedFilters) {
      const advancedFilterRow: AdvancedFilterRow = {
        advancedFilters,
        filterPath: path,
        onSetFiltersDebounced: setFiltersDebounced
      }

      if (nameFilterValues.length > 0) {
        updateAdvancedFilterRow(advancedFilterRow, nameFilterValues, updatedLinkedFilter)
      } else {
        removeAdvancedFilterRow(advancedFilterRow)
      }
    } else {
      setFiltersDebounced([updatedLinkedFilter])
    }
  }, [nameFilterValues, setNameFilterValues]) // eslint-disable-line react-hooks/exhaustive-deps

  return { value: nameFilterValues, setValue: setNameFilterValues }
}
