import React from 'react'

import { EditPage } from 'pages/quickwrap/ABTesting/EditPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { ListPage } from 'pages/quickwrap/ABTesting/ListPage'
import { NewPage } from 'pages/quickwrap/ABTesting/NewPage'
import { routesBuilder } from 'utilities/routesBuilder'

export const ABTestingRoutes = [
  <LayoutRoute key='ab-testing-edit' path={routesBuilder.quickwrap.abTesting.edit()} component={EditPage} />,
  <LayoutRoute key='ab-testing-list' exact path={routesBuilder.quickwrap.abTesting.root} component={ListPage} />,
  <LayoutRoute key='ab-testing-new' path={routesBuilder.quickwrap.abTesting.new} component={NewPage} />
]
