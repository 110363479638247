import React from 'react'

import { useTranslation } from 'react-i18next'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { ButtonTheme, ButtonType } from 'components/Button'
import { LinkButton } from 'components/LinkButton'
import NameFilter from 'components/NameFilter'
import { WorkspacesTable } from 'components/oss/Workspaces/WorkspacesTable'
import { useAdvancedFilterConfig } from 'containers/oss/Workspaces/useAdvancedFilterConfig'
import { routesBuilder } from 'utilities/routesBuilder'
import { CollectionNames } from 'webapp/constants/CollectionNames'

const COLLECTION = CollectionNames.Workspaces

export const WorkspacesContainer: React.FC = () => {
  const { t } = useTranslation('workspaces')

  const filterConfig = useAdvancedFilterConfig()

  return (
    <Box className='workspaces' list={true}>
      <BoxHeader
        title={t('list.title')}
        containerClassName={'box-header__container--space-between'}
        rowClassName={'header__row--width-100'}
      >
        <div>
          <LinkButton type={ButtonType.Primary} theme={ButtonTheme.Blue} to={routesBuilder.oss.workspaces.new}>
            {t('list.addNew')}
          </LinkButton>
        </div>

        <div className='box-header__filters'>
          <AdvancedFilter alignPopup='right' collectionName={COLLECTION} config={filterConfig} />

          <NameFilter collection={COLLECTION} operator={'contains'} path={'name'} />
        </div>
      </BoxHeader>

      <WorkspacesTable />
    </Box>
  )
}
