import { gql } from '@apollo/client'

export const OSS_PAGES_FOR_AD_UNIT_CONNECTING_TABLE = gql`
  query ossPagesForAdUnitConnectingTable($domainId: ID) {
    ossPages(domainId: $domainId) {
      nodes {
        id
        pageName
        path
      }
    }
  }
`
