import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { formStatus } from 'utilities/FormStatus'
import { getUserConfirmation } from 'utilities/history'
import { InfoBar } from 'components/InfoBar'
import { routesBuilder } from 'utilities/routesBuilder'
import { UserContext } from 'webapp/context/UserContext'

import RevokeImpersonation from 'gql/mutations/impersonalization/RevokeImpersonation.gql'

export const ImpersonalizationBar: React.FC = () => {
  const { t } = useTranslation('users')
  const history = useHistory<History>()
  const userContext = useContext(UserContext)
  const user = userContext.user

  const showInfoBar = userContext.isImpersonating

  const [revokeImpersonation] = useMutation(RevokeImpersonation, {
    variables: { input: {} },
    onCompleted: ({ revokeImpersonation }) => {
      userContext.updateToken(revokeImpersonation.credentials.accessToken)
      formStatus.clear()
      history.push(routesBuilder.oss.workspaces.root)
    }
  })

  const onClickHandler = () => {
    getUserConfirmation('unsavedChanges', (accepted) => {
      if (accepted) {
        revokeImpersonation()
      }
    })
  }

  return showInfoBar() ? (
    <InfoBar>
      <div className='info-bar__container'>
        {t('impersonalization.infoBar.message', { workspaceName: user?.workspace?.name, userEmail: user?.email })}

        <Button
          type={ButtonType.Secondary}
          theme={ButtonTheme.BlueOutline}
          buttonType='button'
          onClickHandler={onClickHandler}
        >
          {t('impersonalization.infoBar.button')}
        </Button>
      </div>
    </InfoBar>
  ) : null
}
