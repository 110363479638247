import React from 'react'

import { useTranslation } from 'react-i18next'
import { CellProps, Column } from 'react-table'

import { DateCell } from 'components/Table'
import DomainProductToggle from 'components/shared/Inventory/Domains/DomainProductToggle'
import { Product } from 'constants/Product'
import i18n from 'webapp/i18n'
import { ActionsCell } from './ActionsCell/ActionsCell'
import AdUnitsCounterCell from './AdUnitsCounterCell'
import { ReviveDomainConfigurationRow } from './ReviveDomainConfigurationRow'
import TargetingCell from './TargetingCell'

export const useColumnsConfig = (): Column<ReviveDomainConfigurationRow>[] => {
  const { t } = useTranslation('revive')

  return [
    {
      Header: () => t('configuration.columns.domainName'),
      accessor: 'domainName'
    },
    {
      Header: () => t('configuration.columns.adUnits'),
      id: 'adUnits',
      Cell: ({ row }: CellProps<ReviveDomainConfigurationRow>) => <AdUnitsCounterCell row={row} />,
      disableSortBy: true
    },
    {
      Header: () => t('configuration.columns.targeting.label'),
      accessor: 'matchType',
      Cell: ({ row }: CellProps<ReviveDomainConfigurationRow>) => <TargetingCell row={row} />,
      disableSortBy: true
    },
    {
      Header: () => t('configuration.columns.lastModified'),
      accessor: 'updatedAt',
      Cell: ({ value }) => <DateCell value={value} />,
      disableSortBy: true
    },
    {
      Header: () => t('configuration.columns.revive'),
      accessor: 'reviveEnabled',
      Cell: ({
        row: {
          original: { domainId, reviveEnabled }
        }
      }: CellProps<ReviveDomainConfigurationRow>): JSX.Element => (
        <DomainProductToggle domainId={domainId} initialStatus={reviveEnabled} product={Product.Revive} />
      ),
      disableSortBy: true
    },
    {
      Header: () => i18n.t('table.header.actions'),
      id: 'actions',
      Cell: ({ row }: CellProps<ReviveDomainConfigurationRow>) => <ActionsCell row={row} />,
      disableSortBy: true
    }
  ]
}
