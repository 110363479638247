import React, { useContext, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { ConfirmationModal } from 'components/Modals/ConfirmationModal'
import { DropdownMenu } from 'components/DropdownMenu'
import { Edit } from 'icons/Edit'
import { Eye } from 'icons/Eye'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { routesBuilder } from 'utilities/routesBuilder'

import UpdateWorkspace from 'gql/mutations/workspaces/UpdateWorkspace.gql'

interface Props {
  row: Row<Workspace>
}

export const ActionsCell: React.FC<Props> = ({ row }) => {
  const productRow = row.original

  const { t } = useTranslation('dataStudio')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const onPreviewHandler = () => {
    history.push(routesBuilder.oss.finance.dataStudioLinks.preview(productRow.id))
  }

  const onEditHandler = () => {
    history.push(routesBuilder.oss.finance.dataStudioLinks.edit(productRow.id))
  }

  const onDeleteHandler = () => {
    setIsDeleteModalVisible(true)
    setIsDropdownVisible(false)
  }

  const workspaceInfo = {
    subject: row.original.name
  }

  const [updateWorkspace] = useMutation(UpdateWorkspace, {
    onCompleted: ({ updateWorkspace: { workspace } }) => {
      setIsDeleteModalVisible(false)
      if (workspace) {
        createNotifier(t('links.modal.deleteConfirm.notification.success', workspaceInfo), NotificationType.SUCCESS)
        history.push(history.location)
      } else {
        createNotifier(t('links.modal.deleteConfirm.notification.error', workspaceInfo), NotificationType.ERROR)
      }
    },
    onError: () => {
      setIsDeleteModalVisible(false)
      createNotifier(t('links.modal.deleteConfirm.notification.error', workspaceInfo), NotificationType.ERROR)
    }
  })

  const confirmHandler = () => {
    updateWorkspace(nestGqlInput({ workspaceId: row.original.id, dataStudioUrl: null }))
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownMenu setVisible={setIsDropdownVisible} visible={isDropdownVisible}>
        <MenuItem onClick={onPreviewHandler}>
          {t('common:buttons.preview')} <Eye />
        </MenuItem>

        <MenuItem onClick={onEditHandler}>
          {t('common:buttons.edit')} <Edit />
        </MenuItem>

        <MenuItem onClick={onDeleteHandler}>
          {t('common:buttons.delete')} <Bin />
        </MenuItem>
      </DropdownMenu>

      <ConfirmationModal
        visible={isDeleteModalVisible}
        setVisible={setIsDeleteModalVisible}
        content={t('links.modal.deleteConfirm.content', workspaceInfo)}
        confirmButtonText={t('common:buttons.delete')}
        cancelButtonText={t('common:buttons.cancel')}
        onConfirmHandler={confirmHandler}
      />
    </div>
  )
}
