export const unique = <T extends Record<never, never>, K>(array: T[], mapping: (element: T) => K): T[] => {
  const result: T[] = []

  const alreadyExists = (key: K) => {
    return !!result.find((element) => mapping(element) === key)
  }

  array.forEach((element) => {
    const key = mapping(element)

    if (!key || !alreadyExists(key)) {
      result.push(element)
    }
  })

  return result
}

export const groupBy = <T extends Record<string, string>, K extends keyof T>(array: T[], key: K): Record<T[K], T[]> => {
  return array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {} as Record<T[K], T[]>)
}

export const intersection = <T, K>(arrays: T[][], mapping?: (element: T) => K): T[] => {
  if (arrays.length === 0) return []

  return arrays.reduce((previousSubArray, currentSubArray) =>
    previousSubArray.filter((element) => includes(currentSubArray, element, mapping))
  )
}

const includes = <T, K>(array: T[], needle: T, mapping?: (element: T) => K) => {
  if (!mapping) return array.includes(needle)

  const predicateNeedle = mapping(needle)
  for (const element of array) {
    if (mapping(element) === predicateNeedle) return true
  }

  return false
}
