import { useTranslation } from 'react-i18next'

import { getProductName } from 'utilities/products/utils'
import { FilterType } from 'webapp/constants/FilterType'
import { Product } from 'webapp/constants/Product'

type ProductSelectOption = Pick<SelectOption<Product>, 'value'> & { label: string }

const productSelectOptions = (): SelectOption[] => {
  const products: ProductSelectOption[] = Object.keys(Product).map((key: string) => {
    const value: Product = Product[key as keyof typeof Product]
    const label: string = getProductName(value)

    return {
      label,
      value
    }
  })

  return products.sort((a: ProductSelectOption, b: ProductSelectOption) => a.label.localeCompare(b.label))
}

export const useFilterConfig = (): AdvancedFilterConfiguration[] => {
  const { t } = useTranslation('history')

  return [
    {
      label: t('filters.label.element'),
      name: 'name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder'),
      type: FilterType.Input
    },
    {
      buildValueOptions: productSelectOptions,
      label: t('filters.label.product'),
      name: 'product',
      type: FilterType.Select
    },
    {
      label: t('filters.label.user'),
      name: 'user.fullName',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder'),
      type: FilterType.Input
    }
  ]
}
