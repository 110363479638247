import { useContext, useState } from 'react'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'

import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'

import BulkUploadAdUnit from 'gql/mutations/domains/BulkUploadAdUnit.gql'
import BulkUploadAdUnitJob from 'gql/queries/domains/BulkUploadAdUnitJob.gql'

interface HookResponse {
  clearBulkUploadStatus: () => void
  createBulkUploadAdUnitJob: (file: BulkUploadFileData) => void
  isSubmitting: boolean
  message: BulkUploadMessage | undefined
}

export const useBulkUploadAdUnitJob = (): HookResponse => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const createBulkUploadAdUnitJob = (fileData: BulkUploadFileData) => {
    setIsSubmitting(true)
    bulkUploadAdUnitJob({ variables: { input: fileData } })
  }

  const [bulkUploadAdUnitJob] = useMutation(BulkUploadAdUnit, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ createBulkUploadAdUnitJob }) =>
      bulkUploadJobStatus({ variables: { bulkUploadAdUnitJobId: createBulkUploadAdUnitJob.bulkUploadAdUnitJob.id } })
  })

  const [bulkUploadJobStatus, { stopPolling }] = useLazyQuery(BulkUploadAdUnitJob, {
    pollInterval: 3000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ bulkUploadAdUnitJob }) => getBulkUploadJobStatusSuccessHandler(bulkUploadAdUnitJob),
    onError: (error) => getBulkUploadJobStatusErrorHandler(error)
  })

  const getBulkUploadJobStatusSuccessHandler = (bulkUploadAdUnitJob: any) => {
    const { message, status } = bulkUploadAdUnitJob

    if (status === 'finished') {
      stopPolling && stopPolling()
      setMessage(message)
      setIsSubmitting(false)
    }
  }

  const getBulkUploadJobStatusErrorHandler = (error: ApolloError) => {
    stopPolling && stopPolling()
    setIsSubmitting(false)
    createNotifier(error.message, NotificationType.ERROR, 10)
  }

  const clearBulkUploadStatus = () => {
    setIsSubmitting(false)
    setMessage(undefined)
  }

  return {
    clearBulkUploadStatus,
    createBulkUploadAdUnitJob,
    isSubmitting,
    message
  }
}
