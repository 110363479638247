import React from 'react'
import classNames from 'classnames'
import { Grid } from '@material-ui/core'

interface Props {
  className?: string
  title?: string
  withBorder?: boolean
}

export const FormSection: React.FC<Props> = ({ className, children, title, withBorder }) => {
  const containerClass = classNames('ad_unit-form-section', className)

  const bodyClass = classNames({ 'with-border': withBorder })

  return (
    <div className={containerClass}>
      {title && <h2 className='ad_unit-form-section__title'>{title}</h2>}
      <div className={bodyClass}>
        <Grid container spacing={10}>
          {children}
        </Grid>
      </div>
    </div>
  )
}
