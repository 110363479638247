import React from 'react'

import { useTranslation } from 'react-i18next'

import { AdvancedFilter } from 'components/AdvancedFilter'
import { Box, BoxHeader } from 'components/Box'
import { HistoryTable } from 'components/History/HistoryTable'
import NameFilter from 'components/NameFilter'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { useFilterConfig } from './useFilterConfig'

const COLLECTION = CollectionNames.HistoryActions

export const HistoryContainer: React.FC = () => {
  const { t } = useTranslation('history')
  const filterConfig = useFilterConfig()

  return (
    <Box list>
      <BoxHeader
        title={t('list.title')}
        containerClassName='box-header__container'
        rowClassName='header__row--width-100'
      >
        <div className='box-header__filters'>
          <AdvancedFilter alignPopup='right' collectionName={COLLECTION} config={filterConfig} />

          <NameFilter collection={COLLECTION} operator={'contains'} path='name' placeholder={t('filters.nameFilter')} />
        </div>
      </BoxHeader>

      <HistoryTable />
    </Box>
  )
}
