import React from 'react'
import { useField, FieldConfig } from 'formik'

import { TagInput } from 'components/Form'

type FormikTagInputContainerType = {
  className?: string
  disabled?: boolean
  errors?: string[]
  id: string
  label: string
  onChangeHandler: (values: string[]) => void
  options: string[]
  placeholder?: string
  single?: boolean
  tooltipContent?: string
  values: string[]
  field: FieldConfig
}

export const FormikTagInputContainer = ({
  className,
  disabled,
  errors,
  id,
  label,
  onChangeHandler,
  options,
  placeholder,
  single,
  tooltipContent,
  values,
  ...other
}: FormikTagInputContainerType): JSX.Element => {
  const [field, , helpers] = useField(other.field.name)
  const onChange = (values: string[] = []) => {
    helpers.setValue(values)
    onChangeHandler && onChangeHandler(values)
  }

  return (
    <TagInput
      className={className}
      disabled={disabled}
      errors={errors}
      id={id}
      label={label}
      name={field.name}
      onChangeHandler={onChange}
      options={options}
      placeholder={placeholder}
      single={single}
      tooltipContent={tooltipContent}
      values={field.value}
    />
  )
}
