import React, { useEffect, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useLazyQuery, ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { restoreGamParamsAfterRedirect } from 'components/oss/Workspaces/WorkspaceGamConnector/utils'

import ExchangeAuthorizationCode from 'gql/queries/reports/ExchangeAuthorizationCode.gql'

interface Props {
  errorTargetUrl: string
  oauthCallbackUrl: string
  targetUrl: string
}

export const GamRedirect: React.FC<Props> = ({ errorTargetUrl, oauthCallbackUrl, targetUrl }) => {
  const { t } = useTranslation('reports')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { code } = queryString.parse(history.location.search)

  const [getRefreshToken] = useLazyQuery(ExchangeAuthorizationCode, {
    onCompleted: ({ exchangeAuthorizationCode: { refreshToken } }) => {
      history.push({
        pathname: targetUrl,
        search: restoreGamParamsAfterRedirect(refreshToken)
      })
    },
    onError: (error: ApolloError) => {
      const message = error.graphQLErrors[0]?.message || error.message

      returnToErrorTarget(message)
    }
  })

  const returnToErrorTarget = useCallback(
    (error: string) => {
      history.push(errorTargetUrl)

      createNotifier(error, NotificationType.ERROR)
    },
    [createNotifier, errorTargetUrl, history]
  )

  useEffect(() => {
    if (code) {
      getRefreshToken({ variables: { code, oauthCallbackUrl } })
    } else {
      returnToErrorTarget(t('formPage.errors.noGAMCode'))
    }
  }, [code, getRefreshToken, oauthCallbackUrl, returnToErrorTarget, t])

  return <></>
}
