import React from 'react'

export const Filter: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        id='Icon_filter'
        data-name='Icon filter'
        d='M5.85498 10.291H8.14398V9.19098H5.85498V10.291ZM1.85498 3.70898V4.80898H12.146V3.70898H1.85498ZM3.56998 7.54798H10.431V6.44798H3.56998V7.54798Z'
        fill='#fff'
      />
    </svg>
  )
}
