import React from 'react'

import { Column } from 'react-table'

import i18n from 'webapp/i18n'
import { BiddersOnDomainCell } from './BiddersOnDomainCell'
import { FormSchema } from './formSchema'
import { bidderColumn } from './bidderColumn'

export enum SortColumn {
  AD_UNITS_PLUGGED = 'adUnitsPlugged',
  NAME = 'name'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

enum ConnectionTypes {
  CLIENT = 'client',
  CLIENT_SERVER = 'client_server',
  SERVER = 'server'
}

export type SortConfig = {
  column: SortColumn
  direction: SortDirection
}

export const columnsConfig = (
  formSchema: FormSchema,
  values: Record<string, boolean | SelectOption>,
  sortConfig: SortConfig,
  setSortConfig: (sortConfig: SortConfig) => void
): Column<AdUnitConnection>[] => {
  sortFunction(formSchema, sortConfig)

  return [
    {
      Header: () => <BiddersOnDomainCell sortConfig={sortConfig} setSortConfig={setSortConfig} />,
      disableSortBy: true,
      id: 'biddersOnDomain',
      columns: [
        {
          Header: i18n.t('setup:bidders.inventoryConnection.dataTable.bidderAvailability') as string,
          disableSortBy: true,
          accessor: 'adUnitPath'
        }
      ]
    },
    ...formSchema.bidders.map((bidder) => bidderColumn(bidder, values, formSchema.configured))
  ]
}

const sortFunction = (formSchema: FormSchema, sortConfig: SortConfig) => {
  formSchema.bidders.sort(sortByName)

  if (sortConfig.column === SortColumn.AD_UNITS_PLUGGED) {
    formSchema.bidders.sort(sortByPluggedAdUnitsCount(formSchema))
  }

  if (sortConfig.direction === SortDirection.DESC) {
    formSchema.bidders.reverse()
  }
}

const sortByName = (a: BidderConnection, b: BidderConnection) => a.name.localeCompare(b.name)

const sortByPluggedAdUnitsCount = (formSchema: FormSchema) => (a: BidderConnection, b: BidderConnection) => {
  const pluggedA = getPluggedAUnitsCount(a, formSchema)
  const pluggedB = getPluggedAUnitsCount(b, formSchema)

  return pluggedB - pluggedA
}

const getPluggedAUnitsCount = (bidder: BidderConnection, formSchema: FormSchema) => {
  const connectionType = getConnectionType(bidder, formSchema)

  return Object.keys(formSchema.configured)
    .filter(filterConnectionType(bidder, connectionType))
    .filter(fillterActiveBidders(bidder, formSchema))
    .filter(filterPluggedAdUnits(formSchema)).length
}

const getConnectionType = (bidder: BidderConnection, formSchema: FormSchema) =>
  Object.entries(formSchema.connections).find(([key]) => key.includes(bidder.id))?.[1].value

const filterConnectionType = (
  bidder: BidderConnection,
  connectionType: string | number | boolean | number[] | Date | undefined
) => {
  return (configured: string) =>
    connectionType === ConnectionTypes.CLIENT_SERVER
      ? configured.includes(bidder.id)
      : configured.includes(bidder.id) && configured.includes(ConnectionTypes.CLIENT ? 'c2s' : 's2s')
}

const fillterActiveBidders = (bidder: BidderConnection, formSchema: FormSchema) => (configured: string) =>
  formSchema.initialValues[bidder.id] && configured.includes(bidder.id)

const filterPluggedAdUnits = (formSchema: FormSchema) => (configured: string) =>
  formSchema.configured[configured] && formSchema.initialValues[configured]
