import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ConfirmationModal } from 'components/Modals'
import { Error } from 'utilities/errorUtils'
import { ErrorUtils } from 'utilities/errorUtils'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { NextActiveVersionModal } from './Modals/NextActiveVersionModal'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ToggleSwitch } from 'components/Form'
import { UpdateDomainVersionErrors } from 'webapp/constants/UpdateDomainVersionErrors'
import { VersionedDomainContext } from 'webapp/context/VersionedDomainContext'
import { VersionStatusCellProps, UpdateDomainVersionData, UpdateDomainVersionVars } from './types'

import UPDATE_DOMAIN_VERSION from 'gql/mutations/domains/UpdateDomainVersion.gql'

export const VersionStatusCell: React.FC<VersionStatusCellProps> = ({
  isABTestRunning,
  row,
  testedDomainVersionIds
}) => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { refreshDomainVersionQuery } = useContext(VersionedDomainContext)
  const [nextActiveModalVisible, setNextActiveModalVisible] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const domainVersion: DomainVersion = row.original
  const isAbTestVersion = isABTestRunning && testedDomainVersionIds.includes(domainVersion.id)
  const { active } = domainVersion

  const toggleSwitchClassString = classNames('version-status__switch', {
    'qw-toggle-switch--blue': isAbTestVersion
  })

  const [updateDomainVersion] = useMutation<UpdateDomainVersionData, { input: UpdateDomainVersionVars }>(
    UPDATE_DOMAIN_VERSION,
    {
      onCompleted: ({ updateDomainVersion: { domainVersion, errors } }): void => {
        setLoading(false)

        if (errors.length > 0) {
          handleErrors(errors)
        } else if (domainVersion) {
          refreshDomainVersionQuery()
        }
      }
    }
  )

  const onUpdateDomainVersion = (
    version: Pick<DomainVersion, 'id' | 'name'>,
    activeState: DomainVersion['active']
  ): void =>
    void updateDomainVersion(
      nestGqlInput({
        active: activeState,
        domainVersionId: version.id,
        name: version.name
      })
    )

  const handleErrors = (errors: Error[]): void => {
    const activeErrMsg = new ErrorUtils(errors).errorsFor('active')[0]

    if (activeErrMsg) {
      switch (activeErrMsg) {
        case UpdateDomainVersionErrors.LastVersion: {
          createNotifier(t('domains.edit.versions.lastVersionError'), NotificationType.ERROR)
          break
        }
        case UpdateDomainVersionErrors.LastActiveVersion: {
          setNextActiveModalVisible(true)
          break
        }
      }
    }
  }

  const toggleActive = (): void => {
    setLoading(true)
    onUpdateDomainVersion(domainVersion, !active)
  }

  const toggleId = `version-status-${domainVersion.id}`

  return (
    <>
      <ToggleSwitch
        key={domainVersion.id}
        id={toggleId}
        name={toggleId}
        checked={active || testedDomainVersionIds.includes(domainVersion.id)}
        className={toggleSwitchClassString}
        disabled={loading || isABTestRunning}
        labelFalse={isAbTestVersion ? t('domains.edit.versions.abTestToggle') : undefined}
        labelTrue={isAbTestVersion ? t('domains.edit.versions.abTestToggle') : undefined}
        tooltipContent={isABTestRunning ? t('domains.edit.versions.disabledStatusTooltip') : ''}
        onChange={(): void => (active ? toggleActive() : setConfirmModalVisible(true))}
      />

      <NextActiveVersionModal
        visible={nextActiveModalVisible}
        setVisible={setNextActiveModalVisible}
        onConfirmHandler={(nextDomainVersion) => onUpdateDomainVersion(nextDomainVersion, true)}
        currentVersion={domainVersion}
      />

      <ConfirmationModal
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        content={t('domains.edit.versions.confirmModal.content', { name: domainVersion.name })}
        confirmButtonText={t('domains.edit.versions.confirmModal.plugIn')}
        cancelButtonText={t('Cancel')}
        onConfirmHandler={() => void toggleActive()}
      />
    </>
  )
}
