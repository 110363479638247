import React from 'react'
import { Field, Formik, FormikHelpers } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput, FormikSelectContainer as FormikSelect } from 'containers/FormikContainers'
import { InviteUserFactory } from './formSchema'
import { StatusForm } from 'components/Form'
import { usePublisherRoleOptions } from 'utilities/roles/options'

interface Props {
  errors: Errors
  isSubmitting: boolean
  onSubmit: (values: InviteUserSchema, actions: FormikHelpers<InviteUserSchema>) => void
}

export const InviteFormForPublishers: React.FC<Props> = ({ errors, isSubmitting, onSubmit }) => {
  const { t } = useTranslation('users')

  const roleOptions = usePublisherRoleOptions()

  return (
    <div className='invite-form qw-form'>
      <Formik initialValues={InviteUserFactory()} onSubmit={onSubmit}>
        {({ dirty }) => (
          <StatusForm name='oss-invite-publisher' dirty={dirty}>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={6}>
                <Field
                  component={FormikInput}
                  errors={errors.errorsFor('email')}
                  id='email'
                  label={t('invite.email.label')}
                  name='email'
                  placeholder={t('invite.email.placeholder')}
                  type='text'
                />

                <Field
                  component={FormikSelect}
                  errors={errors.errorsFor('role')}
                  id='role'
                  label={t('invite.role.publisher.label')}
                  name='role'
                  options={roleOptions}
                  placeholder={t('invite.role.publisher.placeholder')}
                />
              </Grid>
            </Grid>

            <Button
              buttonType='submit'
              className='qw-submit'
              disabled={isSubmitting || !dirty}
              theme={ButtonTheme.Blue}
              type={ButtonType.Primary}
            >
              {isSubmitting ? t('invite.submitting') : t('invite.submit')}
            </Button>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
