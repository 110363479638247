import React, { useContext, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { Row } from 'react-table'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Bin } from 'icons/Bin'
import { Can } from 'webapp/context/AbilityContext'
import { ConfirmationModal } from 'components/Modals'
import { DestroyPageData, DestroyPageVars } from 'components/oss/Inventory/Pages/PagesTable/types'
import { DropdownMenu } from 'components/DropdownMenu'
import { Edit } from 'icons/Edit'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { OssPageRow } from 'components/oss/Inventory/Pages/PagesTable/OssPageRow'
import { routesBuilder } from 'utilities/routesBuilder'

import DESTROY_OSS_PAGE from 'gql/mutations/ossPages/DestroyOssPage.gql'

type Props = {
  row: Row<OssPageRow>
}

export const ActionsCell = ({ row }: Props): JSX.Element => {
  const ossPageRow: OssPageRow = row.original

  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('inventory')

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

  const [destroyPage] = useMutation<DestroyPageData, DestroyPageVars>(DESTROY_OSS_PAGE, {
    onCompleted: ({ destroyOssPage: { errors } }) => {
      setVisibleDeleteModal(false)
      if (errors.length) {
        createNotifier(
          t('pages.modal.deleteConfirm.notification.error', { pageName: ossPageRow.pageName }),
          NotificationType.ERROR
        )
      } else {
        createNotifier(
          t('pages.modal.deleteConfirm.notification.success', { pageName: ossPageRow.pageName }),
          NotificationType.SUCCESS
        )
        history.push(history.location)
      }
    }
  })

  const onConfirmDelete = (): void => {
    destroyPage(nestGqlInput({ ossPageId: ossPageRow.id }))
  }

  const onDelete = (): void => {
    setVisibleDeleteModal(true)
  }

  const onEdit = (): void => {
    history.push(routesBuilder.oss.inventory.pages.edit(ossPageRow.id))
  }

  return (
    <div onClick={(e): void => e.stopPropagation()}>
      <DropdownMenu setVisible={setDropdownVisible} visible={dropdownVisible}>
        <MenuItem onClick={onEdit}>
          {t('common:buttons.edit')}
          <Edit />
        </MenuItem>

        <Can I={AbilityAction.DELETE} a={AbilitySubject.DOMAIN}>
          <MenuItem onClick={onDelete}>
            {t('common:buttons.delete')}
            <Bin />
          </MenuItem>
        </Can>
      </DropdownMenu>

      <ConfirmationModal
        cancelButtonText={t('common:buttons.cancel')}
        confirmButtonText={t('common:buttons.delete')}
        content={t('pages.modal.deleteConfirm.content', { pageName: ossPageRow.path })}
        onConfirmHandler={onConfirmDelete}
        setVisible={setVisibleDeleteModal}
        visible={visibleDeleteModal}
      />
    </div>
  )
}
