import { useContext, useState } from 'react'

import { useQuery } from '@apollo/client'
import { UserContext } from 'context/UserContext'
import { UseGeneralSettingsProps } from './types'

import AdminGeneralSettingsQuery from 'gql/queries/settings/AdminGeneralSettings.gql'
import GeneralSettingsQuery from 'gql/queries/settings/GeneralSettings.gql'

export const useGeneralSettings = (workspaceId?: Domain['workspaceId']): UseGeneralSettingsProps => {
  const { isSuperuser } = useContext(UserContext)
  const [generalSettings, setGeneralSettings] = useState<GeneralSetting>()

  useQuery(GeneralSettingsQuery, {
    fetchPolicy: 'network-only',
    skip: isSuperuser,
    onCompleted: ({ generalSettings }) => setGeneralSettings(generalSettings)
  })

  useQuery(AdminGeneralSettingsQuery, {
    fetchPolicy: 'network-only',
    skip: !isSuperuser,
    variables: { workspaceId },
    onCompleted: ({ generalSettings }) => setGeneralSettings(generalSettings)
  })

  return { generalSettings }
}
