import { useTranslation } from 'react-i18next'

import { FilterType } from 'webapp/constants/FilterType'

export const useFilterConfig = (): AdvancedFilterConfiguration[] => {
  const { t } = useTranslation('inventory')

  return [
    {
      label: t('filters.label.adUnitPath'),
      name: 'ad_unit_path',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.adUnitPath'),
      type: FilterType.Input
    }
  ]
}
