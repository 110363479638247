import React from 'react'

import LeftArrow from 'images/left-arrow.svg'
import RightArrow from 'images/right-arrow.svg'

interface Item {
  onClick: (event: React.SyntheticEvent<Element, Event>) => void
  disabled: boolean
}

type PaginationButtonType = string | JSX.Element

export const generateButtons = (item: Item, page: number, type: string, selected: boolean): PaginationButtonType => {
  let children = null

  if (type === 'start-ellipsis' || type === 'end-ellipsis') {
    children = '…'
  } else if (type === 'page') {
    children = (
      <button {...item} className={`item__button ${selected ? 'selected' : ''}`}>
        {page}
      </button>
    )
  } else {
    children = (
      <button {...item} className='item__button'>
        <img src={type === 'previous' ? LeftArrow : RightArrow} />
      </button>
    )
  }

  return children
}
