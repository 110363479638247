import React, { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { SidebarItemType } from 'components/Sidebar/types'
import { SidebarLink } from 'components/Sidebar/SidebarLink'
import { SidebarSubmenuItem } from 'components/Sidebar/SidebarSubmenuItem'

import { removeQueryParams } from 'utilities/removeQueryParams'

interface Props {
  item: SidebarItemType
}

export const SidebarItem: React.FC<Props> = ({ item }) => {
  const location = useLocation()

  const [isExpanded, setIsExpanded] = useState<boolean>(() => {
    const isIncludedInPathname = (href?: string) => href && location.pathname.includes(removeQueryParams(href))

    return Boolean(
      item.subItems?.some((el) => {
        return el.subItems
          ? el.subItems.some((subEl) => isIncludedInPathname(subEl.href))
          : isIncludedInPathname(el.href)
      })
    )
  })
  const toggleSubmenu = useCallback(() => setIsExpanded(!isExpanded), [isExpanded])

  const hasSubMenu = Boolean(item.subItems)
  const expandedClass = hasSubMenu ? 'expand-trigger' : ''

  return item.visible ? (
    <li className={`sidebar__item ${expandedClass}`}>
      <SidebarLink
        title={item.title}
        subtitle={item.subtitle}
        href={item.href}
        icon={item.icon}
        visible={item.visible}
        hasSubMenu={hasSubMenu}
        isExpanded={isExpanded}
        onToggleSubmenu={toggleSubmenu}
        className={item.className}
        absolute={item.absolute}
      />

      {item.subItems && isExpanded && (
        <ul className={'sidebar__sub-menu'}>
          {item.subItems
            .filter((subItem) => subItem.visible)
            .map((subItem) => (
              <SidebarSubmenuItem key={subItem.title} subItem={subItem} />
            ))}
        </ul>
      )}
    </li>
  ) : null
}
