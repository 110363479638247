import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { InfoBar } from 'components/InfoBar'
import { DomainForm } from 'components/quickwrap/Inventory/Domains/Form/DomainForm'
import { DomainFactory } from 'components/quickwrap/Inventory/Domains/Form/formSchema'
import { DomainPriceFloorContainer } from 'containers/quickwrap/Inventory/Domains/DomainPriceFloorContainer'
import { DomainVersionPrebidModulesContainer } from 'containers/quickwrap/Inventory/Domains/DomainVersionPrebidModulesContainer'
import { GeneralSettingsModal } from 'components/quickwrap/Setup/GeneralSettingsModal'
import { DomainVersionSelect } from 'components/shared/Inventory/Domains/DomainVersionSelect'
import { NotificationType } from 'components/ToastNotifier'
import { CustomSettingsContainer } from 'containers/quickwrap/CustomSettings/CustomSettingsContainer'
import { AdUnitsContainer } from 'containers/quickwrap/Inventory/Domains/AdUnitsContainer'
import { DomainNetworksContainer } from 'containers/quickwrap/Inventory/Domains/DomainNetworksContainer'
import { DomainVersionsContainer } from 'containers/quickwrap/Inventory/Domains/DomainVersionsContainer'
import { PrebidDevicesContainer } from 'containers/shared/Inventory/Domains/DevicesContainer'
import { DomainCommentContainer } from 'containers/shared/Inventory/Domains/DomainCommentContainer'
import { DomainSerializer } from 'serializers/DomainSerializer'
import { NO_CACHE } from 'utilities/apolloClient'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { routesBuilder } from 'utilities/routesBuilder'
import { useTestedDomainVersionIds } from 'utilities/useTestedDomainVersionIds'
import { Product } from 'webapp/constants/Product'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { VersionedDomainProvider } from 'webapp/context/VersionedDomainContext'

import UPDATE_VERSIONED_DOMAIN from 'gql/mutations/domains/UpdateVersionedDomain.gql'
import GET_VERSIONED_DOMAIN from 'gql/queries/domains/VersionedDomain.gql'

interface DomainData {
  versionedDomain: VersionedDomain
}

interface DomainVars {
  domainId: string
  domainVersionId: string
}

export const EditPage: React.FC = () => {
  const { t } = useTranslation('inventory')
  const history = useHistory<History>()
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [domain, setDomain] = useState<VersionedDomain | undefined>(undefined)
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const { domainId } = useParams<{ domainId: string }>()
  const { domainVersionId } = useParams<{ domainVersionId: string }>()

  const [customSettings, setCustomSettings] = useState<GeneralSetting | undefined>(undefined)
  const [isSettingsForm, setIsSettingsForm] = useState(false)
  const [modalVisible, settingsVisible] = useState(false)

  const customSettingsRef = useRef<HTMLDivElement>(null)
  const scrollToCustomSettings = () => {
    const csEl: HTMLDivElement | null = customSettingsRef.current
    csEl && csEl.scrollIntoView({ behavior: 'smooth' })
  }

  const { refetch: refetchDomain } = useQuery<DomainData, DomainVars>(GET_VERSIONED_DOMAIN, {
    ...NO_CACHE,
    variables: { domainId, domainVersionId },
    onCompleted: ({ versionedDomain }) => {
      setDomain(versionedDomain)
      setCustomSettings(versionedDomain.settings)
      setIsSettingsForm(Boolean(versionedDomain.settings))
    }
  })

  useEffect(() => {
    refetchDomain()
  }, [refetchDomain, domainId, domainVersionId])

  const [updateVersionedDomain, { loading: mutationLoading }] = useMutation(UPDATE_VERSIONED_DOMAIN, {
    onCompleted: ({ updateVersionedDomain: { domain: updatedData, errors } }) => {
      if (errors.length) {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      } else {
        createNotifier(t('domains.edit.successMessage', { name: updatedData.name }), NotificationType.SUCCESS)
        setDomain(Object.assign(domain!, updatedData))
        formStatus.clear()
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const updateVersionedDomainHandler = useCallback(
    async (domain: DomainFormData) => {
      const serializer = new DomainSerializer(domain)
      updateVersionedDomain({
        variables: {
          input: serializer.updateParams()
        }
      })
    },
    [updateVersionedDomain]
  )

  const runningABTest = domain?.abTests.find((test) => test.active)
  const testedDomainVersionIds = useTestedDomainVersionIds(domain)

  return (
    <>
      {domain && (
        <VersionedDomainProvider domainId={domain.id}>
          {runningABTest && (
            <InfoBar>
              {t('domains.edit.abTestRunning', { domainName: domain.name })} &quot;
              <Link
                className='domain-active-ab-test-link'
                to={routesBuilder.quickwrap.abTesting.edit(runningABTest.id)}
              >
                {runningABTest.name}
              </Link>
              &quot;
            </InfoBar>
          )}

          <BoxHeader title={domain?.name || ' '} rowClassName={'domain-edit-header__row'}>
            <DomainVersionSelect
              initialVersionId={domainVersionId}
              onChangeManaged={(versionId) => {
                history.push(routesBuilder.quickwrap.inventory.domains.edit(domainId, versionId))
              }}
            />

            {!isSettingsForm && (
              <Button
                type={ButtonType.Primary}
                theme={ButtonTheme.BlueReverse}
                bgTransparent={true}
                onClickHandler={(): void => {
                  setIsSettingsForm(true)
                  scrollToCustomSettings()
                }}
              >
                {t('domains.edit.customSettings')}
              </Button>
            )}

            <Button
              className='general-settings__divider'
              onClickHandler={() => settingsVisible(true)}
              theme={ButtonTheme.BlueDark}
              type={ButtonType.Primary}
            >
              {t('domains.edit.generalSettings')}
            </Button>
          </BoxHeader>

          <DomainCommentContainer domainId={domainId} product={Product.Quickwrap} />

          <DomainVersionsContainer
            domainId={domainId}
            isABTestRunning={Boolean(runningABTest)}
            testedDomainVersionIds={testedDomainVersionIds}
          />

          <Box isExpandable={true}>
            <BoxHeader title={t('domains.edit.title')} />

            <BoxBody>
              <DomainForm
                domain={DomainFactory(domain) as DomainFormData}
                errors={errors}
                isSubmitting={mutationLoading}
                onSubmit={updateVersionedDomainHandler}
              />

              <GeneralSettingsModal
                generalSettings={domain.workspace.settings}
                visible={modalVisible}
                setVisible={settingsVisible}
              />
            </BoxBody>
          </Box>

          <div ref={customSettingsRef}>
            {isSettingsForm && (
              <CustomSettingsContainer
                customSettings={customSettings}
                generalSettings={domain.workspace.settings}
                setCustomSettings={setCustomSettings}
                setIsSettingsForm={setIsSettingsForm}
              />
            )}
          </div>

          <DomainNetworksContainer domain={domain} />

          <DomainPriceFloorContainer domain={domain} key={domain.versionId} />

          <PrebidDevicesContainer prebidDeviceableId={domainVersionId} workspaceId={domain.workspace.id} />

          <DomainVersionPrebidModulesContainer
            domain={domain}
            updateVersionedDomainHandler={updateVersionedDomainHandler}
          />

          <AdUnitsContainer domain={domain} readOnly />
        </VersionedDomainProvider>
      )}
    </>
  )
}
