/* eslint-disable @typescript-eslint/no-explicit-any */
import { groupBy } from 'utilities/arrays'

type ReduceNextValue = { [x: string]: string | number }
type MapValue = { [x: string]: string }

export class ReportResultsDataSerializer {
  private _data?: Array<any>

  constructor(data?: Array<any>) {
    this._data = data
  }

  public setData(newData: Array<any>): void {
    this._data = newData
  }

  public get results(): Array<ReportData> {
    return this.processData()
  }

  private processData(): Array<ReportData> {
    const grupped = groupBy(this._data || [], 'Ad unit ID')
    const results: Array<ReportData> = []

    for (const key of Object.keys(grupped)) {
      const values = grupped[key]
      const result = {
        name: values[0]['Ad unit name'],
        id: values[0]['Ad unit ID'],
        total_impressions: values.reduce(
          (total: number, next: ReduceNextValue) => total + +next['Total impressions'],
          0
        ),
        total_revenue: values.reduce(
          (total: number, next: ReduceNextValue) => total + +next['Total CPM and CPC revenue'],
          0
        ),
        subRows: values.map((value: MapValue) => ({
          name: value['Key-values'],
          total_impressions: value['Total impressions'],
          total_revenue: value['Total CPM and CPC revenue']
        }))
      }

      results.push(result)
    }

    return results
  }
}
