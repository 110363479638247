import React from 'react'

import { domainVersionName } from 'utilities/domainVersionName'

import { Plug } from 'icons/Plug'

interface Props {
  active: boolean
  counter: number
  name: string
  id: string
  testedDomainVersionIds: string[]
}

export const DomainVersionSelectRow: React.FC<Props> = ({ active, counter, name, id, testedDomainVersionIds }) => {
  const isVersionInTest = testedDomainVersionIds.includes(id)

  return (
    <div className='domain-version-select__row'>
      {(active || isVersionInTest) && <Plug />}
      {domainVersionName(counter, name)}
    </div>
  )
}
