import React from 'react'

import { Grid } from '@material-ui/core'
import { HighlightOff } from '@material-ui/icons'
import { TFunction } from 'i18next'

import { Label } from 'components/Form'
import SearchIcon from 'images/magnifying-glass.svg'
import { unwrapCSByTableFor } from './service'
import { CommonSlice, ContainerProps, DataSlicesByTableFor, SetByNameProps } from './types'

export const Container = ({ data, setByName, filterState: { filter, setFilter }, t }: ContainerProps): JSX.Element => (
  <div className='PagesAdUnitsCT'>
    <Label>{t(`pagesAdUnitsCT.titleFor.${data.tableFor}`)}</Label>

    <Grid container className='PagesAdUnitsCT__container'>
      <Grid item xs={12} className='PagesAdUnitsCT__search'>
        <input
          value={filter}
          placeholder={t('pagesAdUnitsCT.search')}
          onChange={(e) => void setFilter(e.target.value)}
        />
        <img src={SearchIcon} />
      </Grid>

      <Grid item xs={6} className='PagesAdUnitsCT__column'>
        <Grid item xs={12} className='PagesAdUnitsCT__column--title'>
          {t('pagesAdUnitsCT.all')}
        </Grid>

        <Grid item xs={12} className='PagesAdUnitsCT__column--all'>
          {AllElements(data, filter, setByName, t)}
        </Grid>
      </Grid>

      <Grid item xs={6} className='PagesAdUnitsCT__column'>
        <Grid item xs={12} className='PagesAdUnitsCT__column--title'>
          {t('pagesAdUnitsCT.active')}
        </Grid>

        <Grid item xs={12} className='PagesAdUnitsCT__column--all'>
          {ActiveElements(data, filter, setByName)}
        </Grid>
      </Grid>
    </Grid>
  </div>
)

export const AllElements = (
  data: DataSlicesByTableFor,
  filter: string,
  setByName: SetByNameProps,
  t: TFunction
): JSX.Element[] => {
  const { elements, activeElements, setElements, setActiveElements } = unwrapCSByTableFor(data, setByName)

  return elements
    .filter((el: CommonSlice) => el.name.includes(filter.trim()))
    .map((el: CommonSlice) => {
      const { id, name } = el

      return (
        <div key={id} className='PagesAdUnitsCT__column--element'>
          {name}

          <div
            className='PagesAdUnitsCT--activate'
            onClick={() => {
              setElements(elements.filter((cs: CommonSlice) => cs.id !== id))
              setActiveElements([...activeElements, el])
            }}
          >
            {t('pagesAdUnitsCT.activate')}
          </div>
        </div>
      )
    })
}

export const ActiveElements = (
  data: DataSlicesByTableFor,
  filter: string,
  setByName: SetByNameProps
): JSX.Element[] => {
  const { elements, activeElements, setElements, setActiveElements } = unwrapCSByTableFor(data, setByName)

  return activeElements
    .filter((el: CommonSlice) => el.name.includes(filter.trim()))
    .map((activeEl: CommonSlice) => {
      const { id, name } = activeEl

      return (
        <div key={id} className='PagesAdUnitsCT__column--element'>
          {name}

          <div
            className='PagesAdUnitsCT--deactivate'
            onClick={() => {
              setElements([...elements, activeEl])
              setActiveElements(activeElements.filter((cs: CommonSlice) => cs.id !== id))
            }}
          >
            <HighlightOff />
          </div>
        </div>
      )
    })
}
