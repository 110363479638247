import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'webapp/gql/fragments/common'

const PAYLOAD_TYPE = 'UpsertOssPagePayload'

export const UPSERT_OSS_PAGE = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}
  mutation UpsertOssPage($input: UpsertOssPageInput!) {
    upsertOssPage(input: $input) {
      ossPage {
        activePageId
        id
        pageName
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
