export class PrebidModuleSerializer {
  public prebidModules: PrebidModuleParams[]

  constructor(formData: PrebidModuleForm) {
    const { analytics = [], brandProtection = [], demand = [], own = [] } = formData

    this.prebidModules = [...analytics, ...brandProtection, ...demand, ...own]
  }

  updateParams(): PrebidModuleParams[] {
    return this.prepareBody()
  }

  private prepareBody() {
    return this.prebidModules.map((module) => {
      const newModule: any = {}

      if (module.id) {
        newModule.id = module.id
      }

      if (!this.areParamsEmpty(module.params)) {
        newModule.params = module.params
      }

      return {
        ...newModule,
        prebidModuleId: module.moduleId,
        _destroy: Boolean(module._destroy)
      }
    })
  }

  private areParamsEmpty(params: Params) {
    return !params || Object.keys(params).length === 0
  }
}
