import React, { useContext, useEffect, useState } from 'react'

import { Formik } from 'formik'

import { useLazyQuery } from '@apollo/client'
import { ButtonTheme } from 'components/Button'
import { StatusForm } from 'components/Form'
import { RemoteControl } from 'components/quickwrap/Inventory/AdUnits/Form/Sections/RemoteControl'
import { Footer, MediaTypes } from 'components/shared/Inventory/AdUnits/Form/Sections'
import { Errors, ErrorUtils } from 'utilities/errorUtils'
import { ApplicableVersions, MainForm } from 'webapp/components/quickwrap/Inventory/AdUnits/Form/Sections'
import { UserContext } from 'webapp/context/UserContext'

interface Props {
  addNext?: boolean
  addNextHandler?: () => void
  adUnit: AdUnitForm
  domain?: Domain
  domains: Domain[]
  errors: Errors
  isSubmitting?: boolean
  onSubmit: (adUnit: AdUnitForm) => void
  setErrors: (errors: ErrorUtils) => void
  workspaceDevices: WorkspaceDevice[]
}

import DomainVersionsQuery from 'gql/queries/domainVersions/AdUnitFormDomainVersions.gql'

export const AdUnitForm: React.FC<Props> = ({
  addNext,
  addNextHandler,
  adUnit,
  domain,
  domains,
  errors,
  isSubmitting,
  onSubmit,
  setErrors,
  workspaceDevices
}) => {
  const { isSuperuser } = useContext(UserContext)

  const [domainVersionOptions, setDomainVersionOptions] = useState<DomainVersionOptionWithPaths[]>([])

  const [domainVersionsQuery] = useLazyQuery(DomainVersionsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ domainVersions }) => {
      setDomainVersionOptions(
        domainVersions.nodes.map((version: DomainVersion) => ({
          label: `${version.counter}_${version.name}`,
          value: version.id
        }))
      )
    }
  })

  useEffect(() => {
    if (domain) {
      domainVersionsQuery({ variables: { domainId: domain.id } })
    }
  }, [domain, domainVersionsQuery])

  const filteredWorkspaceDevices = () =>
    workspaceDevices.filter((device) => {
      if (isSuperuser) {
        return device.workspace?.id === domain?.workspace.id && device
      }
      return device
    })

  return (
    <div className='ad_unit-form ad_unit-form--main qw-form--sectioned'>
      <Formik initialValues={adUnit} onSubmit={onSubmit} enableReinitialize>
        {({ dirty }) => {
          return (
            <StatusForm name='qw-ad-unit' dirty={dirty}>
              <MainForm domain={domain} domains={domains} errors={errors} />

              {!adUnit.id && domain && (
                <ApplicableVersions
                  domainVersionOptions={domainVersionOptions}
                  errors={errors.errorsFor('domainVersionIds')}
                />
              )}

              <RemoteControl errors={errors} />

              <MediaTypes errors={errors} setErrors={setErrors} workspaceDevices={filteredWorkspaceDevices()} />

              <Footer
                addNext={addNext}
                addNextHandler={addNextHandler}
                disabled={isSubmitting || !dirty}
                isSubmitting={isSubmitting}
                submitTheme={ButtonTheme.Orange}
              />
            </StatusForm>
          )
        }}
      </Formik>
    </div>
  )
}
