import React from 'react'

import { DynamicListInputPreview } from './DynamicListInputPreview'
import { MainInput } from './MainInput'

type DynamicListInputType = {
  filterPlaceholder: string
  items: string[]
  onUpdateHandler: (items: string[]) => void
  title: string
}

export const DynamicListInput = ({
  filterPlaceholder,
  items,
  onUpdateHandler,
  title
}: DynamicListInputType): JSX.Element => {
  const clearHandler = (clearItems: string[]) => {
    onUpdateHandler(items.filter((item) => !clearItems.includes(item)))
  }

  return (
    <div className='DynamicListInput'>
      <MainInput items={items} onUpdateHandler={onUpdateHandler} title={title} />
      <DynamicListInputPreview placeholder={filterPlaceholder} items={items} clearHandler={clearHandler} />
    </div>
  )
}
