import React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { ButtonTheme, ButtonType } from 'components/Button'
import { LinkButton } from 'components/LinkButton'
import { CustomCodesTable } from 'components/shared/CustomCode/Table/CustomCodesTable'
import { routesBuilder } from 'utilities/routesBuilder'

export const CustomCodeContainer: React.FC = () => {
  const { t } = useTranslation('setup')

  return (
    <Box className='custom-codes' list={true}>
      <BoxHeader
        title={t('customCodes.title')}
        containerClassName='box-header__container--space-between'
        rowClassName='header__row--width-100'
      >
        <div>
          <LinkButton
            to={routesBuilder.quickwrap.setup.customCodes.new}
            type={ButtonType.Primary}
            theme={ButtonTheme.Orange}
            className='custom-codes__header-button'
          >
            {t('common:buttons.addNew')}
          </LinkButton>
        </div>
      </BoxHeader>

      <CustomCodesTable />
    </Box>
  )
}
