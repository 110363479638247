import React from 'react'

import { useTranslation } from 'react-i18next'

import { LearnMoreProps } from './types'

export const LearnMore: React.FC<LearnMoreProps> = ({ learnMoreUrl }) => {
  const { t } = useTranslation('products')

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }

  return (
    <a className='ProductList__learn-more' href={learnMoreUrl} onClick={handleClick} target='_blank' rel='noreferrer'>
      {t('list.items.learnMore')}
    </a>
  )
}
