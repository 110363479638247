import React from 'react'

import { useTranslation } from 'react-i18next'
import { CellProps, Column } from 'react-table'

import { Tooltip } from 'components/Form'
import { DateCell } from 'components/Table'
import DomainProductToggle from 'components/shared/Inventory/Domains/DomainProductToggle'
import { Product } from 'constants/Product'
import { PriceGeniusDomainConfigurationRow } from './PriceGeniusDomainConfigurationRow'
import { TrafficSlider } from './TrafficSlider/TrafficSlider'

export const useColumnsConfig = (): Column<PriceGeniusDomainConfigurationRow>[] => {
  const { t } = useTranslation('priceGenius')

  return [
    {
      Header: () => t('configuration.columns.domainName'),
      accessor: 'domainName'
    },
    {
      Header: TrafficPercentHeader,
      accessor: 'trafficPercent',
      Cell: (rowData) => TrafficPercentCell(rowData),
      disableSortBy: true
    },
    {
      Header: () => t('configuration.columns.priceGenius'),
      accessor: 'priceGeniusEnabled',
      Cell: (rowData) => PriceGeniusEnabledCell(rowData),
      disableSortBy: true
    },
    {
      Header: () => t('configuration.columns.lastModified'),
      accessor: 'updatedAt',
      Cell: ({ value }) => <DateCell value={value} />,
      disableSortBy: true
    }
  ]
}

const TrafficPercentHeader = (): JSX.Element => {
  const { t } = useTranslation('priceGenius')

  return (
    <div className='ConfigurationsTable__traffic-percent-container'>
      {t('configuration.columns.trafficPercent.title')}

      <Tooltip
        className='ConfigurationsTable__traffic-percent-tooltip'
        title={t('configuration.columns.trafficPercent.tooltip')}
      />
    </div>
  )
}

const TrafficPercentCell = ({
  row: {
    original: { id, trafficPercent }
  }
}: CellProps<PriceGeniusDomainConfigurationRow>): JSX.Element => (
  <TrafficSlider priceGeniusDomainId={id} trafficPercent={trafficPercent} />
)

const PriceGeniusEnabledCell = ({
  row: {
    original: { domainId, priceGeniusEnabled }
  }
}: CellProps<PriceGeniusDomainConfigurationRow>): JSX.Element => (
  <DomainProductToggle domainId={domainId} initialStatus={priceGeniusEnabled} product={Product.PriceGenius} />
)
