import React from 'react'
import { CellProps, Column } from 'react-table'
import i18n from 'webapp/i18n'

import { ABTestRowData } from './ABTestRowData'
import { ActionsCell } from './ActionsCell'
import { DateCell } from 'components/Table'
import { StatusCell } from './StatusCell'

export const columnsConfig = (): Column<ABTestRowData>[] => [
  {
    Header: () => i18n.t('abTesting:list.tableHeader.name'),
    accessor: 'name'
  },
  {
    Header: () => i18n.t('abTesting:list.tableHeader.domainName'),
    accessor: 'domainName'
  },
  {
    Header: () => i18n.t('abTesting:list.tableHeader.active'),
    accessor: 'active',
    Cell: ({ row }) => <StatusCell row={row} />
  },
  {
    Header: () => i18n.t('abTesting:list.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    id: 'actions',
    Cell: ({ row }: CellProps<ABTestRowData>) => <ActionsCell row={row} />,
    disableSortBy: true
  }
]
