import React from 'react'

export const Download: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11.64' height='14' viewBox='0 0 11.64 14'>
      <defs>
        <clipPath id='clip-Download'>
          <rect width='11.64' height='14' />
        </clipPath>
      </defs>
      <g id='Download' clipPath='url(#clip-Download)'>
        <path
          id='Icon_ionic-md-download'
          data-name='Icon ionic-md-download'
          d='M18.394,9.431H15.068V4.5H10.076V9.431H6.75l5.822,5.753ZM6.75,16.828v1.645H18.394V16.828Z'
          transform='translate(-6.75 -4.5)'
          fill='#fff'
        />
      </g>
    </svg>
  )
}
