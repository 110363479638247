export class OssAdUnitRowData {
  ossAdUnit: OssAdUnit

  constructor(ossAdUnit: OssAdUnit) {
    this.ossAdUnit = ossAdUnit
  }

  get adUnitName(): string {
    return this.ossAdUnit.adUnitName ?? ''
  }

  get adUnitPath(): string {
    return this.ossAdUnit.adUnitPath
  }

  get id(): string {
    return this.ossAdUnit.id
  }

  get reviveEnabled(): boolean {
    return this.ossAdUnit.reviveEnabled
  }

  get updatedAt(): Date {
    return this.ossAdUnit.updatedAt
  }
}
