import React from 'react'

import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Modal } from 'components/Modals'

interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
}

export const CreateNewVersionLimitReachedModal: React.FC<Props> = ({ visible, setVisible }) => {
  const { t } = useTranslation('inventory')
  const cancelHandler = () => setVisible(false)

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      shadow={true}
      backdrop={true}
      title={t('domains.versions.new.modal.limitReached.title')}
    >
      <div className='new-version__modal-container' id='new-version-form'>
        <div className='confirmation__modal-container'>
          <p className='confirmation__modal-content'>{t('domains.versions.new.modal.limitReached.description')}</p>

          <footer>
            <Button
              theme={ButtonTheme.BlueReverse}
              type={ButtonType.Primary}
              onClickHandler={cancelHandler}
              className='confirmation__modal__button'
            >
              {t('common:buttons:cancel')}
            </Button>
          </footer>
        </div>
      </div>
    </Modal>
  )
}
