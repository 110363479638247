import React, { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { ToggleSwitch } from 'components/Form'
import { ConfirmationModal } from 'components/Modals'

import UpdateWorkspace from 'gql/mutations/workspaces/UpdateWorkspace.gql'

interface Props {
  row: Row<Workspace>
}

export const ActivationCell: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation('workspaces')
  const [visibleActivationModal, setVisibleActivationModal] = useState(false)
  const history = useHistory<History>()

  const [updateWorkspace] = useMutation(UpdateWorkspace, {
    onCompleted: ({ updateWorkspace: { workspace } }) => {
      if (workspace) {
        setVisibleActivationModal(false)
        history.push(history.location)
      }
    }
  })

  const getVariables = useMemo(
    () => ({
      input: { workspaceId: row.original.id, active: !row.original.active }
    }),
    [row]
  )

  const onChangeHandler = useCallback(() => {
    if (!row.values.active) {
      setVisibleActivationModal(true)
    } else {
      updateWorkspace({ variables: getVariables })
    }
  }, [row.values.active])

  const confirmHandler = () => updateWorkspace({ variables: getVariables })

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        key={row.id}
        id={`active-${row.id}`}
        name={`active-${row.id}`}
        checked={row.values.active}
        onChange={onChangeHandler}
        className='active__switch'
        labelClassName={`active-${row.id}`}
      />
      <ConfirmationModal
        visible={visibleActivationModal}
        setVisible={setVisibleActivationModal}
        content={t('list.modal.activationConfirm.content', { workspaceName: row.original.name })}
        confirmButtonText={t('list.turnOn')}
        cancelButtonText={t('common:buttons.cancel')}
        onConfirmHandler={confirmHandler}
      />
    </div>
  )
}
