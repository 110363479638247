import React, { useMemo } from 'react'

import { formatDate } from 'utilities/formatDate'
import { ReportTimePeriodForm } from './ReportTimePeriodForm'
import { ReportTimePeriodInfo } from './ReportTimePeriodInfo'

interface Props {
  label: string
  tooltipContent?: string
  reportFormData: ReportFormType
  onSubmitHandler: (formValues: ReportFormValues) => void
  isSubmitting: boolean
  isEditReport: boolean
  setIsEditReport: (isEdit: boolean) => void
}

export const ReportTimePeriod: React.FC<Props> = ({
  label,
  tooltipContent,
  reportFormData,
  isSubmitting,
  onSubmitHandler,
  isEditReport,
  setIsEditReport
}) => {
  const { startDate, endDate } = reportFormData.timePeriod

  const timePeriod = useMemo(() => {
    const formattedStartDate = formatDate(startDate || '')
    const formattedEndDate = formatDate(endDate || '')

    return `${formattedStartDate} - ${formattedEndDate}`
  }, [endDate, startDate])

  const editHandler = () => setIsEditReport(true)

  return (
    <>
      {!isEditReport ? (
        <ReportTimePeriodInfo
          label={label}
          value={timePeriod}
          tooltipContent={tooltipContent}
          onEditHandler={editHandler}
        />
      ) : (
        <ReportTimePeriodForm
          networkName={reportFormData.networkName}
          networkCode={reportFormData.networkCode}
          startDate={startDate}
          endDate={endDate}
          isSubmitting={isSubmitting}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </>
  )
}
