import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'gql/fragments/common'

const PAYLOAD_TYPE = 'UpsertOssAdUnitPayload'

export const UPDATE_OSS_AD_UNIT_PRODUCT_STATUS = gql`
  ${MUTATION_STATE(PAYLOAD_TYPE)}

  mutation UpsertOssAdUnit($input: UpsertOssAdUnitInput!) {
    upsertOssAdUnit(input: $input) {
      ossAdUnit {
        adUnitName
        frbEnabled
        id
        pgEnabled
        qwEnabled
        reviveEnabled
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
