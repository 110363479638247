import React, { useContext, useEffect, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import { Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ToggleSwitch, Tooltip } from 'components/Form'
import { LoadingContainer } from 'components/LoadingContainer'
import { NotificationType } from 'components/ToastNotifier'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { TargetingForm } from './TargetingForm'
import * as T from './types'

import { UPDATE_REVIVE_DOMAIN_CONFIGURATION } from 'gql/mutations/revive/UpdateReviveDomainConfiguration'
import { REVIVE_DOMAIN_CONFIGURATION } from 'gql/queries/revive/reviveDomainConfiguration'

import './styles.scss'

export const TargetingFormContainer = ({
  urlReviveDomainConfigurationId
}: T.TargetingFormContainerProps): JSX.Element => {
  const { t } = useTranslation('revive')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [reviveDomainConfiguration, setReviveDomainConfiguration] = useState<T.ReviveDomainConfigurationData>()
  const [errors, setErrors] = useState(new ErrorUtils([]))

  useEffect(() => {
    fetchReviveDomainConfiguration({
      variables: {
        reviveDomainConfigurationId: urlReviveDomainConfigurationId
      }
    })
  }, [urlReviveDomainConfigurationId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [fetchReviveDomainConfiguration] = useLazyQuery<
    { reviveDomainConfiguration: T.ReviveDomainConfigurationData },
    T.ReviveDomainConfigurationVars
  >(REVIVE_DOMAIN_CONFIGURATION, {
    onCompleted: (data) => void setReviveDomainConfiguration(data.reviveDomainConfiguration)
  })

  const [updateReviveDomainConfiguration, { loading: updateLoading }] = useMutation<
    T.UpdateReviveDomainConfigurationData,
    { input: T.UpdateReviveDomainConfigurationVars }
  >(UPDATE_REVIVE_DOMAIN_CONFIGURATION, {
    onCompleted: ({ updateReviveDomainConfiguration: { reviveDomainConfiguration, errors } }) => {
      setErrors(new ErrorUtils(errors))

      if (errors.length > 0) {
        createNotifier(t('configuration.edit.targetingForm.update.error'), NotificationType.ERROR)
      } else if (reviveDomainConfiguration) {
        const {
          domain: { name }
        } = reviveDomainConfiguration

        createNotifier(t('configuration.edit.targetingForm.update.success', { name }), NotificationType.SUCCESS)
        formStatus.clear()
      }
    }
  })

  const handleFormSubmit = (
    values: T.UpdateReviveDomainConfigurationVars,
    reviveDomainConfigurationId: T.ReviveDomainConfigurationData['id']
  ) =>
    void updateReviveDomainConfiguration(
      nestGqlInput({
        ...values,
        reviveDomainConfigurationId
      })
    )

  const handleToggleSwitch = ({ id, targetingEnabled }: T.ToggleSwitchProps) => {
    void updateReviveDomainConfiguration(
      nestGqlInput({
        reviveDomainConfigurationId: id,
        targetingEnabled: !targetingEnabled
      })
    )
  }

  const TargetingToggle = ({ id, targetingEnabled }: T.ToggleSwitchProps): JSX.Element => {
    const TOGGLE_ID = 'revive-targeting-status'

    return (
      <ToggleSwitch
        className='targeting-form-container__toggle'
        checked={targetingEnabled}
        id={TOGGLE_ID}
        name={TOGGLE_ID}
        onChange={() => handleToggleSwitch({ id, targetingEnabled })}
      />
    )
  }

  const Loading = (): JSX.Element => <LoadingContainer loading />

  const Container = ({
    reviveDomainConfiguration
  }: {
    reviveDomainConfiguration: T.ReviveDomainConfigurationData
  }): JSX.Element => {
    const { targetingEnabled, id } = reviveDomainConfiguration

    return (
      <Box>
        <BoxHeader
          rowClassName='targeting-form-container__header'
          title={t('configuration.edit.targetingForm.header.title')}
        >
          <Tooltip title={t('configuration.edit.targetingForm.header.tooltip')} />
          <TargetingToggle id={id} targetingEnabled={targetingEnabled} />
        </BoxHeader>

        <BoxBody>
          {updateLoading ? (
            <Loading />
          ) : (
            <Collapse in={targetingEnabled}>
              <TargetingForm
                data={reviveDomainConfiguration}
                errors={errors}
                loading={updateLoading}
                onSubmit={(values) => handleFormSubmit(values, id)}
              />
            </Collapse>
          )}
        </BoxBody>
      </Box>
    )
  }

  return reviveDomainConfiguration ? <Container reviveDomainConfiguration={reviveDomainConfiguration} /> : <Loading />
}
