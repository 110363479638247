import React from 'react'

import { Grid } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { Section } from 'components/Form'
import { Schain } from 'components/quickwrap/Inventory/Domains/Form/Schain'
import { FormikInputContainer, FormikSelectContainer } from 'containers/FormikContainers'
import { CreateDomainVars } from 'pages/oss/Inventory/Domains/NewPage/types'
import { currencyUtils } from 'utilities/currencyUtils'
import useGeneralSettings from 'webapp/hooks/useGeneralSettings'
import { DomainBoxProps } from './types'

export const DomainBox = ({ errors }: DomainBoxProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { setFieldValue } = useFormikContext<CreateDomainVars>()
  const { generalSettings } = useGeneralSettings(undefined)

  return (
    <Box>
      <BoxHeader title={t('domains.new.title')} />

      <Section fullWidth className='page-form'>
        <Grid item lg={4} xs={6}>
          <Field
            component={FormikInputContainer}
            errors={errors.errorsFor('name')}
            id='name'
            label={t('domains.form.domainName.label')}
            name='name'
            placeholder={t('domains.form.domainName.placeholder')}
            tooltipContent={t('domains.form.domainName.tooltip')}
            type='text'
          />

          <Schain
            amazonDemandType={generalSettings?.apsDemandType}
            amazonEnabled={generalSettings?.apsDemandEnabled}
            errors={errors}
          />

          <Field
            component={FormikSelectContainer}
            containerClassName='domain-currency'
            errors={errors.errorsFor('currency')}
            id='domain-currency'
            label={t('domains.form.currency.label')}
            name='currency'
            onChangeHandler={(currency: SelectOption<string>): void =>
              setFieldValue('currency', currency.value.toLowerCase())
            }
            options={currencyUtils.getList()}
            placeholder={t('domains.form.currency.placeholder')}
            tooltipContent={t('domains.form.currency.tooltip')}
          />
        </Grid>
      </Section>
    </Box>
  )
}
