import { BiddersRecommendationForm } from 'components/sharedInterfaces/BiddersRecommendationForm'

export const RecommendBiddersFormFactory = (): BiddersRecommendationForm => {
  return {
    domain: '',
    impressions: '',
    visitsPerGeo: [
      {
        geo: '',
        traffic: ''
      }
    ],
    adFormat: [],
    restrictedContent: []
  }
}
