import React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { CreateForm } from './Forms/CreateForm'
import { UpdateForm } from './Forms/UpdateForm'

interface Props {
  customSettings: GeneralSetting | undefined
  generalSettings: GeneralSetting
  setIsSettingsForm: (isSettingsForm: boolean) => void
  setCustomSettings: (settings: GeneralSetting | undefined) => void
}

export const CustomSettingsContainer: React.FC<Props> = ({
  setIsSettingsForm,
  setCustomSettings,
  customSettings,
  generalSettings
}) => {
  const { t } = useTranslation('setup')

  return (
    <Box className='box--margin-top' isExpandable={true}>
      <BoxHeader title={t('customSettings.title')} subtitle={t('customSettings.subtitle')} />

      {customSettings ? (
        <BoxBody>
          <UpdateForm
            settings={customSettings}
            setSettings={setCustomSettings}
            setIsSettingsForm={setIsSettingsForm}
            custom
          />
        </BoxBody>
      ) : (
        <BoxBody>
          <CreateForm
            settings={generalSettings}
            setSettings={setCustomSettings}
            setIsSettingsForm={setIsSettingsForm}
            custom
          />
        </BoxBody>
      )}
    </Box>
  )
}
