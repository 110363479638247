import React from 'react'
import { useTranslation } from 'react-i18next'

import { RadioButton } from 'components/Form'
import { InputContainer } from 'components/Form/InputContainer'
import { useField } from 'formik'
import { MatchTypeProps } from './types'

export const MatchTypeSelector = ({
  id,
  disabled,
  errors,
  label,
  tooltipContent,
  ...other
}: MatchTypeProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const [field, , helpers] = useField<MatchType>(other.field.name)

  const isChecked = (match: MatchType) => field.value.includes(match)

  const handleChange = (value: string): void => helpers.setValue(value as MatchType)

  return (
    <InputContainer
      className={'input__container'}
      disabled={disabled}
      errors={errors}
      inputId={id}
      label={label}
      labelStyle='bold'
      tooltipContent={tooltipContent}
    >
      <RadioButton
        checked={isChecked('include')}
        disabled={disabled}
        id='match-type-include'
        label={t('pages.form.matchType.include')}
        name='matchTypeInclude'
        onChange={handleChange}
        value='include'
      />

      <RadioButton
        checked={isChecked('exact')}
        disabled={disabled}
        id='match-type-exact'
        label={t('pages.form.matchType.exact')}
        name='matchTypeExact'
        onChange={handleChange}
        value='exact'
      />

      <RadioButton
        checked={isChecked('regex')}
        disabled={disabled}
        id='match-type-regex'
        label={t('pages.form.matchType.regex')}
        name='matchTypeRegex'
        onChange={handleChange}
        value='regex'
      />
    </InputContainer>
  )
}
