import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Modal } from 'components/Modals'

interface Props {
  content: string
  isVisible: boolean
  setIsVisible: (visible: boolean) => void
}

export const CroppedModal: React.FC<Props> = ({ content, isVisible, setIsVisible }) => {
  const { t } = useTranslation()

  const closeHandler = useCallback(() => setIsVisible(false), [])

  return (
    <Modal visible={isVisible} setVisible={setIsVisible} shadow={true} backdrop={true}>
      <div className='confirmation__modal-container'>
        <p className='confirmation__modal-content'>{content}</p>

        <footer>
          <Button
            theme={ButtonTheme.BlueReverse}
            type={ButtonType.Primary}
            onClickHandler={closeHandler}
            className='confirmation__modal__button'
          >
            {t('common:buttons.cancel')}
          </Button>
        </footer>
      </div>
    </Modal>
  )
}
