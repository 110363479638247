import { gql } from '@apollo/client'
import { DOMAIN_FRAGMENT } from 'gql/fragments/domains/DomainFragment'

export const DOMAIN_AS_ADMIN = gql`
  ${DOMAIN_FRAGMENT}

  query DomainAsAdmin($domainId: ID!) {
    domain(domainId: $domainId) {
      ...DomainFragment

      ybAmazonSid
      ybDomainSid
    }
  }
`
