import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { Role } from 'webapp/constants/Role'
import { routesBuilder } from 'utilities/routesBuilder'
import { useQueryUsersByRole } from 'utilities/roles/queries'
import { useScrollUp } from 'utilities/useScrollUp'
import { WorkspaceSerializer } from 'serializers/WorkspaceSerializer'
import { WorkspaceForm } from 'components/oss/Workspaces/Form'

import CreateWorkspace from 'gql/mutations/workspaces/CreateWorkspace.gql'

export const NewPage: React.FC = () => {
  const { t } = useTranslation('workspaces')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const history = useHistory<History>()

  const csManagers = useQueryUsersByRole(Role.CS)

  const [errors, setErrors] = useState(new ErrorUtils([]))

  useScrollUp()

  const [createWorkspace, { loading }] = useMutation(CreateWorkspace, {
    onCompleted: ({ createWorkspace: { workspace, errors } }) => {
      if (workspace) {
        createNotifier(t('new.successMessage', { name: workspace.name }), NotificationType.SUCCESS)
        formStatus.clear()
        history.push(routesBuilder.oss.workspaces.edit(workspace.id))
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
        setErrors(new ErrorUtils(errors))
      }
    }
  })

  const createWorkspaceHandler = (formData: WorkspaceFormData) => {
    const serializer = new WorkspaceSerializer(formData, true)
    createWorkspace(nestGqlInput(serializer.createParams()))
  }

  return (
    <Box>
      <BoxHeader title={t('new.title')} subtitle={t('new.subtitle')} />

      <WorkspaceForm csManagers={csManagers} errors={errors} isSubmitting={loading} onSubmit={createWorkspaceHandler} />
    </Box>
  )
}
