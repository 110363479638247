import React from 'react'

import { Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { pick } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Label, Section, StatusForm } from 'components/Form'
import { FormikInputContainer as FormikInput, FormikToggleContainer } from 'containers/FormikContainers'
import { BasicFormProps } from './types'

const GOOGLE_POLICY_URL =
  'https://support.google.com/admanager/answer/6286179?hl=en#zippy=%2Cavailable-minimum-intervals-for-refresh'

export const BasicForm = ({ data, loading, errors, onSubmit }: BasicFormProps): JSX.Element => {
  const { t } = useTranslation('revive')
  const {
    id,
    domain: { name }
  } = data

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...pick(data, ['refreshTimeout', 'refreshAdThreshold', 'monitoringUserScrollEnabled']),
        reviveDomainConfigurationId: id
      }}
      onSubmit={onSubmit}
    >
      {({ dirty, setFieldValue }) => (
        <div className='basic-form'>
          <StatusForm dirty={dirty} name='basic-form'>
            <Section fullWidth>
              <Grid container spacing={4}>
                <Grid item lg={6} xs={6}>
                  <Label tooltipContent={t('configuration.edit.basicForm.refreshInterval.tooltip')}>
                    {t('configuration.edit.basicForm.refreshInterval.label')}
                  </Label>

                  <div className='basic-form__description'>
                    {t('configuration.edit.basicForm.refreshInterval.description')}
                    <a href={GOOGLE_POLICY_URL} rel='noopener noreferrer' target='_blank'>
                      {t('configuration.edit.basicForm.refreshInterval.urlLabel')}
                    </a>
                    .
                  </div>

                  <Field
                    component={FormikInput}
                    containerClassName='basic-form__input-field'
                    errors={errors.errorsFor('refreshTimeout')}
                    id='refreshTimeout'
                    name='refreshTimeout'
                    onChangeHandler={(value: string): void =>
                      setFieldValue('refreshTimeout', value ? parseInt(value) : '')
                    }
                    placeholder={t('configuration.edit.basicForm.refreshInterval.placeholder')}
                    type='number'
                  />
                </Grid>

                <Grid item lg={4} xs={4}>
                  <Label tooltipContent={t('configuration.edit.basicForm.refreshAdThreshold.tooltip')}>
                    {t('configuration.edit.basicForm.refreshAdThreshold.label')}
                  </Label>

                  <div className='basic-form__description'>
                    {t('configuration.edit.basicForm.refreshAdThreshold.description')}
                  </div>

                  <Field
                    component={FormikInput}
                    containerClassName='basic-form__input-field'
                    errors={errors.errorsFor('refreshAdThreshold')}
                    id='refreshAdThreshold'
                    name='refreshAdThreshold'
                    onChangeHandler={(value: string): void =>
                      setFieldValue('refreshAdThreshold', value ? parseFloat(value) : '')
                    }
                    placeholder={t('configuration.edit.basicForm.refreshAdThreshold.placeholder')}
                    type='number'
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item lg={4} xs={4}>
                  <Field
                    className='basic-form__toggle'
                    component={FormikToggleContainer}
                    errors={errors.errorsFor('monitoringUserScrollEnabled')}
                    id='monitoringUserScrollEnabled'
                    label={t('configuration.edit.basicForm.monitoringUserScrollEnabled.label')}
                    name='monitoringUserScrollEnabled'
                    tooltipContent={t('configuration.edit.basicForm.monitoringUserScrollEnabled.tooltip')}
                    tooltipTogglePlacement='inside'
                  />
                </Grid>
              </Grid>

              <div className='basic-form__description'>
                {t('configuration.edit.basicForm.description1', { name })}
                <br />
                {t('configuration.edit.basicForm.description2')}
              </div>

              <Button
                buttonType='submit'
                disabled={!dirty || loading}
                theme={ButtonTheme.Red}
                type={ButtonType.Primary}
              >
                {loading ? t('common:buttons.saving') : t('common:buttons.save')}
              </Button>
            </Section>
          </StatusForm>
        </div>
      )}
    </Formik>
  )
}
