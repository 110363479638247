import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { BidderSettingSerializer } from 'serializers/BidderSettingSerializer'
import { ErrorUtils } from 'utilities/errorUtils'
import { GoogleAdManagerModal } from '../OwnBiddersContainer/GoogleAdManagerModal'
import { Modal } from 'components/Modals'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { OwnBidderFactory } from 'components/Bidders/OwnBidderForm/formSchema'
import { OwnBidderForm } from 'components/Bidders/OwnBidderForm'
import { OwnBidderTableContext } from 'webapp/context/OwnBiddersContext'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import UpsertBidderSetting from 'gql/mutations/bidderSettings/UpsertBidderSetting.gql'

type OwnBidderModalType = {
  bidders: Bidder[]
  bidderSettingId?: string
  close: () => void
  networks: Network[]
  visible: boolean
}

export const OwnBidderModal = ({
  bidders,
  bidderSettingId,
  close,
  networks,
  visible
}: OwnBidderModalType): JSX.Element => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { bidderSettings, refetchBidderSettings } = useContext(OwnBidderTableContext)

  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [aliases, setAliases] = useState<string[]>([])
  const [adManagerModalVisible, setAdManagerModalVisible] = useState(false)
  const [loadingBidderSettings, setLoadingBidderSettings] = useState(false)

  useEffect(() => {
    if (visible) setErrors(new ErrorUtils([]))
  }, [visible])

  const [updateBidderSetting, { loading: updatingBidderSetting }] = useMutation(UpsertBidderSetting, {
    onCompleted: async ({ upsertBidderSetting: { bidderSetting, errors } }) => {
      if (bidderSetting) {
        createNotifier(
          t(bidderSettingId ? 'bidders.form.editMessage' : 'bidders.form.addMessage'),
          NotificationType.SUCCESS
        )
        setLoadingBidderSettings(true)
        const bidderSettings = (await refetchBidderSettings()).data.bidderSettings.nodes
        setLoadingBidderSettings(false)
        checkAliases(bidderSettings)
        close()
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const updateBidderSettingHandler = (form: OwnBidderFormData) => {
    updateBidderSetting(nestGqlInput(new BidderSettingSerializer(form, bidderSetting as BidderSetting).params()))
  }

  const checkAliases = (bidderSettings: BidderSetting[]) => {
    const aliases = bidderSettings.map((setting) => setting.bidderCode)

    setAliases(aliases)
    aliases.length > 0 && setAdManagerModalVisible(true)
  }

  const bidderSetting = bidderSettings.find(({ id }) => id === bidderSettingId)

  return (
    <>
      <Modal
        backdrop
        setVisible={close}
        shadow
        title={t(bidderSettingId ? 'bidders.form.editTitle' : 'bidders.form.addTitle')}
        visible={visible}
      >
        <div className='confirmation__modal-container'>
          <OwnBidderForm
            bidderSetting={OwnBidderFactory(bidderSetting)}
            bidderSettings={bidderSettings}
            bidders={bidders}
            closeModal={close}
            errors={errors}
            isSubmitting={updatingBidderSetting || loadingBidderSettings}
            networks={networks}
            onSubmitHandler={updateBidderSettingHandler}
          />
        </div>
      </Modal>

      <GoogleAdManagerModal visible={adManagerModalVisible} setVisible={setAdManagerModalVisible} aliases={aliases} />
    </>
  )
}
