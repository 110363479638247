import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Field, Formik } from 'formik'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { DomainVersionSelectOption } from 'webapp/components/shared/Inventory/Domains/DomainVersionSelect/DomainVersionOptionsFactory'
import { FormikSelectContainer } from 'containers/FormikContainers'
import { Modal } from 'components/Modals'
import { NextActiveVersionData, NextActiveVersionModalProps } from '../types'
import { nextActiveVersionFormInitValues, nextActiveVersionFormOptions } from './service'
import { VersionedDomainContext } from 'webapp/context/VersionedDomainContext'

export const NextActiveVersionModal: React.FC<NextActiveVersionModalProps> = ({
  currentVersion,
  onConfirmHandler,
  setVisible,
  visible
}) => {
  const { t } = useTranslation('inventory')
  const { domainVersionSelectOptions } = useContext(VersionedDomainContext)

  const { id: idCurr, name: nameCurr } = currentVersion
  const options: DomainVersionSelectOption[] = nextActiveVersionFormOptions(domainVersionSelectOptions, idCurr)

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      shadow={true}
      backdrop={true}
      title={t('domains.edit.versions.nextActiveVersionModal.title')}
    >
      <div className='NextActiveVersionModal'>
        <p>{t('domains.edit.versions.nextActiveVersionModal.chooseVersion')}</p>

        <Formik initialValues={nextActiveVersionFormInitValues()} onSubmit={() => void {}}>
          {({ values }: { values: NextActiveVersionData }) => {
            const { value: valueNext, name: nameNext, label: labelNext } = values.nextActiveVersion
            const selectIsEmpty: boolean = labelNext === null

            return (
              <>
                <Field
                  component={FormikSelectContainer}
                  isSearchable={false}
                  name='nextActiveVersion'
                  options={options}
                  placeholder={t('domains.edit.versions.nextActiveVersionModal.placeholder')}
                />

                {!selectIsEmpty && (
                  <p className='replace-message'>
                    <Trans
                      i18nKey='inventory:domains.edit.versions.nextActiveVersionModal.replaceMessage'
                      values={{ currentVersion: nameCurr, nextVersion: nameNext }}
                    />
                  </p>
                )}

                <footer>
                  <Button
                    className='confirmation__modal__button'
                    onClickHandler={() => void setVisible(false)}
                    theme={ButtonTheme.BlueReverse}
                    type={ButtonType.Primary}
                  >
                    {t('Cancel')}
                  </Button>

                  <Button
                    className='confirmation__modal__button'
                    disabled={selectIsEmpty}
                    onClickHandler={() => void onConfirmHandler({ id: valueNext, name: nameNext })}
                    theme={ButtonTheme.Blue}
                    type={ButtonType.Primary}
                  >
                    {t('Confirm')}
                  </Button>
                </footer>
              </>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}
