import React from 'react'
import { CircularProgress } from '@material-ui/core'
import classNames from 'classnames'

type LoadingContainerProps = {
  fullHeight?: boolean
  loading?: boolean
}

export const LoadingContainer: React.FC<LoadingContainerProps> = ({ fullHeight = false, loading, children }) => (
  <>
    {loading ? (
      <div className={classNames('loading__container', { 'loading__container--full-height': fullHeight })}>
        <CircularProgress disableShrink />
      </div>
    ) : (
      <>{children}</>
    )}
  </>
)

export const Loading = (): JSX.Element => <LoadingContainer fullHeight loading />
