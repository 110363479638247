import { emptySelectOption } from 'webapp/constants/emptySelectOption'

const integrationMethodList = [
  { value: 'smart_tag', label: 'Smart tags (pasted in header and for each ad unit separately)' },
  { value: 'open_tag', label: 'Open tags (pasted only in header)' }
]

export const integrationMethodUtils = Object.freeze({
  getList: () => integrationMethodList,

  get: (value: string): StringSelectOption => {
    return integrationMethodList.find((el) => el?.value === value) || emptySelectOption
  }
})
