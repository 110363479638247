import React from 'react'

import { DataStudioPage } from 'pages/shared/Performance/DataStudio'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { Product } from 'webapp/constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'

export const DataStudioRoutes = [
  <LayoutRoute
    component={DataStudioPage}
    key='reports-results'
    path={routesBuilder.viewability_booster.performance.dataStudio}
    product={Product.ViewabilityBooster}
    userPermissionRequired
  />
]
