import React from 'react'
import classNames from 'classnames'
import { Row, TableRowProps } from 'react-table'
import { TableCell as Cell, TableRow as MaterialRow } from '@material-ui/core'

interface Props {
  row: Row<Record<string, unknown>>
  getRowProps?: (row: Row<Record<string, unknown>>) => Partial<TableRowProps>
  onRowClick?: (row: Row<Record<string, unknown>>) => void
}

const TableRow: React.FC<Props> = ({ row, getRowProps, onRowClick }) => {
  const { className, ...rest } = row.getRowProps(getRowProps?.(row))

  const rowProps = {
    onClick: () => onRowClick?.(row),
    hover: Boolean(onRowClick),
    className: classNames(
      className,
      row.highlight && 'MuiTableRow-root--highlighted',
      row.currentHighlight && 'MuiTableRow-root--current-highlighted'
    ),
    ...rest
  }

  return (
    <MaterialRow {...rowProps}>
      {row.cells.map((cell, index) => (
        <Cell {...cell.getCellProps([{ className: `cell--${cell.column.id.replace('.', '-')}` }])} key={index}>
          {cell.render('Cell')}
        </Cell>
      ))}
    </MaterialRow>
  )
}

export default React.memo(TableRow, (prevProps, nextProps) => {
  if (!nextProps.row.useMemoization) return false

  return (
    prevProps.row.currentHighlight === nextProps.row.currentHighlight &&
    prevProps.row.highlight === nextProps.row.highlight
  )
})
