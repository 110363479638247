import React from 'react'
import { components, MultiValueProps, Options } from 'react-select'

import { ValueContainerContent } from './ValueContainerContent'

export const ValueContainer: React.ComponentType<MultiValueProps> | undefined = ({ children, ...props }) => {
  const getInputChildren = (children: React.ReactNode[]) => {
    return children.find((child: React.ReactNode) => {
      type Child = { props: { id: string } }

      return ((child as Child)?.props?.id || '').includes('input')
    })
  }

  return (
    <components.ValueContainer {...props}>
      <div className='ValueContainer'>
        {getInputChildren(children as React.ReactNode[])}
        {props.hasValue ? (
          <ValueContainerContent
            name={props.selectProps.name || ''}
            values={props.getValue() as Options<{ label: string; value: string }>}
          />
        ) : (
          props.selectProps.placeholder
        )}
      </div>
    </components.ValueContainer>
  )
}
