import React, { useState } from 'react'

import { PasswordRecoveryContainer } from 'containers/Auth/PasswordRecoveryContainer'
import { PasswordRecoveryCompletedContainer } from 'containers/Auth/PasswordRecoveryCompletedContainer'
import { UserFlowTemplate } from 'components/UserFlowTemplate'

import ForgottenPasswordImage from 'images/forms/forgotten_password.svg'
import CheckEmailImage from 'images/forms/check_email.svg'

export const PasswordRecoveryPage: React.FC = () => {
  const [finalStep, setFinalStep] = useState(false)

  return finalStep ? (
    <UserFlowTemplate image={CheckEmailImage} view='password-recovery'>
      <PasswordRecoveryCompletedContainer />
    </UserFlowTemplate>
  ) : (
    <UserFlowTemplate image={ForgottenPasswordImage} view='password-recovery'>
      <PasswordRecoveryContainer setFinalStep={setFinalStep} />
    </UserFlowTemplate>
  )
}
