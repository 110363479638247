import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AdUnitsAmazonDemandForm } from 'components/AdUnits/AdUnitsAmazonDemandForm'
import { AdUnitAmazonDemandFactory } from 'components/AdUnits/AdUnitsAmazonDemandForm/formSchema'
import { Box, BoxHeader } from 'components/Box'
import { NotificationType } from 'components/ToastNotifier'
import { AdUnitSerializer } from 'serializers/AdUnitSerializer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import useGeneralSettings from 'webapp/hooks/useGeneralSettings'

import UpdateAdUnit from 'gql/mutations/adUnits/UpdateAdUnit.gql'

interface Props {
  adUnit: AdUnit
  domain?: Domain
}

export const AdUnitsAmazonDemandContainer: React.FC<Props> = ({ adUnit, domain }) => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('inventory')

  const [formData, setFormData] = useState(() => AdUnitAmazonDemandFactory(adUnit))
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const { generalSettings } = useGeneralSettings(domain?.workspace.id)

  const [updateAdUnit, { loading }] = useMutation(UpdateAdUnit, {
    onCompleted: ({ updateAdUnit: { adUnit, errors } }) => {
      if (adUnit) {
        createNotifier(
          t('adUnits.edit.successMessage', { adUnitPath: adUnit.ossAdUnit.adUnitPath }),
          NotificationType.SUCCESS
        )
        setFormData(AdUnitAmazonDemandFactory(adUnit))
        formStatus.reset('adUnitAmazonDemandForm')
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmitHandler = (formData: AdUnitAmazonDemandFormData) => {
    updateAdUnit(nestGqlInput(AdUnitSerializer.updateAmazonDemandParams(formData)))
  }

  return (
    <Box>
      <BoxHeader
        title={t('adUnits.form.inputs.apsEnabled.title')}
        subtitle={t('adUnits.form.inputs.apsEnabled.description')}
      />
      <AdUnitsAmazonDemandForm
        apsPubId={generalSettings?.apsPubId || generalSettings?.ybApsPubId}
        errors={errors}
        formData={formData}
        isSubmitting={loading}
        onSubmit={onSubmitHandler}
      />
    </Box>
  )
}
