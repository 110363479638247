import React from 'react'
import classNames from 'classnames'

import { Avatar } from 'components/Avatar'

interface Props {
  className?: string
  heading?: string
  user: User
}

export const UserCard: React.FC<Props> = ({ className, heading, user }) => {
  return (
    <div className={classNames('user-card', className)}>
      {heading && <h2 className='user-card__heading'>{heading}</h2>}

      <div className='user-card__inner'>
        <Avatar user={user} containerClassName='user-card__avatar' />

        <div>
          {user.fullName && <div className='user-card__name'>{user.fullName}</div>}

          <a className='user-card__email' href={`mailto:${user.email}`}>
            {user.email}
          </a>
        </div>
      </div>
    </div>
  )
}
