import React from 'react'
import { Field, useFormikContext } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AdUnitSerializer } from 'serializers/AdUnitSerializer'
import { ErrorMessages } from 'components/Form'
import { Errors } from 'utilities/errorUtils'
import {
  FormikCodeEditorContainer as CodeEditor,
  FormikToggleContainer as FormikToggle
} from 'containers/FormikContainers'

interface Props {
  errors: Errors
}

export const OutstreamVideo: React.FC<Props> = ({ errors }) => {
  const { t } = useTranslation('inventory')
  const { values } = useFormikContext<AdUnitForm>()

  const isOutstreamVideoVisible = AdUnitSerializer.isOutstreamVideoVisible(values)

  return (
    <>
      {isOutstreamVideoVisible && (
        <Grid item lg={4} md={6} xs={8} className='pt-0 mt-4'>
          <Field
            id='outstream-video-enabled'
            name='outstreamVideoEnabled'
            label={t('adUnits.form.inputs.mediaTypes.outstreamVideoEnabled.label')}
            tooltipContent={t('adUnits.form.inputs.mediaTypes.outstreamVideoEnabled.tooltip')}
            component={FormikToggle}
            labelStyle='bold'
          />
          {values.outstreamVideoEnabled && (
            <>
              <Field
                id='outstream-video-attributes'
                name='outstreamVideoAttributes'
                label={t('adUnits.form.inputs.mediaTypes.outstreamVideoEnabled.label')}
                tooltipContent={t('adUnits.form.inputs.mediaTypes.outstreamVideoEnabled.tooltip')}
                component={CodeEditor}
                labelStyle='bold'
              />
              <ErrorMessages
                className='error__section error--media-types'
                errors={errors
                  .errorsFor(['outstreamVideoAttributes'])
                  .concat(errors.nestedErrorsFor(['outstreamVideoAttributes']))}
              />
            </>
          )}
        </Grid>
      )}
    </>
  )
}
