import React from 'react'

import { Close } from 'icons/Close'

import { ButtonTheme } from 'components/Button'

interface Props {
  removeFilter: () => void
  theme: ButtonTheme
}

export const RemoveFilterButton: React.FC<Props> = ({ removeFilter, theme = ButtonTheme.Blue }) => (
  <div>
    <button className={`advanced-filter__remove advanced-filter__remove--${theme}`} onClick={removeFilter}>
      <Close />
    </button>
  </div>
)
