import React from 'react'

import { Collapse } from '@material-ui/core'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import TCFSection, { TCFSectionPlacement } from 'components/shared/Inventory/Domains/TCFSection'
import { FormikToggleContainer } from 'containers/FormikContainers'
import { ConsentManagementBoxProps } from './types'

export const ConsentManagementBox = ({ errors, values }: ConsentManagementBoxProps): JSX.Element => {
  const { t } = useTranslation('inventory')

  const { ossConsentManagementAttributes: cmAttributes, ossConsentManagementEnabled: cmEnabled } = values

  return (
    <Box isExpandable={cmEnabled}>
      <BoxHeader subtitle={t('domains.consentManagement.subtitle')} title={t('domains.consentManagement.title')}>
        <Field
          component={FormikToggleContainer}
          id='oss-consent-management-enabled'
          name='ossConsentManagementEnabled'
          tooltipContent={t('domains.consentManagement.tooltip')}
        />
      </BoxHeader>

      <BoxBody>
        <div className='oss-consent-management-form '>
          <Collapse in={cmEnabled}>
            <TCFSection errors={errors} placement={TCFSectionPlacement.ADD_NEW_DOMAIN} values={cmAttributes} />
          </Collapse>
        </div>
      </BoxBody>
    </Box>
  )
}
