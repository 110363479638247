import React, { useState } from 'react'

import moment, { Moment } from 'moment'
import { SingleDatePicker } from 'react-dates'

import { DATE_FORMAT, cutTime } from 'utilities/formatDate'
import { DatePickerSingleProps } from './types'

export const DatePickerSingle = ({ value, onChange, isOutsideRange, disabled }: DatePickerSingleProps): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false)

  return (
    <div className='DatePickerSingle'>
      <SingleDatePicker
        id='date-picker-single'
        date={moment(value)}
        onDateChange={(newDate: Moment | null): void => (newDate ? onChange(cutTime(newDate.toDate())) : void {})}
        focused={focused}
        onFocusChange={({ focused }) => void setFocused(focused)}
        displayFormat={DATE_FORMAT.DATE_DOTS}
        isOutsideRange={isOutsideRange}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        disabled={disabled}
      />
    </div>
  )
}
