import React from 'react'

export const Plus: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'>
      <g id='Group_10492' data-name='Group 10492' transform='translate(-159 -1571.1)'>
        <rect
          id='Rectangle_4365'
          data-name='Rectangle 4365'
          width='3'
          height='15'
          rx='1.5'
          transform='translate(159 1580.1) rotate(-90)'
        />
        <rect
          id='Rectangle_4366'
          data-name='Rectangle 4366'
          width='3'
          height='15'
          rx='1.5'
          transform='translate(168 1586.1) rotate(180)'
        />
      </g>
    </svg>
  )
}
