export const DomainNetworksFactory = (domainNetworks: DomainNetwork[], networks: Network[]): DomainNetworkForm[] => {
  return networks.map((network) => {
    const domainNetwork = domainNetworks.find((domainNetwork) => domainNetwork.network.id === network.id)

    return {
      asi: network.asi ?? '',
      network: network.name,
      sid: domainNetwork?.sid ?? ''
    }
  })
}
