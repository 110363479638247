import React from 'react'

import { Column, Row } from 'react-table'

import { QueryTable } from 'containers/QueryTable'
import { CollectionNames } from 'webapp/constants/CollectionNames'
import { HistoryRowData } from './HistoryRowData'
import { columnsConfig } from './columnsConfig'

import GET_HISTORY from 'gql/queries/history/History.gql'

const SEARCHABLE_FIELDS = ['name']

const getRowProps = (row: Row<Record<string, unknown>>) => {
  const isTopRow = !row.id.includes('.')
  const isParentRow: boolean = Array.isArray(row.original.subRows) && row.original.subRows.length > 0

  return {
    className: isTopRow ? 'history-table__row--top' : 'history-table__row--collapsable',
    hover: isParentRow
  }
}

export const HistoryTable: React.FC = () => (
  <QueryTable<HistoryRowData>
    className='history-table'
    collectionName={CollectionNames.HistoryActions}
    columnsConfig={columnsConfig as Column[]}
    dataQuery={GET_HISTORY}
    getRowProps={getRowProps}
    mapper={(node: History) => new HistoryRowData(node)}
    onRowClick={(row: Row<Record<string, unknown>>) => void row.toggleRowExpanded()}
    paginateExpandedRows={true}
    searchableFields={SEARCHABLE_FIELDS}
  />
)
