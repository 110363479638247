import React from 'react'

import { TabNavigationItem } from './TabNavigationItem'

interface Props {
  defaultTab?: string
  searchParam: string
  tabs: TabItem[]
}

export interface TabItem {
  href: string
  title: string
}

export const TabNavigation: React.FC<Props> = ({ defaultTab, searchParam, tabs }) => (
  <nav>
    <ul className='tab-navigation'>
      {tabs?.map((tab) => (
        <TabNavigationItem
          defaultTab={defaultTab}
          href={tab.href}
          title={tab.title}
          key={tab.href}
          searchParam={searchParam}
        />
      ))}
    </ul>
  </nav>
)
