export class OssAdUnitRowData {
  ossAdUnit: OssAdUnit

  constructor(ossAdUnit: OssAdUnit) {
    this.ossAdUnit = ossAdUnit
  }

  get adUnitName(): string {
    return this.ossAdUnit.adUnitName ?? ''
  }

  get adUnitPath(): string {
    return this.ossAdUnit.adUnitPath
  }

  get adUnits(): AdUnit[] {
    return this.ossAdUnit.adUnits
  }

  get createdAt(): Date {
    return this.ossAdUnit.createdAt as Date
  }

  get customVersioningEnabled(): boolean {
    return this.ossAdUnit.customVersioningEnabled
  }

  get domain(): DomainBasic['name'] {
    return this.ossAdUnit.domain.name
  }

  get elementId(): string {
    return this.ossAdUnit.elementId
  }

  get id(): string {
    return this.ossAdUnit.id
  }

  get ossPages(): OssPage[] {
    return this.ossAdUnit.ossPages.nodes
  }

  get ossPagesCount(): number {
    return this.ossAdUnit.ossPagesCount
  }

  get updatedAt(): Date {
    return this.ossAdUnit.updatedAt
  }

  get qwEnabled(): boolean {
    return this.ossAdUnit.qwEnabled
  }

  get pgEnabled(): boolean {
    return this.ossAdUnit.pgEnabled
  }

  get reviveEnabled(): boolean {
    return this.ossAdUnit.reviveEnabled
  }

  get frbEnabled(): boolean {
    return this.ossAdUnit.frbEnabled
  }
}
