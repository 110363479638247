import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Plus } from 'icons/Plus'

interface Props {
  addFilter: () => void
  disabled: boolean
  theme?: ButtonTheme
}

export const AddFilterButton: React.FC<Props> = ({ addFilter, disabled, theme = ButtonTheme.Blue }) => {
  const { t } = useTranslation('common')

  return (
    <Button
      className='advanced-filter__add-filter'
      disabled={disabled}
      onClickHandler={addFilter}
      theme={theme}
      type={ButtonType.Secondary}
    >
      <Plus />
      <span>{t('advancedFilter.buttons.addFilter')}</span>
    </Button>
  )
}
