import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

const Footer: React.FC = memo(() => {
  const { t } = useTranslation()

  return (
    <footer className='footer'>
      <span className='footer__item'>{t('footer.copyright')}</span>
    </footer>
  )
})

Footer.displayName = 'Footer'

export { Footer }
