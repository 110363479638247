import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useAbility } from '@casl/react'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { AbilityContext } from 'webapp/context/AbilityContext'
import { BidderParamMode } from 'webapp/constants/BidderParamMode'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Errors, ErrorUtils } from 'utilities/errorUtils'
import { FormikBidderParamContainer as FormikBidderParam } from 'containers/FormikContainers'
import { FormSection } from 'components/Form/ParamFormSection'
import { StatusForm } from 'components/Form'

interface Props {
  className?: string
  data?: Bidder[]
  errors: Errors
  formData: AdUnitBiddersFormData
  formId: string
  isDemand?: boolean
  isSubmitting: boolean
  name: string
  onSubmit: (formData: AdUnitBiddersFormData) => void
  selectId: string
  setErrors: (errors: ErrorUtils) => void
  translations: string
}

export const AdUnitsBiddersForm: React.FC<Props> = ({
  className,
  data,
  errors,
  formData,
  formId,
  isDemand,
  isSubmitting,
  name,
  onSubmit,
  selectId,
  setErrors,
  translations
}) => {
  const ability = useAbility(AbilityContext)
  const { t } = useTranslation('inventory')
  const [manualDirtyClient, setManualDirtyClient] = useState(false)
  const [manualDirtyServer, setManualDirtyServer] = useState(false)

  const { clientBidders, serverBidders } = useSplitBidders(data)

  const manualDirty = manualDirtyClient || manualDirtyServer

  const hideParams = isDemand && !ability.can(AbilityAction.ACCESS, AbilitySubject.ALIAS_LIST)

  return (
    <div className={classNames('ad_unit-form ad_unit-form__bidders', className)}>
      {data && (
        <Formik initialValues={formData} onSubmit={onSubmit}>
          {({ dirty }) => (
            <StatusForm name={`${name}Form`} dirty={manualDirty}>
              <FormSection className='ad-unit-bidders' title={t('adUnits.form.inputs.c2s')}>
                <Field
                  adUnit={formData}
                  component={FormikBidderParam}
                  data={clientBidders}
                  dirty={dirty}
                  errors={errors}
                  formId={`${formId}-client`}
                  hideParams={hideParams}
                  name={`${name}.client`}
                  selectId={selectId}
                  setErrors={setErrors}
                  setManualDirty={setManualDirtyClient}
                  translations={translations}
                />
              </FormSection>

              <FormSection className='ad_unit-server' title={t('adUnits.form.inputs.s2s')}>
                <Field
                  adUnit={formData}
                  component={FormikBidderParam}
                  data={serverBidders}
                  dirty={dirty}
                  errors={errors}
                  formId={`${formId}-server`}
                  hideParams={hideParams}
                  name={`${name}.server`}
                  selectId={selectId}
                  setErrors={setErrors}
                  setManualDirty={setManualDirtyServer}
                  translations={translations}
                />
              </FormSection>

              <div className='ad_unit-form__submit-section'>
                <Button
                  buttonType='submit'
                  className='ad_unit-form__bidders-submit'
                  disabled={isSubmitting || !manualDirty}
                  theme={ButtonTheme.Orange}
                  type={ButtonType.Primary}
                >
                  {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                </Button>
              </div>
            </StatusForm>
          )}
        </Formik>
      )}
    </div>
  )
}

const useSplitBidders = (data?: Bidder[]) => {
  return useMemo(
    () => ({
      clientBidders: data?.filter((bidder) => bidder.mode?.includes(BidderParamMode.Client)),
      serverBidders: data?.filter((bidder) => bidder.mode?.includes(BidderParamMode.Server))
    }),
    [data]
  )
}
