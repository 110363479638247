import React from 'react'
import { Link } from 'react-router-dom'
import withBreadcrumbs, { InjectedProps } from 'react-router-breadcrumbs-hoc'

import { Image } from 'components/Image'
import { removeProductPrefix } from 'utilities/products/utils'
import { routes } from './BreadcrumbsRoutes'

import ArrowRight from 'images/arrow-right.svg'

const BreadcrumbsComponent: React.FC<InjectedProps<unknown>> = ({ breadcrumbs }) => {
  const disabledPaths = ['/performance', '/performance/prebid', '/setup', '/finance']

  return (
    <div className='navigation__head'>
      <div className='navigation__breadcrumbs'>
        {breadcrumbs.map(({ breadcrumb, match, location }, index: number) => (
          <span key={index}>
            {index != breadcrumbs.length - 1 ? (
              <>
                {disabledPaths.includes(removeProductPrefix(match.url)) ? (
                  <>{breadcrumb}</>
                ) : (
                  <Link to={{ pathname: match.url, state: location.state }} className='navigation__breadcrumbs-item'>
                    {breadcrumb}
                  </Link>
                )}
                <Image src={ArrowRight} alt='Breadcrumb' className='navigation__breadcrumbs-delimiter' />
              </>
            ) : (
              <>{breadcrumb}</>
            )}
          </span>
        ))}
      </div>
    </div>
  )
}

export const Breadcrumbs = withBreadcrumbs(routes, { disableDefaults: true })(BreadcrumbsComponent) as any
