/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SizeTypeEnum } from 'utilities/sizeTypeUtils'

export class AdUnitSerializer {
  adUnit: AdUnitForm
  existingMediaTypes: MediaTypeForm[]

  constructor(adUnit: AdUnitForm, existingMediaTypes?: MediaTypeForm[]) {
    this.adUnit = adUnit
    this.existingMediaTypes = existingMediaTypes || []
  }

  bulkCreateParams() {
    return {
      domainVersionId: this.adUnit.domainVersionId?.value || '',
      domainVersionIds: this.domainVersionIds(),
      pagePaths: this.adUnit.pageIds.map((page) => page.label),
      ...this.prepareBody()
    }
  }

  createParams(domainVersionId) {
    return {
      domainVersionId,
      pageIds: this.pageIds(),
      ...this.prepareBody()
    }
  }

  updateParams() {
    return {
      adUnitId: this.adUnit.id,
      domainVersionId: this.adUnit.domainVersionId?.value || '',
      pageIds: this.pageIds(),
      ...this.prepareBody()
    }
  }

  static updateAmazonDemandParams(formData: AdUnitAmazonDemandFormData) {
    return {
      adUnitId: formData.id,
      apsEnabled: formData.apsEnabled
    }
  }

  private prepareBody() {
    return {
      adUnitPath: this.adUnit.adUnitPath,
      apsEnabled: this.adUnit.apsEnabled,
      elementId: this.adUnit.elementId,
      fillrateBoosterEnabled: this.adUnit.fillrateBoosterEnabled,
      lazyLoadEnabled: this.adUnit.lazyLoadEnabled,
      mediaTypesAttributes: this.mediaTypesAttributes(),
      outstreamVideoAttributes: this.buildOutstreamVideo(),
      outstreamVideoEnabled: this.adUnit.outstreamVideoEnabled,
      ...this.remoteControl(),
      sizeType: this.adUnit.sizeType
    }
  }

  private buildOutstreamVideo() {
    if (AdUnitSerializer.isOutstreamVideoVisible(this.adUnit)) {
      try {
        return this.adUnit.outstreamVideoAttributes ? JSON.parse(this.adUnit.outstreamVideoAttributes!) : {}
      } catch {
        return {}
      }
    }

    return undefined
  }

  private domainVersionIds() {
    return this.adUnit.domainVersionIds || []
  }

  private mediaTypesAttributes() {
    if (this.adUnit.sizeType === SizeTypeEnum.GENERAL) {
      return [this.buildMediaTypeAttribute(this.adUnit.mediaTypes!)]
    } else {
      return this.sizeMappingToSave()?.concat(this.sizeMappingToRemove())
    }
  }

  private pageIds() {
    return this.adUnit.pageIds.map((page) => page.value)
  }

  private remoteControl() {
    const remoteControl = this.adUnit.remoteControl
    const result: { remoteControlEnabled: boolean; remoteControlAttributes?: RemoteControlAttributes } = {
      remoteControlEnabled: this.adUnit.remoteControlEnabled
    }
    if (this.adUnit.remoteControlEnabled && remoteControl) {
      const { id, appliedMethod, elementSelector, jsCodeEnabled, jsCode, cssCodeEnabled, cssCode } = remoteControl

      result.remoteControlAttributes = {
        id,
        applied_method: appliedMethod.value,
        element_selector: elementSelector,
        js_code_enabled: jsCodeEnabled,
        js_code: jsCode,
        css_code_enabled: cssCodeEnabled,
        css_code: cssCode
      }
    }
    return result
  }

  private buildMediaTypeAttribute(item: MediaTypeForm, sizeMapping = false) {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const result: any = { id: item.id, workspace_device_id: (sizeMapping && item.value) || null }

    if (sizeMapping && !item.id) {
      result.id = this.existingMediaTypes.find((mediaType) => mediaType.value === item.value)?.id
    }

    result.banner_sizes = item.bannerEnabled ? this.sizeConverter(item.bannerSizes) : null

    if (item.nativeEnabled) {
      try {
        result.native = JSON.parse(sizeMapping ? this.adUnit.sizeMappingNative! : item.native!)
      } catch {
        result.native = {}
      }
    }

    if (item.videoEnabled) {
      let sizes: number[] | string = []

      if (item.playerSize && item.playerSize.length > 0) {
        sizes = this.convertSizeStringToArray(item.playerSize[0])
      }

      result.player_size = sizes
    } else {
      result.player_size = null
    }

    return result
  }

  private sizeMappingToSave() {
    return this.adUnit.sizeMapping?.map((config) => this.buildMediaTypeAttribute(config, true))
  }

  private sizeMappingToRemove() {
    const workspaceDeviceIds = this.adUnit.sizeMapping?.map((config) => config.value)
    return this.existingMediaTypes.reduce((result: unknown[], mediaType) => {
      if (!workspaceDeviceIds?.includes(mediaType.value)) {
        result.push({ id: mediaType.id, _destroy: true })
      }
      return result
    }, [])
  }

  private sizeConverter = (sizes: string[] = []) => sizes.map(this.convertSizeStringToArray)

  private convertSizeStringToArray(size: string): number[] | string {
    if (size.includes('x')) {
      return size?.split('x').map((sizePart) => Number(sizePart))
    } else {
      return size
    }
  }

  static isOutstreamVideoVisible = (formData: AdUnitForm) => {
    if (formData.sizeType === SizeTypeEnum.GENERAL) {
      return formData.mediaTypes?.videoEnabled ?? false
    }
    return formData.sizeMapping?.some(({ videoEnabled }) => videoEnabled) ?? false
  }
}
