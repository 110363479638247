import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'

import BidderSettingsQuery from 'gql/queries/bidderSettings/BidderSettings.gql'
import DomainsQuery from 'gql/queries/domains/DomainFilter.gql'
import { FilterType } from 'webapp/constants/FilterType'

interface Cache {
  bidderOptions?: SelectOption[]
  domains?: DomainFilter[]
}

export function useAdvancedFilterConfig(): AdvancedFilterConfiguration<Cache>[] {
  const { t } = useTranslation('setup')
  const client = useApolloClient()

  const bidderSettingsCacheConfig: AdvancedFilterSelectConfiguration<Cache>['cacheConfig'] = {
    checkIfAlreadyCached: (cache) => !!cache.bidderOptions,
    addToCache: () =>
      client.query({ query: BidderSettingsQuery, fetchPolicy: 'network-only' }).then((data) => ({
        bidderOptions: data.data.bidderSettings.nodes.map((node: BidderSetting) => {
          return {
            label: node.bidder.name,
            value: node.bidderCode
          }
        })
      }))
  }

  const domainsCacheConfig: AdvancedFilterSelectConfiguration<Cache>['cacheConfig'] = {
    checkIfAlreadyCached: (cache) => !!cache.domains,
    addToCache: () =>
      client
        .query({ query: DomainsQuery, fetchPolicy: 'network-only' })
        .then((data) => ({ domains: data.data.domains.nodes }))
  }

  return [
    {
      type: FilterType.Select,
      label: t('bidders.domainComparison.filter.names.domainName'),
      name: 'name',
      buildValueOptions: (cache) =>
        cache.domains?.flatMap((domain) => domain.name).map((item) => ({ label: item, value: item })) ?? [],
      cacheConfig: domainsCacheConfig,
      isMulti: true
    },
    {
      type: FilterType.Select,
      label: t('bidders.domainComparison.filter.names.domainVersionName'),
      name: 'domain_versions.name',
      buildValueOptions: (cache) =>
        cache.domains
          ?.flatMap((domain) => domain.versions.nodes.map((version) => version.name))
          .map((item) => ({ label: item, value: item })) ?? [],
      cacheConfig: domainsCacheConfig,
      isMulti: true
    },
    {
      type: FilterType.Select,
      label: t('bidders.domainComparison.filter.names.bidderName'),
      name: 'bidder_settings.bidder_code',
      buildValueOptions: (cache) => cache.bidderOptions ?? [],
      cacheConfig: bidderSettingsCacheConfig,
      isMulti: true
    }
  ]
}
