import { gql } from '@apollo/client'

export const ACTIVE_OSS_AD_UNITS_FOR_PAGE_CONNECTING_TABLE = gql`
  query activeOssAdUnitsForPageConnectingTable($ossPageId: ID) {
    ossPage(ossPageId: $ossPageId) {
      ossAdUnits {
        nodes {
          adUnitName
          adUnitPath
          id
        }
      }
    }
  }
`
