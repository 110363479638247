import { emptySelectOption } from 'webapp/constants/emptySelectOption'

export type GAMConnectorFormType = {
  id: string | undefined
  creativeTemplateEnabled: boolean
  creativeTemplateId: string
  currency: SelectOption
  native: boolean
  nativeCodeTemplateId: string
  network: SelectOption
  outstreamVideoEnabled: true
}

export const GAMConnectorFactory = (connection?: ConnectedNetwork, forceNative?: boolean): GAMConnectorFormType => {
  return {
    id: connection?.id,
    creativeTemplateEnabled: Boolean(connection?.creativeTemplateId?.trim()),
    creativeTemplateId: connection?.creativeTemplateId ?? '',
    currency: connection ? { value: connection.currency } : emptySelectOption,
    native: forceNative || Boolean(connection?.nativeCodeTemplateId?.trim()),
    nativeCodeTemplateId: connection?.nativeCodeTemplateId ?? '',
    network: connection ? { value: connection.networkCode } : emptySelectOption,
    outstreamVideoEnabled: true
  }
}
