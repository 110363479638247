import React from 'react'

import { ErrorsContainer } from 'containers/Errors'
import ErrorImg from 'images/network_error.png'
import { useGoogleAnalytics } from 'utilities/reactGa'

export const NetworkErrorPage: React.FC = () => {
  useGoogleAnalytics(location.pathname)

  return (
    <ErrorsContainer className='error-page__network-error' image={ErrorImg} translationPlaceholder='networkError' />
  )
}
