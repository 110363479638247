import React, { useContext } from 'react'

import { CellProps, Column } from 'react-table'

import { OssPageRow } from 'components/oss/Inventory/Pages/PagesTable/OssPageRow'
import { UserContext } from 'webapp/context/UserContext'
import i18n from 'webapp/i18n'
import { ActionsCell } from './ActionsCell'
import PlaceholderCell from './PlaceholderCell'

const SUPERUSER_COLUMNS = ['workspaceName']

export const columnsConfig: Array<Column<OssPageRow>> = [
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.domain'),
    accessor: 'domainName'
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.workspaceName'),
    accessor: 'workspaceName'
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.pageName'),
    accessor: 'pageName',
    Cell: PlaceholderCell
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.path'),
    accessor: 'path'
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.ossAdUnitsCount'),
    accessor: 'ossAdUnitsCount'
  },
  {
    Header: () => i18n.t('inventory:pages.list.tableHeader.updatedAt'),
    accessor: 'updatedAt'
  },
  {
    Header: () => i18n.t('table.header.actions'),
    id: 'actions',
    Cell: ({ row }: CellProps<OssPageRow>) => <ActionsCell row={row} />,
    disableSortBy: true
  }
]

export const useColumnsConfig = (): Column<OssPageRow>[] => {
  const { isSuperuser } = useContext(UserContext)

  if (isSuperuser) {
    return columnsConfig
  } else {
    return columnsConfig.filter(({ accessor }) => !SUPERUSER_COLUMNS.includes(accessor as string))
  }
}
