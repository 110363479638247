import { prebidAnalyticsTabs } from 'components/quickwrap/Performance/PrebidAnalytics/prebidAnalyticsTabs'
import { BidderInventoryTabs } from 'components/quickwrap/Setup/BidderInventory/bidderInventoryTabs'
import { Product } from 'webapp/constants/Product'
import { RouteProductPrefix } from 'webapp/constants/RouteProductPrefix'

export const quickWrapRoutes = {
  quickwrap: {
    abTesting: {
      edit: (abTestId = ':abTestId'): string => `/${RouteProductPrefix[Product.Quickwrap]}/ab-testing/${abTestId}/edit`,
      new: `/${RouteProductPrefix[Product.Quickwrap]}/ab-testing/new`,
      root: `/${RouteProductPrefix[Product.Quickwrap]}/ab-testing`
    },

    blockUrlGroups: {
      edit: (blockUrlGroupId?: string): string =>
        `/${RouteProductPrefix[Product.Quickwrap]}/protection/block_url_groups/${
          blockUrlGroupId || ':blockUrlGroupId'
        }/edit`,
      new: `/${RouteProductPrefix[Product.Quickwrap]}/protection/block_url_groups/new`,
      root: `/${RouteProductPrefix[Product.Quickwrap]}/protection/block_url_groups`
    },

    downloadTags: {
      root: `/${RouteProductPrefix[Product.Quickwrap]}/download_tags`
    },

    inventory: {
      adUnit: {
        edit: (adUnitId = ':adUnitId', domainVersionId = ':domainVersionId'): string =>
          `/${RouteProductPrefix[Product.Quickwrap]}/inventory/ad_units/${adUnitId}/versions/${domainVersionId}/edit`,
        root: `/${RouteProductPrefix[Product.Quickwrap]}/inventory/ad_units`
      },
      domains: {
        edit: (domainId = ':domainId', domainVersionId = ':domainVersionId'): string =>
          `/${RouteProductPrefix[Product.Quickwrap]}/inventory/domains/${domainId}/versions/${domainVersionId}/edit`,
        root: `/${RouteProductPrefix[Product.Quickwrap]}/inventory/domains`
      },
      pages: {
        edit: (pageId = ':pageId'): string =>
          `/${RouteProductPrefix[Product.Quickwrap]}/inventory/pages/${pageId}/edit`,
        root: `/${RouteProductPrefix[Product.Quickwrap]}/inventory/pages`
      }
    },

    performance: {
      prebid: {
        root: `/${RouteProductPrefix[Product.Quickwrap]}/performance/prebid`,
        tab: (prebidAnalyticsTab: typeof prebidAnalyticsTabs[keyof typeof prebidAnalyticsTabs]): string =>
          `/${RouteProductPrefix[Product.Quickwrap]}/performance/prebid?tab=${prebidAnalyticsTab}`
      },
      reporting: `/${RouteProductPrefix[Product.Quickwrap]}/performance/reporting`,
      reportingNew: `/${RouteProductPrefix[Product.Quickwrap]}/performance/reporting/new`,
      reportingResults: `/${RouteProductPrefix[Product.Quickwrap]}/performance/reporting/results`,
      root: `/${RouteProductPrefix[Product.Quickwrap]}/performance`
    },

    portal: {
      root: `/${RouteProductPrefix[Product.Quickwrap]}/portal`
    },

    setup: {
      bidders: (tab?: typeof BidderInventoryTabs[keyof typeof BidderInventoryTabs]): string =>
        tab
          ? `/${RouteProductPrefix[Product.Quickwrap]}/setup/bidders?tab=${tab}`
          : `/${RouteProductPrefix[Product.Quickwrap]}/setup/bidders`,
      customCodes: {
        edit: (customCodeId = ':customCodeId'): string =>
          `/${RouteProductPrefix[Product.Quickwrap]}/setup/custom_codes/${customCodeId}/edit`,
        new: `/${RouteProductPrefix[Product.Quickwrap]}/setup/custom_codes/new`
      },
      general: `/${RouteProductPrefix[Product.Quickwrap]}/setup/general`,
      modules: `/${RouteProductPrefix[Product.Quickwrap]}/setup/modules`,
      networks: {
        edit: (networkId?: string) => `/setup/networks/${networkId || ':networkId'}/edit`,
        new: '/setup/networks/new',
        root: '/setup/networks'
      },
      root: `/${RouteProductPrefix[Product.Quickwrap]}/setup`
    }
  }
}
