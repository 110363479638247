import React from 'react'

export const Bin: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14.069' height='16.4' viewBox='0 0 14.069 16.4'>
      <g id='bin_1_' data-name='bin (1)' transform='translate(0.208 0.2)'>
        <path
          id='Path_9212'
          data-name='Path 9212'
          className='cls-1'
          d='M222.828,154.7a.429.429,0,0,0-.429.429v8.11a.429.429,0,0,0,.858,0v-8.11A.429.429,0,0,0,222.828,154.7Zm0,0'
          transform='translate(-213.692 -150.388)'
        />
        <path
          id='Path_9213'
          data-name='Path 9213'
          className='cls-1'
          d='M104.828,154.7a.429.429,0,0,0-.429.429v8.11a.429.429,0,0,0,.858,0v-8.11A.429.429,0,0,0,104.828,154.7Zm0,0'
          transform='translate(-100.311 -150.388)'
        />
        <path
          id='Path_9214'
          data-name='Path 9214'
          className='cls-1'
          d='M1.115,4.762l.872,9.232a2.013,2.013,0,0,0,.577,1.426A1.986,1.986,0,0,0,3.97,16h5.7a1.985,1.985,0,0,0,1.406-.579,2.013,2.013,0,0,0,.577-1.426l.872-9.232a1.435,1.435,0,0,0,1.105-1.569,1.481,1.481,0,0,0-1.491-1.246H10.129V1.479A1.437,1.437,0,0,0,9.675.428,1.588,1.588,0,0,0,8.57,0h-3.5a1.588,1.588,0,0,0-1.1.43,1.437,1.437,0,0,0-.454,1.05v.468H1.5A1.481,1.481,0,0,0,.01,3.193,1.435,1.435,0,0,0,1.115,4.762Zm8.56,10.487H3.97a1.212,1.212,0,0,1-1.2-1.255L1.9,4.795h9.841l-.872,9.2A1.212,1.212,0,0,1,9.675,15.249ZM4.3,1.479A.7.7,0,0,1,4.527.958a.779.779,0,0,1,.548-.21h3.5a.779.779,0,0,1,.548.21.7.7,0,0,1,.223.521v.468H4.3ZM1.5,2.7H12.145a.692.692,0,0,1,.709.674.692.692,0,0,1-.709.674H1.5a.692.692,0,0,1-.709-.674A.692.692,0,0,1,1.5,2.7Zm0,0'
          transform='translate(0.003 0.001)'
        />
        <path
          id='Path_9215'
          data-name='Path 9215'
          className='cls-1'
          d='M163.828,154.7a.429.429,0,0,0-.429.429v8.11a.429.429,0,0,0,.858,0v-8.11A.429.429,0,0,0,163.828,154.7Zm0,0'
          transform='translate(-157.002 -150.388)'
        />
      </g>
    </svg>
  )
}
