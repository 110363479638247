import { Product } from 'webapp/constants/Product'

/**
 * Sorted list of products that aren't enabled by default
 */
export const SecondaryProducts = [
  Product.FillRateBooster,
  Product.PriceGenius,
  Product.Quickwrap,
  Product.Revive,
  Product.ViewabilityBooster
]
