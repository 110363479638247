import React from 'react'
import { useFormikContext } from 'formik'

import { BiddersSelect } from 'components/FormikComponents'
import { Errors } from 'utilities/errorUtils'

interface Props {
  bidders: Bidder[]
  bidderSettings: BidderSetting[]
  errors: Errors
}

export const OwnBidderSelect: React.FC<Props> = ({ bidders, bidderSettings, errors }) => {
  const { setFieldValue } = useFormikContext<OwnBidderFormData>()

  const changeBidderHandler = (bidderId: string) => {
    updateBidderCode(bidderId)
    updateS2sAvailable(bidderId)
  }

  const updateBidderCode = (bidderId: string) => {
    const fieldName = 'bidderCode'
    const isFirstBidderId = bidderSettings.every((bidder: BidderSetting) => bidder?.bidderId?.value !== bidderId)
    const bidderCode = bidders.find((bidder) => bidder.id === bidderId)?.code
    isFirstBidderId ? setFieldValue(fieldName, bidderCode) : setFieldValue(fieldName, '')
  }

  const updateS2sAvailable = (bidderId: string) => {
    const fieldName = 's2sAvailable'
    const isS2sAvailable = bidders.find((bidder) => bidder.id === bidderId)?.s2sAvailable
    setFieldValue(fieldName, isS2sAvailable)
  }

  return (
    <BiddersSelect
      id='own-bidder-select'
      bidders={bidders}
      name='bidderId'
      errors={errors.errorsFor('bidderId')}
      label
      onChangeHandler={(value) => changeBidderHandler(String(value.value))}
    />
  )
}
