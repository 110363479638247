import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonTheme, ButtonType } from 'components/Button'
import { LinkButton } from 'components/LinkButton'
import { Image } from 'components/Image'
import { routesBuilder } from 'utilities/routesBuilder'
import { Product } from 'webapp/constants/Product'
import { useCurrentProduct } from 'utilities/products/current'

interface Props {
  className: string
  image: string
  translationPlaceholder: string
}

export const ErrorsContainer: React.FC<Props> = ({ className, image, translationPlaceholder }) => {
  const { t } = useTranslation()
  const containerClassNames = `error-page ${className}`
  const isOss = useCurrentProduct() === Product.OSS

  return (
    <div className={containerClassNames}>
      <Image src={image} alt={t(`errors.${translationPlaceholder}.imageAlt`)} className='error-page__image' />
      <div className='error-page__text'>{t(`errors.${translationPlaceholder}.text`)}</div>
      <LinkButton
        to={isOss ? routesBuilder.oss.inventory.domains.root : routesBuilder.quickwrap.inventory.domains.root}
        type={ButtonType.Primary}
        theme={ButtonTheme.Blue}
        className='error-page__button'
      >
        {t(`errors.${translationPlaceholder}.button`)}
      </LinkButton>
    </div>
  )
}
