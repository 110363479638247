export enum WorkspacePackageEnum {
  BASIC = 'Basic',
  PREBID_MANAGER = 'Prebid manager',
  S2S = 'S2S',
  PRICE_GENIUS = 'PriceGenius',
  YB_DEMAND = 'YB Demand',
  CUSTOM_CODE = 'Custom Code',
  AD_NETWORK = 'Ad network',
  FILLRATE_BOOSTER = 'FillrateBooster',
  VIEWABILITY_BOOSTER = 'ViewabilityBooster'
}

export const isWorkspacePackageActive = (
  workspacePackages: Array<WorkspacePackage> = [],
  packageName: string
): boolean => {
  return workspacePackages.some((pack: WorkspacePackage) => pack.active && pack.name === packageName)
}
