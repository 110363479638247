import React from 'react'

import { BiddersPage } from 'pages/quickwrap/Setup/BiddersPage'
import { GeneralSettingsPage } from 'pages/quickwrap/Setup/GeneralSettingsPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { ListPage as NetworksListPage } from 'pages/quickwrap/Setup/Networks/ListPage'
import { ModulesPage } from 'pages/quickwrap/Setup/ModulesPage/ModulesPage'
import { NewPage as NetworksNewPage } from 'pages/quickwrap/Setup/Networks/NewPage'
import { CreatePage as CustomCodeCreatePage } from 'pages/quickwrap/Setup/CustomCodes/CreatePage'
import { EditPage as CustomCodeEditPage } from 'pages/quickwrap/Setup/CustomCodes/EditPage'
import { EditPage as NetworksEditPage } from 'pages/quickwrap/Setup/Networks/EditPage'

import { routesBuilder } from 'utilities/routesBuilder'

export const SetupRoutes = [
  <LayoutRoute
    key='setup-bidders'
    path={routesBuilder.quickwrap.setup.bidders()}
    component={BiddersPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='setup-general'
    path={routesBuilder.quickwrap.setup.general}
    component={GeneralSettingsPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='setup-modules'
    path={routesBuilder.quickwrap.setup.modules}
    component={ModulesPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='setup-edit-custom-codes'
    path={routesBuilder.quickwrap.setup.customCodes.edit()}
    component={CustomCodeEditPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='setup-create-custom-codes'
    path={routesBuilder.quickwrap.setup.customCodes.new}
    component={CustomCodeCreatePage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='setup-networks-new'
    path={routesBuilder.quickwrap.setup.networks.new}
    component={NetworksNewPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='setup-networks-edit'
    path={routesBuilder.quickwrap.setup.networks.edit()}
    component={NetworksEditPage}
    userPermissionRequired={true}
  />,
  <LayoutRoute
    key='setup-networks'
    path={routesBuilder.quickwrap.setup.networks.root}
    component={NetworksListPage}
    userPermissionRequired={true}
  />
]
