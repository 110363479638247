import { ProductPlan } from 'webapp/constants/ProductPlan'

export class ProductConnectionSerializer {
  constructor(public productConnection: DataStudioLinkFormData) {}

  public updateDataStudioUrl() {
    return {
      productConnectionId: this.productConnection.productConnectionId?.value,
      dataStudioUrl: this.productConnection.dataStudioUrl.trim()
    }
  }

  static activateProductPlanParams = (productConnection: ProductConnection, plan: ProductPlan) => {
    return {
      productConnectionId: productConnection.id,
      plan: plan
    }
  }

  static unsetDataStudioUrl(productConnection: ProductConnection) {
    return {
      productConnectionId: productConnection.id,
      dataStudioUrl: null
    }
  }
}
