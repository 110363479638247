import React from 'react'

import { Arrow } from 'icons/Arrow'

interface Props {
  value: string | number | undefined
  step?: number
  disabled?: boolean
  onChangeHandler?: (value: string) => void
}

export const Arrows: React.FC<Props> = ({ value, step = 1, disabled, onChangeHandler }) => {
  const isInteger = Number.isInteger(step)
  const getValue = value?.toString() || '0'
  const parsedValue = isInteger ? parseInt(getValue) : parseFloat(getValue)
  const fixedNumber = isInteger ? 0 : step.toString().split('.')[1].length || 0

  const onClick = (e: React.MouseEvent<Element, MouseEvent>, type: string) => {
    if (disabled) return

    e.preventDefault()
    const processedValue = type === 'increment' ? parsedValue + step : parsedValue - step
    onChangeHandler && onChangeHandler(processedValue.toFixed(fixedNumber))
  }

  return disabled ? null : (
    <>
      <Arrow classNames='arrow arrow--up' alt='Increment' onClickHandler={(e) => onClick(e, 'increment')} />
      <Arrow classNames='arrow arrow--down' alt='Decrement' onClickHandler={(e) => onClick(e, 'decrement')} />
    </>
  )
}
