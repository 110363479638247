import { gql } from '@apollo/client'
import { MUTATION_STATE_SIMPLE } from 'webapp/gql/fragments/common'

const PAYLOAD_TYPE = 'DestroyAdUnitPayload'

export const DESTROY_AD_UNIT = gql`
  ${MUTATION_STATE_SIMPLE(PAYLOAD_TYPE)}
  mutation DestroyAdUnit($input: DestroyAdUnitInput!) {
    destroyAdUnit(input: $input) {
      adUnit {
        id
      }

      ...MutationState${PAYLOAD_TYPE}Fields
    }
  }
`
