import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { Box, BoxHeader } from 'components/Box'
import { NetworkFactory } from 'components/quickwrap/Setup/Networks/NetworksForm/formSchema'
import { NetworksForm } from 'components/quickwrap/Setup/Networks/NetworksForm'

import NetworkQuery from 'gql/queries/networks/Network.gql'

export const EditPage = (): JSX.Element => {
  const { networkId } = useParams<{ networkId: string }>()
  const { t } = useTranslation('setup')
  const [network, setNetwork] = useState<Network>()

  useQuery(NetworkQuery, {
    variables: { networkId },
    fetchPolicy: 'network-only',
    onCompleted: ({ network }: { network: Network }) => {
      setNetwork(network)
    }
  })

  return (
    <Box>
      <BoxHeader title={t('networks.edit.title')} />

      {network && <NetworksForm network={NetworkFactory(network)} />}
    </Box>
  )
}
