import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ConfirmationModal, Modal } from 'components/Modals'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import SmartBiddersRequest from 'gql/mutations/bidders/SmartBiddersRequest.gql'

const BLOCK_SMART_BIDDERS_TILL_KEY = 'quickwrap.blockSmartBiddersTill'
const DAY_IN_MS = 86400000

export const YieldbirdSmartBiddersContainer = () => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [shouldBlockRequest, setShouldBlockRequest] = useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)

  useEffect(() => {
    const blockSmartBiddersTill = localStorage.getItem(BLOCK_SMART_BIDDERS_TILL_KEY)
    if (blockSmartBiddersTill) {
      setShouldBlockRequest(+blockSmartBiddersTill - Date.now() > 0)
    }
  }, [])

  const [sendSmartBiddersRequest, { loading }] = useMutation(SmartBiddersRequest, {
    onCompleted: ({ smartBiddersRequest: { errors } }) => {
      if (errors.length) {
        createNotifier(t(errors[0].message), NotificationType.ERROR)
      } else {
        localStorage.setItem(BLOCK_SMART_BIDDERS_TILL_KEY, String(Date.now() + DAY_IN_MS))
        setShouldBlockRequest(true)
        setIsSuccessVisible(true)
      }
    }
  })

  const onShowConfirmation = () => setIsConfirmationVisible(true)
  const onConfirm = () => {
    sendSmartBiddersRequest(nestGqlInput({}))
    setIsConfirmationVisible(false)
  }
  const onCloseSuccessModal = () => setIsSuccessVisible(false)

  return (
    <div className='smart-bidders'>
      <div className='smart-bidders__inner'>
        <Button
          disabled={shouldBlockRequest}
          onClickHandler={onShowConfirmation}
          theme={ButtonTheme.Blue}
          type={ButtonType.Primary}
        >
          {t('smartBidders.button')}
        </Button>
      </div>

      <ConfirmationModal
        cancelButtonText={t('common:buttons.cancel')}
        confirmButtonText={t('smartBidders.confirmationModal.submit')}
        content={t('smartBidders.confirmationModal.content')}
        isSubmitting={loading}
        onConfirmHandler={onConfirm}
        setVisible={setIsConfirmationVisible}
        title={t('smartBidders.confirmationModal.title')}
        visible={isConfirmationVisible}
      />

      <Modal
        backdrop={true}
        setVisible={setIsSuccessVisible}
        shadow={true}
        title={t('smartBidders.successModal.title')}
        visible={isSuccessVisible}
      >
        <div className='confirmation__modal-container'>
          <p>{t('smartBidders.successModal.content_1')}</p>
          <p>{t('smartBidders.successModal.content_2')}</p>
          <div>
            <Button
              buttonType='button'
              className='confirmation__modal__button'
              onClickHandler={onCloseSuccessModal}
              theme={ButtonTheme.Blue}
              type={ButtonType.Primary}
            >
              {t('common:buttons.continue')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
