import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { Product } from 'constants/Product'
import { HistoryListPage } from 'pages/History/ListPage'
import { routesBuilder } from 'utilities/routesBuilder'

export const HistoryRoutes = [
  <LayoutRoute
    component={HistoryListPage}
    exact
    key='oss-history'
    path={routesBuilder.oss.history.root}
    product={Product.OSS}
  />
]
