/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import classNames from 'classnames'
import { Row } from 'react-table'
import { useTranslation } from 'react-i18next'

import { Arrow } from 'icons/Arrow'

interface Props {
  row: Row<any>
  handleToggling?: boolean
}

export const ToggleCell: React.FC<Props> = ({ row, handleToggling = true }) => {
  const { t } = useTranslation()
  const subRows = row.subRows

  if (!row.canExpand || !subRows.length) {
    return null
  }

  const arrowClassNames = classNames('toggle-cell__arrow', row.isExpanded ? 'arrow--up' : 'arrow--down')
  const buttonProps: any = row.getToggleRowExpandedProps({
    className: 'toggle-cell__button',
    title: t('toggleRow')
  } as any)

  if (!handleToggling) {
    buttonProps.onClick = null
  }

  return (
    <div className='toggle-cell'>
      <button {...buttonProps}>
        <Arrow alt={t('toggleRow')} classNames={arrowClassNames} />
      </button>
    </div>
  )
}
