import React from 'react'
import { useField, FieldConfig } from 'formik'
import { PropsValue } from 'react-select'

import { SortableSelect } from 'components/Form'

type FormikSortableSelectContainerType = {
  className?: string
  closeMenuOnSelect?: boolean
  containerClassName?: string
  defaultValue?: SelectOption
  disabled?: boolean
  errors?: string[]
  field: FieldConfig
  filterOption?: (candidate: SelectOption, input: string) => boolean
  id: string
  isMulti?: boolean
  isSearchable?: boolean
  label?: string
  labelStyle?: string
  onChangeHandler?: (value: PropsValue<SelectOption>) => void
  options: SelectOption[]
  placeholder: string
  selectedItem?: SelectOption | []
  tooltipContent?: string
}

export const FormikSortableSelectContainer = ({
  id,
  className,
  closeMenuOnSelect,
  containerClassName,
  defaultValue,
  disabled,
  errors,
  filterOption,
  isMulti,
  isSearchable,
  label,
  labelStyle,
  onChangeHandler,
  options,
  placeholder,
  tooltipContent,
  selectedItem,
  ...other
}: FormikSortableSelectContainerType): JSX.Element => {
  const [field, , helpers] = useField(other.field.name)
  const onChange = (value: PropsValue<SelectOption>) => {
    const result = value || []

    helpers.setValue(result)
    onChangeHandler && onChangeHandler(result)
  }

  const filterSelectedValues = (): SelectOption | SelectOption[] | undefined => {
    if (isMulti) {
      return field.value
    }
    return options.find((elem: SelectOption): boolean => elem.value === field.value?.value)
  }

  return (
    <>
      <SortableSelect
        id={id}
        name={field.name}
        className={className}
        containerClassName={containerClassName}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isSearchable={isSearchable}
        closeMenuOnSelect={closeMenuOnSelect}
        disabled={disabled}
        errors={errors}
        label={label}
        labelStyle={labelStyle}
        tooltipContent={tooltipContent}
        selectedItem={selectedItem || filterSelectedValues()}
        onChangeHandler={onChange}
        filterOption={filterOption}
        isMulti={isMulti}
        setSelected={(value: string[]) => helpers.setValue(value)}
      />
    </>
  )
}
