import { useHistory } from 'react-router-dom'

import { Product } from 'constants/Product'
import { ProductHistory } from 'types/router'
import { UseProductHistoryProps } from './types'

export const useProductHistory = (): UseProductHistoryProps => {
  const history = useHistory<ProductHistory>()

  const prevProduct: Product | undefined = history.location.state?.fromProduct

  return { history, prevProduct }
}
