import React, { useMemo } from 'react'
import { useField } from 'formik'

import { TotalTraffic } from 'components/Form'

export const ABTestTotalTraffic: React.FC = () => {
  const [fieldInput] = useField<ABTestFormData['abTestVersionsAttributes']>('abTestVersionsAttributes')
  const abTestVersionsAttributes = fieldInput.value

  const totalTraffic = useMemo(() => {
    return abTestVersionsAttributes.reduce((acc, testVersion) => {
      return acc + Number(testVersion.share)
    }, 0)
  }, [abTestVersionsAttributes])

  if (!abTestVersionsAttributes.length) {
    return null
  }

  return <TotalTraffic percent={totalTraffic} />
}
