import { useQuery } from '@apollo/client'
import { useState } from 'react'

import WorkspaceProductsQuery from 'gql/queries/workspaces/WorkspaceProducts.gql'

export const useWorkspacesWithProducts = () => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>()

  useQuery(WorkspaceProductsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setWorkspaces(data.workspaces.nodes)
    }
  })

  return workspaces
}
