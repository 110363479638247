import React from 'react'

import { DataStudioLinksListPage } from 'pages/oss/Performance/DataStudioLinks/ListPage'
import { DataStudioPage } from 'pages/shared/Performance/DataStudio'
import { EditPage } from 'pages/oss/Performance/DataStudioLinks/EditPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { NewPage } from 'pages/oss/Performance/DataStudioLinks/NewPage'
import { Product } from 'webapp/constants/Product'
import { routesBuilder } from 'utilities/routesBuilder'

export const DataStudioRoutes = [
  <LayoutRoute
    key='data-studio-list'
    product={Product.OSS}
    exact
    path={routesBuilder.oss.performance.dataStudioLinks.root}
    component={DataStudioLinksListPage}
    userPermissionRequired
  />,
  <LayoutRoute
    key='data-studio-edit'
    product={Product.OSS}
    path={routesBuilder.oss.performance.dataStudioLinks.edit()}
    component={EditPage}
    userPermissionRequired
  />,
  <LayoutRoute
    key='data-studio-new'
    product={Product.OSS}
    path={routesBuilder.oss.performance.dataStudioLinks.new}
    component={NewPage}
    userPermissionRequired
  />,
  <LayoutRoute
    key='data-studio-preview'
    product={Product.OSS}
    path={routesBuilder.oss.performance.dataStudioLinks.preview()}
    component={DataStudioPage}
    userPermissionRequired
  />
]
