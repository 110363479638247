import { useEffect, useState } from 'react'

export const useScrollUp = (): (() => void) => {
  const [forceScrollUp, setForceScrollUp] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [forceScrollUp])

  return () => {
    setForceScrollUp((forceScrollUp) => !forceScrollUp)
  }
}
