import { useTranslation } from 'react-i18next'

import { ReviveDomainConfigurationRow } from '../ReviveDomainConfigurationRow'
import { TargetingCellProps } from './types'

export const TargetingCell = ({ row }: TargetingCellProps): JSX.Element => {
  const { t } = useTranslation('revive')
  const { matchType, targetingEnabled }: ReviveDomainConfigurationRow = row.original

  return targetingEnabled
    ? t(`configuration.columns.targeting.type.${matchType}.label`)
    : t('configuration.columns.targeting.type.notAvailable')
}
