import React from 'react'

import { Link } from 'react-router-dom'

import { getProductSettings } from 'utilities/products/settings'
import { getProductName } from 'utilities/products/utils'
import { Product } from 'webapp/constants/Product'

type Props = {
  closeMenu: () => void
  product: Product
}

export const ActiveItem: React.FC<Props> = ({ closeMenu, product }) => {
  const { logoIcon, homePageUrl } = getProductSettings(product)
  const name = getProductName(product)

  const ProductRow = (): JSX.Element => (
    <div className='product-menu__item '>
      <div className='product-menu__logo'>
        <img src={logoIcon} alt={name} />
      </div>

      <div className='product-menu__item-right'>{name}</div>
    </div>
  )

  return homePageUrl ? (
    <Link className='product-menu__item--active' to={homePageUrl} onClick={closeMenu}>
      <ProductRow />
    </Link>
  ) : (
    <ProductRow />
  )
}
