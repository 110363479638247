import React, { useCallback } from 'react'
import { useField, FieldConfig } from 'formik'

import { SizeInput } from 'components/Form'

interface Props {
  id: string
  disabled: boolean
  errors?: Array<string>
  label: string
  tooltipContent: React.ReactNode
  single?: boolean
  name: string
  field: FieldConfig
}

export const Sizes: React.FC<Props> = ({ id, disabled, errors, label, tooltipContent, single, ...other }) => {
  const [field, , helpers] = useField(other.field.name)
  const onChangeHandler = useCallback(
    (values: string[]) => {
      helpers.setValue(values)
    },
    [helpers]
  )

  return (
    <SizeInput
      id={id}
      disabled={disabled}
      errors={errors}
      label={label}
      tooltipContent={tooltipContent as string}
      single={single}
      onChangeHandler={onChangeHandler}
      name={other.field.name}
      values={field.value}
    />
  )
}
