import React, { useContext, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { LoadingContainer } from 'components/LoadingContainer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { Role } from 'webapp/constants/Role'
import { useQueryUsersByRole } from 'utilities/roles/queries'
import { UserContext } from 'webapp/context/UserContext'
import { WorkspaceForm } from 'components/oss/Workspaces/Form/WorkspaceForm'
import { WorkspaceSerializer } from 'serializers/WorkspaceSerializer'

import CurrentWorkspaceQuery from 'gql/queries/workspaces/CurrentWorkspace.gql'
import UpdateWorkspace from 'gql/mutations/workspaces/UpdateWorkspace.gql'
import WorkspaceQuery from 'gql/queries/workspaces/Workspace.gql'

export const EditWorkspaceContainer: React.FC = () => {
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [workspace, setWorkspace] = useState<Workspace>()
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { isSuperuser, reloadUser } = useContext(UserContext)

  const { t } = useTranslation('workspaces')

  const { workspaceId } = useParams<{ workspaceId: string }>()

  const csManagers = useQueryUsersByRole(Role.CS, !isSuperuser)

  const [workspaceQuery] = useLazyQuery(WorkspaceQuery, {
    fetchPolicy: 'network-only',
    variables: { workspaceId },
    onCompleted: ({ workspace }) => {
      setWorkspace(workspace)
    }
  })

  const [currentWorkspaceQuery] = useLazyQuery(CurrentWorkspaceQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ workspace }) => setWorkspace(workspace)
  })

  // TODO: disabled for tests => non endless loop
  useEffect(() => {
    isSuperuser ? workspaceQuery({}) : currentWorkspaceQuery({})
  }, [currentWorkspaceQuery, isSuperuser, workspaceQuery])

  const [updateWorkspace, { loading: mutationLoading }] = useMutation(UpdateWorkspace, {
    onCompleted: ({ updateWorkspace: { workspace, errors } }) => {
      if (workspace) {
        setWorkspace(workspace)
        createNotifier(t('mainForm.successMessage'), NotificationType.SUCCESS)
        reloadUser()
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const updateWorkspaceHandler = (workspace: WorkspaceFormData) => {
    const serializer = new WorkspaceSerializer(workspace, isSuperuser)
    updateWorkspace(nestGqlInput(serializer.updateParams()))
  }

  return (
    <Box className='edit-workspace-container' isExpandable={true}>
      <BoxHeader title={t('mainForm.title')} subtitle={t('mainForm.subtitle')} />

      <BoxBody>
        <LoadingContainer loading={!workspace || (isSuperuser && !csManagers)}>
          {workspace && (
            <>
              <WorkspaceForm
                csManagers={csManagers}
                errors={errors}
                isSubmitting={mutationLoading}
                onSubmit={updateWorkspaceHandler}
                workspace={workspace}
              />
            </>
          )}
        </LoadingContainer>
      </BoxBody>
    </Box>
  )
}
