import { useTranslation } from 'react-i18next'

import { ProductStatus } from 'constants/ProductStatus'

export const useProductStatusOptions = (TrialOption: JSX.Element): SelectOption[] => {
  const { t } = useTranslation('products')

  return Object.keys(ProductStatus).map((value: string) => {
    const status = ProductStatus[value as keyof typeof ProductStatus]
    const label = status === ProductStatus.Trial ? TrialOption : t(`status.${status}`)

    return {
      label,
      value: status
    }
  })
}
