import React from 'react'
import classNames from 'classnames'

import { Image } from 'components/Image'
import SpinnerImg from 'images/spinner.svg'

interface Props {
  className?: string
}

export const Spinner: React.FC<Props> = ({ className }) => {
  return <Image src={SpinnerImg} alt='Spinner' className={classNames('spinner', className)} />
}
