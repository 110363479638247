import React from 'react'
import { Column, Row } from 'react-table'
import { useHistory } from 'react-router-dom'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { columnsConfig } from './columnsConfig'
import { routesBuilder } from 'utilities/routesBuilder'
import { QueryTable } from 'containers/QueryTable/QueryTable'

import WorkspaceListQuery from 'gql/queries/workspaces/WorkspaceList.gql'

const SEARCHABLE_FIELDS = ['name', 'owner.fullName', 'domains.name']

export const WorkspacesTable: React.FC = () => {
  const history = useHistory()
  const openEditingPage = (row: Row<Record<string, unknown>>) => {
    history.push(routesBuilder.oss.workspaces.edit((row.original as unknown as Workspace).id))
  }

  return (
    <QueryTable<Workspace>
      collectionName={CollectionNames.Workspaces}
      dataQuery={WorkspaceListQuery}
      columnsConfig={columnsConfig as Column[]}
      searchableFields={SEARCHABLE_FIELDS}
      onRowClick={openEditingPage}
    />
  )
}
