import { useCallback, useReducer } from 'react'

import { ContextProps, HighlightSearches } from './HighlightSearchContext'

export enum ActionType {
  Next = 'next',
  Previous = 'previous',
  Set = 'set',
  Unset = 'unset'
}

export interface Action {
  type: ActionType
  key: string
  current?: number
  query?: string
  total?: number
}

const initialState = {}

export function reducer(state: HighlightSearches, action: Action): HighlightSearches {
  const search = state[action.key]

  let current = search?.current ?? 0
  const total = search?.total ?? 0
  const query = search?.query ?? ''

  switch (action.type) {
    case ActionType.Next:
      current = current === total - 1 ? 0 : current + 1
      return {
        ...state,
        [action.key]: { current, total, query }
      }
    case ActionType.Previous:
      current = current === 0 ? total - 1 : current - 1
      return {
        ...state,
        [action.key]: { current, total, query }
      }
    case ActionType.Set:
      return {
        ...state,
        [action.key]: {
          current: action.current ?? 0,
          total: action.total ?? 0,
          query: action.query ?? ''
        }
      }
    case ActionType.Unset: {
      const { [action.key]: removedKey, ...rest } = state
      return rest
    }
    default:
      throw new Error()
  }
}

export const useHighlightSearch = () => {
  const [highlightSearches, dispatch] = useReducer(reducer, initialState)

  const goToNext = useCallback((key: string) => dispatch({ type: ActionType.Next, key }), [])

  const goToPrevious = useCallback((key: string) => dispatch({ type: ActionType.Previous, key }), [])

  const setSearch: ContextProps['setSearch'] = useCallback(
    ({ current, key, total, query }) => dispatch({ type: ActionType.Set, current, key, total, query }),
    []
  )

  const unsetSearch = useCallback((key: string) => dispatch({ type: ActionType.Unset, key }), [])

  return {
    goToNext,
    goToPrevious,
    highlightSearches,
    setSearch,
    unsetSearch
  }
}
