import moment from 'moment'

import { cutTime } from 'utilities/formatDate'
import { TrialDuration } from './types'

export const trialDurationDefault = ({
  trialStartsAt,
  trialEndsAt
}: Pick<ProductConnection, 'trialStartsAt' | 'trialEndsAt'>): TrialDuration => ({
  from: cutTime(trialStartsAt ?? new Date()),
  to: cutTime(trialEndsAt ?? moment().add(30, 'days').toDate())
})
