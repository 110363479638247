import React from 'react'
import { Column } from 'react-table'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { columnsConfig } from './columnsConfig'
import { Table } from 'components/Table'

interface Props {
  data: ConnectedNetwork[]
}

export const ConnectedNetworksTable: React.FC<Props> = ({ data }) => {
  return (
    <Table<ConnectedNetwork>
      data={data}
      columns={columnsConfig as Column[]}
      disablePagination={true}
      collectionName={CollectionNames.ConnectedNetworks}
    />
  )
}
