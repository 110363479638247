import React from 'react'
import { Column } from 'react-table'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { columnsConfig } from './columnsConfig'
import { ProductManagementRowData } from 'components/oss/Products/ProductManagementTable/ProductManagementRowData'
import { QueryTable } from 'containers/QueryTable/QueryTable'

import WorkspaceProductsQuery from 'gql/queries/workspaces/WorkspaceProducts.gql'

export const ProductManagementTable: React.FC = () => {
  const mapper = (item: Workspace) => new ProductManagementRowData(item)

  return (
    <QueryTable<ProductManagementRowData>
      className='product-management-table'
      collectionName={CollectionNames.Workspaces}
      columnsConfig={columnsConfig as Column[]}
      dataQuery={WorkspaceProductsQuery}
      mapper={mapper}
      pageSize={25}
    />
  )
}
