import React from 'react'

import { Select } from 'components/Form'
import { AdvancedFilterOperators, useOperators } from './useAdvancedFilterOperators'
import { FilterType } from 'webapp/constants/FilterType'

interface Props {
  filter: AdvancedFilter
  filterConfig?: AdvancedFilterConfiguration
  updateFilter: (id: string, newFilter: Partial<AdvancedFilter>) => void
}

export const AdvancedFilterOperator: React.FC<Props> = ({ filter, filterConfig, updateFilter }) => {
  const operators = useOperators()
  const defaultOperators = (
    filterConfig?.type === FilterType.Input ? ['contains', 'not_contains'] : ['is', 'is_not']
  ) as AdvancedFilterOperators
  const options =
    (filterConfig?.operators ?? defaultOperators).map((value) => ({ value, label: operators[value] })) ?? []

  const onChangeHandler = (selected: SelectOption) =>
    updateFilter(filter.id!, { operator: (selected as SelectOption<string>)?.value })

  return (
    <div>
      <Select
        className='advanced-filter--operator'
        name='filterOperator'
        onChangeHandler={onChangeHandler}
        options={options}
        selectedItem={options.filter(({ value }) => value === filter.operator)}
      />
    </div>
  )
}
