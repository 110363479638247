import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Bin } from 'icons/Bin'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { CollapseButton } from 'components/CollapseButton'
import { converter } from './ParamEditorConverter'
import { Errors } from 'utilities/errorUtils'
import { parameterize } from 'utilities/parameterize'
import { ParamInput } from './ParamInput'

type SaveHandlerType = React.MouseEvent<HTMLButtonElement, MouseEvent>

interface Props {
  element: string
  errors: Errors
  isSaved: boolean
  onDeleteHandler: (event: SaveHandlerType) => void
  onSubmitHandler: (params: Params) => void
  paramsNames?: Array<string>
  params?: Params
  requiredParams?: Array<string>
  translation?: string
}

export const ParamEditor: React.FC<Props> = ({
  element,
  errors,
  isSaved,
  requiredParams = [],
  paramsNames = [],
  params,
  onDeleteHandler,
  onSubmitHandler,
  translation
}) => {
  const { t } = useTranslation()
  const hasRequiredParams = requiredParams.length > 0
  const [collapsed, setCollapsed] = useState(hasRequiredParams)
  const onCollapsedChange = () => setCollapsed(!collapsed)

  const [parameters, setParameters] = useState(params || {})
  const onParamsChange = (name: string, value: string) => {
    setParameters((params: Params): Params => Object.assign({}, params, { [name]: value }))
  }

  const optionalParams = paramsNames.filter((name: string): boolean => !requiredParams.includes(name))
  const sortedParamsNames = requiredParams.concat(optionalParams)

  const onSaveHandler = (event: SaveHandlerType) => {
    event.preventDefault()
    onSubmitHandler(converter(parameters))
  }

  const isHidden = (name: string) => {
    if (hasRequiredParams) {
      return !requiredParams.includes(name) && collapsed
    }
    return false
  }

  const showCollapsedButton = hasRequiredParams && paramsNames.length > requiredParams.length

  return (
    <>
      <div className='param-form__inputs'>
        {sortedParamsNames.map((name) => (
          <ParamInput
            key={parameterize(`${element} ${name}`)}
            id={parameterize(`${element} ${name}`)}
            disabled={isSaved}
            name={name}
            element={element}
            hidden={isHidden(name)}
            errors={errors.errorsFor(name).concat(errors.nestedErrorsFor(name))}
            onChangeHandler={onParamsChange}
            initialValue={params && params[name]}
            translation={translation}
            containerClassName='param-form__input-container'
          />
        ))}
      </div>

      {!isSaved && (
        <div className='param-form__buttons'>
          {showCollapsedButton && <CollapseButton collapsed={collapsed} onClickHandler={onCollapsedChange} />}

          <Button onClickHandler={onSaveHandler} type={ButtonType.Primary} theme={ButtonTheme.BlueDark}>
            {t('buttons.confirm')}
          </Button>

          <Button
            type={ButtonType.SingleAction}
            theme={ButtonTheme.BlueDark}
            onClickHandler={onDeleteHandler}
            className='param-form__button-delete'
          >
            <Bin />
          </Button>
        </div>
      )}
    </>
  )
}
