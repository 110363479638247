import React, { useContext } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { AbilityAction, AbilitySubject } from 'utilities/security'
import { AbilityContext } from 'webapp/context/AbilityContext'
import { ButtonType, ButtonTheme } from 'components/Button'
import { CollectionField } from 'components/FormikComponents'
import { Errors } from 'utilities/errorUtils'
import {
  getEmptyDeviceTemplate,
  WorkspaceDevicesFactory
} from 'components/quickwrap/Setup/GeneralSettings/WorkspaceDeviceForm/formSchema'
import { LoadingButton } from 'components/Button/LoadingButton'
import { StatusForm } from 'components/Form'
import { WorkspaceDeviceRow } from './WorkspaceDeviceRow'

interface Props {
  errors: Errors
  isSubmitting: boolean
  workspaceDevices: WorkspaceDevice[]
  onSubmitHandler: (values: WorkspaceDeviceFormData) => void
  onRemoveHandler: (field: number) => void
}

export const WorkspaceDeviceForm: React.FC<Props> = ({
  errors,
  isSubmitting,
  onRemoveHandler,
  onSubmitHandler,
  workspaceDevices
}) => {
  const { t } = useTranslation()

  const ability = useContext(AbilityContext)
  const canEdit = ability.can(AbilityAction.EDIT, AbilitySubject.GENERAL_SETTINGS)

  return (
    <div className='size-mapping__form'>
      <Formik
        initialValues={WorkspaceDevicesFactory(workspaceDevices)}
        onSubmit={onSubmitHandler}
        enableReinitialize={true}
      >
        {({ dirty }) => (
          <StatusForm name='qw-size-mapping' dirty={dirty}>
            <CollectionField
              collectionName='sizeMapping'
              disabled={!canEdit}
              emptyElementTemplate={getEmptyDeviceTemplate()}
              name='sizeMapping'
              onRemoveHandler={onRemoveHandler}
            >
              {({ index }: { index: number }) => (
                <WorkspaceDeviceRow disabled={!canEdit} index={index} errors={errors} />
              )}
            </CollectionField>

            {canEdit && (
              <div className='own-bidders-form__save-btn'>
                <LoadingButton
                  buttonType='submit'
                  disabled={!dirty}
                  loading={isSubmitting}
                  theme={ButtonTheme.Orange}
                  type={ButtonType.Primary}
                >
                  {t('buttons.save')}
                </LoadingButton>
              </div>
            )}
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}
