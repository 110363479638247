import { Product } from 'webapp/constants/Product'
import { RouteProductPrefix } from 'webapp/constants/RouteProductPrefix'

export const priceGeniusRoutes = {
  price_genius: {
    configuration: {
      root: `/${RouteProductPrefix[Product.PriceGenius]}/configuration`
    },

    performance: {
      dataStudio: `/${RouteProductPrefix[Product.PriceGenius]}/performance/data-studio`
    }
  }
}
