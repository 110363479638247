import { useEffect, useState } from 'react'
import { filterStore } from 'utilities/filters/filterStore'
import { UseSubscribeFilters } from './types'

export const useSubscribeFilters: UseSubscribeFilters = (collection) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false)

  useEffect(() => {
    const callback = () => void setFiltersChanged(true)
    filterStore.subscribe(collection, callback)

    return () => {
      filterStore.unsubscribe(callback)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filtersChanged) {
      setFiltersChanged(false)
    }
  }, [filtersChanged])

  return { filtersChanged }
}
