import React, { useContext, useState } from 'react'

import { Grid } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { FormSection } from 'components/shared/Inventory/AdUnits/Form/Sections'
import { NotificationType } from 'components/ToastNotifier'
import {
  FormikInputContainer as FormikInput,
  FormikSelectContainer as FormikSelect,
  FormikToggleContainer as FormikToggle
} from 'containers/FormikContainers'
import { PageSlice } from 'pages/quickwrap/Inventory/Pages/EditPage'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { EditPageFormProps, UpdatePageData, UpdatePageVars } from './types'

import { UPDATE_PAGE } from 'gql/mutations/pages/UpdatePage'

const EditPageForm = ({ page }: EditPageFormProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const [updatePage, { loading }] = useMutation<UpdatePageData, { input: UpdatePageVars }>(UPDATE_PAGE, {
    onCompleted: ({ updatePage: { errors } }) => {
      setErrors(new ErrorUtils(errors))

      if (errors.length) {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      } else if (page) {
        createNotifier(t('pages.edit.successMessage', { name: page.ossPage.pageName }), NotificationType.SUCCESS)
      }
    }
  })

  const prepareInput = (values: PageSlice): { variables: { input: UpdatePageVars } } => {
    const { id: pageId, prebidTimeout, prebidTimeoutEnabled } = values

    return nestGqlInput({
      pageId,
      prebidTimeout: Number(prebidTimeout),
      prebidTimeoutEnabled
    })
  }

  return (
    <div className='page-form'>
      <Formik initialValues={page} onSubmit={(values) => void updatePage(prepareInput(values))}>
        {({ values, dirty }) => (
          <StatusForm name='qw-page-edit' dirty={dirty}>
            <FormSection>
              <Grid item lg={4} xs={6}>
                <Field
                  component={FormikInput}
                  disabled
                  id='qw-page-name'
                  label={t('pages.form.pageName.label')}
                  name='ossPage.pageName'
                  type='text'
                />

                <Field
                  component={FormikInput}
                  disabled
                  id='qw-page-path'
                  label={t('pages.form.pagePath.label')}
                  name='ossPage.path'
                  type='text'
                />

                {page.domainVersion && (
                  <Field
                    component={FormikSelect}
                    disabled
                    id='qw-page-domain-version-id'
                    label={t('pages.form.domainId.label')}
                    name='domainVersion.id'
                    selectedItem={{ value: page.domainVersion.id, label: page.domainVersion.name }}
                  />
                )}

                <Field
                  component={FormikToggle}
                  errors={errors.errorsFor('prebidTimeoutEnabled')}
                  id='timeout-enabled'
                  label={t('pages.form.prebidTimeoutEnabled.label')}
                  labelStyle='bold'
                  name='prebidTimeoutEnabled'
                  tooltipContent={t('pages.form.prebidTimeoutEnabled.tooltip')}
                />

                <Field
                  component={FormikInput}
                  disabled={!values.prebidTimeoutEnabled}
                  errors={errors.errorsFor('prebidTimeout')}
                  id='qw-page-prebid-timeout'
                  label={t('pages.form.prebidTimeout.label')}
                  name='prebidTimeout'
                  tooltipContent={t('pages.form.prebidTimeout.tooltip')}
                  type='number'
                />
              </Grid>
            </FormSection>

            <div className='form-section--submit'>
              <Button
                disabled={!dirty || loading}
                type={ButtonType.Primary}
                theme={ButtonTheme.Orange}
                buttonType='submit'
              >
                {loading ? t('common:buttons.saving') : t('common:buttons.save')}
              </Button>
            </div>
          </StatusForm>
        )}
      </Formik>
    </div>
  )
}

export default EditPageForm
