import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Box, BoxBody, BoxHeader } from 'components/Box'
import { ToggleSwitch } from 'components/Form'
import { DomainConsentManagementForm } from 'components/oss/Inventory/Domains/DomainConsentManagementForm'
import { OSSDomainConsentManagementFactory } from 'components/oss/Inventory/Domains/DomainConsentManagementForm/formSchema'
import { NotificationType } from 'components/ToastNotifier'
import { OSSConsentManagementSerializer } from 'serializers/ConsentManagementSerializer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'

import UpsertOssConsentManagement from 'gql/mutations/consentManagement/UpsertOssConsentManagement.gql'
import UpdateDomainForCM from 'gql/mutations/domains/UpdateDomainForConsentManagement.gql'

interface Props {
  domain: Domain
}

export const DomainConsentManagementContainer: React.FC<Props> = ({ domain }) => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('inventory')
  const {
    consentManagementEnabled,
    toggleConsentManagementEnabled,
    loading: isConsentLoading
  } = useConsentManagementToggle(domain)

  const [formData, setFormData] = useState<OSSConsentManagementFormData>(() =>
    OSSDomainConsentManagementFactory(domain, domain.ossConsentManagement)
  )
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const [upsertConsentManagement, { loading: isFormLoading }] = useMutation(UpsertOssConsentManagement, {
    onCompleted: ({ upsertOssConsentManagement: { ossConsentManagement, errors } }) => {
      if (ossConsentManagement) {
        createNotifier(t('domains.consentManagement.successMessage'), NotificationType.SUCCESS)
        setFormData(OSSDomainConsentManagementFactory(domain, ossConsentManagement))
        formStatus.reset('consentManagementForm')
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const onSubmitHandler = (newFormData: OSSConsentManagementFormData) => {
    upsertConsentManagement(nestGqlInput(OSSConsentManagementSerializer.upsertParams(newFormData)))
  }

  return (
    <Box isExpandable={consentManagementEnabled}>
      <BoxHeader title={t('domains.consentManagement.title')} subtitle={t('domains.consentManagement.subtitle')}>
        <ToggleSwitch
          checked={consentManagementEnabled}
          className='consent-management-toggle__switch'
          disabledSeamlessly={isConsentLoading}
          tooltipContent={t('domains.consentManagement.tooltip')}
          id={'consent-management-toggle'}
          name={'consentManagementEnabled'}
          onChange={toggleConsentManagementEnabled}
        />
      </BoxHeader>

      <BoxBody>
        <Collapse in={consentManagementEnabled}>
          {formData && (
            <DomainConsentManagementForm
              consentManagementEnabled={consentManagementEnabled}
              errors={errors}
              formData={formData}
              isSubmitting={isFormLoading}
              onSubmit={onSubmitHandler}
            />
          )}
        </Collapse>
      </BoxBody>
    </Box>
  )
}

const useConsentManagementToggle = (domain: Domain) => {
  const { t } = useTranslation('inventory')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const [consentManagementEnabled, setConsentManagementEnabled] = useState(domain.ossConsentManagementEnabled)
  const [updateConsentManagementEnabled, { loading }] = useMutation(UpdateDomainForCM, {
    onCompleted: ({ updateDomain: { domain, errors } }) => {
      if (errors.length === 0) {
        if (domain.ossConsentManagementEnabled) {
          createNotifier(t('domains.consentManagement.enabledMessage'), NotificationType.SUCCESS)
        } else {
          createNotifier(t('domains.consentManagement.disabledMessage'), NotificationType.SUCCESS)
        }
      }
    },
    onError: () => {
      createNotifier(t('common:errors.common'), NotificationType.ERROR)
    }
  })

  const toggleConsentManagementEnabled = () => {
    setConsentManagementEnabled((ossConsentManagementEnabled: boolean) => {
      updateConsentManagementEnabled(
        nestGqlInput({
          domainId: domain.id,
          ossConsentManagementEnabled: !ossConsentManagementEnabled
        })
      )
      return !consentManagementEnabled
    })
  }

  return { consentManagementEnabled, toggleConsentManagementEnabled, loading }
}
