import React, { FC, useContext, useState } from 'react'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { ProductActivationModal } from 'components/oss/Products/ProductActivationModal'
import { useTranslation } from 'react-i18next'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Product } from 'webapp/constants/Product'
import { ProductPlan } from 'webapp/constants/ProductPlan'
import { AbilityContext } from 'webapp/context/AbilityContext'

import { ItemProps } from './types'

export const TrialActivation: FC<ItemProps> = ({ productConnection }) => {
  const ability = useContext(AbilityContext)
  const { t } = useTranslation('products')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const buttonLabel = productConnection.product === Product.FillRateBooster ? t('buttons.buy') : t('buttons.try')

  const onClickHandler = () => {
    setIsModalVisible(true)
  }

  return (
    <>
      {ability.can(AbilityAction.EDIT, AbilitySubject.PRODUCT) ? (
        <>
          <Button theme={ButtonTheme.Blue} type={ButtonType.Primary} onClickHandler={onClickHandler}>
            {buttonLabel}
          </Button>

          <ProductActivationModal
            plan={ProductPlan.Trial}
            productConnection={productConnection}
            setVisible={setIsModalVisible}
            visible={isModalVisible}
          />
        </>
      ) : (
        t('list.items.inactive')
      )}
    </>
  )
}
