import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import IconWarning from 'images/warning-icon.svg'
import { Image } from 'components/Image'

export const LoadingStatus: React.FC = () => {
  const { t } = useTranslation('inventory')

  return (
    <div>
      <header className='bulk-upload-status__header'>
        <Image className='bulk-upload-status__header--icon' src={IconWarning} alt='status_message_icon' />
        <p className='bulk-upload-status__header--message'>{t('domains.edit.adUnits.bulkUploadStatus.inProgress')}</p>
      </header>

      <div className='loading__container'>
        <CircularProgress disableShrink />
      </div>
    </div>
  )
}
