import { filterStore } from 'utilities/filters/filterStore'

export const pageIndex = (collectionName: string, pageIndex = 0): number => {
  const filters = filterStore.getFilters(collectionName)

  if (filters.page && typeof filters.page === 'string') {
    return parseInt(filters.page) - 1
  }

  return pageIndex
}
