import { useTable as useReactTable, Column, useSortBy, usePagination, useExpanded } from 'react-table'

import { pageIndex as getIndex } from './pageIndex'

interface Props<T> {
  collectionName: string
  columns: Column[]
  data: Array<T>
  disablePagination?: boolean
  hiddenColumns?: Array<string>
  pageIndex?: number
  paginateExpandedRows?: boolean
  queryPageSize?: number
  queryPagination?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTable<T>({
  collectionName,
  columns,
  data,
  disablePagination,
  hiddenColumns = [],
  pageIndex,
  paginateExpandedRows = false,
  queryPageSize,
  queryPagination
}: Props<T>) {
  const queryPageIndex = getIndex(collectionName, pageIndex)
  const isPaginationDisabled = disablePagination || queryPagination
  const initialState = {
    hiddenColumns,
    ...(isPaginationDisabled ? {} : { pageIndex: queryPageIndex, pageSize: 10 })
  }

  const {
    footerGroups,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
    rows,
    setHiddenColumns,
    state: { pageIndex: currentPageIndex },
    toggleAllRowsExpanded
  } = useReactTable(
    {
      columns,
      data: data || [],
      initialState,
      paginateExpandedRows
    },
    useSortBy,
    useExpanded,
    ...(isPaginationDisabled ? [] : [usePagination])
  )

  const getRows = isPaginationDisabled ? rows : page
  const getPageIndex = queryPagination ? queryPageIndex : currentPageIndex
  const getGotoPage = () => ({ ...(!queryPagination && { gotoPage }) })
  const getPageSize = () => {
    if (disablePagination) return 0
    return typeof queryPageSize !== 'undefined' ? queryPageSize : (pageOptions || []).length
  }
  const isPaginationEnabled = !disablePagination && getPageSize() > 1

  return {
    footerGroups,
    getGotoPage,
    getPageIndex,
    getPageSize,
    getRows,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    isPaginationEnabled,
    prepareRow,
    setHiddenColumns,
    toggleAllRowsExpanded
  }
}
