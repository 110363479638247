import { useContext } from 'react'

import { Ability } from '@casl/ability'

import { SidebarItemType } from 'components/Sidebar/types'
import { prebidAnalyticsTabs } from 'components/quickwrap/Performance/PrebidAnalytics/prebidAnalyticsTabs'
import { getProductSettings } from 'utilities/products/settings'
import { routesBuilder } from 'utilities/routesBuilder'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { Product } from 'webapp/constants/Product'
import { AbilityContext } from 'webapp/context/AbilityContext'
import i18n from 'webapp/i18n'

import abTestingImg from 'images/menu_ab_testing.svg'
import downloadTagImg from 'images/menu_download_tags.svg'
import financeImg from 'images/menu_finance.svg'
import historyImg from 'images/menu_history.svg'
import inventoryImg from 'images/menu_inventory.svg'
import knowledgeBaseImg from 'images/menu_knowledge_base.svg'
import productsImg from 'images/menu_products.svg'
import protectionImg from 'images/menu_protection.svg'
import performanceImg from 'images/menu_reporting.svg'
import setupImg from 'images/menu_setup.svg'
import portalImg from 'images/portal_icon.svg'

export const useSidebarConfiguration = (user: User, product = Product.Quickwrap): SidebarItemType[] => {
  const ability = useContext(AbilityContext)

  switch (product) {
    case Product.FillRateBooster:
      return fillRateBoosterConfiguration(ability, product)
    case Product.OSS:
      return ossConfiguration(ability)
    case Product.PriceGenius:
      return priceGeniusConfiguration(ability)
    case Product.Quickwrap:
      return quickwrapConfiguration(ability, user)
    case Product.Revive:
      return reviveConfiguration(ability)
    case Product.ViewabilityBooster:
      return viewabilityBoosterConfiguration(ability)
  }
}

const fillRateBoosterConfiguration = (ability: Ability, product: Product): SidebarItemType[] => [
  {
    title: i18n.t('sidebar:performance.label'),
    icon: performanceImg,
    type: 'mainMenuItem',
    visible: ability.can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_PATH),
    subItems: [
      {
        title: i18n.t('sidebar:performance.dataStudio'),
        href: routesBuilder.fill_rate_booster.performance.dataStudio,
        visible: true
      }
    ]
  },
  knowledgeBaseItem(product)
]

const ossConfiguration = (ability: Ability): SidebarItemType[] => [
  {
    href: routesBuilder.oss.products.management,
    title: i18n.t('sidebar:products'),
    icon: productsImg,
    visible: ability.can(AbilityAction.ACCESS, AbilitySubject.PRODUCT_MANAGEMENT),
    type: 'mainMenuItem'
  },
  {
    href: routesBuilder.oss.products.root,
    title: i18n.t('sidebar:products'),
    icon: productsImg,
    key: 'product-management',
    visible: ability.can(AbilityAction.ACCESS, AbilitySubject.PRODUCT),
    type: 'mainMenuItem'
  },
  {
    title: i18n.t('sidebar:inventory.label'),
    icon: inventoryImg,
    visible: true,
    type: 'mainMenuItem',
    subItems: [
      {
        href: routesBuilder.oss.inventory.domains.root,
        title: i18n.t('sidebar:inventory:domains'),
        visible: true
      },
      {
        href: routesBuilder.oss.inventory.pages.root,
        title: i18n.t('sidebar:inventory:pages'),
        visible: true
      },
      {
        href: routesBuilder.oss.inventory.adUnit.root,
        title: i18n.t('sidebar:inventory:adUnits'),
        visible: true
      },
      {
        href: routesBuilder.oss.inventory.devices.root,
        title: i18n.t('sidebar:inventory:devices'),
        visible: ability.can(AbilityAction.ACCESS, AbilitySubject.DEVICES)
      }
    ]
  },
  {
    title: i18n.t('sidebar:performance.label'),
    icon: performanceImg,
    type: 'mainMenuItem',
    visible: ability.can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_LINKS_PATH),
    subItems: [
      {
        title: i18n.t('sidebar:performance.dataStudio'),
        href: routesBuilder.oss.performance.dataStudioLinks.root,
        visible: true
      }
    ]
  },
  {
    title: i18n.t('sidebar:finance.label'),
    icon: financeImg,
    visible: ability.can(AbilityAction.ACCESS, AbilitySubject.FINANCE_DATA_STUDIO_LINKS_PATH),
    type: 'mainMenuItem',
    subItems: [
      {
        title: i18n.t('sidebar:finance.dataStudio'),
        href: routesBuilder.oss.finance.dataStudioLinks.root,
        visible: true
      }
    ]
  },
  {
    href: routesBuilder.oss.downloadTags.root,
    title: i18n.t('sidebar:downloadTags'),
    icon: downloadTagImg,
    visible: true,
    type: 'mainMenuItem'
  },
  {
    href: routesBuilder.oss.history.root,
    title: i18n.t('sidebar:history'),
    icon: historyImg,
    visible: true,
    type: 'mainMenuItem'
  },
  portalItem(routesBuilder.oss.portal.root),
  knowledgeBaseItem(Product.OSS)
]

const priceGeniusConfiguration = (ability: Ability): SidebarItemType[] => [
  {
    href: routesBuilder.price_genius.configuration.root,
    icon: inventoryImg,
    title: i18n.t('sidebar:priceGenius.configuration'),
    type: 'mainMenuItem',
    visible: true
  },
  {
    icon: performanceImg,
    title: i18n.t('sidebar:priceGenius.performance.label'),
    type: 'mainMenuItem',
    visible: ability.can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_PATH),
    subItems: [
      {
        href: routesBuilder.price_genius.performance.dataStudio,
        title: i18n.t('sidebar:priceGenius.performance.dataStudio'),
        visible: true
      }
    ]
  },
  knowledgeBaseItem(Product.PriceGenius)
]

const quickwrapConfiguration = (ability: Ability, user: User): SidebarItemType[] => {
  const doYbBiddersExist = Boolean(user?.workspace?.demandBidders.length)

  return [
    {
      title: i18n.t('sidebar:setup.label'),
      icon: setupImg,
      visible: ability.can(AbilityAction.ACCESS, AbilitySubject.SETUP),
      type: 'mainMenuItem',
      subItems: [
        {
          title: i18n.t('sidebar:setup.general'),
          href: routesBuilder.quickwrap.setup.general,
          visible: true
        },
        {
          title: i18n.t('sidebar:setup.bidders'),
          href: routesBuilder.quickwrap.setup.bidders(),
          visible: true
        },
        {
          title: i18n.t('sidebar:setup.modules'),
          href: routesBuilder.quickwrap.setup.modules,
          visible: user.role !== 'admin'
        },
        {
          title: i18n.t('sidebar:setup.networks'),
          href: routesBuilder.quickwrap.setup.networks.root,
          visible: ability.can(AbilityAction.ACCESS, AbilitySubject.NETWORKS_PATH)
        }
      ]
    },
    {
      title: i18n.t('sidebar:inventory.label'),
      icon: inventoryImg,
      visible: true,
      type: 'mainMenuItem',
      subItems: [
        {
          href: routesBuilder.quickwrap.inventory.domains.root,
          title: i18n.t('sidebar:inventory:domains'),
          visible: true
        },
        {
          href: routesBuilder.quickwrap.inventory.pages.root,
          title: i18n.t('sidebar:inventory:pages'),
          visible: true
        },
        {
          href: routesBuilder.quickwrap.inventory.adUnit.root,
          title: i18n.t('sidebar:inventory:adUnits'),
          visible: true
        }
      ]
    },
    {
      title: i18n.t('sidebar:protection.label'),
      icon: protectionImg,
      visible: ability.can(AbilityAction.ACCESS, AbilitySubject.BLOCK_URL_GROUP),
      type: 'mainMenuItem',
      subItems: [
        {
          href: routesBuilder.quickwrap.blockUrlGroups.root,
          title: i18n.t('sidebar:protection:blockUrlGroups'),
          visible: ability.can(AbilityAction.ACCESS, AbilitySubject.BLOCK_URL_GROUP)
        }
      ]
    },
    {
      title: i18n.t('sidebar:performance.label'),
      icon: performanceImg,
      visible: true,
      type: 'mainMenuItem',
      subItems: [
        {
          title: i18n.t('sidebar:performance.reporting'),
          href: routesBuilder.quickwrap.performance.reporting,
          visible: true
        },
        {
          title: i18n.t('sidebar:performance.prebid.label'),
          visible: user.role !== 'admin',
          href: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.dashboard),
          subItems: [
            {
              title: i18n.t('sidebar:performance.prebid.dashboard'),
              href: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.dashboard),
              visible: true
            },
            {
              title: i18n.t('sidebar:performance.prebid.adTypeAnalysis'),
              href: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.adTypeAnalysis),
              visible: true
            },
            {
              title: i18n.t('sidebar:performance.prebid.bidderAnalysis'),
              href: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.bidderAnalysis),
              visible: true
            },
            {
              title: i18n.t('sidebar:performance.prebid.siteAnalysis'),
              href: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.siteAnalysis),
              visible: true
            },
            {
              title: i18n.t('sidebar:performance.prebid.auctionReports'),
              href: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.auctionReports),
              visible: true
            },
            {
              title: i18n.t('sidebar:performance.prebid.versionComparison'),
              href: routesBuilder.quickwrap.performance.prebid.tab(prebidAnalyticsTabs.versionComparison),
              visible: true
            }
          ]
        },
        {
          title: i18n.t('sidebar:performance.insights'),
          subtitle: i18n.t('sidebar:performance.insightsSubtitle'),
          href: 'https://insights.yieldbird.com/',
          visible: doYbBiddersExist && ability.can(AbilityAction.ACCESS, AbilitySubject.YB_DEMAND_PACKAGE),
          absolute: true
        }
      ]
    },
    {
      href: routesBuilder.quickwrap.abTesting.root,
      title: i18n.t('sidebar:abTesting'),
      icon: abTestingImg,
      visible: true,
      type: 'mainMenuItem'
    },
    portalItem(routesBuilder.quickwrap.portal.root),
    knowledgeBaseItem(Product.Quickwrap)
  ]
}

const reviveConfiguration = (ability: Ability): SidebarItemType[] => [
  {
    href: routesBuilder.revive.configuration.root,
    icon: inventoryImg,
    title: i18n.t('sidebar:revive.configuration'),
    type: 'mainMenuItem',
    visible: true
  },
  {
    icon: performanceImg,
    title: i18n.t('sidebar:revive.performance.label'),
    type: 'mainMenuItem',
    visible: ability.can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_PATH),
    subItems: [
      {
        href: routesBuilder.revive.performance.dataStudio,
        title: i18n.t('sidebar:revive.performance.dataStudio'),
        visible: true
      }
    ]
  },
  portalItem(routesBuilder.revive.portal.root),
  knowledgeBaseItem(Product.Revive)
]

const viewabilityBoosterConfiguration = (ability: Ability): SidebarItemType[] => [
  {
    title: i18n.t('sidebar:performance.label'),
    icon: performanceImg,
    type: 'mainMenuItem',
    visible: ability.can(AbilityAction.ACCESS, AbilitySubject.PERFORMANCE_DATA_STUDIO_PATH),
    subItems: [
      {
        title: i18n.t('sidebar:performance.dataStudio'),
        href: routesBuilder.viewability_booster.performance.dataStudio,
        visible: true
      }
    ]
  },
  knowledgeBaseItem(Product.ViewabilityBooster)
]

const portalItem = (href: string): SidebarItemType => ({
  href,
  title: i18n.t('sidebar:portal'),
  icon: portalImg,
  visible: true,
  className: 'portal-link',
  type: 'sideMenuItem'
})

const knowledgeBaseItem = (product: Product): SidebarItemType => ({
  href: getProductSettings(product).knowledgeBaseUrl,
  title: i18n.t('sidebar:knowledgeBase'),
  icon: knowledgeBaseImg,
  visible: true,
  className: 'knowledge-base-link',
  type: 'sideMenuItem',
  absolute: true
})
