import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

interface Props {
  collapsed: boolean
  className?: string
  onClickHandler?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const CollapseButton: React.FC<Props> = ({ collapsed, className, onClickHandler }) => {
  const { t } = useTranslation()
  const buttonClass = classNames('collapse-button', className)
  const arrowClass = classNames('collapse-button__arrow', `collapse-button__arrow--${collapsed ? 'down' : 'up'}`)
  const title = collapsed ? t('collapseButtons.more') : t('collapseButtons.less')

  return (
    <a className={buttonClass} onClick={onClickHandler}>
      <span className='collapse-button__title'>{title}</span>
      <div className={arrowClass}></div>
    </a>
  )
}
