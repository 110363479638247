import React from 'react'
import { Field } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Errors } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'

interface Props {
  index: number
  errors: Errors
}

export const VisitsPerGeoRow: React.FC<Props> = ({ index, errors }) => {
  const { t } = useTranslation('setup')

  return (
    <>
      <Grid item lg={5} xs={4}>
        <Field
          id={`recommend-bidders-geo-${index}`}
          name={`visitsPerGeo.${index}.geo`}
          type='text'
          placeholder={t('recommendBidders.form.visitsPerGeo.geo.placeholder')}
          label={index === 0 && t('recommendBidders.form.visitsPerGeo.geo.label')}
          tooltipContent={index === 0 ? t('recommendBidders.form.visitsPerGeo.geo.tooltip') : null}
          component={FormikInput}
          errors={errors.errorsFor(['visitsPerGeo', index.toString(), 'geo'])}
        />
      </Grid>

      <Grid item lg={5} xs={4}>
        <Field
          id={`recommend-bidders-traffic-${index}`}
          name={`visitsPerGeo.${index}.traffic`}
          type='number'
          placeholder={t('recommendBidders.form.visitsPerGeo.traffic.placeholder')}
          label={index === 0 && t('recommendBidders.form.visitsPerGeo.traffic.label')}
          tooltipContent={index === 0 ? t('recommendBidders.form.visitsPerGeo.traffic.tooltip') : null}
          component={FormikInput}
          errors={errors.errorsFor(['visitsPerGeo', index.toString(), 'traffic'])}
        />
      </Grid>
    </>
  )
}
