import { domainVersionName } from 'utilities/domainVersionName'

const getDomainId = (abTest?: ABTest, domain?: undefined | { id: string; name: string }): SelectOption<string> => {
  const value = domain?.id || abTest?.domain?.id
  const label = domain?.name || abTest?.domain?.name
  return { value: value ?? '', label: label ?? '' }
}

export const AbTestFactory = (
  abTest?: ABTest,
  domain?: undefined | { id: string; name: string; version?: { id: string } }
): ABTestFormData => {
  return {
    id: abTest?.id || undefined,
    active: abTest?.active,
    domainId: getDomainId(abTest, domain),
    name: abTest?.name || '',
    abTestVersionsAttributes:
      abTest?.abTestVersions.map((version) => ({
        id: version.id,
        domainVersionId: getDomainVersionOption(version.domainVersion),
        share: version.share
      })) ?? []
  }
}

export const ABEndTestFactory = (abTest: ABTest): ABEndTestFormData => {
  return {
    id: abTest.id,
    activateDomainVersionId: null
  }
}

export const getEmptyVersionTemplate = (): ABTestFormData['abTestVersionsAttributes'][0] => ({
  domainVersionId: null,
  share: 0
})

export const getDomainVersionOption = (domainVersion: DomainVersion): SelectOption<string> => {
  return {
    value: domainVersion.id,
    label: domainVersionName(domainVersion.counter, domainVersion.name)
  }
}

export const getDomainVersionsSortedForTest = (abTest: ABTest): SelectOption<string>[] => {
  const active: { [id: string]: number } = {}
  abTest.abTestVersions.forEach((version) => {
    active[version.domainVersion.id] = version.share
  })

  return (
    abTest.domain?.versions?.nodes
      .concat()
      .sort((a, b) => {
        const aOrder = Number(active[a.id] !== undefined && 1000) + (active[a.id] ?? 0) + a.counter / 1000
        const bOrder = Number(active[b.id] !== undefined && 1000) + (active[b.id] ?? 0) + b.counter / 1000
        return bOrder - aOrder
      })
      .map(getDomainVersionOption) ?? []
  )
}

export const canStartTest = (abTestVersionsAttributes: ABTestFormData['abTestVersionsAttributes']) => {
  if (abTestVersionsAttributes.length < 2) return false

  const sum = abTestVersionsAttributes.reduce((acc, abTestVersionAttribute) => {
    return acc + Number(abTestVersionAttribute.share)
  }, 0)
  return sum === 100
}
