import { gql } from '@apollo/client'

export const OSS_CONSENT_MANAGEMENT_FRAGMENT = gql`
  fragment OssConsentManagementFragment on OssConsentManagement {
    domainId
    id
    tcfTimeout
    type
    waitForTcf
  }
`
