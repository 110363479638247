import { createTheme, Fade } from '@material-ui/core'

export const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiMenu: {
      TransitionComponent: Fade,
      transitionDuration: 100
    }
  }
})
