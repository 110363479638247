import React, { useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { DataStudioForm } from 'components/oss/Finance/DataStudioLinks/Form/DataStudioForm'
import { LoadingContainer } from 'components/LoadingContainer'
import { useUpdateWorkspaceUrl } from 'components/oss/Finance/DataStudioLinks/Form/useUpdateWorkspaceUrl'

import WorkspaceListQuery from 'gql/queries/workspaces/WorkspaceList.gql'
import WorkspaceQuery from 'gql/queries/workspaces/Workspace.gql'

export const NewPage: React.FC = () => {
  const { t } = useTranslation('dataStudio')

  const [workspace, setWorkspace] = useState<Workspace>()
  const [workspaces, setWorkspaces] = useState<Workspace[]>()

  useQuery(WorkspaceListQuery, {
    fetchPolicy: 'network-only',
    variables: { filter: { by: 'data_studio_url', operator: 'is_null', values: [] } },
    onCompleted: ({ workspaces: { nodes } }) => {
      setWorkspaces(nodes)
    }
  })

  const [loadWorkspace, { loading: loadingWorkspace }] = useLazyQuery(WorkspaceQuery, {
    onCompleted: ({ workspace }) => {
      setWorkspace(workspace)
    }
  })

  const [updateWorkspace, errors, isSubmitting] = useUpdateWorkspaceUrl('new')

  return (
    <Box className='data-studio-links-edit'>
      <BoxHeader title={t('new.title')} />
      <LoadingContainer loading={!workspaces}>
        <DataStudioForm
          errors={errors}
          isSubmitting={isSubmitting}
          onSubmit={updateWorkspace}
          workspaces={workspaces || []}
          workspace={workspace}
          loadingWorkspace={loadingWorkspace}
          onWorkspaceChange={(workspaceId: string) => {
            setWorkspace(undefined)
            loadWorkspace({ variables: { workspaceId } })
          }}
        />
      </LoadingContainer>
    </Box>
  )
}
