import pick from 'lodash/pick'
import { DomainWithOssAdUnits, PageData, UpsertOssPageVars, upsertOssPageVarsDefault } from './types'

export const prepareFormDomains = (
  formType: FormType,
  domains?: DomainWithOssAdUnits[],
  page?: PageData['ossPage']
): DomainWithOssAdUnits[] => {
  if (formType === 'edit' && page) {
    return [page.domain]
  } else if (domains) {
    return domains
  } else return []
}

export const prepareInitValues = ({
  formType,
  initActiveAdUnits,
  page
}: {
  formType: FormType
  initActiveAdUnits: OssAdUnit['id'][]
  page?: PageData['ossPage']
}): UpsertOssPageVars => {
  if (formType === 'edit' && page) {
    const {
      domain: { id: domainId },
      id: ossPageId
    } = page

    return {
      ...pick(page, ['matchType', 'path']),
      domainId,
      ossAdUnitIds: initActiveAdUnits,
      ossPageId,
      pageName: page.pageName ?? ''
    }
  } else return upsertOssPageVarsDefault
}
