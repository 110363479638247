import React from 'react'
import { CellProps, Column } from 'react-table'

import { ActionsCell } from './ActionsCell'
import { DataStudioLinkRowData } from 'components/oss/Performance/DataStudioLinks/Table/DataStudioLinkRowData'
import { DateCell } from 'components/Table'
import i18n from 'webapp/i18n'
import { LinkCell } from 'components/Table/LinkCell'

export const columnsConfig: Column<DataStudioLinkRowData>[] = [
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.workspace'),
    accessor: 'workspaceName'
  },
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.product'),
    accessor: 'product',
    Cell: ({ row }) => i18n.t(`products:product.${row.original.product}`)
  },
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.link'),
    accessor: 'dataStudioUrl',
    Cell: ({ row }: CellProps<DataStudioLinkRowData>) => <LinkCell href={row.original.dataStudioUrl} />
  },
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.updatedBy'),
    accessor: 'updatedBy'
  },
  {
    Header: () => i18n.t('dataStudio:links.list.tableHeader.updatedAt'),
    accessor: 'updatedAt',
    Cell: ({ value }) => <DateCell value={value} />
  },
  {
    id: 'actions',
    Header: () => i18n.t('Actions'),
    Cell: ({ row }: CellProps<DataStudioLinkRowData>) => <ActionsCell row={row} />,
    disableSortBy: true
  }
]
