export class DomainNetworkSerializer {
  private form: DomainNetworkForm[]
  private domain: Domain
  private networks: Network[]
  private domainNetworks: DomainNetwork[]

  constructor(
    form: { domainNetworks: DomainNetworkForm[] },
    domain: Domain,
    networks: Network[],
    domainNetworks: DomainNetwork[]
  ) {
    this.form = form.domainNetworks
    this.domain = domain
    this.networks = networks
    this.domainNetworks = domainNetworks
  }

  public updateParams = (): { domainId: string; attributes: DomainNetworkAttributes[] } => ({
    domainId: this.domain.id,
    attributes: this.attributes()
  })

  private attributes(): DomainNetworkAttributes[] {
    return this.form.map((form): DomainNetworkAttributes => {
      const params: DomainNetworkAttributes = {
        networkId: this.networks.find((network) => network.name === form.network)?.id ?? '',
        sid: form.sid
      }
      const id = this.domainNetworks.find((domainNetwork) => domainNetwork.network.name === form.network)?.id

      if (id) {
        params.id = id
      }

      return params
    })
  }
}
