type Params = Record<string, number | string | boolean | undefined>

export class DomainSerializer {
  domain: { [key: string]: any }

  constructor(domain: DomainFormData) {
    this.domain = domain
  }

  createParams(): Params {
    return this.sharedParams()
  }

  updateParams(): Params {
    return {
      ...this.sharedParams(),
      domainVersionId: this.domain.versionId
    }
  }

  sharedParams(): Params {
    const mainParams = [
      'amazonSid',
      'autoSendRequestEnabled',
      'currency',
      'customCodeEnabled',
      'name',
      'ybAmazonSid',
      'ybDomainSid',
      'wrapperVersion'
    ]
    const lazyLoadParams = [
      'auctionMarginPercent',
      'customLazyLoadEnabled',
      'fetchMarginPercent',
      'lazyAuctionEnabled',
      'mobileScaling',
      'renderMarginPercent'
    ]
    const configurationParams = [
      ...(this.domain.lazyLoadEnabled ? lazyLoadParams : []),
      'auctionPerAdUnit',
      'consentManagementEnabled',
      'customPrebidModulesEnabled',
      'fillrateBoosterEnabled',
      'lazyLoadEnabled',
      'prebidManagerEnabled',
      'viewabilityBoosterEnabled',
      'withoutConsentEnabled'
    ]

    return this._prepareBody([...mainParams, ...configurationParams])
  }

  private _prepareBody(permittedParams: string[]): Params {
    const body: Params = {}

    this.filterParams(this.rawParams(), permittedParams).forEach(
      (paramName) => (body[paramName] = this.domain[paramName])
    )
    this.filterParams(this.intParams(), permittedParams).forEach(
      (paramName) => (body[paramName] = parseInt(this.domain[paramName], 10))
    )
    this.filterParams(this.floatParams(), permittedParams).forEach(
      (paramName) => (body[paramName] = parseFloat(this.domain[paramName]))
    )
    this.filterParams(this.selectParams(), permittedParams).forEach(
      (paramName) => (body[paramName] = this.getSelectValue(this.domain[paramName]))
    )

    return body
  }

  private filterParams(params: Array<keyof DomainFormData>, permittedParams: string[]) {
    return params.filter((param) => permittedParams.length === 0 || permittedParams.includes(param))
  }

  private rawParams(): Array<keyof DomainFormData> {
    return [
      'amazonSid',
      'auctionPerAdUnit',
      'currency',
      'customLazyLoadEnabled',
      'customCodeEnabled',
      'customPrebidModulesEnabled',
      'fillrateBoosterEnabled',
      'lazyAuctionEnabled',
      'lazyLoadEnabled',
      'name',
      'prebidManagerEnabled',
      'viewabilityBoosterEnabled',
      'withoutConsentEnabled',
      'ybAmazonSid',
      'ybDomainSid'
    ]
  }

  private intParams(): Array<keyof DomainFormData> {
    return ['auctionMarginPercent', 'fetchMarginPercent', 'renderMarginPercent']
  }

  private floatParams(): Array<keyof DomainFormData> {
    return ['mobileScaling']
  }

  private selectParams(): Array<keyof DomainFormData> {
    return ['currency']
  }

  private getSelectValue(select: SelectOption | string): Params[keyof Params] {
    if (typeof select === 'object') {
      return typeof select.value === 'string' ? select.value.toLowerCase() : (select.value as Params[keyof Params])
    } else {
      return select
    }
  }
}
