import React from 'react'
import { Column } from 'react-table'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { columnsConfig } from './columnsConfig'
import { QueryTable } from 'containers/QueryTable'

import { CUSTOM_CODES } from 'gql/queries/customCode/CustomCodes'

export const CustomCodesTable: React.FC = () => {
  return (
    <QueryTable<CustomCode>
      className='custom-codes-table'
      collectionName={CollectionNames.CustomCodes}
      dataQuery={CUSTOM_CODES}
      columnsConfig={columnsConfig as Column[]}
    />
  )
}
