import React from 'react'

import { EditPage } from 'pages/oss/Finance/DataStudioLinks/EditPage'
import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { ListPage } from 'pages/oss/Finance/DataStudioLinks/ListPage'
import { NewPage } from 'pages/oss/Finance/DataStudioLinks/NewPage'
import { Product } from 'webapp/constants/Product'
import { PublisherListPage } from 'pages/oss/Finance/DataStudio/PublisherListPage'
import { routesBuilder } from 'utilities/routesBuilder'

export const FinanceRoutes = [
  <LayoutRoute
    key='finance-data-studio'
    product={Product.OSS}
    exact
    path={routesBuilder.oss.finance.root}
    component={PublisherListPage}
    userPermissionRequired
  />,
  <LayoutRoute
    key='finance-data-studio-list'
    product={Product.OSS}
    exact
    path={routesBuilder.oss.finance.dataStudioLinks.root}
    component={ListPage}
    userPermissionRequired
  />,
  <LayoutRoute
    key='finance-data-studio-edit'
    product={Product.OSS}
    path={routesBuilder.oss.finance.dataStudioLinks.edit()}
    component={EditPage}
    userPermissionRequired
  />,
  <LayoutRoute
    key='finance-data-studio-new'
    product={Product.OSS}
    path={routesBuilder.oss.finance.dataStudioLinks.new}
    component={NewPage}
    userPermissionRequired
  />,
  <LayoutRoute
    key='finance-data-studio-preview'
    product={Product.OSS}
    path={routesBuilder.oss.finance.dataStudioLinks.preview()}
    component={PublisherListPage}
    userPermissionRequired
  />
]
