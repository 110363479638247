import React, { useState, useContext, useRef } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { BidderContext } from 'pages/quickwrap/Setup/BiddersPage/BiddersPage'
import { BidderSettingsForm } from 'components/sharedInterfaces/BidderSettingsForm'
import { BidderSettingsSerializer } from 'serializers/BidderSettingsSerializer'
import { Box, BoxHeader, BoxBody } from 'components/Box'
import { ConfirmationModal } from 'components/Modals'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikHelpers } from 'formik'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { YbDemandCreateSection } from 'components/Bidders/YbDemandForm/YbDemandCreateSection'
import { YbDemandEditSection } from 'components/Bidders/YbDemandForm'
import { YieldbirdDemandStatusContainer } from 'containers/quickwrap/Setup/YieldbirdDemandStatusContainer'
import { YieldbirdSmartBiddersContainer } from 'webapp/containers/quickwrap/Setup/YieldbirdSmartBiddersContainer/YieldbirdSmartBiddersContainer'
import { UserContext } from 'webapp/context/UserContext'

import BirdSrc from 'images/bird.svg'

import BiddersQuery from 'gql/queries/bidders/Bidders.gql'
import UpdateBidderSettings from 'gql/mutations/bidderSettings/UpdateBidderSettings.gql'

interface Props {
  demandBidderSettings: BidderSetting[]
  updateDemandBidderSettingsHandler: (demandBidderSettings: BidderSetting[]) => void
}

export const YieldbirdDemandContainer: React.FC<Props> = ({
  demandBidderSettings,
  updateDemandBidderSettingsHandler
}) => {
  const { t } = useTranslation('setup')
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { reloadUser } = useContext(UserContext)
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form, setForm] = useState<BidderSettingsForm>({ bidders: [] })
  const formikHelpersRef = useRef<FormikHelpers<BidderSettingsForm>>()

  const { forceConnectionsRefresh } = useContext(BidderContext)

  const [demandBidders, setDemandBidders] = useState<Bidder[]>()
  useQuery(BiddersQuery, {
    variables: { order: { by: 'name', direction: 'asc' }, demand: true },
    onCompleted: ({ bidders: { nodes } }) => setDemandBidders(nodes)
  })

  const [updateDemandBidderSettings, { loading }] = useMutation(UpdateBidderSettings, {
    onCompleted: ({ updateBidderSettings: { bidderSettings, errors } }) => {
      if (bidderSettings) {
        updateDemandBidderSettingsHandler([])
        updateDemandBidderSettingsHandler(bidderSettings)
        createNotifier(t('yieldbirdDemand.form.successMessage'), NotificationType.SUCCESS)
        setAllowSubmit(false)
        reloadUser()
        forceConnectionsRefresh()
      }
      setErrors(new ErrorUtils(errors))
    }
  })

  const saveBidderSettingsHandler = (form: BidderSettingsForm, formikHelpers: FormikHelpers<BidderSettingsForm>) => {
    setForm(form)
    form.bidders.length > 0 ? setIsModalVisible(true) : updateBidderSettingsHandler(form)
    formikHelpersRef.current = form.shouldCreateOnly ? formikHelpers : undefined
  }

  const updateBidderSettingsHandler = (form: BidderSettingsForm) => {
    const serializer = new BidderSettingsSerializer(form.bidders, demandBidderSettings, form.shouldCreateOnly)
    updateDemandBidderSettings(nestGqlInput({ attributes: serializer.params(), demand: true }))
    formikHelpersRef.current?.resetForm()
  }

  const onRemoveHandler = (field: number) => {
    errors.deleteAt(field)
    setErrors(errors)
  }

  const confirmHandler = () => {
    updateBidderSettingsHandler(form)
    setIsModalVisible(false)
  }

  return (
    <>
      {demandBidders && (
        <Box className='yb-demand-bidders yb-demand-bidders-container' isExpandable={true} defaultExpand={false}>
          <BoxHeader
            title={
              <div className='yb-demand-bidders__heading'>
                <img src={BirdSrc} alt='Yieldbird' /> {t('yieldbirdDemand.form.title')}
              </div>
            }
            subtitle={t('yieldbirdDemand.form.subtitle')}
          />

          <BoxBody>
            <h2 className='yb-demand-bidders__subheading yb-demand-bidders__subheading--with-margin'>
              {t('yieldbirdDemand.create.title')}
            </h2>

            <div className='yb-demand-bidders__sections'>
              <YieldbirdSmartBiddersContainer />

              <YbDemandCreateSection
                demandBidderSettings={demandBidderSettings}
                demandBidders={demandBidders}
                errors={errors}
                isSubmitting={loading}
                onSubmitHandler={saveBidderSettingsHandler}
              />
            </div>

            <YbDemandEditSection
              allowSubmit={allowSubmit}
              demandBidders={demandBidders}
              demandBidderSettings={BidderSettingsSerializer.parseToForm(demandBidderSettings)}
              onSubmitHandler={saveBidderSettingsHandler}
              onRemoveHandler={onRemoveHandler}
              errors={errors}
              isSubmitting={loading}
              onChangeHandler={setAllowSubmit}
            />

            <ConfirmationModal
              visible={isModalVisible}
              setVisible={setIsModalVisible}
              content={t('yieldbirdDemand.form.confirmation')}
              confirmButtonText={t('common:buttons.save')}
              cancelButtonText={t('common:buttons.cancel')}
              onConfirmHandler={confirmHandler}
            />

            <YieldbirdDemandStatusContainer demandBidderSettings={demandBidderSettings} />
          </BoxBody>
        </Box>
      )}
    </>
  )
}
