import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Formik } from 'formik'
import { Grid } from '@material-ui/core'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { Errors } from 'webapp/utils/errorUtils'
import { FormikTreeInventoryPickerContainer } from 'webapp/containers/FormikContainers/FormikTreeInventoryPickerContainer'
import { FormikDynamicListInputContainer } from 'containers/FormikContainers/FormikDynamicListInputContainer'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { StatusForm } from 'components/Form'

import DomainsSelectQuery from 'gql/queries/domains/DomainSelect.gql'
import { useQuery } from '@apollo/client'

type BlockUrlGroupFormType = {
  blockUrlGroup: BlockUrlGroupData
  errors: Errors
  isSubmitting: boolean
  onSubmit: (blockUrlGroupData: BlockUrlGroupData) => void
}

export const BlockUrlGroupsForm = ({
  blockUrlGroup,
  errors,
  isSubmitting,
  onSubmit
}: BlockUrlGroupFormType): JSX.Element => {
  const { t } = useTranslation('protection')
  const [domains, setDomains] = useState<Domain[]>([])

  useQuery(DomainsSelectQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ domains }) => {
      setDomains(domains.nodes)
    }
  })

  return (
    <div className='qw-form'>
      <Formik initialValues={blockUrlGroup} onSubmit={onSubmit} enableReinitialize={true}>
        {({ dirty }) => {
          return (
            <StatusForm name='qw-block-url-groups' dirty={dirty}>
              <Grid item md={6} lg={4} xl={3}>
                <Field
                  id='block-url-group-name'
                  name='name'
                  component={FormikInput}
                  errors={errors.errorsFor('name')}
                  label={t('blockUrlGroups.form.inputs.name.label')}
                  type='text'
                  placeholder={t('blockUrlGroups.form.inputs.name.placeholder')}
                  tooltipContent={t('blockUrlGroups.form.inputs.name.tooltip')}
                />
              </Grid>

              <Grid className='mt-4' item md={8} lg={6} xl={4}>
                <Field
                  id='block-url-group-urls'
                  name='urls'
                  component={FormikDynamicListInputContainer}
                  errors={errors.errorsFor('urls')}
                  label={t('blockUrlGroups.form.inputs.urls.label')}
                  filterPlaceholder={t('blockUrlGroups.form.inputs.urls.placeholder')}
                  title={t('blockUrlGroups.form.inputs.urls.title')}
                />
              </Grid>

              <Grid className='mt-4' item md={6} lg={4} xl={3}>
                {domains.length > 0 ? (
                  <Field
                    id='block-url-group-domains'
                    name='domains'
                    blockUrlGroup={blockUrlGroup}
                    component={FormikTreeInventoryPickerContainer}
                    domains={domains}
                    errors={errors.errorsFor('domains')}
                    placeholder={t('blockUrlGroups.form.inputs.domains.placeholder')}
                  />
                ) : (
                  <div className='qw-label'>{t('blockUrlGroups.form.inputs.domains.notConfigured')}</div>
                )}
              </Grid>

              <Grid item md={6} lg={4} xl={3}>
                <Button
                  buttonType='submit'
                  className='block-url-group-submit mt-4'
                  disabled={!dirty}
                  theme={ButtonTheme.Orange}
                  type={ButtonType.Primary}
                >
                  {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
                </Button>
              </Grid>
            </StatusForm>
          )
        }}
      </Formik>
    </div>
  )
}
