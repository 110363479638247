import React, { useMemo, ReactType } from 'react'
import classNames from 'classnames'

import { Tag } from './Tag'

interface Props {
  cleanInputHandler: () => void
  createNewTag: () => void
  disabled?: boolean
  errors?: boolean
  getInputProps: () => { onBlur: () => void }
  name: string
  onKeysDownHandler: (event: React.KeyboardEvent<Element>) => void
  value: string
  TagComponent?: ReactType
  placeholder?: string
}

export const Input: React.FC<Props> = ({
  cleanInputHandler,
  createNewTag,
  disabled,
  getInputProps,
  name,
  onKeysDownHandler,
  value,
  TagComponent,
  placeholder
}) => {
  const classString = useMemo(() => {
    return classNames('tag-input__input', {
      'tag-input__input--active': value
    })
  }, [value])

  const generateTag = (inputValue: string) => {
    if (TagComponent) {
      return <TagComponent label={inputValue} onDelete={cleanInputHandler} />
    } else {
      return <Tag label={inputValue} onDelete={cleanInputHandler} />
    }
  }

  return (
    <>
      <div className='tag-input__new-tag' onClick={() => createNewTag()}>
        {value && generateTag(value)}
      </div>

      <input
        className={classString}
        disabled={disabled}
        {...getInputProps()}
        onBlur={() => {
          getInputProps().onBlur()
          createNewTag()
        }}
        onKeyDown={onKeysDownHandler}
        name={name}
        value={value}
        placeholder={placeholder}
      />
    </>
  )
}
