import { Dispatch, SetStateAction } from 'react'

import { CommonSlice, DataSlicesByTableFor, OssAdUnitSlice, OssPageSlice, SetByNameProps } from './types'

export const unwrapCSByTableFor = (
  data: DataSlicesByTableFor,
  setByName: SetByNameProps
): {
  elements: CommonSlice[]
  activeElements: CommonSlice[]
  setElements: Dispatch<SetStateAction<CommonSlice[] | undefined>>
  setActiveElements: Dispatch<SetStateAction<CommonSlice[] | undefined>>
} => {
  const { tableFor } = data

  let elements: CommonSlice[], activeElements: CommonSlice[], setElements, setActiveElements
  if (tableFor === 'OSS_PAGE') {
    elements = data.ossAdUnits
    activeElements = data.activeOssAdUnits

    setElements = setByName['ossAdUnits']
    setActiveElements = setByName['activeOssAdUnits']
  } else {
    elements = data.ossPages
    activeElements = data.activeOssPages

    setElements = setByName['ossPages']
    setActiveElements = setByName['activeOssPages']
  }

  return { elements, activeElements, setElements, setActiveElements }
}

export const convertOssAdUnitsToCS = (ossAdUnits: OssAdUnitSlice[]): CommonSlice[] =>
  ossAdUnits.map((e: OssAdUnitSlice) => ({
    id: e.id,
    name: e.adUnitName ?? e.adUnitPath
  }))

export const convertOssPagesToCS = (ossPages: OssPageSlice[]): CommonSlice[] =>
  ossPages.map((e: OssPageSlice) => ({
    id: e.id,
    name: e.pageName ?? e.path
  }))
