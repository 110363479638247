import React from 'react'

import { LayoutRoute } from 'components/Layout/LayoutRoute'
import { PrebidAnalyticsPage } from 'pages/quickwrap/Performance/PrebidAnalyticsPage'
import { routesBuilder } from 'utilities/routesBuilder'

export const PrebidAnalyticsRoutes = [
  <LayoutRoute
    key='prebid-analytics'
    path={routesBuilder.quickwrap.performance.prebid.root}
    component={PrebidAnalyticsPage}
  />
]
