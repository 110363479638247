import React from 'react'
import classNames from 'classnames'
import { Grid, GridSpacing } from '@material-ui/core'

import { Bin } from 'icons/Bin'
import { Button, ButtonType, ButtonTheme } from 'components/Button'

interface Props {
  collectionName?: string
  disabled?: boolean
  isFirstElement?: boolean
  isNew?: boolean
  onDeleteHandler?: () => void
  removeHidden?: boolean
}

export const CollectionInputContainer: React.FC<Props> = ({
  children,
  collectionName,
  disabled,
  isFirstElement,
  isNew,
  onDeleteHandler,
  removeHidden
}) => {
  const btnClassName = classNames('collection-input-container__bin', {
    'collection-input-container__bin--first': isFirstElement
  })

  let spacing: GridSpacing = 4
  if (collectionName) {
    if (['sizeMapping', 'ownBidders', 'demandBidders'].includes(collectionName)) {
      spacing = 2
    } else if (collectionName === 'abTestVersionsAttributes') {
      spacing = 0
    }
  }

  return (
    <Grid container spacing={spacing} className='collection-input-container'>
      {React.cloneElement(children as React.ReactElement<any>, { isNew: isNew })}

      <Grid item lg={1} xs={1}>
        {!removeHidden && !disabled && (
          <Button
            type={ButtonType.SingleAction}
            theme={ButtonTheme.Blue}
            buttonType='button'
            onClickHandler={onDeleteHandler}
            className={btnClassName}
          >
            <Bin />
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
