import React from 'react'

import { Grid } from '@material-ui/core'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { CodeEditor } from 'components/Form'
import { Modal } from 'components/Modals'
import { CustomCodeTemplateModalProps } from './types'

import './style.scss'

export const CustomCodeTemplateModal = ({
  visible,
  setVisible,
  confirmButtonText,
  customCode: { codeAfter, codeBefore, label }
}: CustomCodeTemplateModalProps): JSX.Element => (
  <Modal visible={visible} setVisible={setVisible} shadow={true} backdrop={true}>
    <div className='custom-code-template-modal-container confirmation__modal-container'>
      <div className='CustomCodeTemplateModal confirmation__modal-content'>
        <Grid>
          <b>{label}</b>
        </Grid>

        <Grid>
          <label htmlFor='custom-code-template-modal-code-before'>Code Before</label>

          <CodeEditor
            id='custom-code-template-modal-code-before'
            name='code-before'
            mode='javascript'
            disabled={true}
            value={codeBefore}
          />
        </Grid>

        <Grid>
          <label htmlFor='custom-code-template-modal-code-after'>Code After</label>

          <CodeEditor
            id='custom-code-template-modal-code-after'
            name='code-after'
            mode='javascript'
            disabled={true}
            value={codeAfter}
          />
        </Grid>
      </div>

      <footer>
        <Button
          theme={ButtonTheme.Blue}
          type={ButtonType.Primary}
          className='custom-code-template-modal__button'
          onClickHandler={() => setVisible(false)}
        >
          {confirmButtonText}
        </Button>
      </footer>
    </div>
  </Modal>
)
