import React from 'react'
import { ErrorsContainer } from 'containers/Errors'
import ErrorImg from 'images/unauthorized.png'

import { useGoogleAnalytics } from 'utilities/reactGa'

export const UnauthorizedErrorPage: React.FC = () => {
  useGoogleAnalytics(location.pathname)

  return <ErrorsContainer className='error-page__not-found' image={ErrorImg} translationPlaceholder='unauthorized' />
}
