import { RoleType } from 'webapp/constants/Role'
import { routesBuilder } from 'utilities/routesBuilder'
import { TabItem } from 'components/Box/TabNavigation/TabNavigation'
import { useTranslation } from 'react-i18next'

export const useUserTabs = () => {
  const { t } = useTranslation('users')

  const defaultTab = RoleType.Publisher
  const searchParam = 'roleType'
  const currentTab = new URLSearchParams(location.search).get(searchParam) ?? defaultTab

  const tabs: TabItem[] = [
    { href: routesBuilder.oss.users.publishersRoot, title: t('roleTypes.publishers') },
    { href: routesBuilder.oss.users.superusersRoot, title: t('roleTypes.superusers') }
  ]

  return { currentTab, defaultTab, searchParam, tabs }
}
