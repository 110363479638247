import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { ErrorUtils } from 'utilities/errorUtils'
import { FormikInputContainer as FormikInput } from 'containers/FormikContainers'
import { PasswordRecoveryFormFactory } from './formSchema'

import SendPasswordResetMutation from 'gql/mutations/auth/SendPasswordReset.gql'

interface Props {
  onSuccess: () => void
}

export const ForgottenPasswordForm: React.FC<Props> = ({ onSuccess }) => {
  const [errors, setErrors] = useState(new ErrorUtils([]))

  const { t } = useTranslation('auth')

  const [SendPasswordReset, { loading: isSubmitting }] = useMutation(SendPasswordResetMutation, {
    onCompleted: ({ sendPasswordReset: { errors } }) => {
      if (errors.length) {
        setErrors(new ErrorUtils(errors))
      } else {
        onSuccess()
      }
    }
  })

  const onSubmitHandler = (values: PasswordRecoveryData) => {
    SendPasswordReset({ variables: { input: values } })
  }

  return (
    <div className='password-form'>
      <Formik initialValues={PasswordRecoveryFormFactory()} onSubmit={onSubmitHandler}>
        <Form>
          <Field
            id='email'
            name='email'
            type='email'
            label={t('forgottenPassword.form.email.label')}
            placeholder={t('forgottenPassword.form.email.placeholder')}
            component={FormikInput}
            errors={errors.errorsFor('email')}
          />

          <Button disabled={isSubmitting} type={ButtonType.Primary} theme={ButtonTheme.Blue} buttonType='submit'>
            {isSubmitting ? t('common:buttons.sending') : t('common:buttons.send')}
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
