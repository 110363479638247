import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { Menu } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ActiveProducts } from 'components/Header/ProductMenu/ActiveProducts'
import { InactiveProducts } from 'components/Header/ProductMenu/InactiveProducts'
import { MenuButton } from 'components/Button/MenuButton'
import { SwitchToOss } from 'components/Header/ProductMenu/SwitchToOss'

export const ProductMenu: React.FC = () => {
  const history = useHistory<History>()
  const { t } = useTranslation('products')

  const buttonRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLElement>(null)

  const [isVisible, setIsVisible] = useState(false)
  const handleFocusProps = useHandleFocus(menuRef)

  useEffect(() => {
    const unregister = history.listen(() => {
      setIsVisible(false)
    })

    return () => {
      unregister()
    }
  }, [setIsVisible])

  const openMenu = () => {
    setIsVisible(true)
  }

  const closeMenu = () => {
    setIsVisible(false)
  }

  return (
    <div className='product-menu'>
      <MenuButton
        aria-label={t('menu.showProductMenu')}
        aria-controls='product-menu'
        aria-haspopup
        className='product-menu__toggle'
        innerRef={buttonRef}
        onClick={openMenu}
      />

      <Menu
        id='product-menu'
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -220
        }}
        classes={{
          paper: 'MuiPopover-paper product-menu__container'
        }}
        getContentAnchorEl={null}
        onClose={closeMenu}
        open={isVisible}
        ref={menuRef}
        {...handleFocusProps}
      >
        <div>
          <SwitchToOss closeMenu={closeMenu} />
          <ActiveProducts closeMenu={closeMenu} />
          <InactiveProducts />
        </div>
      </Menu>
    </div>
  )
}

const useHandleFocus = (menuRef: RefObject<HTMLElement>) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].includes(event.key) || !menuRef.current) return

      const focusableElements = Array.from(menuRef.current.querySelectorAll('a, button')) as HTMLElement[]
      const index = focusableElements.indexOf(document.activeElement as HTMLElement)

      const element = ['ArrowDown', 'ArrowRight'].includes(event.key)
        ? focusableElements[index + 1] ?? focusableElements[0]
        : focusableElements[index - 1] ?? focusableElements.slice(-1)[0]
      element?.focus()
    },
    [menuRef]
  )

  const onMenuShown = (menu: HTMLElement) => {
    menu.querySelector<HTMLButtonElement | HTMLAnchorElement>('a, button')?.focus()
    menu.addEventListener('keydown', onKeyDown)
  }

  const onMenuClosing = (menu: HTMLElement) => {
    menu.removeEventListener('keydown', onKeyDown)
  }

  return {
    autoFocus: false,
    disableAutoFocusItem: true,
    disableScrollLock: true,
    TransitionProps: {
      onEntered: onMenuShown,
      onExiting: onMenuClosing
    },
    variant: 'menu' as const
  }
}
