import React from 'react'
import Tooltip from 'react-tooltip-lite'
import { useTranslation } from 'react-i18next'

import WarningIcon from 'images/warning-icon.svg'

interface Props {
  disabled?: boolean
}

export const UnconfiguredCell: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation('setup')

  return (
    <div className='unconfigured'>
      <Tooltip
        className='qw-tooltip'
        content={t('bidders.inventoryConnection.dataTable.unconfigured')}
        direction='right-start'
        tipContentHover={true}
        arrow={false}
      >
        <img className={disabled ? 'disabled' : ''} src={WarningIcon} alt={'warning icon'} />
      </Tooltip>
    </div>
  )
}
