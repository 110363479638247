import { gql } from '@apollo/client'

export const CUSTOM_CODE_FRAGMENT = gql`
  fragment CustomCodeFragment on CustomCode {
    codeAfter
    codeBefore
    comment
    id
    name
    template
    updatedAt
    workspace {
      id
      name
    }
    workspaceId
  }
`
