import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import omit from 'lodash/omit'
import { useTranslation } from 'react-i18next'

import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { StatusForm } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { ConsentManagementBox, DomainBox } from 'components/oss/Inventory/Domains/NewDomainForm'
import { TCF_TIMEOUT_DEFAULT } from 'constants/ConsentManagement'
import { TCFWaitType } from 'constants/TCFWaitType'
import { Notification, NotificationContext } from 'context/NotificationContext'
import { UserContext } from 'context/UserContext'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { ErrorUtils } from 'utilities/errorUtils'
import { CreateDomainData, CreateDomainVars, createDomainVarsDefault } from './types'
import { useRedirectOnSave } from './useRedirectOnSave'

import CREATE_DOMAIN from 'gql/mutations/domains/CreateDomain.gql'

export const NewPage = (): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { isSuperuser } = useContext(UserContext)
  const { createNotifier } = useContext(NotificationContext) as Notification
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const { redirectOnSave } = useRedirectOnSave()

  const [createDomain, { loading }] = useMutation<CreateDomainData, { input: CreateDomainVars }>(CREATE_DOMAIN, {
    onCompleted: ({ createDomain: { domain, errors } }) => {
      if (errors.length > 0) {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
        setErrors(new ErrorUtils(errors))
      } else if (domain) {
        const {
          id,
          name,
          activeVersion: { id: activeVersionId }
        } = domain

        createNotifier(t('domains.new.successMessage', { name }), NotificationType.SUCCESS)
        formStatus.clear()
        redirectOnSave(id, activeVersionId)
      }
    }
  })

  const handleSubmit = (data: CreateDomainVars): void => {
    const { ossConsentManagementAttributes: cmAttributes } = data

    if (cmAttributes) {
      const { tcfTimeout, type, waitForTcf } = cmAttributes

      if (waitForTcf) {
        cmAttributes.tcfTimeout = type === TCFWaitType.Timeout ? Number(tcfTimeout) : TCF_TIMEOUT_DEFAULT
      }
    }

    createDomain(nestGqlInput(isSuperuser ? data : omit(data, ['ybAmazonSid', 'ybDomainSid'])))
  }

  return (
    <Formik initialValues={createDomainVarsDefault} onSubmit={(values: CreateDomainVars): void => handleSubmit(values)}>
      {({ dirty, values }) => (
        <StatusForm name='oss-domain' dirty={dirty}>
          <DomainBox errors={errors} />
          <ConsentManagementBox errors={errors} values={values} />

          <Button buttonType='submit' disabled={loading || !dirty} theme={ButtonTheme.Blue} type={ButtonType.Primary}>
            {loading ? t('common:buttons.saving') : t('common:buttons.save')}
          </Button>
        </StatusForm>
      )}
    </Formik>
  )
}
