import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/Avatar'

interface Props {
  user: User
}

const UserDetails: React.FC<Props> = memo(({ user }) => {
  const { t } = useTranslation()
  return (
    <div className='user-widget__user-details'>
      <Avatar user={user} containerClassName='user-widget__user-details--avatar' />
      <p className='user-widget__user-details--name'>{user?.fullName}</p>
      <p className='user-widget__user-details--email'>{user?.email}</p>
      <p className='user-widget__user-details--role'>{t(`userRole.${user?.role}`)}</p>
    </div>
  )
})

UserDetails.displayName = 'UserDetails'

export { UserDetails }
