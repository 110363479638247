import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ButtonType, ButtonTheme } from 'components/Button'
import { FormikDateRangePickerContainer as FormikDatePicker } from 'containers/FormikContainers'
import { LoadingButton } from 'components/Button/LoadingButton'

interface Props {
  networkCode: string
  networkName: string
  startDate?: Date
  endDate?: Date
  isSubmitting: boolean
  onSubmitHandler: (formValues: ReportFormValues) => void
}

export const ReportTimePeriodForm: React.FC<Props> = ({
  startDate,
  endDate,
  networkCode,
  networkName,
  isSubmitting,
  onSubmitHandler
}) => {
  const { t } = useTranslation('reports')

  return (
    <Grid item>
      <Formik
        initialValues={{ network: { value: networkCode, label: networkName }, timePeriod: { startDate, endDate } }}
        onSubmit={onSubmitHandler}
      >
        <Form>
          <div className='report-form__container report-form__container--details'>
            <div>
              <Field
                id='time-period'
                name='timePeriod'
                label={t('formPage.timePeriod.label')}
                tooltipContent={t('formPage.timePeriod.tooltip')}
                component={FormikDatePicker}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
                disabled={isSubmitting}
              />
            </div>
            <LoadingButton
              type={ButtonType.Primary}
              theme={ButtonTheme.Orange}
              buttonType='submit'
              className='report-form__footer__btn report-form__footer--details'
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t('formPage.generateReport')}
            </LoadingButton>
          </div>
        </Form>
      </Formik>
    </Grid>
  )
}
