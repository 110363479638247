import React from 'react'
import { Field } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Errors } from 'utilities/errorUtils'
import { ErrorMessages } from 'components/Form'
import { FieldsContainer } from './FieldsContainer'
import {
  FormikToggleContainer as FormikToggle,
  FormikCodeEditorContainer as CodeEditor
} from 'containers/FormikContainers'
import { Sizes } from './Sizes'

interface Props {
  errors: Errors
  index?: number
  generalSizeType: boolean
}

export const MediaTypesRow: React.FC<Props> = ({ errors, index = 0, generalSizeType }) => {
  const { t } = useTranslation('inventory')
  const fieldNamePrefix = generalSizeType ? 'mediaTypes' : `sizeMapping.${index}`

  const getErrors = (fields: string[], nested?: boolean) => {
    const attributes = [generalSizeType ? 'general' : 'selected', 'mediaTypesAttributes', index.toString(), ...fields]
    const result = errors.errorsFor(attributes)
    return nested ? result.concat(errors.nestedErrorsFor(attributes)) : result
  }

  return (
    <Grid container spacing={10}>
      <Grid item lg={4} xs={4}>
        <Field
          id={`ad_unit-banner-enabled-${index}`}
          name={`${fieldNamePrefix}.bannerEnabled`}
          label={t('adUnits.form.inputs.mediaTypes.bannerEnabled.label')}
          tooltipContent={t('adUnits.form.inputs.mediaTypes.bannerEnabled.tooltip')}
          component={FormikToggle}
          labelStyle='bold'
        />

        <FieldsContainer sizeMapping={!generalSizeType} index={index} type='bannerEnabled'>
          <Field
            id={`ad_unit-banner-sizes-${index}`}
            name={`${fieldNamePrefix}.bannerSizes`}
            errors={getErrors(['bannerSizes'])}
            label={t('adUnits.form.inputs.mediaTypes.banner.sizes.label')}
            tooltipContent={t('adUnits.form.inputs.mediaTypes.banner.sizes.tooltip')}
            component={Sizes}
            labelStyle='bold'
          />
        </FieldsContainer>
      </Grid>

      <Grid item lg={4} xs={4}>
        <Field
          id={`ad_unit-video-enabled-${index}`}
          name={`${fieldNamePrefix}.videoEnabled`}
          label={t('adUnits.form.inputs.mediaTypes.videoEnabled.label')}
          tooltipContent={t('adUnits.form.inputs.mediaTypes.videoEnabled.tooltip')}
          component={FormikToggle}
          labelStyle='bold'
        />

        <FieldsContainer sizeMapping={!generalSizeType} index={index} type='videoEnabled'>
          <Field
            id={`ad_unit-player-size-${index}`}
            name={`${fieldNamePrefix}.playerSize`}
            errors={getErrors(['playerSize'])}
            label={t('adUnits.form.inputs.mediaTypes.video.playerSize.label')}
            tooltipContent={t('adUnits.form.inputs.mediaTypes.video.playerSize.tooltip')}
            single
            component={Sizes}
            labelStyle='bold'
          />
        </FieldsContainer>
      </Grid>

      <Grid item lg={4} xs={4}>
        <Field
          id={`ad_unit-native-enabled-${index}`}
          name={`${fieldNamePrefix}.nativeEnabled`}
          label={t('adUnits.form.inputs.mediaTypes.nativeEnabled.label')}
          tooltipContent={t('adUnits.form.inputs.mediaTypes.nativeEnabled.tooltip')}
          component={FormikToggle}
          labelStyle='bold'
        />

        <FieldsContainer sizeMapping={!generalSizeType} index={index} type='nativeEnabled'>
          <Field
            id={`ad_unit-native-${index}`}
            name={generalSizeType ? 'mediaTypes.native' : 'sizeMappingNative'}
            errors={getErrors(['native'], true)}
            label={t('adUnits.form.inputs.mediaTypes.native.label')}
            tooltipContent={t('adUnits.form.inputs.mediaTypes.native.tooltip')}
            component={CodeEditor}
            labelStyle='bold'
          />
        </FieldsContainer>
      </Grid>

      <ErrorMessages
        className='error__section error--media-types'
        errors={getErrors(['']).concat(getErrors(['workspaceDeviceId']))}
      />
    </Grid>
  )
}
