import React from 'react'

import { Close } from 'icons/Close'
import { Image } from 'components/Image'

interface Props {
  icon: string
  message: string
  onCloseHandler: () => void
}

export const MessageContainer: React.FC<Props> = ({ children, icon, message, onCloseHandler }) => {
  return (
    <div>
      <header className='bulk-upload-status__header'>
        <Image className='bulk-upload-status__header--icon' src={icon} alt='status_message_icon' />
        <p className='bulk-upload-status__header--message'>{message}</p>
      </header>

      <main className='bulk-upload-status__message-container'>
        <button className='bulk-upload-status__button--close' onClick={onCloseHandler}>
          <Close />
        </button>
        {children}
      </main>
    </div>
  )
}
