import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export const PasswordRecoveryCompletedContainer: React.FC = () => {
  const { t } = useTranslation('auth')

  return (
    <Grid container spacing={4} className='password-recovery'>
      <Grid item xl={6} lg={7} xs={8}>
        <header className='password-recovery__header'>
          <h2 className='user-flow__title'>{t('forgottenPasswordCompleted.title')}</h2>
          <span className='user-flow__subtitle'>{t('forgottenPasswordCompleted.subtitle')}</span>
        </header>
      </Grid>
    </Grid>
  )
}
