import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Formik } from 'formik'
import { Grid } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import { ErrorUtils } from 'utilities/errorUtils'
import { InvitedUserFactory } from 'components/Auth/RegisterForm/formSchema'
import { InvitedUserSerializer } from 'serializers/InvitedUserSerializer'
import jwtToken from 'utilities/jwtToken'
import { NotificationContext, Notification } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { RegisterForm } from 'components/Auth/RegisterForm/RegisterForm'
import { nestGqlInput } from 'utilities/commonGqlObjects'

import CompleteSignUpMutation from 'gql/mutations/users/CompleteSignUp.gql'
import InvitedUserQuery from 'gql/queries/users/InvitedUser.gql'

interface Props {
  onComplete: () => void
}

export const RegisterUserContainer: React.FC<Props> = ({ onComplete }) => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('auth')
  const history = useHistory<History>()
  const queryParams = queryString.parse(history.location.search)

  const [disabledFields, setDisabledFields] = useState<string[]>([])
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [invitedUser, setInvitedUser] = useState<InvitedUser | undefined>(undefined)

  const invitationToken = useMemo(() => {
    return queryParams.invitation_token
  }, [queryParams])

  const redirectToSignIn = useCallback(
    (error) => {
      history.push('/log_in')
      createNotifier(error, NotificationType.ERROR)
    },
    [createNotifier, history]
  )

  useEffect(() => {
    !queryParams.invitation_token && redirectToSignIn(t('register.invitationToken.notification.error'))
  }, [redirectToSignIn, t, queryParams])

  useQuery(InvitedUserQuery, {
    variables: { invitationToken },
    onCompleted: ({ invitedUser }) => {
      if (invitedUser) {
        const user: InvitedUser = {
          email: invitedUser.email,
          invitationToken: invitationToken as string
        }

        setInvitedUser(user)
        setDisabledFields(
          (Object.keys(user) as (keyof InvitedUser)[]).filter((field: keyof InvitedUser) => user[field])
        )
      } else {
        redirectToSignIn(t('register.completed.error'))
      }
    }
  })

  const [CompleteSignUp, { loading: isSubmitting }] = useMutation(CompleteSignUpMutation, {
    onCompleted: ({ completeSignUp: { credentials, errors } }) => {
      if (credentials?.accessToken) {
        jwtToken.setToken(credentials.accessToken)
        onComplete()
      } else {
        setErrors(new ErrorUtils(errors))
      }
    }
  })

  const onSubmit = (values: InvitedUser) => {
    const serializer = new InvitedUserSerializer(values)
    CompleteSignUp(nestGqlInput(serializer.getParams()))
  }

  return (
    <Grid container spacing={4} className='oss'>
      <Grid item xl={6} lg={7} xs={8}>
        <div>
          <header className='register-form__header'>
            <h2 className='user-flow__title'>{t('register.title')}</h2>
            <span className='user-flow__subtitle'>
              {t('register.subtitle.description')}
              <Link to='/log_in' className='user-flow__subtitle--link'>
                {t('register.subtitle.link')}
              </Link>
            </span>
          </header>

          {invitedUser && (
            <Formik initialValues={InvitedUserFactory(invitedUser)} onSubmit={onSubmit}>
              <RegisterForm disabled={disabledFields} errors={errors} isSubmitting={isSubmitting} />
            </Formik>
          )}
        </div>
      </Grid>
    </Grid>
  )
}
