import { useTranslation } from 'react-i18next'

import { FilterType } from 'webapp/constants/FilterType'

export const useFilterConfig = (): AdvancedFilterConfiguration[] => {
  const { t } = useTranslation('inventory')

  return [
    {
      label: t('filters.label.domainName'),
      name: 'name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.domainName'),
      type: FilterType.Input
    },
    {
      label: t('filters.label.versionName'),
      name: 'domain_versions.name',
      operators: ['contains', 'not_contains'],
      placeholder: t('filters.placeholder.versionName'),
      type: FilterType.Input
    },
    {
      buildValueOptions: () => [
        { label: t('filters.value.active'), value: 'true' },
        { label: t('filters.value.inactive'), value: 'false' }
      ],
      label: t('filters.label.healthcheck'),
      name: 'live',
      type: FilterType.Select
    }
  ]
}
