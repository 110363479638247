import { gql } from '@apollo/client'

export const OSS_AD_UNIT_FRAGMENT = gql`
  fragment OssAdUnitFragment on OssAdUnit {
    adUnitName
    adUnitPath
    domain {
      id
      name
    }
    elementId
    frbEnabled
    id
    outOfPage
    outOfPageType
    pgEnabled
    qwEnabled
    reviveEnabled
    dedicatedForUnfilledRecovery
  }
`
