import React, { useContext, useEffect, useState } from 'react'
import { Column } from 'react-table'

import { CollectionNames } from 'webapp/constants/CollectionNames'
import { OwnBidderSettingRowData } from './OwnBidderSettingRowData'
import { OwnBidderTableContext } from 'webapp/context/OwnBiddersContext'
import { QueryTable } from 'containers/QueryTable'
import { columnsConfig } from './columnsConfig'

import BidderSettingsQuery from 'gql/queries/bidderSettings/BidderSettings.gql'

export const OwnBidderSettingsTable: React.FC = () => {
  const { openEditModal, bidderSettings } = useContext(OwnBidderTableContext)
  const [shouldRefetch, setShouldRefetch] = useState(false)

  useEffect(() => {
    setShouldRefetch(!shouldRefetch)
  }, [bidderSettings])

  return (
    <QueryTable<OwnBidderSettingRowData>
      className='own-bidder-settings-table'
      collectionName={CollectionNames.BidderSettings}
      columnsConfig={columnsConfig as Column[]}
      dataQuery={BidderSettingsQuery}
      mapper={(node: BidderSetting) => new OwnBidderSettingRowData(node)}
      onRowClick={(row) => openEditModal(row.original.id as string)}
      shouldRefetchData={shouldRefetch}
    />
  )
}
