import React, { useState } from 'react'

import { ToastNotifier } from 'components/ToastNotifier/ToastNotifier'
import { NotificationType } from 'components/ToastNotifier/enums'

type ToastNotifierHookType = {
  Notifier: React.ReactNode
  createNotifier: (message: string, type: NotificationType) => void
}

export const useToastNotifier = (): ToastNotifierHookType => {
  const [Notifier, setNotifier] = useState<React.ReactNode>(null)

  const onRenderFinishedHandler = (finished: boolean): void => {
    if (finished) setNotifier(null)
  }

  const createNotifier = (message: string, type: NotificationType, time = 5): void => {
    setNotifier(<ToastNotifier config={{ type, message, time }} onRenderFinished={onRenderFinishedHandler} />)
  }

  return { Notifier, createNotifier }
}
