import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ToggleSwitch } from 'components/Form'
import { NotificationType } from 'components/ToastNotifier'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Error } from 'utilities/errorUtils'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { OssAdUnitRowData } from '../OssAdUnitRowData'
import { ReviveToggleCellProps, UpsertOssAdUnitData, UpsertOssAdUnitVars } from './types'

import { UPSERT_OSS_AD_UNIT } from 'gql/mutations/adUnits/UpsertOssAdUnit'

import './style.scss'

export const ReviveToggleCell = ({ row }: ReviveToggleCellProps): JSX.Element => {
  const { t } = useTranslation('revive')
  const { createNotifier } = useContext(NotificationContext) as Notification

  const { id: ossAdUnitId, reviveEnabled }: OssAdUnitRowData = row.original
  const [status, setStatus] = useState<boolean>(reviveEnabled)

  const [updateStatus] = useMutation<UpsertOssAdUnitData, { input: UpsertOssAdUnitVars }>(UPSERT_OSS_AD_UNIT, {
    onCompleted: ({ upsertOssAdUnit: { ossAdUnit, errors } }) => {
      if (errors.length > 0) {
        createNotifier(errors.map((e: Error) => e.message).join(', '), NotificationType.ERROR)
      } else if (ossAdUnit) {
        const { adUnitName, reviveEnabled } = ossAdUnit

        setStatus(reviveEnabled)
        createNotifier(t('configuration.edit.adUnits.reviveUpdateSuccess', { adUnitName }), NotificationType.SUCCESS)
      }
    }
  })

  const handleChange = () =>
    void updateStatus(
      nestGqlInput({
        ossAdUnitId,
        reviveEnabled: !reviveEnabled
      })
    )

  return (
    <div className='ReviveToggleCell' onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        checked={status}
        id={`revive-status-${ossAdUnitId}`}
        key={ossAdUnitId}
        name={`revive-status-${ossAdUnitId}`}
        onChange={handleChange}
      />
    </div>
  )
}
