import React from 'react'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { BoxHeader } from 'components/Box'
import { BasicFormContainer } from './BasicForm/BasicFormContainer'
import { LoadingContainer } from 'components/LoadingContainer'
import DomainProductToggle from 'components/shared/Inventory/Domains/DomainProductToggle'
import { Product } from 'constants/Product'
import AdUnitsTableContainer from './AdUnitsTableContainer'
import TargetingFormContainer from './TargetingForm'
import { ReviveDomainData, ReviveDomainVars, UrlParams } from './types'

import { REVIVE_DOMAIN_CONFIGURATION } from 'gql/queries/revive/reviveDomainConfiguration'

export const ConfigurationEdit = (): JSX.Element => {
  const { reviveDomainConfigurationId } = useParams<UrlParams>()

  const { data } = useQuery<ReviveDomainData, ReviveDomainVars>(REVIVE_DOMAIN_CONFIGURATION, {
    variables: { reviveDomainConfigurationId }
  })

  if (data) {
    const {
      reviveDomainConfiguration: {
        domain: { id, name, reviveEnabled }
      }
    } = data

    return (
      <>
        <BoxHeader title={name}>
          <DomainProductToggle domainId={id} initialStatus={reviveEnabled} product={Product.Revive} />
        </BoxHeader>

        <BasicFormContainer urlReviveDomainConfigurationId={reviveDomainConfigurationId} />

        <TargetingFormContainer urlReviveDomainConfigurationId={reviveDomainConfigurationId} />

        <AdUnitsTableContainer domainId={id} />
      </>
    )
  } else return <LoadingContainer fullHeight loading />
}
