import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GAMConnectorFactory, GAMConnectorFormType } from 'components/oss/Workspaces/WorkspaceGamConnector/formSchema'

const connectionIdKey = 'gamAutomationForm.connectionId'
const nativeKey = 'gamAutomationForm.native'

export const useFormState = (connections: ConnectedNetwork[] | null) => {
  const [gamConnectionForm, setGamConnectionForm] = useState<GAMConnectorFormType>(() => GAMConnectorFactory())
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const connectionId = searchParams.get('connectionId')
  const forceNative = Boolean(searchParams.get('native'))

  useEffect(() => {
    if (!connections?.length) return

    const connection = connections.find(({ id }) => id === connectionId)
    if (connection) {
      setGamConnectionForm(GAMConnectorFactory(connection, forceNative))
    }
  }, [connections?.length, connectionId, forceNative])

  return { gamConnectionForm }
}

export const useFormTitle = (gamConnectionForm: GAMConnectorFormType) => {
  const { t } = useTranslation('workspaces')
  const [formTitle, setFormTitle] = useState(t('gamConnector.networkForm.title.new'))

  useEffect(() => {
    setFormTitle(t(`gamConnector.networkForm.title.${gamConnectionForm.id ? 'edit' : 'new'}`))
  }, [gamConnectionForm.id])

  return formTitle
}

export const saveGamParamsBeforeRedirect = (connectionId?: string, native?: boolean) => {
  sessionStorage.setItem(connectionIdKey, connectionId ?? '')
  sessionStorage.setItem(nativeKey, native ? 'true' : '')
}

export const restoreGamParamsAfterRedirect = (refreshToken: string) => {
  const connectionId = sessionStorage.getItem(connectionIdKey)
  const native = sessionStorage.getItem(nativeKey)

  return queryString.stringify(
    {
      connectionId,
      native,
      refreshToken
    },
    {
      skipEmptyString: true,
      skipNull: true
    }
  )
}
