import { Mark } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { DEFAULT_TRAFFIC, MIN_SAFE_TRAFFIC } from './constants'

export const useSliderMarks = (): Mark[] => {
  const { t } = useTranslation('priceGenius')

  return [
    { value: MIN_SAFE_TRAFFIC },
    { value: DEFAULT_TRAFFIC, label: t('configuration.columns.trafficPercent.default') }
  ]
}
