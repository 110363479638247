import React, { useContext, useMemo } from 'react'
import i18n from 'i18next'
import { CellProps, Column } from 'react-table'

import { ActionsCell } from './ActionsCell'
import { BidderLogo } from 'components/BidderLogo'
import { OwnBidderSettingRowData } from './OwnBidderSettingRowData'
import { ToggleCell } from './ToggleCell'
import { UserContext } from 'webapp/context/UserContext'

const useAdNetworkEnabled = () => {
  const { user } = useContext(UserContext)
  return useMemo(() => user?.workspace?.adNetworkEnabled, [user])
}

export const columnsConfig: Column<OwnBidderSettingRowData>[] = [
  {
    Header: () => i18n.t('setup:bidders.tableHeader.bidder'),
    id: 'bidders.name',
    accessor: 'bidderLogo',
    disableSortBy: false,
    Cell: ({ value: bidderLogo }) => (
      <>
        <div className='d-flex align-items-center'>
          <BidderLogo bidderName={bidderLogo.bidderName} fileName={bidderLogo.fileName} />
        </div>
      </>
    )
  },
  {
    Header: () => i18n.t('setup:bidders.tableHeader.name'),
    accessor: 'bidderCode',
    disableSortBy: false
  },
  {
    Header: (): string | null => {
      return useAdNetworkEnabled() ? i18n.t('setup:bidders.tableHeader.relationship') : null
    },
    accessor: 'networks',
    disableSortBy: true,
    Cell: (props: CellProps<OwnBidderSettingRowData>): JSX.Element | null =>
      useAdNetworkEnabled() ? ((props.value ?? []).length === 0 ? 'Direct' : props.value.join(', ')) : null
  },
  {
    Header: () => i18n.t('setup:bidders.tableHeader.bidderSid'),
    accessor: 'bidderSid',
    disableSortBy: true
  },
  {
    Header: () => i18n.t('setup:bidders.tableHeader.bidderCpmAdjustment'),
    accessor: 'bidCpmAdjustment',
    disableSortBy: false
  },
  {
    Header: () => i18n.t('setup:bidders.tableHeader.consentMode'),
    accessor: 'consentMode',
    disableSortBy: true,
    Cell: (props: CellProps<OwnBidderSettingRowData>): string =>
      i18n.t(`setup:bidders.form.consentMode.values.${props.value}`)
  },
  {
    Header: () => i18n.t('setup:bidders.tableHeader.priority'),
    accessor: 'priority',
    disableSortBy: false
  },
  {
    Header: (): string => i18n.t('setup:bidders.tableHeader.active'),
    accessor: 'active',
    disableSortBy: true,
    Cell: (props: CellProps<OwnBidderSettingRowData>): JSX.Element | null => (
      <ToggleCell fieldName='active' id='active' {...props} />
    )
  },
  {
    Header: () => i18n.t('setup:bidders.tableHeader.action'),
    id: 'actions',
    disableSortBy: true,
    Cell: ({ row }: CellProps<OwnBidderSettingRowData>) => <ActionsCell row={row} />
  }
]
