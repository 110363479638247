import React from 'react'
import { useToastNotifier, NotificationType } from 'components/ToastNotifier'

export const NotificationContext = React.createContext<Partial<Notification>>({})

export interface Notification {
  createNotifier: (message: string, type: NotificationType, time?: number) => void
}

export const NotificationProvider: React.FC = ({ children }) => {
  const { Notifier, createNotifier } = useToastNotifier()

  return (
    <NotificationContext.Provider value={{ createNotifier }}>
      {children}
      {Notifier}
    </NotificationContext.Provider>
  )
}
