import { WorkspacePackageEnum } from 'utilities/workspacePackageUtils'
import { AbilityAction, AbilitySubject } from 'utilities/security'
import { CanFunctionType, IsPackageActiveFunctionType } from 'webapp/context/AbilityContext/AbilityContext'

export const processPackagePermissions = (can: CanFunctionType, isPackageActive: IsPackageActiveFunctionType): void => {
  if (isPackageActive(WorkspacePackageEnum.FILLRATE_BOOSTER)) {
    can(AbilityAction.ACCESS, AbilitySubject.FILLRATE_BOOSTER_PACKAGE)
  }

  if (isPackageActive(WorkspacePackageEnum.PREBID_MANAGER)) {
    can(AbilityAction.ACCESS, AbilitySubject.PREBID_MANAGER_PACKAGE)
  }

  if (isPackageActive(WorkspacePackageEnum.S2S)) {
    can(AbilityAction.ACCESS, AbilitySubject.S2S_PACKAGE)
  }

  if (isPackageActive(WorkspacePackageEnum.VIEWABILITY_BOOSTER)) {
    can(AbilityAction.ACCESS, AbilitySubject.VIEWABILITY_BOOSTER_PACKAGE)
  }

  if (isPackageActive(WorkspacePackageEnum.YB_DEMAND)) {
    can(AbilityAction.ACCESS, AbilitySubject.YB_DEMAND_PACKAGE)
  }
}
