import React from 'react'

import { ToggleSwitch } from 'components/Form'

type ToggleCellType = {
  domain: PrebidAnalyticsDomain
  toggleDomainsHandler: (id: string, prebidAnalyticsEnabled: boolean) => void
}

export const ToggleCell = ({ domain, toggleDomainsHandler }: ToggleCellType): JSX.Element => {
  const onChangeHandler = () => {
    toggleDomainsHandler(domain.id, !domain.prebidManagerEnabled)
  }

  return (
    <div className='d-flex justify-content-center' onClick={(e) => e.stopPropagation()}>
      <ToggleSwitch
        key={domain.id}
        id={domain.id}
        name={`${domain.id}-toggle`}
        checked={domain.prebidManagerEnabled ?? false}
        onChange={onChangeHandler}
      />
    </div>
  )
}
