import { Product } from 'webapp/constants/Product'

type IndexedProductConnections = { [product in string & Omit<Product, Product.OSS>]: ProductConnection }

export class ProductManagementRowData {
  indexedProductConnections: IndexedProductConnections

  constructor(public workspace: Workspace) {
    this.indexedProductConnections = workspace.productConnections.reduce((acc, productConnection) => {
      acc[productConnection.product] = productConnection
      return acc
    }, {} as IndexedProductConnections)
  }

  get id(): string {
    return this.workspace.id
  }

  get name(): string {
    return this.workspace.name
  }

  get [Product.Quickwrap](): ProductConnection {
    return this.indexedProductConnections[Product.Quickwrap]
  }

  get [Product.FillRateBooster](): ProductConnection {
    return this.indexedProductConnections[Product.FillRateBooster]
  }

  get [Product.PriceGenius](): ProductConnection {
    return this.indexedProductConnections[Product.PriceGenius]
  }

  get [Product.Revive](): ProductConnection {
    return this.indexedProductConnections[Product.Revive]
  }

  get [Product.ViewabilityBooster](): ProductConnection {
    return this.indexedProductConnections[Product.ViewabilityBooster]
  }

  get updatedAt(): Date {
    return new Date(this.workspace.updatedAt)
  }
}
