import React from 'react'
import { useTranslation } from 'react-i18next'

import { CollectionSelect } from 'components/FormikComponents'
import { DeviceListItem } from 'components/Form/DeviceList/DeviceListItem'
import { Errors } from 'utilities/errorUtils'

interface DeviceListChildProps {
  index: number
  onDeleteHandler?: (index: number) => void
}

interface Props {
  children: (props: DeviceListChildProps) => JSX.Element
  errors: Errors
  name: string
  onDeleteHandler?: (index: number) => void
  options: SelectOption[]
  schemaBuilder?: () => Record<string, unknown>
}

export const DeviceList: React.FC<Props> = ({ children, errors, options, name, onDeleteHandler, schemaBuilder }) => {
  const { t } = useTranslation('inventory')

  return (
    <div className='device-list'>
      <CollectionSelect
        buttonContent={t('adUnits.form.inputs.sizeMapping.button')}
        errors={errors}
        label={t('adUnits.form.inputs.sizeMapping.label')}
        generalSizeType={false}
        name={name}
        options={options}
        placeholder={t('adUnits.form.inputs.sizeMapping.placeholder')}
        schemaBuilder={schemaBuilder}
        tooltipContent={t('adUnits.form.inputs.sizeMapping.tooltip')}
        onRemoveHandler={onDeleteHandler}
      >
        <DeviceListItem index={0} name={name}>
          {children}
        </DeviceListItem>
      </CollectionSelect>
    </div>
  )
}
