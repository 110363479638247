import React from 'react'
import classNames from 'classnames'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'

interface Props extends ButtonProps {
  loading?: boolean
}

export const LoadingButton: React.FC<Props> = ({
  type,
  theme,
  className,
  disabled,
  onClickHandler,
  buttonType,
  children,
  loading
}) => {
  return (
    <Button
      type={type}
      theme={theme}
      className={classNames('loading-btn', className)}
      disabled={disabled || loading}
      onClickHandler={onClickHandler}
      buttonType={buttonType}
    >
      {loading && <Spinner />}
      {children}
    </Button>
  )
}
