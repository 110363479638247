import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@material-ui/core'

import { apolloClient } from 'utilities/apolloClient'
import { AppRouter } from 'components/AppRouter'
import { HighlightSearchProvider } from 'webapp/context/HighlightSearchContext/HighlightSearchContext'
import { NotificationProvider } from 'webapp/context/NotificationContext'
import { theme } from 'utilities/theme'
import { UserProvider } from 'webapp/context/UserContext'
import 'webapp/i18n'

export const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <HighlightSearchProvider>
        <NotificationProvider>
          <ThemeProvider theme={theme}>
            <UserProvider>
              <AppRouter />
            </UserProvider>
          </ThemeProvider>
        </NotificationProvider>
      </HighlightSearchProvider>
    </ApolloProvider>
  )
}
