import React from 'react'

import { StickyCell } from 'components/quickwrap/Setup/DomainComparisonTable/StickyCell'
import { domainVersionName } from 'utilities/domainVersionName'

import { Plug } from 'icons/Plug'

interface Props {
  startingOffset?: number
  version: DomainVersion
}

export const DomainVersionCell: React.FC<Props> = ({ startingOffset, version }) => {
  return (
    <StickyCell className='domain-comparison-table__version' startingOffset={startingOffset} stickToLeft tag='td'>
      <Plug />
      <span>{domainVersionName(version.counter, version.name)}</span>
    </StickyCell>
  )
}
