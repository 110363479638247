import React, { FC, useMemo } from 'react'
import classNames from 'classnames'

export enum ButtonTheme {
  Orange = 'orange',
  OrangeReverse = 'orange-reverse',
  Blue = 'blue',
  BlueReverse = 'blue-reverse',
  BlueDark = 'blue-dark',
  BlueOutline = 'blue-outline',
  Red = 'red',
  RedReverse = 'red-reverse',
  FillRateBooster = 'fill-rate-booster',
  PriceGenius = 'price-genius',
  ViewabilityBooster = 'viewability-booster',
  White = 'white'
}

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  SingleAction = 'single-action'
}

export const Button: FC<ButtonProps> = ({
  theme,
  type,
  bgTransparent,
  buttonType,
  children,
  className = '',
  disabled,
  onClickHandler,
  title,
  tooltipContent
}) => {
  const btnClassName = useMemo(() => {
    return classNames('button', className, {
      [`button--${type}`]: true,
      [`button--${theme}`]: true,
      [`button--${theme}--transparent`]: bgTransparent,
      ['button__tooltip']: Boolean(tooltipContent),
      'button--disabled': disabled
    })
  }, [className, type, theme, bgTransparent, tooltipContent, disabled])

  return (
    <button
      className={btnClassName}
      data-tooltip={tooltipContent}
      disabled={disabled}
      onClick={onClickHandler}
      title={title}
      type={buttonType}
    >
      <div className='button__content'>{children}</div>
    </button>
  )
}
