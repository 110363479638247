import { MutableRefObject, useEffect, useRef } from 'react'

import deepClone from 'lodash/cloneDeep'

export const usePrevious = <T>(value: T): T | undefined => {
  const ref: MutableRefObject<T | undefined> = useRef<T>()

  useEffect(() => {
    ref.current = deepClone(value)
  }, [value])

  return ref.current
}
