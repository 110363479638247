import React, { useMemo, useState } from 'react'

import { CheckboxTreeListContainer } from './CheckboxTreeListContainer'
import { CheckboxTreeListElement } from './CheckboxTreeListElement'
import { CheckboxTreeListFilter } from './CheckboxTreeListFilter'
import { TreeHelper } from './TreeHelper'

type CheckboxTreeListType = {
  dataStructure: CheckboxTreeListDataItem[]
  updateHandler: (newItems: string[]) => void
  placeholder: string
  values: string[]
}

export const CheckboxTreeList = ({
  dataStructure,
  updateHandler,
  placeholder,
  values
}: CheckboxTreeListType): JSX.Element => {
  const [filter, setFilter] = useState('')
  const filterHandler = (filter: string) => setFilter(filter)

  const treeHelper = useMemo(() => {
    return new TreeHelper(dataStructure, values)
  }, [])

  const constructTreeView = (itemDataStructure: CheckboxTreeListDataItem[]): JSX.Element[] => {
    return itemDataStructure
      .filter((node) => treeHelper.findByKey(node.key).containsFilter(filter))
      .map((node) => (
        <CheckboxTreeListElement
          key={node.key}
          filter={filter}
          item={node}
          treeHelper={treeHelper}
          updateHandler={updateHandler}
        >
          {(node.children ?? []).length > 0 ? constructTreeView(node?.children ?? []) : undefined}
        </CheckboxTreeListElement>
      ))
  }

  return (
    <div className='CheckboxTreeList'>
      <CheckboxTreeListFilter filterHandler={filterHandler} placeholder={placeholder} />
      <div className='CheckboxTreeList__wrapper'>
        <CheckboxTreeListContainer>{constructTreeView(dataStructure)}</CheckboxTreeListContainer>
      </div>
    </div>
  )
}
