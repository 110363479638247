interface BodyType {
  [key: string]: string | number | boolean | undefined | null
}

interface ParamsType extends BodyType {
  domainVersionId?: string
  resourceId?: string
}

export class SettingsSerializer {
  settings: { [key: string]: any }
  resourceId: string

  constructor(settings: GeneralSettingForm, resourceId: string) {
    this.settings = settings
    this.resourceId = resourceId
  }

  createParams(): ParamsType {
    return {
      domainVersionId: this.resourceId,
      ...this._prepareBody()
    }
  }

  updateParams(): ParamsType {
    return {
      resourceId: this.resourceId,
      ...this._prepareBody()
    }
  }

  createCustomParams(): ParamsType {
    return {
      ...this.createParams()
    }
  }

  updateCustomParams(): ParamsType {
    return {
      ...this.updateParams()
    }
  }

  private _prepareBody(): BodyType {
    const body: BodyType = {}

    this._intParams().forEach((paramName) => (body[paramName] = parseInt(this.settings[paramName], 10)))
    return body
  }

  private _intParams(): Array<keyof GeneralSetting> {
    return ['failsafeTimeout', 'prebidTimeout']
  }

  private _getSelectValue(select: SelectOption | string) {
    if (typeof select === 'object') {
      return typeof select.value === 'string' ? select.value.toLowerCase() : select.value
    } else {
      return select
    }
  }
}
