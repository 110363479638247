type PrebidDeviceFormAttributes = {
  id?: string
  timeout: number
  timeoutEnabled: boolean
  workspaceDeviceId: string
  _destroy?: true
}

type PrebidDevicesForm = {
  prebidDeviceableId: string
  attributes: PrebidDeviceFormAttributes[]
}

export type PrebidDeviceOption = PrebidDeviceFormAttributes & SelectOption

export type PrebidDevicesFormikValues = {
  devices: PrebidDeviceOption[]
}

export class PrebidDevicesFormBuilder {
  static options = (devices: WorkspaceDevice[]): PrebidDeviceOption[] => devices.map(PrebidDevicesFormBuilder.option)

  static option = (device: WorkspaceDevice): PrebidDeviceOption => ({
    label: device.name,
    value: device.id,
    timeoutEnabled: false,
    timeout: 1000,
    workspaceDeviceId: device.id
  })

  static submitForm = (
    form: PrebidDevicesFormikValues,
    redundantDevices: PrebidDeviceOption[],
    prebidDeviceableId: string
  ): PrebidDevicesForm => ({
    attributes: form.devices.concat(redundantDevices).map((device) => ({
      id: device.id,
      timeout: device.timeoutEnabled ? Number(device.timeout) : Math.abs(Number(device.timeout)),
      timeoutEnabled: device.timeoutEnabled,
      workspaceDeviceId: device.workspaceDeviceId,
      _destroy: device._destroy
    })),
    prebidDeviceableId
  })

  static initialValues = (devices: PrebidDevice[] = []): PrebidDevicesFormikValues => ({
    devices: devices.map((device) => ({
      ...device,
      label: device.workspaceDevice.name,
      value: device.workspaceDevice.id,
      workspaceDeviceId: device.workspaceDevice.id
    }))
  })
}
