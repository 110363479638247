/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { assignToBidderParamsCloneTemplate, filterBidderParams } from 'components/AdUnits/AdUnitsBiddersForm/formSchema'
import { BidderParamMode } from 'webapp/constants/BidderParamMode'

export class AdUnitBiddersSerializer {
  formData: AdUnitBiddersFormData

  constructor(formData: AdUnitBiddersFormData) {
    this.formData = formData
  }

  ownBiddersParams() {
    return {
      adUnitId: this.formData.id,
      attributes: AdUnitBiddersSerializer.mapToAttributes(
        this.mergeAndSetBiddersMode(this.formData.bidderParamsAttributes)
      )
    }
  }

  yieldbirdBiddersParams() {
    return {
      adUnitId: this.formData.id,
      attributes: AdUnitBiddersSerializer.mapToAttributes(
        this.mergeAndSetBiddersMode(this.formData.demandBidderParamsAttributes)
      )
    }
  }

  static cloneBiddersParams(adUnitId: string, adUnit: AdUnit, canAccessAliases: boolean) {
    const attributes = AdUnitBiddersSerializer.mapToAttributes(
      adUnit
        .bidderParams!.filter((bidderParam) => canAccessAliases || filterBidderParams(bidderParam))
        .map(assignToBidderParamsCloneTemplate)
    )
    return {
      adUnitId,
      attributes
    }
  }

  static mapToAttributes(attributes: AdUnitFormBidder[]): BidderParamAttribute[] {
    return attributes.map((param) => ({
      bidderSettingId: param.bidderSettingId,
      connected: param.connected,
      id: param.id,
      mode: param.mode,
      params: param.params,
      _destroy: Boolean(param._destroy)
    }))
  }

  private mergeAndSetBiddersMode(bidderParams: AdUnitFormBidders): AdUnitFormBidder[] {
    return [
      ...bidderParams.client.map((bidderParam) => ({
        ...bidderParam,
        mode: BidderParamMode.Client
      })),
      ...bidderParams.server.map((bidderParam) => ({
        ...bidderParam,
        mode: BidderParamMode.Server
      }))
    ]
  }
}
