import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, ButtonTheme } from 'components/Button'
import { Checkbox } from 'components/Form'

interface Props {
  addNext?: boolean
  addNextHandler?: () => void
  disabled?: boolean
  isSubmitting?: boolean
  submitTheme: ButtonTheme
}

export const Footer: React.FC<Props> = ({ addNext, addNextHandler, disabled, isSubmitting, submitTheme }) => {
  const { t } = useTranslation('inventory')

  return (
    <div className='ad_unit-form__submit-section'>
      {typeof addNext !== 'undefined' && addNextHandler && (
        <Checkbox
          id='add_next'
          name='addNext'
          checked={addNext}
          label={t('adUnits.form.addNext')}
          onChange={addNextHandler}
        />
      )}
      <Button disabled={disabled} type={ButtonType.Primary} theme={submitTheme} buttonType='submit'>
        {isSubmitting ? t('common:buttons.saving') : t('common:buttons.save')}
      </Button>
    </div>
  )
}
