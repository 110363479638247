export const predefinedSizes = [
  '468x60',
  '728x90',
  '120x90',
  '120x600',
  '160x600',
  '300x600',
  '200x200',
  '250x250',
  '300x250',
  '336x280',
  '240x400',
  '300x100',
  '980x120',
  '250x360',
  '180x500',
  '980x150',
  '468x400',
  '930x180',
  '750x100',
  '750x200',
  '750x300',
  '2x4',
  '320x50',
  '300x50',
  '300x300',
  '1024x768',
  '300x1050',
  '970x90',
  '970x250',
  '1000x90',
  '320x80',
  '320x150',
  '1000x1000',
  '580x500',
  '640x480',
  '930x600',
  '320x480',
  '1800x1000',
  '320x320',
  '320x160',
  '980x240',
  '980x300',
  '980x400',
  '480x300',
  '300x120',
  '548x150',
  '970x310',
  '970x100',
  '970x210',
  '480x320',
  '768x1024',
  '480x280',
  '250x800',
  '320x240',
  '1000x300',
  '320x100',
  '800x250',
  '200x600',
  '980x600',
  '980x150',
  '1000x250',
  '640x320',
  '320x250',
  '250x600',
  '600x300',
  '640x360',
  '640x200',
  '1030x590',
  '980x360',
  '1x1',
  '320x180',
  '2000x1400',
  '580x400',
  '6x6',
  '2x2',
  '480x820',
  '400x600',
  '500x200',
  '998x200',
  '970x1000',
  '1920x1080',
  '1800x200',
  '320x500',
  '320x400',
  '640x380',
  'fluid'
]
