import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { DataStudioForm } from 'components/oss/Performance/DataStudioLinks/Form/DataStudioForm'
import { DataStudioLinksFactory } from 'components/oss/Performance/DataStudioLinks/Form/formSchema'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { LoadingContainer } from 'components/LoadingContainer'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'
import { ProductConnectionSerializer } from 'serializers/ProductConnectionSerializer'
import { routesBuilder } from 'utilities/routesBuilder'
import { useWorkspacesWithProducts } from 'utilities/products/administration'

import { UPDATE_PRODUCT_CONNECTION } from 'gql/mutations/productConnections/UpdateProductConnection'

export const NewPage: React.FC = () => {
  const { t } = useTranslation('dataStudio')
  const history = useHistory<History>()

  const [errors, setErrors] = useState(new ErrorUtils([]))
  const { createNotifier } = useContext(NotificationContext) as Notification

  const workspaces = useWorkspacesWithProducts()

  const [updateProductConnection, { loading: isSubmitting }] = useMutation(UPDATE_PRODUCT_CONNECTION)
  const onUpdateProductConnection = async (formData: DataStudioLinkFormData) => {
    const serializer = new ProductConnectionSerializer(formData)
    const { data } = await updateProductConnection(nestGqlInput(serializer.updateDataStudioUrl()))

    const errors = data.updateProductConnection.errors
    if (errors.length) {
      setErrors(new ErrorUtils(errors))
      createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
    } else {
      createNotifier(t('new.successMessage'), NotificationType.SUCCESS)
      formStatus.clear()
      history.push(routesBuilder.oss.performance.dataStudioLinks.root)
    }
  }

  return (
    <Box className='data-studio-links-new'>
      <BoxHeader title={t('new.title')} />

      <LoadingContainer loading={!workspaces}>
        {workspaces && (
          <DataStudioForm
            errors={errors}
            formData={DataStudioLinksFactory()}
            isSubmitting={isSubmitting}
            onSubmit={onUpdateProductConnection}
            workspaces={workspaces}
          />
        )}
      </LoadingContainer>
    </Box>
  )
}
