import React, { useContext, useEffect } from 'react'
import { useField } from 'formik'

import { ConnectedNetworksTableContext } from 'components/oss/Workspaces/ConnectedNetworksTable'

interface Props {
  currency: SelectOption
  network: SelectOption
}

export const ConnectionField: React.FC<Props> = ({ currency, network }) => {
  const [, , { setValue }] = useField('id')

  const { data: connections } = useContext(ConnectedNetworksTableContext)

  useEffect(() => {
    const connection = connections?.find(
      (connection) => connection.currency === currency.value && connection.networkCode === network.value
    )
    setValue(connection?.id)
  }, [currency, network, connections])

  return <></>
}
