import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Collapse } from '@material-ui/core'
import { debounce, isEqual } from 'lodash'
import { nanoid } from 'nanoid'

import { ButtonTheme } from 'components/Button'
import { filterStore } from 'utilities/filters/filterStore'
import { useOnClickOutside } from 'utilities/useOnClickOutside'
import { FILTER_DEBOUNCE_TIMEOUT } from 'webapp/constants/config'
import usePrevious from 'webapp/hooks/usePrevious'
import { AddFilterButton } from './AddFilterButton'
import { AdvancedFilterRow } from './AdvancedFilterRow'
import { ShowFilterButton } from './ShowFilterButton'
import { getFiltersFromLS } from './service'

interface Props {
  alignPopup?: 'left' | 'right'
  collectionName: string
  config: AdvancedFilterConfiguration[]
  theme?: ButtonTheme
}

export const AdvancedFilter: React.FC<Props> = ({
  alignPopup = 'left',
  collectionName,
  config,
  theme = ButtonTheme.Blue
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const filterRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(filterRef, () => setIsVisible(false))

  const filtersInit = getFiltersFromLS(collectionName)
  const [filters, setFilters] = useState<AdvancedFilter[]>(filtersInit)
  const filtersPrevious = usePrevious<AdvancedFilter[] | undefined>(filters)

  useEffect(() => {
    if (filters.some(({ by }) => by && !config.some(({ name }) => name === by))) {
      setFilters(filters.filter(({ by }) => !by ?? config.some(({ name }) => name === by)))
    }
  }, [filters])

  useEffect(() => {
    const callback = () => {
      const updatedFilters = filterStore.getFilters(collectionName).advancedFilter
      updatedFilters && setFilters(updatedFilters)
    }
    filterStore.subscribe(collectionName, callback)

    return () => {
      filterStore.unsubscribe(callback)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const addNewFilter = () => {
    setFilters([...filters, { id: nanoid(), by: '', operator: '', values: [] }])
  }

  const updateFilter = (id: string, newFilter: Partial<AdvancedFilter>) => {
    const index = filters.findIndex((filter) => filter.id === id)
    const filter = { ...filters[index], ...newFilter }
    const newFilters = [...filters]
    newFilters[index] = filter
    setFilters(newFilters)
  }

  const removeFilter = (id: string) => {
    setFilters(filters.filter((filter) => filter.id !== id))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFiltersDebounced = useCallback(
    debounce(
      (afs: AdvancedFilter[]) => filterStore.setFilter(collectionName, 'advancedFilter', afs),
      FILTER_DEBOUNCE_TIMEOUT
    ),
    []
  )

  useEffect(() => {
    const filtersLS = getFiltersFromLS(collectionName)

    if (filtersPrevious) {
      const diffWithPrevious = !isEqual(filters, filtersPrevious)
      const diffWithLS = !isEqual(filters, filtersLS)

      if (diffWithPrevious && diffWithLS) {
        setFiltersDebounced(filters)
      }
    }
  }, [filtersPrevious, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  const [cache, setCache] = useState({})

  return (
    <div ref={filterRef} className={`advanced-filter advanced-filter--${alignPopup}`}>
      <ShowFilterButton filters={filters} theme={theme} toggleFilter={() => setIsVisible(!isVisible)} />

      <Collapse in={isVisible}>
        <div className='advanced-filter__container'>
          {filters.map((filter, i) => (
            <AdvancedFilterRow
              cache={cache}
              config={config}
              filter={filter}
              filters={filters}
              key={filter.id || i}
              removeFilter={removeFilter}
              setCache={setCache}
              theme={theme}
              updateFilter={updateFilter}
            />
          ))}

          <AddFilterButton addFilter={addNewFilter} disabled={filters.length === config.length} theme={theme} />
        </div>
      </Collapse>
    </div>
  )
}
