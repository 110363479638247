import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { BidderContext } from 'pages/quickwrap/Setup/BiddersPage/BiddersPage'
import { Box, BoxBody, BoxHeader } from 'components/Box'
import { Button, ButtonTheme, ButtonType } from 'components/Button'
import { LoadingContainer } from 'components/LoadingContainer'
import { OwnBidderTableContext } from 'webapp/context/OwnBiddersContext'
import { OwnBidderModal } from 'containers/quickwrap/Setup/OwnBidder/OwnBidderModal'
import { OwnBidderSettingsTable } from 'components/quickwrap/Setup/OwnBidderSettings/Table/OwnBidderSettingsTable'
import { UserContext } from 'webapp/context/UserContext'

import BidderSettingsQuery from 'gql/queries/bidderSettings/BidderSettings.gql'
import BiddersQuery from 'gql/queries/bidders/Bidders.gql'
import NetworkQuery from 'gql/queries/networks/Networks.gql'

export const OwnBiddersContainer: React.FC = () => {
  const { t } = useTranslation('setup')
  const { user } = useContext(UserContext)

  const [visible, setVisible] = useState(false)
  const [bidderSettingId, setBidderSettingId] = useState<string>()
  const [closeDropdownCounter, setCloseDropdownCounter] = useState(0)
  const { forceConnectionsRefresh } = useContext(BidderContext)

  const [bidders, setBidders] = useState<Bidder[]>()
  useQuery(BiddersQuery, {
    variables: { order: { by: 'name', direction: 'asc' }, demand: false },
    onCompleted: ({ bidders: { nodes } }) => setBidders(nodes)
  })

  const [bidderSettings, setBidderSettings] = useState<BidderSetting[]>()
  const [networks, setNetworks] = useState<Network[]>([])

  const { refetch } = useQuery(BidderSettingsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ bidderSettings: { nodes } }) => setBidderSettings(nodes)
  })

  useQuery(NetworkQuery, {
    variables: { workspaceId: user?.workspace?.id },
    fetchPolicy: 'network-only',
    onCompleted: ({ networks: { nodes } }) => setNetworks(nodes)
  })

  const refetchBidderSettings = () => {
    forceConnectionsRefresh()
    setCloseDropdownCounter((prev) => prev + 1)
    return refetch()
  }

  const openEditModal = (bidderSettingId: string) => {
    setBidderSettingId(bidderSettingId)
    setVisible(true)
  }

  return (
    <LoadingContainer loading={!bidders || !bidderSettings}>
      {bidders && bidderSettings && (
        <Box className='own-bidders-container' isExpandable={true} list>
          <BoxHeader title={t('bidders.form.title')} subtitle={t('bidders.form.subtitle')}>
            <Button
              type={ButtonType.Primary}
              theme={ButtonTheme.Orange}
              onClickHandler={() => {
                setBidderSettingId(undefined)
                setVisible(true)
              }}
            >
              {t('common:buttons.addNew')}
            </Button>
          </BoxHeader>
          <BoxBody>
            <OwnBidderTableContext.Provider
              value={{
                bidderSettings,
                closeDropdownCounter,
                openEditModal,
                refetchBidderSettings
              }}
            >
              <OwnBidderSettingsTable />
              <OwnBidderModal
                bidders={bidders}
                bidderSettingId={bidderSettingId}
                close={() => {
                  setVisible(false)
                  setCloseDropdownCounter((prev) => prev + 1)
                }}
                networks={networks}
                visible={visible}
              />
            </OwnBidderTableContext.Provider>
          </BoxBody>
        </Box>
      )}
    </LoadingContainer>
  )
}
