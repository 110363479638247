import React from 'react'
import { ApolloQueryResult } from '@apollo/client'

interface OwnBidderTableContextValues {
  bidderSettings: BidderSetting[]
  closeDropdownCounter: number
  openEditModal: (id: string) => void
  refetchBidderSettings: () => Promise<ApolloQueryResult<{ bidderSettings: Connection<BidderSetting> }>>
}

export const OwnBidderTableContext = React.createContext<OwnBidderTableContextValues>({
  bidderSettings: [],
  closeDropdownCounter: 0,
  openEditModal: (_id: string) => {},
  refetchBidderSettings: () => new Promise(() => {})
})
