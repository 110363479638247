import React from 'react'

import { Grid } from '@material-ui/core'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from 'components/Box'
import { Section } from 'components/Form'
import { FormikInputContainer, FormikSelectContainer } from 'containers/FormikContainers'
import { DomainWithOssAdUnits } from 'pages/oss/Inventory/Pages/PageFormContainer'
import PagesAdUnitsConnectingTable from 'webapp/components/oss/Inventory/PagesAdUnitsConnectingTable'
import { MatchTypeSelector } from 'webapp/components/shared/Inventory/Pages/Form'
import { PageFormProps } from './types'

const PageForm = ({
  domainId,
  domains,
  errors,
  formType,
  onInitActiveAdUnits,
  onSetFieldValue,
  pageId
}: PageFormProps): JSX.Element => {
  const { t } = useTranslation('inventory')

  const domainsOptions = domains.map(
    (d: DomainWithOssAdUnits): SelectOption<DomainWithOssAdUnits['id']> => ({ value: d.id, label: d.name })
  )

  return (
    <Box>
      <BoxHeader title={t(`pages.${formType}.title`)} />

      <Section fullWidth className='page-form'>
        <Grid container spacing={4}>
          <Grid item lg={4} xs={4}>
            <Field
              component={FormikSelectContainer}
              disabled={formType === 'edit'}
              errors={errors.errorsFor('domainId')}
              id='domainId'
              label={t('pages.form.domainId.label')}
              name='domainId'
              onChangeHandler={(domain: SelectOption<string>): void => onSetFieldValue('domainId', domain.value)}
              options={domainsOptions}
              placeholder={t('pages.form.domainId.placeholder')}
              selectedItem={formType === 'edit' && domainsOptions[0]}
            />

            <Field
              component={FormikInputContainer}
              errors={errors.errorsFor('pageName')}
              id='pageName'
              label={t('pages.form.pageName.label')}
              name='pageName'
              placeholder={t('pages.form.pageName.placeholder')}
              type='text'
            />

            <Field
              component={FormikInputContainer}
              errors={errors.errorsFor('path')}
              id='path'
              label={t('pages.form.pagePath.label')}
              name='path'
              placeholder={t('pages.form.pagePath.placeholder')}
              type='text'
            />

            <Field
              id='matchType'
              name='matchType'
              label={t('pages.form.matchType.label')}
              component={MatchTypeSelector}
              errors={errors.errorsFor('matchType')}
            />
          </Grid>

          {domainId && (
            <Grid item xs={6}>
              <PagesAdUnitsConnectingTable
                domainId={domainId}
                onChange={(ids) => void onSetFieldValue('ossAdUnitIds', ids)}
                onInitActives={onInitActiveAdUnits}
                ossPageId={pageId}
                tableFor='OSS_PAGE'
              />
            </Grid>
          )}
        </Grid>
      </Section>
    </Box>
  )
}

export default PageForm
