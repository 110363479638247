import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Image } from 'components/Image'
import { getProductSettings } from 'utilities/products/settings'
import { Product } from 'webapp/constants/Product'
import { ProductMenu } from './ProductMenu'
import { UserWidget } from './UserWidget'

type Props = {
  product: Product
}

export const Header: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation('sidebar')
  const { homePageUrl, logo } = getProductSettings(product)

  const Logo = (): JSX.Element => (
    <Image src={logo} alt={t(`logo.${product}.alt`)} className='app-header__logo' id='company-logo' />
  )

  return (
    <header className='app-header mui-fixed'>
      {homePageUrl ? (
        <Link to={homePageUrl}>
          <Logo />
        </Link>
      ) : (
        <Logo />
      )}

      <div className='app-header__right'>
        <ProductMenu />
        <UserWidget />
      </div>
    </header>
  )
}
