import React, { useMemo } from 'react'
import { Field, useField } from 'formik'

import { FormikPrebidModulesContainer } from 'containers/FormikContainers'
import { ErrorUtils } from 'utilities/errorUtils'

import YieldbirdImg from 'images/logos/no-text/oss.svg'

interface Props {
  errors: ErrorUtils
  hideRequestModules?: boolean
  initialValues: UserIdFormData
  isDemand: boolean
  isDirty: boolean
  prebidModules: PrebidModule[]
  setErrors: React.Dispatch<React.SetStateAction<ErrorUtils>>
  setIsDirty: (isDirty: boolean) => void
  title: string
}

export const UserIdsSection: React.FC<Props> = ({
  errors,
  hideRequestModules,
  isDemand,
  isDirty,
  prebidModules,
  setErrors,
  setIsDirty,
  title
}) => {
  const [{ value }] = useField<PrebidModule[]>(isDemand ? 'own' : 'demand')

  const filteredPrebidModules = useMemo(() => {
    const existingModules = value.reduce((acc, module) => {
      return module._destroy ? acc : [...acc, module.name]
    }, [] as string[])

    return prebidModules.filter(
      (prebidModule) => prebidModule.demand === isDemand && !existingModules.includes(prebidModule.name)
    )
  }, [isDemand, prebidModules, value])

  const name = isDemand ? 'demand' : 'own'

  return (
    <div className={`box__body user-id-form__section user-id-form__section--${name}`}>
      <h3 className='form-section__title'>
        {isDemand && <img className='form-section__title-logo' src={YieldbirdImg} alt='' />}
        {title}
      </h3>

      <Field
        component={FormikPrebidModulesContainer}
        errorType='userIds'
        errors={errors}
        formId='add-user_id-form'
        handleIsDirty={setIsDirty}
        hideRequestModules={hideRequestModules}
        isDirty={isDirty}
        isDemand={isDemand}
        moduleKind='user_id'
        name={name}
        prebidModules={filteredPrebidModules}
        selectId='userIdsFormSelect'
        setErrors={setErrors}
        translations='userId'
      />
    </div>
  )
}
