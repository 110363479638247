/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AdUnitsBiddersForm } from 'components/AdUnits/AdUnitsBiddersForm'
import { AdUnitBiddersSerializer } from 'serializers/AdUnitBiddersSerializer'
import {
  AdUnitYieldbirdBiddersFactory,
  matchValidationErrorsToSplitBidders,
  validateUnsavedAdUnitBidders
} from 'components/AdUnits/AdUnitsBiddersForm/formSchema'
import { BidderParamMode } from 'webapp/constants/BidderParamMode'
import { Box, BoxHeader } from 'components/Box'
import { ErrorUtils } from 'utilities/errorUtils'
import { formStatus } from 'utilities/FormStatus'
import { nestGqlInput } from 'utilities/commonGqlObjects'
import { Notification, NotificationContext } from 'webapp/context/NotificationContext'
import { NotificationType } from 'components/ToastNotifier'

import BidderSettingsQuery from 'gql/queries/bidderSettings/BidderSettings.gql'
import UpsertBidderParams from 'gql/mutations/bidderParams/UpsertBidderParams.gql'

interface Props {
  adUnit: AdUnit
  domain?: Domain
}

export const AdUnitsYieldbirdBiddersContainer: React.FC<Props> = ({ adUnit, domain }) => {
  const { createNotifier } = useContext(NotificationContext) as Notification
  const { t } = useTranslation('inventory')
  const lastBidderParams = useRef<AdUnitFormBidders>()
  const [formData, setFormData] = useState(() => AdUnitYieldbirdBiddersFactory(adUnit))
  const [errors, setErrors] = useState(new ErrorUtils([]))
  const [forceRemount, setForceRemount] = useState(0)

  const aliases = useAliases(adUnit, domain)

  const [upsertBidderParams, { loading }] = useMutation(UpsertBidderParams, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ upsertBidderParams: { bidderParams, errors } }) => {
      if (bidderParams) {
        createNotifier(
          t('adUnits.edit.successMessage', { adUnitPath: adUnit.ossAdUnit.adUnitPath }),
          NotificationType.SUCCESS
        )
        setFormData(AdUnitYieldbirdBiddersFactory({ id: adUnit.id, bidderParams }))
        setForceRemount((forceRemount) => forceRemount + 1)
        formStatus.reset('demandBidderParamsAttributesForm')
      } else {
        createNotifier(t('common:formSubmitFailure'), NotificationType.ERROR)
        setErrors(new ErrorUtils(matchValidationErrorsToSplitBidders(errors, lastBidderParams.current!)))
      }
    }
  })

  const onSubmitHandler = (formData: AdUnitBiddersFormData) => {
    const hasUnsaved = validateUnsavedAdUnitBidders(formData, 'demandBidderParamsAttributes', errors, t)
    if (hasUnsaved) {
      setErrors(new ErrorUtils(errors.raw))
    } else {
      lastBidderParams.current = formData.bidderParamsAttributes
      upsertBidderParams(nestGqlInput(new AdUnitBiddersSerializer(formData).yieldbirdBiddersParams()))
    }
  }

  return (
    <Box>
      <BoxHeader
        title={t('adUnits.form.inputs.aliases.label')}
        subtitle={t('adUnits.form.inputs.aliases.description')}
      />
      <AdUnitsBiddersForm
        className='yieldbird-bidders-form'
        data={aliases}
        errors={errors}
        formData={formData}
        formId='add-alias-form'
        isSubmitting={loading}
        key={forceRemount}
        name='demandBidderParamsAttributes'
        onSubmit={onSubmitHandler}
        selectId='ad-unit-alias-bidder'
        setErrors={setErrors}
        translations='aliases'
        isDemand
      />
    </Box>
  )
}

const useAliases = (adUnit?: AdUnit, domain?: Domain) => {
  const [aliases, setAliases] = useState<Bidder[]>()

  const mapBidderSettings = (setting: BidderSetting) => {
    const mode = (adUnit?.domainVersion?.bidderSettingModes || {})[setting.id] || BidderParamMode.Client

    return {
      ...setting.bidder,
      active: setting.active,
      bidderCode: setting.bidderCode,
      mode,
      settingId: setting.id,
      connected: setting.connected
    }
  }

  const [aliasesQuery] = useLazyQuery(BidderSettingsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: ({ bidderSettings }) => {
      setAliases(bidderSettings.nodes.map(mapBidderSettings))
    }
  })

  const workspaceId = domain?.workspace.id

  useEffect(() => {
    if (workspaceId) {
      aliasesQuery({ variables: { adUnitId: adUnit?.id, workspaceId, demand: true } })
    }
  }, [workspaceId, adUnit, aliasesQuery])

  return aliases
}
