import { useContext, useMemo } from 'react'

import { findKey } from 'lodash'
import { useLocation } from 'react-router-dom'

import { Product } from 'webapp/constants/Product'
import { RouteProductPrefix } from 'webapp/constants/RouteProductPrefix'
import { UserContext } from 'webapp/context/UserContext'

/**
 * currentProduct refers to a path within the app.
 * currentProductConnection refers to an actual product connection db entity.
 * This separation is necessary because superusers don't have productConnections,
 * but need to access most of the product routes.
 *
 * ---
 *
 * Note that developers in code have different product names than end users. We map these names with `RouteProductPrefix`.
 */
export const getCurrentProduct = (currentPath = location.pathname): Product => {
  const productByPrefix =
    Object.values(RouteProductPrefix).find((prefix) => {
      const normalizedPrefix = prefix.replace(/_/g, '-')
      const pathMatchesPrefix = currentPath.startsWith(`/${normalizedPrefix}`)

      return pathMatchesPrefix
    }) ?? RouteProductPrefix[Product.OSS]

  const mappedPrefixToProduct = findKey(RouteProductPrefix, (value) => value === productByPrefix) as Product

  return mappedPrefixToProduct
}

export const useCurrentProduct = (): Product => {
  const location = useLocation()
  return getCurrentProduct(location.pathname)
}

export const useCurrentProductConnection = () => {
  const { user } = useContext(UserContext)

  const productConnections = user?.workspace?.productConnections
  const currentProductName = useCurrentProduct()

  return useMemo(
    () => productConnections?.find(({ product }) => product === currentProductName) ?? null,
    [productConnections, currentProductName]
  )
}
