import React, { useState } from 'react'

import { MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import { DropdownMenu } from 'components/DropdownMenu'
import { Edit } from 'icons/Edit'
import { useTranslation } from 'react-i18next'
import { routesBuilder } from 'utilities/routesBuilder'
import { PageRowData } from './PageRowData'

type ActionsCellProps = {
  row: Row<PageRowData>
}

export const ActionsCell = ({ row }: ActionsCellProps): JSX.Element => {
  const { t } = useTranslation('inventory')
  const history = useHistory<History>()
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const pageRow: PageRowData = row.original

  const onEdit = (): void => void history.push(routesBuilder.quickwrap.inventory.pages.edit(pageRow.id))

  return (
    <div onClick={(e): void => e.stopPropagation()}>
      <DropdownMenu setVisible={setDropdownVisible} visible={dropdownVisible}>
        <MenuItem onClick={onEdit}>
          {t('common:buttons.edit')}
          <Edit />
        </MenuItem>
      </DropdownMenu>
    </div>
  )
}
